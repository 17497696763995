export const getFormattedDate = (year: any, month: any, day: any) => {
    if(!(year && month && day)) return ''
    return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2)
}

export const getDiffdate = (datefrom: any, dateTill: any) => {
    if(!datefrom) return;
    if(!dateTill) return;
    let fromSplit = datefrom.split("-")
    let tillSplit = dateTill.split("-")

    let fromDate = new Date(fromSplit[0], fromSplit[1] -1, fromSplit[2])
    let tillDate = new Date(tillSplit[0], tillSplit[1] -1, tillSplit[2])

    let diffintim = tillDate.getTime() - fromDate.getTime();
    console.log(diffintim / (1000 * 3600 * 24))
    return diffintim / (1000 * 3600 * 24)

}

export const getCurrentDate = (date: any) => {
    let fromSplit = date.split("-")
    let fromDate = new Date(fromSplit[0], fromSplit[1] -1, fromSplit[2])
    return fromDate
}
