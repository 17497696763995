import React, {useEffect, useMemo, useState} from 'react';
import {Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../reactTable/useServerCall";
import useSWR from "swr";
import DeleteModal from "../../deleteModal";
import ReactTable from "../../reactTable/ReactTable";
import CircularSpinner from "../../../../shared/atom/spinner";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DataEmptyMessage from "../dataEmptyMessage";
import {useHistory} from "react-router-dom";

const CustomTable: React.FC<any> = (Props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const [deleteId, setDeleteId] = useState(null)

    const history = useHistory();

    const branchColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {
                return (
                    <Grid container spacing={3} justify={'center'}>

                        <Grid item >
                            <IconButton size={"small"} style={{color: '#4aca53'}}
                                        onClick={() => history.push(`/dashboard/sales/customers/edit/${val.row.original.id}`, val.row.original)}

                            >
                                <EditIcon fontSize={"small"}/>
                            </IconButton>
                        </Grid>

                    </Grid>
                )
            }
        },

        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Phone',
            Footer: 'Phone',
            accessor: 'phone'
        },
        {
            Header: 'Email',

            Footer: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Max Allowed Discount',
            Footer: 'Max Allowed Discount',
            Cell: (val: any) => (
                <div style={{fontWeight: 700, paddingLeft: 3}}>
                    {val.row.original.maxAllowedDiscount}%
                </div>
            )
        },

        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },


    ]

    const path = '/getCustomers'

    const {data, loading} = useServerCall({path, page, perPage, search: `?search=${Props.formState.search}`})

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}?search=${Props.formState.search}`)


    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [];

    const mockData = useMemo(() => calledData, [data]);

    const columns = useMemo(() => branchColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div>
                    <DeleteModal message={`Are you sure? Once deleted it cannot be reverted.`}
                                 trigger={`/getBranches/${page - 1}/${perPage}`}
                                 deletePath={`/deleteBranch/${deleteId}`} open={deleteId}
                                 handleDeleteCancel={() => setDeleteId(null)}
                    />
                    <ReactTable
                        data={mockData}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        perPageOption={[30,50,100]}
                        totalPage={data?.t.totalPage}
                    />
                </div>
            ): (
                (page ===1 && loading) ? null:    <DataEmptyMessage/>
            )}
        </div>
    );
};

export default CustomTable;
