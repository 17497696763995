import React, {useState} from 'react';
import * as Yup from "yup";
import {Grid, Popper} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import InputField from "../../../../shared/atom/formElements/inputField";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import DateComponent from "../../../../product/molecules/dateComponent";
import Button from "../../../../shared/atom/button";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import SearchIcon from "@material-ui/icons/Search";
import CircularSpinner from "../../../../shared/atom/spinner";
import {makeStyles} from "@material-ui/core/styles";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import useSWR from "swr";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const PurchaseFilter: React.FC<any> = (Props) => {
    const styles = useStyles();

    const initialValues = {
        search: '',
        enable: 'Enabled',
        isJournaled: 'With Journal',
        vendor: null,
        transactionYearAdFrom: null,
        transactionMonthAdFrom: null,
        transactionDateAdFrom: null,
        transactionYearBsFrom: null,
        transactionMonthBsFrom: null,
        transactionDateBsFrom: null,
        transactionYearAdTill: null,
        transactionMonthAdTill: null,
        transactionDateAdTill: null,
        transactionYearBsTill: null,
        transactionMonthBsTill: null,
        transactionDateBsTill: null,

    }

    const [formState, setFormState] = useState(initialValues)


    const validationSchema = Yup.object({})

    const onSubmit = (values: any, formAction: any) => {

        Props.setFormState(values)

    };

    const {data: vendorList} = useSWR('/getVendors')


    const withJournaled = ['']
    const withDate = ['purchase-order', 'purchase-bill'];
    const withName = ['purchase-rfp'];
    const withVendor = ['purchase-order', 'purchase-bill']

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{width: 400}} placement='bottom-end'/>)
    }



    return (

        <div>


            <Formik

                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {

                    return (
                        <div style={{marginTop: 10}}>
                            <PagePrompt formik={formik}/>

                            <Form style={{marginLeft: 2}}>
                                <Grid container justify={(withJournaled.includes(Props.from) || withName.includes(Props.from)) ? 'flex-start': withDate.includes(Props.from) ? "space-between" :  'space-between'}
                                      alignItems={"center"} spacing={1}>


                                    {withName.includes(Props.from) && (
                                        <Grid item xs={3} alignItems={'center'}
                                              style={{marginBottom: withDate.includes(Props.from) ? 0 : 5}}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'search'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'} id={'search'} {...field}
                                                                                size={"small"}
                                                                                filterInput={true}
                                                                                placeholder={'Code/ Name'}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>
                                        </Grid>
                                    )}

                                    {withVendor.includes(Props.from) && (
                                        <Grid item xs={2} alignItems={'center'}
                                              style={{marginBottom: 0}}>

                                            <div style={{position: 'relative'}}>
                                                <Field name={'vendor'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'vendor'} {...field}
                                                                                       size={"small"}
                                                                                       placeholder={'Vendor'}
                                                                                       onChange={(e, value) => {
                                                                                           form.setFieldValue("vendor", value)

                                                                                       }}

                                                                                       options={vendorList ? vendorList.data : []}
                                                                                       loading={!vendorList}
                                                                                       getOptionLabel={option => option.name}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>
                                        </Grid>
                                    )}

                                    {withDate.includes(Props.from) && (
                                        <>
                                            <Grid container item xs={5} alignItems={'center'} spacing={2}

                                                  style={{marginBottom: 10, paddingLeft: 20}}>
                                                <Grid item xs={2}>
                                                    <label htmlFor="fromDate"
                                                           className={styles.label}
                                                    >
                                                        From:
                                                    </label>
                                                </Grid>

                                                <Grid item container xs={10} alignItems={'center'}>

                                                    <DateComponent
                                                        ad={['transactionYearAdFrom', 'transactionMonthAdFrom', 'transactionDateAdFrom']}
                                                        bs={['transactionYearBsFrom', 'transactionMonthBsFrom', 'transactionDateBsFrom']}
                                                    />

                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={5} alignItems={'center'} spacing={2}
                                                  style={{marginBottom: 10}}>
                                                <Grid item xs={2}>
                                                    <label htmlFor="tillDate"
                                                           className={styles.label}
                                                    >
                                                        Till:
                                                    </label>
                                                </Grid>

                                                <Grid item container xs={10} alignItems={'center'}>

                                                    <DateComponent
                                                        ad={['transactionYearAdTill', 'transactionMonthAdTill', 'transactionDateAdTill']}
                                                        bs={['transactionYearBsTill', 'transactionMonthBsTill', 'transactionDateBsTill']}
                                                    />

                                                </Grid>
                                            </Grid>


                                        </>
                                    )}



                                    <Grid item xs={3} alignItems={'center'}
                                          style={{marginBottom: 5}}>
                                        <Grid container justify={"flex-start"} spacing={2} alignItems={'center'}>
                                            <Grid item>
                                                <div style={{maxWidth: 140}}>
                                                    <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                            textColor={'white'}
                                                            disabled={Props.submitting}
                                                            startIcon={<SearchIcon/>}

                                                            type={'submit'}>
                                                            <span style={{fontSize: 15}}>
                                                                Search
                                                            </span>
                                                    </Button>
                                                </div>
                                            </Grid>

                                            <Grid item>
                                                {Props.submitting &&
                                                <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )
};

export default PurchaseFilter;
