import React, {useState} from 'react';
import DialogComponent from "../../../shared/atom/dialog";
import {Grid, InputAdornment} from "@material-ui/core";
import Button from "../../../shared/atom/button";
import CircularSpinner from "../../../shared/atom/spinner";
import axiosInstance from "../../../../config/axiosConfig";
import {trigger} from "swr";
import {useDispatch, useSelector} from "react-redux";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import {RootState} from "../../../../store/RootReducer";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputField from "../../../shared/atom/formElements/inputField";


const DeleteModal: React.FC<any> = (Props) => {


    const [deleted, setDeleted] = useState(false);
    const [message, setMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(false);

    const isDisable = Props.disable

    const [formData, setFormData] = useState({
        password: ""
    });


    const dashboard = useSelector((state: RootState) => state.dashboard);


    const handleDelete = async () => {


        if (Props.withVerification) {
            if (formData.password.length === 0) {
                setError(true)
                return;
            } else {
                setError(false)
            }
        }
        setSubmitting(true)

        try {

            let dataTemp: any = {};

            if (Props.withVerification) {
                const {data: token} = await axiosInstance.post('/verifyYourself', {
                    email: dashboard.email,
                    password: formData.password
                })

                if (isDisable) {
                    const {data} = await axiosInstance.put(Props.deletePath + `?token=${token.token}`)
                    dataTemp = data;
                } else {
                    const {data} = await axiosInstance.delete(Props.deletePath)
                    dataTemp = data;
                }
            } else {
                if (isDisable) {
                    const {data} = await axiosInstance.put(Props.deletePath)
                    dataTemp = data;
                } else {
                    const {data} = await axiosInstance.delete(Props.deletePath)
                    dataTemp = data;
                }
            }


            if (dataTemp.code === 'OK') {

                trigger(Props.trigger);

                setDeleted(true)
                handleResponse(`${isDisable ? Props.enable ? 'Enabled' : 'Disabled' : 'Deleted'} successfully.`, 'success')
                Props.handleDeleteCancel();
                setFormData(formData);
                setMessage(`${isDisable ? Props.enable ? 'Enabled' : 'Disabled' : 'Deleted'} successfully.`)
            }


        } catch (e) {
            setSubmitting(false)
            setMessage('Error encountered.')
        }

        setSubmitting(false)

    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }


    return (
        <div>
            {Props.open && (
                <DialogComponent open={Props.open} maxWidth={"xs"} >

                    {(message && !deleted) && (
                        <div style={{color: 'red', paddingBottom: 10}}>

                            {message}

                        </div>
                    )}

                    <div style={{fontSize: 16, fontWeight: 600, marginBottom: 30}}>
                        {deleted ? message : Props.message}
                    </div>

                    {Props.withVerification && (

                        <div>
                            <div style={{paddingBottom: '10px', fontSize: 14, fontWeight: 700}}>
                                Please verify yourself.
                            </div>

                            <InputField type={!showPassword ? 'password' : 'text'} id={'password'}
                                        value={formData.password}
                                        onChange={event => setFormData(prevState => {
                                            return {...prevState, password: event.target.value}
                                        })}
                                        size={"small"}
                                        fullWidth={true}
                                        error={error}
                                        helperText={error && 'Required'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end">
                                                    {!showPassword ? (
                                                        <VisibilityIcon
                                                            fontSize={"small"}
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: COLOR_PROFILE.primaryColor
                                                            }}
                                                            onClick={() => setShowPassword(true)}
                                                        />
                                                    ) : (
                                                        <VisibilityOffIcon
                                                            fontSize={"small"}
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: COLOR_PROFILE.primaryColor
                                                            }}
                                                            onClick={() => setShowPassword(false)}
                                                        />
                                                    )}

                                                </InputAdornment>
                                            ),
                                        }}
                            />

                        </div>

                    )}

                    <Grid container spacing={3} style={{margin: '10px 0 0 -10px '}} alignItems={"center"}>

                        <Grid item>
                            <div style={{minWidth: 125}}>
                                <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue} textColor={'white'}
                                        type={'submit'} onClick={Props.handleDeleteCancel} disabled={submitting}>
                                    <span style={{fontSize: 15}}>{'Cancel'}</span>
                                </Button>
                            </div>
                        </Grid>
                        {submitting ? (
                            <Grid item style={{marginTop: 3}}>
                                <CircularSpinner size={30} circleColor={'blue'}/>
                            </Grid>
                        ) : (
                            !deleted && (
                                <Grid item>
                                    <div style={{minWidth: 125}}>
                                        <Button variant={"contained"}
                                                customColor={Props.enable ? COLOR_PROFILE.buttonGreen : COLOR_PROFILE.buttonRed}
                                                textColor={'white'}
                                                type={'button'}
                                                onClick={handleDelete}
                                        >
                                    <span style={{fontSize: 15}}>
                                        {isDisable ? Props.enable ? 'Enable' : 'Disable' : 'Delete'}
                                    </span>
                                        </Button>
                                    </div>
                                </Grid>
                            )
                        )}

                    </Grid>
                </DialogComponent>

            )}

        </div>
    );
};

export default DeleteModal;
