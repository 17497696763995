import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import InputField from "../../../../shared/atom/formElements/inputField";
import useSWR from "swr";
import FormSaveButton from "../../FormSaveButton";
import axiosInstance from "../../../../../config/axiosConfig";
import RequiredNotation from "../../../molecules/requiredNotation";
import {matchSorter} from "match-sorter";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import Button from "../../../../shared/atom/button";
import PagePrompt from "../../../../shared/organisms/pagePrompt";
import useAddressHandler from "../../../../../effects/useAddressHandler";

interface Props {

    from: string
    mode: 'edit' | 'create',
    nextPath ?: any,
    id ?: any

}


const BankForm: React.FC<Props> = (Props) => {


    const form: any = {
        name: null,
        address: null,
        accountNumber: '',
        accountName: "",
        branchName: null,
        signatoryEmployeeDto1: null,
        signatoryEmployeeDto2: null,
        accountHeadName: '',
        accountType: '',
        accountCreatedDate: "",
        description: ""
    }

    const [formState, setFormState] = useState(form);


    const validationSchema = Yup.object({
        name: Yup.object().required('Required').nullable(true),
        address: Yup.object().required('Required').nullable(true),
        accountName: Yup.string().required('Required'),
        accountNumber: Yup.string().required('Required'),
        accountHeadName: Yup.string().required('Required').nullable(true),
        // signatoryEmployeeDto1: Yup.object().required('Required').nullable(true)
    })

    const {data: employeeOptions} = useSWR('/getAllEmployees');
    const {data: bankOptions} = useSWR('/getDefaultBanks');

    const location = useLocation();
    // const {provinceOptions, districtOptions, vdcOptions, wardOptions} = useAddressHandler({...selectedAddress})

    useEffect(() => {

        dispatch({type: 'SET_DATA_FETCHING', payload: true});

        if(!bankOptions) return;

            if (Props.id) {

                // @ts-ignore
                const {name, address, accountNumber, bankDefault, accountName, branchName, signatoryEmployeeDto, accountHeadName, accountType,accountCreatedDate, description} = location.state;

                console.log(name)
                try {


                    // @ts-ignore
                    setFormState({
                        name: bankDefault,
                        address: bankDefault,
                        accountNumber: accountNumber,
                        accountName: accountName,
                        branchName: '',
                        signatoryEmployeeDto1: signatoryEmployeeDto ? signatoryEmployeeDto[0] : null,
                        signatoryEmployeeDto2: signatoryEmployeeDto? signatoryEmployeeDto[1]: null,
                        accountHeadName: accountHeadName,
                        accountType: accountType,
                        accountCreatedDate: accountCreatedDate? accountCreatedDate.split('T')[0] : '',
                        description: description
                    })

                } catch (e) {
                    console.log(e);
                }
            }

            dispatch({type: 'SET_DATA_FETCHING', payload: false});


        }, [bankOptions]
    )




    const onSubmit = async (values: any, other: any) => {

        let isReset = values.action === 'submitAndAdd';

        const {name, address} = values;

        try {
            let foundSelected = bankOptions?.data.find((item: any) => (item.name === name.name) && (item.branchName === address.branchName))


            let bankDefault = {}
            if (foundSelected) {
                bankDefault = {id: foundSelected.id}
            } else {
                bankDefault = {
                    name: name.name,
                    branchName: address.branchName
                }
            }

            let sigEmpDto = [];

            if(values.signatoryEmployeeDto1){
                sigEmpDto.push({
                    id: values.signatoryEmployeeDto1.id
                })
            }else if (values.signatoryEmployeeDto2){
                sigEmpDto.push({
                    id: values.signatoryEmployeeDto2.id
                })
            }

            const payload = {
                bankDefault: bankDefault,
                accountName: values.accountName,
                accountNumber: values.accountNumber,
                accountType: values.accountType,
                signatoryEmployeeDto: sigEmpDto,
                accountCreatedDate: values.accountCreatedDate,
                description: values.description,
                accountHeadName: values.accountHeadName

            }

            if(Props.id){
                const {data} = await axiosInstance.put('/updateBank', {...payload, id: Props.id});
                responseProcess(data, other, isReset);
            }else{
                const {data} = await axiosInstance.post('/addBank', payload);
                responseProcess(data, other, isReset);
            }



            other.setSubmitting(false)
        } catch (e) {
            handleResponse('Something went wrong.', 'warning')
            other.setSubmitting(false)
        }

    }
    const history = useHistory();

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if (!isReset) history.replace('/dashboard/company-setup/banks')

        } else {

            handleResponse(data.message, 'warning')

        }
    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }
    return (
        <div>
            <Formik
                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {

                    return (
                        <div>
                            <PagePrompt formik={formik}/>

                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>


                                <Grid container justify={"space-between"}>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="name" style={{fontWeight: "bold", fontSize: 14}}> Bank
                                                Name:<RequiredNotation/></label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'name'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;
                                                            console.log('bannk',field);

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'name'} {...field}
                                                                                       size={"small"}
                                                                                       freeSolo={true}
                                                                                       onChange={(e, value) => {
                                                                                           console.log(value)
                                                                                           if (!value) {
                                                                                               form.setFieldValue("name", value)
                                                                                               return;
                                                                                           }

                                                                                           if (value.id) {
                                                                                               form.setFieldValue("name", value)
                                                                                               form.setFieldValue("accountHeadName", form.values.accountName + ' ' + value)
                                                                                           } else {
                                                                                               form.setFieldValue("name", {name: value})
                                                                                               form.setFieldValue("accountHeadName", form.values.accountName + ' ' + value)
                                                                                           }
                                                                                       }}
                                                                                       // autoSelect={true}
                                                                                       loading={!bankOptions}
                                                                                       options={bankOptions ? bankOptions.data : []}
                                                                                       getOptionLabel={option => option.name}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                               <span
                                                                   style={{
                                                                       color: 'red',
                                                                       fontSize: 12,
                                                                       bottom: 0,
                                                                       left: 2
                                                                   }}
                                                               >
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                               </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>

                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="address"
                                                   style={{
                                                       fontWeight: "bold",
                                                       fontSize: 14
                                                   }}> Branch:<RequiredNotation/></label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'address'}

                                                >
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'address'} {...field}
                                                                                       size={"small"}
                                                                                       freeSolo={true}
                                                                                       filterOptions={(options, {inputValue}) => {
                                                                                           let filteredOptions = bankOptions.data.filter((item: any) => item.name === form.values.name?.name)
                                                                                           return matchSorter(filteredOptions, inputValue, {keys: ['branchName', 'code']});
                                                                                       }}

                                                                                       onChange={(e, value) => {
                                                                                           console.log(value)
                                                                                           if (!value) {
                                                                                               form.setFieldValue("address", value)
                                                                                               return;
                                                                                           }
                                                                                           if (value.id) {
                                                                                               form.setFieldValue("address", value)
                                                                                           } else {
                                                                                               form.setFieldValue("address", {branchName: value})

                                                                                           }
                                                                                       }}
                                                                                       autoSelect={true}
                                                                                       loading={!bankOptions}
                                                                                       options={bankOptions ? bankOptions.data : []}
                                                                                       getOptionLabel={option => option.branchName}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>

                                            <span
                                                style={{
                                                    color: 'red',
                                                    fontSize: 12,
                                                    bottom: 0,
                                                    left: 2
                                                }}
                                            >
                                        {(meta.touched && meta.error) ? meta.error : null}
                                            </span>

                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>

                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="accountName" style={{fontWeight: "bold", fontSize: 14}}>
                                                Account Name:<RequiredNotation/>
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'accountName'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'accountName'} {...field}
                                                                                size={"small"}
                                                                                onChange={(event: any) => {
                                                                                    form.setFieldValue("accountName",event.target.value)
                                                                                    form.setFieldValue("accountHeadName",  event.target.value + ' ' + form.values.name.name)
                                                                                }}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                            <span style={{
                                                color: 'red',
                                                fontSize: 12,
                                                bottom: 0,
                                                left: 2
                                            }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>



                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="accountNumber" style={{fontWeight: "bold", fontSize: 14}}>
                                                Account Number:<RequiredNotation/>
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'accountNumber'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'accountNumber'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                            <span style={{
                                                color: 'red',
                                                fontSize: 12,
                                                bottom: 0,
                                                left: 2
                                            }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="accountHeadName" style={{fontWeight: "bold", fontSize: 14}}>
                                                Account Head Name:<RequiredNotation/>
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'accountHeadName'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                disabled={Props.id}

                                                                                id={'accountHeadName'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                            <span style={{
                                                color: 'red',
                                                fontSize: 12,
                                                bottom: 0,
                                                left: 2
                                            }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="accountType" style={{fontWeight: "bold", fontSize: 14}}>
                                                Account Type:
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'accountType'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'accountType'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                            <span style={{
                                                color: 'red',
                                                fontSize: 12,
                                                bottom: 0,
                                                left: 2
                                            }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="accountCreatedDate"
                                                   style={{fontWeight: "bold", fontSize: 14}}>
                                                Account Created Date
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'accountCreatedDate'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'date'}
                                                                                id={'accountCreatedDate'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                            <span style={{
                                                color: 'red',
                                                fontSize: 12,
                                                bottom: 0,
                                                left: 2
                                            }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="signatoryEmployeeDto1"
                                                   style={{fontWeight: "bold", fontSize: 14}}> Signatory Employee
                                                1:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'signatoryEmployeeDto1'}

                                                >
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'signatoryEmployeeDto1'} {...field}
                                                                                       size={"small"}
                                                                                       onChange={(e, value) => {
                                                                                           form.setFieldValue("signatoryEmployeeDto1", value)
                                                                                       }}
                                                                                       loading={!employeeOptions}
                                                                                       options={employeeOptions?.data}
                                                                                       getOptionLabel={option => option.name}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>

                                            <span
                                                style={{
                                                    color: 'red',
                                                    fontSize: 12,
                                                    bottom: 0,
                                                    left: 2
                                                }}
                                            >
                                        {(meta.touched && meta.error) ? meta.error : null}
                                            </span>

                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>

                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="signatoryEmployeeDto2"
                                                   style={{fontWeight: "bold", fontSize: 14}}> Signatory Employee
                                                2:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'signatoryEmployeeDto2'}

                                                >
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'signatoryEmployeeDto2'} {...field}
                                                                                       size={"small"}
                                                                                       onChange={(e, value) => {
                                                                                           form.setFieldValue("signatoryEmployeeDto2", value)
                                                                                       }}
                                                                                       loading={!employeeOptions}
                                                                                       options={employeeOptions?.data}
                                                                                       getOptionLabel={option => option.name}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>

                                            <span
                                                style={{
                                                    color: 'red',
                                                    fontSize: 12,
                                                    bottom: 0,
                                                    left: 2
                                                }}
                                            >
                                        {(meta.touched && meta.error) ? meta.error : null}
                                            </span>

                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>

                                    </Grid>



                                    <Grid container item sm={6} spacing={2} alignItems={'center'}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="description"
                                                   style={{fontWeight: "bold", fontSize: 14, marginTop: 5}}>
                                                Description
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'description'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'description'} {...field}
                                                                                size={"small"}
                                                                                rows={3}
                                                                                multiline={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                            <span style={{
                                                color: 'red',
                                                fontSize: 12,
                                                bottom: 0,
                                                left: 2
                                            }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>


                                </Grid>


                                <Grid container justify={"space-between"} alignItems={"center"}>
                                    <Grid item>
                                        <FormSaveButton mode={Props.mode} from={Props.from} submitting={formik.isSubmitting}/>
                                    </Grid>
                                    <Grid item>
                                        {Props.from === 'home' && <div>
                                            <div>
                                                <Button variant={"contained"} customColor={'#297ecc'}
                                                        textColor={'white'}
                                                        onClick={Props.nextPath}>
                                                    Complete Setup
                                                </Button>
                                            </div>
                                        </div>}
                                    </Grid>

                                </Grid>



                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    )

}

export default BankForm;
