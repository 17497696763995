import React, {useCallback, useEffect} from 'react';
import {Divider, Grid} from "@material-ui/core";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../store/RootReducer";
import {getFormattedDate} from "../../../../../../utils/dateFunction";
import {useFormikContext} from "formik";
import numberToWords from "../../../../../../utils/numberToWords";
import './style.css';
import Cancelled from '../../../../../../assets/cancelled.png';
import useAdAndBsHandler from "../../../../../../effects/useAdAndBsHandler";
import {convertAdToBs, convertAdToBsDisplay} from "../../../../../../config/dateFunctions";
import logo from '../../../../../../assets/download.jpeg';

const InvoicePrintViewSuvam: React.FC<any> = (Props) => {

    const dashboard = useSelector((state: RootState) => state.dashboard);
    const {formik, state} = Props;
    const {values: {tableRow, tableRowNonTaxable, paymentStatus}} = useFormikContext();

    const isReturn = Props.type ==='Return';


    const actualAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity))
        }, 0.00)
    }, [tableRow])

    const discountAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity) * (parseFloat(item.discount) / 100))
        }, 0.00)
    }, [tableRow])


    const taxableAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity) * (1 - (parseFloat(item.discount) / 100)))
        }, 0.00)
    }, [tableRow])

    const vatAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + (parseFloat(item.price) * parseFloat(item.quantity) * (1 - (parseFloat(item.discount) / 100))) * (parseFloat(item.tax) / 100))
        }, 0.00)
    }, [tableRow])


    const actualAmountNonTaxable = useCallback(() => {
        return tableRowNonTaxable?.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity))
        }, 0.00) || 0
    }, [tableRowNonTaxable])

    const discountAmountNonTaxable = useCallback(() => {
        return tableRowNonTaxable?.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity) * (parseFloat(item.discount) / 100))
        }, 0.00) || 0
    }, [tableRowNonTaxable])

    let decimals = (taxableAmount() + vatAmount() + actualAmountNonTaxable() - discountAmountNonTaxable()) - parseInt(String(taxableAmount() + vatAmount() + actualAmountNonTaxable() - discountAmountNonTaxable()));
    let roundOff = decimals ?  Math.round((decimals) * 100) : 0;

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();

    const commaSeperator = (num: number) => {
        if(!num) return 0;
        let x = num.toString();
        let lastThree = x.substring(x.length-3);
        let otherNumbers = x.substring(0,x.length-3);
        if(otherNumbers !== '')
            lastThree = ',' + lastThree;
        return  otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    }

    return (
        <div style={{position: 'relative', fontSize: 11}}>
            {Props.canceled && <img src={Cancelled} alt="" style={{position: 'absolute',  opacity: 0.3, top: '30%', left: '10%'}}/>}

            <Grid container>

                <Grid container item alignItems={"center"} xs={2} >
                    <Grid item container alignItems={"flex-start"}>
                        <img src={logo} alt='logo' height={50}/>
                    </Grid>

                </Grid>
                <Grid container item xs={8} style={{ fontWeight: 500, fontSize: 8}}>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <div style={{
                            fontSize: 22,
                            fontWeight: 700
                        }}> {dashboard.companyInformation.companyName}</div>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        ADM Office : WARD NO.11 TRIPURISHWAR, MALLA COMPLEX
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        3RD FLOOR, OPP. HOTEL JANAK, KATHMANDU-997, KATHMANDU
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        WWW.SUGAMGROUP.COM | email: NEPAL@SUGAMGROUP.COM | 00977-5354508, 5327301, 5328935
                    </Grid>

                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        PAN No. : 609520453 C.I.N. No:- VAT No.: 609520453
                    </Grid>
                </Grid>
                <Grid container item alignItems={"flex-end"} xs={2} style={{border: '1px solid white'}}>
                    <Grid item xs={12}>
                    </Grid>

                </Grid>


                <Grid container item xs={12} style={{paddingTop: 5, marginTop: 5 ,borderTop: '1px solid #bababa', }}>
                    <Grid item xs={4} style={{border: '1px solid white'}}>
                    </Grid>
                    <Grid item xs={4}
                          style={{border: '1px solid white', textAlign: 'center', fontWeight: 700, fontSize: 16}}>
                        {isReturn ? 'RETURN' : (Props.view === "Customer" && !state.billPrinted) ? 'TAX - INVOICE' : 'INVOICE'}
                    </Grid>
                    <Grid item xs={4} style={{border: '1px solid white'}}>

                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{paddingTop: 7}}>
                    <Grid item container xs={7} style={{border: '1px solid white'}}>
                        <Grid item xs={4}>
                        {isReturn ? 'Return No.' : 'Invoice No.'}:
                        </Grid>
                        <Grid item xs={8}>
                            <span style={{fontWeight: 700}}>{isReturn ? formik.values.salesReturnNumber: formik.values.billNumber}</span>
                        </Grid>
                    </Grid>

                    <Grid item xs={5} style={{border: '1px solid white', textAlign: 'right', fontWeight: 700}}>
                        {(!isReturn || !state.billPrinted) && (
                            <span ><u>{Props.view} Copy</u></span>
                        )}
                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{paddingTop: 7}}>
                    <Grid item container xs={7} style={{border: '1px solid white'}}>
                        <Grid item xs={4}>
                            Seller's VAT No.:
                        </Grid>
                        <Grid item xs={8}>
                            {dashboard.companyInformation.panNumber}
                        </Grid>
                    </Grid>
                    <Grid item xs={5} container style={{border: '1px solid white', textAlign: 'right'}}>
                        <Grid item xs={1} style={{textAlign: 'left'}}>
                        </Grid>
                        <Grid item xs style={{textAlign: 'left'}}>
                            Delivery
                            Date:
                        </Grid>
                        <Grid item xs={7}>
                            {convertAdToBsDisplay(getFormattedDate(formik.values.transactionYearAd, formik.values.transactionMonthAd, formik.values.transactionDateAd), formatedNepEngDates)}/ {getFormattedDate(formik.values.transactionYearAd, formik.values.transactionMonthAd, formik.values.transactionDateAd)}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{paddingTop: 7}}>
                    <Grid item container xs={7} style={{border: '1px solid white'}}>

                        <Grid item xs={4}>
                            Buyer's Name:
                        </Grid>
                        <Grid item xs={8}>
                            {formik.values.customer?.name}
                        </Grid>
                    </Grid>
                    <Grid item xs={5} container style={{border: '1px solid white', textAlign: 'right'}}>
                        <Grid item xs={1} style={{textAlign: 'left'}}>
                        </Grid>
                        <Grid item xs style={{textAlign: 'left'}}>
                            Invoice Date:
                        </Grid>
                        <Grid item xs={7}>
                            {convertAdToBsDisplay(state.createdAt.split('T')[0], formatedNepEngDates)}/ {state.createdAt.split('T')[0]}
                        </Grid>

                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{paddingTop: 7}}>
                    <Grid item container xs={7} style={{border: '1px solid white'}}>
                        <Grid item xs={4}>
                        Address:
                        </Grid>
                        <Grid item xs={8}>
                            {formik.values.customer?.address?.city}
                        </Grid>
                    </Grid>
                    <Grid item xs={5} style={{border: '1px solid white'}}>

                    </Grid>
                </Grid>


                <Grid container item xs={12} style={{paddingTop: 7}}>
                    <Grid item xs={7} container style={{border: '1px solid white'}}>
                        <Grid item xs={4}>
                            Buyer's VAT No.:
                        </Grid>
                        <Grid item xs={8}>
                            { formik.values.customer?.panNumber && String(formik.values.customer?.panNumber).split("").map((item: any) => {
                                return (
                                    <div style={{
                                        border: '1px solid gray',
                                        display: 'inline-block',
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>{item}</div>
                                )
                            })}
                        </Grid>

                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{paddingTop: 7}}>
                    <Grid item container xs={7} style={{border: '1px solid white'}}>
                        <Grid item xs={4}>
                        Mode of Payment:
                        </Grid>
                        <Grid item xs={8}>
                        {formik.values.paymentMode?.headname}
                        </Grid>
                    </Grid>
                    <Grid item xs={5} container style={{border: '1px solid white', textAlign: 'right'}}>
                        {isReturn && (
                            <>
                                <Grid item xs style={{textAlign: 'left'}}>
                                </Grid>
                                <Grid item xs style={{textAlign: 'left'
                                    , whiteSpace: "nowrap"}}>
                                    Ref. Invoice No.:
                                </Grid>
                                <Grid item xs={7}>
                                    <span>{formik.values.referenceInvoiceNumber}</span>
                                    {/*SLN-78-79/L701000012*/}
                                </Grid>

                            </>
                        )}

                    </Grid>
                </Grid>


                <Grid container item xs={12} style={{paddingTop: 10, fontWeight: 700}}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', textAlign: 'center', padding: 2}}>
                        S.N
                    </Grid>
                    <Grid item xs={9} style={{borderTop: '1px solid #bababa', borderBottom: '1px solid #bababa', textAlign: 'center', padding: 2}}>
                        Particulars
                    </Grid>
                    <Grid item xs={2} style={{border: '1px solid #bababa', textAlign: 'center', padding: 2}}>
                        Amount (NPR)
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', borderTop: 0, textAlign: 'center', padding: 2}}>
                        (A)
                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa', padding: 3}}>
                        <Grid container item xs={12} style={{paddingBottom: 2}}>
                            <Grid item xs={8}>
                                Consignment Note No: {formik.values.consignmentNo}
                            </Grid>
                            <Grid item xs={4}>
                                Date: {getFormattedDate(formik.values.consignmentYearAd, formik.values.consignmentMonthAd, formik.values.consignmentDateAd)}
                            </Grid>
                            <Grid item xs={4}>
                                Ex: {formik.values.consignmentEx}
                            </Grid>
                            <Grid item xs={4}>
                                Via: {formik.values.consignmentVia}
                            </Grid>
                            <Grid item xs={4}>
                                To: {formik.values.consignmentTo}
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4}>
                                No. of packages: {formik.values.noOfPackages}
                            </Grid>

                        </Grid>
                        <Grid container item xs={12} style={{paddingTop: 5, borderTop: '1px dotted gray'}}>
                            <Grid item xs={6}>
                                Non Taxable Items:
                            </Grid>
                            <Grid item xs={6}>
                                {formik.values?.tableRowNonTaxable.map((item: any) => {
                                    return (
                                        <Grid item xs={12}>
                                            {`➢ ${item.product?.productname}`}
                                        </Grid>
                                    )
                                })}

                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid container item alignItems={"flex-end"} xs={8} justify={"flex-end"}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {formik.values?.tableRowNonTaxable.map((item: any, index: any) => {
                                return (
                                    <>
                                        {commaSeperator(parseInt(String(item.quantity * item.price)))}<br/>
                                    </>
                                )
                            })}
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {formik.values?.tableRowNonTaxable.map((item: any, index: any) => {
                                return (
                                    <>
                                        {Math.round(((item.quantity * item.price) - parseInt(String(item.quantity * item.price))) * 100) || '00'}
                                        <br/>
                                    </>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container item xs={12}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', borderTop: 0}}>

                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa', padding: 3}}>
                        <Grid item xs={12}>
                            Gross Total
                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid container item alignItems={"flex-end"} xs={8} justify={"flex-end"}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {commaSeperator(parseInt(actualAmountNonTaxable()))}
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{border: '1px solid #bababa', borderTop: 0, padding: 3}}>
                            {Math.round((actualAmountNonTaxable() - parseInt(actualAmountNonTaxable())) * 100) || '00'}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', borderTop: 0}}>

                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa', padding: 5}}>
                        <Grid item xs={12}>
                            Discount
                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid container item alignItems={"flex-end"} xs={8} justify={"flex-end"}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 5}}>
                            {commaSeperator(parseInt(discountAmountNonTaxable()))}
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa',  padding: 5}}>
                            {Math.round((discountAmountNonTaxable() - parseInt(discountAmountNonTaxable())) * 100) || '00'}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', borderTop: 0}}>

                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa', padding: 5}}>
                        <Grid item xs={12} style={{fontWeight: 700}}>
                            Sub Total (A)
                        </Grid>
                    </Grid>
                    <Grid container item xs={2} style={{fontWeight: 700}}>
                        <Grid container item alignItems={"flex-end"} xs={8} justify={"flex-end"}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa',  padding: 5}}>
                            {commaSeperator(parseInt(actualAmountNonTaxable()) - parseInt(discountAmountNonTaxable()))}
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa',  padding: 5}}>
                            {Math.round(((actualAmountNonTaxable() - discountAmountNonTaxable()) - (parseInt(actualAmountNonTaxable()) - parseInt(discountAmountNonTaxable()))) * 100) || '00'}
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container item xs={12}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', borderTop:0, padding: 3, textAlign: 'center'}}>
                        (B)
                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa', padding: 3}}>
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                Taxable Items:
                            </Grid>
                            <Grid item xs={6}>
                                {formik.values?.tableRow.map((item: any) => {
                                    return (
                                        <Grid item xs={12}>
                                            {`➢ ${item.product?.productname}`}
                                        </Grid>
                                    )
                                })}
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid container item alignItems={"flex-end"} xs={8} justify={"flex-end"}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa', textAlign: 'right', padding: 3}}>
                            {formik.values?.tableRow.map((item: any, index: any) => {
                                return (
                                    <>
                                        {commaSeperator(parseInt(String(item.quantity * item.price)))}
                                        <br/>
                                    </>
                                )
                            })}

                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {formik.values?.tableRow.map((item: any, index: any) => {
                                return (
                                    <>
                                        {Math.round(((item.quantity * item.price) - parseInt(String(item.quantity * item.price))) * 100) || '00'}
                                        <br/>
                                    </>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container item xs={12}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', borderTop: 0}}>

                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa', padding: 3}}>
                        <Grid item xs={12}>
                            Gross Total
                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid container item alignItems={"flex-end"} xs={8} justify={"flex-end"}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {commaSeperator(parseInt(actualAmount()))}
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {Math.round((actualAmount() - parseInt(actualAmount())) * 100) || '00'}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', borderTop: 0}}>

                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa',  padding: 3}}>
                        <Grid item xs={12}>
                            Discount
                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid container item alignItems={"flex-end"} xs={8} justify={"flex-end"}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa',  padding: 3}}>
                            {commaSeperator(parseInt(discountAmount()))}
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {Math.round((discountAmount() - parseInt(discountAmount())) * 100) || '00'}

                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', borderTop: 0}}>

                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa', padding: 3}}>
                        <Grid item xs={12}>
                            Total Taxable Amount
                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid container item alignItems={"flex-end"} xs={8} justify={"flex-end"}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3,}}>
                            {commaSeperator(parseInt(taxableAmount()))}
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {Math.round((taxableAmount() - parseInt(taxableAmount())) * 100) || '00'}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={1} style={{border: '1px solid #bababa',borderTop: 0}}>

                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa', padding: 3}}>
                        <Grid item xs={12}>
                            Vat Amount @ 13%
                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid container item alignItems={"flex-end"} xs={8} justify={"flex-end"}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3,}}>
                            {commaSeperator(parseInt(vatAmount()))}
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {Math.round((vatAmount().toFixed(2) - parseInt(vatAmount())) * 100) || '00'}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{fontWeight: 700}}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', borderTop: 0}}>

                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa', padding: 3}}>
                        <Grid item xs={12}>
                            Sub Total (B)
                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid container item alignItems={"flex-end"} justify={"flex-end"} xs={8}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa',  padding: 3,}}>
                            {commaSeperator(parseInt(taxableAmount() + vatAmount()))}
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {Math.round(((taxableAmount() + vatAmount()) - parseInt(taxableAmount() + vatAmount())) * 100) || '00'}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={1} style={{border: '1px solid #bababa', borderTop: 0}}>

                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa',  padding: 3}}>
                        <Grid item xs={12}>
                            Round Off
                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid container item alignItems={"flex-end"} justify={"flex-end"} xs={8}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {(roundOff < 50 && roundOff !== 0) && ' - '} 0
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            {roundOff >= 50 ? Math.round((1 - roundOff/100)*100): roundOff}
                            {/*{roundOff}*/}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{fontWeight: 700}}>
                    <Grid item xs={1}
                          style={{border: '1px solid #bababa', borderTop: 0, textAlign: 'center', padding: 3, fontWeight: 500}}>
                        (C)
                    </Grid>
                    <Grid item xs={9} style={{borderBottom: '1px solid #bababa',  padding: 3}}>
                        <Grid item xs={12} style={{fontSize: 16}}>
                            Grand Total (A + B)
                        </Grid>
                    </Grid>
                    <Grid container item xs={2} style={{fontSize: 16}}>
                        <Grid container item alignItems={"flex-end"} justify={"flex-end"} xs={8}
                              style={{borderLeft: '1px solid #bababa',borderBottom: '1px solid #bababa',  padding: 3}}>
                            {commaSeperator(parseInt(String(taxableAmount() + vatAmount() + actualAmountNonTaxable() - discountAmountNonTaxable() + (roundOff >= 50 ? 1 : 0))))}
                        </Grid>
                        <Grid container item alignItems={"flex-end"} xs={4}
                              style={{borderLeft: '1px solid #bababa', borderRight: '1px solid #bababa',borderBottom: '1px solid #bababa', padding: 3}}>
                            00
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{border: '1px solid #bababa', borderTop: 0, padding: '12px 7px 12px 20px'}}>
                    <Grid item xs={12} style={{fontWeight: 700}}>
                        Amount in words : <span
                        >{numberToWords(parseInt(String(taxableAmount() + vatAmount() + actualAmountNonTaxable() - discountAmountNonTaxable() + (roundOff >=50 ? 1 : 0))))} Only</span>
                    </Grid>

                </Grid>


                <Grid container item xs={12} style={{paddingTop: 10, paddingBottom: 20}}>
                    <Grid container item xs={12} style={{fontWeight: 700}}>
                        Note:
                    </Grid>
                    <Grid container item xs={7} style={{fontSize: 9}}>

                        Please pay A/C payee cheque only in favour of Sugam Logistics (Nepal) Pvt. Ltd. <br/>
                        24% per annum interest will be charged if not paid within 7 days.
                    </Grid>


                    <Grid container justify={"flex-end"} alignItems={"flex-end"} item xs={5} style={{marginTop: 20,  paddingLeft: 30 , fontWeight: 700}}>
                        <div style={{borderTop: '1px solid #bababa', textAlign: 'center', width: '100%',}}>
                            For: Sugam
                            Logistics (Nepal) Pvt. Ltd.
                        </div>
                    </Grid>

                </Grid>


                <Grid container item xs={12} style={{marginTop: 20, fontWeight: 700}}>
                    <Grid container justify={"flex-start"} alignItems={"center"} item xs={4}>
                        <div style={{borderTop: '1px solid #bababa', width: '60%', textAlign: 'center'}}>Prepared By</div>
                    </Grid>
                    <Grid container justify={"center"} alignItems={"center"} item xs={4}>
                        <div style={{borderTop: '1px solid #bababa', width: '60%', textAlign: 'center'}}>Checked By</div>
                    </Grid>
                    <Grid container justify={"flex-end"} alignItems={"center"} item xs={4}>
                        <div style={{borderTop: '1px solid #bababa', width: '60%', textAlign: 'center'}}>Approved By</div>
                    </Grid>

                </Grid>

                {(state.billPrinted && !Props.canceled) && (
                    <div className='display-in-print'>
                        <Grid container  alignItems={"center"} style={{borderTop: '1px dotted grey', marginTop: 20}}>
                            <Grid item xs={12} style={{textAlign: "center", padding: 5}}>
                                Copy of Original({state.printNumber})
                            </Grid>
                        </Grid>
                    </div>
                )}

            </Grid>

        </div>
    );
};

export default InvoicePrintViewSuvam;
