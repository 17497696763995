import React from 'react';
import UomAddition from "../../templates/uomAddition";

const UomAdd = () => {
    return (
        <div>
            <UomAddition/>
        </div>
    );
};

export default UomAdd;