import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../../../accounting/organisms/reactTable/useServerCall";
import useSWR from "swr";
import ReactTable from "../../../../accounting/organisms/reactTable/ReactTable";
import DataEmptyMessage from "../../../../accounting/organisms/tables/dataEmptyMessage";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AccessFilter from "../../../../../accessControl/accessFilter";
import {getTodaysDate} from "../../../../../config/dateFunctions";

const PurchaseBillTable: React.FC<any> = (Props) => {
    const {formState} = Props;

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);

    const history = useHistory();

    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' style={{marginRight: 15, paddingRight: 15}}>

                    <Grid item xs={6}>
                        <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                    onClick={() => {
                                        history.push(`/dashboard/purchase/purchase-bill/view/${val.row.original.id}`, val.row.original)
                                    }}
                        >
                            <VisibilityIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>

                    <AccessFilter

                        section={"purchaseBill"}
                        privilege={"update"}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed) return null

                            return (

                                <Grid item xs={6}>
                                    <IconButton size={"small"} style={{color: '#4aca53'}}
                                                onClick={() => {
                                                    history.push(`/dashboard/purchase/purchase-bill/edit/${val.row.original.id}`, val.row.original)
                                                }}>
                                        <EditIcon fontSize={"small"}/>
                                    </IconButton>
                                </Grid>
                            )

                        }}

                    />


                </Grid>
            )
        },
        {
            Header: 'Bill No.',
            Footer: 'Bill No.',
            accessor: 'billNumber'
        },

        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} = cellObj.cell
                return <div>{original.productSubs.map((item: any) => item.name).join(', ')}</div>
            }
        },
        {
            Header: 'Bill Date',
            Footer: 'Bill Date',
            accessor: 'billDate'
        },
        {
            Header: 'Payment Status',
            Footer: 'Payment Status',
            accessor: 'paymentStatus'
        },
        {
            Header: 'Payment Mode',
            Footer: 'Payment Mode',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.paymentMode ? val.row.original?.paymentMode.name: 'N/A'}
                </div>
            )
        },

        {
            Header: 'Vendor',
            Footer: 'Vendor',
            accessor: 'vendorDto.name'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },
    ]

    const path = '/getPurchaseBills'
    const {data, loading} = useServerCall({
        path, page, perPage,
        search: `?&vendorId=${formState.vendor ? formState.vendor.id : 0}&from=${
            (formState.transactionYearAdFrom && formState.transactionMonthAdFrom && formState.transactionDateAdFrom) ? (formState.transactionYearAdFrom + '-' + ('0' + formState?.transactionMonthAdFrom).slice(-2) + '-' + ('0' + formState.transactionDateAdFrom).slice(-2)) : ''
        }&to=${
            (formState.transactionYearAdTill && formState.transactionMonthAdTill && formState.transactionDateAdTill) ? (formState.transactionYearAdTill + '-' + ('0' + formState?.transactionMonthAdTill).slice(-2) + '-' + ('0' + formState.transactionDateAdTill).slice(-2)) : getTodaysDate()
        }`
    })

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}?isJournaled=${formState.isJournaled === 'With Journal' ? true: false}&vendorId=${formState.vendor ? formState.vendor.id : 0}&from=${
        (formState.transactionYearAdFrom && formState.transactionMonthAdFrom && formState.transactionDateAdFrom) ? (formState.transactionYearAdFrom + '-' + ('0' + formState?.transactionMonthAdFrom).slice(-2) + '-' + ('0' + formState.transactionDateAdFrom).slice(-2)) : ''
    }&to=${
        (formState.transactionYearAdTill && formState.transactionMonthAdTill && formState.transactionDateAdTill) ? (formState.transactionYearAdTill + '-' + ('0' + formState?.transactionMonthAdTill).slice(-2) + '-' + ('0' + formState.transactionDateAdTill).slice(-2)) : ''
    }`)

    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];
    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => shareHolderColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <ReactTable
                    data={mockData}
                    perPageOption={[30, 50, 100]}
                    columns={columns}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={page}
                    perPage={perPage}
                    totalPage={data?.t.totalPage}
                />
            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default PurchaseBillTable;
