import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import VerifyRequestsTable from '../../organisms/tables/verifyRequests';
import AutoCompleteInput from "../../../shared/atom/formElements/autocompleteInput";
import {Grid} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import {APPROVAL_MAPPER} from "../../../../utils/contants";


const VerifyRequests: React.FC = () => {

    const dashboard = useSelector((state: RootState) => state.dashboard);



    const groupBy = (xs: any, key: string) => {
        return xs.reduce(function (rv: any, x: any) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    let menus = ['Account Group', 'Account Head', 'Journal', 'Receipt', 'Payment', 'Bank', 'Vendor', 'Category', 'UOM', 'Product', 'Product Bundle', 'Offer', 'Customer', 'Inventory Adjustment', 'Request for Proposal', 'Purchase Order', 'Purchase Bill Entry', 'Expense Entry', 'Purchase Return', 'Proforma', 'Sales Return']
    const dispatch = useDispatch();
    const {verifyRequestSelected, verifyRequestOptions} = useSelector((state: any) => state.selectedStatus);

    useEffect(() => {

        if (verifyRequestSelected) return;
        const groupedMenus = groupBy(dashboard.role.menus.filter((item: any) => !item.name.includes('Report')), 'name');
        console.log("grouped", groupedMenus)
        // @ts-ignore
        let options = Object.keys(groupedMenus).includes('All Menus') ? menus : Object.keys(groupedMenus).filter((item: any) => APPROVAL_MAPPER[item]);
        console.log("options", options)
        dispatch({type: 'SET_VERIFY_OPTIONS', payload: options})
        dispatch({type: 'SET_VERIFY_REQUEST', payload: options[0]})

    }, [])

    const mappedItem = (selectedItem: any) => {
        // @ts-ignore
        return APPROVAL_MAPPER[selectedItem]
    }


    return (
        <div>

            <div style={{margin: 20}}>

                <Grid container xs={12} spacing={3} style={{margin: -5, paddingBottom: 5}}>
                    {verifyRequestOptions.map((item: any) => {
                        let selected =verifyRequestSelected === item;
                        return(
                            <Grid item  key={item} alignItems={"center"} justify={"center"} style={{padding: '7px 10px 7px 10px', cursor: "pointer", margin: 10, background: selected ? COLOR_PROFILE.primaryColor: COLOR_PROFILE.tableRowOdd,
                                boxShadow: `0 1px 3px ${COLOR_PROFILE.backgroundDark}`,
                                color: selected ? 'white' : COLOR_PROFILE.primaryColor, fontWeight: 700}}
                                  onClick={() =>  dispatch({type: 'SET_VERIFY_REQUEST', payload: item})}
                            >
                                {item}
                            </Grid>
                        )
                    })}
                    <Grid item xs>

                    </Grid>
                </Grid>

                {verifyRequestSelected && (
                    <div>
                        <VerifyRequestsTable selectedInfo={mappedItem} selectedItem={verifyRequestSelected}/>
                    </div>
                )}

                {/*<div style={{width: 300, paddingTop: 10, paddingBottom: 10}}>*/}


                {/*    <AutoCompleteInput*/}
                {/*        id={'accountHead'}*/}
                {/*        size={"small"}*/}
                {/*        onChange={(e, value) => {*/}
                {/*            dispatch({type: 'SET_VERIFY_REQUEST', payload: value})*/}
                {/*        }}*/}
                {/*        value={verifyRequestSelected}*/}
                {/*        options={verifyRequestOptions}*/}
                {/*        getOptionLabel={option => option}*/}
                {/*        disableClearable={true}*/}
                {/*        renderInput={(temp) => <div>'</div>}*/}
                {/*    />*/}

                    {/*<InputField type={'text'} id={'role'}*/}
                    {/*            select={true}*/}
                    {/*            size={"small"}*/}
                    {/*            onChange={handleChange}*/}
                    {/*            value={verifyRequestSelected}*/}
                    {/*>*/}
                    {/*    {verifyRequestOptions.map((option: any) => (*/}
                    {/*        <MenuItem key={option}*/}
                    {/*                  value={option}*/}
                    {/*                  style={{fontSize: 14, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}>*/}
                    {/*            {option}*/}
                    {/*        </MenuItem>*/}
                    {/*    ))}*/}

                    {/*</InputField>*/}
                {/*</div>*/}
            </div>



        </div>
    );
};

export default VerifyRequests;
