import React from 'react';
import {useTable} from "react-table";
import {Grid, useMediaQuery} from "@material-ui/core";
import Button from "../../../../shared/atom/button";
import {useFormikContext} from "formik";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {useTheme} from "@material-ui/core/styles";

const VoucherTableForm: React.FC<any> = (Props: any) => {

    const formik = useFormikContext();
    const {columns, data, addNew, balance, total, type, sectionName, mode} = Props
    const isView = mode === 'view';

    const totalStructure = {
        'journal': {
            totalIn: [1, 2],
            1: 'credit',
            2: 'debit',
        },
        'payment': {
            totalIn: [1],
            1: 'debit'
        },
        'receipt': {
            totalIn: [1],
            1: 'credit'
        }
    }

    const tableInstance: any = useTable({
        columns,
        data
    })

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div>
            <div>
                <div style={{overflow: 'auto', marginRight: -30}}>

                    <div style={{maxWidth: matches ? '90vw' : '100%', overflow: 'auto'}}>
                    <table {...getTableProps()} style={{
                        width: '100%',
                        paddingRight: rows.length <= 2 ? 10 : 0,
                    }}>
                        <thead
                            style={{background: COLOR_PROFILE.tableHeader}}
                        >

                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map((column: any, index: any) => {

                                        return (
                                            <th {...column.getHeaderProps()}
                                                style={{
                                                    padding: 7,
                                                    color: COLOR_PROFILE.primaryColor,
                                                    minWidth: column.width || 100,
                                                    background: index === headerGroup.headers.length - 1 ? '#fafafa' : null,
                                                    textAlign: column.alignment || 'left'
                                                }}
                                            >
                                                {column.render('Header')}
                                            </th>

                                        )
                                    })
                                }
                            </tr>
                        ))}

                        </thead>

                        <tbody {...getTableBodyProps()}
                               style={{maxHeight: 200, overflow: "auto", justifyContent: 'flex-start'}}>

                        {rows.map((row: any, index: any) => {
                            prepareRow(row)
                            return (
                                <>
                                    <tr {...row.getRowProps()}
                                        style={{backgroundColor: index % 2 === 0 ? COLOR_PROFILE.tableRowEven : COLOR_PROFILE.tableRowOdd}}
                                    >
                                        {row.cells.map((cell: any, indexCell: any) => {

                                            return (
                                                <td {...cell.getCellProps()}
                                                    style={{
                                                        padding: indexCell === 0 ? 5 : -1,
                                                        backgroundColor: row.cells.length - 1 === indexCell ? '#fafafa' : null
                                                    }}>
                                                    {indexCell === 0 ? (
                                                        <strong>{index + 1}</strong>) : indexCell === row.cells.length - 1 ?
                                                        <></>
                                                        // !isView &&
                                                        // <DeleteForeverIcon
                                                        //     fontSize={"small"}
                                                        //        style={{
                                                        //            color: 'red', cursor: 'pointer',
                                                        //            display: rows.length > Props.minLength ? 'inherit' : 'none'
                                                        //        }}
                                                        //        onClick={() => Props.handleDeleteClick(index)}
                                                        //
                                                        // />
                                                        :
                                                        cell.render('Cell')}

                                                </td>

                                            )
                                        })}

                                    </tr>

                                    {(index === rows.length - 1 && !Props.fromProduct)  && (
                                        <tr {...row.getRowProps()}
                                        >
                                            {row.cells.map((cell: any, indexCell: any) => {
                                                // let showBorder = [row.cells.length - 3, row.cells.length - 2].includes(indexCell)
                                                // @ts-ignore
                                                let structure = totalStructure[type];
                                                let showBorder = structure.totalIn.map((item: any) => row.cells.length - item - 1).includes(indexCell)

                                                return <td {...cell.getCellProps()}
                                                           style={{
                                                               backgroundColor: row.cells.length - 1 === indexCell ? '#fafafa' : null,
                                                               padding: '5px 0px 5px 13px',
                                                               fontSize: 18, fontWeight: 600,
                                                               color: COLOR_PROFILE.primaryColor,
                                                               textAlign: (row.cells.length - structure.totalIn.length - 2) === indexCell ? 'center' : 'left',
                                                               border: `1px solid ${showBorder ? '#fafafa' : '#fafafa'}`
                                                           }}>
                                                    {
                                                        (row.cells.length - structure.totalIn.length - 2) === indexCell ? 'Total:' :
                                                            structure.totalIn.includes(row.cells.length - 1 - indexCell) ?
                                                                total[structure[row.cells.length - 1 - indexCell]]().toFixed(2) :
                                                                null
                                                    }
                                                </td>

                                            })}

                                        </tr>
                                    )}
                                </>
                            )
                        })}


                        </tbody>


                    </table>

                    </div>

                    <Grid container justify={"space-between"}>
                        <Grid item>
                        {/*{!isView && (*/}

                        {/*    <div style={{maxWidth: 120, marginTop: 10, marginLeft: 2, marginBottom: 10}}>*/}
                        {/*        <Button variant={"text"} onClick={() => addNew(formik, sectionName)} customColor={'#297ecc'}*/}
                        {/*                textColor={'#297ecc'}> Add New Row </Button>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        </Grid>

                        {Props.type === 'journal' && (
                            <Grid item style={{marginRight: 30, fontSize: 18, fontWeight: 600, minWidth: 200}}>
                                <Grid container
                                      style={{fontWeight: 600, background: '#d6f6f3', padding: 10, marginTop: 10}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Balance: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {balance()}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {Props.type === 'bundle' && (
                            <Grid item style={{marginRight: 30, fontSize: 16, fontWeight: 600, minWidth: 270}}>

                                <Grid container
                                      style={{ background: COLOR_PROFILE.tableRowOdd, padding: 10, marginTop: 10}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Amount: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.actualAmount}
                                    </Grid>
                                </Grid>

                                <Grid container
                                      style={{background: COLOR_PROFILE.tableRowOdd, padding: 10, marginTop: 2}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Discount Amount: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.discountAmount}
                                    </Grid>
                                </Grid>

                                <Grid container
                                      style={{background: '#d6f6f3', padding: 10, marginTop: 2}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Total Amount: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.bundlePrice}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}


                        {(Props.type === 'purchaseBillAdditional' || Props.type === 'expenseEntryAdditional' || Props.type === 'purchaseReturn' || Props.type === 'estimateAdditional' || Props.type === 'salesReturn') && (
                            <Grid item style={{marginRight: 30, marginTop: 10, fontSize: 16, fontWeight: 600, minWidth: 270}}>

                                <Grid container

                                      style={{ background: COLOR_PROFILE.tableRowOdd, padding: 10, marginTop: 10}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Bill Total: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.totalAmount}
                                    </Grid>
                                </Grid>
                                {Props.type === 'expenseEntryAdditional' && (
                                    <>
                                        <Grid container
                                              style={{background: COLOR_PROFILE.tableRowOdd, padding: 10, marginTop: 2}}
                                              justify={"space-between"}>
                                            <Grid item>
                                                TDS Withdrawn: &nbsp;&nbsp;
                                            </Grid>
                                            <Grid item>
                                                {total.tdsAmount}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}

                                <Grid container
                                      style={{background: COLOR_PROFILE.tableRowOdd, padding: 10, marginTop: 2}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Paid Amount: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.paidAmount}
                                    </Grid>
                                </Grid>



                                <Grid container
                                      style={{background: '#d6f6f3', padding: 10, marginTop: 2}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Credit Amount: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.remainingAmount}
                                    </Grid>
                                </Grid>
                            </Grid>


                        )}

                        {/*'grossTotal': actualAmount(),*/}
                        {/*'discountAmount': discountAmount(),*/}
                        {/*'taxableAmount': taxableAmount(),*/}
                        {/*'vatAmount': vatAmount(),*/}
                        {/*'subtotal': taxableAmount + vatAmount()*/}


                        {(Props.type === 'invoiceTaxable') && (
                            <Grid item style={{marginRight: 30, marginTop: 10, fontSize: 14, fontWeight: 600, minWidth: 270}}>

                                <Grid container

                                      style={{ background: COLOR_PROFILE.tableRowOdd, padding: 7, marginTop: 10}}
                                      justify={"space-between"}>
                                    <Grid item>
                                       Gross Total: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.grossTotal || 0}
                                    </Grid>
                                </Grid>

                                <Grid container
                                      style={{background: COLOR_PROFILE.tableRowOdd, padding: 7, marginTop: 2}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Discount Amount: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.discountAmount }
                                    </Grid>
                                </Grid>

                                <Grid container
                                      style={{background: COLOR_PROFILE.tableRowOdd, padding: 7, marginTop: 2}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Taxable Amount: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.taxableAmount}
                                    </Grid>
                                </Grid>

                                <Grid container
                                      style={{background: COLOR_PROFILE.tableRowOdd, padding: 7, marginTop: 2}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Vat Amount: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.vatAmount || 0}
                                    </Grid>
                                </Grid>



                                <Grid container
                                      style={{background: '#d6f6f3', padding: 5, marginTop: 2}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Sub Total (A): &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.subTotal || 0}
                                    </Grid>
                                </Grid>
                            </Grid>


                        )}

                        {(Props.type === 'invoiceNonTaxable') && (
                            <Grid item style={{marginRight: 30, marginTop: 10, fontSize: 14, fontWeight: 600, minWidth: 270}}>

                                <Grid container

                                      style={{ background: COLOR_PROFILE.tableRowOdd, padding: 7, marginTop: 10}}
                                      justify={"space-between"}>
                                    <Grid item>
                                       Gross Total: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.grossTotal || 0}
                                    </Grid>
                                </Grid>

                                <Grid container
                                      style={{background: COLOR_PROFILE.tableRowOdd, padding: 7, marginTop: 2}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Discount Amount: &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.discountAmount }
                                    </Grid>
                                </Grid>


                                <Grid container
                                      style={{background: '#d6f6f3', padding: 7, marginTop: 2}}
                                      justify={"space-between"}>
                                    <Grid item>
                                        Sub Total (B): &nbsp;&nbsp;
                                    </Grid>
                                    <Grid item>
                                        {total.subTotal || 0}
                                    </Grid>
                                </Grid>
                            </Grid>


                        )}





                    </Grid>

                    {Props.type === 'invoiceTaxable' && (

                        <div style={{maxWidth: 400, margin: '0 auto'}}>

                            <Grid container
                              style={{background: COLOR_PROFILE.tableRowOdd, padding: 7, marginTop: 2, color: COLOR_PROFILE.primaryColor, fontSize: 14, fontWeight: 700}}
                              justify={"center"}>
                            <Grid item>
                                Round Off: &nbsp;&nbsp;
                            </Grid>
                            <Grid item>
                                Rs. {(Math.round(total.grandTotal) - total.grandTotal).toFixed(2) || 0}
                            </Grid>
                        </Grid>
                            <Grid container
                              style={{background: '#dff5f8', padding: 7, marginTop: 2, color: COLOR_PROFILE.primaryColor, fontSize: 18, fontWeight: 700}}
                              justify={"center"}>
                            <Grid item>
                                Grand Total (A + B): &nbsp;&nbsp;
                            </Grid>
                            <Grid item>
                                Rs. {Math.round(total.grandTotal) || 0}
                            </Grid>
                        </Grid>
                        </div>

                    )}


                </div>
            </div>

        </div>
    )
};

export default VoucherTableForm;
