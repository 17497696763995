import React from 'react';
import PurchaseEntryAddition from "../../templates/expenseEntryAddition";

const ExpenseEntryAdd: React.FC = () => {
    return (
        <div>
            <PurchaseEntryAddition/>
        </div>
    );
};

export default ExpenseEntryAdd;