import React, {useState} from 'react';
import SetupAddNew from "../../../organisms/setupAddNew";
import AccountGroupTable from "../../../organisms/tables/accountGroup";
import AccessFilter from "../../../../../accessControl/accessFilter";
import TableFilter from "../../../organisms/Forms/tableFilter";

const AccountGroupPage: React.FC = () => {

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})

    return (
        <div>


            <AccessFilter

                section={"accountGroup"}
                privilege={'write'}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>


                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/accounting-setup/account-group/add',
                                            name: 'Add Account Group'
                                        }}
                                        buttonNameImport={isBranchAdmin ? {
                                            pathTo: '/importAccountGroups',
                                            name: 'Import Account Group from Head Office',
                                            refreshPath: '/getAccountGroup/0/30'
                                        } : undefined}

                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>Add Account Group by clicking the button below. Groups can also be
                                                    imported from parent
                                                    organization.
                                                </div>

                                            </div>
                                        }

                                    />
                                </div>

                            </div>
                        </div>
                    )

                }}

            />


            <AccessFilter

                section={"accountGroup"}
                privilege={'read'}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>
                            <div style={{maxWidth: 1000}}>

                                <div style={{padding: '0 30px 0 28px'}}>

                                    <div style={{marginBottom: -10}}>
                                        <TableFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                                     submitting={submitting} from={'accountGroup'}/>
                                    </div>
                                    <AccountGroupTable  formState={formState} setSubmitting={setSubmitting}/>

                                </div>

                            </div>
                        </div>
                    )

                }}

            />


        </div>
    );
};

export default AccountGroupPage;
