import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import * as Yup from "yup";
import useAddressHandler from "../../../../../effects/useAddressHandler";
import {Field, Form, Formik} from "formik";
import InputField from "../../../../shared/atom/formElements/inputField";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import FormSaveButton from "../../FormSaveButton";
import axiosInstance from "../../../../../config/axiosConfig";
import {useDispatch} from "react-redux";
import RequiredNotation from "../../../molecules/requiredNotation";
import {useHistory, useLocation} from "react-router-dom";
import PagePrompt from "../../../../shared/organisms/pagePrompt";
import useSWR, {trigger} from "swr";
import ErrorLabel from "../../../../product/molecules/errorLabel";


interface Props {

    from: string
    mode: 'edit' | 'create',
    nextPath?: string
    id?: any
    handleClose?: any
    noFixed?: boolean

}


const CustomerForm: React.FC<Props> = (Props) => {


    const initialValues: any = {

        name: "",
        phone: "",
        email: "",
        companyName: "",
        panNumber: "",
        type1: '',
        maxAllowedDiscount: 10,
        accountHeadName: '',
        type2: '',
        description: "",
        membership: null,
        eximCode: "",

        address: {
            country: "Nepal",
            provinceOrState: null,
            district: null,
            city: "",
            wardNumber: null,
            municipalityOrVdc: null,
            googleMapLocationUrl: ''
        }

    }

    const [formState, setFormState] = useState(initialValues)
    // @ts-ignore
    const [selectedAddress, setSelectedAddress] = useState({
        province: null,
        district: null,
        vdc: null,
        ward: null
    })
    const {provinceOptions, districtOptions, vdcOptions, wardOptions, provinceLoading, vdcLoading} = useAddressHandler({...selectedAddress})


    const location = useLocation();

    const {data: membershipData} = useSWR("/membership");

    useEffect(() => {

        if(!(provinceOptions && districtOptions && vdcOptions && wardOptions)) return;


        if (Props.id) {


                // @ts-ignore
                const {name, phone, accountHeadName,eximCode, email, companyName,maxAllowedDiscount, membership, panNumber, type1, type2,description, address} = location.state

                try {

                    let provinceTemp = provinceOptions.find((item: any) => item.id === address?.provinceOrState?.id);
                    validateProvince(provinceTemp);
                    let districtTemp = districtOptions.find((item:any) => item.district == address?.district)
                    handleSelectedDistrict(districtTemp);
                    let muniCipalityTemp = vdcOptions.find((item: any) => item.municipalityOrVdcName = address?.municipalityOrVdc);
                    validateMunicipality(muniCipalityTemp);

                    let addressTemp = {
                        address: {
                            country: 'Nepal',
                            provinceOrState: provinceTemp,
                            municipalityOrVdc:  muniCipalityTemp,
                            district: districtTemp,
                            wardNumber: address?.wardNumber || null,
                            city: address?.city || '',
                            googleMapLocationUrl: address?.googleMapLocationUrl || ''
                        }

                    }


                setFormState({
                    ...addressTemp,
                    name: name,
                    eximCode: eximCode,
                    phone: phone,
                    maxAllowedDiscount:maxAllowedDiscount,
                    accountHeadName:accountHeadName,
                    email: email,
                    companyName: companyName,
                    panNumber: panNumber,
                    membership: membership,
                    type1: type1,
                    type2: type2,
                    description: description,
                })

            } catch (e) {

            }
        }

    }, [provinceOptions, districtOptions, vdcOptions, wardOptions])



    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        // email: Yup.string().email('Email is invalid').required('Required'),
        maxAllowedDiscount: Yup.number().required('Required'),
        accountHeadName: Yup.string().required("Required"),
        // phone: Yup.string().required('Required')
    })

    const dispatch = useDispatch();

    const onSubmit = async (values: any, other: any) => {

        let isReset = values.action === 'submitAndAdd';

        const tempVal = {...values};

        try {
            const payload = {
                ...tempVal,
                address: {
                    ...tempVal.address,
                    provinceOrState: tempVal.address.provinceOrState ? tempVal.address.provinceOrState.province : null,
                    municipalityOrVdc: tempVal.address.municipalityOrVdc ? tempVal.address.municipalityOrVdc.municipalityOrVdcName : null,
                    district: tempVal.address.district ? tempVal.address.district.district : null,
                    wardNumber: tempVal.address.wardNumber,
                }
            }


            // return


            if(Props.id){

                // @ts-ignore
                // throw new Error("Contraint Violation");

                const {data} = await axiosInstance.put('/updateCustomer', {...payload, id: parseInt(Props.id)})

                if (data?.code === 'OK') {
                    handleResponse(data, other, isReset)
                }

            }else{
                const {data} = await axiosInstance.post('/addCustomer', payload)

                if (data?.code === 'OK') {

                    handleResponse(data, other, isReset)

                }
            }


        } catch (e) {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: 'Something went wrong', type: 'warning'}
            })
        }


    }

    const history = useHistory();

    const handleResponse = (data: any, formActions: any, isReset: boolean) => {

        let message = ''
        let barType = 'info'

        if (data?.code === 'OK') {

            message = data?.message;

            if (data?.message.includes("added") || data?.message.includes("updated")) {
                formActions.resetForm();
                barType = 'success'
                trigger("/getAllCustomers")
                if(!isReset)
                    Props.handleClose ?
                        Props.handleClose() :
                        history.replace('/dashboard/sales/customers');

            } else {
                barType = 'warning'
            }

        }

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: message, type: barType}
        })
    }







    const validateProvince = (value: any) => {


        if (value?.province) {


            setSelectedAddress(prevState => {
                return {
                    ...prevState,
                    province: value
                }
            })

        }

        return false

    }

    const handleSelectedDistrict = (value: any) => {

        setSelectedAddress(prevState => {
            return {
                ...prevState,
                district: value
            }
        })

    }

    const validateMunicipality = (value: any) => {


        if (value?.wardNumbers) {

            setSelectedAddress(prevState => {
                return {
                    ...prevState,
                    vdc: value
                }
            })

        }

    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }


    return (
        <div>

            <Formik

                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
                enableReinitialize={true}
            >
                {formik => {


                    return (
                        <div>
                            <PagePrompt formik={formik}/>
                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>

                                <Grid container justify={"space-between"}>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="name"
                                                   style={{
                                                       fontWeight: "bold",
                                                       fontSize: 14
                                                   }}>Name:<RequiredNotation/></label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'name'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'} id={'name'} {...field}
                                                                                size={"small"}
                                                                                onChange = {(event: any) => {
                                                                                    form.setFieldValue("name", event.target.value)
                                                                                    form.setFieldValue("accountHeadName", event.target.value + ' (Account Receivables)');

                                                                                    if(!event.target.value) form.setFieldValue("accountHeadName", '');


                                                                                }}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="panNumber" style={{fontWeight: "bold", fontSize: 14}}>PAN/VAT
                                                No:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'panNumber'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'string'}
                                                                                id={'panNumber'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="eximCode" style={{fontWeight: "bold", fontSize: 14}}>Exim Code:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'eximCode'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'string'}
                                                                                id={'eximCode'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>

                                        <Grid item xs={4}>
                                            <label htmlFor="phone"
                                                   style={{fontWeight: "bold", fontSize: 14}}>Phone:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'phone'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'phone'} id={'phone'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>

                                    </Grid>



                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="email"
                                                   style={{fontWeight: "bold", fontSize: 14}}>Email:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'email'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'email'} id={'email'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                    {/*      style={{marginBottom: 15}}>*/}
                                    {/*    <Grid item xs={4}>*/}
                                    {/*        <label htmlFor="accountHeadName" style={{fontWeight: "bold", fontSize: 14}}>*/}
                                    {/*            Account Head Name:<RequiredNotation/>*/}
                                    {/*        </label>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={8}>*/}
                                    {/*        <div style={{position: 'relative'}}>*/}
                                    {/*            <Field name={'accountHeadName'}>*/}
                                    {/*                {*/}
                                    {/*                    (props: { field: any; form: any; meta: any; }) => {*/}

                                    {/*                        const {field, form, meta} = props;*/}

                                    {/*                        return (*/}
                                    {/*                            <>*/}
                                    {/*                                <InputField type={'text'}*/}
                                    {/*                                            id={'accountHeadName'} {...field}*/}
                                    {/*                                            size={"small"}*/}
                                    {/*                                            error={meta.touched && meta.error}*/}
                                    {/*                                />*/}
                                    {/*                                <div style={{position: "absolute"}}>*/}
                                    {/*        <span style={{*/}
                                    {/*            color: 'red',*/}
                                    {/*            fontSize: 12,*/}
                                    {/*            bottom: 0,*/}
                                    {/*            left: 2*/}
                                    {/*        }}>{(meta.touched && meta.error) ? meta.error : null}</span>*/}
                                    {/*                                </div>*/}
                                    {/*                            </>*/}

                                    {/*                        )*/}
                                    {/*                    }*/}
                                    {/*                }*/}
                                    {/*            </Field>*/}

                                    {/*        </div>*/}

                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}

                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                    {/*      style={{marginBottom: 15}}>*/}
                                    {/*    <Grid item xs={4}>*/}
                                    {/*        <label htmlFor="companyName" style={{fontWeight: "bold", fontSize: 14}}>Company*/}
                                    {/*            Name:</label>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={8}>*/}
                                    {/*        <div style={{position: 'relative'}}>*/}
                                    {/*            <Field name={'companyName'}>*/}
                                    {/*                {*/}
                                    {/*                    (props: { field: any; form: any; meta: any; }) => {*/}

                                    {/*                        const {field, form, meta} = props;*/}

                                    {/*                        return (*/}
                                    {/*                            <>*/}
                                    {/*                                <InputField type={'text'}*/}
                                    {/*                                            id={'companyName'} {...field}*/}
                                    {/*                                            size={"small"}*/}
                                    {/*                                            error={meta.touched && meta.error}*/}
                                    {/*                                />*/}
                                    {/*                                <div style={{position: "absolute"}}>*/}
                                    {/*                            <span style={{*/}
                                    {/*                                color: 'red',*/}
                                    {/*                                fontSize: 12,*/}
                                    {/*                                bottom: 0,*/}
                                    {/*                                left: 2*/}
                                    {/*                            }}>*/}
                                    {/*                                {(meta.touched && meta.error) ? meta.error : null}*/}
                                    {/*                            </span>*/}
                                    {/*                                </div>*/}
                                    {/*                            </>*/}

                                    {/*                        )*/}
                                    {/*                    }*/}
                                    {/*                }*/}
                                    {/*            </Field>*/}

                                    {/*        </div>*/}

                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}

                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                    {/*      style={{marginBottom: 15}}>*/}
                                    {/*    <Grid item xs={4}>*/}
                                    {/*        <label htmlFor="membership"*/}
                                    {/*               style={{fontWeight: "bold", fontSize: 14}}> Membership:</label>*/}
                                    {/*    </Grid>*/}

                                    {/*    <Grid item xs={8}>*/}
                                    {/*        <div style={{position: 'relative'}}>*/}
                                    {/*            <Field name={'membership'}>*/}
                                    {/*                {(props: { field: any; form: any; meta: any; }) => {*/}
                                    {/*                    const {field, form, meta} = props;*/}
                                    {/*                    return (*/}
                                    {/*                        <>*/}
                                    {/*                            <AutoCompleteInput type={'text'}*/}
                                    {/*                                               id={'membership'} {...field}*/}
                                    {/*                                               size={"small"}*/}
                                    {/*                                               onChange={(e, value) => form.setFieldValue("membership", value)}*/}
                                    {/*                                               loading={!membershipData}*/}
                                    {/*                                               options={membershipData ? membershipData.data : []}*/}
                                    {/*                                               getOptionLabel={option => option.membershipName}*/}
                                    {/*                                               error={meta.touched && meta.error}*/}
                                    {/*                            />*/}
                                    {/*                            <ErrorLabel meta={meta}/>*/}
                                    {/*                        </>*/}
                                    {/*                    )*/}
                                    {/*                }}*/}
                                    {/*            </Field>*/}

                                    {/*        </div>*/}
                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="maxAllowedDiscount" style={{fontWeight: "bold", fontSize: 14}}>
                                                Max Allowed Discount(%):<RequiredNotation/>
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'maxAllowedDiscount'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'number'}
                                                                                id={'maxAllowedDiscount'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                            <span style={{
                                                color: 'red',
                                                fontSize: 12,
                                                bottom: 0,
                                                left: 2
                                            }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>


                                    {/*<Grid container item sm={6} alignItems={'flex-start'} spacing={2} style={{marginBottom: 15}}>*/}
                                    {/*</Grid>*/}

                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                    {/*      style={{marginBottom: 15}}>*/}
                                    {/*    <Grid item xs={4}>*/}
                                    {/*        <label htmlFor="type1" style={{fontWeight: "bold", fontSize: 14}}>Type*/}
                                    {/*            :</label>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={8}>*/}
                                    {/*        <div style={{position: 'relative'}}>*/}
                                    {/*            <Field name={'type1'}>*/}
                                    {/*                {*/}
                                    {/*                    (props: { field: any; form: any; meta: any; }) => {*/}

                                    {/*                        const {field, form, meta} = props;*/}

                                    {/*                        return (*/}
                                    {/*                            <>*/}
                                    {/*                                <InputField type={'text'} id={'type1'} {...field}*/}
                                    {/*                                            size={"small"}*/}
                                    {/*                                            error={meta.touched && meta.error}*/}
                                    {/*                                />*/}
                                    {/*                                <div style={{position: "absolute"}}>*/}
                                    {/*                            <span style={{*/}
                                    {/*                                color: 'red',*/}
                                    {/*                                fontSize: 12,*/}
                                    {/*                                bottom: 0,*/}
                                    {/*                                left: 2*/}
                                    {/*                            }}>*/}
                                    {/*                                {(meta.touched && meta.error) ? meta.error : null}*/}
                                    {/*                            </span>*/}
                                    {/*                                </div>*/}
                                    {/*                            </>*/}

                                    {/*                        )*/}
                                    {/*                    }*/}
                                    {/*                }*/}
                                    {/*            </Field>*/}

                                    {/*        </div>*/}

                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}


                                    {/*<Grid container item sm={6} alignItems={'flex-start'} spacing={2} style={{marginBottom: 15}}>*/}
                                    {/*</Grid>*/}

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="city" style={{fontWeight: "bold", fontSize: 14}}> Address:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'address.city'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'} id={'city'} {...field}
                                                                                size={"small"}

                                                                                onChange={(e: any) => {
                                                                                    form.setFieldValue("address.city", e.target.value);
                                                                                }}

                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'flex-start'} spacing={2}
                                          style={{marginBottom: 15}}>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'flex-start'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4} style={{marginTop: 5}}>
                                            <label htmlFor="description"
                                                   style={{fontWeight: "bold", fontSize: 14}}>Description:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'description'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'description'} {...field}
                                                                                size={"small"}
                                                                                multiline={true}
                                                                                rows={3}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>




                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                    {/*      style={{marginBottom: 15}}>*/}
                                    {/*    <Grid item xs={4}>*/}
                                    {/*        <label htmlFor="provinceOrState"*/}
                                    {/*               style={{fontWeight: "bold", fontSize: 14}}> Province:</label>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={8}>*/}
                                    {/*        <div style={{position: 'relative'}}>*/}
                                    {/*            <Field name={'address.provinceOrState'}*/}
                                    {/*                   validate={validateProvince}*/}
                                    {/*            >*/}
                                    {/*                {*/}
                                    {/*                    (props: { field: any; form: any; meta: any; }) => {*/}

                                    {/*                        const {field, form, meta} = props;*/}


                                    {/*                        return (*/}
                                    {/*                            <>*/}
                                    {/*                                <AutoCompleteInput type={'text'}*/}
                                    {/*                                                   id={'provinceOrState'} {...field}*/}
                                    {/*                                                   size={"small"}*/}
                                    {/*                                                   onChange={(e, value) => {*/}
                                    {/*                                                       if (value === null) {*/}
                                    {/*                                                           form.setFieldValue('address.district', null)*/}
                                    {/*                                                           form.setFieldValue('address.municipalityOrVdc', null)*/}
                                    {/*                                                           form.setFieldValue('address.city', null)*/}
                                    {/*                                                           form.setFieldValue('address.wardNumber', null)*/}
                                    {/*                                                       }*/}
                                    {/*                                                       form.setFieldValue("address.provinceOrState", value)*/}
                                    {/*                                                   }}*/}
                                    {/*                                                   options={provinceOptions}*/}
                                    {/*                                                   getOptionLabel={option => option.province}*/}
                                    {/*                                                   error={meta.touched && meta.error}*/}
                                    {/*                                />*/}
                                    {/*                                <div style={{position: "absolute"}}>*/}
                                    {/*                    <span style={{*/}
                                    {/*                        color: 'red',*/}
                                    {/*                        fontSize: 12,*/}
                                    {/*                        bottom: 0,*/}
                                    {/*                        left: 2*/}
                                    {/*                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>*/}
                                    {/*                                </div>*/}
                                    {/*                            </>*/}

                                    {/*                        )*/}
                                    {/*                    }*/}
                                    {/*                }*/}
                                    {/*            </Field>*/}

                                    {/*        </div>*/}

                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}

                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                    {/*      style={{marginBottom: 15}}>*/}
                                    {/*    <Grid item xs={4}>*/}
                                    {/*        <label htmlFor="district"*/}
                                    {/*               style={{fontWeight: "bold", fontSize: 14}}> District:</label>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={8}>*/}
                                    {/*        <div style={{position: 'relative'}}>*/}
                                    {/*            <Field name={'address.district'}*/}

                                    {/*            >*/}
                                    {/*                {*/}
                                    {/*                    (props: { field: any; form: any; meta: any; }) => {*/}

                                    {/*                        const {field, form, meta} = props;*/}

                                    {/*                        return (*/}
                                    {/*                            <>*/}
                                    {/*                                <AutoCompleteInput type={'text'}*/}
                                    {/*                                                   id={'district'} {...field}*/}
                                    {/*                                                   size={"small"}*/}
                                    {/*                                                   onChange={(e, value) => {*/}
                                    {/*                                                       if (value === null) {*/}
                                    {/*                                                           form.setFieldValue('address.municipalityOrVdc', null)*/}
                                    {/*                                                           form.setFieldValue('address.wardNumber', null)*/}
                                    {/*                                                           form.setFieldValue('address.city', null)*/}
                                    {/*                                                       } else {*/}
                                    {/*                                                           handleSelectedDistrict(value)*/}

                                    {/*                                                       }*/}
                                    {/*                                                       form.setFieldValue("address.district", value)*/}
                                    {/*                                                   }}*/}
                                    {/*                                                   options={districtOptions}*/}
                                    {/*                                                   getOptionLabel={option => option.district}*/}
                                    {/*                                                   error={meta.touched && meta.error}*/}
                                    {/*                                />*/}
                                    {/*                                <div style={{position: "absolute"}}>*/}

                                    {/*                    <span*/}
                                    {/*                        style={{*/}
                                    {/*                            color: 'red',*/}
                                    {/*                            fontSize: 12,*/}
                                    {/*                            bottom: 0,*/}
                                    {/*                            left: 2*/}
                                    {/*                        }}*/}
                                    {/*                    >*/}
                                    {/*                        {(meta.touched && meta.error) ? meta.error : null}*/}
                                    {/*                    </span>*/}

                                    {/*                                </div>*/}
                                    {/*                            </>*/}

                                    {/*                        )*/}
                                    {/*                    }*/}
                                    {/*                }*/}
                                    {/*            </Field>*/}

                                    {/*        </div>*/}

                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}


                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                    {/*      style={{marginBottom: 15}}>*/}
                                    {/*    <Grid item xs={4}>*/}
                                    {/*        <label htmlFor="municipalityOrVdc"*/}
                                    {/*               style={{fontWeight: "bold", fontSize: 14}}> Municipality/*/}
                                    {/*            Vdc:</label>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={8}>*/}
                                    {/*        <div style={{position: 'relative'}}>*/}
                                    {/*            <Field name={'address.municipalityOrVdc'}*/}
                                    {/*                   validate={validateMunicipality}>*/}
                                    {/*                {*/}
                                    {/*                    (props: { field: any; form: any; meta: any; }) => {*/}

                                    {/*                        const {field, form, meta} = props;*/}

                                    {/*                        return (*/}
                                    {/*                            <>*/}
                                    {/*                                <AutoCompleteInput type={'text'}*/}
                                    {/*                                                   id={'municipalityOrVdc'} {...field}*/}
                                    {/*                                                   size={"small"}*/}
                                    {/*                                                   onChange={(e, value) => {*/}
                                    {/*                                                       if (value === null) {*/}
                                    {/*                                                           form.setFieldValue('address.wardNumber', null)*/}
                                    {/*                                                       }*/}
                                    {/*                                                       form.setFieldValue("address.municipalityOrVdc", value)*/}
                                    {/*                                                   }}*/}
                                    {/*                                                   loading={vdcLoading}*/}
                                    {/*                                                   options={vdcOptions}*/}
                                    {/*                                                   getOptionLabel={option => option.municipalityOrVdcName}*/}
                                    {/*                                                   error={meta.touched && meta.error}*/}
                                    {/*                                />*/}
                                    {/*                                <div style={{position: "absolute"}}>*/}
                                    {/*                    <span style={{*/}
                                    {/*                        color: 'red',*/}
                                    {/*                        fontSize: 12,*/}
                                    {/*                        bottom: 0,*/}
                                    {/*                        left: 2*/}
                                    {/*                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>*/}
                                    {/*                                </div>*/}
                                    {/*                            </>*/}

                                    {/*                        )*/}
                                    {/*                    }*/}
                                    {/*                }*/}
                                    {/*            </Field>*/}

                                    {/*        </div>*/}

                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}

                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                    {/*      style={{marginBottom: 15}}>*/}
                                    {/*    <Grid item xs={4}>*/}
                                    {/*        <label htmlFor="wardNumber" style={{fontWeight: "bold", fontSize: 14}}> Ward*/}
                                    {/*            No.:</label>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={8}>*/}
                                    {/*        <div style={{position: 'relative'}}>*/}
                                    {/*            <Field name={'address.wardNumber'}>*/}
                                    {/*                {*/}
                                    {/*                    (props: { field: any; form: any; meta: any; }) => {*/}

                                    {/*                        const {field, form, meta} = props;*/}

                                    {/*                        return (*/}
                                    {/*                            <>*/}
                                    {/*                                <AutoCompleteInput type={'text'}*/}
                                    {/*                                                   id={'wardNumber'} {...field}*/}
                                    {/*                                                   size={"small"}*/}
                                    {/*                                                   onChange={(e, value) => form.setFieldValue("address.wardNumber", value)}*/}
                                    {/*                                                   options={wardOptions}*/}
                                    {/*                                                   getOptionLabel={option => option}*/}
                                    {/*                                                   error={meta.touched && meta.error}*/}
                                    {/*                                />*/}
                                    {/*                                <div style={{position: "absolute"}}>*/}
                                    {/*                    <span style={{*/}
                                    {/*                        color: 'red',*/}
                                    {/*                        fontSize: 12,*/}
                                    {/*                        bottom: 0,*/}
                                    {/*                        left: 2*/}
                                    {/*                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>*/}
                                    {/*                                </div>*/}
                                    {/*                            </>*/}

                                    {/*                        )*/}
                                    {/*                    }*/}
                                    {/*                }*/}
                                    {/*            </Field>*/}

                                    {/*        </div>*/}

                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}



                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                    {/*      style={{marginBottom: 15}}>*/}
                                    {/*    <Grid item xs={4}>*/}
                                    {/*        <label htmlFor="googleMapLocationUrl"*/}
                                    {/*               style={{fontWeight: "bold", fontSize: 14}}> Google*/}
                                    {/*            Map URL:</label>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={8}>*/}
                                    {/*        <div style={{position: 'relative'}}>*/}
                                    {/*            <Field name={'address.googleMapLocationUrl'}>*/}
                                    {/*                {*/}
                                    {/*                    (props: { field: any; form: any; meta: any; }) => {*/}

                                    {/*                        const {field, form, meta} = props;*/}

                                    {/*                        return (*/}
                                    {/*                            <>*/}
                                    {/*                                <InputField type={'text'}*/}
                                    {/*                                            id={'address.googleMapLocationUrl'} {...field}*/}
                                    {/*                                            size={"small"}*/}

                                    {/*                                            onChange={(e: any, value: any) => {*/}
                                    {/*                                                if (value === null) {*/}

                                    {/*                                                }*/}
                                    {/*                                                form.setFieldValue("address.googleMapLocationUrl", value)*/}
                                    {/*                                            }}*/}

                                    {/*                                            error={meta.touched && meta.error}*/}
                                    {/*                                />*/}
                                    {/*                                <div style={{position: "absolute"}}>*/}
                                    {/*                <span style={{*/}
                                    {/*                    color: 'red',*/}
                                    {/*                    fontSize: 12,*/}
                                    {/*                    bottom: 0,*/}
                                    {/*                    left: 2*/}
                                    {/*                }}>{(meta.touched && meta.error) ? meta.error : null}</span>*/}
                                    {/*                                </div>*/}
                                    {/*                            </>*/}

                                    {/*                        )*/}
                                    {/*                    }*/}
                                    {/*                }*/}
                                    {/*            </Field>*/}

                                    {/*        </div>*/}

                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}


                                </Grid>

                                <FormSaveButton mode={Props.mode} from={Props.from} handleClose={Props.handleClose}
                                                noFixed={Props.noFixed}/>

                            </Form>

                        </div>
                    )
                }}

            </Formik>

        </div>
    )

}

export default CustomerForm;
