import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../reactTable/useServerCall";
import useSWR from "swr";
import DeleteModal from "../../deleteModal";
import ReactTable from "../../reactTable/ReactTable";
import DataEmptyMessage from "../dataEmptyMessage";
import AccessFilter from "../../../../../accessControl/accessFilter";
import DeleteIcon from "@material-ui/icons/Delete";

const MembershipTable = () => {

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const [deleteId, setDeleteId] = useState(null)

    const history = useHistory();

    const branchColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' style={{marginRight: 15, paddingRight: 15}}>

                    <AccessFilter

                        section={"uom"}
                        privilege={"delete"}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed) return null

                            return (

                                <Grid item xs={6}>
                                    <IconButton size={"small"} style={{color: '#ec4949'}} onClick={() => setDeleteId(val.row.original.id)}>
                                        <DeleteIcon fontSize={"small"}/>
                                    </IconButton>
                                </Grid>

                            )

                        }}

                    />

                    <AccessFilter

                        section={"uom"}
                        privilege={"update"}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed) return null

                            return (

                                <Grid item xs={6}>
                                    <IconButton size={"small"} style={{color: '#4aca53'}}
                                                onClick={() => {
                                                    history.push(`/dashboard/sales/membership/edit/${val.row.original.id}`, val.row.original)
                                                }}
                                    >
                                        <EditIcon fontSize={"small"}/>
                                    </IconButton>
                                </Grid>
                            )

                        }}

                    />

                </Grid>
            )
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'membershipName'
        },
        {
            Header: 'Description',
            Footer: 'Description',
            accessor: 'description'
        },


    ]

    const path = '/membership'

    const {data} = useSWR(`${path}`);


    // const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}`)


    // @ts-ignore
    const calledData = data ? data.data : [];

    const mockData = useMemo(() => calledData, [data]);

    const columns = useMemo(() => branchColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div>
                    <DeleteModal message={`Are you sure? Once deleted it cannot be reverted.`}
                                 trigger={`/membership`}
                                 deletePath={`/membership/${deleteId}`} open={deleteId}
                                 handleDeleteCancel={() => setDeleteId(null)}
                    />
                    <ReactTable
                        data={mockData}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        perPageOption={[30,50,100]}
                        totalPage={1}
                    />
                </div>
            ): (
                (page === 1 && data?.data.length === 0) ? null:    <DataEmptyMessage/>
            )}
        </div>
    );
};

export default MembershipTable;
