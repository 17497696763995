import {Route} from "react-router-dom";
import {motion} from "framer-motion";
import AccessFilter from "../../../accessControl/accessFilter";
import AccessDenied from "../../../components/accounting/templates/errorTemplates/accessDenied";
import React from "react";
import MonthlyVATReturn from "../../../components/VATFiling/virtualPages/monthlyVATReturn";
import MonthlyTds from "../../../components/VATFiling/virtualPages/monthlyTds";

export const VatManagement = [
    <Route path={'/dashboard/vatManagement/vat'} exact>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"vat"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <MonthlyVATReturn/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/vatManagement/tds'} exact>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"vat"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <MonthlyTds/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
]
