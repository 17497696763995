import React, {useCallback, useEffect, useMemo, useState} from 'react';
import * as Yup from "yup";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Grid, Popper} from "@material-ui/core";
import InputField from "../../../../shared/atom/formElements/inputField";
import {Column} from "react-table";
import FormSaveButton from "../../FormSaveButton";
import AddIcon from '@material-ui/icons/Add';
import ToolTip from "../../../../shared/atom/tooltip";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import useSWR, {trigger} from "swr";
import VoucherTableForm from "../voucherTableForm";
import axiosInstance from "../../../../../config/axiosConfig";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import {matchSorter} from 'match-sorter';
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs, convertBsToAd} from "../../../../../config/dateFunctions";
import DialogComponent from "../../../../shared/atom/dialog";
import AccountHeadForm from "../accountHeadForm";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {FaCalendarAlt} from 'react-icons/fa'
import RequiredNotation from "../../../molecules/requiredNotation";
import DateComponent from "../../../../product/molecules/dateComponent";
import {isTransactionDateValid} from "../../../../../config/fiscalYear";
import {RootState} from "../../../../../store/RootReducer";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface dataI {
    id: number,
    debit: number,
    credit: number
}

const JournalVoucherForm: React.FC<any> = (Props: any) => {
    const initialValues = {
        journalEntryId: '',
        transactionYearAd: null,
        transactionMonthAd: null,
        transactionDateAd: null,
        transactionYearBs: null,
        transactionMonthBs: null,
        transactionDateBs: null,
        voucherId: '',
        costCenter: '',
        description: '',
        document: [],
        tableRow: [
            {id: 1, accountHead: null, description: '', debit: 0, credit: 0},
            {id: 2, accountHead: null, description: '', debit: 0, credit: 0}
        ],
    }
    const [openDialog, setOpenDialog] = useState(false)

    const location = useLocation();

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths,nepEngDates } = useAdAndBsHandler();


    useEffect(() => {
        if (!formatedNepEngDates) return;
        if (Props.id) {

            // @ts-ignore
            const {voucherNumber, description, journalRows, receiptNumber, costCenter, transactionDate} = location.state
            let adDate = transactionDate.split('-');
            let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

            // @ts-ignore
            let tempJson = {
                transactionYearAd: adDate[0],
                transactionDateAd: adDate[2],
                transactionMonthAd: adDate[1],
                transactionDateBs: bsDate[2],
                transactionMonthBs: bsDate[1],
                transactionYearBs: bsDate[0],
            }

            // @ts-ignore
            setFormState({

                ...tempJson,
                journalEntryId: voucherNumber,
                voucherId: receiptNumber,
                costCenter: costCenter,
                description: description,
                document: [],
                tableRow: journalRows.map((item: any, index: number) => {
                    return {
                        id: index + 1,
                        accountHead: item.accountHead,
                        description: item.description,
                        debit: item.type === 'debit' ? item.amount : 0,
                        credit: item.type === 'credit' ? item.amount : 0,

                    }
                })

            })
        }


    }, [formatedNepEngDates])


    const convert = (transactionDate: any) => {

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            transactionYearAd: adDate[0],
            transactionDateAd: adDate[2],
            transactionMonthAd: adDate[1],
            transactionDateBs: bsDate[2],
            transactionMonthBs: bsDate[1],
            transactionYearBs: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }

    useEffect(() => {

        if (Props.id) return;
        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])

    }, [formatedNepEngDates])

    const [formState, setFormState] = useState(initialValues)
    const {data: journalVoucherCode} = useSWR(`/getCode?name=journalVoucher&id=0`)

    useEffect(() => {

        if (Props.id) return;
        if (journalVoucherCode) setFormState(prevState => {
            return {
                ...prevState,
                journalEntryId: journalVoucherCode.t.code
            }
        })

    }, [journalVoucherCode])

    const validationSchema = Yup.object({})

    const {data: costCenterOptions} = useSWR('/getCostCenter')

    const dispatch = useDispatch();
    const dashboard = useSelector((state: RootState) => state.dashboard);


    const onSubmit = async (values: any, other: any) => {


        try {

        let isReset = values.action === 'submitAndAdd';

        let transactionDate = values.transactionYearAd + '-' + ('0' + values.transactionMonthAd).slice(-2) + '-' + ('0' + values.transactionDateAd).slice(-2);
        let transactionDateBs = values.transactionYearBs + '-' + ('0' + values.transactionMonthBs).slice(-2) + '-' + ('0' + values.transactionDateBs).slice(-2);


        // if(!isTransactionDateValid(nepEngDates.data, dashboard.fiscalYear,transactionDateBs)){
        //     dispatch({
        //         type: 'OPEN_SNACKBAR',
        //         payload: {message: `Transaction is not of running fiscal year.`, type: 'warning'}
        //     })
        //     return;
        // }

        if (values.tableRow.filter((item: any) => (item.accountHead !== null) && (item.credit !== 0) && (item.debit !== 0)).length > 0) {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `Entry with both credit and debit is present.`, type: 'warning'}
            })
            return;
        }

        if (!(values.transactionDateAd && values.transactionMonthAd && values.transactionYearAd)) {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `Transaction Date is required,`, type: 'warning'}
            })
            return;
        }
        if (values.tableRow.filter((item: any) => item.accountHead !== null).length < 2) {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `Enter more than one row.`, type: 'warning'}
            })
            return;
        }

        let sum = values.tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit) - parseFloat(item.credit))
        }, 0.00)

        if (sum !== 0.00) {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `Balance should be 0.`, type: 'warning'}
            })
            return;
        }

        const payload = {
            voucherNumber: values.journalEntryId,
            receiptNumber: values.voucherId,
            type: "journal",
            journalRows: values.tableRow.filter((item: any) => item.accountHead !== null).map((item: any, index: number) => {

                let editId= {};

                //add id of each index of productSubs to each product sub in the new list of product that need to be sent to the server
                if(Props.mode === 'edit'){
                    // @ts-ignore
                    if(location.state.journalRows.length >= index + 1 ){
                        // @ts-ignore
                        editId = {id: location.state.journalRows[index].id}
                    }
                }

                const isCredit = item.credit > 0 && item.debit === 0;

                return {
                    ...editId,
                    type: isCredit ? 'credit' : 'debit',
                    amount: isCredit ? item.credit : item.debit,
                    accountHead: {
                        id: item.accountHead?.id
                    },
                    accountGroupId: item.accountHead.accgroupid,
                    subTypeId: item.accountHead.accountsubtypeid,
                    description: item.description
                }
            }),
            transactionDate: transactionDate,
            costCenter: values.costCenter ? {
                id: values.costCenter?.id
            } : null,
            description: values.description
        }


        try {

            if (Props.id) {

                // @ts-ignore
                let {journalRows} = location.state;

                let newPayload = payload.journalRows.map((item: any) => {

                    if(!journalRows.find((itemRow: any) => itemRow.accountHead.id === item.accountHead.id)) return {...item};

                    let filtered = journalRows.find((rowItem: any) => rowItem.accountHead.id === item.accountHead.id)

                    return {
                        ...item,
                        subTypeId: filtered.subTypeId,
                        accountGroupId: filtered.accountGroupId,
                        // id: filtered.id
                    }
                })


                // return;
                const {data} = await axiosInstance.put('/updateJournalEntry', {
                    ...payload,
                    journalRows: newPayload,
                    id: parseInt(Props.id)
                })

                handleResponse(data, other, values, isReset)
            } else {
                const {data} = await axiosInstance.post('/addJournalEntry', payload)
                handleResponse(data, other, values, isReset)
            }

            other.setSubmitting(false);

        } catch (e) {


            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {
                    message: `${e.response.data.message ? e.response.data.message : 'Something went wrong.'}`,
                    type: 'warning'
                }
            })

            other.setSubmitting(false);

        }

        }catch (e) {

            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {
                    message: `${e.response.data.message ? e.response.data.message : 'Something went wrong.'}`,
                    type: 'warning'
                }
            })

            other.setSubmitting(false);
        }


    }

    const history = useHistory();

    const handleResponse = (data: any, other: any, values: any, isReset: boolean) => {

        if (data?.code === 'OK') {
            if (data?.message === "Journal voucher added" || data?.message === "Journal's information updated.") {
                dispatch({
                    type: 'OPEN_SNACKBAR',
                    payload: {
                        message: `Voucher with entry : ${values.journalEntryId} ${Props.id ? 'edited' : 'created'}.`,
                        type: 'success'
                    }
                })

                if (!isReset) {
                    history.replace('/dashboard/voucher-entries/journal-voucher');
                } else {
                    other.resetForm();
                    trigger('/getCode?name=journalVoucher&id=0')
                    const newDate = new Date().toISOString();
                    // @ts-ignore
                    convert(newDate.split('T')[0])
                }

            } else {
                dispatch({
                    type: 'OPEN_SNACKBAR',
                    payload: {
                        message: `${data?.message ? data.message : 'Something went wrong'} `,
                        type: 'warning'
                    }
                })
            }

        } else {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: ` Something went wrong! Please try again `, type: 'warning'}
            })

        }
    }


    const handleRemove = (index: number, formikInstance: any) => {
        const {values: {tableRow}} = formikInstance;
        let tempTable = [...tableRow].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue('tableRow', [...tempTable])
    }


    const addNew = useCallback((formikInstance: any) => {

        const {values: {tableRow}} = formikInstance;
        //console.log(tableRow);
        let tempArr = {
            id: tableRow[tableRow.length - 1].id + 1,
            accountHead: null,
            description: '',
            debit: 0,
            credit: 0
        }

        tableRow.push(tempArr)

        formikInstance.setFieldValue('tableRow', [...tableRow])


    }, [formState])

    useEffect(() => {

        //console.log(formState)

    }, [formState])


    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const styleLib = {
        label: {
            color: COLOR_PROFILE.primaryColor,
            fontWeight: 600,
            fontSize: 14
        }

    }


    const handleDateBsBlur = (form: any) => {

        const {transactionYearBs, transactionMonthBs, transactionDateBs} = form.values
        const dateInAd = convertBsToAd(transactionYearBs + '-' + transactionMonthBs + '-' + transactionDateBs, formatedNepEngDates);

        // @ts-ignore
        if (!dateInAd) return;

        // @ts-ignore
        let deconstructedDate = dateInAd?.split('T')[0]?.split('-');
        // @ts-ignore
        form.setFieldValue(`transactionYearAd`, deconstructedDate[0])
        // @ts-ignore
        form.setFieldValue(`transactionMonthAd`, parseInt(deconstructedDate[1]).toString())
        // @ts-ignore
        form.setFieldValue(`transactionDateAd`, parseInt(deconstructedDate[2]).toString())


    }

    const handleDateAdBlur = (form: any) => {

        const {transactionYearAd, transactionMonthAd, transactionDateAd} = form.values
        const dateInBs = convertAdToBs(transactionYearAd + '-' + transactionMonthAd + '-' + transactionDateAd, formatedNepEngDates);

        // @ts-ignore
        if (!dateInBs) return;

        // @ts-ignore
        form.setFieldValue(`transactionYearBs`, dateInBs[0])
        // @ts-ignore
        form.setFieldValue(`transactionMonthBs`, parseInt(dateInBs[1]).toString())
        // @ts-ignore
        form.setFieldValue(`transactionDateBs`, parseInt(dateInBs[2]).toString())


    }

    return (
        <div>
            <div>
                <DialogComponent open={openDialog} handleClose={setOpenDialog} title={'Add Account Head'}
                                 maxWidth={'sm'}>
                    <AccountHeadForm mode={'create'} from={'journal page'} handleClose={() => setOpenDialog(false)}
                                     noFixed={true}
                    />
                </DialogComponent>
            </div>
            <Formik

                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {

                    return (
                        <div>
                            <PagePrompt formik={formik}/>

                            <Form
                                // style={{margin: 20}}
                                onKeyDown={onKeyDown}

                            >

                                <Grid container justify={"space-between"}>
                                    <Grid item xs={12} container justify={"space-between"}
                                          style={{margin: '10px 0px 5px 0px '}}>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 10}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="journalEntryId"
                                                       style={styleLib.label}
                                                >
                                                    Journal Entry Id:
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <strong>  {formState.journalEntryId}</strong>
                                            </Grid>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 10}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="transactionDate"
                                                       style={styleLib.label}
                                                >
                                                    Transaction
                                                    Date:
                                                    <RequiredNotation/>
                                                </label>
                                            </Grid>



                                            <Grid item container xs={8} alignItems={'center'}>

                                                <DateComponent
                                                    from={"transaction"}
                                                    ad={['transactionYearAd', 'transactionMonthAd', 'transactionDateAd']}
                                                    bs={['transactionYearBs', 'transactionMonthBs', 'transactionDateBs']}
                                                />


                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 10}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="voucherId"
                                                       style={styleLib.label}> Voucher ID:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'voucherId'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'voucherId'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 10}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="transactionDate"
                                                       style={styleLib.label}> Document:</label>
                                            </Grid>
                                            <Grid item xs={8} style={{fontWeight: 'bold'}}>
                                                <u>Upload</u>
                                            </Grid>
                                        </Grid>

                                        {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                        {/*      style={{marginBottom: 10}}>*/}
                                        {/*    <Grid item xs={4}>*/}
                                        {/*        <label htmlFor="costCenter"*/}
                                        {/*               style={styleLib.label}> Cost Center:</label>*/}
                                        {/*    </Grid>*/}
                                        {/*    <Grid item xs={8}>*/}
                                        {/*        <div style={{position: 'relative'}}>*/}
                                        {/*            <Field name={'costCenter'}>*/}
                                        {/*                {*/}
                                        {/*                    (props: { field: any; form: any; meta: any; }) => {*/}

                                        {/*                        const {field, form, meta} = props;*/}


                                        {/*                        return (*/}
                                        {/*                            <>*/}
                                        {/*                                <AutoCompleteInput type={'text'}*/}
                                        {/*                                                   id={`costCenter`} {...field}*/}
                                        {/*                                                   size={"small"}*/}

                                        {/*                                                   autoHighlight={true}*/}
                                        {/*                                                   autoSelect={true}*/}
                                        {/*                                                   onChange={(e, value) => {*/}
                                        {/*                                                       form.setFieldValue(`costCenter`, value)*/}
                                        {/*                                                   }}*/}

                                        {/*                                                   options={costCenterOptions ? costCenterOptions.data : []}*/}
                                        {/*                                                   loading={!costCenterOptions}*/}

                                        {/*                                                   getOptionLabel={option => option.name}*/}
                                        {/*                                                   error={meta.touched && meta.error}*/}
                                        {/*                                />*/}
                                        {/*                                <div style={{position: "absolute"}}>*/}
                                        {/*            <span style={{*/}
                                        {/*                color: 'red',*/}
                                        {/*                fontSize: 12,*/}
                                        {/*                bottom: 0,*/}
                                        {/*                left: 2*/}
                                        {/*            }}>{(meta.touched && meta.error) ? meta.error : null}</span>*/}
                                        {/*                                </div>*/}
                                        {/*                            </>*/}

                                        {/*                        )*/}
                                        {/*                    }*/}
                                        {/*                }*/}
                                        {/*            </Field>*/}

                                        {/*        </div>*/}

                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 10}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="description"
                                                       style={styleLib.label}> Description:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'description'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'description'} {...field}
                                                                                    size={"small"}
                                                                                    multiline
                                                                                    rows={3}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}
                                                                        >
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>




                                    </Grid>
                                </Grid>


                                <TableForm handleRemove={handleRemove} addNew={addNew} setOpenDialog={setOpenDialog}
                                           formikInstance={formik}
                                />

                                <div>
                                    <FormSaveButton mode={Props.mode} from={'journal entry'}
                                                    submitting={formik.isSubmitting}/>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )
};


const TableForm: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRow}} = useFormikContext();
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');

    const name = 'tableRow'
    const formik = useFormikContext();


    const getDebit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit))
        }, 0.00)
    }, [tableRow])

    const getCredit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRow])


    const data = useMemo(() => tableRow, [tableRow, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{width: 500}} placement='bottom-start'/>)
    }

    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        {
            id: 'accountHead',
            width: 250,
            Header: 'Account Head',
            accessor: "accountHead",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;


                return (
                    <Field name={`${name}[${index}].accountHead`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {

                                    return  options;

                                    let tempRows = form.values.tableRow;
                                    let selectedHeads = tempRows.filter((item: any) => item.accountHead).map((item: any) => item.accountHead?.id);
                                    if (field.value?.id) selectedHeads.splice(selectedHeads.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedHeads.includes(item.id)) : [];

                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>
                                                <AutoCompleteInput type={'text'}
                                                                   id={`${name}[${index}].accountHead`} {...field}
                                                                   size={"small"}
                                                                   placeholder={'Select Account Head'}
                                                                   filterOptions={(options, {inputValue}) => {


                                                                       let filteredOptions = getOptions(options)

                                                                       return matchSorter(options, inputValue, {keys: ['name', 'code', 'subtypename']}).sort(function(a: any, b: any) {
                                                                           let nameA = a.subtypename.toUpperCase();
                                                                           let nameB = b.subtypename.toUpperCase();
                                                                           if (nameA < nameB) {
                                                                               return -1;
                                                                           }
                                                                           if (nameA > nameB) {
                                                                               return 1;
                                                                           }

                                                                           // names must be equal
                                                                           return 0;
                                                                       });
                                                                   }}
                                                                   onChange={(e, value) => {
                                                                       form.setFieldValue(`${name}[${index}].accountHead`, value)
                                                                   }}
                                                                   hideOutline={true}
                                                                   autoHighlight={true}

                                                                   autoSelect={true}
                                                                   options={accountHeadOptions ?
                                                                       accountHeadOptions.data
                                                                       : []}
                                                                   loading={!accountHeadOptions}
                                                                   variant={'standard'}
                                                                   InputProps={{
                                                                       disableUnderline: true,
                                                                       style: {paddingLeft: 5, paddingRight: 5}
                                                                   }}
                                                                   groupBy={option => option.subtypename}
                                                                   PopperComponent={PopperMy}
                                                                   getOptionLabel={option => option.name}
                                                                   renderOption={(option) => {
                                                                       return (
                                                                           <div>
                                                                               <div style={{
                                                                                   fontSize: 11,
                                                                               }}>
                                                                                   <>{option.code}</>
                                                                               </div>
                                                                               <div>{option.name}</div>
                                                                           </div>
                                                                       )
                                                                   }}
                                                                   error={meta.touched && meta.error}
                                                />
                                            </Grid>
                                            <ToolTip title={'Add new head'}>
                                                <Grid item style={{
                                                    borderLeft: '1px solid #d0d0d0',
                                                    padding: '8px 3px 3px 3px',
                                                    cursor: 'pointer',

                                                }}
                                                      onClick={() => handleDialogOpen()}
                                                >

                                                    <div>

                                                        <AddIcon style={{
                                                            color: COLOR_PROFILE.primaryColor,
                                                            fontWeight: 700,
                                                            fontSize: 20
                                                        }}/>

                                                    </div>
                                                </Grid>
                                            </ToolTip>
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'description',
            Header: 'Description',
            width: 250,

            accessor: "description",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                return (
                    <Field name={`${name}[${index}].description`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'text'} id={`${name}[${index}].description`} {...field}
                                                    size={"small"}
                                                    multiline
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'debit',
            Header: 'Debit Value (Dr)',
            accessor: "debit",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                return (
                    <Field name={`${name}[${index}].debit`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].debit`} {...field}
                                                    size={"small"}
                                                    step=".01"
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'credit',
            Header: 'Credit Value (Cr)',
            accessor: "credit",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].credit`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].credit`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                        </div>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                //console.log(cellObj)

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        //console.log('delete clicked')
        handleRemove(index, formikInstance)
    }

    const getBalance = () => {
        return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }

    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, accountHeadOptions]);


    return (
        <div>

            <div>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} balance={getBalance}
                                  total={{'credit': getCredit, 'debit': getDebit}} type={'journal'}
                                  minLength={2}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )

};

export default JournalVoucherForm;
