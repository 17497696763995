import React, {useState} from 'react';
import SetupAddNew from "../../../organisms/setupAddNew";
import AccountHeadTable from "../../../organisms/tables/accountHead";
import AccessFilter from "../../../../../accessControl/accessFilter";
import TableFilter from "../../../organisms/Forms/tableFilter";

const AccountHeadPage: React.FC = () => {

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})

    return (
        <div>

            <AccessFilter

                section={"accountHead"}
                privilege={'write'}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>


                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{pathTo: '/dashboard/accounting-setup/account-head/add', name: 'Add Account Head'}}
                                        buttonNameImport={isBranchAdmin ? {pathTo: '/importAccountHeads', name: 'Import Account Head from Head Office', refreshPath: '/getAccountHeads/0/30'}: undefined}

                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>
                                                    Add Account Head by clicking the button below. Heads can also be imported from parent
                                                    organization.
                                                </div>

                                            </div>
                                        }

                                    />
                                </div>

                            </div>

                        </div>
                    )

                }}

            />


            <AccessFilter

                section={"accountHead"}
                privilege={'read'}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div style={{maxWidth: 1000}}>

                            <div style={{padding: '0 30px 0 28px'}}>
                                <div style={{marginBottom: -10}}>
                                    <TableFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                                 submitting={submitting}
                                                 from={'accountHead'}
                                    />
                                </div>
                                <AccountHeadTable formState={formState} setSubmitting={setSubmitting}/>

                            </div>
                        </div>
                    )

                }}

            />





        </div>
    );
};

export default AccountHeadPage;
