import React from 'react';
import PurchaseTemplate from "../../../templates/reporting/purchaseTemplate";

const PurchaseReport: React.FC  = () => {
    return (
        <div>
            <PurchaseTemplate/>
        </div>
    );
};

export default PurchaseReport;