import React, {useEffect, useMemo, useState} from 'react';
import useSWR from "swr";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import Grid from "@material-ui/core/Grid";
import LoopIcon from "@material-ui/icons/Loop";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CircularSpinner from "../../../shared/atom/spinner";
import ReportTable from "../../../accounting/organisms/tables/reportTable";
import {recursivelyUpdateTable} from "../../../accounting/virtualPages/reporting/recursiveFunction";

const ProductHierarchy: React.FC = () => {

    const [tableData, setTableData] = useState([]);

    const {data} = useSWR('/getCategoryUptoProducts');

    useEffect(() => {
        if (!data) return
        setTableData(data)
        data.map((item: any) => {
            console.log(item);
        })
    }, [data]);

    const tempColumns = [

        {
            id: 'id',
            Header: '',
            accessor: 'id',
            width: 10,
            Cell: (item: any) => {
                const {row, parentIndex} = item;


                if (row.original.name.includes('Total')) return null

                let id = row.id.split('.').map((item: string) => parseInt(item) + 1);

                return (
                    <span style={{color: COLOR_PROFILE.primaryColor}}>{id.join('.')}</span>
                )
            }
        },
        {
            Header: 'Particulars',
            id: 'expander',
            Cell: (item: any) => {

                const {row, isLoading, isExpanded} = item;
                const {row: {original}} = item;
                const isTotal = original.name.includes('Total');
                const head = original.head;
                const type = false

                const toggleRowExpandedProps = row.getToggleRowExpandedProps();

                const onClick = async (event: any) => {
                    if (!isLoading) {
                        if (!isExpanded) {
                            await handleClickRow(row);
                        }
                        console.log(row.original)
                        toggleRowExpandedProps.onClick(event);
                    }
                }


                return (
                    <Grid container alignItems={"center"}
                          {...row.getToggleRowExpandedProps({
                              style: {
                                  paddingLeft: isTotal ? 0 : `${row.depth}rem`,
                                  paddingTop: head ? 3: 0,
                                  paddingBottom: head ? 3: 0,
                                  cursor: isTotal ? 'default' : "pointer",
                                  marginLeft: isTotal ? '-0.5rem' : "inherit"
                              },
                          })}
                          onClick={!head ? onClick : () => {
                          }}
                    >

                        {(!head && !isTotal) && (<Grid item style={{padding: '3px 0px 0 0 ', color: 'gray'}}>
                            {
                                isLoading ? <LoopIcon style={{fontSize: 20, padding: 2}}/> : (row.isExpanded) ?
                                    <KeyboardArrowDownIcon fontSize={"small"}/> : <ChevronRightIcon fontSize={"small"}/>
                            }
                        </Grid>)}
                        <Grid item style={{paddingLeft: isTotal ? 0 : 5, marginLeft: head ? 5 : 0}}>

                                <span style={{
                                    color: head ? '#50bf47' : type ? COLOR_PROFILE.primaryColor : '#2595b1',
                                    fontSize: isTotal ? 16 : "inherit",
                                }}>{row.original.name} {('(' + row.original.code + ')')}</span>

                        </Grid>


                    </Grid>
                )
            },
        },


    ]


    const columnsExpense = useMemo(() => tempColumns, [tableData]);

    const [isRowLoading, setIsRowLoading] = useState({});

    const handleClickRow = async (item: any) => {
        const {id, original, depth} = item;

        setIsRowLoading({[id]: true});
        console.log('id', id);
        const head = false;

        try {

            let childData: any = tableData

            // if (depth === 0) {
            //     console.log('depth is 0');
            //     childData = original.accountSubTypeDtos;
            // } else {


            let groups = original.child ? [...original.child] : [];

            // @ts-ignore
                let item: any = (original.productDtos ) ? [...original.productDtos.map((item: any) => {
                    return {...item, head: true}
                })] : [];
                childData = [...groups,...item]
            console.log('child data', childData)


            setIsRowLoading({[id]: false})


            if (tableData) {

                // @ts-ignore
                const updatedTableData: [] = recursivelyUpdateTable({tableData, childData, id});

                console.log('update table data', updatedTableData);
                // @ts-ignore
                setTableData([...updatedTableData]);
            }
        } catch (e) {
            setIsRowLoading({[id]: false})
        }

    }

    return (
        <div>

            <div
                style={{
                    padding: '10px 0 20px 20px',
                    fontWeight: 700,
                    fontSize: 18,
                    color: COLOR_PROFILE.primaryColor
                }}
            >
                Product Hierarchy:
            </div>

            <div style={{paddingLeft: 20}}>
                {!data && <CircularSpinner circleColor={COLOR_PROFILE.buttonBlue}/>}
            </div>

            {tableData && (
                <div>
                    <ReportTable columns={columnsExpense}
                                 data={tableData}
                                 type={'journal'}
                                 reportType={"temp"}
                                 isChartOfAccount={true}
                                 isRowLoading={isRowLoading}
                                 withOutHeadBackground={false}
                    />
                </div>
            )}

        </div>
    );
};

export default ProductHierarchy;
