import React from 'react';
import {Prompt} from "react-router-dom";



const PagePrompt: React.FC<any> = (Props) => {

    const {formik} = Props

    return (
        <div>
            {/*<Prompt*/}
            {/*    when={Object.keys(formik.touched).length > 0}*/}
            {/*    message={"All the values of the form will be lost. Are you sure you?"}*/}
            {/*/>*/}
        </div>
    );
};

export default PagePrompt;