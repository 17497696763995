/* eslint-disable */
const numberToWords = (
    price: number,
    tl = (a: unknown) => a,
    withCurrencyLabel = false
) => {
    const sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine"
    ];
    const dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen"
    ];
    const tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety"
    ];
    const handle_tens = function (dgt: any, prevDgt: any) {
        return 0 == dgt
            ? ""
            : " " + (1 == dgt ? tl(dblDigit[prevDgt]) : tl(tensPlace[dgt]));
    };
    const handle_utlc = function (dgt: any, nxtDgt: any, denom: any) {
        return (
            (0 != dgt && 1 != nxtDgt ? " " + tl(sglDigit[dgt]) : "") +
            (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
    };

    let str = "",
        digitIdx = 0,
        digit = 0,
        nxtDigit = 0,
        words = [];

    // @ts-ignore
    price += "";
    // @ts-ignore
    if (isNaN(parseInt(price))) return "";

    // @ts-ignore
    if (parseInt(price) === 0) return `${tl("Zero")} ${tl("rupees")}`;

    // @ts-ignore
    const priceBrokenDown = price.split(".");
    price = priceBrokenDown[0];
    let decimalPrice = priceBrokenDown[1];

    // @ts-ignore
    if (parseInt(price) > 0 && price.length <= 10) {
        // @ts-ignore
        for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
            switch (
                // @ts-ignore
                ((digit = price[digitIdx] - 0),
                    // @ts-ignore
                    (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
                    // @ts-ignore
                price.length - digitIdx - 1)
                ) {
                case 0:
                    words.push(handle_utlc(digit, nxtDigit, ""));
                    break;
                case 1:
                    // @ts-ignore
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 2:
                    words.push(
                        0 != digit
                            ? " " +
                            tl(sglDigit[digit]) +
                            ` ${tl("Hundred")}` +
                            // @ts-ignore
                            (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                                ? ` ${tl("And")}`
                                : "")
                            : ""
                    );
                    break;
                case 3:
                    words.push(handle_utlc(digit, nxtDigit, tl("Thousand")));
                    break;
                case 4:
                    // @ts-ignore
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 5:
                    words.push(handle_utlc(digit, nxtDigit, tl("Lakh")));
                    break;
                case 6:
                    // @ts-ignore
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 7:
                    words.push(handle_utlc(digit, nxtDigit, tl("Crore")));
                    break;
                case 8:
                    // @ts-ignore
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 9:
                    words.push(
                        0 != digit
                            ? " " +
                            sglDigit[digit] +
                            ` ${tl("Hundred")}` +
                            // @ts-ignore
                            (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                                ? ` ${tl("And")}`
                                : ` ${tl("Crore")}`)
                            : ""
                    );
            }
        str = words
            .reverse()
            .join("")
            .concat(withCurrencyLabel ? ` ${tl("rupees")}` : "");
    }
    if (parseInt(decimalPrice) > 0 && decimalPrice.length) {
        decimalPrice = decimalPrice.padEnd(2, 0);
        str += ` ${tl("And")}  ${numberToWords(
            Number(decimalPrice),
            undefined,
            false
        )} ${tl("paisa")}`;
    }
    return str;
};

export default numberToWords;
