import React from 'react';
import PurchaseReturnHome from "../../templates/purchaseReturnHome";

const PurchaseReturn = () => {
    return (
        <div>
            <PurchaseReturnHome/>
        </div>
    );
};

export default PurchaseReturn;