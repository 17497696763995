import React from 'react';
import PurchaseRfpAddition from "../../templates/purchaseRfpAddition";

const PurchaseRfpAdd = () => {
    return (
        <div>
            <PurchaseRfpAddition/>
        </div>
    );
};

export default PurchaseRfpAdd;