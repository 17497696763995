import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import axiosInstance from "../../../../../config/axiosConfig";
import ReportTable from "../../../organisms/tables/reportTable";
import {recursivelyUpdateTable} from "../../../virtualPages/reporting/recursiveFunction";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import Grid from "@material-ui/core/Grid";
import LoopIcon from "@material-ui/icons/Loop";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";


const FromSubTypes: React.FC<any> = (Props: any) => {
    // @ts-ignore
    const {id} = useParams();
    const {getData} = Props

    let balance = 0;

    const branchColumns = [

        {
            id: 'id',
            Header: 'SN.',
            accessor: 'id',
            width: 10,
            Cell: (item: any) => {

                const {row, parentIndex} = item;
                let id = row.id.split('.').map((item: string) => parseInt(item) + 1);

                return (
                    <span style={{color: COLOR_PROFILE.primaryColor}}>{id.join('.')}</span>
                )
            }
        },
        {
            Header: 'Voucher Number',
            accessor: 'voucherNumber'
        },
        {
            Header: 'Transaction Date',
            accessor: 'transactionDate',
            Cell: (val: any) => {

                const {row: {original}} = val
                return (
                    <div>
                        {original.transactionDate.split('T')[0]}
                    </div>
                )

            }
        },
        {
            Header: 'Debit',
            accessor: 'debit',
            alignment: 'right',
            Cell: (val: any) => {

                const {row: {original: {journalRows}}} = val
                balance = balance - journalRows.amount;

                return (
                    <div style={{textAlign: 'right'}}>
                        {journalRows.type === 'debit' ? journalRows.amount : 0.00}
                    </div>
                )

            }
        },

        {
            Header: 'Credit',
            accessor: 'credit',
            alignment: 'right',

            Cell: (val: any) => {

                const {row: {original: {journalRows}}} = val
                console.log(val.row)
                balance = balance + journalRows.amount;
                return (
                    <div style={{textAlign: 'right'}}>
                        {journalRows.type === 'credit' ? journalRows.amount : 0.00}
                    </div>
                )

            }
        },
        {
            Header: 'Balance',
            accessor: 'balance',
            alignment: 'right',

            Cell: (val: any, other: any) => {

                let balance = 0;
                const getBalance = () => {

                    try {
                        const {row: {index, allCells}} = val

                        let tempVal = [...mockData.slice(0, index + 1)];
                        balance = [...tempVal].reduce((result: number, completelyNew: any) => {
                            const newItem = completelyNew.journalRows
                            let toReturn = newItem.type === 'credit' ? (parseFloat(result.toString()) - parseFloat(newItem.amount)) : (parseFloat(result.toString()) + parseFloat(newItem.amount))
                            return toReturn.toFixed(2)
                        }, 0.00)

                    } catch (e) {

                    }

                    return Math.abs(balance) + ' ' + (balance < 0 ? 'Cr' : balance > 0 ? 'Dr' : '');
                }



                return (
                    <div style={{textAlign: 'right'}}>
                        {getBalance()}
                    </div>
                )

            }
        },


    ]

    const getFormattedHead = () => {

        if (id.includes('head')) {


            let anotherTemp = getData.data.map((x: any) => {
                return {
                    ...x,
                    journalRows: x.journalRows.filter((item: any) => item.accountHead.id === parseInt(id.split('=')[1]))[0]
                }
            })

            return anotherTemp

        }
            // else if (id.includes('type')) {
            //
            //     return getData?.t?.child
            //
        // }
        else {
            return getData.data
        }
    }


    const isHead = (item: {}) => {
        return Object.keys(item)
    }


    const tempColumns = [

        {
            id: 'id',
            Header: '',
            accessor: 'id',
            width: 10,
            Cell: (item: any) => {
                const {row, parentIndex} = item;


                if(row.original.name.includes('Total')) return null

                let id = row.id.split('.').map((item: string) => parseInt(item) + 1);

                return (
                    <span style={{color: COLOR_PROFILE.primaryColor}}>{id.join('.')}</span>
                )
            }
        },
        {
            Header: 'Particulars',
            id: 'expander',
            Cell: (item: any) => {

                const {row, isLoading, isExpanded} = item;
                const {row: {original}} = item;
                const isTotal = original.name.includes('Total');
                const head = !isHead(original).includes('child') && isHead(original).includes('accountSubTypeId');
                const type = !isHead(original).includes('child') && !isHead(original).includes('accountSubType')

                const toggleRowExpandedProps = row.getToggleRowExpandedProps();

                const onClick = async (event: any) => {
                    if (!isLoading) {
                        if (!isExpanded) {
                            await handleClickRow(row);
                        }
                        toggleRowExpandedProps.onClick(event);
                    }
                }


                return (
                    <Grid container alignItems={"center"}
                        {...row.getToggleRowExpandedProps({
                            style: {
                                paddingLeft: isTotal ? 0:`${row.depth}rem`,
                                cursor: isTotal? 'default': "pointer",
                                marginLeft: isTotal ? '-0.5rem': "inherit"
                            },
                        })}
                        onClick={!head ? onClick : () => {
                        }}
                    >

                        {(!head && !isTotal) && (<Grid item style={{padding: '3px 0px 0 0 ', color: 'gray'}}>
                            {
                                isLoading ? <LoopIcon style={{fontSize: 20, padding: 2}}/> : (row.isExpanded) ?
                                    <KeyboardArrowDownIcon fontSize={"small"}/> : <ChevronRightIcon fontSize={"small"}/>
                            }
                        </Grid>)}
                        <Grid item style={{paddingLeft: isTotal ? 0:5, marginLeft: head ? 5: 0}}>
                            {(head && !Props.isChartOfAccount) ? (
                                <Link
                                    target="_blank"
                                    to={`/dashboard/reports/${Props.from}/${head ? 'head=' + row.original.id : row.original.id}`}>
                                    <u style={{color: head ? '#50bf47' : '#2595b1'}}>
                                        {row.original.name}
                                    </u>
                                </Link>
                            ) : (
                                <span style={{
                                    color: head ? '#50bf47' : type ? COLOR_PROFILE.primaryColor : '#2595b1',
                                    fontSize: isTotal ? 16: "inherit",
                                }}>{row.original.name}  {(Props.isChartOfAccount && ('('+row.original.code+')'))}</span>
                            )}
                        </Grid>


                  </Grid>
                )
            },
        },

        {
            Header: 'Debit (Dr)',
            accessor: 'debit',
            width: 10,
            Cell: (val: any) => {

                const {row: {original}} = val
                if(original.name.includes('Total')) return <div style={{fontSize: 16}}>{Math.abs(original.balanceDebit)}</div>
                return (
                    <div>
                        {original.balance ? original.balance >= 0 && Math.abs(original.balance.toFixed(2)) : null}
                    </div>
                )

            }

        },

        {
            Header: 'Credit (Cr)',
            accessor: 'credit',
            width: 10,
            Cell: (val: any) => {

                const {row: {original}} = val
                if(original.name.includes('Total')) return <div style={{fontSize: 16}}>{Math.abs(original.balanceCredit)}</div>
                return (
                    <div>
                        {original.balance ? original.balance <= 0 && Math.abs(original.balance.toFixed(2)) : null}
                    </div>
                )

            }

        },

            {
                Header: 'Amount',
                accessor: 'Amount',
                width: 10,
                Cell: (val: any) => {

                    const {row: {original}} = val

                    let total = original.balanceDebit - Math.abs(original.balanceCredit);
                    if(original.name.includes('Total')) return <div style={{fontSize: 16}}>
                        {
                            Math.abs(total)
                        }
                    </div>


                    return (
                        <div>
                            {original.balance ? original.balance > 0 ? Math.abs(original.balance)  : Math.abs(original.balance)  : null}
                        </div>
                    )

                }

            },
    ]

    const calledData =
        getData ? id === 'home'
            ? getData :
            (id.includes('head') || id.includes('type')) ? getFormattedHead() : getData.data ? getData.t.child.concat(getData.data) : getData.t.child :
            [];

    const mockData = useMemo(() => calledData, [id, getData]);
    const [isRowLoading, setIsRowLoading] = useState({});
    const [tableData, setTableData] = useState(null);

    useEffect(() => {
        if (!getData) return;

        if(id.includes('head')){
            setTableData(getFormattedHead());
        }else{
            setTableData(null);
            setIsRowLoading({})
            // @ts-ignore
            if(getData.length > 0){
                if(Props.isChartOfAccount){
                    setTableData(getData);

                }else{
                    // @ts-ignore
                    setTableData([...getData, ...[{name: `${Props.type} Total`, balanceDebit: getDebit(), balanceCredit: getCredit()}]]);

                }

            }

        }


    }, [getData])

    const handleClickRow = async (item: any) => {
        const {id, original, depth} = item;

        setIsRowLoading({[id]: true});
        const head = !isHead(original).includes('child') && isHead(original).includes('accountSubTypeId');

        try {

            let childData = []
            if (depth === 0) {
                const {data: subData} = await fetchChildOfType(original.id);
                childData = subData.data
            }
            if (depth > 0 && !head) {
                const {data: subData} = await fetchChildOfSubType(original.id);
                childData = subData.t.child.concat(subData.data)
            }

            setIsRowLoading({[id]: false})

            if (tableData) {
                // @ts-ignore
                const updatedTableData: [] = recursivelyUpdateTable({tableData, childData, id});
                // @ts-ignore
                setTableData([...updatedTableData]);
            }
        } catch (e) {
            setIsRowLoading({[id]: false})
        }

    }

    const fetchChildOfType = (id: number) => axiosInstance.get(`/report/getAccountSubTypesGroups?from=${Props.fromDate || ''}&to=${Props.tillDate || ''}&accSubTypeId=${id}&branchId=${null}`);
    const fetchChildOfSubType = (id: number) => axiosInstance.get(`/report/getAccountGroupAndBalance?from=${Props.fromDate || ''}&to=${Props.tillDate || ''}&id=${id}&branchId=${null}`);


    const getCol = (name: string) => {
        let tempCol = [...tempColumns];

        tempCol[1].Header = name

        if (name !== 'Expense') {
        }

        return tempCol;
    }

    const getFinalColumn = useCallback((name: string) => {

        const getColumn = id.includes('head') ? branchColumns : id.includes('type') ? tempColumns : id === 'home' ? [...getCol(name)] : tempColumns;

        return getColumn

    }, [tableData])

    let onlyOne= ['balance-sheet/table','profit-loss/table']

    const getWithRemovedColumn = useCallback((from: string,tempColumns: any, type:string) => {

        let spliced = Props.isChartOfAccount ? [...tempColumns.splice(2,3)] :  onlyOne.includes(from) ? [...tempColumns.splice(2,2)]: [...tempColumns.splice(4,1)]
        let tempCol = [...tempColumns];
        tempCol[1].Header = type
        return id.includes('head') ? branchColumns: tempCol;

    },[Props.from])

    const columnsExpense = useMemo(() => getWithRemovedColumn(Props.from, tempColumns, Props.type), [id, tableData, Props.from]);

    const getDebit = useCallback(() => {
        if (!tableData) return
        try {
            // @ts-ignore
            return tableData.reduce((result: number, item: any) => {
                return (item.balance > 0 ? result + parseFloat(item.balance) : result)
            }, 0.00)

        } catch (e) {
        }
    }, [tableData])

    const getCredit = useCallback(() => {

        if (!tableData) return

        try {
            // @ts-ignore
            return tableData.reduce((result: number, item: any) => {
                return (item.balance < 0 ? result + parseFloat(item.balance) : result)
            }, 0.00)

        } catch (e) {

        }

    }, [tableData])

    return (
        <div>

            <div>
                {tableData && (
                    <div>
                        <ReportTable columns={columnsExpense}
                                     data={id.includes('head') ? calledData: tableData}
                                     withTotal={id === 'home'}
                                     type={'journal'}
                                     reportType={Props.type}
                                     isChartOfAccount={Props.isChartOfAccount}
                                     isRowLoading={isRowLoading}
                                     withOutHeadBackground={Props.withOutHeadBackground}
                                     withTwoHead={Props.withTwoHead}
                                     finalData={Props.finalData}
                                     finalReportName={Props.finalReportName}
                                     finalReport={Props.finalReport}
                                     total={{'credit': getCredit, 'debit': getDebit}}
                        />
                    </div>
                )}
            </div>

        </div>
    );

};

export default FromSubTypes;
