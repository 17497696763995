import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Checkbox, Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../../../accounting/organisms/reactTable/useServerCall";
import useSWR, {trigger} from "swr";
import ReactTable from "../../../../accounting/organisms/reactTable/ReactTable";
import DataEmptyMessage from "../../../../accounting/organisms/tables/dataEmptyMessage";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AccessFilter from "../../../../../accessControl/accessFilter";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModal from "../../../../accounting/organisms/deleteModal";
import {getTodaysDate} from "../../../../../config/dateFunctions";

const EstimateTable: React.FC<any> = (Props) => {
    const {formState} = Props;

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const [deleteId, setDeleteId] = useState(null)

    const withJournal = formState.isJournaled === 'With Journal';



    const history = useHistory();

    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            // width: 100,
            alignment: 'center',
            Cell: (val: any) => {
                let id: any = val.row.original.id;

                return (
                    <Grid container spacing={withJournal ? 3:4} justify= { withJournal ? "center":'space-between'} alignItems={"center"}
                          style={{marginRight: withJournal ? 15 : 10, paddingRight: withJournal ? 15 : 0, width: withJournal ? undefined:130}}>

                        <AccessFilter

                            section={"estimate"}
                            privilege={"update"}
                            render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean, isChecker: boolean) => {

                                if (!isAllowed) return null

                                return (

                                    !withJournal && (
                                        <>
                                            {/*{(isBranchAdmin || hasAllAccess || isChecker) && (*/}
                                            {/*    <Grid item xs={withJournal ? 4 : 2}>*/}
                                            {/*        <Checkbox*/}
                                            {/*            color={"primary"}*/}
                                            {/*            size={"small"}*/}
                                            {/*            style={{*/}
                                            {/*                color: COLOR_PROFILE.buttonGreen,*/}
                                            {/*                margin: -5,*/}
                                            {/*                fontSize: 10*/}
                                            {/*            }}*/}
                                            {/*            checked={Props.checked.indexOf(id) !== -1}*/}
                                            {/*            onChange={() => Props.handleClick(id)}*/}
                                            {/*        />*/}
                                            {/*    </Grid>*/}
                                            {/*)}*/}
                                            <Grid item xs={isBranchAdmin ? 4 : 2}>
                                                <IconButton size={"small"} style={{color: '#4aca53'}}
                                                            onClick={() => {
                                                                history.push(`/dashboard/sales/estimate/edit/${val.row.original.id}`,
                                                                    {...val.row.original, journaled: withJournal}
                                                                )
                                                            }}>
                                                    <EditIcon fontSize={"small"}/>
                                                </IconButton>
                                            </Grid>
                                        </>
                                    )

                                )

                            }}
                        />


                        <Grid item xs={withJournal ? 6 : 2}>
                            <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                        onClick={() => {
                                            history.push(`/dashboard/sales/estimate/view/${val.row.original.id}`,
                                                {...val.row.original, journaled: withJournal}
                                            )
                                        }}>
                                <VisibilityIcon fontSize={"small"}/>
                            </IconButton>
                        </Grid>


                        {/*this is for deleting the section for allowed user*/}
                        {!withJournal && (
                            <Grid item xs={2}>
                                <IconButton size={"small"} style={{color: '#ec4949'}}
                                            onClick={() => setDeleteId(val.row.original.id)}>
                                    <DeleteIcon fontSize={"small"}/>
                                </IconButton>
                            </Grid>
                        )}

                    </Grid>
                )
            }
        },
        {
            Header: 'Estimate No.',
            Footer: 'Estimate No.',
            width: 30,
            accessor: 'estimateNumber'
        },
        {
            Header: 'Customer',
            Footer: 'Customer',
            accessor: 'customer.name'
        },
        {
            Header: 'Estimate Date',
            Footer: 'Estimate Date',
            accessor: 'estimateDate'
        },
        {
            Header: 'Expiry Date',
            Footer: 'Expiry Date',
            accessor: 'expiryDate'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved' : 'Unapproved'}
                </div>
            )
        },

    ]

    const path = '/getEstimates'

    useEffect(() => {

        console.log('is submitting', Props.submitting);
        // if(Props.submitting) return;
        trigger(`${path}/${page - 1}/${perPage}?${getQueryParams(formState)}`)
    }, [Props.submitting])

    const getQueryParams = (formState: any) => {
        return `isJournaled=${formState.isJournaled === 'With Journal' ? true : false}&customerId=${formState.customer ? formState.customer.id : 0}&from=${
            (formState.transactionYearAdFrom && formState.transactionMonthAdFrom && formState.transactionDateAdFrom) ? (formState.transactionYearAdFrom + '-' + ('0' + formState?.transactionMonthAdFrom).slice(-2) + '-' + ('0' + formState.transactionDateAdFrom).slice(-2)) : ''
        }&to=${
            (formState.transactionYearAdTill && formState.transactionMonthAdTill && formState.transactionDateAdTill) ? (formState.transactionYearAdTill + '-' + ('0' + formState?.transactionMonthAdTill).slice(-2) + '-' + ('0' + formState.transactionDateAdTill).slice(-2)) : getTodaysDate()
        }&branchId=null&createdById=0`
    }

    const {data, loading} = useServerCall({
        path, page, perPage,
        search: `?${getQueryParams(formState)}`
    })

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}?${getQueryParams(formState)}`)

    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];
    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => shareHolderColumns, [Props.checked, formState]);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <>
                    {(deleteId && !withJournal) && (
                        <DeleteModal message={`Are you sure? Once deleted it cannot be reverted.`}
                                     trigger={`${path}/${page - 1}/${perPage}${getQueryParams(formState)}`}
                                     deletePath={`/estimate/${deleteId}`} open={deleteId}
                                     handleDeleteCancel={() => setDeleteId(null)}
                        />
                    )}
                    <ReactTable
                        data={mockData}
                        perPageOption={[30, 50, 100]}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        totalPage={data?.t.totalPage}
                    />
                </>

            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default EstimateTable;
