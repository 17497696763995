import {Grid, IconButton} from '@material-ui/core';
import React, {useMemo, useState} from 'react';
import ReactTable from "./ReactTable";
import useServerCall from "./useServerCall";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import useSWR from "swr";
import {useHistory} from 'react-router-dom';
import DeleteModal from "../deleteModal";
import CircularSpinner from "../../../shared/atom/spinner";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import DataEmptyMessage from "../tables/dataEmptyMessage";


const TableFeader: React.FC = () => {

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const history = useHistory();
    const [deleteId, setDeleteId] = useState(null);

    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            accessor: 'id',

            Cell: (val: any) => {
                return (
                    <Grid container spacing={3} style={{marginRight: 15, paddingRight: 15}}>

                        <Grid item xs={6}>
                            <IconButton size={"small"} style={{color: '#ec4949'}} onClick={() => setDeleteId(val.row.original.id)}>
                                <DeleteIcon fontSize={"small"}/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton size={"small"} style={{color: '#4aca53'}}
                                        onClick={() => history.push(`/dashboard/company-setup/share-holders/edit/${val.row.original.id}`, val.row.original)}>
                                <EditIcon fontSize={"small"}/>
                            </IconButton>
                        </Grid>

                    </Grid>
                )
            }
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Email',
            Footer: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Phone',
            Footer: 'Phone',
            accessor: 'phone'
        },
        {
            Header: 'DOB',
            Footer: 'DOB',
            accessor: 'dateOfBirth'
        },
        {
            Header: 'Citizenship Number',
            Footer: 'Citizenship Number',
            accessor: 'citizenshipNumber'
        },
    ]

    const path = '/getShareHolders'
    const {data, loading} = useServerCall({path, page, perPage})
    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}`)

    if (data?.t.data) {
    }

    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];
    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => shareHolderColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div>
                    <DeleteModal message={`Are you sure? Once deleted it cannot be reverted.`}
                                 trigger={`/getShareHolders/${page-1}/${perPage}`}
                                 deletePath={`/deleteShareHolder/${deleteId}`} open={deleteId}
                                 handleDeleteCancel={() => setDeleteId(null)}
                    />
                    <ReactTable
                        data={mockData}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        totalPage={data?.t.totalPage}
                    />
                </div>
            ): (
                (page ===1 && loading) ? <CircularSpinner circleColor={COLOR_PROFILE.buttonBlue}/>
                    :    <DataEmptyMessage/>
            )}
        </div>
    );
};

export default TableFeader;
