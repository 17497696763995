export const recursivelyUpdateTable = ({ tableData, childData, id }) => {
    console.log('recursively update table', tableData)
    return insertIntoTable({
        existingRows: tableData,
        subRowsToInsert: childData,
        path: id.split('.')
    });
}

const insertIntoTable = ({ existingRows, subRowsToInsert, path }) => {
    const id = path[0];
    let updatedRows;

    console.log('existing row',existingRows);

    const isBaseCase = path.length === 1;

    console.log('is base case', isBaseCase)

    if (isBaseCase) {
        return existingRows.map((row, index) => {
            const isMatchedRowWithoutSubRows = index === Number(id) && !row.subRows;

            if (isMatchedRowWithoutSubRows) {

                console.log('is row without sub row',{
                    ...row,
                    subRows: subRowsToInsert
                });

                return {
                    ...row,
                    subRows: subRowsToInsert
                }
            }

            return row;
        });
    }

    return existingRows.map((row, index) => {
        const isMatchedRowWithSubRows = index === Number(id) && row.subRows;

        if (isMatchedRowWithSubRows) {

            const [, ...updatedPath] = path;

            return {
                ...row,
                subRows: insertIntoTable({
                    existingRows: row.subRows,
                    subRowsToInsert,
                    path: updatedPath
                })
            }
        }

        return row;
    });
}