import {useEffect, useState} from "react";
import useSWR from "swr";


const useAdAndBsHandler = () => {

    // const isFiscal = dateRange === 'fiscalYear';

    const [formatedNepEngDates, setFormatedNepEngDates] = useState([])
    const [nepaliYear, setNepaliYear] = useState([]);
    const [nepaliMonths, setNepaliMonths] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
    const [englishYear, setEnglishYear] = useState([]);
    const [englishMonths, setEnglishMonths] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);

    const {data: nepEngDates} = useSWR('/getNepEngDates')

    const convertToDate = (tempDate: string) => {
        return new Date(parseInt(tempDate.split('-')[0]), parseInt(tempDate.split('-')[1]) - 1, parseInt(tempDate.split('-')[2]));
    }

    const findDifferenceInDate = (date1: string, date2: string) => {
        console.log(date1)
        console.log(date2)
        return (convertToDate(date2).getTime() - convertToDate(date1).getTime()) / (1000 * 3600 * 24);
    }

    const getEnglishYears = (dateJson: any) => {
        const firstYear = parseInt(dateJson[0].startDateEnglish.split('-')[0]);
        const lastYear = parseInt(dateJson[dateJson.length - 1].startDateEnglish.split('-')[0]);
        let dateArray = [];

        for (let i = 0; i < (lastYear - firstYear + 1); i++) {
            dateArray[i] = (firstYear + i).toString();
        }

        return dateArray
    }

    const getNepaliYears = (dateJson: any) => {

        const firstYear = dateJson[0].nepaliYear;
        const lastYear = dateJson[dateJson.length - 1].nepaliYear
        let dateArray = [];

        for (let i = 0; i < (lastYear - firstYear + 1); i++) {
            dateArray[i] = (firstYear + i).toString()
        }

        return dateArray

    }

    const findDateOfEachMonths = (dateJson: any) => {

        if (!dateJson) return [];
        let newDateJson = [...dateJson]

        return newDateJson.map((item, index) => {

            const {startDateEnglish, endDateEnglish} = item;
            const daysDiff = findDifferenceInDate(startDateEnglish, endDateEnglish);

            return {
                ...item,
                days: Array.from({length: daysDiff + 1}, (_, i) => (i + 1).toString())
            }

        })

    }

    useEffect(() => {

        if (nepEngDates) {
            // @ts-ignore
            setFormatedNepEngDates(findDateOfEachMonths(nepEngDates.data))
            // @ts-ignore
            setNepaliYear(getNepaliYears(nepEngDates.data));
            // @ts-ignore
            setEnglishYear(getEnglishYears(nepEngDates.data))
        }

    }, [nepEngDates])


    return {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates}

}

export default useAdAndBsHandler;
