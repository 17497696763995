import React from 'react';
import TermsAndConditions from "../../templates/termsAndConditions";

const GlobalSetting: React.FC = () => {
    return (
        <div>
            <TermsAndConditions/>
        </div>
    );
};

export default GlobalSetting;