export const getInvoiceAmounts = (values: object[]) => {

    let amount = 0.00, discount = 0.00, taxableAmount: number, taxAmount = 0.00, totalAmount = 0.00;

    values.forEach((item: any) => {

        if(!(item.product && item.price && item.quantity)) return

        let itemAmount = parseFloat(item.price) * parseFloat(item.quantity);
        amount += itemAmount;

        let itemDiscount = 0.00;
        if(item.discount) {
            itemDiscount = itemAmount * 0.01 * (parseFloat(item.discount))
            discount += itemDiscount;
        }

        let itemTaxAmount = 0.00;
        if(item.tax) {
            itemTaxAmount = (itemAmount - itemDiscount) * item.tax / 100;
            taxAmount += itemTaxAmount
        }

        let itemTotalAmount = itemAmount - itemDiscount + itemTaxAmount;
        totalAmount += itemTotalAmount;

    })

    taxableAmount = amount - discount

    return {amount, discount, taxableAmount, taxAmount, totalAmount}
}