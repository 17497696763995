import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import * as Yup from "yup";
import useSWR from "swr";
import {Grid, Popper} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import InputField from "../../../../shared/atom/formElements/inputField";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import DateComponent from "../../../../product/molecules/dateComponent";
import Button from "../../../../shared/atom/button";
import SearchIcon from "@material-ui/icons/Search";
import CircularSpinner from "../../../../shared/atom/spinner";
import {MonthList} from "../../../../../utils/contants";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/RootReducer";

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const VATFilingFilter: React.FC<any> =  (Props) => {
    const styles = useStyles();



    const [formState, setFormState] = useState<any>()




    const validationSchema = Yup.object({})
    const onSubmit = (values: any, formAction: any) => {
        Props.setFormState(values)
    };


    const withMonth = ['vatReport', 'tdsReport'];
    const withYear = ['vatReport', 'tdsReport'];

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{width: 400}} placement='bottom-end'/>)
    }

    const dashboard = useSelector((state: RootState) => state.dashboard);
    let year = dashboard.fiscalYear.find((item: any) => item.status === "running")?.fiscalYears

    return (

        <div>

            <Formik

                initialValues={Props.formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {

                    return (
                        <div style={{marginTop: 10}}>
                            <Form style={{marginLeft: 2}}>
                                <Grid container justify={'flex-start'}
                                      alignItems={"center"} spacing={1}>

                                    {withYear.includes(Props.from) && (
                                        <Grid item xs={3} alignItems={'center'}
                                              style={{marginBottom:  5}}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'year'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'month'} {...field}
                                                                                       size={"small"}
                                                                                       placeholder={'Year'}
                                                                                       onChange={(e, value) => {
                                                                                           form.setFieldValue("year", value)

                                                                                       }}
                                                                                       options={[year+1, year, year-1, year-2, year-3].map((y: any) => y.toString())}
                                                                                       getOptionLabel={option => option}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>
                                        </Grid>
                                    )}

                                    {withMonth.includes(Props.from) && (
                                        <Grid item xs={3} alignItems={'center'}
                                              style={{marginBottom:  5}}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'month'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                      id={'month'} {...field}
                                                                                      size={"small"}
                                                                                      placeholder={'Month'}
                                                                                      onChange={(e, value) => {
                                                                                          form.setFieldValue("month", value)

                                                                                      }}
                                                                                      options={MonthList}
                                                                                      getOptionLabel={option => option.name}
                                                                                      error={meta.touched && meta.error}
                                                                />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>
                                        </Grid>
                                    )}



                                    <Grid item xs={3} alignItems={'center'}
                                          style={{marginBottom: 5}}>
                                        <Grid container justify={"flex-start"} spacing={2} alignItems={'center'}>
                                            <Grid item>
                                                <div style={{maxWidth: 140}}>
                                                    <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                            textColor={'white'}
                                                            disabled={Props.submitting}
                                                            startIcon={<SearchIcon/>}

                                                            type={'submit'}>
                                                            <span style={{fontSize: 15}}>
                                                                Search
                                                            </span>
                                                    </Button>
                                                </div>
                                            </Grid>

                                            <Grid item>
                                                {Props.submitting &&
                                                <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )
};

export default VATFilingFilter;
