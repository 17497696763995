import React from 'react';
import PageTopBar from "../../../organisms/pageTopBar";
import DividerComponent from "../../../../shared/atom/divider";
import BankForm from "../../../organisms/Forms/bankForm";
import {useParams} from "react-router-dom";

const AddBanksPage: React.FC = () => {

    // @ts-ignore
    const {id} = useParams();
    const mode = id ? 'edit' : 'create';

    return (
        <div>
            <PageTopBar name={id ? 'Edit Bank':'Add Bank'}/>


            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: '25px 0px 10px 25px', paddingBottom: 30}}>
                <BankForm from={'branch'} mode={mode} id={id}/>
            </div>
        </div>
    );
};

export default AddBanksPage;
