import {FaBuilding, FaFileInvoice, FaMoneyCheckAlt, FaPoll, FaTachometerAlt} from "react-icons/fa";

export const DRAWER_MENUS = [
    {
        id: 1,
        name: 'Dashboard',
        path: '/dashboard/home',
        clickable: true,
        icon: FaTachometerAlt,
        applyAccessControl: false,
    },
    {
        id: 6,
        name: 'Verify Requests',
        path: '/dashboard/verify-requests',
        clickable: true,
        icon: FaTachometerAlt,
        applyAccessControl: true,
        accessTo: 'Company Setup'
    },
    {
        id: 2,
        name: 'My Company',
        path: '/dashboard/company-setup',
        clickable: true,
        icon: FaBuilding,
        accessTo: 'Company Setup',
        applyAccessControl: true,
        subMenu: [
            // {
            //     id: 21,
            //     clickable: true,
            //     applyAccessControl: true,
            //     accessTo: 'shareHolders',
            //     name: 'Share Holder',
            //     path: '/share-holders',
            //     addPath: '/dashboard/company-setup/share-holders/add'
            // },
            // {
            //     id: 22,
            //     clickable: true,
            //     applyAccessControl: true,
            //     name: 'Branches',
            //     accessTo: 'branches',
            //     path: '/branch',
            //     addPath: '/dashboard/company-setup/branch/add'
            // },
            {
                id: 23,
                name: 'Employees',
                clickable: true,
                accessTo: 'employees',
                applyAccessControl: true,
                path: '/employee',
                addPath: '/dashboard/company-setup/employee/add'
            },
            {
                id: 24,
                clickable: true,
                applyAccessControl: true,
                name: 'User and Roles',
                accessTo: 'userAndRoles',
                path: '/user-and-roles',
                addPath: '/dashboard/company-setup/user-and-roles/add'

            },
            // {
            //     id: 25,
            //     clickable: true,
            //     applyAccessControl: true,
            //     name: 'Banks',
            //     accessTo: 'bank',
            //     path: '/banks',
            //     addPath: '/dashboard/company-setup/banks/add'
            // },
            // {
            //     id: 26,
            //     clickable: true,
            //     name: 'Vendors',
            //     accessTo: 'vendors',
            //     applyAccessControl: true,
            //     path: '/vendors',
            //     addPath: '/dashboard/company-setup/vendors/add'
            // },

        ]

    },
    {
        id: 3,
        name: 'Accounting Setup',
        path: '/dashboard/accounting-setup',
        clickable: true,
        icon: FaFileInvoice,
        accessTo: 'Accounting Setup',
        applyAccessControl: true,
        subMenu: [
    //         {
    //             id: 31,
    //             name: 'Chart of Accounts',
    //             applyAccessControl: true,
    //             path: '/chart-of-accounts/table/home',
    //             clickable: true,
    //             accessTo: 'accountGroup',
    //
    //         },
    //         {
    //             id: 31,
    //             name: 'Account Group',
    //             applyAccessControl: true,
    //             path: '/account-group',
    //             clickable: true,
    //             accessTo: 'accountGroup',
    //             addPath: '/dashboard/accounting-setup/account-group/add'
    //
    //         },
            {
                id: 32,
                name: 'Account Head',
                path: '/account-head',
                applyAccessControl: true,
                accessTo: 'accountHead',
                clickable: true,
                addPath: '/dashboard/accounting-setup/account-head/add'

            }
        ]
    },
    // {
    //     id: 4,
    //     name: 'Voucher Entries',
    //     applyAccessControl: true,
    //     accessTo: 'Entries',
    //     icon: FaMoneyCheckAlt,
    //     path: '/dashboard/voucher-entries',
    //     clickable: true,
    //     subMenu: [
    //         {
    //             id: 41,
    //             name: 'Journal Voucher',
    //             path: '/journal-voucher',
    //             clickable: true,
    //             accessTo: 'journalEntry',
    //             applyAccessControl: true,
    //             addPath: '/dashboard/voucher-entries/journal-voucher/add'
    //         },
    //         {
    //             id: 42,
    //             name: 'Receipt Voucher',
    //             path: '/receipt-voucher',
    //             applyAccessControl: true,
    //             accessTo: 'receiptEntry',
    //             clickable: true,
    //             addPath: '/dashboard/voucher-entries/receipt-voucher/add'
    //         },
    //         {
    //             id: 43,
    //             name: 'Payment Voucher',
    //             applyAccessControl: true,
    //             accessTo: 'paymentEntry',
    //             path: '/payment-voucher',
    //             clickable: true,
    //             addPath: '/dashboard/voucher-entries/payment-voucher/add'
    //         }
    //     ]
    //
    // },

    {
        id: 5,
        name: 'Product',
        applyAccessControl: true,
        accessTo: 'Product',
        icon: FaMoneyCheckAlt,
        path: '/dashboard/product',
        clickable: true,
        subMenu: [
            // {
            //     id: 52,
            //     name: 'Product Hierarchy',
            //     path: '/product-hierarchy',
            //     clickable: true,
            //     accessTo: 'category',
            //     applyAccessControl: true,
            //     // addPath: '/dashboard/product/product-category/add'
            // },
            {
                id: 51,
                name: 'Category',
                path: '/product-category',
                clickable: true,
                accessTo: 'category',
                applyAccessControl: true,
                addPath: '/dashboard/product/product-category/add'
            },
            // {
            //     id: 52,
            //     name: 'Unit Of Measure',
            //     path: '/uom',
            //     clickable: true,
            //     accessTo: 'uom',
            //     applyAccessControl: true,
            //     addPath: '/dashboard/product/uom/add'
            // },
            {
                id: 53,
                name: 'Item',
                path: '/product-item',
                applyAccessControl: true,
                accessTo: 'productItem',
                clickable: true,
                addPath: '/dashboard/product/product-item/add'
            },
            // {
            //     id: 54,
            //     name: 'Bundle',
            //     applyAccessControl: true,
            //     accessTo: 'bundle',
            //     path: '/product-bundle',
            //     clickable: true,
            //     addPath: '/dashboard/product/product-bundle/add'
            // },
            // {
            //     id: 55,
            //     name: 'Sales & Offers',
            //     applyAccessControl: true,
            //     accessTo: 'salesOffer',
            //     path: '/product-sales-offer',
            //     clickable: true,
            //     addPath: '/dashboard/product/product-sales-offer/add'
            // }
        ]

    },
    // {
    //     id: 7,
    //     name: 'Purchase',
    //     applyAccessControl: true,
    //     accessTo: 'Purchase',
    //     icon: FaMoneyCheckAlt,
    //     path: '/dashboard/purchase',
    //     clickable: true,
    //     subMenu: [
    //         {
    //             id: 71,
    //             name: 'Request for Proposal',
    //             path: '/request-for-proposal',
    //             clickable: true,
    //             accessTo: 'purchaseRFP',
    //             applyAccessControl: true,
    //             addPath: '/dashboard/purchase/request-for-proposal/add'
    //         },
    //         {
    //             id: 72,
    //             name: 'Purchase Order',
    //             path: '/purchase-order',
    //             clickable: true,
    //             accessTo: 'purchaseOrder',
    //             applyAccessControl: true,
    //             addPath: '/dashboard/purchase/purchase-order/add'
    //         },
    //         {
    //             id: 73,
    //             name: 'Purchase Bill Entry',
    //             path: '/purchase-bill',
    //             clickable: true,
    //             accessTo: 'purchaseBill',
    //             applyAccessControl: true,
    //             addPath: '/dashboard/purchase/purchase-bill/add'
    //         },
    //         // {
    //         //     id: 74,
    //         //     name: 'Expense Bill Entry',
    //         //     path: '/expense-entry',
    //         //     clickable: true,
    //         //     accessTo: 'expenseBill',
    //         //     applyAccessControl: true,
    //         //     addPath: '/dashboard/purchase/expense-entry/add'
    //         // },
    //         {
    //             id: 75,
    //             name: 'Purchase Return',
    //             path: '/purchase-return',
    //             clickable: true,
    //             accessTo: 'purchaseReturn',
    //             applyAccessControl: true,
    //             addPath: '/dashboard/purchase/purchase-return/add'
    //         }
    //     ]
    //
    // },
    // {
    //     id: 10,
    //     name: 'Expense',
    //     applyAccessControl: true,
    //     accessTo: 'expenseBill',
    //     icon: FaMoneyCheckAlt,
    //     path: '/dashboard/purchase',
    //     clickable: true,
    //     subMenu: [
    //         {
    //             id: 101,
    //             name: 'Expense Entry',
    //             path: '/expense-entry',
    //             clickable: true,
    //             accessTo: 'inventoryLevel',
    //             applyAccessControl: true,
    //             addPath: '/dashboard/purchase/expense-entry/add'
    //         }
    //
    //     ]
    //
    // },

    // {
    //     id: 8,
    //     name: 'Inventory',
    //     applyAccessControl: true,
    //     accessTo: 'Inventory',
    //     icon: FaMoneyCheckAlt,
    //     path: '/dashboard/inventory',
    //     clickable: true,
    //     subMenu: [
    //         {
    //             id: 81,
    //             name: 'Inventory Level',
    //             path: '/inventory-level',
    //             clickable: true,
    //             accessTo: 'inventoryLevel',
    //             applyAccessControl: true,
    //             // addPath: '/dashboard/inventory/inventory-level/add'
    //         },
    //         {
    //             id: 82,
    //             name: 'Inventory Adjustment',
    //             path: '/inventory-adjustment',
    //             clickable: true,
    //             accessTo: 'inventoryAdjustment',
    //             applyAccessControl: true,
    //             addPath: '/dashboard/inventory/inventory-adjustment/add'
    //         }
    //
    //     ]
    //
    // },

    {
        id: 9,
        name: 'Sales',
        applyAccessControl: true,
        accessTo: 'Sales',
        icon: FaMoneyCheckAlt,
        path: '/dashboard/sales',
        clickable: true,
        subMenu: [
            {
                id: 95,
                clickable: true,
                name: 'Membership',
                accessTo: 'membership',
                applyAccessControl: true,
                path: '/membership',
                addPath: '/dashboard/sales/membership/add'
            },
            {
                id: 91,
                clickable: true,
                name: 'Customers',
                accessTo: 'customers',
                applyAccessControl: true,
                path: '/customers',
                addPath: '/dashboard/sales/customers/add'
            },
            // {
            //     id: 92,
            //     name: 'Proforma/ Estimate',
            //     path: '/estimate',
            //     clickable: true,
            //     accessTo: 'estimate',
            //     applyAccessControl: true,
            //     addPath: '/dashboard/sales/estimate/add'
            // },
            {
                id: 93,
                name: 'Invoices',
                path: '/invoice',
                clickable: true,
                accessTo: 'invoice',
                applyAccessControl: true,
                addPath: '/dashboard/sales/invoice/add'
            },
            {
                id: 93,
                name: 'Activity/ Audit Logs',
                path: '/logs',
                clickable: true,
                accessTo: 'auditLog',
                applyAccessControl: true,
                // addPath: '/dashboard/sales/logs'
            },
            {
                id: 94,
                name: 'Sales Return',
                path: '/return',
                clickable: true,
                accessTo: 'salesReturn',
                applyAccessControl: true,
                addPath: '/dashboard/sales/return/add'
            },
            {
                id: 96,
                name: 'Materialized View',
                path: '/materialized',
                clickable: true,
                accessTo: 'materializedView',
                applyAccessControl: true,
                // addPath: '/dashboard/sales/return/add'
            }

        ]

    },
    {
        id: 13,
        name: 'VAT/TDS Management',
        applyAccessControl: true,
        accessTo: 'VAT/ TDS Management',
        icon: FaMoneyCheckAlt,
        path: '/dashboard/vatManagement',
        clickable: true,
        subMenu: [
            {
                id: 131,
                clickable: true,
                name: 'VAT',
                accessTo: 'vat',
                applyAccessControl: true,
                path: '/vat',
                // addPath: '/dashboard/tableOrder/placeOrder/add'
            }
        ]

    },
    {
        id: 6,
        name: 'Reports',
        path: '/dashboard/reports',
        clickable: true,
        icon: FaPoll,
        accessTo: 'Report',
        applyAccessControl: true,
        subMenu: [
            // {
            //     id: 61,
            //     name: 'General Ledger',
            //     clickable: true,
            //     accessTo: 'generalLedger',
            //     applyAccessControl: true,
            //     path: '/general-ledger'
            //
            // },
            // {
            //     id: 62,
            //     clickable: true,
            //     name: 'Group Summary',
            //     applyAccessControl: true,
            //     accessTo: 'groupSummary',
            //     path: '/group-summary/table/home'
            // },

            // {
            //     id: 66,
            //     clickable: true,
            //     name: 'Receivables Report',
            //     applyAccessControl: true,
            //     accessTo: 'groupSummary',
            //     path: '/receivables-reports/home'
            // },
            // {
            //     id: 67,
            //     clickable: true,
            //     name: 'Payable Report',
            //     applyAccessControl: true,
            //     accessTo: 'groupSummary',
            //     path: '/payable-reports/home'
            // },
            // {
            //     id: 68,
            //     clickable: true,
            //     name: 'Purchase Report',
            //     applyAccessControl: true,
            //     accessTo: 'groupSummary',
            //     path: '/purchase-reports/home'
            // },
            {
                id: 69,
                clickable: true,
                name: 'Sales Report',
                applyAccessControl: true,
                accessTo: 'salesReport',
                path: '/sales-reports/home'
            },
            {
                id: 71,
                clickable: true,
                name: 'Cancellation Report',
                applyAccessControl: true,
                accessTo: 'salesReport',
                path: '/cancellation-reports/home'
            },

            {
                id: 70,
                clickable: true,
                name: 'Sales Return Report',
                applyAccessControl: true,
                accessTo: 'salesReturnReport',
                path: '/sales-return-report/home'
            },
            // {
            //     id: 69,
            //     clickable: true,
            //     name: 'Expense Report',
            //     applyAccessControl: true,
            //     accessTo: 'groupSummary',
            //     path: '/expense-reports/home'
            // },
            // {
            //     id: 64,
            //     name: 'Trial Balance',
            //     clickable: true,
            //     applyAccessControl: true,
            //     accessTo: 'trialBalance',
            //     path: '/trial-balance/table/home'
            // },
            // {
            //     id: 63,
            //     name: 'Profit & Loss',
            //     clickable: true,
            //     applyAccessControl: true,
            //     accessTo: 'profitAndLoss',
            //     path: '/profit-loss/table/home'
            // },
            // {
            //     id: 65,
            //     clickable: true,
            //     applyAccessControl: true,
            //     name: 'Balance Sheet',
            //     accessTo: 'trialBalance',
            //     path: '/balance-sheet/table/home'
            // },

        ]
    },

    // {
    //     id: 10,
    //     name: 'VAT Filing',
    //     path: '/dashboard/vat-filing',
    //     clickable: true,
    //     icon: FaPoll,
    //     accessTo: 'Report',
    //     applyAccessControl: true,
    //     subMenu: [
    //         {
    //             id: 101,
    //             name: 'Monthly VAT Return',
    //             clickable: true,
    //             accessTo: 'generalLedger',
    //             applyAccessControl: true,
    //             path: '/monthly-vat-return'
    //
    //         },
    //         {
    //             id: 102,
    //             clickable: true,
    //             name: 'TDS Report',
    //             applyAccessControl: true,
    //             accessTo: 'groupSummary',
    //             path: '/tds-report'
    //         },
    //     ]
    // },
]

export const DaysJson = [
    {
        id: 1,
        name: 'Su',
        day: 'Sunday'
    },
    {
        id: 2,
        name: 'Mo',
        day: 'Monday'
    },
    {
        id: 3,
        name: 'Tu',
        day: 'Tuesday'
    },
    {
        id: 4,
        name: 'We',
        day: 'Wednesday'
    },
    {
        id: 5,
        name: 'Th',
        day: 'Thursday'
    },
    {
        id: 6,
        name: 'Fr',
        day: 'Friday'
    },
    {
        id: 6,
        name: 'Sa',
        day: 'Saturday'
    }
]

export const ACCESS_MAPPER = {
    allMenu: {
        id: 1,
        name: 'All Menus'
    },
    shareHolders: {
        id: 2,
        name: 'Share Holders'
    },
    branches: {
        id: 3,
        name: 'Branches'
    },
    employees: {
        id: 4,
        name: 'Employees'
    },
    userAndRoles: {
        id: 5,
        name: 'User Management'
    },
    bank: {
        id: 6,
        name: 'Bank'
    },
    customers: {
        id: 7,
        name: 'Customer'
    },
    vendors: {
        id: 8,
        name: 'Vendor'
    },
    accountGroup: {
        id: 9,
        name: 'Account Group'
    },
    accountHead: {
        id: 10,
        name: 'Account Head'
    },
    costCenter: {
        id: 11,
        name: 'Cost Center'
    },
    journalEntry: {
        id: 12,
        name: 'Journal'
    },
    paymentEntry: {
        id: 13,
        name: 'Payment'
    },
    receiptEntry: {
        id: 14,
        name: 'Receipt'
    },
    salesEntry: {
        id: 15,
        name: 'Sales'
    },
    purchaseRFP: {
        id: 24,
        name: 'Request for Proposal'
    },
    purchaseOrder: {
        id: 25,
        name: 'Purchase Order'
    },
    purchaseBill: {
        id: 26,
        name: 'Purchase Bill Entry'
    },
    expenseBill: {
        id: 27,
        name: 'Expense Entry'
    },
    purchaseReturn: {
        id: 28,
        name: 'Purchase Return'
    },
    estimate: {
        id: 29,
        name: 'Proforma'
    },
    salesReturn: {
        id: 30,
        name: 'Sales Return'
    },
    category: {
        id: 31,
        name: "Category"
    },
    uom: {
        id: 32,
        name: "UOM"
    },
    productItem: {
        id: 33,
        name: "Product"
    },
    bundle: {
        id: 34,
        name: "Product Bundle"
    },
    salesOffer: {
        id: 35,
        name: "Offer"
    },
    inventoryLevel: {
        id: 36,
        name: "Inventory Level"
    },
    inventoryAdjustment: {
        id: 37,
        name: "Inventory Adjustment"
    },
    generalLedger: {
        id: 16,
        name: 'General Ledger Reports'
    },
    groupSummary: {
        id: 17,
        name: 'Group Summary Reports'
    },
    payable: {
        id: 18,
        name: 'Payable Reports'
    },
    receivable: {
        id: 19,
        name: 'Receivable Reports'
    },
    balanceSheet: {
        id: 20,
        name: 'Report Balance Sheet'
    },
    profitAndLoss: {
        id: 21,
        name: 'Report Profit & Loss'
    },
    trialBalance: {
        id: 22,
        name: 'Report Trial Balance'
    },
    sales: {
        id: 23,
        name: 'Sales Reports'
    },
    payableReport: {
        id: 24,
        name: "Payable Reports"
    },
    receivableReport: {
        id: 25,
        name: "Receivable Reports"
    },
    salesReport: {
        id: 26,
        name: "Sales Reports"
    },
    salesReturnReport: {
        id: 27,
        name: "Sales Return Reports"
    },
    membership: {
        id: 27,
        name: "Membership"
    },
    invoice: {
        id: 28,
        name: "Invoice"
    },
    auditLog: {
        id: 29,
        name: "Audit Log"
    },
    materializedView: {
        id: 30,
        name: "Materialized View"
    },
    vat: {
        id: 35,
        name: "VAT"
    },
    tds: {
        id: 36,
        name: "TDS"
    },
}

export const APPROVAL_MAPPER = {
    'Journal': {
        displayName: 'Journal Entries',
        section: 'Voucher Entries',
        path: 'journal',
        approval: '/approveJournals'
    },
    'Payment': {
        displayName: 'Payment Entries',
        path: 'payment',
        section: 'Voucher Entries',
        approval: '/approveJournals'
    },
    'Receipt': {
        displayName: 'Receipt Entries',
        path: 'receipt',
        section: 'Voucher Entries',
        approval: '/approveJournals'
    },
    'Account Head': {
        displayName: 'Account Head',
        path: 'accountHeads',
        section: 'Accounting Setup',
        approval: '/approveAccountHeads',
    },
    'Account Group': {
        displayName: 'Account Groups',
        path: 'accountGroups',
        section: 'Accounting Setup',
        approval: '/approveAccountGroups',
    },
    'Bank': {
        displayName: 'Bank',
        path: 'banks',
        section: 'Company Setup',
        approval: '/approveBanks',
    },
    'Customer': {
        displayName: 'Customer',
        path: 'customers',
        section: 'Company Setup',
        approval: '/approveCustomers',
    },
    'Vendor': {
        displayName: 'Vendor',
        path: 'vendors',
        section: 'Company Setup',
        approval: '/approveVendors',
    },
    'Request for Proposal': {
        displayName: 'Purchase RFP',
        path: 'getUnApprovedPurchaseRFP',
        withoutPrams: true,
        approval: '/approvePurchaseRFPs',
    },
    'Purchase Order': {
        displayName: 'Purchase Order',
        path: 'getUnapprovedPos',
        withoutPrams: true,
        approval: '/approvePOs',
    },
    'Purchase Bill Entry': {
        displayName: 'Purchase Bill',
        path: 'getUnapprovedPBs',
        withoutPrams: true,
        approval: '/approvePBills',
    },
    'Expense Entry': {
        displayName: 'Expense Bill',
        path: 'getUnapprovedEBs',
        withoutPrams: true,
        approval: '/approveEbs',
    },
    'Purchase Return': {
        displayName: 'Purchase Return',
        path: 'getUnapprovedPRs',
        withoutPrams: true,
        approval: '/approvePRs',
    },
    'Proforma': {
        displayName: 'Estimate/ Proforma',
        path: 'getUnapprovedEstimates',
        withoutPrams: true,
        approval: '/approveEstimates',
    },
    'Sales Return': {
        displayName: 'Sales Return',
        path: 'getUnapprovedSRs',
        withoutPrams: true,
        approval: '/approveSRs',
    },
    'Category': {
        displayName: 'Category',
        path: 'getUnapprovedCategories',
        withoutPrams: true,
        approval: '/approveCategories',
    },
    'UOM': {
        displayName: 'UOM',
        path: 'getUnapprovedUoms',
        withoutPrams: true,
        approval: '/approveUoms',
    },
    'Product': {
        displayName: 'Product Item',
        path: 'getUnapprovedProducts',
        withoutPrams: true,
        approval: '/approveProducts',
    },
    'Product Bundle': {
        displayName: 'Product Bundle',
        path: 'getUnapprovedPbs',
        withoutPrams: true,
        approval: '/approvePbs',
    },
    'Offer': {
        displayName: 'Sales/ Offer',
        path: 'getUnapprovedOffers',
        withoutPrams: true,
        approval: '/approveOffers',
    },
    'Inventory Adjustment': {
        displayName: 'Inventory Adjustment',
        path: 'getUnapprovedInventoryAdjustments',
        withoutPrams: true,
        approval: '/approveInventoryAdjustments',
    },
}

export const MonthList = [
    {
        id: 1,
        name: 'Baisakh'
    },
    {
        id: 2,
        name: 'Jestha'
    },
    {
        id: 3,
        name: 'Ashad'
    },
    {
        id: 4,
        name: 'Shrawan'
    },
    {
        id: 5,
        name: 'Bhadra'
    },
    {
        id: 6,
        name: 'Ashwin'
    },
    {
        id: 7,
        name: 'Kartik'
    },
    {
        id: 8,
        name: 'Mangsir'
    },
    {
        id: 9,
        name: 'Poush'
    },
    {
        id: 10,
        name: 'Magh'
    },
    {
        id: 11,
        name: 'Falgun'
    },
    {
        id: 12,
        name: 'Chaitra'
    }
]


