const mapper = {
    'localWithIp': 'http://172.16.10.52:8080',
    'serverDatahub': 'http://103.90.86.15:8080',
    'ec2Old': 'http://ec2-3-142-142-105.us-east-2.compute.amazonaws.com:8080',
    'ec2OldAgain': 'http://ec2-65-0-11-246.ap-south-1.compute.amazonaws.com:8080',
    'ec2': 'http://ec2-3-129-64-151.us-east-2.compute.amazonaws.com:8080',
    'sugam': 'https://d6gjl6pepdwxr.cloudfront.net',
    'ngrok': 'http://2a04-27-34-16-184.ngrok.io',
    'withLocalHost': 'http://127.0.0.1:8080',
    "test": "http://ec2-13-232-7-44.ap-south-1.compute.amazonaws.com:8080"
};

const baseUrl = mapper['sugam'];
export default baseUrl;
