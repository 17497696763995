import React from 'react';
import {DaysJson} from "../../../../utils/contants";
import {Grid} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../config/colorCode";

const DaysLayout: React.FC<any> = (Props) => {
    return (
        <Grid container>

            {DaysJson.map((item: any) => {

                const isSelected = Props.selected.includes(item.name);

                return (
                    <Grid item
                          style={{
                              border: `1px solid ${COLOR_PROFILE.setupStepBackground}`,
                              padding: '5px 10px 5px 10px',
                              cursor: 'Pointer',
                              background: isSelected ? COLOR_PROFILE.buttonGreen : undefined,
                              color: isSelected ? 'white' : COLOR_PROFILE.primaryColor
                          }}
                          onClick={Props.handleChange(item.name)}
                     >
                        {item.name}
                    </Grid>
                )

            })}
        </Grid>
    );
};

export default DaysLayout;