import React from 'react';

const ErrorLabel: React.FC<any> = (Props) => {
    const {meta} = Props;
    return (
        <div style={{position: "absolute"}}>
           <span
               style={{
                   color: 'red',
                   fontSize: 12,
                   bottom: 0,
                   left: 2
               }}
           >
                {(meta.touched && meta.error) ? meta.error : null}
           </span>
        </div>
    );
};

export default ErrorLabel;