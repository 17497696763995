import React from 'react';
import InventoryAdjustmentAddition from "../../templates/inventoryAdjustmentAddition";

const InventoryAdjustmentAdd = () => {
    return (
        <div>
            <InventoryAdjustmentAddition/>
        </div>
    );
};

export default InventoryAdjustmentAdd;