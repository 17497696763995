import React from 'react';
import {Grid} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../config/colorCode";

const ListView: React.FC<any> = (Props) => {
    let {data, header} = Props;
    return (
        <div style={{fontWeight: 500, color: COLOR_PROFILE.primaryColor}}>
            {data.length > 0 ?
                <div>
                    <Grid container xs={12} item alignItems={"center"} justify={"space-between"} style={{background: COLOR_PROFILE.tableHeader, fontSize: 14, fontWeight: 700, padding: 5}}>
                        <Grid item xs>
                            {header[0]}
                        </Grid>
                        <Grid item>
                            {header[1]}
                        </Grid>
                    </Grid>
                    {[...data].map((item: any, index: number) => {
                        return (
                            <Grid container item justify={"space-between"} style={{padding: 5, borderBottom: `1px solid ${COLOR_PROFILE.backgroundDark}`}} key={index}>
                                <Grid item>
                                    {item.key}
                                </Grid>
                                <Grid item>
                                    {item.value}
                                </Grid>
                            </Grid>
                        )
                    })}
                </div>
                :
                <div>
                    No data present!
                </div>
            }

        </div>
    );
};

export default ListView;