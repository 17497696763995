import React, {useState} from 'react';
import axiosInstance from "../../../../config/axiosConfig";
import {trigger} from "swr";
import {useDispatch} from "react-redux";
import DialogComponent from "../../../shared/atom/dialog";
import {Grid} from "@material-ui/core";
import Button from "../../../shared/atom/button";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import CircularSpinner from "../../../shared/atom/spinner";

const MakeCopyModal: React.FC<any> = (Props) => {

    const [copied, setCopied] = useState(false);
    const [message, setMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);


    const handleDelete = async () => {

        setSubmitting(true)

        try {
            const {data} = await axiosInstance.post(Props.copyPath)

            if (data.code === 'OK') {
                setCopied(true)
                setMessage('Copied successfully.')

                handleResponse('Copied successfully.', 'success')
                trigger(Props.trigger);

                Props.handleDeleteCancel();
            }


        } catch (e) {
            setSubmitting(false)

            setMessage('Error encountered while copying.')
        }

        setSubmitting(false)

    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }


    return (
        <div>
            {Props.open && (
                <DialogComponent open={Props.open} maxWidth={"xs"}>

                    {(message && !copied) && (
                        <div style={{color: 'red', paddingBottom: 10}}>

                            {message}

                        </div>
                    )}

                    <div style={{fontSize: 16, fontWeight: 600, marginBottom: 30}}>
                        {copied ? message : Props.message}
                    </div>

                    <Grid container spacing={3} style={{margin: '10px 0 0 -10px '}} alignItems={"center"}>

                        <Grid item>
                            <div style={{minWidth: 125}}>
                                <Button variant={"contained"} customColor={COLOR_PROFILE.buttonRed} textColor={'white'}
                                        type={'button'}
                                        disabled={submitting}
                                        onClick={handleDelete}
                                >
                                    <span style={{fontSize: 15}}>
                                        Copy
                                    </span>
                                </Button>
                            </div>
                        </Grid>

                        {submitting ? (
                            <Grid item style={{marginTop: 3}}>
                                <CircularSpinner size={30} circleColor={'blue'}/>
                            </Grid>
                        ) : (
                            !copied && (
                                <Grid item>
                                    <div style={{minWidth: 125}}>
                                        <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue} textColor={'white'}
                                                type={'submit'} onClick={Props.handleDeleteCancel} disabled={submitting}>
                                            <span style={{fontSize: 15}}>{'Cancel'}</span>
                                        </Button>
                                    </div>
                                </Grid>
                            )
                        )}

                    </Grid>
                </DialogComponent>

            )}

        </div>
    );
};

export default MakeCopyModal;