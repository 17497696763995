import React, {useMemo, useState} from 'react';
import {Grid, IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../reactTable/useServerCall";
import useSWR from "swr";
import ReactTable from "../../reactTable/ReactTable";
import DeleteModal from "../../deleteModal";
import CircularSpinner from "../../../../shared/atom/spinner";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DataEmptyMessage from "../dataEmptyMessage";
import {useHistory} from "react-router-dom";
import BlockIcon from "@material-ui/icons/Block";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ToolTip from "../../../../shared/atom/tooltip";

const BranchTable: React.FC = () => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [deleteId, setDeleteId] = useState(null)
    const [isDisabled, setIsDisabled] = useState<any>(null);

    const history = useHistory();

    const branchColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {
                return (
                    <Grid container spacing={3} style={{marginRight: 15, paddingRight: 15}}>


                        <Grid item xs={6}>

                            <ToolTip title={!val.row.original.active ? "Activate Branch":'Deactivate Branch'}>
                                <IconButton size={"small"} style={{color: val.row.original.type === "head" ? COLOR_PROFILE.backgroundDark :'#ec4949'}}
                                            disabled={val.row.original.type === "head"}
                                >
                                    {
                                        val.row.original.active ?
                                            <BlockIcon fontSize={"small"}
                                                       onClick={() => {
                                                           setDeleteId(val.row.original.id);
                                                           setIsDisabled(false);
                                                       }}
                                            />
                                            :
                                            <PlayArrowIcon fontSize={"small"}
                                                           onClick={() => {
                                                               setDeleteId(val.row.original.id)
                                                               setIsDisabled(true);
                                                           }}
                                            />
                                    }
                                </IconButton>
                            </ToolTip>


                        </Grid>
                        <Grid item xs={6}>
                            <IconButton size={"small"} style={{color: '#4aca53'}}
                                        onClick={() => history.push(`/dashboard/company-setup/branch/edit/${val.row.original.id}`, val.row.original)}
                            >
                                <EditIcon fontSize={"small"}/>
                            </IconButton>
                        </Grid>

                    </Grid>
                )
            }
        },
        {
            Header: 'Branch Code',
            Footer: 'Branch Code',

            accessor: 'branchCode'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Email',

            Footer: 'Email',
            accessor: 'emailId'
        },

        {
            Header: 'Manager',
            Footer: 'Manager',
            accessor: 'manager'
        },
        {
            Header: 'Phone Number',
            Footer: 'Phone Number',
            accessor: 'phoneNumber'
        },


    ]


    const groupedHeader = [
        {
            Header: 'Actions',
            width: 50,
            columns: [
                {
                    id: 'delete',
                    width: 10,

                    accessor: 'deleteButton',
                    Cell: () => (
                        <Grid container>


                            <Grid item xs={12}>
                                <IconButton size={"small"} style={{color: 'red'}}>
                                    <DeleteIcon fontSize={"small"}/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    )
                },
                {
                    id: 'edit',
                    width: 10,
                    accessor: 'editButton',
                    Cell: () => (
                        <Grid container>

                            <Grid item xs={12}>
                                <IconButton size={"small"} style={{color: 'green'}}>
                                    <EditIcon fontSize={"small"}/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    )
                }
            ]
        },
        {
            Header: 'Info',
            columns: [
                {
                    Header: 'Name',
                    Footer: 'Name',
                    accessor: 'name'
                },
                {
                    Header: 'Email',

                    Footer: 'Email',
                    accessor: 'email'
                },
                {
                    Header: 'Phone',
                    Footer: 'Phone',

                    accessor: 'phone'
                },
                {
                    Header: 'DOB',
                    Footer: 'DOB',

                    accessor: 'dateOfBirth'
                },
                {
                    Header: 'Citizenship Number',
                    Footer: 'Citizenship Number',

                    accessor: 'citizenshipNumber'
                }
            ]
        }
    ]

    const path = '/getBranches'

    const {data, loading} = useServerCall({path, page, perPage})

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}`)


    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [];

    const mockData = useMemo(() => calledData, [data]);

    const columns = useMemo(() => branchColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div>
                    {deleteId && (
                    <DeleteModal message={isDisabled ? `Are you sure you want to enable the branch?` : 'Are you sure you want to disable the branch?'}
                                 trigger={`/getBranches/${page - 1}/${perPage}`}
                                 withVerification={true}
                                 deletePath={isDisabled ? `/activateBranch/${deleteId}`: `/deactivateBranch/${deleteId}`}
                                 open={deleteId}
                                 disable={true}
                                 enable={isDisabled}
                                 handleDeleteCancel={() => {
                                     setDeleteId(null)
                                     setIsDisabled(null)
                                 }}
                    />
                    )}
                    <ReactTable
                        data={mockData}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        totalPage={data?.t.totalPage}
                    />
                </div>
            ): (
                (page ===1 && loading) ? <CircularSpinner circleColor={COLOR_PROFILE.buttonBlue}/>
                    :    <DataEmptyMessage/>
            )}
        </div>
    );
};

export default BranchTable;
