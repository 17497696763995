import React, {useEffect, useMemo, useState} from 'react';
import {Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../reactTable/useServerCall";
import useSWR from "swr";
import ReactTable from "../../reactTable/ReactTable";
import {useHistory} from 'react-router-dom';
import AccessFilter from "../../../../../accessControl/accessFilter";
import CircularSpinner from "../../../../shared/atom/spinner";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DataEmptyMessage from "../dataEmptyMessage";
import ToolTip from "../../../../shared/atom/tooltip";

const AccountHeadTable: React.FC<any> = (Props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);

    const history = useHistory();

    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' style={{marginRight: 15, paddingRight: 15}}>

                    <AccessFilter

                        section={"accountHead"}
                        privilege={'update'}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed) return null

                            return (

                                    <Grid item>
                                        <ToolTip title={val.row.original.editDelete === null ? `This default head cannot be edited.`: ""}>

                                        <IconButton size={"small"} style={{color: val.row.original.editDelete === null ? COLOR_PROFILE.backgroundDark:'#4aca53'}}
                                                    onClick={() => {
                                                        if(val.row.original.editDelete === null) return;
                                                        history.push(`/dashboard/accounting-setup/account-head/edit/${val.row.original.id}`, val.row.original)
                                                    }}
                                        >
                                            <EditIcon fontSize={"small"}/>
                                        </IconButton>
                                        </ToolTip>
                                    </Grid>

                            )

                        }}

                    />


                </Grid>
            )
        },
        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'code'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Group',
            Footer: 'Group',
            accessor: 'accountGroupDto.name'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },
        {
            Header: 'Balance',
            Footer: 'Balance',
            alignment: 'right',
            accessor: 'balance',

            Cell: (val: any) => {

                let balance = val.row.original.balance;

                let displayFormat = balance ? balance > 0 ? Math.abs(balance) + ' Dr': Math.abs(balance) + ' Cr' : balance;

                return (
                    <div style={{textAlign: 'right'}}>
                        {displayFormat}
                    </div>
                )
            }
        },



    ]


    const path = '/getAccountHeads'
    const {data, loading} = useServerCall({path, page, perPage,
        search: `?name=${Props.formState.search}&subTypeId=${Props.formState.accountType?Props.formState.accountType.id:0}&groupId=${Props.formState.group?Props.formState.group.id:0}`
    })

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}?name=${Props.formState.search}&subTypeId=${Props.formState.accountType?Props.formState.accountType.id:0}&groupId=${Props.formState.group?Props.formState.group.id:0}`)


    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];

    const mockData = useMemo(() => calledData, [data]);

    const columns = useMemo(() => shareHolderColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <ReactTable
                    from={'accountHeads'}
                    data={mockData  }
                    perPageOption={[30,50,100]}
                    columns={columns}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={page}
                    perPage={perPage}
                    totalPage={data?.t.totalPage}
                />
            ): (
                (page ===1 && loading) ? null
                    :    <DataEmptyMessage/>
            )}
        </div>
    );
};

export default AccountHeadTable;
