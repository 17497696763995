import React from 'react';
import MonthlyVAYReturnHome from "../../templates/monthlyVATReturnHome";

const MonthlyVATReturn: React.FC = (Props) => {
    return (
        <div>
            <MonthlyVAYReturnHome/>
        </div>
    );
};

export default MonthlyVATReturn;