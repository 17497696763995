import React, {useState} from 'react';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {Grid} from "@material-ui/core";
import InputField from "../../../shared/atom/formElements/inputField";
import Button from "../../../shared/atom/button";
import CircularSpinner from "../../../shared/atom/spinner";
import axios from "axios";
import baseUrl from "../../../../config/baseVariables";
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import {COLOR_PROFILE} from "../../../../config/colorCode";
import useAddressHandler from "../../../../effects/useAddressHandler";
import AutoCompleteInput from "../../../shared/atom/formElements/autocompleteInput";

const SignUpPage: React.FC = () => {

    const initialValues = {
        systemUser: {
            userName1: '',
            email: '',
            password: '',
            // tenantOrClientId: null,
            alias: '',
            confirmPassword: ''
        },
        company: {
            companyName: "",
            phoneNumber: "",
            emailId: "",
            branchCode: ""
        },
        address: {
            country: "Nepal",
            provinceOrState: null,
            district: null,
            city: '',
            wardNumber: null,
            municipalityOrVdc: null,
            googleMapLocationUrl: null
        },
    }

    const [selectedAddress, setSelectedAddress] = useState({
        province: null,
        district: null,
        vdc: null,
        ward: null
    })

    const {provinceOptions, districtOptions, vdcOptions, wardOptions, provinceLoading, vdcLoading} = useAddressHandler({...selectedAddress})

    const [message, setMessage] = useState('')
    const [submitted, setSubmitted] = useState(false);

    const [formState, setFormState] = useState(initialValues)

    const validationSchema = Yup.object({
        systemUser: Yup.object({
            userName1: Yup.string().required('Required'),
            email: Yup.string().email('Email is invalid').required('Required'),
            alias: Yup.string().required("Required"),
            password: Yup.string().required('Required'),
            confirmPassword: Yup.string()
                .test('passwords-match', 'Passwords must match', function (value) {
                    return this.parent.password === value
                })
        }),
        company: Yup.object({
            companyName: Yup.string().required('Required'),
            branchCode: Yup.string().required("Required")
        })
    })


    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = async (values: any, formActions: any) => {

        //
        // console.log(values);
        // formActions.setSubmitting(false)
        // return;


        try {

            const tempVal = {...values};

            let payload = {
                usersLimit:10,
                branchesLimit: 2,
                systemUser: {
                    name: values.systemUser.userName1,
                    email: values.systemUser.email,
                    // tenantOrClientId: values.systemUser.tenantOrClientId,
                    alias: values.systemUser.alias,
                    password: values.systemUser.password,
                    confirmPassword: values.systemUser.confirmPassword
                },
                company: {
                    name: values.company.companyName,
                    phoneNumber: values.company.phoneNumber,
                    emailId: values.company.emailId,
                    branchCode:values.company.branchCode
                },
                address: {
                    ...tempVal.address,
                    provinceOrState: tempVal.address.provinceOrState ? tempVal.address.provinceOrState.province : null,
                    municipalityOrVdc: tempVal.address.municipalityOrVdc ? tempVal.address.municipalityOrVdc.municipalityOrVdcName : null,
                    district: tempVal.address.district ? tempVal.address.district.district : null,
                    wardNumber: tempVal.address.wardNumber,
                }
            }

            // console.log(payload);
            // return;

            // const {data} = await axios.post(`${baseUrl}/signup`, payload)
            const {data} = await axios.post(`${baseUrl}/setupFirstAccount`, payload)

            if (data.code === 'OK') {

                if (data?.message === 'Account added for review') {

                    localStorage.removeItem('token');
                    history.push('/login');
                    setMessage('Account created successfully.');
                    setSubmitted(true);

                }
            } else {
                setMessage('An error encountered.')
            }

        } catch (e) {
            setMessage('An error encountered.')
            formActions.setSubmitting(false)

        }

        formActions.setSubmitting(false)


    }
    

    const validateProvince = (value: any) => {


        if (value?.province) {

            setSelectedAddress(prevState => {
                return {
                    ...prevState,
                    province: value
                }
            })

        }

        return false

    }

    const handleSelectedDistrict = (value: any) => {

        setSelectedAddress(prevState => {
            return {
                ...prevState,
                district: value
            }
        })

    }

    const validateMunicipality = (value: any) => {


        if (value?.wardNumbers) {

            setSelectedAddress(prevState => {
                return {
                    ...prevState,
                    vdc: value
                }
            })

        }

    }

    return (

        <div>

            <div
                style={{
                    maxWidth: 600,
                    margin: '0 auto',
                    color: COLOR_PROFILE.primaryColor,
                    background: 'white',
                    boxShadow: `0 0 5px 1px ${COLOR_PROFILE.setupStepBackground}`
                }}
            >
                {!submitted ? (
                    <div>
                        <div style={{textAlign: 'center'}}>
                            <h2>
                                Don't have an account?
                            </h2>
                            <h1 style={{marginTop: -20}}>
                                Sign Up
                            </h1>
                        </div>
                        <div>
                            {message && (
                                <div style={{margin: 10}}>
                                    <div
                                        style={{padding: 10, backgroundColor: '#d06e6e', color: 'white', fontSize: 14}}>
                                        {message}
                                    </div>
                                </div>
                            )}
                        </div>
                        <Formik
                            initialValues={formState}
                            onSubmit={onSubmit}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            validateOnChange={false}
                            validateOnMount={false}
                            validateOnBlur={true}
                        >
                            {formik => {


                                return (
                                    <div>
                                        <Form style={{margin: 20}}>


                                            <div>
                                                <div style={{position: 'relative', margin: '5px 0  15px 0'}}>
                                                    <Field name={'systemUser.userName1'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'systemUser.userName1'} {...field}
                                                                                    size={"small"}
                                                                                    placeholder={'Name'}
                                                                                    fullWidth={true}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 5,
                                                        left: 2
                                                    }}> </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>
                                            </div>

                                            <div>
                                                <div style={{position: 'relative', margin: '5px 0  15px 0'}}>
                                                    <Field name={'systemUser.email'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'systemUser.email'} {...field}
                                                                                    size={"small"}
                                                                                    placeholder={'Email'}
                                                                                    fullWidth={true}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}> </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>
                                            </div>

                                            <div>
                                                <div style={{position: 'relative', margin: '5px 0  15px 0'}}>
                                                    <Field name={'systemUser.alias'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'systemUser.alias'} {...field}
                                                                                    size={"small"}
                                                                                    placeholder={'Alias'}
                                                                                    fullWidth={true}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 5,
                                                        left: 2
                                                    }}> </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>
                                            </div>

                                            <div>

                                                <div style={{position: 'relative', margin: '5px 0  15px 0'}}>
                                                    <Field name={'systemUser.password'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'password'}
                                                                                    id={'systemUser.password'} {...field}
                                                                                    size={"small"}
                                                                                    placeholder={'Password'}
                                                                                    fullWidth={true}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}> </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>
                                            </div>

                                            <div>

                                                <div style={{position: 'relative', margin: '5px 0  15px 0'}}>
                                                    <Field name={'systemUser.confirmPassword'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'password'}
                                                                                    id={'systemUser.confirmPassword'} {...field}
                                                                                    size={"small"}
                                                                                    placeholder={'Confirm Password'}
                                                                                    fullWidth={true}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}> </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>
                                            </div>

                                            <div>

                                                <div style={{position: 'relative', margin: '5px 0  15px 0'}}>
                                                    <Field name={'company.companyName'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'company.companyName'} {...field}
                                                                                    size={"small"}
                                                                                    placeholder={'Company Name'}
                                                                                    fullWidth={true}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}> </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>
                                            </div>

                                            <div>

                                                <div style={{position: 'relative', margin: '5px 0  15px 0'}}>
                                                    <Field name={'company.branchCode'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'company.branchCode'} {...field}
                                                                                    size={"small"}
                                                                                    placeholder={'Branch Code'}
                                                                                    fullWidth={true}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}> </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>
                                            </div>

                                            <div>

                                                <div style={{position: 'relative', margin: '5px 0  15px 0'}}>
                                                    <Field name={'company.phoneNumber'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'company.phoneNumber'} {...field}
                                                                                    size={"small"}
                                                                                    placeholder={'Phone Number'}
                                                                                    fullWidth={true}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}> </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>
                                            </div>

                                            <div>

                                                <div style={{position: 'relative', margin: '5px 0  15px 0'}}>
                                                    <Field name={'company.emailId'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'company.emailId'} {...field}
                                                                                    size={"small"}
                                                                                    placeholder={'Company Email'}
                                                                                    fullWidth={true}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}> </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>
                                            </div>


                                            <div>
                                                Address
                                            </div>

                                            <Grid container sm={12}>
                                                <Grid item sm={6} alignItems={'center'}
                                                      style={{marginBottom: 15}}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'address.provinceOrState'}
                                                               validate={validateProvince}
                                                        >
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={'provinceOrState'} {...field}
                                                                                               size={"small"}
                                                                                               onChange={(e, value) => {
                                                                                                   if (value === null) {
                                                                                                       form.setFieldValue('address.district', null)
                                                                                                       form.setFieldValue('address.municipalityOrVdc', null)
                                                                                                       form.setFieldValue('address.city', null)
                                                                                                       form.setFieldValue('address.wardNumber', null)
                                                                                                   }
                                                                                                   form.setFieldValue("address.provinceOrState", value)
                                                                                               }}
                                                                                               placeholder={'Province or States'}
                                                                                               options={provinceOptions}
                                                                                               getOptionLabel={option => option.province}
                                                                                               error={meta.touched && meta.error}
                                                                            />

                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>

                                                <Grid container item sm={6} alignItems={'center'}
                                                      style={{marginBottom: 15}}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'address.district'}

                                                        >
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;

                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={'district'} {...field}
                                                                                               size={"small"}
                                                                                               fullWidth={true}
                                                                                               onChange={(e, value) => {
                                                                                                   if (value === null) {
                                                                                                       form.setFieldValue('address.municipalityOrVdc', null)
                                                                                                       form.setFieldValue('address.wardNumber', null)
                                                                                                       form.setFieldValue('address.city', null)
                                                                                                   } else {
                                                                                                       handleSelectedDistrict(value)

                                                                                                   }
                                                                                                   form.setFieldValue("address.district", value)
                                                                                               }}
                                                                                               placeholder={'District'}
                                                                                               options={districtOptions}
                                                                                               getOptionLabel={option => option.district}
                                                                                               error={meta.touched && meta.error}
                                                                            />

                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>


                                                </Grid>


                                                <Grid container item sm={6} alignItems={'center'}
                                                      style={{marginBottom: 15}}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'address.municipalityOrVdc'}
                                                               validate={validateMunicipality}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;

                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={'municipalityOrVdc'} {...field}
                                                                                               size={"small"}
                                                                                               onChange={(e, value) => {
                                                                                                   if (value === null) {
                                                                                                       form.setFieldValue('address.wardNumber', null)
                                                                                                   }
                                                                                                   form.setFieldValue("address.municipalityOrVdc", value)
                                                                                               }}
                                                                                               placeholder={'Municipality or VDC'}
                                                                                               loading={vdcLoading}
                                                                                               options={vdcOptions}
                                                                                               getOptionLabel={(option: any) => option.municipalityOrVdcName}
                                                                                               error={meta.touched && meta.error}
                                                                            />

                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>

                                                <Grid container item sm={6} alignItems={'center'}
                                                      style={{marginBottom: 15}}>


                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'address.wardNumber'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;

                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               placeholder={'Ward'}
                                                                                               id={'address.wardNumber'} {...field}
                                                                                               size={"small"}
                                                                                               onChange={(e, value) => form.setFieldValue("address.wardNumber", value)}
                                                                                               options={wardOptions}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />

                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>

                                                <Grid container item sm={6} alignItems={'center'}
                                                      style={{marginBottom: 15}}>


                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'address.city'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;

                                                                    return (
                                                                        <>
                                                                            <InputField type={'text'}
                                                                                        id={'city'} {...field}
                                                                                        size={"small"}
                                                                                        fullWidth={true}
                                                                                        placeholder={'Tole/ Street'}
                                                                                        onChange={(e: any, value: any) => {

                                                                                            form.setFieldValue("address.city", value)
                                                                                        }}

                                                                                        error={meta.touched && meta.error}
                                                                            />

                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>

                                                <Grid container item sm={6} alignItems={'center'}
                                                      style={{marginBottom: 15}}>


                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'address.googleMapLocationUrl'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;

                                                                    return (
                                                                        <>
                                                                            <InputField type={'text'}
                                                                                        id={'googleMapLocationUrl'} {...field}
                                                                                        size={"small"}

                                                                                        placeholder={'Google Map URL'}
                                                                                        error={meta.touched && meta.error}
                                                                            />

                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>


                                                <Grid container justify={"flex-start"} spacing={2} alignItems={'center'}
                                                      style={{marginTop: 10}}>
                                                    <Grid item>
                                                        <div style={{maxWidth: 160}}>
                                                            <Button variant={"contained"}
                                                                    customColor={COLOR_PROFILE.buttonGreen}
                                                                    textColor={'white'}
                                                                    disabled={formik.isSubmitting}
                                                                    type={'submit'}>
                                                        <span style={{fontSize: 15}}>
                                                            Sign Up
                                                        </span>
                                                            </Button>
                                                        </div>
                                                    </Grid>

                                                    <Grid item>
                                                        {formik.isSubmitting &&
                                                        <CircularSpinner size={30} circleColor={'blue'}/>}
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                )
                            }}
                        </Formik>

                    </div>

                ) : (

                    <div>

                        <div style={{marginTop: 20, marginBottom: 20}}>
                            {message}
                        </div>

                        <div style={{maxWidth: 160}}>
                            <Button variant={"contained"} customColor={COLOR_PROFILE.buttonGreen}
                                    textColor={'white'}
                                    type={'submit'}
                                    onClick={() => history.push('/login')}
                            >
                                    <span style={{fontSize: 15}}>
                                        Login Now
                                    </span>
                            </Button>
                        </div>

                    </div>
                )}

            </div>
        </div>
    );
};

export default SignUpPage;
