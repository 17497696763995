import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import useAdAndBsHandler from "../../../../effects/useAdAndBsHandler";
import {MonthList} from "../../../../utils/contants";
import NepaliCalendar from "../../../../utilities/NepaliCalendar";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import VATFilingFilter from "../../organisms/forms/vatFilingFilter";
import MonthlyVATReturnTable from "../../organisms/tables/monthlyVATReturn";
import MonthlyTdsReportTable from "../../organisms/tables/monthlyTDSReport";

const MonthlyTDSHome = () => {
    const [submitting, setSubmitting] = useState(false);

    const dashboard = useSelector((state: RootState) => state.dashboard);

    const [formState, setFormState] = useState({
        year: dashboard.fiscalYear.find((item: any) => item.status === "running")?.fiscalYears+"",
        month: {},
    })

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();

    useEffect(() => {
        if(!formatedNepEngDates) return

        setFormState(prevState => {
            return {
                ...prevState,
                month: {...MonthList.filter((i: any) => i.id === ((NepaliCalendar.convertADtoBS(new Date()).bsMonth - 1) || 12))[0]},
                year: NepaliCalendar.convertADtoBS(new Date()).bsMonth === 1 ? (parseInt(prevState.year) -1) + "": prevState.year
            }
        })
    }, [formatedNepEngDates])

    return (
        <div>
            <div style={{fontSize: 16, fontWeight: 700, padding: '30px 30px 20px 30px', color: COLOR_PROFILE.primaryColor}}>
                Monthly TDS Report
            </div>
            <div style={{maxWidth: 1000}}>
                <div style={{margin: '0 0 0 28px'}}>

                    <div style={{marginBottom: -10}}>
                        <VATFilingFilter setFormState={setFormState} formState={formState} setSubmitting={setSubmitting}
                                         submitting={submitting} from={'vatReport'}/>
                    </div>

                    <MonthlyTdsReportTable formState={formState} setSubmitting={setSubmitting}/>
                </div>
            </div>
        </div>
    );
};

export default MonthlyTDSHome;
