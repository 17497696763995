import React from 'react';
import {Grid, IconButton} from "@material-ui/core";
import DividerComponent from "../../../../shared/atom/divider";
import AddIcon from '@material-ui/icons/ArrowBack';
import {useHistory, useParams} from 'react-router-dom';
import AccountHeadForm from '../../../organisms/Forms/accountHeadForm';

const AddAccountHeadPage: React.FC = () => {

    const history = useHistory();
    // @ts-ignore
    const {id} = useParams();

    return (
        <div>

            <Grid container justify={'space-between'} alignItems={"center"}>
                <Grid item>
                    <Grid item container alignItems={'center'}>
                        <Grid item style={{marginLeft: -10}}>
                            <IconButton onClick={() => history.goBack()}>
                                <AddIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <div style={{fontSize: 18, fontWeight: 600, padding: 5}}>
                                {id ? 'Edit Account Head' : 'Add Account Head'}
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>


                </Grid>
            </Grid>

            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: '25px 0px 10px 25px',paddingBottom: 40}}>
                <AccountHeadForm mode={id ? 'edit': "create"} from={'account page'} id={id}/>
            </div>
        </div>
    );
};




export default AddAccountHeadPage;
