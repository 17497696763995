import React, {useState} from 'react';
import useAdAndBsHandler from "../../../../effects/useAdAndBsHandler";
import {convertAdToBs, convertBsToAd} from "../../../../config/dateFunctions";
import {Grid} from "@material-ui/core";
import {FaCalendarAlt} from "react-icons/fa";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import {Field} from "formik";
import AutoCompleteInput from "../../../shared/atom/formElements/autocompleteInput";
import {matchSorter} from "match-sorter";
import useSWR from "swr";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import DialogComponent from "../../../shared/atom/dialog";
import Calendar from "../../../commonPages/organisms/Calendar";

interface DateProps {
    ad : string[],
    bs : string[],
    from ?: 'transaction' | 'report'
}

const DateComponent: React.FC<DateProps> = (Props) => {

    const {ad, bs} = Props

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();
    const {data: costCenterOptions} = useSWR('/getCostCenter')

    const dashboard = useSelector((state: RootState) => state.dashboard);
    const {data: nepEngDates} = useSWR('/getNepEngDates')

    const [openDialog, setOpenDialog] = useState(false);

    const handleClick = () => {
        // setOpenDialog(prevState => !prevState);
    }

    const handleDateBsBlur = (form: any) => {

        //console.log(form.values);

        const transactionYearBs = form.values[bs[0]]
        const transactionMonthBs = form.values[bs[1]]
        const transactionDateBs = form.values[bs[2]]
        const dateInAd = convertBsToAd(transactionYearBs + '-' + transactionMonthBs + '-' + transactionDateBs, formatedNepEngDates);

        //console.log(dateInAd)

        // @ts-ignore
        if (!dateInAd) return;

        // @ts-ignore
        let deconstructedDate = dateInAd?.split('T')[0]?.split('-');
        // @ts-ignore
        form.setFieldValue(ad[0], deconstructedDate[0])
        // @ts-ignore
        form.setFieldValue(ad[1], parseInt(deconstructedDate[1]).toString())
        // @ts-ignore
        form.setFieldValue(ad[2], parseInt(deconstructedDate[2]).toString())


    }

    const handleDateAdBlur = (form: any) => {

        const transactionYearAd = form.values[ad[0]]
        const transactionMonthAd = form.values[ad[1]]
        const transactionDateAd = form.values[ad[2]]

        const dateInBs = convertAdToBs(transactionYearAd + '-' + transactionMonthAd + '-' + transactionDateAd, formatedNepEngDates);

        console.log('date bs ', dateInBs)
        //console.log(dateInBs)
        // @ts-ignore
        if (!dateInBs) return;

        // @ts-ignore
        form.setFieldValue(bs[0], dateInBs[0])
        // @ts-ignore
        form.setFieldValue(bs[1], parseInt(dateInBs[1]).toString())
        // @ts-ignore
        form.setFieldValue(bs[2], parseInt(dateInBs[2]).toString())


    }

    return (
        <>
            <Grid item container xs={12} sm={6} style={{paddingRight: 3 }}
                  onClick={handleClick}
            >
                <Grid item container xs={12}
                      justify={"center"} spacing={1}>
                    <Grid item>
                        <div>AD</div>
                    </Grid>
                    <Grid item>
                        <FaCalendarAlt style={{
                            fontSize: 12,
                            color: COLOR_PROFILE.buttonBlue
                        }}/>
                    </Grid>

                </Grid>

                <Grid item xs={4}>

                    <div style={{position: 'relative'}}>
                        <Field name={ad[0]}>
                            {
                                (props: { field: any; form: any; meta: any; }) => {

                                    const {field, form, meta} = props;

                                    return (
                                        <>
                                            <AutoCompleteInput type={'text'}
                                                               id={ad[0]} {...field}
                                                               size={"small"}
                                                               autoHighlight={true}
                                                               autoSelect={true}
                                                               forcePopupIcon={false}
                                                               disableClearable={true}
                                                               placeholder={'Year'}
                                                               onChange={(e, value) => {
                                                                   form.setFieldValue(ad[0], value)
                                                               }}
                                                               padding={0}
                                                               onBlur={() => handleDateAdBlur(form)}
                                                               options={englishYear}
                                                               getOptionLabel={option => option}
                                                               error={meta.touched && meta.error}
                                            />
                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                            </div>
                                        </>

                                    )
                                }
                            }
                        </Field>

                    </div>

                </Grid>
                <Grid item xs={4}>

                    <div style={{position: 'relative'}}>
                        <Field name={ad[1]}>
                            {
                                (props: { field: any; form: any; meta: any; }) => {

                                    const {field, form, meta} = props;


                                    return (
                                        <>
                                            <AutoCompleteInput type={'text'}
                                                               id={ad[1]} {...field}
                                                               size={"small"}
                                                               autoHighlight={true}
                                                               autoSelect={true}
                                                               forcePopupIcon={false}
                                                               placeholder={'Month'}
                                                               disableClearable={true}
                                                               onChange={(e, value) => {
                                                                   form.setFieldValue(ad[1], value)
                                                               }}
                                                               onBlur={() => handleDateAdBlur(form)}
                                                               padding={0}
                                                               options={englishMonths}
                                                               getOptionLabel={option => option}
                                                               error={meta.touched && meta.error}
                                            />
                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                            </div>
                                        </>

                                    )
                                }
                            }
                        </Field>

                    </div>

                </Grid>
                <Grid item xs={4}>

                    <div style={{position: 'relative'}}>
                        <Field name={ad[2]}>
                            {
                                (props: { field: any; form: any; meta: any; }) => {

                                    const {field, form, meta} = props;

                                    const getDaysOptions = () => {
                                        const transactionYearAd = form.values[ad[0]]
                                        const transactionMonthAd = form.values[ad[1]]
                                        if (!transactionMonthAd && !transactionMonthAd) return []
                                        let daysInMonth = new Date(transactionYearAd, transactionMonthAd, 0).getDate();
                                        let dateArray = [];
                                        for (let i = 0; i < daysInMonth; i++) {
                                            dateArray[i] = (i + 1).toString()
                                        }
                                        return dateArray;
                                    }

                                    return (
                                        <>
                                            <AutoCompleteInput type={'text'}
                                                               id={ad[2]} {...field}
                                                               size={"small"}
                                                               filterOptions={(options, {inputValue}) => {
                                                                   let filteredOptions = getDaysOptions()
                                                                   return matchSorter(filteredOptions, inputValue).sort((a:any, b:any) => parseInt(a)- parseInt(b));
                                                               }}
                                                               autoHighlight={true}
                                                               autoSelect={true}
                                                               forcePopupIcon={false}
                                                               placeholder={'Day'}
                                                               disableClearable={true}
                                                               onBlur={() => handleDateAdBlur(form)}
                                                               onChange={(e, value) => {
                                                                   form.setFieldValue(ad[2], value)
                                                               }}
                                                               options={costCenterOptions ? costCenterOptions.data : []}
                                                               loading={!costCenterOptions}
                                                               padding={0}

                                                               getOptionLabel={option => option}
                                                               error={meta.touched && meta.error}
                                            />
                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                            </div>
                                        </>

                                    )
                                }
                            }
                        </Field>

                    </div>

                </Grid>

            </Grid>

            <Grid item container xs={12} sm={6} style={{paddingLeft: 3}}>

                <Grid item xs={12} container
                      style={{ textAlign: 'center'}}
                      justify={"center"} spacing={1}>
                    <Grid item>
                        <div>BS</div>
                    </Grid>
                    <Grid item>
                        <FaCalendarAlt style={{
                            fontSize: 12,
                            color: COLOR_PROFILE.buttonBlue
                        }}/>
                    </Grid>
                </Grid>


                <Grid item xs={4} style={{marginTop: 0}}>

                    <div style={{position: 'relative'}}>
                        <Field name={bs[0]}>
                            {
                                (props: { field: any; form: any; meta: any; }) => {

                                    const {field, form, meta} = props;


                                    return (
                                        <>
                                            <AutoCompleteInput type={'text'}
                                                               id={bs[0]} {...field}
                                                               size={"small"}
                                                               placeholder={'Year'}
                                                               disableClearable={true}
                                                               onChange={(e, value) => {
                                                                   form.setFieldValue(bs[0], value)
                                                               }}
                                                               onBlur={() => handleDateBsBlur(form)}
                                                               autoHighlight={true}
                                                               autoSelect={true}
                                                               options={nepaliYear}
                                                               padding={0}
                                                               forcePopupIcon={false}
                                                               getOptionLabel={option => option}
                                                               error={meta.touched && meta.error}
                                            />
                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                            </div>
                                        </>

                                    )
                                }
                            }
                        </Field>

                    </div>

                </Grid>
                <Grid item xs={4} style={{marginTop: 0}}>

                    <div style={{position: 'relative'}}>
                        <Field name={bs[1]}>
                            {
                                (props: { field: any; form: any; meta: any; }) => {

                                    const {field, form, meta} = props;


                                    return (
                                        <>
                                            <AutoCompleteInput type={'text'}
                                                               id={bs[1]} {...field}
                                                               size={"small"}
                                                               placeholder={'Month'}
                                                               disableClearable={true}
                                                               onChange={(e, value) => {
                                                                   form.setFieldValue(bs[1], value)
                                                               }}
                                                               forcePopupIcon={false}
                                                               padding={0}
                                                               onBlur={() => handleDateBsBlur(form)}
                                                               autoHighlight={true}
                                                               autoSelect={true}
                                                               options={nepaliMonths}
                                                               getOptionLabel={option => option}
                                                               error={meta.touched && meta.error}
                                            />
                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                            </div>
                                        </>

                                    )
                                }
                            }
                        </Field>

                    </div>

                </Grid>
                <Grid item xs={4} style={{marginTop: 0}}>

                    <div style={{position: 'relative'}}>
                        <Field name={bs[2]}>
                            {
                                (props: { field: any; form: any; meta: any; }) => {

                                    const {field, form, meta} = props;

                                    const getDateOptions = () => {

                                        const transactionYearBs = form.values[bs[0]]
                                        const transactionMonthBs = form.values[bs[1]]
                                        if (!transactionMonthBs && !transactionMonthBs) return [];

                                        // @ts-ignore
                                        return formatedNepEngDates.filter((item: any) => (parseInt(item.nepaliYear) === parseInt(transactionYearBs)) && (parseInt(item.nepaliMonth) === parseInt(transactionMonthBs)))[0].days


                                    }


                                    return (
                                        <>
                                            <AutoCompleteInput type={'text'}
                                                               id={bs[2]} {...field}
                                                               size={"small"}
                                                               filterOptions={(options, {inputValue}) => {
                                                                   let filteredOptions = getDateOptions()
                                                                   return matchSorter(filteredOptions, inputValue).sort((a:any, b:any) => parseInt(a)- parseInt(b));
                                                               }}
                                                               placeholder={'Day'}
                                                               disableClearable={true}
                                                               padding={0}
                                                               onChange={(e, value) => {
                                                                   form.setFieldValue(bs[2], value)
                                                               }}
                                                               autoHighlight={true}
                                                               autoSelect={true}
                                                               options={[]}
                                                               forcePopupIcon={false}
                                                               onBlur={() => handleDateBsBlur(form)}
                                                               getOptionLabel={option => option}
                                                               error={meta.touched && meta.error}
                                            />
                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                            </div>
                                        </>

                                    )
                                }
                            }
                        </Field>

                    </div>

                </Grid>

            </Grid>

            {/*<DialogComponent open={openDialog} handleClose={handleClick}>*/}
            {/*    <div style={{paddingBottom: 20, marginTop: -40, paddingTop: 20}}>*/}
            {/*        <Calendar/>*/}
            {/*    </div>*/}
            {/*</DialogComponent>*/}
        </>
    )

}

export default DateComponent;
