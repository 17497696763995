import React from 'react';
import {CircularProgress, CircularProgressProps} from "@material-ui/core";

interface Props extends CircularProgressProps {
    circleColor: string
}


const CircularSpinner: React.FC<Props> = ({size, circleColor}) => {
    return (
        <div>
            <CircularProgress
                style={{color: circleColor}}
                size={size}
                disableShrink={true}
                // color={color || 'primary'}
            />

        </div>
    );
};

export default CircularSpinner;
