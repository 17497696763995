import React from 'react';
import PageTopBar from "../../../organisms/pageTopBar";
import DividerComponent from "../../../../shared/atom/divider";
import {Grid} from "@material-ui/core";
import ReceiptVoucherForm from "../../../organisms/Forms/receiptVoucher";
import {useParams} from "react-router-dom";

const AddReceiptVoucher: React.FC = () => {

    // @ts-ignore
    const {id} = useParams();


    return (
        <div>
            <div>
                <PageTopBar name={id ? 'Edit Receipt Entry' : 'Receipt Entry'}/>
            </div>


            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>


            <div>
                <div style={{maxWidth: 1150, paddingLeft: 42, paddingRight: 42, paddingBottom: 60}}>
                    <Grid container justify={"space-between"}>
                        <Grid item xs={12}>
                            <ReceiptVoucherForm mode={id ? 'edit' : 'create'} id={id}/>
                        </Grid>

                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default AddReceiptVoucher;
