import React, {useState} from 'react';
import AccessFilter from "../../../../accessControl/accessFilter";
import SetupAddNew from "../../../accounting/organisms/setupAddNew";
import PurchaseFilter from "../../organisms/forms/purchaseFilter";
import PurchaseBillTable from "../../organisms/tables/purchaseBillTable";
import ExpenseEntryTable from "../../organisms/tables/expenseEntryTable";

const ExpenseEntryHome = () => {
    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})

    return (
        <div>

            <AccessFilter

                section={"expenseBill"}
                privilege={'write'}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>


                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/purchase/expense-entry/add',
                                            name: 'Add Expense Bill'
                                        }}


                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>Create Expense Bill by clicking the button below
                                                </div>

                                            </div>
                                        }

                                    />
                                </div>

                            </div>
                        </div>
                    )

                }}

            />

            <div style={{maxWidth: 1000}}>
                <div style={{padding: '0 30px 0 28px'}}>

                    <div style={{marginBottom: -10}}>
                        <PurchaseFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                        submitting={submitting} from={'purchase-order'}/>
                    </div>

                    <ExpenseEntryTable formState={formState} setSubmitting={setSubmitting}/>
                </div>
            </div>

        </div>
    );
};

export default ExpenseEntryHome;