import React, {useState} from 'react';
import AccessFilter from "../../../../accessControl/accessFilter";
import SetupAddNew from "../../../accounting/organisms/setupAddNew";
import PurchaseFilter from "../../../purchase/organisms/forms/purchaseFilter";
import PurchaseBillTable from "../../../purchase/organisms/tables/purchaseBillTable";
import SalesFilter from "../../organisms/forms/salesFilter";
import EstimateTable from "../../organisms/tables/estimateTable";
import axiosInstance from "../../../../config/axiosConfig";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";
import Button from "../../../shared/atom/button";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import CircularSpinner from "../../../shared/atom/spinner";

const EstimateHome =  () => {
    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: '', isJournaled: 'Without Journal'})
    const [checked, setChecked] = useState<any>([]);

    const handleClick = (name: number) => {

        let checkedList = [...checked];

        if (checked.includes(name)) {
            checkedList.splice(checkedList.indexOf(name), 1)
        } else {
            checkedList.push(name)
        }

        setChecked(checkedList);

    }

    const handleEnableDisable = async (item: any) => {

        if (checked.length === 0) return;

        try {

            setSubmitting(true);

            let payload = {
                ids: checked.map((item: any) => parseInt(item)),
                model: null,
                message: null
            };


            const {data} = await axiosInstance.put(`/convertToJrs`, payload)

            if(data.code === 'OK') {
                setChecked([]);
                handleResponse(data.message, 'success')
            }   else{
                handleResponse(data.message, 'warning')
            }

        } catch (e) {

            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')

        }
        setSubmitting(false);

    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    return (
        <div>

            <AccessFilter

                section={"estimate"}
                privilege={'write'}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>


                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/sales/estimate/add',
                                            name: 'Create Estimate'
                                        }}

                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>
                                                    Create Proforma/Estimate by clicking the button below
                                                </div>
                                            </div>
                                        }

                                    />
                                </div>

                            </div>
                        </div>
                    )

                }}

            />

            <div style={{maxWidth: 1000}}>
                <div style={{padding: '0 30px 0 28px'}}>

                    <div style={{marginBottom: -10}}>
                        <SalesFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                        submitting={submitting} from={'estimate'}/>
                    </div>

                    {checked.length > 0 && (
                        <div
                            style={{
                                position: 'fixed',
                                bottom: 0,
                                zIndex: 100,
                                width: '100%',
                                paddingTop: 10,
                                background: '#fafafa',
                                paddingBottom: 20
                            }}
                        >
                            <Grid container justify={"flex-start"} spacing={2} alignItems={'center'}>
                                <Grid item>
                                    <div style={{maxWidth: 450}}>
                                        <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                textColor={'white'}
                                                disabled={submitting}
                                                onClick={handleEnableDisable}
                                                style={{width: 200}}
                                        >
                                            <span style={{fontSize: 15}}>
                                                Make Journal Entries
                                            </span>
                                        </Button>
                                    </div>
                                </Grid>

                                <Grid item>
                                    {submitting &&
                                    <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                                </Grid>

                            </Grid>
                        </div>
                    )}


                    <div style={{paddingBottom: checked.length > 0 ? 40: undefined}}>

                    <EstimateTable handleClick={handleClick} checked={checked} formState={formState}
                                   setSubmitting={setSubmitting} submitting={submitting}
                    />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default EstimateHome;