import React from 'react';
import SetupAddNew from "../../../organisms/setupAddNew";
import BankTable from "../../../organisms/tables/bankTable";
import AccessDenied from "../../../templates/errorTemplates/accessDenied";
import AccessFilter from "../../../../../accessControl/accessFilter";

const BankPage: React.FC = () => {
    return (
        <div>


            <div style={{maxWidth: 1000 }}>

                <AccessFilter
                    section={"bank"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <div style={{padding: 20}}>
                                        <SetupAddNew

                                            buttonNameAdd={{pathTo: '/dashboard/company-setup/banks/add', name: 'Add Bank'}}
                                            // buttonNameImport={{pathTo: '', name: 'Import Vendors from Head Office'}}

                                            message={
                                                <div style={{
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    padding: '10px 10px 10px 5px'
                                                }}>
                                                    <div>Click the button below to add a bank.
                                                    </div>

                                                </div>
                                            }

                                        />
                                    </div>
                                </div>

                            </div>

                        )

                    }}

                />




                <AccessFilter
                    section={"bank"}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <div style={{padding: '0 30px 0 28px'}}>
                                        <BankTable/>
                                    </div>

                                </div>

                            </div>

                        )

                    }}

                />


            </div>

        </div>
    );
};

export default BankPage;
