import React, {useEffect, useMemo, useState} from 'react';
import {Checkbox, Grid, IconButton} from "@material-ui/core";
import useSWR, {trigger} from "swr";
import DeleteModal from "../../deleteModal";
import ReactTable from "../../reactTable/ReactTable";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import Button from "../../../../shared/atom/button";
import CircularSpinner from "../../../../shared/atom/spinner";
import axiosInstance from "../../../../../config/axiosConfig";
import {useDispatch} from "react-redux";
import DataEmptyMessage from "../dataEmptyMessage";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import {useHistory} from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";


const VerifyRequestsTable: React.FC<any> = (Props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [deleteId, setDeleteId] = useState(null)
    const [checked, setChecked] = useState<any>([]);
    const [mappedItem, setMappedItem] = useState<any>({});
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {

        setMappedItem(Props.selectedInfo(Props.selectedItem))


        console.log('mapped item', Props.selectedItem)
        setPage(1);

    }, [Props.selectedItem, Props.selectedInfo])

    const handleClick = (name: number) => (event: React.ChangeEvent<HTMLInputElement>) => {

        let checkedList = [...checked];

        if (checked.includes(name)) {
            checkedList.splice(checkedList.indexOf(name), 1)

        } else {
            checkedList.push(name)
        }

        setChecked(checkedList);

    }

    const history = useHistory();

    const branchColumns = [

        {
            id: 'delete',
            Header: 'Select',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {

                let id: any = val.row.original.id;
                // @ts-ignore
                return (
                    <Grid container spacing={3} alignItems={'center'} justify={"center"}>


                        <Grid item>

                            <Checkbox
                                color={"primary"}
                                size={"small"}
                                style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                                checked={checked.indexOf(id) !== -1}
                                onChange={handleClick(id)}
                            />

                        </Grid>

                    </Grid>
                )
            }
        },

        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },

        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'code'
        },
        {
            Header: 'Description',
            Footer: 'Description',
            accessor: 'description'
        },


    ]

    const bankColumns = [

        {
            id: 'delete',
            Header: 'Select',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {

                let id: any = val.row.original.id;
                // @ts-ignore
                return (
                    <Grid container spacing={3} alignItems={'center'} justify={"center"}>


                        <Grid item>

                            <Checkbox
                                color={"primary"}
                                size={"small"}
                                style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                                checked={checked.indexOf(id) !== -1}
                                onChange={handleClick(id)}
                            />

                        </Grid>

                    </Grid>
                )
            }
        },
        {
            Header: 'Bank Name',
            Footer: 'Bank Name',
            accessor: 'bankDefault.name'
        },
        {
            Header: 'Acc. Name',
            Footer: 'Acc. Name',
            accessor: 'accountName'
        },

        {
            Header: 'Account Number',
            Footer: 'Account Number',
            accessor: 'accountNumber'
        },



    ]
    const voucherColumns = [

        {
            id: 'delete',
            Header: 'Select',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {

                let id: any = val.row.original.id;
                // @ts-ignore
                return (
                    <Grid container spacing={3} alignItems={'center'} justify={"center"}>


                        <Grid item>

                            <Checkbox
                                color={"primary"}
                                size={"small"}
                                style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                                checked={checked.indexOf(id) !== -1}
                                onChange={handleClick(id)}
                            />

                        </Grid>

                    </Grid>
                )
            }
        },

        {
            Header: 'Transaction Date',
            accessor: 'transactionDate'
        },

        {
            Header: 'Voucher Number',
            Footer: 'Voucher Number',
            accessor: 'voucherNumber'
        },
        {
            Header: 'Description',
            Footer: 'Description',
            accessor: 'description'
        },


    ]
    const purchaseRfpColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (

                <Grid container spacing={3} justify='center' alignItems={"center"}
                      style={{marginRight: 30, paddingRight: 20}}>

                    <Grid item xs={5}>
                        <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                    onClick={() => {
                                        history.push(`/dashboard/purchase/request-for-proposal/view/${val.row.original.id || 1}`, val.row.original)
                                    }}>
                            <VisibilityIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>


                    <Grid item xs={6}>

                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                            checked={checked.indexOf(val.row.original.id) !== -1}
                            onChange={handleClick(val.row.original.id)}
                        />

                    </Grid>
                </Grid>
            )
        },
        {
            Header: 'RFP No.',
            Footer: 'RFP No.',
            accessor: 'rfpNumber'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },

        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} = cellObj.cell
                return <div>{original?.productSubs?.map((item: any) => item.name).join(', ')}</div>
            }
        },
        {
            Header: 'Issue Date',
            Footer: 'Issue Date',
            accessor: 'issueDate'
        },
        {
            Header: 'Expiry Date',
            Footer: 'Expiry Date',
            accessor: 'expiryDate'
        },

        {
            Header: 'Vendor',
            Footer: 'Vendor',
            accessor: 'vendor.name'
        },
    ]
    const purchaseOrderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' alignItems={"center"}
                      style={{marginRight: 30, paddingRight: 20}}>

                    <Grid item xs={6}>
                        <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                    onClick={() => {
                                        history.push(`/dashboard/purchase/purchase-order/view/${val.row.original.id}`, val.row.original)
                                    }}>
                            <VisibilityIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>


                    <Grid item xs={6}>

                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                            checked={checked.indexOf(val.row.original.id) !== -1}
                            onChange={handleClick(val.row.original.id)}
                        />

                    </Grid>

                </Grid>
            )
        },
        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'orderNumber'
        },
        {
            Header: 'PO Date',
            Footer: 'PO Date',
            accessor: 'date'
        },
        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} = cellObj.cell
                return <div>{original?.productSubs?.map((item: any) => item.name).join(', ')}</div>
            }
        },
        {
            Header: 'Due Date',
            Footer: 'Due Date',
            accessor: 'paymentDueDate'
        },
        {
            Header: 'Initial Payment',
            Footer: 'Initial Payment',
            accessor: 'initialPayment'
        },
        {
            Header: 'Vendor',
            Footer: 'Vendor',
            accessor: 'vendor.name'
        },
    ]
    const purchaseBillColumns = [
        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' alignItems={"center"}
                      style={{marginRight: 30, paddingRight: 20}}>

                    <Grid item xs={6}>
                        <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                    onClick={() => {
                                        history.push(`/dashboard/purchase/purchase-bill/view/${val.row.original.id}`, val.row.original)
                                    }}>
                            <VisibilityIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>


                    <Grid item xs={6}>

                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                            checked={checked.indexOf(val.row.original.id) !== -1}
                            onChange={handleClick(val.row.original.id)}
                        />

                    </Grid>

                </Grid>
            )
        },
        {
            Header: 'Bill No.',
            Footer: 'Bill No.',
            accessor: 'billNumber'
        },

        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} = cellObj.cell
                return <div>{original?.productSubs?.map((item: any) => item.name).join(', ')}</div>
            }
        },
        {
            Header: 'Bill Date',
            Footer: 'Bill Date',
            accessor: 'billDate'
        },
        {
            Header: 'Payment Mode',
            Footer: 'Payment Mode',
            accessor: 'paymentMethod.name'
        },

        {
            Header: 'Vendor',
            Footer: 'Vendor',
            accessor: 'vendorDto.name'
        },
    ]
    const expenseBillColumns = [
        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' alignItems={"center"}
                      style={{marginRight: 30, paddingRight: 20}}>

                    <Grid item xs={6}>
                        <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                    onClick={() => {
                                        history.push(`/dashboard/purchase/expense-entry/view/${val.row.original.id}`, val.row.original)
                                    }}>
                            <VisibilityIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>


                    <Grid item xs={6}>

                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                            checked={checked.indexOf(val.row.original.id) !== -1}
                            onChange={handleClick(val.row.original.id)}
                        />

                    </Grid>

                </Grid>
            )
        },
        {
            Header: 'Bill No.',
            Footer: 'Bill No.',
            accessor: 'expenseEntryNumber'
        },

        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} = cellObj.cell
                return <div>{original?.productSubs?.map((item: any) => item.name).join(', ')}</div>
            }
        },
        {
            Header: 'Bill Date',
            Footer: 'Bill Date',
            accessor: 'purchaseDate'
        },
        {
            Header: 'Payment Mode',
            Footer: 'Payment Mode',
            accessor: 'paymentMethod.name'
        },

        {
            Header: 'Vendor',
            Footer: 'Vendor',
            accessor: 'vendorDto.name'
        },
    ]
    const purchaseReturnColumns = [
        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' alignItems={"center"}
                      style={{marginRight: 30, paddingRight: 20}}>

                    <Grid item xs={6}>
                        <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                    onClick={() => {
                                        history.push(`/dashboard/purchase/purchase-return/view/${val.row.original.id}`, val.row.original)
                                    }}>
                            <VisibilityIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>


                    <Grid item xs={6}>

                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                            checked={checked.indexOf(val.row.original.id) !== -1}
                            onChange={handleClick(val.row.original.id)}
                        />

                    </Grid>

                </Grid>
            )
        },
        {
            Header: 'Return No.',
            Footer: 'Return No.',
            accessor: 'code'
        },

        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} = cellObj.cell
                return <div>{original?.productSubs?.map((item: any) => item.name).join(', ')}</div>
            }
        },
        {
            Header: 'Return Date',
            Footer: 'Return Date',
            accessor: 'returnDate'
        },
        {
            Header: 'Vendor',
            Footer: 'Vendor',
            accessor: 'vendorDto.name'
        },
        {
            Header: 'Purchase Bill',
            Footer: 'Purchase Bill',
            accessor: 'purchaseBillNumber'
        },
    ]
    const estimateColumns = [
        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' alignItems={"center"}
                      style={{marginRight: 30, paddingRight: 20}}>

                    <Grid item xs={6}>
                        <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                    onClick={() => {
                                        history.push(`/dashboard/sales/estimate/view/${val.row.original.id}`, val.row.original)
                                    }}>
                            <VisibilityIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>


                    <Grid item xs={6}>

                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                            checked={checked.indexOf(val.row.original.id) !== -1}
                            onChange={handleClick(val.row.original.id)}
                        />

                    </Grid>

                </Grid>
            )
        },
        {
            Header: 'Estimate No.',
            Footer: 'Estimate No.',
            width: 30,
            accessor: 'estimateNumber'
        },
        {
            Header: 'Customer',
            Footer: 'Customer',
            accessor: 'customer.name'
        },
        {
            Header: 'Estimate Date',
            Footer: 'Estimate Date',
            accessor: 'estimateDate'
        },
        {
            Header: 'Expiry Date',
            Footer: 'Expiry Date',
            accessor: 'expiryDate'
        },
    ]
    const salesReturnColumn = [
        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' alignItems={"center"}
                      style={{marginRight: 30, paddingRight: 20}}>

                    <Grid item xs={6}>
                        <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                    onClick={() => {
                                        history.push(`/dashboard/sales/return/view/${val.row.original.id}`, val.row.original)
                                    }}>
                            <VisibilityIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>


                    <Grid item xs={6}>

                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                            checked={checked.indexOf(val.row.original.id) !== -1}
                            onChange={handleClick(val.row.original.id)}
                        />

                    </Grid>

                </Grid>
            )
        },
        {
            Header: 'Return No.',
            Footer: 'Return No.',
            accessor: 'salesReturnNumber'
        },
        {
            Header: 'Customer',
            Footer: 'Customer',
            accessor: 'customerDto.name'
        },
        {
            Header: 'Return Date',
            Footer: 'Return Date',
            accessor: 'returnDate'
        }
    ]
    const categoryColumn = [
        {
        id: 'delete',
        Header: 'Actions',
        width: 10,
        alignment: 'center',
        Cell: (val: any) => (

            <Grid container spacing={3} justify='center' alignItems={"center"}
                  style={{marginRight: 30, paddingRight: 20}}>


                <Grid item xs={12}>

                    <Checkbox
                        color={"primary"}
                        size={"small"}
                        style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                        checked={checked.indexOf(val.row.original.id) !== -1}
                        onChange={handleClick(val.row.original.id)}
                    />

                </Grid>

            </Grid>


        )
    },
        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'code'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
    ];
    const uomColumn= [
        {
        id: 'delete',
        Header: 'Actions',
        width: 10,
        alignment: 'center',
        Cell: (val: any) => (
            <Grid container spacing={3} justify='center' alignItems={"center"}
                  style={{marginRight: 30, paddingRight: 20}}>


                <Grid item xs={12}>

                    <Checkbox
                        color={"primary"}
                        size={"small"}
                        style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                        checked={checked.indexOf(val.row.original.id) !== -1}
                        onChange={handleClick(val.row.original.id)}
                    />

                </Grid>

            </Grid>
        )
    },
        {
            Header: 'Code',
            Footer: 'Code',
            width: 50,
            accessor: 'code'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        }
        ];
    const itemColumn = [
        {
            id: 'delete',
            Header: 'Actions',
            // width: 40,
            alignment: 'center',
            Cell: (val: any) => {
                let id: any = val.row.original.id;
                return (
                    <Grid container spacing={3} justify='center' alignItems={"center"}
                          style={{marginRight: 30, paddingRight: 20}}>


                        <Grid item xs={12}>

                            <Checkbox
                                color={"primary"}
                                size={"small"}
                                style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                                checked={checked.indexOf(val.row.original.id) !== -1}
                                onChange={handleClick(val.row.original.id)}
                            />

                        </Grid>

                    </Grid>
                )
            }
        },
        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'code'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Purchase Price',
            Footer: 'Purchase Price',
            accessor: 'buyingPrice'
        },{
            Header: 'Selling Price',
            Footer: 'Selling Price',
            accessor: 'sellingPrice'
        },{
            Header: 'Allowed Discount',
            Footer: 'Allowed Discount',
            accessor: 'maxAllowableDiscount'
        },{
            Header: 'Quantity',
            Footer: 'Quantity',
            accessor: 'quantity'
        },
        {
            Header: 'Category',
            Footer: 'Category',
            accessor: 'categoryDto.name'
        },

    ];
    const bundleColumn = [
        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' alignItems={"center"}
                      style={{marginRight: 30, paddingRight: 20}}>


                    <Grid item xs={12}>

                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                            checked={checked.indexOf(val.row.original.id) !== -1}
                            onChange={handleClick(val.row.original.id)}
                        />

                    </Grid>

                </Grid>
            )
        },
        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'code'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },

        {
            Header: 'Bundle Price',
            Footer: 'Bundle Price',
            accessor: 'bundlePrice'
        },
        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} = cellObj.cell
                return <div>{original?.productSub?.map((item: any) => item.name + '(' + item.quantity + ')').join(', ')}</div>
            }
        },
        {
            Header: 'Effective Days',
            Footer: 'Effective Days',
            accessor: 'effectiveDays'
        },
        {
            Header: 'Effective From',
            Footer: 'Effective From',
            accessor: 'effectiveFrom'
        },
        {
            Header: 'Effective To',
            Footer: 'Effective To',
            accessor: 'effectiveTo'
        },
    ];
    const offerColumn = [
        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' alignItems={"center"}
                      style={{marginRight: 30, paddingRight: 20}}>


                    <Grid item xs={12}>

                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                            checked={checked.indexOf(val.row.original.id) !== -1}
                            onChange={handleClick(val.row.original.id)}
                        />

                    </Grid>

                </Grid>
            )
        },
        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'code'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Purchase Price',
            Footer: 'Purchase Price',
            accessor: 'buyingPrice'
        },{
            Header: 'Selling Price',
            Footer: 'Selling Price',
            accessor: 'sellingPrice'
        },{
            Header: 'Allowed Discount',
            Footer: 'Allowed Discount',
            accessor: 'maxAllowableDiscount'
        },{
            Header: 'Quantity',
            Footer: 'Quantity',
            accessor: 'quantity'
        },
    ];
    const adjustmentColumn = [
        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' alignItems={"center"}
                      style={{marginRight: 30, paddingRight: 20}}>


                    <Grid item xs={12}>

                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            style={{color: COLOR_PROFILE.buttonGreen, fontSize: 10}}
                            checked={checked.indexOf(val.row.original.id) !== -1}
                            onChange={handleClick(val.row.original.id)}
                        />

                    </Grid>

                </Grid>
            )
        },
        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'adjustmentNumber'
        },

        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} =cellObj.cell
                return <div>{original?.productSubs?.map((item: any) => item.name + '('+ item.quantity+')').join(', ')}</div>
            }
        },

        {
            Header: 'Vendor',
            Footer: 'Vendor',
            accessor: 'vendorDto.name'
        },
        {
            Header: 'Description',
            Footer: 'Description',
            accessor: 'description'
        },
    ];

    const path = '/toApprove'

    const [loading, setLoading] = useState(false);

    console.log()
    const {data} = useSWR((Props.selectedItem && mappedItem) ? mappedItem.withoutPrams ? `${mappedItem.path}/${page - 1}/${perPage}` : `${path}/${page - 1}/${perPage}?name=${mappedItem.path}` : null)
    // const {data, loading} = useServerCall({path, page, perPage})

    useEffect(() => {
        setLoading(true)
        if (!data) return
        setLoading(false)

    }, [data])


    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [];

    const journals = ['Journal', 'Payment', 'Receipt']
    const mapper: any = {
        'Request for Proposal': purchaseRfpColumns,
        'Purchase Order': purchaseOrderColumns,
        'Purchase Bill Entry': purchaseBillColumns,
        'Expense Entry': expenseBillColumns,
        'Purchase Return': purchaseReturnColumns,
        'Proforma': estimateColumns,
        'Sales Return': salesReturnColumn,
        'Category': categoryColumn,
        'UOM': uomColumn,
        'Product Item': itemColumn,
        'Product Bundle': bundleColumn,
        'Sales/ Offer': offerColumn,
        'Inventory Adjustment': adjustmentColumn,
        'Bank': bankColumns

    }

    const getColumns = () => {
        if (mapper[Props.selectedItem]) return mapper[Props.selectedItem]
        return journals.includes(Props.selectedItem) ? voucherColumns : branchColumns
    }

    const mockData = useMemo(() => calledData, [data, checked, Props.selectedInfo]);
    const columns = useMemo(() => getColumns(), [checked, Props.selectedItem]);


    const handleVerify = async () => {

        if (checked.length === 0) {
            handleResponse('Please select an entry to verify.', 'warning')
            return;
        }

        try {
            setSubmitting(true)

            const payload = {
                ids: checked,
                model: null,
                message: null
            }

            const {data} = await axiosInstance.put(`${mappedItem.approval}`, payload);

            if (data?.code === 'OK') {
                if (data?.message.includes('approved')) {
                    handleResponse('Approved successfully.', 'success')
                    setChecked([])
                } else {
                    handleResponse('Something went wrong', 'warning')
                }
                trigger(mappedItem.withoutPrams ? `${mappedItem.path}/${page - 1}/${perPage}` : `${path}/${page - 1}/${perPage}?name=${mappedItem.path}`)
            }

            setSubmitting(false)

        } catch (e) {

            handleResponse('Something went wrong', 'warning')
            setSubmitting(false)
        }


    }


    const dispatch = useDispatch();


    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div>
                    <DeleteModal message={`Are you sure? Once deleted it cannot be reverted.`}
                                 trigger={`/getBranches/${page - 1}/${perPage}`}
                                 deletePath={`/deleteBranch/${deleteId}`} open={deleteId}
                                 handleDeleteCancel={() => setDeleteId(null)}
                    />
                    <div style={{paddingBottom: 20}}>


                        <ReactTable
                            data={mockData}
                            columns={columns}
                            setPage={setPage}
                            setPerPage={setPerPage}
                            currentpage={page}
                            perPage={perPage}
                            totalPage={data?.t.totalPage}
                        />
                    </div>
                    <div style={{
                        width: '100%',
                        background: '#fafafa',
                        position: "relative"
                    }}>

                        <div
                            style={{
                                position: 'absolute',
                                float: 'left',
                                bottom: 0,
                                left: '0%',
                                paddingTop: 20,
                                width: '100%',
                                background: '#fafafa',
                                paddingBottom: 20
                            }}
                        >

                            <Grid container justify={"center"} spacing={2} alignItems={'center'} style={{marginRight: 20}}>
                                <Grid item>
                                    <div style={{maxWidth: 200}}>
                                        <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                textColor={'white'}
                                                disabled={submitting}
                                                onClick={handleVerify}
                                                style={{width: 170}}
                                        >
                                            <span style={{fontSize: 18}}>
                                            Verify
                                            </span>
                                        </Button>
                                    </div>
                                </Grid>

                                <Grid item>
                                    {submitting &&
                                    <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                                </Grid>

                            </Grid>
                        </div>

                    </div>
                </div>
            ) : (

                (page === 1 && loading) ? <CircularSpinner circleColor={COLOR_PROFILE.buttonBlue}/>
                    : <DataEmptyMessage/>


            )}
        </div>
    );
};

export default VerifyRequestsTable;
