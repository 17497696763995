import React from 'react';
import {Grid, IconButton} from "@material-ui/core";
import DividerComponent from "../../../../shared/atom/divider";
import AddIcon from "@material-ui/icons/ArrowBack";
import {useHistory, useParams} from "react-router-dom";
import AccountGroupForm from "../../../organisms/Forms/accountGroupForm";

const AddAccountGroupPage: React.FC = () => {

// @ts-ignore
    const {id} = useParams();
    const history = useHistory();


    return (
        <div>

            <Grid container justify={'space-between'} alignItems={"center"}>
                <Grid item>
                    <Grid item container alignItems={'center'}>
                        <Grid item style={{marginLeft: -10}}>
                            <IconButton onClick={() => history.goBack()}>
                                <AddIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <div style={{fontSize: 18, fontWeight: 600, padding: 5}}>
                                {id ? 'Edit Account Group' : 'Add Account Group'}
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>


                </Grid>
            </Grid>


            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: '25px 0px 10px 25px', paddingBottom: 40}}>

                <AccountGroupForm mode={id ? 'edit': "create"} from={'account page'} id={id}/>
            </div>


        </div>
    );
};


export default AddAccountGroupPage;
