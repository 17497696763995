import {useEffect, useState} from "react";
import useSWR from "swr";


const useAddressHandler = (Props: any) => {

    const {province, district, vdc, ward} = Props;

    const [provinceOptions, setProvinceOptions] = useState<any>([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [vdcOptions, setVcdOptions] = useState([]);
    const [wardOptions, setWardOptions] = useState([]);
    const [provinceLoading, setProvinceLoading] = useState(false);
    const [vdcLoading, setVdcLoading] = useState(false);


    const {data: tempCountryData} = useSWR('/address/getCountries')

    useEffect(() => {

        try {

            setProvinceLoading(true)

            if (!tempCountryData) {
                setProvinceLoading(false)
                return;
            }

            const province = tempCountryData[0]['provinceOrStates'].map((item: any) => {
                return {
                    province: item.name,
                    districts: item.districts
                }
            })

            setProvinceOptions(province)
            setProvinceLoading(false)

        } catch (e) {

        }
    }, [tempCountryData])


    useEffect(() => {


        try {


            if (!province) return;

            const tempDistrictOptions = province['districts'].map((item: any) => {
                return {
                    id: item.id,
                    district: item.name,
                    municipalities: item.municipalityOrVdcs
                }
            })

            setDistrictOptions(tempDistrictOptions);

        } catch (e) {

        }
    }, [province])


    const {data: tempMunicipalityOptions} = useSWR(district ? '/address/getMunicipalityOrVdcs/' + district?.id : null)

    useEffect(() => {
        setVdcLoading(true);
    }, [district])

    useEffect(() => {

        try {

            if (!tempMunicipalityOptions) {
                setVdcLoading(false);
                return;
            }
            if (!district.id) return;

            setVcdOptions(tempMunicipalityOptions);
            setVdcLoading(false);


        } catch (e) {

        }

    }, [tempMunicipalityOptions])

    useEffect(() => {

        try {
            if (!vdc) return;

            setWardOptions(vdc.wardNumbers.split(',').map((item: any) => item.toString()))

        } catch (e) {

        }

    }, [vdc])


    return {provinceOptions, districtOptions, vdcOptions, wardOptions, provinceLoading, vdcLoading}

}

export default useAddressHandler;
