
const  dashboardReducer = (state:any = {}, action: any) => {
    switch (action.type) {
        case 'SET_DASHBOARD':
            state = {...action.payload};
            return state;
        default:
            return state
    }
}

export default dashboardReducer;

