import React from 'react';
import ProductItemAddition from "../../templates/productItemAddition";

const ProductItemAdd = () => {
    return (
        <div>
            <ProductItemAddition/>
        </div>
    );
};

export default ProductItemAdd;