import React from 'react';
import PurchaseOrderHome from "../../templates/purchaseOrderHome";

const PurchaseOrder = () => {
    return (
        <div>
            <PurchaseOrderHome/>
        </div>
    );
};

export default PurchaseOrder;