import React, {useEffect} from 'react';
import {Grid, IconButton, LinearProgress} from "@material-ui/core";
import AddIcon from "@material-ui/icons/ArrowBack";
import {useHistory} from 'react-router-dom';
import {COLOR_PROFILE} from "../../../../config/colorCode";
import CircularSpinner from "../../../shared/atom/spinner";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import React_2 from "framer-motion/dist/framer-motion";


interface Props {
    name: string
}

const PageTopBar: React.FC<Props> = (Props) => {

    const history = useHistory();
    const {fetching} = useSelector((state: RootState) => state.formState);

    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {


                if (oldProgress === 101) {
                    return 101;
                }
                let diff;
                if(!fetching) {
                    diff = 100;
                }else{
                   diff  = Math.random() * 30;
                }

                if((fetching && diff + oldProgress) > 100 ) return oldProgress;
                return Math.min(oldProgress + diff, 100);
            });
        }, 0.2);

        if(progress === 101) clearInterval(timer);
        return () => {
            clearInterval(timer);
        };
    }, [fetching, progress]);

    useEffect(() => {
        if(progress === 100) {
            setTimeout(() => {
                setProgress(101)
            }, 10)
        }
        // setProgress(100)
    }, [progress])

    return (
        <div>
            <Grid container justify={'space-between'} alignItems={"center"}>
                <Grid item>
                    <Grid item container alignItems={'center'}>
                        <Grid item style={{marginLeft: -10}}>
                            <IconButton onClick={() => history.goBack()}>
                                <AddIcon style={{color: COLOR_PROFILE.primaryColor}}/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <div style={{fontSize: 18, fontWeight: 600, color: COLOR_PROFILE.primaryColor, padding: 5}}>
                                {Props.name}
                            </div>
                        </Grid>

                    </Grid>

                </Grid>
                <Grid item>

                </Grid>
            </Grid>
            <div style={{marginBottom: -8, marginLeft: -23, marginRight: -23}}>
                { progress !== 101 ? <LinearProgress variant="determinate" value={progress}/> : <div style={{paddingTop: 4}}> </div>}
            </div>

        </div>
    );
};

export default PageTopBar;
