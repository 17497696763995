import React, {useEffect, useState} from 'react';
import Button from "../../../../shared/atom/button";
import {Grid, InputAdornment} from "@material-ui/core";
import axiosInstance from "../../../../../config/axiosConfig";
import {useHistory} from 'react-router-dom';
import CircularSpinner from "../../../../shared/atom/spinner";
import axios from "axios";
import baseUrl from "../../../../../config/baseVariables";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import InputField from "../../../../shared/atom/formElements/inputField";
import RequiredNotation from "../../../molecules/requiredNotation";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import PagePrompt from "../../../../shared/organisms/pagePrompt";
import {useDispatch} from "react-redux";
import {cache} from "swr";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Logo from "../../../../../assets/logo.png";


interface loginProfile {
    email: string,
    password: string,
    alias: string
}

const LoginForm: React.FC = () => {


    const [form, setForm] = useState<loginProfile>({email: '', password: '', alias: ''})
    const [error, setError] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    let history = useHistory();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({type: "RESET_SELECTED_STATUS"})
        cache.clear();
    }, [])

    const onSubmit = async (values: any, formActions: any) => {
        delete axiosInstance.defaults.headers.common['Authorization'];
        localStorage.removeItem('token')

        try {

            let reqData = {
                "email": values.email.toLowerCase().trim(),
                "password": values.password.trim(),
                "alias": values.alias.trim()
            }
            // const res = await axios.post(`${baseUrl}/authenticate`, reqData);
            const res = await axios.post(`${baseUrl}/login`, reqData);
            let {jwt} = res.data;
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${jwt}`
            const setLocal = await localStorage.setItem('token', jwt)
            history.push('/dashboard/home/setup/share-holders')
        } catch (e) {
            setError(e?.response ? e.response.data.message : 'Something went wrong!')
            formActions.setSubmitting(false)
        }

    }

    const validationSchema = Yup.object({
        email: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
        alias: Yup.string().required('Required'),
    })


    return (

        <div style={{maxWidth: 400, margin: '0 auto'}}>

            {error && (
                <div style={{margin: 10}}>
                    <div style={{padding: 10, backgroundColor: '#d06e6e', color: 'white', fontSize: 14}}>
                        {error}
                    </div>
                </div>
            )}

            <Formik
                initialValues={form}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {

                    return (
                        <div>
                            <PagePrompt formik={formik}/>

                            <Form style={{margin: 10}}>

                                <div>
                                    <img
                                        src={Logo}
                                        alt="logo"
                                        height="100px"
                                        width="100%"
                                        style={{ paddingTop: "0px", marginBottom: 10 }}
                                    />
                                </div>

                                <div >
                                    <Field name={'alias'}>
                                        {
                                            (props: { field: any; form: any; meta: any; }) => {

                                                const {field, form, meta} = props;


                                                return (
                                                    <>
                                                        <div style={{padding: '5px 0 5px 0',  color: COLOR_PROFILE.primaryColor, }}>
                                                            Alias<RequiredNotation/>
                                                        </div>
                                                        <InputField type={'string'} id={'alias'} {...field}
                                                                    size={"small"}
                                                                    fullWidth={true}
                                                                    error={meta.touched && meta.error}
                                                        />

                                                    </>

                                                )
                                            }
                                        }
                                    </Field>

                                </div>

                                <div >
                                    <Field name={'email'}>
                                        {
                                            (props: { field: any; form: any; meta: any; }) => {

                                                const {field, form, meta} = props;


                                                return (
                                                    <>
                                                        <div style={{padding: '5px 0 5px 0',  color: COLOR_PROFILE.primaryColor, }}>
                                                            Username<RequiredNotation/>
                                                        </div>
                                                        <InputField type={'string'} id={'email'} {...field}
                                                                    size={"small"}
                                                                    fullWidth={true}
                                                                    error={meta.touched && meta.error}
                                                        />

                                                    </>

                                                )
                                            }
                                        }
                                    </Field>

                                </div>

                                <div style={{ marginTop: 5}} >
                                    <Field name={'password'}>
                                        {
                                            (props: { field: any; form: any; meta: any; }) => {

                                                const {field, form, meta} = props;


                                                return (
                                                    <>
                                                        <div style={{padding: '5px 0 5px', color: COLOR_PROFILE.primaryColor,}}>
                                                            Password<RequiredNotation/>
                                                        </div>
                                                        <InputField type={!showPassword ? 'password': 'text'} id={'password'} {...field}
                                                                    size={"small"}
                                                                    fullWidth={true}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment
                                                                                position="end">
                                                                                {!showPassword ? (
                                                                                    <VisibilityIcon
                                                                                        fontSize={"small"}
                                                                                        style={{cursor: 'pointer', color: COLOR_PROFILE.primaryColor}}
                                                                                        onClick={() => setShowPassword(true)}
                                                                                    />
                                                                                ):(
                                                                                    <VisibilityOffIcon
                                                                                        fontSize={"small"}
                                                                                        style={{cursor: 'pointer', color: COLOR_PROFILE.primaryColor}}
                                                                                        onClick={() => setShowPassword(false)}
                                                                                    />
                                                                                )}

                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    error={meta.touched && meta.error}
                                                        />
                                                    </>

                                                )
                                            }
                                        }
                                    </Field>

                                </div>

                                <div style={{margin: '20px 0px 10px 0px'}}>

                                    <Grid container alignItems={"center"} justify={"space-between"}>

                                        <Grid item xs={8}>
                                            <Grid container alignItems={"center"} spacing={1}>
                                                <Grid item xs={4}>
                                                    <div style={{color: 'white'}}>
                                                        <Button type={'submit'} variant={"contained"}
                                                                customColor={'green'}

                                                                textColor={'white'} disabled={formik.isSubmitting}>
                                                            Login
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {formik.isSubmitting && <CircularSpinner size={30} circleColor={'blue'}/>}
                                                </Grid>
                                            </Grid>


                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "right"}}>
                                            <div>
                                                <a href={''} style={{color: 'gray', fontSize: 12}}>forget password?</a>
                                            </div>
                                        </Grid>


                                    </Grid>
                                </div>

                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    );
};

export default LoginForm;
