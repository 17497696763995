import React from 'react';
import Button from "../../../shared/atom/button";
import {Grid, useMediaQuery} from "@material-ui/core";
import CircularSpinner from "../../../shared/atom/spinner";
import {useHistory} from 'react-router-dom';
import {COLOR_PROFILE} from "../../../../config/colorCode";
import {useFormikContext} from "formik";
import {useTheme} from "@material-ui/core/styles";

interface Type {
    from: string,
    mode: string,
    submitting?: boolean,
    handleClose?: any,
    noFixed?: any
}

const FormSaveButton: React.FC<Type> = (Props) => {

    const {from, mode, submitting} = Props;

    const isFromHome = from === 'home';
    const isEdit = mode === 'edit';

    const history = useHistory();
    const formikInstance = useFormikContext();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const fixed = !isFromHome && !Props.noFixed;
    // const isSubmitting = submitting;
    const isInvoiceCancel = Props.from === 'invoiceCancel';

    return (
        <div
            style={{
                position: (fixed) ? 'fixed' : 'relative',
                bottom: 0,
                paddingBottom: 10,
                marginLeft: -80,
                paddingLeft: 80,
                width: `calc(100vw + 80px)`,
                background: fixed ? '#fafafa' : undefined
            }}
        >
            {(isFromHome) && (
                <Grid container justify={"flex-start"} spacing={2} alignItems={'center'}>
                    <Grid item>
                        <div style={{maxWidth: matches ? 90: 140}}>
                            <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue} textColor={'white'}
                                    disabled={Props.submitting}
                                    type={'submit'}
                            >
                                    <span style={{fontSize: matches ? 12: 15}}>
                                    {isEdit ? 'Save' : 'Submit'}
                                    </span>
                            </Button>
                        </div>
                    </Grid>

                    <Grid item>
                        {Props.submitting && <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                    </Grid>

                </Grid>
            )}


            {!isFromHome && (
                <Grid container spacing={matches ? 1: 3} style={{margin: '10px 0 0 -10px '}} alignItems={"center"}>

                    <Grid item>
                        <div style={{minWidth: matches ? 70:125}}>
                            <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue} textColor={'white'}
                                    type={'submit'}
                                    onClick={() => {
                                        formikInstance.setFieldValue("action", "submitAndExit")
                                    }}
                            >
                                <span style={{fontSize: matches ? 12: 15}}>{isEdit ? isInvoiceCancel ? 'Cancel': 'Save' : 'Submit & Exit'}</span>
                            </Button>
                        </div>
                    </Grid>
                    {/*{!isEdit && (*/}
                    {/*    <Grid item>*/}
                    {/*        <div style={{minWidth: matches ? 70: 125}}>*/}
                    {/*            <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue} textColor={'white'}*/}
                    {/*                    type={'submit'}*/}
                    {/*                    onClick={() => formikInstance.setFieldValue("action", "submitAndAdd")}*/}
                    {/*            >*/}
                    {/*                <span style={{fontSize: matches? 12:15}}>Save & Add Another</span>*/}
                    {/*            </Button>*/}
                    {/*        </div>*/}
                    {/*    </Grid>*/}
                    {/*)}*/}

                    {Props.submitting ? (
                        <Grid item style={{marginTop: 3}}>
                            <CircularSpinner size={30} circleColor={'blue'}/>
                        </Grid>
                    ) : (
                        <Grid item>
                            <div style={{minWidth: matches ? 70: 125}}>
                                <Button variant={"contained"} customColor={COLOR_PROFILE.buttonRed} textColor={'white'}
                                        type={'button'}
                                        onClick={() => Props.handleClose ? Props.handleClose() : history.goBack()}
                                >
                                    <span style={{fontSize: matches ? 12: 15}}>
                                        {isInvoiceCancel ? 'Exit' : 'Cancel'}
                                    </span>
                                </Button>
                            </div>
                        </Grid>
                    )}
                </Grid>

            )}

        </div>

    )
}

export default FormSaveButton;
