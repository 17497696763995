import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {Avatar, Badge, ClickAwayListener, Fade, Grid, MenuItem, Paper, Popper, useMediaQuery} from "@material-ui/core";
import {useHistory} from 'react-router-dom';
import Button from '../../../shared/atom/button';
import {DRAWER_MENUS} from "../../../../utils/contants";
import DrawerList from "../../organisms/drawerList";
import DashboardRoute from "../../../../routes/dashboardRoute";
import NotificationsIcon from '@material-ui/icons/Notifications';
import HelpIcon from '@material-ui/icons/Help';
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import SnackBar from "../../molecules/snackBar";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import {domainToASCII} from "url";
import dashboardReducer from "../../../../store/dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Logo from '../../../../assets/logo.png';


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            background: COLOR_PROFILE.navigationBackground,
            color: 'black'
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
            color: COLOR_PROFILE.primaryColor
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,

        },
        drawerPaper: {
            background: COLOR_PROFILE.navigationBackground,
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: COLOR_PROFILE.navigationBackground,
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'space-between',
        },
        drawerFooter: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginTop: 50,
            // maxWidth: `calc(100% - ${drawerWidth}px)`,
            // maxHeight: `calc(100vh - 50px)`,
            // overflow: 'auto',
            // marginLeft: 10,
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        popperStyle: {
            width: 250,
            marginLeft: '-2px',
            marginTop: 11,
            zIndex: 1300,
            color: '#999'
        },

    }),
);

export default function PersistentDrawerLeft() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [open, setOpen] = React.useState<undefined | boolean>(undefined);
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const [openProfileMenu, setOpenProfileMenu] = useState(false);
    const [anchorElProfile, setAnchorElProfile] = useState(null);

    useEffect(() => {
        let status = localStorage.getItem('drawerStatus');
        setOpen(status ? status === 'true' : matches ? false: true);
    }, [])

    const dashboard = useSelector((state: RootState) => state.dashboard);


    const handleDrawerOpen = () => {
        localStorage.setItem('drawerStatus', true.toString())
        setOpen(true);
    };

    const handleDrawerClose = () => {
        localStorage.setItem('drawerStatus', false.toString())
        setOpen(false);
    };


    const handleLogout = () => {
        localStorage.removeItem('token')
        history.push('/login');
    };

    const clickHandler = (event: any) => {
        setAnchorElProfile(event.currentTarget);
        setOpenProfileMenu(true);
    };


    const handleCloseProfile = () => {
        setAnchorElProfile(null);
        setOpenProfileMenu(false);
    }

    const snackBarReducer = useSelector((state: RootState) => state.snackBar);


    return (
        <div>
            {open !== undefined && (
                <div className={classes.root}>

                    <CssBaseline/>
                    <AppBar
                        position="fixed"

                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: open,
                        })}
                    >
                        {snackBarReducer.message && (
                            <SnackBar message={snackBarReducer.message} type={snackBarReducer.type}/>
                        )}

                        <Toolbar>
                            <Grid container justify={"space-between"} alignItems={"center"}>
                                <Grid item xs sm
                                      container alignItems={'center'}>
                                    <Grid item>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={open ? handleDrawerClose : handleDrawerOpen}
                                            edge="start"
                                            className={clsx(classes.menuButton,
                                                // open && classes.hide
                                            )}
                                        >
                                            {!open ? <MenuIcon/> : <MenuOpenIcon/>}
                                        </IconButton>
                                    </Grid>
                                    {/*{!matches &&*/}
                                    {/*<Grid item>*/}
                                    {/*    <div>*/}

                                    {/*        <Button*/}
                                    {/*            variant={"contained"}*/}
                                    {/*            textColor={'white'}*/}
                                    {/*            customColor={COLOR_PROFILE.buttonGreen}*/}
                                    {/*            startIcon={<AddIcon style={{color: 'white'}}/>}*/}
                                    {/*        >*/}
                                    {/*            Add New*/}
                                    {/*        </Button>*/}

                                    {/*    </div>*/}
                                    {/*</Grid>*/}
                                    {/*}*/}
                                </Grid>
                                <Grid item xs={10} sm alignItems={'center'} justify={"flex-end"} container spacing={matches ? 1: 3}>


                                    <Grid item style={{cursor: 'pointer'}}>
                                        <IconButton disableFocusRipple={true} disableRipple={true}>
                                            <HelpIcon fontSize="default" style={{color: "#2b2b2b"}}/>
                                        </IconButton>
                                        <span style={{fontSize: 12, marginLeft: -5}}>Help</span>
                                    </Grid>

                                    <Grid item>
                                        <IconButton>
                                            <Badge color="secondary" variant="dot">
                                                <NotificationsIcon fontSize="default" style={{color: "#2b2b2b"}}/>
                                            </Badge>
                                        </IconButton>
                                    </Grid>


                                    {/*<Grid item>*/}
                                    {/*    <IconButton onClick={handleLogout}>*/}
                                    {/*        <ExitToApp fontSize="default" style={{color: "#2b2b2b",}}/>*/}
                                    {/*    </IconButton>*/}
                                    {/*</Grid>*/}

                                    <Grid item style={{textAlign: 'right'}}>

                                        <Grid container justify={"center"} style={{cursor: 'pointer'}} onClick={clickHandler}>
                                            <Grid item style={{marginTop: 3, paddingLeft: 5}}>

                                                <Avatar style={{backgroundColor: 'orange'}}>N</Avatar>

                                            </Grid>

                                            <Grid item style={{paddingTop: 5, textAlign: 'right'}}>

                                                <div >
                                                    <ArrowDropDownIcon fontSize='large' style={{cursor: 'pointer'}}/>
                                                </div>

                                            </Grid>

                                            <Popper open={openProfileMenu} anchorEl={anchorElProfile} placement={'bottom-end'}
                                                    className={classes.popperStyle}
                                                    // onClose={handleCloseProfile}
                                                    transition>

                                                {({TransitionProps}) => (

                                                    <ClickAwayListener onClickAway={handleCloseProfile}>

                                                        <Fade {...TransitionProps} timeout={150}>

                                                            <Paper style={{boxShadow: '0px 0px 10px 0px gray', fontWeight: 700}} elevation={1}>


                                                                <Grid container justify={"flex-end"} alignItems={"center"}
                                                                      style={{padding: '10px 10px 10px 20px'}}>


                                                                    <Grid item xs={12}
                                                                          style={{fontSize: 16, color: COLOR_PROFILE.primaryColor}}>
                                                                        {dashboard.name}
                                                                    </Grid>

                                                                    <Grid item xs={12} style={{
                                                                        wordBreak: 'break-all',
                                                                        fontSize: 10,
                                                                    }}>
                                                                        {dashboard.email}
                                                                    </Grid>

                                                                </Grid>

                                                                <Divider/>
                                                                <Divider/>

                                                                <MenuItem onClick={handleLogout}
                                                                          style={{
                                                                              color: COLOR_PROFILE.primaryColor,
                                                                              paddingTop: 10, fontWeight: 700, paddingBottom: 10
                                                                          }}
                                                                >
                                                                    <ExitToAppIcon style={{paddingRight: 5, marginRight: 10 }}/>
                                                                    <span style={{fontSize: 14}}>Logout</span>
                                                                </MenuItem>

                                                            </Paper>

                                                        </Fade>

                                                    </ClickAwayListener>

                                                )}

                                            </Popper>

                                        </Grid>




                                    </Grid>

                                </Grid>

                            </Grid>


                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div style={{position: 'relative'}}>
                            <div
                                style={{position: 'sticky',zIndex: 1000, top: 0, left: 0, background: '#d0d0d0'}}
                            >
                                <div className={classes.drawerHeader}>
                                    <Grid container alignItems={"center"} justify={"center"} style={{fontSize: 16, fontWeight: 700, color: COLOR_PROFILE.primaryColor}}>
                                        <img
                                            src={Logo}
                                            alt="logo"
                                            height="60px"
                                            width="100%"
                                            style={{ paddingTop: "0px" }}
                                        />
                                    </Grid>
                                    <div style={{marginTop: 10}}>
                                        <Divider/>
                                    </div>

                                </div>
                            </div>

                            <div>

                                {DRAWER_MENUS.map((item: any, index: number) => {
                                    return (
                                        <DrawerList key={item.id} {...item} index={index + 1}/>
                                    )
                                })}

                                <div style={{marginTop: 20}}>

                                </div>
                            </div>
                        </div>

                    </Drawer>


                    <main
                        className={clsx(classes.content, {
                            [classes.contentShift]: open,
                        })}
                    >
                        <div style={{marginLeft: matches ? -25: 0}}>

                            <DashboardRoute/>

                        </div>


                        <div>
                            {/*contents will be here*/}
                        </div>
                    </main>
                </div>
            )}
        </div>
    );
}
