import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import * as Yup from "yup";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Grid, Popper} from "@material-ui/core";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import InputField from "../../../../shared/atom/formElements/inputField";
import ErrorLabel from "../../../molecules/errorLabel";
import DateComponent from "../../../molecules/dateComponent";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import useSWR, {trigger} from "swr";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import {matchSorter} from "match-sorter";
import ToolTip from "../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {Column} from "react-table";
import VoucherTableForm from "../../../../accounting/organisms/Forms/voucherTableForm";
import {makeStyles} from "@material-ui/core/styles";
import DaysLayout from "../../../molecules/daysLayout";
import {addOrRemoveFromArray, GetEffectiveTime} from "../../../../../config/helperFunctions";
import CustomTimePicker from "../../../../shared/atom/formElements/picker";
import {useDispatch} from "react-redux";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs} from "../../../../../config/dateFunctions";
import {getFormattedDate} from "../../../../../utils/dateFunction";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {

    from: string
    mode: 'edit' | 'create',
    nextPath?: any,
    id ?: string | null

}

const useStyles= makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const ProductOfferForm: React.FC<Props> = (Props) => {

    const initialState = {
        transactionYearAdFrom: null,
        transactionMonthAdFrom: null,
        transactionDateAdFrom: null,
        transactionYearBsFrom: null,
        transactionMonthBsFrom: null,
        transactionDateBsFrom: null,
        transactionYearAdTill: null,
        transactionMonthAdTill: null,
        transactionDateAdTill: null,
        transactionYearBsTill: null,
        transactionMonthBsTill: null,
        transactionDateBsTill: null,
        offerName: '',
        code: '',
        effectiveDays: ['Su','Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        effectiveTimeStart: new Date(1970,0,0,0,0,0),
        effectiveTimeEnd: new Date(1970,0,0,23,59,0),
        tableRow: [
            {id: 1, product: null,  price: 0, discount: 0, description: '', amount: 0},
            {id: 2, product: null,  price: 0, discount: 0, description: '', amount: 0},
        ],

    }

    const {data: offerCode} = useSWR('/getCode?name=offersSales&id=0')

    useEffect(() => {

        if(Props.id) return;
        if (offerCode) setFormState(prevState => {
            return {
                ...prevState,
                code: offerCode.t.code
            }
        })

    }, [offerCode])

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();
    const {data: productOptions} = useSWR(Props.id ? '/getProductsAndBundles': null);
    const {data: productOptionsInventory} = useSWR('/getAllProductsInInventoryHead?type=inventory');

    const {data: uomOptions} = useSWR(Props.id ? '/getAllUoms' : null);

    useEffect(() => {
        if (!formatedNepEngDates) return;
        if(!productOptions) return;
        if(!productOptionsInventory) return;
        if(!uomOptions) return;
        if (Props.id) {

            // @ts-ignore
            const {code,effectiveFrom,effectiveTo,name,effectiveDays,effectiveTime, offerProducts} = location.state
            let adDateFrom = effectiveFrom.split('-')
            let bsDateFrom: any = convertAdToBs(effectiveFrom, formatedNepEngDates) || [];
            let adDateTill = effectiveTo?  effectiveTo.split('-'): [];
            let bsDateTill: any = convertAdToBs(effectiveTo, formatedNepEngDates) || [];

            // @ts-ignore
            let tempJson = {
                transactionYearAdFrom: adDateFrom[0],
                transactionMonthAdFrom: adDateFrom[1],
                transactionDateAdFrom: adDateFrom[2],
                transactionYearBsFrom: bsDateFrom[0],
                transactionMonthBsFrom: bsDateFrom[1],
                transactionDateBsFrom: bsDateFrom[2],
                transactionYearAdTill: adDateTill[0],
                transactionMonthAdTill: adDateTill[1],
                transactionDateAdTill: adDateTill[2],
                transactionYearBsTill: bsDateTill[0],
                transactionMonthBsTill: bsDateTill[1],
                transactionDateBsTill: bsDateTill[2],
            }

            let effectiveTimeArray = effectiveTime.split('-');

            console.log(effectiveTime)

            // @ts-ignore
            setFormState({

                ...tempJson,
                offerName: name,
                code: code,
                effectiveDays: effectiveDays.split(','),
                effectiveTimeStart: new Date(1970, 0, 0, parseInt(effectiveTimeArray[0]), parseInt(effectiveTimeArray[1]), 0),
                effectiveTimeEnd: new Date(1970, 0, 0, parseInt(effectiveTimeArray[2]), parseInt(effectiveTimeArray[3]), 0),
                tableRow: offerProducts.map((item: any) => {

                    return {
                        id: item.id,
                        product: productOptions.data.find((itemOption: any) => itemOption.id === (item.productId || item.productBundleId)  && (itemOption.type === (item.productId ? 'Product': 'Product Bundle'))),
                        quantity: item.quantity,
                        uom: null,
                        price: item.price,
                        discount: item.discount,
                        description: item.description,
                        amount: item.amount

                    }
                })

            })

        }


    }, [formatedNepEngDates,productOptions, uomOptions, productOptionsInventory ])

    const convert = (transactionDate: any) => {

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            transactionYearAdFrom: adDate[0],
            transactionDateAdFrom: adDate[2],
            transactionMonthAdFrom: adDate[1],
            transactionDateBsFrom: bsDate[2],
            transactionMonthBsFrom: bsDate[1],
            transactionYearBsFrom: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }

    useEffect(() => {

        if (Props.id) return;
        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])

    }, [formatedNepEngDates])

    const [openDialog, setOpenDialog] = useState(false)
    const [formState, setFormState] = useState(initialState);

    const location = useLocation();
    const styles = useStyles();

    const validationSchema = Yup.object({
        offerName: Yup.string().required('Required'),
    })

    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            if(values.effectiveDays.length === 0){
                handleResponse(`Select at least one effective days.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRow.filter((item: any) => item.product).length === 0){
                handleResponse(`Enter at least one item`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRow.filter((item: any)=> (item.product) && !(item.price && item.amount)).length > 0){
                handleResponse(`Some row doesn't have Product, Quantity, Price and Amount.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            let payload = {

                offerProducts : values.tableRow.filter((item: any) => item.product && item.price && item.amount).map((item: any, index: number) => {

                    let editId= {};

                    //add id of each index of productSubs to each product sub in the new list of product that need to be sent to the server
                    if(Props.mode === 'edit'){
                        // @ts-ignore
                        if(location.state.offerProducts.length >= index + 1 ){
                            // @ts-ignore
                            editId = {id: location.state.offerProducts[index].id}
                        }
                    }

                    return {
                        ...editId,
                        productId: item.product.type === 'Product' ? item.product.id: 0,
                        productBundleId: item.product.type === 'Product' ? 0: item.product.id,
                        price: parseFloat(item.price),
                        discount: parseFloat(item.discount),
                        amount: parseFloat(item.amount)
                    }
                }),
                name: values.offerName,
                code: values.code,

                //todo handle empty from and to date
                effectiveFrom: getFormattedDate(values.transactionYearAdFrom , values.transactionMonthAdFrom, values.transactionDateAdFrom),
                effectiveTo: getFormattedDate(values.transactionYearAdTill , values.transactionMonthAdTill, values.transactionDateAdTill),
                effectiveTime: GetEffectiveTime(values.effectiveTimeStart, values.effectiveTimeEnd),
                description: values.description,
                effectiveDays: values.effectiveDays.join(','),
            }


            if(Props.id){

                const {data} = await axiosInstance.put('/updateOffer', {...payload,id: parseInt(Props.id as string)});
                responseProcess(data, formActions, isReset)

            }else{
                const {data} = await axiosInstance.post('/addOffer', payload);
                responseProcess(data, formActions, isReset)
            }


        }catch (e) {
            console.log(e)

            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')

        }

        formActions.setSubmitting(false)
    }

    const history = useHistory();

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) history.replace('/dashboard/product/product-sales-offer')
            trigger('/getCode?name=offersSales&id=0')
        } else {
            handleResponse(data.message, 'warning')
        }
    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {
        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })
    }

    const handleRemove = (index: number, formikInstance: any) => {
        const {values: {tableRow}} = formikInstance;
        let tempTable = [...tableRow].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue('tableRow', [...tempTable])
    }

    const addNew = useCallback((formikInstance: any) => {
        const {values: {tableRow}} = formikInstance;
        let tempArr = {
            id: tableRow[tableRow.length - 1].id + 1,
            product: null,  price: 0, discount: 0, description: '', amount: 0
        }
        tableRow.push(tempArr)
        formikInstance.setFieldValue('tableRow', [...tableRow])
    }, [formState])

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>

            <Formik
                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {

                    return (
                        <div>
                            <PagePrompt formik={formik}/>
                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>
                                <Grid container justify={"space-between"}>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="code"
                                                   className={styles.label}> Offer/ Sales Code:</label>
                                        </Grid>

                                        <Grid item xs={8} className={styles.label}>
                                            {formik.values.code}
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="offerName"
                                                   className={styles.label}> Offer/ Sales Name:<RequiredNotation/></label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'offerName'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'text'}
                                                                            id={'offerName'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="fromDate"
                                                   className={styles.label}
                                            >
                                                From:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <DateComponent
                                                ad={['transactionYearAdFrom', 'transactionMonthAdFrom', 'transactionDateAdFrom']}
                                                bs={['transactionYearBsFrom', 'transactionMonthBsFrom', 'transactionDateBsFrom']}
                                            />

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="tillDate"
                                                   className={styles.label}
                                            >
                                                Till:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <DateComponent
                                                ad={['transactionYearAdTill', 'transactionMonthAdTill', 'transactionDateAdTill']}
                                                bs={['transactionYearBsTill', 'transactionMonthBsTill', 'transactionDateBsTill']}
                                            />

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="tillDate"
                                                   className={styles.label}
                                            >
                                                Effective Days:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <Field name={'effectiveDays'}>
                                                {(props: { field: any; form: any; meta: any; }) => {
                                                    const {field, form, meta} = props;
                                                    return (
                                                        <>
                                                            <DaysLayout selected={field.value}
                                                                        handleChange={(name: string) =>(event: any) =>  {
                                                                            form.setFieldValue('effectiveDays',addOrRemoveFromArray(field.value, name) )
                                                                        }}
                                                            />
                                                        </>
                                                    )
                                                }}
                                            </Field>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="effectiveTimeStart"
                                                   className={styles.label}
                                            >
                                                Effective Time:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={4} alignItems={'center'}>

                                            <div style={{position: 'relative'}}>
                                                <Field name={'effectiveTimeStart'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>

                                                                <CustomTimePicker
                                                                    id={'effectiveTimeStart'} {...field}
                                                                    inputVariant={'outlined'}
                                                                    variant="inline"
                                                                    autoOk={true}
                                                                    clearable={true}
                                                                    placeholder={'Start Time'}
                                                                    onChange={(event: any, val: any) => {
                                                                        form.setFieldValue('effectiveTimeStart', event)
                                                                    }}
                                                                    size={"small"}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>

                                        </Grid>

                                        <Grid item container xs={4} alignItems={'center'}>

                                            <div style={{position: 'relative'}}>
                                                <Field name={'effectiveTimeEnd'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>

                                                                <CustomTimePicker
                                                                    id={'effectiveTimeEnd'} {...field}
                                                                    inputVariant={'outlined'}
                                                                    variant="inline"
                                                                    placeholder={'End Time'}
                                                                    clearable={true}
                                                                    autoOk={true}

                                                                    clearLabel={'Clear'}
                                                                    onChange={(event: any, val: any) => {
                                                                        form.setFieldValue('effectiveTimeEnd', event)
                                                                    }}
                                                                    size={"small"}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                </Grid>

                                <div>

                                    <TableForm handleRemove={handleRemove} addNew={addNew} setOpenDialog={setOpenDialog}
                                               formikInstance={formik}/>
                                </div>
                                <div>
                                    <FormSaveButton mode={Props.mode} from={'product-bundle'}
                                                    submitting={formik.isSubmitting}/>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    );
};

const TableForm: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRow}} = useFormikContext();
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');
    const {data: productAndBundleOptions} = useSWR('/getProductsAndBundles');

    const name = 'tableRow'
    const formik = useFormikContext();


    const getDebit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit))
        }, 0.00)
    }, [tableRow])

    const getCredit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRow])


    const data = useMemo(() => tableRow, [tableRow, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{width: 500}} placement='bottom-start'/>)
    }


    // {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},

    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        {
            id: 'productItem',
            width: 400,
            Header: 'Product/ Product Bundle',
            accessor: "productItem",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj



                return (
                    <Field name={`${name}[${index}].product`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {
                                    let tempRows = form.values.tableRow;
                                    let selectedProduct= tempRows.filter((item: any) => item.product).map((item: any) => item.product?.id);
                                    if (field.value?.id) selectedProduct.splice(selectedProduct.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedProduct.includes(item.id)) : [];

                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"} style={{paddingTop: 5, paddingBottom: 5}}>
                                            <Grid item xs>
                                                <AutoCompleteInput type={'text'}
                                                                   id={`${name}[${index}].product`} {...field}
                                                                   size={"small"}
                                                                   placeholder={'Select Product'}
                                                                   filterOptions={(options, {inputValue}) => {
                                                                       let filteredOptions = getOptions(options)
                                                                       return matchSorter(filteredOptions, inputValue, {keys: ['name', 'code']});
                                                                   }}
                                                                   onChange={(e, value) => {
                                                                       form.setFieldValue(`${name}[${index}].price`, value? value.price:0)
                                                                       form.setFieldValue(`${name}[${index}].product`, value)
                                                                       form.setFieldValue(`${name}[${index}].amount`, value? value.price: 0)
                                                                   }}

                                                                   hideOutline={true}
                                                                   autoHighlight={true}
                                                                   autoSelect={true}
                                                                   options={productAndBundleOptions ? productAndBundleOptions.data : []}
                                                                   loading={!productAndBundleOptions}
                                                                   variant={'standard'}
                                                                   InputProps={{
                                                                       disableUnderline: true,
                                                                       style: {paddingLeft: 5, paddingRight: 5}
                                                                   }}
                                                                   PopperComponent={PopperMy}
                                                                   getOptionLabel={option => option.name}
                                                                   renderOption={(option) => {
                                                                       return (
                                                                           <div>
                                                                               <div style={{
                                                                                   fontSize: 11,
                                                                               }}>
                                                                                   <>{option.code}</>
                                                                               </div>
                                                                               <div>{option.name}</div>
                                                                           </div>
                                                                       )
                                                                   }}
                                                                   groupBy={option => option.type}
                                                                   error={meta.touched && meta.error}
                                                />
                                            </Grid>
                                            {/*<ToolTip title={'Add new product'}>*/}
                                            {/*    <Grid item style={{*/}
                                            {/*        borderLeft: '1px solid #d0d0d0',*/}
                                            {/*        padding: '8px 3px 3px 3px',*/}
                                            {/*        cursor: 'pointer',*/}

                                            {/*    }}*/}
                                            {/*          onClick={() => handleDialogOpen()}*/}
                                            {/*    >*/}

                                            {/*        <div>*/}

                                            {/*            <AddIcon style={{*/}
                                            {/*                color: COLOR_PROFILE.primaryColor,*/}
                                            {/*                fontWeight: 700,*/}
                                            {/*                fontSize: 20*/}
                                            {/*            }}/>*/}

                                            {/*        </div>*/}
                                            {/*    </Grid>*/}
                                            {/*</ToolTip>*/}
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'price',
            Header: 'Price',
            accessor: "price",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].price`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].price`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].price`, event.target.value)
                                                        form.setFieldValue(`${name}[${index}].amount`, (1-original.discount/100) * event.target.value )
                                                    }}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'discount',
            Header: 'Discount (%)',
            accessor: "discount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].discount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].discount`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].discount`, event.target.value)

                                                        form.setFieldValue(`${name}[${index}].amount`, (1-event.target.value/100) * original.price )
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'amount',
            Header: 'Amount',
            accessor: "amount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj

                // formik.setFieldValue(`${name}[${index}].amount`, original.quantity * original.price)


                return (
                    <Field name={`${name}[${index}].amount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].amount`} {...field}
                                                    size={"small"}
                                                    value={original.amount}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                //console.log(cellObj)

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        //console.log('delete clicked')
        handleRemove(index, formikInstance)
    }

    const getBalance = () => {
        return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }

    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, accountHeadOptions]);


    return (
        <div>

            <div>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} balance={getBalance}
                                  total={{'credit': getCredit, 'debit': getDebit}} type={'offer'}
                                  minLength={2}
                                  totalLayout={null}
                                  fromProduct={true}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )


};

export default ProductOfferForm;
