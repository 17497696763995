import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Grid} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import useSWR, {trigger} from "swr";
import DashboardReportItem from "../../../../accounting/molecules/dashboardReportItem";
import Button from "../../../../shared/atom/button";
import ReportTable from "../../../../accounting/organisms/tables/reportTable";
import DataEmptyMessage from "../../../../accounting/organisms/tables/dataEmptyMessage";
import axiosInstance from "../../../../../config/axiosConfig";
import {useDispatch} from "react-redux";

const MonthlyTdsReportTable: React.FC<any>  = (Props) => {
    const {formState} = Props;

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const [submitting, setSubmitting] = useState(false);


    const history = useHistory();

    const payable = ['Purchase', 'Expense']
    const receivable = ['Estimate', 'Invoice']

    const shareHolderColumns = [

        {
            id: 'sn',
            Header: 'S.N',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {

                if (val.row.index === mockData.length - 1) return <div></div>

                let id: any = val.row.id;

                return (
                    <Grid container justify={"center"}>
                        <span style={{color: COLOR_PROFILE.primaryColor, textAlign: 'center'}}>{parseInt(id) + 1}</span>
                    </Grid>
                )
            }
        },
        {
            Header: 'PAN No.',
            Footer: 'PAN No.',
            width: 30,
            accessor: 'pan'
        },
        {
            Header: 'Transaction With',
            Footer: 'Trade Name',
            accessor: 'tradeName'
        },
        {
            Header: 'Source',
            Footer: 'Source',
            Cell: (val: any) => {
                if (val.row.index === mockData.length - 1) return <div style={{fontSize: 16}}>Total</div>

                const {row: {original: {purchaseSell}}} = val
                return <div>{purchaseSell}</div>
            }
        },
        {
            Header: 'Source Id',
            Footer: 'Source Id',
            accessor: 'billNumber'
        },
        {
            Header: 'TDS Receivable',
            Footer: 'TDS Receivable',
            Cell: (val: any) => {

                let balance = 0
                if (val.row.index === mockData.length - 1) {
                    let tempVal = [...mockData.slice(0, mockData.length - 1)];
                    balance = [...tempVal].reduce((result: number, completelyNew: any) => {
                        const newItem = completelyNew
                        let toReturn = receivable.includes(newItem.purchaseSell) ? (parseFloat(result.toString()) + parseFloat(newItem.taxAmount)) : parseFloat(String(result));
                        return toReturn.toFixed(2)
                    }, 0.00)
                    return <div style={{textAlign: 'right', fontSize: 16}}>{Math.abs(balance)}</div>
                }


                const {row: {original}} = val
                balance = original.taxAmount;

                return (
                    <div style={{textAlign: 'right'}}>
                        {receivable.includes(original.purchaseSell) ? balance : 0.00}
                    </div>
                )

            }
        },
        {
            Header: 'TDS Payable',
            Footer: 'TDS Payable',
            Cell: (val: any) => {

                let balance = 0
                if (val.row.index === mockData.length - 1) {
                    let tempVal = [...mockData.slice(0, mockData.length - 1)];
                    balance = [...tempVal].reduce((result: number, completelyNew: any) => {
                        const newItem = completelyNew
                        let toReturn = payable.includes(newItem.purchaseSell) ? (parseFloat(result.toString()) + parseFloat(newItem.taxAmount)) : parseFloat(String(result));
                        return toReturn.toFixed(2)
                    }, 0.00)
                    return <div style={{textAlign: 'right', fontSize: 16}}>{Math.abs(balance)}</div>
                }


                const {row: {original}} = val
                balance = original.taxAmount;

                return (
                    <div style={{textAlign: 'right'}}>
                        {payable.includes(original.purchaseSell) ? balance : 0.00}
                    </div>
                )

            }
        },

    ]

    const path = '/report/monthlyTdsReport'

    const [loading, setloading] = useState(true);

    const getQueryParams = (formState: any) => {
        return `nepaliYear=${formState.year}&nepalMonth=${formState.month ? formState.month.id : 0}&type=`
    }

    const {data} = useSWR(`${path}?${getQueryParams(formState)}`)

    useEffect(() => {
        if (!data) return;
        setloading(false)
        Props.setSubmitting(false)
    }, [data])

    useEffect(() => {
        if (Props.submitting) return;
        trigger(`${path}
        ?${getQueryParams(formState)}`)
    }, [Props.submitting])

    const {data: dataSwr} = useSWR(`${path}?${getQueryParams(formState)}`)

    let val = data?.data ? data.data : [];

    // @ts-ignore
    const calledData = data?.data ? val.length > 0 ? [...val, ...[{}]] : val : [];
    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => shareHolderColumns, [mockData, Props.checked]);

    const getSum = () => {
        return mockData.reduce((result: number, completelyNew: any) => {
            let toReturn = parseFloat(result.toString()) + (payable.includes(completelyNew.purchaseSell) ? 1 : -1) * parseFloat(completelyNew.taxAmount || 0);
            return toReturn.toFixed(2)
        }, 0.00)
    }


    const handleMakePayment = () => {
        let totalAmt = getSum();
        history.push(`/dashboard/voucher-entries/payment-voucher/add`,
            {
                from: "tds",
                amount: Math.abs(totalAmt),
                type: totalAmt > 0 ? "Payable": "Receivable",
                year: formState.year,
                month: formState.month.id
            }
        )
    }

    const handleClear = async () => {
        try {
            setSubmitting(true)
            const payload = {

                nepaliYear:formState.year,
                nepaliMonth: formState.month ? formState.month.id : 0,
                amount: getSum()

            }

            const {data} = await axiosInstance.put('/status/tds', payload)

            if (data.code === 'OK') {
                // trigger('/getAllAccountHeads')
                setSubmitting(false)
                responseProcess(data)
            } else {
                setSubmitting(false)
                handleResponse(data.message, 'warning')
            }



        } catch (e) {
            handleResponse('Something went wrong', 'warning')
            setSubmitting(false)
        }


    }


    const responseProcess = (data: any, ) => {

        if (data.code === 'OK') {

            handleResponse(data.message, 'success')
            trigger(`${path}?${getQueryParams(formState)}`);

        } else {

            handleResponse(data.message, 'warning')

        }
    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div style={{margin: '20px -20px 20px -20px'}}>

                    <Grid container item style={{padding: "5px 7px 10px 7px"}} alignItems={"center"} justify={"space-between"}>

                        <Grid item xs style={{padding: 10, margin: '0 4px'}}>
                            <DashboardReportItem
                                name={"Total" + `${getSum() > 0 ? " (Payable)" : getSum() < 0 ? " (Receivable)" : ""}`}
                                loading={false}
                                striped
                                value={Math.abs(getSum()) + ""}
                            />
                        </Grid>
                        <Grid item xs container justify={"flex-end"} spacing={2} style={{paddingRight: 25}}>
                            <Grid item>
                                <div style={{maxWidth: 230}}>
                                    <Button variant={"contained"} customColor={COLOR_PROFILE.buttonGreen}
                                            textColor={'white'}
                                            disabled={mockData[0].paid || submitting}
                                            onClick={handleMakePayment}
                                    >
                                            <span style={{fontSize: 15}}>
                                                {mockData[0].paid ? "Cleared":"Make Entry & Mark Cleared"}
                                            </span>
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item>
                                {!mockData[0].paid && (
                                    <div style={{maxWidth: 140}}>
                                        <Button variant={"contained"} customColor={COLOR_PROFILE.orange}
                                                textColor={'white'}
                                                disabled={submitting}
                                                onClick={handleClear}
                                        >
                                            <span style={{fontSize: 15}}>
                                               Mark Cleared
                                            </span>
                                        </Button>
                                    </div>
                                )}
                            </Grid>

                        </Grid>

                    </Grid>

                    <ReportTable
                        columns={columns}
                        data={mockData}
                        isRowLoading={false}
                    />
                </div>
            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default MonthlyTdsReportTable;
