import React, {useCallback, useMemo} from 'react';
import {Field, useFormikContext} from "formik";
import useSWR from "swr";
import {Grid, Popper} from "@material-ui/core";
import AutoCompleteInput from "../../../../../shared/atom/formElements/autocompleteInput";
import {matchSorter} from "match-sorter";
import ToolTip from "../../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import {COLOR_PROFILE} from "../../../../../../config/colorCode";
import InputField from "../../../../../shared/atom/formElements/inputField";
import {Column} from "react-table";
import VoucherTableForm from "../../../../../accounting/organisms/Forms/voucherTableForm";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14,
        padding: '0 5px',
        color: COLOR_PROFILE.primaryColor
    }
}))

const PurchaseOrderProduct: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance, mode}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRow}} = useFormikContext();
    const {data: productOptions} = useSWR('/getProducts');
    const {data: uomOptions} = useSWR('/getAllUoms');

const style= useStyles();
    const name = 'tableRow'
    const formik = useFormikContext();
const isView = mode === 'view';

    const getDebit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit))
        }, 0.00)
    }, [tableRow])


    const actualAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity))
        }, 0.00)
    }, [tableRow])

    const discountAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount && item.discount).reduce((result: number, item: any) => {
            return (result + (parseFloat(item.price) * parseFloat(item.quantity) * (item.discount/100)))
        }, 0.00)
    }, [tableRow])

    const bundlePrice = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])


    // let sum = values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
    //     return (result + parseFloat(item.amount))
    // }, 0.00)

    const getCredit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRow])


    const data = useMemo(() => tableRow, [tableRow, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{width: 500}} placement='bottom-start'/>)
    }


    // {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},

    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        {
            id: 'productItem',
            width: isView ? undefined: 380,
            Header: 'Product Item',
            accessor: "productItem",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].product`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {
                                    let tempRows = form.values.tableRow;

                                    //todo do this section again
                                    let selectedProduct = tempRows.filter((item: any) => item.product).map((item: any) => item.product?.id);
                                    if (field.value?.id) selectedProduct.splice(selectedProduct.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedProduct.includes(item.id)) : [];

                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>

                                                {isView ? (
                                                    <div className={style.label}>
                                                        {field?.value?.name || ''}
                                                    </div>
                                                ):(
                                                <AutoCompleteInput type={'text'}
                                                                   id={`${name}[${index}].product`} {...field}
                                                                   size={"small"}
                                                                   placeholder={'Select Product'}
                                                                   filterOptions={(options, {inputValue}) => {
                                                                       let filteredOptions = getOptions(options)

                                                                       //todo do this again
                                                                       return matchSorter(filteredOptions, inputValue, {keys: ['name', 'code']});
                                                                   }}
                                                                   onChange={(e, value) => {
                                                                       form.setFieldValue(`${name}[${index}].price`, value ? value.buying_price : 0)
                                                                       form.setFieldValue(`${name}[${index}].product`, value)
                                                                       form.setFieldValue(`${name}[${index}].amount`, 0)
                                                                   }}

                                                                   hideOutline={true}
                                                                   autoHighlight={true}
                                                                   autoSelect={true}
                                                                   options={productOptions ? productOptions.data : []}
                                                                   loading={!productOptions}
                                                                   variant={'standard'}
                                                                   InputProps={{
                                                                       disableUnderline: true,
                                                                       style: {paddingLeft: 5, paddingRight: 5}
                                                                   }}
                                                                   PopperComponent={PopperMy}
                                                                   getOptionLabel={option => option.name}
                                                                   renderOption={(option) => {
                                                                       return (
                                                                           <div>
                                                                               <div style={{
                                                                                   fontSize: 11,
                                                                               }}>
                                                                                   <>{option.code}</>
                                                                               </div>
                                                                               <div>{option.name}</div>
                                                                           </div>
                                                                       )
                                                                   }}
                                                                   error={meta.touched && meta.error}
                                                />
                                                )}
                                            </Grid>

                                            {!isView && (
                                            <ToolTip title={'Add new product'}>
                                                <Grid item style={{
                                                    borderLeft: '1px solid #d0d0d0',
                                                    padding: '8px 3px 3px 3px',
                                                    cursor: 'pointer',

                                                }}
                                                      onClick={() => handleDialogOpen()}
                                                >

                                                    <div>

                                                        <AddIcon style={{
                                                            color: COLOR_PROFILE.primaryColor,
                                                            fontWeight: 700,
                                                            fontSize: 20
                                                        }}/>

                                                    </div>
                                                </Grid>
                                            </ToolTip>
                                            )}
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'quantity',
            Header: 'Quantity',
            accessor: "quantity",
            width: 10,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].quantity`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.label}>
                                            {field?.value || 0}
                                        </div>
                                    ):(

                                        <InputField type={'number'} id={`${name}[${index}].quantity`} {...field}
                                                    size={"small"}
                                                    step="1"
                                                    variant={"standard"}
                                                    onChange={(event: any) => {

                                                        form.setFieldValue(`${name}[${index}].quantity`, event.target.value)
                                                        let discountedAmount = event.target.value * original.price - (original.discount)/100 * event.target.value * original.price;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
                                                    }}

                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />

                                    )}

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'uom',
            Header: 'UOM',
            width: 40,
            accessor: "uom",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].uom`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.label}>
                                            {field?.value?.name || ''}
                                        </div>
                                    ):(
                                        <AutoCompleteInput type={'text'}
                                                           id={`${name}[${index}].uom`} {...field}
                                                           size={"small"}
                                                           onChange={(e, value) => {
                                                               form.setFieldValue(`${name}[${index}].uom`,value)
                                                           }}

                                                           hideOutline={true}
                                                           autoHighlight={true}
                                                           autoSelect={true}
                                                           options={uomOptions ? uomOptions.data : []}
                                                           loading={!uomOptions}
                                                           variant={'standard'}
                                                           InputProps={{
                                                               disableUnderline: true,
                                                               style: {paddingLeft: 5, paddingRight: 5}
                                                           }}
                                                           getOptionLabel={option => option.name}
                                                           renderOption={(option) => {
                                                               return (
                                                                   <div>
                                                                       <div>{option.name}</div>
                                                                   </div>
                                                               )
                                                           }}
                                                           error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>
                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'price',
            Header: 'Price',

            accessor: "price",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].price`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.label}>
                                            {field?.value || ''}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].price`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].price`, event.target.value)
                                                        let discountedAmount = original.quantity * event.target.value - (original.discount)/100 * original.quantity * event.target.value;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
                                                    }}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'discount',
            Header: 'Discount (%)',
            width: 10,
            accessor: "discount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].discount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.label}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].discount`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].discount`, event.target.value)
                                                        let discountedAmount = original.quantity * original.price - (event.target.value)/100 * original.quantity * original.price;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'tax',
            Header: 'VAT (%)',
            width: 10,
            accessor: "tax",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].tax`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.label}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].tax`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].tax`, event.target.value)
                                                        let discountedAmount = original.quantity * original.price - (original.discount)/100 * original.quantity * original.price;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + event.target?.value/100 * discountedAmount)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'amount',
            Header: 'Amount',
            accessor: "amount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].amount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.label}>
                                            {field?.value || ''}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].amount`} {...field}
                                                    size={"small"}
                                                    value={original.amount}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                //console.log(cellObj)

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        //console.log('delete clicked')
        handleRemove(index, formikInstance)
    }

    const getBalance = () => {
        return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }

    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, productOptions]);


    return (
        <div>

            <div style={{paddingBottom: 40}}>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} balance={getBalance}
                                  mode={mode}
                                  total={{
                                      'actualAmount': actualAmount(),
                                      'discountAmount': discountAmount(),
                                      'bundlePrice': bundlePrice()
                                  }} type={'bundle'}

                                  minLength={1}
                                  fromProduct={true}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )
};

export default PurchaseOrderProduct;