import {useEffect, useState} from "react";
import useSWR from "swr";


const useServerCall = (Props: any) => {

    const {path, page, perPage, search} = Props;
    const [data, setData] = useState<any>({t: {data: []}})
    const [loading, setLoading] = useState(false)

    const {data: dataSwr} = useSWR(path ? `${path}/${parseInt(page) - 1}/${perPage}${search || ''}`: null)

    useEffect(() => {

        if (!dataSwr) {
            setLoading(true)
        } else {
            if (dataSwr) {
                setLoading(false)
                setData(dataSwr)
            }
        }

    }, [dataSwr])

    return {data, loading}
}

export default useServerCall;