import React from 'react';
import {COLOR_PROFILE} from "../../../../config/colorCode";
import {Grid} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

interface Props {
    name: string,
    value: string,
    loading: boolean,
    striped?:boolean
}

const DashboardReportItem: React.FC<Props> = ({name, value, loading, striped}) => {

    if(striped) return (
        <Grid container style={{backgroundColor: COLOR_PROFILE.navigationBackground, width: 330, textAlign: 'center'}}>
            <Grid container item xs={6} justify={"center"} alignItems={"center"} style={{
                padding: 5,
                fontWeight: 600,
                backgroundColor: COLOR_PROFILE.tableHeader,
                color: COLOR_PROFILE.primaryColor
            }}>
                {name}
            </Grid>
            <Grid container item xs={6} alignItems={"center"} justify={"center"}
                  style={{fontSize: 20, padding: loading ? 0 :2, margin: '0px 0 0px 0px'}}>
                {loading ? (
                    <div style={{margin: -10}}>
                        <Skeleton animation={"wave"} height={30} width={100}/>
                    </div>
                ) : (
                    <div>
                        Rs. {value ? Math.abs(Number(value)) : 0}
                    </div>
                )}
            </Grid>
        </Grid>
    );


    return (
        <div
            style={{backgroundColor: COLOR_PROFILE.navigationBackground, width: 200, textAlign: 'center',
                boxShadow: `0 2px 3px 0px ${COLOR_PROFILE.backgroundDark}`
            }}>
            <div style={{
                padding: 5,
                fontWeight: 600,
                backgroundColor: COLOR_PROFILE.tableHeader,
                color: COLOR_PROFILE.primaryColor,
            }}>
                {name}
            </div>
            <Grid container alignItems={"center"} justify={"center"}
                  style={{fontSize: 20, padding: loading ? 0 : 20, margin: '-10px 0 0px 0px'}}>
                {loading ? (
                    <div style={{margin: -10}}>
                        <Skeleton animation={"wave"} height={85} width={200}/>
                    </div>
                ) : (
                    <div>
                        Rs.{value ? Math.abs(Number(value)) : 0}
                    </div>
                )}
            </Grid>

        </div>
    );
};

export default DashboardReportItem;
