import React from 'react';
import LoginForm from "../../organisms/Forms/loginForm";

const LoginPage: React.FC = () => {


    return (


        <LoginForm/>


    );
};

export default LoginPage;
