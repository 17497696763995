import React from 'react';
import UomHome from "../../templates/uomHome";

const Uom = () => {
    return (
        <div>
         <UomHome/>
        </div>
    );
};

export default Uom;