import React, {useEffect, useState} from 'react';
import useSWR, {cache} from "swr";
import {useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import PersistentDrawerLeft from "../../templates/drawer/drawer";

const Dashboard: React.FC = () => {

    const {data: dashboard, error} = useSWR('/dashboard')
    const [called, setCalled] = useState(false);
    const history = useHistory();

    useEffect(() => {

        // console.log(dashboard)

        console.log(dashboard)
        console.log('found error')
        console.log(error);


        try {
            switch (error?.response?.status) {

                case 401:

                    console.log(error)
                    console.log('reached error', error)

                    localStorage.removeItem('token')
                    cache.clear();
                    history.replace('/login')
                    break;

                default:
                    break;


            }


        } catch (e) {
            console.log(e)
        }

    }, [error, dashboard])



    const dispatch = useDispatch();

    useEffect(() => {

        if (!dashboard) return;

        dispatch({type: 'SET_DASHBOARD', payload: dashboard})
        setCalled(true)

    }, [dashboard])

    return (


        <>
            {called && (
                <>
                    <PersistentDrawerLeft/>
                </>
            )}


        </>

    );
};

export default Dashboard;
