import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {useHistory, useLocation} from "react-router-dom";
import * as Yup from "yup";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Grid, Popper} from "@material-ui/core";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import DateComponent from "../../../../product/molecules/dateComponent";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import InputField from "../../../../shared/atom/formElements/inputField";
import useSWR, {trigger} from "swr";
import {useDispatch} from "react-redux";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs} from "../../../../../config/dateFunctions";
import {getFormattedDate} from "../../../../../utils/dateFunction";
import ProductItemForm from "../../../../product/organisms/form/productItem";
import DialogComponent from "../../../../shared/atom/dialog";
import PurchaseOrderProduct from "./purchaseOrderProduct";
import PurchaseOrderPrintView from "./purchaseOrderPrintView";
import ReportActionButton from "../../../../accounting/organisms/reportActionButton";
import {useReactToPrint} from "react-to-print";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {
    from: string
    mode: 'edit' | 'create' | 'view',
    nextPath?: any,
    id?: any

}

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const PurchaseOrderForm: React.FC<Props> = (Props) => {

    const initialState = {
        transactionYearAd: null,
        transactionMonthAd: null,
        transactionDateAd: null,
        transactionYearBs: null,
        transactionMonthBs: null,
        transactionDateBs: null,
        code: '',
        vendor: null,
        paymentMethod: null,
        tableRow: [
            {id: 1, product: null, quantity: '', uom: null, price: '', discount: '', tax: '', description: '', amount: ''},
            {id: 2, product: null, quantity: '', uom: null, price: '', discount: '', tax: '', description: '', amount: ''},
        ],

        initialPayment: 0,
        chequeNumber: '',
        paymentMethodTerms: null,

        paymentYearAd: null,
        paymentMonthAd: null,
        paymentDateAd: null,
        paymentYearBs: null,
        paymentMonthBs: null,
        paymentDateBs: null,

        paymentDueYearAd: null,
        paymentDueMonthAd: null,
        paymentDueDateAd: null,
        paymentDueYearBs: null,
        paymentDueMonthBs: null,
        paymentDueDateBs: null,
    }

    const {data: poCode} = useSWR('/getCode?name=purchaseOrder&id=0')
    const {data: vendorsData} = useSWR('/getVendors');
    const {data: productOptions} = useSWR('/getProducts');

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();

    useEffect(() => {
        dispatch({type: 'SET_DATA_FETCHING', payload: true});

        if (!formatedNepEngDates) return;
        if(!productOptions) return;
        dispatch({type: 'SET_DATA_FETCHING', payload: false});

        if (Props.id) {

            // @ts-ignore
            const {productSub, orderNumber, vendor, paymentMethod, date, initialPayment,paymentDate,paymentDueDate, paymentMethod2, chequeNumber} = location.state
            let adTransactionDate = date.split('-');
            let bsTransactionDate: any = convertAdToBs(date, formatedNepEngDates) || [];

            let adPaymentDate = paymentDate?paymentDate.split('-'): [];
            let bsPaymentDate: any = convertAdToBs(paymentDate, formatedNepEngDates) || [];

            let adDueDate = paymentDueDate ? paymentDueDate.split('-'): [];
            let bsDueDate: any = convertAdToBs(paymentDueDate, formatedNepEngDates) || [];

            // @ts-ignore
            let tempJson = {
                transactionYearAd: adTransactionDate[0],
                transactionDateAd: adTransactionDate[2],
                transactionMonthAd: adTransactionDate[1],
                transactionDateBs: bsTransactionDate[2],
                transactionMonthBs: bsTransactionDate[1],
                transactionYearBs: bsTransactionDate[0],

                paymentYearAd: adPaymentDate[0],
                paymentMonthAd: adPaymentDate[1],
                paymentDateAd: adPaymentDate[2],
                paymentYearBs: bsPaymentDate[0],
                paymentMonthBs: bsPaymentDate[1],
                paymentDateBs: bsPaymentDate[2],

                paymentDueYearAd: adDueDate[0],
                paymentDueMonthAd: adDueDate[1],
                paymentDueDateAd: adDueDate[2],
                paymentDueYearBs: bsDueDate[0],
                paymentDueMonthBs: bsDueDate[1],
                paymentDueDateBs: bsDueDate[2],
            }

            // @ts-ignore
            setFormState({

                ...tempJson,
                code: orderNumber,
                tableRow: [...productSub.map((item: any) => {
                    return {
                        ...item,
                        product: productOptions.data.find((prodItem: any) => item.productId === prodItem.id)
                    }
                })],
                initialPayment: initialPayment,
                vendor: vendor,
                paymentMethod: paymentMethod,
                paymentMethodTerms: paymentMethod2,
                chequeNumber: chequeNumber


            })
        }


    }, [formatedNepEngDates, productOptions])

    useEffect(() => {


        if(Props.mode !== 'create') return;

        if (poCode) setFormState(prevState => {
            return {
                ...prevState,
                code: poCode.t.code
            }
        })

    }, [poCode])



    const convert = (transactionDate: any) => {

        if(Props.mode !== 'create') return;

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            transactionYearAd: adDate[0],
            transactionDateAd: adDate[2],
            transactionMonthAd: adDate[1],
            transactionDateBs: bsDate[2],
            transactionMonthBs: bsDate[1],
            transactionYearBs: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }

    useEffect(() => {

        // if (Props.id) return;
        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])

    }, [formatedNepEngDates])

    const [openDialog, setOpenDialog] = useState(false)

    const location = useLocation();

    const [formState, setFormState] = useState(initialState);

    const styles = useStyles();
    const validationSchema = Yup.object({
        vendor: Yup.object().required('Required').nullable(true),
        paymentMethod: Yup.string().required('Required').nullable(true)
    })


    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            if(values.tableRow.filter((item: any) => item.product).length === 0){
                handleResponse(`Select at least one product to create Purchase Order.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRow.filter((item: any) => item.product && !( item.price && item.quantity && item.amount)).length > 0){
                handleResponse(`Some row doesn't have Product, Price, Quantity & Amount. Please delete the excess rows or fill the required columns.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }


            let payload = {
                productSub: values.tableRow.filter((item: any) => item.product && item.price && item.quantity && item.amount).map((item: any, index: number) => {

                    let editId= {};

                    //add id of each index of productSubs to each product sub in the new list of product that need to be sent to the server
                    if(Props.mode === 'edit'){
                        // @ts-ignore
                        if(location.state.productSub.length >= index + 1 ){
                            // @ts-ignore
                            editId = {id: location.state.productSub[index].id}
                        }
                    }

                    return {
                        ...editId,
                        productId: item.product.id,
                        name: item.product.name,
                        quantity: parseInt(item.quantity),

                        //todo uom in this section
                        // uom: {id: item.uom? item.uom.id: 0},
                        price: item.price,
                        discount: item.discount ? parseFloat(item.discount) : 0,
                        amount: parseFloat(item.amount)
                    }
                }),

                orderNumber: values.code,
                paymentMethod: values.paymentMethod,
                date: values.transactionYearAd + '-' + ('0' + values.transactionMonthAd).slice(-2) + '-' + ('0' + values.transactionDateAd).slice(-2),
                description: values.description,
                initialPayment: parseFloat(values.initialPayment),
                vendor: {id: values.vendor.id},
                paymentDate: getFormattedDate( values.paymentYearAd, values.paymentMonthAd, values.paymentDateAd),
                paymentDueDate: getFormattedDate(values.paymentDueYearAd, values.paymentDueMonthAd, values.paymentDueDateAd),
                paymentMethod2: values.paymentMethodTerms,
                chequeNumber: values.chequeNumber,
                payment: 'partial'
            }

            if(Props.id){
                // console.log({...payload,id: parseInt(Props.id)})
                // throw new Error('asd');
                const {data} = await axiosInstance.put('/updatePO', {...payload,id: parseInt(Props.id)});
                responseProcess(data, formActions, isReset)
            }else{
                const {data} = await axiosInstance.post('/addPurchaseOrder', payload);
                responseProcess(data, formActions, isReset)
            }

        } catch (e) {
            console.log(e)
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')

        }

        formActions.setSubmitting(false)
    }

    const history = useHistory();
    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) history.replace('/dashboard/purchase/purchase-order')
            trigger('/getCode?name=purchaseOrder&id=0')
        } else {
            handleResponse(data.message, 'warning')
        }
    }

    const dispatch = useDispatch();
    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }


    const handleRemove = (index: number, formikInstance: any) => {

        //console.log(formikInstance);
        const {values: {tableRow}} = formikInstance;

        let tempTable = [...tableRow].filter((item: any, indexInner: number) => indexInner !== index)

        formikInstance.setFieldValue('tableRow', [...tempTable])

    }


    const addNew = useCallback((formikInstance: any) => {


        const {values: {tableRow}} = formikInstance;
        //console.log(tableRow);
        let tempArr = {
            id: tableRow[tableRow.length - 1].id + 1,
            product: null, quantity: '', uom: null, price: '', discount: '', tax: '', description: '', amount: ''
        }

        tableRow.push(tempArr)

        formikInstance.setFieldValue('tableRow', [...tableRow])


    }, [formState])


    const componentRef = useRef(null);
    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@page { size: auto; margin: 00mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 130px 40px 100px 40px !important;  } }'
    });

    const printClicked = async () => {

        if (handlePrint) {
            handlePrint()
        }
    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>

            <DialogComponent open={openDialog} handleClose={setOpenDialog} title={'Add Product'}
                             maxWidth={'md'}>
                <ProductItemForm mode={'edit'} from={'journal page'} handleClose={() => setOpenDialog(false)}
                                 noFixed={true}
                />
            </DialogComponent>

            <Formik

                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {

                    return (
                        <div >
                            <PagePrompt formik={formik}/>

                            <Form style={{padding: 20}} onKeyDown={onKeyDown}>

                                <div ref={componentRef}>

                                <Grid container justify={"space-between"}>

                                    {Props.mode === 'view' ?
                                        (
                                            formik.values.tableRow.length > 0 &&
                                            <PurchaseOrderPrintView formik={formik}/>
                                    ):
                                        (
                                            <>

                                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 15}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="code"
                                                               className={styles.label}> Order Number:</label>
                                                    </Grid>

                                                    <Grid item xs={8} className={styles.label}>
                                                        {formik.values.code}
                                                    </Grid>
                                                </Grid>

                                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 15}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="vendor"
                                                               className={styles.label}> Vendor: <RequiredNotation/></label>
                                                    </Grid>

                                                    <Grid item xs={8}>
                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'vendor'}>
                                                                {(props: { field: any; form: any; meta: any; }) => {
                                                                    const {field, form, meta} = props;
                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`vendor`} {...field}
                                                                                               size={"small"}

                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`vendor`, value)
                                                                                               }}

                                                                                               options={vendorsData ? vendorsData.data: []}
                                                                                               loading={!vendorsData}

                                                                                               getOptionLabel={option => option.name}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <ErrorLabel meta={meta}/>
                                                                        </>
                                                                    )
                                                                }}
                                                            </Field>

                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 15}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="paymentMethod"
                                                               className={styles.label}> Payment Method: <RequiredNotation/></label>
                                                    </Grid>

                                                    <Grid item xs={8}>
                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'paymentMethod'}>
                                                                {(props: { field: any; form: any; meta: any; }) => {
                                                                    const {field, form, meta} = props;
                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`paymentMethod`} {...field}
                                                                                               size={"small"}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`paymentMethod`, value)
                                                                                               }}

                                                                                               options={['Cash', 'Cheque']}
                                                                                               loading={true}

                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <ErrorLabel meta={meta}/>
                                                                        </>
                                                                    )
                                                                }}
                                                            </Field>

                                                        </div>
                                                    </Grid>
                                                </Grid>


                                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 10}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="fromDate"
                                                               className={styles.label}
                                                        >
                                                            Date:<RequiredNotation/>
                                                        </label>
                                                    </Grid>

                                                    <Grid item container xs={8} alignItems={'center'}>

                                                        <DateComponent
                                                            ad={['transactionYearAd', 'transactionMonthAd', 'transactionDateAd']}
                                                            bs={['transactionYearBs', 'transactionMonthBs', 'transactionDateBs']}
                                                        />

                                                    </Grid>
                                                </Grid>


                                            </>
                                        )
                                    }


                                </Grid>

                                <div>

                                    <PurchaseOrderProduct handleRemove={handleRemove} addNew={addNew} setOpenDialog={setOpenDialog}
                                                          mode={Props.mode}
                                               formikInstance={formik}/>
                                </div>

                                </div>

                                {Props.mode !== 'view' &&
                                    <div style={{width: '80%'}}>
                                        <PaymentTerms formikInstance={formik}/>
                                    </div>
                                }

                                {Props.mode !== 'view' ?
                                    <div style={{marginTop: 50}}>
                                        <FormSaveButton mode={Props.mode} from={'product-bundle'}
                                                submitting={formik.isSubmitting}/>
                                    </div>
                                    :
                                    <div>
                                        <ReportActionButton approveData={location.state} path={'Purchase Order'} handlePrint={printClicked} handleDownloadAsPdf={printClicked}/>
                                    </div>

                                }

                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    );
};

const PaymentTerms: React.FC<any> = (Props) => {

    const styles = useStyles();

    const {values: {tableRow}} = useFormikContext();

    const totalAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])

    return (

        <div>
            <div style={{paddingBottom: 20, fontSize: 16, fontWeight: 700, color: COLOR_PROFILE.primaryColor}}>
                <u>Payment terms</u>
            </div>
            <Grid container justify={"space-between"}>


                <Grid container item sm={6} alignItems={'center'} spacing={2}
                      style={{marginBottom: 15}}>
                    <Grid item xs={4}>
                        <label htmlFor="initialPayment" className={styles.label}> Initial
                            Payment:</label>
                    </Grid>
                    <Grid item xs={8}>
                        <div style={{position: 'relative'}}>
                            <Field name={'initialPayment'}>
                                {
                                    (props: { field: any; form: any; meta: any; }) => {

                                        const {field, form, meta} = props;


                                        return (
                                            <>
                                                <InputField type={'number'} id={'initialPayment'} {...field}
                                                            size={"small"}
                                                            fullWidth={true}
                                                            error={meta.touched && meta.error}
                                                />
                                                <ErrorLabel meta={meta}/>
                                            </>

                                        )
                                    }
                                }
                            </Field>

                        </div>

                    </Grid>
                </Grid>

                <Grid container item sm={6} alignItems={'center'} spacing={2}
                      style={{marginBottom: 15}}>
                    <Grid item xs={4}>
                        <label htmlFor="paymentDateAd" className={styles.label}> Initial
                            Payment Date:</label>
                    </Grid>
                    <Grid item container xs={8}>
                        <DateComponent
                            ad={['paymentYearAd', 'paymentMonthAd', 'paymentDateAd']}
                            bs={['paymentYearBs', 'paymentMonthBs', 'paymentDateBs']}
                        />
                    </Grid>
                </Grid>

                <Grid container item sm={6} alignItems={'center'} spacing={2}
                      style={{marginBottom: 15}}>
                    <Grid item xs={4}>
                        <label htmlFor="paymentMethodTerms"
                               className={styles.label}> Payment Method:</label>
                    </Grid>

                    <Grid item xs={8}>
                        <div style={{position: 'relative'}}>
                            <Field name={'paymentMethodTerms'}>
                                {(props: { field: any; form: any; meta: any; }) => {
                                    const {field, form, meta} = props;
                                    return (
                                        <>
                                            <AutoCompleteInput type={'text'}
                                                               id={`paymentMethodTerms`} {...field}
                                                               size={"small"}

                                                               autoHighlight={true}
                                                               autoSelect={true}
                                                               onChange={(e, value) => {
                                                                   form.setFieldValue(`paymentMethodTerms`, value)
                                                               }}

                                                               options={['Cash', 'Cheque']}
                                                               loading={true}

                                                               getOptionLabel={option => option}
                                                               error={meta.touched && meta.error}
                                            />
                                            <ErrorLabel meta={meta}/>
                                        </>
                                    )
                                }}
                            </Field>

                        </div>
                    </Grid>
                </Grid>

                <Grid container item sm={6} alignItems={'center'} spacing={2}
                      style={{marginBottom: 15}}>
                    <Grid item xs={4}>
                        <label htmlFor="chequeNumber" className={styles.label}> Cheque Number:</label>
                    </Grid>
                    <Grid item xs={8}>
                        <div style={{position: 'relative'}}>
                            <Field name={'chequeNumber'}>
                                {
                                    (props: { field: any; form: any; meta: any; }) => {

                                        const {field, form, meta} = props;


                                        return (
                                            <>
                                                <InputField type={'text'} id={'chequeNumber'} {...field}
                                                            size={"small"}
                                                            fullWidth={true}
                                                            error={meta.touched && meta.error}
                                                />
                                                <ErrorLabel meta={meta}/>
                                            </>

                                        )
                                    }
                                }
                            </Field>

                        </div>

                    </Grid>
                </Grid>

                <Grid container item sm={6}  spacing={2}
                      style={{marginBottom: 15}}>
                    <Grid item xs={4} style={{marginTop: 15}}>
                        <label htmlFor="paymentDueAd" className={styles.label}> Payment Due:</label>
                    </Grid>
                    <Grid item container alignItems={"flex-start"} xs={8}>
                        <DateComponent
                            ad={['paymentDueYearAd', 'paymentDueMonthAd', 'paymentDueDateAd']}
                            bs={['paymentDueYearBs', 'paymentDueMonthBs', 'paymentDueDateBs']}
                        />
                    </Grid>
                </Grid>


                <Grid container item sm={6} justify={"flex-end"} spacing={2}
                      style={{marginBottom: 15}}>

                    <Grid item style={{marginRight: 0, fontSize: 16, fontWeight: 600, minWidth: 300}}>

                        <Grid container
                              style={{background: '#d6f6f3', padding: 10, marginTop: 10}}
                              justify={"space-between"}>
                            <Grid item>
                                Total: &nbsp;&nbsp;
                            </Grid>
                            <Grid item>
                                Rs. {totalAmount().toFixed(2)}
                            </Grid>
                        </Grid>

                        <Grid container
                              style={{background: COLOR_PROFILE.tableRowOdd, padding: 10, marginTop: 2}}
                              justify={"space-between"}>
                            <Grid item>
                                Covered: &nbsp;&nbsp;
                            </Grid>
                            <Grid item>
                                Rs. {(Props.formikInstance.values.initialPayment || 0.00).toFixed(2)}
                            </Grid>
                        </Grid>

                        <Grid container
                              style={{background: COLOR_PROFILE.tableRowOdd, padding: 10, marginTop: 2}}
                              justify={"space-between"}>
                            <Grid item>
                                Remaining: &nbsp;&nbsp;
                            </Grid>
                            <Grid item>
                                Rs. {((totalAmount().toFixed(2) - Props.formikInstance.values.initialPayment) || 0.00).toFixed(2)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>

    )
}

export default PurchaseOrderForm;
