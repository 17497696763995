import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {Prompt, useHistory, useLocation} from "react-router-dom";
import * as Yup from "yup";
import {GetEffectiveTime} from "../../../../../config/helperFunctions";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Grid, Popper} from "@material-ui/core";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import DateComponent from "../../../../product/molecules/dateComponent";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import useSWR, {trigger} from "swr";
import {matchSorter} from "match-sorter";
import InputField from "../../../../shared/atom/formElements/inputField";
import {Column} from "react-table";
import VoucherTableForm from "../../../../accounting/organisms/Forms/voucherTableForm";
import {useDispatch} from "react-redux";
import {getTaxAmount} from "../../../../../utils/getTax";
import {getInvoiceAmounts} from "../../../../../utils/invoiceAmounts";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs} from "../../../../../config/dateFunctions";
import {getFormattedDate} from "../../../../../utils/dateFunction";
import {useReactToPrint} from "react-to-print";
import ReportActionButton from "../../../../accounting/organisms/reportActionButton";
import ErrorDialog from "../../../../shared/organisms/errorDialog";
import PurchaseReturnPrintPreview from "./purchaseReturnPrintView";
import {ACCOUNT_CODE_MAPPER} from "../../../../../utils/accountCodeMapper";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {
    from: string
    mode: 'edit' | 'create' | 'view',
    nextPath?: any,
    id ?: string | null
}

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    },
    labelValue: {
        fontWeight: "bold",
        fontSize: 14,
        paddingLeft: 10, paddingRight: 10,
        color: COLOR_PROFILE.primaryColor
    }
}))

const PopperMy = function (props: any) {
    return (<Popper {...props} style={{width: 500}} placement='bottom-start'/>)
}

const PurchaseReturnForm: React.FC<Props> =  (Props) => {
    const initialState = {
        transactionYearAd: null,
        transactionMonthAd: null,
        transactionDateAd: null,
        transactionYearBs: null,
        transactionMonthBs: null,
        transactionDateBs: null,
        code: '',
        vendor: null,
        paymentStatus: 'Full',
        paymentMode: null,
        amountPaid: 0,
        chequeNumber: '',
        purchaseEntryNo: null,
        description: '',
        tableRow: [
            {id: 1, product: null, accountHead: null, quantity: 0, uom: null, price: 0, discount:0, tax: 0, description: '', amount: 0},
            {id: 2, product: null, accountHead:null, quantity: 0, uom: null, price: 0, tax: 0, discount: 0, description: '', amount: 0},
        ],
    }

    const {data: purchaseReturnCode} = useSWR('/getCode?name=purchaseReturn&id=0')

    useEffect(() => {

        if (Props.id) return;

        if (purchaseReturnCode) setFormState(prevState => {
            return {
                ...prevState,
                code: purchaseReturnCode.t.code
            }
        })

    }, [purchaseReturnCode])

    const [openDialog, setOpenDialog] = useState(false)
    const {data: vendorsData} = useSWR('/getVendors');
    const {data: paymentModeOptions} = useSWR('/getHeadsForPayment');
    const {data: headOptionsInCategory} = useSWR( `/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.inventoryAccount},${ACCOUNT_CODE_MAPPER.indirectExpenseAdministrativeExpense},${ACCOUNT_CODE_MAPPER.currentLiabilites}`);
    const {data: purchaseBillList} = useSWR('/getAllPurchaseBillEntries');
    const {data: inventoryProducts} = useSWR('/getAllProductsInInventoryHead?type=inventory');
    const {data: productOptions} = useSWR('/getProducts');
    const {data: inventoryAccount} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.inventoryAccount}`);
    const {data: assetAccount} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.assetAccount}`);
    const {data: returnTypeAccount} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.returnTypeAccount}`);

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();


    const location = useLocation();
    useEffect(() => {
        dispatch({type: 'SET_DATA_FETCHING', payload: true});

        if (!formatedNepEngDates) return;
        if(!paymentModeOptions) return;
        if(!vendorsData) return;
        if(!inventoryProducts) return;
        if(!inventoryAccount) return;
        if(!assetAccount) return;
        if(!returnTypeAccount) return;


        let accounts = [...inventoryAccount.data.map((item: any) => {
            return {...item, isInventory: true}
        }), ...assetAccount.data.map((item: any) => {
            return {...item, subtypename: 'Fixed Asset', isInventory: false, headname: item.name}
        })]

        dispatch({type: 'SET_DATA_FETCHING', payload: false});

        if (Props.id) {

            // @ts-ignore
            const {vendorDto, returnDate, code,amountPaid, productSubs,purchaseBillNumber,paymentStatus,paymentMode,chequeNumber,description, journalVoucherDto} = location.state
            let adTransactionDate = returnDate.split('-');
            let bsTransactionDate: any = convertAdToBs(returnDate, formatedNepEngDates) || [];

            // @ts-ignore
            let tempJson = {
                transactionYearAd: adTransactionDate[0],
                transactionDateAd: adTransactionDate[2],
                transactionMonthAd: adTransactionDate[1],
                transactionDateBs: bsTransactionDate[2],
                transactionMonthBs: bsTransactionDate[1],
                transactionYearBs: bsTransactionDate[0]
            }

            // @ts-ignore
            setFormState({
                ...formState,
                ...tempJson,
                code: code,
                purchaseEntryNo: purchaseBillNumber,
                description: description,
                paymentStatus: paymentStatus,
                chequeNumber: chequeNumber,
                amountPaid: amountPaid,
                paymentMode:paymentMode ?  paymentModeOptions.data.find((item: any) => item.id === paymentMode.id): null,
                tableRow: [...productSubs.map((item: any) => {

                    console.log('acc options',returnTypeAccount.data)
                    console.log('acc head',item.accountHeadId)
                    console.log('find',returnTypeAccount.data.find((itemHead: any) => itemHead.id === item.accountHeadId))

                    return {
                        ...item,
                        product: productOptions.data.find((prodItem: any) => item.productId === prodItem.id),
                        accountHead: returnTypeAccount.data.find((itemHead: any) => itemHead.id === item.accountHeadId)
                    }
                })],

                vendor: vendorDto,
            })
        }


    }, [formatedNepEngDates,inventoryProducts, paymentModeOptions, vendorsData, inventoryAccount, assetAccount])

    const convert = (transactionDate: any) => {

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            transactionYearAd: adDate[0],
            transactionDateAd: adDate[2],
            transactionMonthAd: adDate[1],
            transactionDateBs: bsDate[2],
            transactionMonthBs: bsDate[1],
            transactionYearBs: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }

    useEffect(() => {
        if(Props.id) return;
        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])
    }, [formatedNepEngDates])

    const [formState, setFormState] = useState(initialState);
    const styles = useStyles();
    const validationSchema = Yup.object({
        vendor: Yup.object().when("paymentStatus", {
            is: 'Full',
            then: Yup.object().nullable(true),
            otherwise: Yup.object().required("Required").nullable(true)
        }).nullable(true),
        paymentStatus: Yup.string().required('Required').nullable(true),
        paymentMode: Yup.object().when("paymentStatus", {
            is: 'Unpaid',
            then: Yup.object().nullable(true),
            otherwise: Yup.object().required("Required").nullable(true)
        }).nullable(true),
            amountPaid: Yup.number().when("paymentStatus", {
            is: 'Unpaid',
            then: Yup.number().max(0, "Must be 0"),
            otherwise: Yup.number().min(1, "Must be greater than 0").required("Required")
        })
        // purchaseOrder: Yup.object().required('Required').nullable(true)
    })


    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            if(values.tableRow.filter((item: any) => (item.product )).length === 0){
                handleResponse(`Select at least one product.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRow.filter((item: any) => (item.product) && !(item.quantity && item.price && item.amount)).length > 0) {
                handleResponse(`Some row doesn't have Product, Quantity, Price and Amount.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            let vendorAccount : any = {}

            if(values.paymentStatus !== 'Full'){
                let {data: tempAcc} = await axiosInstance.get(`/getAccountHeadOfVendorById/${values?.vendor?.id}`)
                vendorAccount = tempAcc;
            }
            let taxAccount = headOptionsInCategory.data.filter((item: any) => item.code === ACCOUNT_CODE_MAPPER.vatAccountCode)[0];
            let itemJournal: object[] = [];

            values.tableRow.filter((item: any) => item.product && item.amount).map((item: any) => {

                let account =  item.accountHead;

                let taxAmount = 0;
                let amount = parseFloat(item.amount) ;
                
                // there is no tax in purchase return so this code does not really impact anything.
                if(item.tax > 0) {
                    taxAmount = getTaxAmount(item.amount, parseFloat(item.tax));
                    amount  -= taxAmount
                    itemJournal.push({
                        accountHead: {id: taxAccount.id},
                        amount: taxAmount,
                        tax: item.tax,
                        accountGroupId: taxAccount.accgroupid,
                        type: 'credit',
                        rate: '',
                        description: item.description,
                        subTypeId: taxAccount.subtypeid
                    })
                }

                itemJournal.push({
                    accountHead: {id: account.id},
                    amount: amount,
                    tax: item.tax,
                    type: 'credit',
                    accountGroupId: account.accgroupid,
                    rate: '',
                    description: item.description,
                    subTypeId: account.subtypeid
                })
            })


            let sumProduct = values.tableRow.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)


            let vendorRow: any = [];
            let bankRow: any = [];

            if(values.paymentStatus === 'Full' || values.paymentStatus === 'Semi'){
                bankRow = [{
                    accountHead: {id: values.paymentMode.id},
                    amount: parseFloat(values.amountPaid),
                    tax: '',
                    type: 'debit',
                    accountGroupId: values.paymentMode.accgroupid,
                    rate: '',
                    description: values.chequeNumber,
                    subTypeId: values.paymentMode.subtypeid
                }]
            }
            if(values.paymentStatus === 'Semi' || values.paymentStatus === 'Unpaid'){
                vendorRow = [{
                    accountHead: {id: vendorAccount.t.id},
                    amount:  sumProduct - parseFloat(values.amountPaid || 0),
                    tax: '',
                    type: 'debit',
                    accountGroupId: vendorAccount.t.accgroupid,
                    rate: '',
                    description: '',
                    subTypeId: vendorAccount.t.subtypeid
                }]
            }

            let bankAccount = values.paymentStatus === 'Semi' ? [...vendorRow, ...bankRow] : values.paymentStatus === 'Full' ? [...bankRow] : [...vendorRow];
            const {amount, discount, taxableAmount, taxAmount, totalAmount} = getInvoiceAmounts([...values.tableRow]);

            let voucherEditInfo = {};

            if(Props.mode === 'edit'){
                //@ts-ignore
                let {voucherNumber, id} = location.state.journalVoucherDto
                voucherEditInfo = {
                    voucherNumber: voucherNumber,
                    id: id
                }
            }

            // @ts-ignore
            let payload = {
                productSubs: values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).map((item: any, index: number) => {

                    let editId= {};

                    //add id of each index of productSubs to each product sub in the new list of product that need to be sent to the server
                    if(Props.mode === 'edit'){
                        // @ts-ignore
                        if(location.state.productSubs.length >= index + 1 ){
                            // @ts-ignore
                            editId = {id: location.state.productSubs[index].id}
                        }
                    }

                    return {
                        ...editId,
                        productId: item.product.id,
                        name: item.product.name,
                        accountHeadId: item.accountHead.id,
                        quantity: parseInt(item.quantity),
                        uom: item.uom,
                        price: item.price,
                        discount: 0,
                        amount: parseFloat(item.amount)
                    }
                }),

                paymentStatus: values.paymentStatus,
                paymentMode: values.paymentMode ? {id: values.paymentMode.id} : null,
                chequeNumber:values.chequeNumber,
                amount: amount,
                discount: discount,
                taxableAmount: taxableAmount,
                taxAmount: taxAmount,
                totalAmount: totalAmount,
                amountPaid: values.amountPaid,
                purchaseBillNumber: values.purchaseBillNumber ? {id: values.purchaseBillNumber.id}: null,
                vendorDto:values.vendor ?  {id: values.vendor.id}: null,
                code: values.code,
                returnDate: getFormattedDate(values.transactionYearAd, values.transactionMonthAd, values.transactionDateAd),
                description: values.description,
                journalVoucherDto: {
                    ...voucherEditInfo,
                    type: 'journal',
                    receiptNumber: '',
                    //@ts-ignore
                    journalRows: [
                        ...itemJournal,
                        ...bankAccount
                    ]
                }
            }

            // console.log({...payload,id: parseInt(Props.id as string)});
            // throw new Error('asdf');

            if(Props.id){
                const {data} = await axiosInstance.put('/updatePurchaseReturn', {...payload,id: parseInt(Props.id as string)});
                responseProcess(data, formActions, isReset)
            }else{
                const {data} = await axiosInstance.post('/addPurchaseReturn', payload);
                responseProcess(data, formActions, isReset)
            }


        } catch (e) {
            console.log(e)
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }

        formActions.setSubmitting(false)
    }

    const history = useHistory();

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) history.replace('/dashboard/purchase/purchase-return')
            trigger('/getCode?name=purchaseReturn&id=0')
        } else {
            handleResponse(data.message, 'warning')
        }
    }

    const dispatch = useDispatch();
    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    const handleRemove = (index: number, formikInstance: any) => {

        const {values: {tableRow}} = formikInstance;
        let tempTable = [...tableRow].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue('tableRow', [...tempTable])

    }


    const addNew = useCallback((formikInstance: any) => {

        const {values: {tableRow}} = formikInstance;
        let tempArr = {
            id: tableRow[tableRow.length - 1].id + 1,
            product: null, quantity: 0, uom: null, price: 0, discount:0, tax: 0, description: '', amount: 0
        }
        tableRow.push(tempArr)
        formikInstance.setFieldValue('tableRow', [...tableRow])

    }, [formState])

    const componentRef = useRef(null);
    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const printClicked = async () => {
        if (handlePrint) {
            handlePrint()
        }
    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>

            <Formik
                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {

                    return (
                        <div>

                            <PagePrompt formik={formik}/>

                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>

                                <div ref={componentRef}>

                                <Grid container justify={"space-between"}>

                                    {Props.mode === 'view' ?
                                        (
                                            formik.values.tableRow.length > 0 &&
                                            <PurchaseReturnPrintPreview formik={formik}/>
                                        ):
                                        (
                                            <>

                                            <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                  style={{marginBottom: 15}}>
                                                <Grid item xs={4}>
                                                    <label htmlFor="code"
                                                           className={styles.label}> Purchase Return Code:</label>
                                                </Grid>

                                                <Grid item xs={8}  className={styles.label}>
                                                    {formik.values.code}
                                                </Grid>

                                            </Grid>

                                            <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                  style={{marginBottom: 15}}>
                                                <Grid item xs={4}>
                                                    <label htmlFor="vendor"
                                                           className={styles.label}> Vendor: {formik.values.paymentStatus !== 'Full' && <RequiredNotation/>}</label>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'vendor'}>
                                                            {(props: { field: any; form: any; meta: any; }) => {
                                                                const {field, form, meta} = props;
                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}
                                                                                           id={`vendor`} {...field}
                                                                                           size={"small"}

                                                                                           autoHighlight={true}
                                                                                           autoSelect={true}
                                                                                           onChange={(e, value) => {
                                                                                               form.setFieldValue(`vendor`, value)
                                                                                           }}

                                                                                           options={vendorsData ? vendorsData.data: []}
                                                                                           loading={!vendorsData}

                                                                                           getOptionLabel={option => option.name}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <ErrorLabel meta={meta}/>
                                                                    </>
                                                                )
                                                            }}
                                                        </Field>

                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                  style={{marginBottom: 15}}>
                                                <Grid item xs={4}>
                                                    <label htmlFor="purchaseEntryNo"
                                                           className={styles.label}> Purchase Entry No.:</label>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'purchaseEntryNo'}>
                                                            {(props: { field: any; form: any; meta: any; }) => {
                                                                const {field, form, meta} = props;
                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}
                                                                                           id={`purchaseEntryNo`} {...field}
                                                                                           size={"small"}

                                                                                           autoHighlight={true}
                                                                                           autoSelect={true}
                                                                                           onChange={(e, value) => {
                                                                                               form.setFieldValue(`purchaseEntryNo`, value)
                                                                                           }}

                                                                                           options={purchaseBillList ? purchaseBillList.data: []}
                                                                                           loading={!purchaseBillList}
                                                                                           getOptionLabel={option => option.bill_number}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <ErrorLabel meta={meta}/>
                                                                    </>
                                                                )
                                                            }}
                                                        </Field>

                                                    </div>
                                                </Grid>
                                            </Grid>



                                            <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                  style={{marginBottom: 10}}>
                                                <Grid item xs={4}>
                                                    <label htmlFor="fromDate"
                                                           className={styles.label}
                                                    >
                                                         Return Date:<RequiredNotation/>
                                                    </label>
                                                </Grid>

                                                <Grid item container xs={8} alignItems={'center'}>

                                                    <DateComponent
                                                        ad={['transactionYearAd', 'transactionMonthAd', 'transactionDateAd']}
                                                        bs={['transactionYearBs', 'transactionMonthBs',  'transactionDateBs']}
                                                    />

                                                </Grid>
                                            </Grid>

                                            <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                  style={{marginBottom: 15}}>
                                                <Grid item xs={4}>
                                                    <label htmlFor="paymentStatus"
                                                           className={styles.label}> Payment Status:<RequiredNotation/> </label>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'paymentStatus'}>
                                                            {(props: { field: any; form: any; meta: any; }) => {
                                                                const {field, form, meta} = props;
                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}
                                                                                           id={`paymentStatus`} {...field}
                                                                                           size={"small"}
                                                                                           autoHighlight={true}
                                                                                           autoSelect={true}
                                                                                           onChange={(e, value) => {
                                                                                               form.setFieldValue(`paymentStatus`, value)
                                                                                           }}

                                                                                           options={['Full', 'Semi', 'Unpaid']}
                                                                                           getOptionLabel={option => option}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <ErrorLabel meta={meta}/>
                                                                    </>
                                                                )
                                                            }}
                                                        </Field>

                                                    </div>
                                                </Grid>

                                            </Grid>

                                            <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                  style={{marginBottom: 15}}>
                                                <Grid item xs={4}>
                                                    <label htmlFor="paymentMode"
                                                           className={styles.label}> Payment Mode:{formik.values.paymentStatus !== 'Unpaid' && <RequiredNotation/>}
                                                    </label>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'paymentMode'}>
                                                            {(props: { field: any; form: any; meta: any; }) => {
                                                                const {field, form, meta} = props;
                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}
                                                                                           id={`paymentMode`} {...field}
                                                                                           size={"small"}
                                                                                           autoHighlight={true}
                                                                                           autoSelect={true}
                                                                                           onChange={(e, value) => {
                                                                                               form.setFieldValue(`paymentMode`, value)
                                                                                           }}

                                                                                           options={paymentModeOptions ? paymentModeOptions.data : []}
                                                                                           loading={!paymentModeOptions}

                                                                                           groupBy={option => option.subtypename}
                                                                                           PopperComponent={PopperMy}
                                                                                           getOptionLabel={option => option.headname}
                                                                                           renderOption={(option) => {
                                                                                               return (
                                                                                                   <div>
                                                                                                       <div style={{
                                                                                                           fontSize: 11,
                                                                                                       }}>
                                                                                                           <>{option.code}</>
                                                                                                       </div>
                                                                                                       <div>{option.headname}</div>
                                                                                                   </div>
                                                                                               )
                                                                                           }}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <ErrorLabel meta={meta}/>
                                                                    </>
                                                                )
                                                            }}
                                                        </Field>

                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                  style={{marginBottom: 15}}>
                                                <Grid item xs={4}>
                                                    <label htmlFor="chequeNumber" className={styles.label}> Cheque
                                                        Number:</label>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'chequeNumber'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    return (
                                                                        <>
                                                                            <InputField type={'text'}
                                                                                        id={'chequeNumber'} {...field}
                                                                                        size={"small"}
                                                                                        fullWidth={true}
                                                                                        error={meta.touched && meta.error}
                                                                            />
                                                                            <ErrorLabel meta={meta}/>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                            </Grid>

                                            <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                  style={{marginBottom: 15}}>
                                                <Grid item xs={4}>
                                                    <label htmlFor="amountPaid" className={styles.label}> Amount Paid:{formik.values.paymentStatus !== 'Unpaid' && <RequiredNotation/>}</label>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'amountPaid'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;

                                                                    return (
                                                                        <>
                                                                            <InputField type={'number'}
                                                                                        id={'amountPaid'} {...field}
                                                                                        size={"small"}
                                                                                        fullWidth={true}
                                                                                        error={meta.touched && meta.error}
                                                                            />
                                                                            <ErrorLabel meta={meta}/>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            </>
                                        )}
                                </Grid>

                                <div>
                                    <TableForm handleRemove={handleRemove} addNew={addNew} mode={Props.mode} setOpenDialog={setOpenDialog}
                                               formikInstance={formik}/>
                                </div>

                                <div>
                                    <Grid container item sm={6} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={3}>
                                            <label htmlFor="description" className={styles.label}> Description:</label>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'description'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                {Props.mode === 'view' ? (
                                                                    <div className={styles.labelValue} >
                                                                        {field?.value || ''}
                                                                    </div>
                                                                ):(
                                                                    <>
                                                                    <InputField type={'text'}
                                                                                id={'description'} {...field}
                                                                                size={"small"}
                                                                                multiline={true}
                                                                                rows={3}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                        </>
                                                                    )}
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>
                                </div>

                                </div>

                                {Props.mode !== 'view' ?
                                    <div style={{marginTop: 50}}>
                                        <FormSaveButton mode={Props.mode} from={'product-bundle'}
                                                        submitting={formik.isSubmitting}/>
                                    </div>
                                    :
                                    <div>
                                        <ReportActionButton  approveData={location.state} path={'Purchase Return'} handlePrint={printClicked} handleDownloadAsPdf={printClicked}/>
                                    </div>
                                }
                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    );
};


const TableForm: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance, mode}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRow, amountPaid, paymentStatus}} = useFormikContext();
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');
    const {data: productOptions} = useSWR('/getProducts');
    // const {data: headOptionsInCategory} = useSWR('/getHeadByGroup?groups=CA-209');
    const {data: inventoryAccount} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.inventoryAccount}`);
    const {data: returnTypeAccount} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.returnTypeAccount}`);
    const {data: assetAccount} = useSWR( `/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.assetAccount}`);

    const {data: inventoryProducts} = useSWR('/getAllProductsInInventoryHead?type=inventory');
    const {data: uomOptions} = useSWR('/getAllUoms');

    const name = 'tableRow'
    const formik = useFormikContext();
    const style=useStyles();
    const [errorDialog, setErrorDialog] = useState(false);
    const isView = mode === 'view';

    useEffect(() => {
        if(paymentStatus === 'Full'){
            formik.setFieldValue('amountPaid', (tableRowSum()))
        }

    }, [paymentStatus, tableRow])



    const tableRowSum = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])



    const getDebit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit))
        }, 0.00)
    }, [tableRow])


    const actualAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity))
        }, 0.00)
    }, [tableRow])

    const discountAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity) - parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])

    const bundlePrice = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])


    // let sum = values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
    //     return (result + parseFloat(item.amount))
    // }, 0.00)

    const getCredit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRow])


    const data = useMemo(() => tableRow, [tableRow, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{width: 500}} placement='bottom-start'/>)
    }


    // {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},

    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        {
            id: 'productItem',
            width: isView? undefined:250,
            Header: 'Product Item',
            accessor: "productItem",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].product`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {
                                    let tempRows = form.values.tableRow;

                                    //todo do this section again
                                    let selectedProduct = tempRows.filter((item: any) => item.product).map((item: any) => item.product?.id);
                                    if (field.value?.id) selectedProduct.splice(selectedProduct.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedProduct.includes(item.id)) : [];

                                }

                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"} style={{padding: '5px 0'}}>
                                            <Grid item xs>
                                                {isView ? (
                                                    <div className={style.labelValue}>
                                                        {field?.value?.name || ''}
                                                    </div>
                                                ):(

                                                <AutoCompleteInput type={'text'}
                                                                   id={`${name}[${index}].product`} {...field}
                                                                   size={"small"}
                                                                   placeholder={'Select Product'}
                                                                   filterOptions={(options, {inputValue}) => {
                                                                       let filteredOptions = getOptions(options)
                                                                       //todo do this again
                                                                       return matchSorter(filteredOptions, inputValue, {keys: ['name', 'code']});
                                                                   }}
                                                                   onChange={(e, value) => {
                                                                       if(!value) {
                                                                           form.setFieldValue(`${name}[${index}].product`, value)
                                                                           form.setFieldValue(`${name}[${index}].accountHead`,'')
                                                                           return;
                                                                       }

                                                                       if(value?.inventory_account_id || value?.asset_account_id){
                                                                           form.setFieldValue(`${name}[${index}].product`, value)
                                                                           form.setFieldValue(`${name}[${index}].price`, value ? value.buying_price : 0)
                                                                           form.setFieldValue(`${name}[${index}].amount`, value)

                                                                           // if (inventoryAccount && assetAccount) {
                                                                           //
                                                                           //     let tempAccounts = [...inventoryAccount.data.map((item: any) => {
                                                                           //         return {...item, isInventory: true}
                                                                           //     }), ...assetAccount.data.map((item: any) => {
                                                                           //         return {...item, subtypename: 'Fixed Asset',accgroupid: item.accountGroupDto.id, subtypeid: item.accountSubTypeId, isInventory: false, headname: item.name}
                                                                           //     })]
                                                                           //
                                                                           //     form.setFieldValue(`${name}[${index}].accountHead`,
                                                                           //         tempAccounts.find((accountItem: any) => accountItem.id === (value.inventory_account_id || value.asset_account_id))
                                                                           //     )
                                                                           // }
                                                                       }else{
                                                                           setErrorDialog(true)
                                                                       }

                                                                   }}

                                                                   hideOutline={true}
                                                                   autoHighlight={true}
                                                                   autoSelect={true}
                                                                   options={productOptions ? productOptions.data : []}
                                                                   loading={!productOptions}
                                                                   variant={'standard'}
                                                                   InputProps={{
                                                                       disableUnderline: true,
                                                                       style: {paddingLeft: 5, paddingRight: 5}
                                                                   }}
                                                                   PopperComponent={PopperMy}
                                                                   getOptionLabel={option => option.name}
                                                                   renderOption={(option) => {
                                                                       return (
                                                                           <div>
                                                                               <div style={{
                                                                                   fontSize: 11,
                                                                               }}>
                                                                                   <>{option.code}</>
                                                                               </div>
                                                                               <div>{option.name}</div>
                                                                           </div>
                                                                       )
                                                                   }}
                                                                   error={meta.touched && meta.error}
                                                />
                                                )}
                                            </Grid>

                                            {/*<ToolTip title={'Add new product'}>*/}
                                            {/*    <Grid item style={{*/}
                                            {/*        borderLeft: '1px solid #d0d0d0',*/}
                                            {/*        padding: '8px 3px 3px 3px',*/}
                                            {/*        cursor: 'pointer',*/}
                                            {/*    }}*/}
                                            {/*          onClick={() => handleDialogOpen()}*/}
                                            {/*    >*/}

                                            {/*        <div>*/}

                                            {/*            <AddIcon style={{*/}
                                            {/*                color: COLOR_PROFILE.primaryColor,*/}
                                            {/*                fontWeight: 700,*/}
                                            {/*                fontSize: 20*/}
                                            {/*            }}/>*/}

                                            {/*        </div>*/}
                                            {/*    </Grid>*/}
                                            {/*</ToolTip>*/}
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
          id: 'accountHead',
          Header: 'Return Head',
          width: isView ? undefined:250,
          accessor: 'accountHead',
          Cell: (cellObj: any) => {

              const index = cellObj.row.index;
              const {row: {original}} = cellObj


              return (
                  <Field name={`${name}[${index}].accountHead`}>
                      {
                          (props: { field: any; form: any; meta: any; }) => {

                              const {field, form, meta} = props;


                              return (
                                  <>

                                      {/*<div style={{fontSize: 14, fontWeight: 700, color:COLOR_PROFILE.primaryColor, paddingLeft: 5, paddingRight: 5}}>*/}
                                      {/*    {field?.value?.headname || ''}*/}
                                      {/*</div>*/}
                                      <AutoCompleteInput type={'text'}
                                                         id={`${name}[${index}].product`} {...field}
                                                         size={"small"}
                                                         placeholder={'Select Head'}
                                                         filterOptions={(options, {inputValue}) => {
                                                             // let filteredOptions = getOptions(options)
                                                             //todo do this again
                                                             return matchSorter(options, inputValue, {keys: ['name', 'code']});
                                                         }}
                                                         onChange={(e, value) => {
                                                             form.setFieldValue(`${name}[${index}].accountHead`, value)
                                                         }}

                                                         hideOutline={true}
                                                         autoHighlight={true}
                                                         autoSelect={true}
                                                         options={(returnTypeAccount) ? [...returnTypeAccount.data] : []}
                                                         loading={!returnTypeAccount }
                                                         variant={'standard'}
                                                         InputProps={{
                                                             disableUnderline: true,
                                                             style: {paddingLeft: 5, paddingRight: 5}
                                                         }}
                                                         groupBy={option => option.subtypename}
                                                         PopperComponent={PopperMy}
                                                         getOptionLabel={option => option.headname}
                                                         renderOption={(option) => {
                                                             return (
                                                                 <div>
                                                                     <div style={{
                                                                         fontSize: 11,
                                                                     }}>
                                                                         <>{option.code}</>
                                                                     </div>
                                                                     <div>{option.headname}</div>
                                                                 </div>
                                                             )
                                                         }}
                                                         error={meta.touched && meta.error}
                                      />
                                  </>
                              )
                          }
                      }
                  </Field>
              )
          }
        },

        {
            id: 'quantity',
            Header: 'Quantity',
            accessor: "quantity",
            width: 10,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].quantity`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].quantity`} {...field}
                                                    size={"small"}
                                                    step="1"
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].quantity`, event.target.value)
                                                        let discountedAmount = event.target.value * original.price - (original.discount)/100 * event.target.value * original.price;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
                                                    }}

                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'uom',
            Header: 'UOM',
            width: 30,
            accessor: "uom",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].uom`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value?.name || ''}
                                        </div>
                                    ):(
                                        <AutoCompleteInput type={'text'}
                                                           id={`${name}[${index}].uom`} {...field}
                                                           size={"small"}
                                                           onChange={(e, value) => {
                                                               form.setFieldValue(`${name}[${index}].uom`,value)
                                                           }}

                                                           hideOutline={true}
                                                           autoHighlight={true}
                                                           autoSelect={true}
                                                           options={uomOptions ? uomOptions.data : []}
                                                           loading={!uomOptions}
                                                           variant={'standard'}
                                                           InputProps={{
                                                               disableUnderline: true,
                                                               style: {paddingLeft: 5, paddingRight: 5}
                                                           }}
                                                           getOptionLabel={option => option.name}
                                                           renderOption={(option) => {
                                                               return (
                                                                   <div>
                                                                       <div>{option.name}</div>
                                                                   </div>
                                                               )
                                                           }}
                                                           error={meta.touched && meta.error}
                                        />
                                        )}

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'price',
            Header: 'Price',
            accessor: "price",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].price`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].price`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].price`, event.target.value)
                                                        let discountedAmount = original.quantity * event.target.value - (original.discount)/100 * original.quantity * event.target.value;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
                                                    }}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'amount',
            Header: 'Amount',
            accessor: "amount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].amount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value?.name || ''}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].amount`} {...field}
                                                    size={"small"}
                                                    value={original.amount}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                //console.log(cellObj)

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        //console.log('delete clicked')
        handleRemove(index, formikInstance)
    }

    const getBalance = () => {
        return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }

    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, accountHeadOptions, inventoryProducts,inventoryAccount,assetAccount]);


    return (
        <div>

            <ErrorDialog
                open={errorDialog}
                handleClose={() => setErrorDialog(false)}
                message={<div>The selected item is not associated with proper account head. Purchased items must be have either inventory type or fixed asset type head selected in their Dr. Account Type. Click in this link to learn fully about it.</div>}
                title={'Error!'}
                size={'sm'}
            />

            <div style={{paddingBottom: 40}}>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} balance={getBalance}
                                  total={{
                                      'totalAmount': tableRowSum() ,
                                      'paidAmount': parseFloat(amountPaid || 0),
                                      'remainingAmount': tableRowSum() - parseFloat(amountPaid || 0)
                                  }} type={'purchaseReturn'}
                                  minLength={1}
                                  mode={mode}
                                  fromProduct={true}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )
};

export default PurchaseReturnForm;