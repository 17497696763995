import React, {useEffect, useState} from 'react';
import useSWR from "swr";
import CustomChart from "../../../molecules/customChart";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {Grid, Paper, Popper, useMediaQuery} from "@material-ui/core";
import {MonthList} from "../../../../../utils/contants";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import {matchSorter} from "match-sorter";
import {ACCOUNT_CODE_MAPPER} from "../../../../../utils/accountCodeMapper";
import {useTheme} from "@material-ui/core/styles";
import {Fragment} from 'react';

const ExpenseTemplate = () => {

    const [head, setHead] = useState(null);
    const {data} = useSWR('/report/monthlyExpenseBill')

    // @ts-ignore
    const {data: headData, isLoading} = useSWR('/report/monthlyExpenseBill?accountHeadId=' + (head ? head.id : null));
    const {data: indirectExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectExpenseAccount}`);
    const {data: directExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.directExpenseAccount}`);

    useEffect(() => {
        console.log(data)
    }, [data])

    const PopperMy = function (props: any) {
        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.down('xs'));
        return (<Popper {...props} style={{width: matches ? 250 : 500}} placement='bottom-start'/>)
    }

    const getOptions = (options: any) => {
        return options;
    }

    return (
        <div style={{maxWidth: 700, margin: '0 auto', color: COLOR_PROFILE.primaryColor, paddingTop: 10}}>
            <Grid container justify={"center"} spacing={1} alignItems={"center"}>
                <Grid item style={{fontSize: 16, fontWeight: 700}}>
                    Total Expenses (YTD):
                </Grid>
                <Grid item>
                    <div style={{
                        padding: '5px 10px',
                        marginLeft: 10,
                        fontSize: 16,
                        fontWeight: 700,
                        backgroundColor: COLOR_PROFILE.tableHeader,
                        borderRadius: 5,
                        boxShadow: `0px 2px 4px 0px ${COLOR_PROFILE.backgroundDark}`
                    }}>
                        Rs. {
                        data?.reduce((result: number, item: any) => {
                            return result + item.balance;
                        }, 0).toFixed(2)}
                    </div>
                </Grid>

            </Grid>


            <Grid container justify={"center"} style={{}}>
                <div style={{position: 'relative', height: 'auto', width: '100%'}}>
                    {data && (
                        <Paper style={{padding: 10, marginRight: -12, marginTop: 15}}>
                            <CustomChart
                                data={
                                    {
                                        datasets: [{
                                            label: '',
                                            barPercentage: 0.9,
                                            barThickness: 20,
                                            maxBarThickness: 30,
                                            minBarLength: 2,
                                            // borderColor: COLOR_PROFILE.buttonLightGreen,
                                            tension: 0.1,
                                            fill: false,
                                            backgroundColor: COLOR_PROFILE.buttonLightGreen,
                                            data: [...data.sort((a: any, b: any) => a.nepaliMonth - b.nepaliMonth).map((item: any) => item.balance)]
                                        }],

                                        // showTooltips: true,
                                        labels: [...MonthList.map((item) => item.name)]
                                    }
                                }
                                type={'bar'}
                                options={{
                                    responsive: true, maintainAspectRatio: true, color: COLOR_PROFILE.buttonGreen,
                                    // showTooltips: true,
                                    hover: {
                                        mode: "index",
                                        intersect: false
                                    },
                                    // scales: {
                                    //     xAxis: {
                                    //         barPercentage: 1,
                                    //         categoryPercentage: 0.9,
                                    //     }
                                    // },

                                    // interaction: {
                                    //     intersect: false,
                                    //     mode: 'index',
                                    // },
                                    plugins: {
                                        tooltip: {
                                            enabled: true,
                                            mode: "index",
                                            intersect: true
                                        }
                                    },
                                }}
                            />
                        </Paper>
                    )}

                </div>

            </Grid>

            <Grid container spacing={2} alignItems={"center"} justify={"center"} style={{
                fontSize: 16,
                margin: '20px 0px 10px 0',
                fontWeight: 700,
                backgroundColor: COLOR_PROFILE.tableHeader,
            }}>

                <Grid item>
                    Expense By Month
                </Grid>
                <Grid item xs={5}>
                    <AutoCompleteInput
                        // id={`${name}[${index}].product`} {...field}

                        size={"small"}
                        placeholder={'Select Head'}
                        filterOptions={(options, {inputValue}) => {
                            let filteredOptions = getOptions(options)
                            //todo do this again
                            return matchSorter(filteredOptions, inputValue, {keys: ['name', 'code']});
                        }}
                        onChange={(e, value) => {
                            setHead(value)
                        }}
                        value={head}
                        // hideOutline={true}
                        autoHighlight={true}
                        autoSelect={true}
                        options={(indirectExpenseAccounts && directExpenseAccounts) ? [...indirectExpenseAccounts.data, ...directExpenseAccounts.data] : []}
                        loading={!indirectExpenseAccounts && !directExpenseAccounts}
                        // variant={'standard'}

                        groupBy={option => option.subtypename}
                        PopperComponent={PopperMy}
                        getOptionLabel={option => option.name}
                        renderOption={(option) => {
                            return (
                                <div>
                                    <div style={{
                                        fontSize: 11,
                                    }}>
                                        <>{option.code}</>
                                    </div>
                                    <div>{option.name}</div>
                                </div>
                            )
                        }}
                        // error={meta.touched && meta.error}
                        renderInput={() => <div></div>}
                    />
                </Grid>

            </Grid>


            {!head && (
                <div style={{fontSize: 14, paddingTop: 10, textAlign: "center"}}>
                    Select head to view report! <br/>
                </div>
            )}

            <Grid container justify={"center"} style={{}}>
                <div style={{position: 'relative', height: 'auto', width: '100%'}}>
                    {headData && (

                        <div>
                            <Grid container spacing={2}
                                  style={{marginLeft: 1, marginBottom: 20, fontWeight: 700, fontSize: 14}}>
                                {MonthList.map((item, index) => {
                                    let bgColor = index % 2 == 0 ? COLOR_PROFILE.tableRowEven : COLOR_PROFILE.tableRowOdd;
                                    return (
                                        <>
                                            <Grid item xs={6} style={{background: bgColor, paddingLeft: '10%'}}>
                                                {item.name}
                                            </Grid>
                                            <Grid item xs={6} style={{background: bgColor, paddingLeft: '10%'}}>
                                                Rs. {headData.find((itemData: any) => itemData.nepaliMonth === item.id).balance.toFixed(2)}
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                            {/*{headData.sort((a: any, b: any) => a.nepaliMonth - b.nepaliMonth).map()}*/}
                            <Paper style={{padding: 10, marginRight: -15,}}>
                                <CustomChart
                                    data={
                                        {
                                            datasets: [{
                                                label: '',
                                                barPercentage: 0.9,
                                                barThickness: 20,
                                                maxBarThickness: 30,
                                                minBarLength: 2,
                                                // borderColor: COLOR_PROFILE.buttonLightGreen,
                                                tension: 0.1,
                                                fill: false,
                                                backgroundColor: COLOR_PROFILE.buttonLightGreen,
                                                data: [...headData.sort((a: any, b: any) => a.nepaliMonth - b.nepaliMonth).map((item: any) => item.balance)]
                                            }],

                                            // showTooltips: true,
                                            labels: [...MonthList.map((item) => item.name)]
                                        }
                                    }
                                    type={'bar'}
                                    options={{
                                        responsive: true, maintainAspectRatio: true, color: COLOR_PROFILE.buttonGreen,
                                        // showTooltips: true,
                                        hover: {
                                            mode: "index",
                                            intersect: false
                                        },
                                        // scales: {
                                        //     xAxis: {
                                        //         barPercentage: 1,
                                        //         categoryPercentage: 0.9,
                                        //     }
                                        // },

                                        // interaction: {
                                        //     intersect: false,
                                        //     mode: 'index',
                                        // },
                                        plugins: {
                                            tooltip: {
                                                enabled: true,
                                                mode: "index",
                                                intersect: true
                                            }
                                        },
                                    }}
                                />
                            </Paper>
                        </div>
                    )}

                    {isLoading && (
                        <div style={{height: 1000}}>

                        </div>
                    )}
                </div>

            </Grid>

        </div>
    );
};

export default ExpenseTemplate;
