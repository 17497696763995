import React, {useCallback, useMemo, useState} from "react";
import {Field, useFormik, useFormikContext} from "formik";
import useSWR from "swr";
import {Divider, Grid, Popper, useMediaQuery} from "@material-ui/core";
import AutoCompleteInput from "../../../../../shared/atom/formElements/autocompleteInput";
import {matchSorter} from "match-sorter";
import ToolTip from "../../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import {COLOR_PROFILE} from "../../../../../../config/colorCode";
import InputField from "../../../../../shared/atom/formElements/inputField";
import {Column} from "react-table";
import VoucherTableForm from "../../../../../accounting/organisms/Forms/voucherTableForm";
import ErrorDialog from "../../../../../shared/organisms/errorDialog";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor,
        padding: '5px 2px'

    },
    labelValue: {
        fontWeight: "bold",
        fontSize: 14,
        padding: '7px 10px',
        color: COLOR_PROFILE.primaryColor
    }
}))


const EstimateProductsFormNonTaxable: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance, mode, formValues, type}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRowNonTaxable}} = useFormikContext();
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');
    const {data: productAndBundleOptions} = useSWR('/getProductsBundlesAndOffers');
    const {data: inventoryProducts} = useSWR('/getAllProductsInInventoryHead?type=inventory');

    const {data: uomOptions} = useSWR('/getAllUoms');

    const name = 'tableRowNonTaxable';
    const style = useStyles();
    const formik = useFormikContext();
    const isView = mode === 'view';
    const [errorDialog, setErrorDialog] = useState(false);




    const isOffer = (product: any) => {

        if(!product.offerid) return false;
        let today = new Date().toLocaleString('en-us', {  weekday: 'long' });
        let initials = today.substring(0,2);
        if(product.effective_days.includes(initials)) return true;

    };


    const actualAmount = useCallback(() => {
        return tableRowNonTaxable.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return parseFloat(String(result + (parseFloat(item.price) * parseFloat(item.quantity)))).toFixed(2)
        }, 0.00)
    }, [tableRowNonTaxable])

    const discountAmount = useCallback(() => {
        return tableRowNonTaxable.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return parseFloat(String(result + parseFloat(item.price) * parseFloat(item.quantity) * (parseFloat(item.discount) / 100))).toFixed(2)
        }, 0.00)
    }, [tableRowNonTaxable])


    // let sum = values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
    //     return (result + parseFloat(item.amount))
    // }, 0.00)

    const getCredit = useCallback(() => {
        return tableRowNonTaxable.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRowNonTaxable])


    const data = useMemo(() => tableRowNonTaxable, [tableRowNonTaxable, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.down('xs'));
        return (<Popper {...props} style={{width: matches ? 250:500}} placement='bottom-start'/>)
    }


    // {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},

    const dispatch = useDispatch();
    const handleResponse = (message: any, type: string) => {
        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })
    }


    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        {
            id: 'productItem',
            width: isView ? undefined : 400,
            Header: 'Product Item',
            accessor: "productItem",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj

                return (
                    <div className={style.labelValue} >
                        {original.product?.productname || ''}
                    </div>
                )

                return (
                    <Field name={`${name}[${index}].product`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {

                                    return options;
                                    let tempRows = form.values.tableRow;

                                    //todo do this section again
                                    let selectedProduct = tempRows.filter((item: any) => item.product).map((item: any) => item.product?.id);
                                    if (field.value?.id) selectedProduct.splice(selectedProduct.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedProduct.includes(item.id)) : [];

                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>
                                                {isView ? (
                                                    <div className={style.labelValue}>
                                                        {field?.value?.productname || field?.value?.bundlename  || ''}
                                                    </div>
                                                ):(

                                                    <AutoCompleteInput type={'text'}
                                                                       id={`${name}[${index}].product`} {...field}
                                                                       size={"small"}
                                                                       autoHighlight={true}
                                                                       placeholder={'Select Product'}
                                                                       filterOptions={(options, {inputValue}) => {
                                                                           // let filteredOptions = getOptions(options)
                                                                           //todo do this again
                                                                           return matchSorter(options, inputValue, {keys: [ 'bundlename','name', 'productname','code']}).sort(function(a: any, b: any) {
                                                                               let nameA = a.name.toUpperCase();
                                                                               let nameB = b.name.toUpperCase();
                                                                               if (nameA < nameB) {
                                                                                   return -1;
                                                                               }
                                                                               if (nameA > nameB) {
                                                                                   return 1;
                                                                               }

                                                                               // names must be equal
                                                                               return 0;
                                                                           });
                                                                       }}

                                                                       onChange={(e, value) => {

                                                                           if (!value) {
                                                                               form.setFieldValue(`${name}[${index}].product`, value)
                                                                               form.setFieldValue(`${name}[${index}].quantity`, 0)
                                                                               form.setFieldValue(`${name}[${index}].price`, 0)
                                                                               form.setFieldValue(`${name}[${index}].discount`, 0)
                                                                               return
                                                                           };

                                                                           if(value?.sales_account_id) {
                                                                               form.setFieldValue(`${name}[${index}].product`, value)
                                                                               form.setFieldValue(`${name}[${index}].quantity`, 1)
                                                                               form.setFieldValue(`${name}[${index}].discount`, 0)
                                                                               let offer = isOffer(value);
                                                                               form.setFieldValue(`${name}[${index}].price`, value.name === 'Product' ? offer ? value.amount : value.selling_price : offer ? value.amount : value.bundle_price)
                                                                           }else{
                                                                               setErrorDialog(true)
                                                                           }
                                                                       }}

                                                                       hideOutline={true}
                                                        // autoSelect={true}

                                                                       options={(productAndBundleOptions ) ?
                                                                           [...productAndBundleOptions.data.map((item: any) => {
                                                                               return {...item,productName: item.name, name: 'Product'}
                                                                           }), ...productAndBundleOptions.data2.map((item: any) => {
                                                                               return {...item,  name: 'Bundle'}
                                                                           })] : []}
                                                                       loading={!productAndBundleOptions}
                                                                       variant={'standard'}
                                                                       InputProps={{
                                                                           disableUnderline: true,
                                                                           style: {paddingLeft: 5, paddingRight: 5}
                                                                       }}
                                                                       disabled
                                                                       PopperComponent={PopperMy}
                                                                       groupBy={(option) => option.name}
                                                                       getOptionLabel={option => (isOffer(option)? '⚡ ': '') +  (option.bundlename || option.productname)}
                                                                       renderOption={(option) => {
                                                                           let offer = isOffer(option)
                                                                           return (
                                                                               <div>
                                                                                   {offer && (
                                                                                       <div style={{
                                                                                           fontSize: 11,
                                                                                           color: COLOR_PROFILE.orange
                                                                                       }}>
                                                                                           <>{'⚡ ' + option.offername} <span style={{textDecoration: "line-through", fontSize: 14}}>Rs. { option.name === 'Product' ? option.selling_price: option.bundle_price}</span> <span style={{fontSize: 14}}>Rs. {option.amount}</span></>
                                                                                       </div>
                                                                                   )}
                                                                                   <div style={{color: offer ? COLOR_PROFILE.buttonLightGreen: undefined}}>{option.bundlename || option.productname}</div>
                                                                               </div>
                                                                           )
                                                                       }}
                                                                       error={meta.touched && meta.error}
                                                    />
                                                )}
                                            </Grid>
                                            {!isView && (
                                                <ToolTip title={'Add new product'}>
                                                    <Grid item style={{
                                                        borderLeft: '1px solid #d0d0d0',
                                                        padding: '8px 3px 3px 3px',
                                                        cursor: 'pointer',

                                                    }}
                                                          onClick={() => handleDialogOpen()}
                                                    >
                                                        <div>

                                                            <AddIcon style={{
                                                                color: COLOR_PROFILE.primaryColor,
                                                                fontWeight: 700,
                                                                fontSize: 20
                                                            }}/>

                                                        </div>
                                                    </Grid>
                                                </ToolTip>
                                            )}
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        // {
        //     id: 'quantity',
        //     Header: 'Quantity',
        //     accessor: "quantity",
        //     width: 30,
        //     Cell: (cellObj: any) => {
        //
        //         const index = cellObj.row.index;
        //         const {row: {original}} = cellObj
        //
        //
        //         return (
        //             <Field name={`${name}[${index}].quantity`}>
        //                 {
        //                     (props: { field: any; form: any; meta: any; }) => {
        //
        //                         const {field, form, meta} = props;
        //
        //
        //                         return (
        //                             <>
        //                                 {isView ? (
        //                                     <div className={style.labelValue}>
        //                                         {field?.value || 0}
        //                                     </div>
        //                                 ):(
        //
        //                                     <InputField type={'number'} id={`${name}[${index}].quantity`} {...field}
        //                                                 size={"small"}
        //                                                 step="1"
        //                                                 variant={"standard"}
        //                                                 onChange={(event: any) => {
        //
        //                                                     // if(original.product){
        //                                                     //     if(original.product.inventory_account_id){
        //                                                     //         if(event.target.value > original.product?.quantity){
        //                                                     //             handleResponse(`Entered quantity is more than available quantity(${original.product?.quantity})`, 'warning')
        //                                                     //             return;
        //                                                     //         }
        //                                                     //     }
        //                                                     // }else{
        //                                                     //     handleResponse(`Please select product first.`, 'warning')
        //                                                     //     return;
        //                                                     // }
        //
        //
        //
        //                                                     form.setFieldValue(`${name}[${index}].quantity`, event.target.value)
        //                                                     let discountedAmount = event.target.value * original.price - (original.discount)/100 * event.target.value * original.price;
        //                                                     form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
        //
        //                                                 }}
        //
        //                                                 InputProps={{
        //                                                     disableUnderline: true,
        //                                                     style: {paddingLeft: 5, paddingRight: 5}
        //                                                 }}
        //                                                 style={{border: 'none'}}
        //                                                 error={meta.touched && meta.error}
        //                                     />
        //                                 )}
        //                             </>
        //
        //                         )
        //                     }
        //                 }
        //             </Field>
        //         )
        //     }
        // },
        // {
        //     id: 'uom',
        //     Header: 'UOM',
        //     width: 30,
        //     accessor: "uom",
        //     Cell: (cellObj: any) => {
        //
        //         const index = cellObj.row.index;
        //
        //         const {row: {original}} = cellObj
        //
        //
        //         return (
        //             <Field name={`${name}[${index}].uom`}>
        //                 {
        //                     (props: { field: any; form: any; meta: any; }) => {
        //
        //                         const {field, form, meta} = props;
        //
        //                         return (
        //                             <>
        //                                 {isView ? (
        //                                     <div className={style.labelValue}>
        //                                         {field?.value?.name || ''}
        //                                     </div>
        //                                 ):(
        //                                     <AutoCompleteInput type={'text'}
        //                                                        id={`${name}[${index}].uom`} {...field}
        //                                                        size={"small"}
        //                                                        onChange={(e, value) => {
        //                                                            form.setFieldValue(`${name}[${index}].uom`,value)
        //                                                        }}
        //
        //                                                        hideOutline={true}
        //                                                        autoHighlight={true}
        //                                                        autoSelect={true}
        //                                                        options={uomOptions ? uomOptions.data : []}
        //                                                        loading={!uomOptions}
        //                                                        variant={'standard'}
        //                                                        InputProps={{
        //                                                            disableUnderline: true,
        //                                                            style: {paddingLeft: 5, paddingRight: 5}
        //                                                        }}
        //                                                        getOptionLabel={option => option.name}
        //                                                        renderOption={(option) => {
        //                                                            return (
        //                                                                <div>
        //                                                                    <div>{option.name}</div>
        //                                                                </div>
        //                                                            )
        //                                                        }}
        //                                                        error={meta.touched && meta.error}
        //                                     />
        //                                 )}
        //                             </>
        //
        //                         )
        //                     }
        //                 }
        //             </Field>
        //         )
        //     }
        // },

        {
            id: 'price',
            Header: 'Price',
            accessor: "price",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].price`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        {isView ? (
                                            <div className={style.labelValue}>
                                                {field?.value || 0}
                                            </div>
                                        ):(
                                            <InputField type={'number'} id={`${name}[${index}].price`} {...field}
                                                        size={"small"}
                                                        onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                        style={{border: 'none'}}
                                                        onChange={(event: any) => {
                                                            form.setFieldValue(`${name}[${index}].price`, event.target.value)
                                                            let discountedAmount = original.quantity * event.target.value - (original.discount)/100 * original.quantity * event.target.value;
                                                            form.setFieldValue(`${name}[${index}].amount`, ((discountedAmount)  + original.tax/100 * discountedAmount).toFixed(2))
                                                        }}
                                                        variant={"standard"}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: {paddingLeft: 5, paddingRight: 5}
                                                        }}
                                                        error={meta.touched && meta.error}
                                            />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'discount',
            Header: 'Discount (%)',
            width: 30,
            accessor: "discount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].discount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        {isView ? (
                                            <div className={style.labelValue}>
                                                {field?.value || 0}
                                            </div>
                                        ):(
                                            <InputField type={'number'} id={`${name}[${index}].discount`} {...field}
                                                        size={"small"}

                                                        onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                        style={{border: 'none'}}
                                                        variant={"standard"}
                                                        onChange={(event: any) => {

                                                            try {

                                                                if(isOffer(original.product)){
                                                                    handleResponse("Product has an offer. Discount cannot be allowed", 'warning');
                                                                }
                                                                // @ts-ignore
                                                                else if( (event.target.value  <= original.product.max_allowable_discount) ){
                                                                    if( form.values.customer){
                                                                        if(event.target.value <= form.values.customer.maximum_allowed_discount ){
                                                                            form.setFieldValue(`${name}[${index}].discount`, event.target.value)
                                                                            let discountedAmount = original.quantity * original.price - (event.target.value)/100 * original.quantity * original.price;
                                                                            form.setFieldValue(`${name}[${index}].amount`, ((discountedAmount)  + original.tax/100 * discountedAmount).toFixed(2))
                                                                        }else {
                                                                            let message = (event.target.value  > form.values.customer.maximum_allowed_discount) ? "Value is greater than allowed for customer." : "";
                                                                            message = (event.target.value  > original.product.max_allowable_discount) ? "Value is greater than allowed in item." : message
                                                                            handleResponse(message, 'warning');
                                                                        }
                                                                    }else {
                                                                        form.setFieldValue(`${name}[${index}].discount`, event.target.value)
                                                                        let discountedAmount = original.quantity * original.price - (event.target.value)/100 * original.quantity * original.price;
                                                                        form.setFieldValue(`${name}[${index}].amount`, ((discountedAmount)  + original.tax/100 * discountedAmount).toFixed(2))
                                                                    }
                                                                    // form.setFieldValue(`${name}[${index}].discount`, event.target.value)

                                                                }else{

                                                                    // @ts-ignore
                                                                    console.log("form values",form)
                                                                    // @ts-ignore
                                                                    let  message = (event.target.value  > original.product.max_allowable_discount) ? "Value is greater than allowed in item." : message
                                                                    handleResponse(message, 'warning');
                                                                }

                                                            }catch (e) {
                                                                console.log(e)
                                                                handleResponse("Something went wrong", 'warning');
                                                            }


                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: {paddingLeft: 5, paddingRight: 5}
                                                        }}
                                                        error={meta.touched && meta.error}
                                            />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'amount',
            Header: 'Amount',
            accessor: "amount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].amount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        {isView ? (
                                            <div className={style.labelValue}>
                                                {field?.value || 0}
                                            </div>
                                        ):(
                                            <InputField type={'number'} id={`${name}[${index}].amount`} {...field}
                                                        size={"small"}

                                                        value={original.amount}
                                                        onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                        style={{border: 'none'}}
                                                        variant={"standard"}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: {paddingLeft: 5, paddingRight: 5}
                                                        }}
                                                        error={meta.touched && meta.error}
                                            />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                //console.log(cellObj)

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        //console.log('delete clicked')
        handleRemove(index, formikInstance, name)
    }


    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, accountHeadOptions, productAndBundleOptions, inventoryProducts]);


    return (
        <div>

            <ErrorDialog
                open={errorDialog}
                handleClose={() => setErrorDialog(false)}
                message={<div>The selected item is not associated with proper account head. Sales items must be have sales type head selected in their Dr. Account Type. Click in this link to learn fully about it.</div>}
                title={'Error!'}
                size={'sm'}
            />

            <div style={{paddingBottom: 40}}>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} sectionName={name}
                                  total={{
                                      'grossTotal': actualAmount(),
                                      'discountAmount': discountAmount(),
                                      'subTotal': (actualAmount() - discountAmount()).toFixed(2)
                                  }} type={type || 'purchaseBill'}
                                  minLength={1}
                                  mode={mode}
                                  fromProduct={true}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )
};

export default EstimateProductsFormNonTaxable;
