import moment from "moment-timezone";
import { t } from "./translator";

// data pretaining to nepali calendar
// eslint-disable-next-line @typescript-eslint/no-shadow
export const bsMonths = (t: (v: string, l: string) => void) => [
  t("cal.np.months.1", "np"),
  t("cal.np.months.2", "np"),
  t("cal.np.months.3", "np"),
  t("cal.np.months.4", "np"),
  t("cal.np.months.5", "np"),
  t("cal.np.months.6", "np"),
  t("cal.np.months.7", "np"),
  t("cal.np.months.8", "np"),
  t("cal.np.months.9", "np"),
  t("cal.np.months.10", "np"),
  t("cal.np.months.11", "np"),
  t("cal.np.months.12", "np")
];

// eslint-disable-next-line @typescript-eslint/no-shadow
export const bsDays = (t = (v: string) => v) => [
  t("cal.days.s1"),
  t("cal.days.s2"),
  t("cal.days.s3"),
  t("cal.days.s4"),
  t("cal.days.s5"),
  t("cal.days.s6"),
  t("cal.days.s7")
];

// eslint-disable-next-line @typescript-eslint/no-shadow
export const bsDaysFull = (t = (v: string) => v) => [
  t("cal.days.1"),
  t("cal.days.2"),
  t("cal.days.3"),
  t("cal.days.4"),
  t("cal.days.5"),
  t("cal.days.6"),
  t("cal.days.7")
];

// eslint-disable-next-line @typescript-eslint/no-shadow
export const nepaliNumbers = (t: (v: string, l: string) => void) => [
  t("num.0", "np"),
  t("num.1", "np"),
  t("num.2", "np"),
  t("num.3", "np"),
  t("num.4", "np"),
  t("num.5", "np"),
  t("num.6", "np"),
  t("num.7", "np"),
  t("num.8", "np"),
  t("num.9", "np")
];

export const bsMonthUpperDays = [
  [30, 31],
  [31, 32],
  [31, 32],
  [31, 32],
  [31, 32],
  [30, 31],
  [29, 30],
  [29, 30],
  [29, 30],
  [29, 30],
  [29, 30],
  [30, 31]
];
export const extractedBsMonthData = [
  [
    0, 1, 1, 22, 1, 3, 1, 1, 1, 3, 1, 22, 1, 3, 1, 3, 1, 22, 1, 3, 1, 19, 1, 3,
    1, 1, 3, 1, 2, 2, 1, 3, 1
  ],
  [
    1, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 3, 2, 2, 2, 1, 3, 1, 3, 1, 2,
    2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1,
    3, 1, 2, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 2, 1, 3, 1, 1, 2
  ],
  [
    0, 1, 2, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 2, 2, 2,
    1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 1, 3, 1, 3, 1, 1, 1, 1, 2, 2, 2, 2, 2, 1, 3, 1, 1, 2
  ],
  [
    1, 2, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1,
    3, 1, 3, 1, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 1, 3, 1, 3,
    1, 3, 1, 3, 1, 3, 1, 3, 2, 2, 1, 3, 1, 2, 2, 2, 1, 2
  ],
  [59, 1, 26, 1, 28, 1, 2, 1, 12],
  [
    0, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2,
    2, 2, 2, 2, 2, 2, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 2, 5, 1, 1, 2, 2, 1, 3, 1, 2, 1, 2
  ],
  [
    0, 12, 1, 3, 1, 3, 1, 5, 1, 11, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 18, 1, 3,
    1, 3, 1, 27, 1, 2
  ],
  [
    1, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2, 2, 3, 1, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 1, 2, 2, 2, 15, 2, 4
  ],
  [
    0, 1, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 3, 2, 2, 2, 1, 3, 1, 3, 1,
    3, 1, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    1, 3, 1, 3, 1, 2, 2, 2, 15, 2, 4
  ],
  [
    1, 1, 3, 1, 3, 1, 14, 1, 3, 1, 1, 1, 3, 1, 14, 1, 3, 1, 3, 1, 3, 1, 18, 1,
    3, 1, 3, 1, 3, 1, 14, 1, 3, 15, 1, 2, 1, 1
  ],
  [
    0, 1, 1, 3, 1, 3, 1, 10, 1, 3, 1, 3, 1, 1, 1, 3, 1, 3, 1, 10, 1, 3, 1, 3, 1,
    3, 1, 3, 1, 14, 1, 3, 1, 3, 1, 3, 1, 3, 1, 10, 1, 20, 1, 1, 1
  ],
  [
    1, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 1, 3, 1, 3,
    1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2,
    2, 1, 3, 1, 3, 1, 20, 3
  ]
];
export const minBsYear = 1970;
export const maxBsYear = 2100;
export const minAdDateEqBsDate = {
  ad: {
    year: 1913,
    month: 3,
    date: 13
  },
  bs: {
    year: 1970,
    month: 1,
    date: 1
  }
};

export default class NepaliCalendar {
  private static getMonthDaysNumFormMinBsYear(
    bsMonth: number,
    yearDiff: number
  ): number {
    let yearCount = 0;
    let monthDaysFromMinBsYear = 0;
    if (yearDiff === 0) return 0;

    const bsMonthData = extractedBsMonthData[bsMonth - 1];
    for (let i = 0; i < bsMonthData.length; i += 1) {
      if (bsMonthData[i] === 0) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const bsMonthUpperDaysIndex = i % 2;
      if (yearDiff > yearCount + bsMonthData[i]) {
        yearCount += bsMonthData[i];
        monthDaysFromMinBsYear +=
          bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex] * bsMonthData[i];
      } else {
        monthDaysFromMinBsYear +=
          bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex] *
          (yearDiff - yearCount);
        yearCount = yearDiff - yearCount;
        break;
      }
    }

    return monthDaysFromMinBsYear;
  }

  private static getTotalDaysNumFromMinBsYear = (
    bsYear: number,
    bsMonth: number,
    bsDate: number
  ): number | null => {
    if (bsYear < minBsYear || bsYear > maxBsYear) {
      return null;
    }

    let daysNumFromMinBsYear = 0;
    const diffYears = bsYear - minBsYear;
    for (let month = 1; month <= 12; month += 1) {
      if (month < bsMonth) {
        daysNumFromMinBsYear += NepaliCalendar.getMonthDaysNumFormMinBsYear(
          month,
          diffYears + 1
        );
      } else {
        daysNumFromMinBsYear += NepaliCalendar.getMonthDaysNumFormMinBsYear(
          month,
          diffYears
        );
      }
    }

    if (bsYear > 2085 && bsYear < 2088) {
      daysNumFromMinBsYear += bsDate - 2;
    } else if (bsYear === 2085 && bsMonth > 5) {
      daysNumFromMinBsYear += bsDate - 2;
    } else if (bsYear > 2088) {
      daysNumFromMinBsYear += bsDate - 4;
    } else if (bsYear === 2088 && bsMonth > 5) {
      daysNumFromMinBsYear += bsDate - 4;
    } else {
      daysNumFromMinBsYear += bsDate;
    }

    return daysNumFromMinBsYear;
  };

  public static toDevanagariDigits(arg: number): string {
    return String(arg)
      .split("")
      .map((ed) => nepaliNumbers(t)[Number(ed)])
      .join("");
  }

  public static getBsMonthDays(bsYear: number, bsMonth: number): number | null {
    let yearCount = 0;
    const totalYears = bsYear + 1 - minBsYear;
    const bsMonthData = extractedBsMonthData[bsMonth - 1];
    for (let i = 0; i < bsMonthData.length; i += 1) {
      if (bsMonthData[i] === 0) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const bsMonthUpperDaysIndex = i % 2;
      yearCount += bsMonthData[i];
      if (totalYears <= yearCount) {
        if (
          (bsYear === 2085 && bsMonth === 5) ||
          (bsYear === 2088 && bsMonth === 5)
        ) {
          return bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex] - 2;
        }
        return bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex];
      }
    }
    return null;
  }

  public static convertBStoAD(
    bsYear: number,
    bsMonth: number,
    bsDate: number
  ): Date {
    const daysNumFromMinBsYear = NepaliCalendar.getTotalDaysNumFromMinBsYear(
      bsYear,
      bsMonth,
      bsDate
    );
    const { year, month, date } = minAdDateEqBsDate.ad;
    const adDate = new Date(year, month, date - 1);
    adDate.setDate(adDate.getDate() + (daysNumFromMinBsYear || 0));
    return adDate;
  }

  public static convertADtoBS(
    adYear: number | moment.Moment | Date,
    adMonth?: number,
    adDate?: number
  ): {
    bsYear: number;
    bsMonth: number;
    bsDate: number;
    formatted: string;
    formatted2: string;
    formatted3: string;
    formatted4: string;
    formatted5: string;
  } {
    let newAdYear = adYear;
    let newAdMonth = adMonth;
    let newAdDate = adDate;
    if (moment.isMoment(newAdYear) || moment.isDate(newAdYear)) {
      newAdMonth = moment(newAdYear).month() + 1;
      newAdDate = moment(newAdYear).date();
      newAdYear = moment(newAdYear).year();
    }
    let bsYear = newAdYear + 57;
    let bsMonth = ((newAdMonth || 0) + 9) % 12;
    bsMonth = bsMonth === 0 ? 12 : bsMonth;
    let bsDate = 1;

    if ((newAdMonth || 0) < 4) {
      bsYear -= 1;
    }

    const bsMonthFirstAdDate = NepaliCalendar.convertBStoAD(bsYear, bsMonth, 1);
    if (
      (newAdDate || 0) >= 1 &&
      (newAdDate || 0) < bsMonthFirstAdDate.getDate()
    ) {
      if (newAdMonth === 4) {
        const bsYearFirstAdDate = NepaliCalendar.convertBStoAD(bsYear, 1, 1);
        if ((newAdDate || 0) < bsYearFirstAdDate.getDate()) {
          bsYear -= 1;
        }
      }
      bsMonth = bsMonth !== 1 ? bsMonth - 1 : 12;
      const bsMonthDays = NepaliCalendar.getBsMonthDays(bsYear, bsMonth);
      bsDate =
        (bsMonthDays || 0) -
        (bsMonthFirstAdDate.getDate() - (newAdDate || 0)) +
        1;
    } else {
      bsDate = (newAdDate || 0) - bsMonthFirstAdDate.getDate() + 1;
    }

    return {
      bsYear,
      bsMonth,
      bsDate,
      formatted: `${NepaliCalendar.toDevanagariDigits(
        bsYear
      )}/${NepaliCalendar.toDevanagariDigits(
        bsMonth
      )}/${NepaliCalendar.toDevanagariDigits(bsDate)}`,
      formatted2: `${bsMonths(t)[bsMonth - 1]} ${bsDate}, ${bsYear}`,
      formatted3: `${bsMonths(t)[bsMonth - 1]} ${bsDate}`,
      formatted4: `${bsYear}-${bsMonth}-${bsDate}`,
      formatted5: `${bsDate} ${bsMonths(t)[bsMonth - 1]} ${bsYear}`
    };
  }
}
