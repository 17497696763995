import {Field, Form, Formik, useFormikContext} from 'formik';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import * as Yup from "yup";
import {Grid, Popper} from "@material-ui/core";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import {matchSorter} from "match-sorter";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import DateComponent from "../../../molecules/dateComponent";
import {makeStyles} from "@material-ui/core/styles";
import InputField from "../../../../shared/atom/formElements/inputField";
import ErrorLabel from "../../../molecules/errorLabel";
import useSWR, {trigger} from "swr";
import ToolTip from "../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import {Column} from "react-table";
import VoucherTableForm from "../../../../accounting/organisms/Forms/voucherTableForm";
import {useHistory, useLocation} from "react-router-dom";
import axiosInstance from "../../../../../config/axiosConfig";
import CustomTimePicker from "../../../../shared/atom/formElements/picker";
import DaysLayout from "../../../molecules/daysLayout";
import {addOrRemoveFromArray, GetEffectiveTime} from "../../../../../config/helperFunctions";
import {useDispatch} from "react-redux";
import {convertAdToBs} from "../../../../../config/dateFunctions";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {getFormattedDate} from "../../../../../utils/dateFunction";
import AccountHeadForm from "../../../../accounting/organisms/Forms/accountHeadForm";
import DialogComponent from "../../../../shared/atom/dialog";
import ProductItemForm from "../productItem";
import {ACCOUNT_CODE_MAPPER} from "../../../../../utils/accountCodeMapper";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {

    from: string
    mode: 'edit' | 'create',
    nextPath?: any,
    id ?: string | null

}

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const PopperMy = function (props: any) {
    return (<Popper {...props} style={{width: 500}} placement='bottom-start'/>)
}

const ProductBundleForm: React.FC<Props> = (Props) => {

    const initialState = {
        transactionYearAdFrom: null,
        transactionMonthAdFrom: null,
        transactionDateAdFrom: null,
        transactionYearBsFrom: null,
        transactionMonthBsFrom: null,
        transactionDateBsFrom: null,
        transactionYearAdTill: null,
        transactionMonthAdTill: null,
        transactionDateAdTill: null,
        transactionYearBsTill: null,
        transactionMonthBsTill: null,
        transactionDateBsTill: null,
        bundleName: '',
        code: '',

        bundleQuantity: 1,
        inventoryAssetAccount: null,
        salesAccount: null,
        effectiveDays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        effectiveTimeStart: new Date(1970, 0, 0, 0, 0, 0),
        effectiveTimeEnd: new Date(1970, 0, 0, 23, 59, 0),
        tableRow: [
            {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},
            {id: 2, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},
        ],

    }

    const location = useLocation();

    const {data: bundleCode} = useSWR('/getCode?name=productBundle&id=0')

    useEffect(() => {
        if(Props.id) return;
        if (bundleCode) setFormState(prevState => {
            return {
                ...prevState,
                code: bundleCode.t.code
            }
        })
    }, [bundleCode])

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();
    const {data: productOptions} = useSWR('/getAllProductsInInventoryHead?type=inventory');
    const {data: uomOptions} = useSWR('/getAllUoms');
    const {data: salesAccounts} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.directIncomeRevenue}`);
    const {data: inventoryAccount} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.inventoryAccount}`);
    const {data: assetAccount} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.assetAccount}`);
    const {data: indirectExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectExpenseAccount}`);
    const {data: directExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.directExpenseAccount}`);

    useEffect(() => {

        if (!formatedNepEngDates) return;
        if(!productOptions) return;
        if(!uomOptions) return;
        if(!salesAccounts) return;
        if(!assetAccount) return;
        if(!indirectExpenseAccounts) return;
        if(!directExpenseAccounts) return;

        let assetInvAccounts =  [...inventoryAccount.data.map((item: any) => {
            return {...item, isInventory: true}
        }), ...assetAccount.data.map((item: any) => {
            return {...item, subtypename: 'Fixed Asset', isInventory: false, headname: item.name}
        }), ...indirectExpenseAccounts.data.map((item: any) => {
            return {...item, subtypename: 'Indirect Expense', isExpense: true, headname: item.name}
        }),...directExpenseAccounts.data.map((item: any) => {
            return {...item, subtypename: 'Direct Expense', isExpense: true, headname: item.name}
        })]

        if (Props.id) {

            // @ts-ignore
            const {bundlePrice,bundleQuantity,code,assetAccount,inventoryAccount, salesAccount, expenseAccount,description,discountAmount,effectiveFrom,effectiveTo,name,effectiveDays,effectiveTime, productSub} = location.state
            let adDateFrom = effectiveFrom.split('-')
            let bsDateFrom: any = convertAdToBs(effectiveFrom, formatedNepEngDates) || [];
            let adDateTill = effectiveTo ? effectiveTo.split('-'): [];
            let bsDateTill: any = convertAdToBs(effectiveTo, formatedNepEngDates) || [];

            // @ts-ignore
            let tempJson = {
                transactionYearAdFrom: adDateFrom[0],
                transactionMonthAdFrom: adDateFrom[1],
                transactionDateAdFrom: adDateFrom[2],
                transactionYearBsFrom: bsDateFrom[0],
                transactionMonthBsFrom: bsDateFrom[1],
                transactionDateBsFrom: bsDateFrom[2],
                transactionYearAdTill: adDateTill[0],
                transactionMonthAdTill: adDateTill[1],
                transactionDateAdTill: adDateTill[2],
                transactionYearBsTill: bsDateTill[0],
                transactionMonthBsTill: bsDateTill[1],
                transactionDateBsTill: bsDateTill[2],
            }

            let effectiveTimeArray = effectiveTime.split('-');

            // @ts-ignore
            setFormState({

                ...tempJson,
                bundleName: name,
                code: code,

                bundleQuantity: bundleQuantity,
                effectiveDays: effectiveDays.split(','),
                effectiveTimeStart: new Date(1970, 0, 0, parseInt(effectiveTimeArray[0]), parseInt(effectiveTimeArray[1]), 0),
                effectiveTimeEnd: new Date(1970, 0, 0, parseInt(effectiveTimeArray[2]), parseInt(effectiveTimeArray[3]), 0),
                inventoryAssetAccount: assetInvAccounts ? assetInvAccounts.find((itemHead: any) => itemHead.id === (assetAccount ? assetAccount?.id : inventoryAccount ? inventoryAccount?.id: expenseAccount?.id)): null,
                salesAccount: salesAccounts ? salesAccounts.data.find((item: any) => item.id === salesAccount?.id) : null,
                tableRow: productSub.map((item: any) => {

                    return {
                        id: item.id,
                        product: productOptions.data.find((itemOption: any) => itemOption.id === item.productId),
                        quantity: item.quantity,
                        uom: null,
                        price: item.price,
                        discount: item.discount,
                        description: item.description,
                        amount: item.amount

                    }
                }),



            })

        }


    }, [formatedNepEngDates,productOptions, uomOptions, salesAccounts, assetAccount, indirectExpenseAccounts, directExpenseAccounts ])

    const convert = (transactionDate: any) => {

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            transactionYearAdFrom: adDate[0],
            transactionDateAdFrom: adDate[2],
            transactionMonthAdFrom: adDate[1],
            transactionDateBsFrom: bsDate[2],
            transactionMonthBsFrom: bsDate[1],
            transactionYearBsFrom: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }

    useEffect(() => {

        if (Props.id) return;
        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])

    }, [formatedNepEngDates])

    const [formState, setFormState] = useState(initialState);
    const [openDialog, setOpenDialog] = useState(false)

    const styles = useStyles();
    const validationSchema = Yup.object({
        code: Yup.string().required(''),
        bundleName: Yup.string().required('Required'),
        bundleQuantity: Yup.number().required('Required'),
        inventoryAssetAccount: Yup.object().required('Required').nullable(true),
        salesAccount: Yup.object().required('Required').nullable(true)
    })

    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            if(values.effectiveDays.length === 0){
                handleResponse(`Select at least one effective days.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRow.filter((item: any) => (item.product)).length === 0){
                handleResponse(`Select at least one product to create a bundle.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            let sum = values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            if(values.tableRow.filter((item: any) => (item.product) && !(item.quantity && item.price && item.amount)).length > 0) {
                handleResponse(`Some row doesn't have Product, Quantity, Price and Amount.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }


            let productSubPayload = values.tableRow.filter((item: any) => item.product && item.quantity && item.price && item.amount).map((item: any, index: number) => {

                let editId= {};

                //add id of each index of productSubs to each product sub in the new list of product that need to be sent to the server
                if(Props.mode === 'edit'){
                    // @ts-ignore
                    if(location.state.productSub.length >= index + 1 ){
                        // @ts-ignore
                        editId = {id: location.state.productSub[index].id}
                    }
                }
                return {
                    ...editId,
                    productId: item.product.id,
                    name: item.product.name,
                    quantity: item.quantity,
                    uom: item.uom ? {id: item.uom.id}: null,
                    price: item.price,
                    discount: item.discount ? parseFloat(item.discount) : 0,
                    amount: parseFloat(item.amount)
                }
            })


            let payload = {
                productSub: productSubPayload,
                name: values.bundleName,
                code: values.code,
                //todo clarity what is the case here

                // quantity null if nothing is mentioned, null will have infinite quantity
                bundleQuantity: values.bundleQuantity || null,
                effectiveFrom: getFormattedDate(values.transactionYearAdFrom , values.transactionMonthAdFrom, values.transactionDateAdFrom),
                effectiveTo: getFormattedDate(values.transactionYearAdTill, values.transactionMonthAdTill, values.transactionDateAdTill),
                effectiveTime: GetEffectiveTime(values.effectiveTimeStart, values.effectiveTimeEnd),
                description: values.description,
                effectiveDays: values.effectiveDays.join(','),
                bundlePrice: sum,
                assetAccount: values.inventoryAssetAccount.isInventory ? null : {id: values.inventoryAssetAccount.id},
                inventoryAccount: values.inventoryAssetAccount.isInventory ? {id: values.inventoryAssetAccount.id} : null,
                salesAccount: values.salesAccount ? {id: values.salesAccount.id}: null,
                expenseAccount: values.inventoryAssetAccount.isExpense ? {id: values.inventoryAssetAccount.id}: null

            }

            if(Props.id){
                const {data} = await axiosInstance.put('/updateProductBundle', {...payload,id: parseInt(Props.id as string)});
                responseProcess(data, formActions, isReset)
            } else {
                const {data} = await axiosInstance.post('/addProductBundle', payload);
                responseProcess(data, formActions, isReset)
            }

        } catch (e) {
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }

        formActions.setSubmitting(false)
    }

    const history = useHistory();
    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if (!isReset) history.replace('/dashboard/product/product-bundle');
            trigger('/getCode?name=productBundle&id=0');
        } else {

            handleResponse(data.message, 'warning')

        }
    }


    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }


    const handleRemove = (index: number, formikInstance: any) => {

        const {values: {tableRow}} = formikInstance;
        let tempTable = [...tableRow].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue('tableRow', [...tempTable])

    }


    const addNew = useCallback((formikInstance: any) => {

        const {values: {tableRow}} = formikInstance;
        //console.log(tableRow);
        let tempArr = {
            id: tableRow[tableRow.length - 1].id + 1,
            product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0
        }

        tableRow.push(tempArr)
        formikInstance.setFieldValue('tableRow', [...tableRow])

    }, [formState])

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>

            <DialogComponent open={openDialog} handleClose={setOpenDialog} title={'Add Product'}
                             maxWidth={'md'}>
                <ProductItemForm mode={'create'} from={'journal page'} handleClose={() => setOpenDialog(false)}
                                 noFixed={true}
                />
            </DialogComponent>

            <Formik
                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {

                    return (
                        <div>
                            <PagePrompt formik={formik}/>
                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>
                                <Grid container justify={"space-between"}>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="code"
                                                   className={styles.label}> Bundle Code:</label>
                                        </Grid>

                                        <Grid item xs={8} className={styles.label}>
                                            {formik.values.code}
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="bundleName"
                                                   className={styles.label}> Bundle Name:<RequiredNotation/></label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'bundleName'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'text'}
                                                                            id={'bundleName'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>

                                        <Grid item xs={4}>
                                            <label htmlFor="bundleQuantity"
                                                   className={styles.label}> Bundle Quantity:<RequiredNotation/></label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'bundleQuantity'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'number'}
                                                                            id={'bundleQuantity'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="fromDate"
                                                   className={styles.label}
                                            >
                                                From:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <DateComponent
                                                ad={['transactionYearAdFrom', 'transactionMonthAdFrom', 'transactionDateAdFrom']}
                                                bs={['transactionYearBsFrom', 'transactionMonthBsFrom', 'transactionDateBsFrom']}
                                            />

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="tillDate"
                                                   className={styles.label}
                                            >
                                                Till:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <DateComponent
                                                ad={['transactionYearAdTill', 'transactionMonthAdTill', 'transactionDateAdTill']}
                                                bs={['transactionYearBsTill', 'transactionMonthBsTill', 'transactionDateBsTill']}
                                            />

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="tillDate"
                                                   className={styles.label}
                                            >
                                                Effective Days:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <Field name={'effectiveDays'}>
                                                {(props: { field: any; form: any; meta: any; }) => {
                                                    const {field, form, meta} = props;
                                                    return (
                                                        <>
                                                            <DaysLayout selected={field.value}
                                                                        handleChange={(name: string) => (event: any) => {
                                                                            form.setFieldValue('effectiveDays', addOrRemoveFromArray(field.value, name))
                                                                        }}
                                                            />
                                                        </>
                                                    )
                                                }}
                                            </Field>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="effectiveTimeStart"
                                                   className={styles.label}
                                            >
                                                Effective Time:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={4} alignItems={'center'}>

                                            <div style={{position: 'relative'}}>
                                                <Field name={'effectiveTimeStart'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>

                                                                <CustomTimePicker
                                                                    id={'effectiveTimeStart'} {...field}
                                                                    inputVariant={'outlined'}
                                                                    variant="inline"
                                                                    autoOk={true}
                                                                    clearable={true}
                                                                    placeholder={'Start Time'}
                                                                    onChange={(event: any, val: any) => {
                                                                        console.log(event, val)
                                                                        form.setFieldValue('effectiveTimeStart', event)
                                                                    }}
                                                                    size={"small"}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>

                                        </Grid>

                                        <Grid item container xs={4} alignItems={'center'}>

                                            <div style={{position: 'relative'}}>
                                                <Field name={'effectiveTimeEnd'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>

                                                                <CustomTimePicker
                                                                    id={'effectiveTimeEnd'} {...field}
                                                                    inputVariant={'outlined'}
                                                                    variant="inline"
                                                                    placeholder={'End Time'}
                                                                    clearable={true}
                                                                    autoOk={true}

                                                                    clearLabel={'Clear'}
                                                                    onChange={(event: any, val: any) => {
                                                                        form.setFieldValue('effectiveTimeEnd', event)
                                                                    }}
                                                                    size={"small"}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>

                                        </Grid>

                                    </Grid>



                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="inventoryAssetAccount"
                                                   className={styles.label}> Inventory/ Asset/ Expense Account:<RequiredNotation/>
                                            </label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'inventoryAssetAccount'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`inventoryAssetAccount`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`inventoryAssetAccount`, value)
                                                                                   }}

                                                                                   options={(inventoryAccount && assetAccount && indirectExpenseAccounts && directExpenseAccounts) ?
                                                                                       [...inventoryAccount.data.map((item: any) => {
                                                                                           return {...item, isInventory: true}
                                                                                       }), ...assetAccount.data.map((item: any) => {
                                                                                           return {...item, subtypename: 'Fixed Asset', isInventory: false, headname: item.name}
                                                                                       }),...indirectExpenseAccounts.data.map((item: any) => {
                                                                                           return {...item, subtypename: 'Indirect Expense', isExpense: true, headname: item.name}
                                                                                       }),...directExpenseAccounts.data.map((item: any) => {
                                                                                           return {...item, subtypename: 'Direct Expense', isExpense: true, headname: item.name}
                                                                                       })]
                                                                                       : []}
                                                                                   loading={!inventoryAccount && !assetAccount && !indirectExpenseAccounts && !directExpenseAccounts}

                                                                                   groupBy={option => option.subtypename}
                                                                                   PopperComponent={PopperMy}
                                                                                   getOptionLabel={option => option.headname}
                                                                                   renderOption={(option) => {
                                                                                       return (
                                                                                           <div>
                                                                                               <div style={{
                                                                                                   fontSize: 11,
                                                                                               }}>
                                                                                                   <>{option.code}</>
                                                                                               </div>
                                                                                               <div>{option.headname}</div>
                                                                                           </div>
                                                                                       )
                                                                                   }}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>



                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="salesAccount"
                                                   className={styles.label}> Sales Account:<RequiredNotation/>
                                            </label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'salesAccount'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`salesAccount`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`salesAccount`, value)
                                                                                   }}

                                                                                   options={salesAccounts ? salesAccounts.data : []}
                                                                                   loading={!salesAccounts}

                                                                                   groupBy={option => option.subtypename}
                                                                                   PopperComponent={PopperMy}
                                                                                   getOptionLabel={option => option.headname}
                                                                                   renderOption={(option) => {
                                                                                       return (
                                                                                           <div>
                                                                                               <div style={{
                                                                                                   fontSize: 11,
                                                                                               }}>
                                                                                                   <>{option.code}</>
                                                                                               </div>
                                                                                               <div>{option.headname}</div>
                                                                                           </div>
                                                                                       )
                                                                                   }}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <div>

                                    <TableForm handleRemove={handleRemove} addNew={addNew}
                                               setOpenDialog={setOpenDialog} formikInstance={formik}
                                    />

                                </div>


                                <div>
                                    <FormSaveButton mode={Props.mode} from={'product-bundle'}
                                                    submitting={formik.isSubmitting}/>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    );
};

const TableForm: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRow}} = useFormikContext();
    const {data: productOptions} = useSWR('/getAllProductsInInventoryHead?type=inventory');
    const {data: uomOptions} = useSWR('/getAllUoms');

    const name = 'tableRow'
    const formik = useFormikContext();


    const getDebit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit))
        }, 0.00)
    }, [tableRow])


    const actualAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity))
        }, 0.00)
    }, [tableRow])

    const discountAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity) - parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])

    const bundlePrice = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])


    // let sum = values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
    //     return (result + parseFloat(item.amount))
    // }, 0.00)

    const getCredit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRow])


    const data = useMemo(() => tableRow, [tableRow, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{width: 500}} placement='bottom-start'/>)
    }


    // {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},

    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        {
            id: 'productItem',
            width: 400,
            Header: 'Product Item',
            accessor: "productItem",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].product`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {
                                    let tempRows = form.values.tableRow;
                                    let selectedProduct = tempRows.filter((item: any) => item.product).map((item: any) => item.product?.id);
                                    if (field.value?.id) selectedProduct.splice(selectedProduct.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedProduct.includes(item.id)) : [];

                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>
                                                <AutoCompleteInput type={'text'}
                                                                   id={`${name}[${index}].product`} {...field}
                                                                   size={"small"}
                                                                   placeholder={'Select Product'}
                                                                   filterOptions={(options, {inputValue}) => {
                                                                       let filteredOptions = getOptions(options)

                                                                       return matchSorter(filteredOptions, inputValue, {keys: ['name', 'code']});
                                                                   }}
                                                                   onChange={(e, value) => {
                                                                       form.setFieldValue(`${name}[${index}].price`, value ? value.selling_price : 0)
                                                                       form.setFieldValue(`${name}[${index}].product`, value)
                                                                       form.setFieldValue(`${name}[${index}].amount`, value)
                                                                   }}

                                                                   hideOutline={true}
                                                                   autoHighlight={true}
                                                                   autoSelect={true}
                                                                   options={productOptions ? productOptions.data : []}
                                                                   loading={!productOptions}
                                                                   variant={'standard'}
                                                                   InputProps={{
                                                                       disableUnderline: true,
                                                                       style: {paddingLeft: 5, paddingRight: 5}
                                                                   }}
                                                                   PopperComponent={PopperMy}
                                                                   getOptionLabel={option => option.name}
                                                                   renderOption={(option) => {
                                                                       return (
                                                                           <div>
                                                                               <div style={{
                                                                                   fontSize: 11,
                                                                               }}>
                                                                                   <>{option.code}</>
                                                                               </div>
                                                                               <div>{option.name}</div>
                                                                           </div>
                                                                       )
                                                                   }}
                                                                   error={meta.touched && meta.error}
                                                />
                                            </Grid>
                                            <ToolTip title={'Add new product'}>

                                                <Grid item style={{
                                                    borderLeft: '1px solid #d0d0d0',
                                                    padding: '8px 3px 3px 3px',
                                                    cursor: 'pointer',
                                                }}
                                                      onClick={() => handleDialogOpen()}
                                                >

                                                    <div>

                                                        <AddIcon style={{
                                                            color: COLOR_PROFILE.primaryColor,
                                                            fontWeight: 700,
                                                            fontSize: 20
                                                        }}/>

                                                    </div>
                                                </Grid>
                                            </ToolTip>
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'quantity',
            Header: 'Quantity',
            width: 30,
            accessor: "quantity",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].quantity`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].quantity`} {...field}
                                                    size={"small"}
                                                    step="1"
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        console.log(cellObj.cell.row.original)
                                                        console.log(event.target.value)
                                                        form.setFieldValue(`${name}[${index}].quantity`, event.target.value)
                                                        form.setFieldValue(`${name}[${index}].amount`, (1 - original.discount / 100) * event.target.value * original.price)
                                                    }}

                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'uom',
            Header: 'UOM',
            width: 140,
            accessor: "uom",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].uom`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <AutoCompleteInput type={'text'}
                                                           id={`${name}[${index}].uom`} {...field}
                                                           size={"small"}
                                                           onChange={(e, value) => {
                                                               form.setFieldValue(`${name}[${index}].uom`,value)
                                                           }}

                                                           hideOutline={true}
                                                           autoHighlight={true}
                                                           autoSelect={true}
                                                           options={uomOptions ? uomOptions.data : []}
                                                           loading={!uomOptions}
                                                           variant={'standard'}
                                                           InputProps={{
                                                               disableUnderline: true,
                                                               style: {paddingLeft: 5, paddingRight: 5}
                                                           }}
                                                           getOptionLabel={option => option.name}
                                                           renderOption={(option) => {
                                                               return (
                                                                   <div>
                                                                       <div>{option.name}</div>
                                                                   </div>
                                                               )
                                                           }}
                                                           error={meta.touched && meta.error}
                                        />
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'price',
            Header: 'Price',
            accessor: "price",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].price`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].price`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].price`, event.target.value)
                                                        form.setFieldValue(`${name}[${index}].amount`, (1 - original.discount / 100) * event.target.value * original.quantity)
                                                    }}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'discount',
            Header: 'Discount (%)',
            width: 10,
            accessor: "discount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].discount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].discount`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        console.log(event.target.value)
                                                        form.setFieldValue(`${name}[${index}].discount`, event.target.value)
                                                        // form.setFieldValue(`${name}[${index}].amount`, 1)

                                                        form.setFieldValue(`${name}[${index}].amount`, (1 - event.target.value / 100) * original.quantity * original.price)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'amount',
            Header: 'Amount',
            accessor: "amount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj

                // formik.setFieldValue(`${name}[${index}].amount`, original.quantity * original.price)


                return (
                    <Field name={`${name}[${index}].amount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].amount`} {...field}
                                                    size={"small"}
                                                    value={original.amount}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                return (
                    <div>

                    </div>
                )
            }
        }

    ]

    const handleDeleteClick = (index: number) => {
        handleRemove(index, formikInstance)
    }

    const getBalance = () => {
        return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }

    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, productOptions]);


    return (
        <div>

            <div style={{paddingBottom: 40}}>

                <VoucherTableForm
                    columns={columns} data={data} addNew={addNew} balance={getBalance}
                      total={{
                          'actualAmount': actualAmount(),
                          'discountAmount': discountAmount(),
                          'bundlePrice': bundlePrice()
                      }} type={'bundle'}
                      minLength={2}
                      fromProduct={true}
                      handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )

};


export default ProductBundleForm;