import {Route} from "react-router-dom";
import {motion} from "framer-motion";
import AccessFilter from "../../../accessControl/accessFilter";
import AccessDenied from "../../../components/accounting/templates/errorTemplates/accessDenied";
import React from "react";
import ShareHolderPage from "../../../components/accounting/virtualPages/companySetup/shareHolder";
import AddShareHolderPage from "../../../components/accounting/virtualPages/companySetup/addShareHolderPage";
import BranchPage from "../../../components/accounting/virtualPages/companySetup/branches";
import AddBranchPage from "../../../components/accounting/virtualPages/companySetup/addBranchesPage";
import EmployeePage from "../../../components/accounting/virtualPages/companySetup/employee";
import AddEmployeePage from "../../../components/accounting/virtualPages/companySetup/addEmployeePage";
import UserAndRolePage from "../../../components/accounting/virtualPages/companySetup/userAndRoles";
import AddUserAndRolePage from "../../../components/accounting/virtualPages/companySetup/addUserAndRolePage";
import BankPage from "../../../components/accounting/virtualPages/companySetup/banks";
import AddBanksPage from "../../../components/accounting/virtualPages/companySetup/addBanksPage";
import VendorsSetup from "../../../components/accounting/virtualPages/companySetup/vendors";
import AddVendorPage from "../../../components/accounting/virtualPages/companySetup/addVendorsPage";

export const CompanySetupRoutes = [

    <Route path={'/dashboard/company-setup'} exact>
        <div>
            Lets show some quick links and reports here just to make sense of hierarchy
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/share-holders'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"shareHolders"}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <ShareHolderPage/>
                                </div>

                            </div>

                        )

                    }}

                />

            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/share-holders/add'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}
            >
                <AccessFilter
                    section={"shareHolders"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <AddShareHolderPage/>

                                </div>

                            </div>

                        )

                    }}

                />


            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/share-holders/edit/:id'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}
            >
                <AccessFilter
                    section={"shareHolders"}
                    privilege={"update"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <AddShareHolderPage/>
                                </div>

                            </div>

                        )

                    }}

                />

            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/branch'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"branches"}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>

                                    <BranchPage/>

                                </div>

                            </div>

                        )

                    }}

                />
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/branch/add'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"branches"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <AddBranchPage/>
                                </div>

                            </div>

                        )

                    }}

                />
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/branch/edit/:id'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"branches"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <AddBranchPage/>
                                </div>

                            </div>

                        )

                    }}

                />
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/employee'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"employees"}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>

                                    <EmployeePage/>

                                </div>

                            </div>

                        )

                    }}

                />
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/employee/add'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"employees"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>

                                    <AddEmployeePage/>

                                </div>

                            </div>

                        )

                    }}

                />
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/employee/edit/:id'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"employees"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>

                                    <AddEmployeePage/>

                                </div>

                            </div>

                        )

                    }}

                />
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/user-and-roles'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"userAndRoles"}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>

                                    <UserAndRolePage/>

                                </div>

                            </div>

                        )

                    }}

                />
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/user-and-roles/add'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"userAndRoles"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>

                                    <AddUserAndRolePage/>

                                </div>

                            </div>

                        )

                    }}

                />

            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/user-and-roles/edit/:id'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}
            >

                <AccessFilter
                    section={"userAndRoles"}
                    privilege={"update"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <AddUserAndRolePage/>

                                </div>

                            </div>

                        )

                    }}

                />
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/banks'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}
            >

                <AccessFilter
                    section={"bank"}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <BankPage/>

                                </div>

                            </div>

                        )

                    }}

                />

            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/banks/add'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"bank"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <AddBanksPage/>
                                </div>

                            </div>

                        )

                    }}

                />

            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/banks/edit/:id'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"bank"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <AddBanksPage/>
                                </div>

                            </div>

                        )

                    }}

                />

            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/vendors'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}
            >
                <AccessFilter
                    section={"vendors"}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <VendorsSetup/>
                                </div>

                            </div>

                        )

                    }}

                />
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/vendors/add'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"vendors"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <AddVendorPage/>
                                </div>

                            </div>

                        )

                    }}

                />

            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/company-setup/vendor/edit/:id'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AccessFilter
                    section={"vendors"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <AddVendorPage/>
                                </div>

                            </div>

                        )

                    }}

                />

            </motion.div>
        </div>
    </Route>,

]