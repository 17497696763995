import {createMuiTheme, makeStyles, Popper, TextField, ThemeProvider} from '@material-ui/core';
import {Autocomplete as MAutoComplete, AutocompleteProps} from '@material-ui/lab';
import React from 'react';
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import clsx from "clsx";

// @ts-ignore
const theme = createMuiTheme({
    overrides: {
        MuiInput: {
            root: {
                userSelect: 'none',
                boxShadow: '1px 1px 3px 2px #e8e8e8',
                padding: '5px 10px 5px 10px',
                width: 500,
                border: "none",
            },
            underline: {}
        },
        MuiListItem: {
            button: {
                color: COLOR_PROFILE.primaryColor,
                fontWeight: 600,
                '&[data-focus="true"]': {
                    backgroundColor: 'red',
                },
            },
        }

    }
});

const useStyles = makeStyles(theme => ({
    inputFieldProps: {
        fontSize: 14,
        // fontFamily: globalFontFamily,
        userSelect: 'none',
        fontWeight: 600,
        color: COLOR_PROFILE.primaryColor,
    },
    endAdornment: {
        marginRight: 10,
        fontSize: 16,
        marginBottom: 10
    },
    autocompleteOption: {
        fontWeight: 600,
        fontSize: 14,
        // fontFamily: globalFontFamily,
        color: COLOR_PROFILE.primaryColor,
    },
    popper: {
        width: "fit-content"
    },
    noBorder: {
        border: "none",
    },
    customTextField: {
        "& input::placeholder": {
            fontSize: "12px"
        }
    },
    option: {
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    groupLabel: {
        color: COLOR_PROFILE.buttonBlue,
        fontWeight: 600,
        borderLeft: '2px solid gray'
    }
}));

const AutoCompleteInput: React.FC<AutocompleteProps<any, any, any, any>> = (Props) => {

    // @ts-ignore
    const {padding, error, hideOutline} = Props;
    const style = useStyles();

    // @ts-ignore
    return (
        <>
            <ThemeProvider theme={theme}>
                <MAutoComplete
                    id={Props.id}
                    groupBy={Props.groupBy}
                    PopperComponent={Props.PopperComponent}
                    onKeyUp={Props.onKeyUp}
                    options={Props.options}
                    onChange={Props.onChange}
                    freeSolo={Props.freeSolo}
                    renderOption={Props.renderOption}
                    ListboxProps={{
                        style: {
                            fontSize: 14,
                            textAlign: 'left',
                            marginLeft: -10,
                            fontWeight: 600,
                            color: COLOR_PROFILE.primaryColor
                        }
                    }}
                    classes={{
                        groupLabel: style.groupLabel,
                        // option: style.option
                    }}
                    filterOptions={Props.filterOptions}
                    onFocus={Props.onFocus}
                    placeholder={Props.placeholder}
                    onOpen={Props.onOpen}
                    autoHighlight={Props.autoHighlight}
                    autoSelect={Props.freeSolo}
                    open={Props.open}
                    disabled={Props.disabled}
                    onClose={Props.onClose}
                    autoComplete={Props.autoComplete}
                    value={Props.value}
                    onBlur={Props.onBlur}
                    loading={Props.loading}
                    size={Props.size}

                    disableClearable={Props.disableClearable}
                    getOptionLabel={Props.getOptionLabel}
                    forcePopupIcon={Props.forcePopupIcon}
                    renderInput={(params) => {
                        // @ts-ignore
                        return <TextField {...params}
                                        // name={Props.id}
                                          variant={"outlined"}
                                          style={{borderRadius: 0}}
                                          autoComplete={'off'}
                                          id={Props.id}
                                          fullWidth={true}
                                          disabled={Props.disabled}
                                          placeholder={Props.placeholder}
                                          error={error}
                                          InputProps={{
                                              ...params.InputProps,
                                              disableUnderline: true,
                                              classes: {
                                                  notchedOutline: clsx(hideOutline && style.noBorder),
                                                  root: style.customTextField
                                              },
                                              style: {
                                                  paddingLeft: padding >= 0 ? padding : null,
                                                  paddingRight: padding >= 0 ? padding : null,
                                                  marginTop: hideOutline ? -5 : undefined,
                                                  paddingBottom: hideOutline ? 0 : undefined,
                                                  borderRadius: 0,
                                                  // backgroundColor: '#fafafa',
                                                  fontSize: 14,
                                                  fontWeight: 'bold'
                                              }
                                          }}
                        />
                    }

                    }
                />
            </ThemeProvider>

        </>
    );


};

export default AutoCompleteInput;
