const initialState = {
    fetching: false,
}

const formState = (state: any = initialState, action: any) => {
    switch (action.type) {
        case 'SET_DATA_FETCHING':
            state = {...state,fetching: action.payload};
            return state;

        default:
            return state
    }
}

export default formState;

