import React, {useCallback, useEffect, useMemo} from "react";
import {Field, useFormikContext} from "formik";
import useSWR from "swr";
import {Grid, Popper, useMediaQuery} from "@material-ui/core";
import AutoCompleteInput from "../../../../../shared/atom/formElements/autocompleteInput";
import {matchSorter} from "match-sorter";
import ToolTip from "../../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import {COLOR_PROFILE} from "../../../../../../config/colorCode";
import InputField from "../../../../../shared/atom/formElements/inputField";
import {Column} from "react-table";
import VoucherTableForm from "../../../../../accounting/organisms/Forms/voucherTableForm";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {ACCOUNT_CODE_MAPPER} from "../../../../../../utils/accountCodeMapper";
const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    },
    labelValue: {
        fontWeight: "bold",
        fontSize: 14,
        paddingLeft: 10, paddingRight: 10,
        color: COLOR_PROFILE.primaryColor
    }
}))

const EstimateAdditionalForm: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance, mode}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRowAdditional, tableRowNonTaxable,  tableRow, amountPaid, tds, paymentStatus}} = useFormikContext();
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');
    const {data: productOptions} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectIncomeAccount}`);
    const {data: productOptions1} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.indirectExpenseOperatingExpense}`);

    const name = 'tableRowAdditional'
    const formik = useFormikContext();
    const isView = mode === 'view';
    const style=useStyles();

    useEffect(() => {
        if(paymentStatus === 'Full'){
            formik.setFieldValue('amountPaid', (tableRowSum() + tableRowNonTaxableSum() + tableRowAdditionalSum() - tdsSum()))
        }
    }, [paymentStatus,tableRowAdditional, tableRow])

    const tableRowAdditionalSum = useCallback(() => {
        return tableRowAdditional.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRowAdditional])

    const tableRowNonTaxableSum = useCallback(() => {
        return tableRowNonTaxable?.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00) || 0
    }, [tableRowNonTaxable])

    const tableRowSum = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])

    const tdsSum = useCallback(() => {
        return tds.filter((item: any) =>  item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tds])



    // let sum = values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
    //     return (result + parseFloat(item.amount))
    // }, 0.00)

    const getCredit = useCallback(() => {
        return tableRowAdditional.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRowAdditional])


    const data = useMemo(() => tableRowAdditional, [tableRowAdditional, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.down('xs'));
        return (<Popper {...props} style={{width: matches ? 250:500}} placement='bottom-start'/>)
    }


    // {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},

    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        {
            id: 'productItem',
            width: isView ? undefined:400,
            Header: 'Account',
            accessor: "productItem",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].product`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {
                                    let tempRows = form.values.tableRowAdditional;

                                    //todo do this section again
                                    let selectedProduct = tempRows.filter((item: any) => item.product).map((item: any) => item.product?.id);
                                    if (field.value?.id) selectedProduct.splice(selectedProduct.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedProduct.includes(item.id)) : [];

                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>
                                                {isView ? (
                                                    <div className={style.labelValue}>
                                                        {field?.value?.headname || ''}
                                                    </div>
                                                ):(

                                                <AutoCompleteInput type={'text'}
                                                                   id={`${name}[${index}].product`} {...field}
                                                                   size={"small"}
                                                                   placeholder={'Select Head'}
                                                                   filterOptions={(options, {inputValue}) => {
                                                                       let filteredOptions = getOptions(options)
                                                                       //todo do this again
                                                                       return matchSorter(filteredOptions, inputValue, {keys: ['name', 'code']});
                                                                   }}
                                                                   onChange={(e, value) => {
                                                                       form.setFieldValue(`${name}[${index}].product`, value)
                                                                       if(value){
                                                                           form.setFieldValue(`${name}[${index}].price`, value ? value.selling_price : 0)
                                                                           form.setFieldValue(`${name}[${index}].amount`, value.selling_price * (1 + original.tax / 100))
                                                                       }else{
                                                                           form.setFieldValue(`${name}[${index}].price`,  0)
                                                                           form.setFieldValue(`${name}[${index}].amount`, 0)
                                                                       }

                                                                   }}

                                                                   hideOutline={true}
                                                                   autoHighlight={true}
                                                                   autoSelect={true}
                                                                   options={productOptions ? productOptions.data.map((item: any) => {
                                                                       return {...item, subtypename: 'Indirect Income', isExpense: true, headname: item.name, accgroupid: item.accountGroupDto.id, subtypeid: item.accountSubTypeId
                                                                       }
                                                                   }) : []}
                                                                   loading={!productOptions}
                                                                   variant={'standard'}
                                                                   InputProps={{
                                                                       disableUnderline: true,
                                                                       style: {paddingLeft: 5, paddingRight: 5}
                                                                   }}
                                                                   groupBy={options => options.subtypename}
                                                                   PopperComponent={PopperMy}
                                                                   getOptionLabel={option => option.headname}
                                                                   renderOption={(option) => {
                                                                       return (
                                                                           <div>
                                                                               <div style={{
                                                                                   fontSize: 11,
                                                                               }}>
                                                                                   <>{option.code}</>
                                                                               </div>
                                                                               <div>{option.headname}</div>
                                                                           </div>
                                                                       )
                                                                   }}
                                                                   error={meta.touched && meta.error}
                                                />
                                                    )}
                                            </Grid>
                                            {!isView && (
                                            <ToolTip title={'Add new head'}>
                                                <Grid item style={{
                                                    borderLeft: '1px solid #d0d0d0',
                                                    padding: '8px 3px 3px 3px',
                                                    cursor: 'pointer',

                                                }}
                                                      onClick={() => handleDialogOpen()}
                                                >

                                                    <div>

                                                        <AddIcon style={{
                                                            color: COLOR_PROFILE.primaryColor,
                                                            fontWeight: 700,
                                                            fontSize: 20
                                                        }}/>

                                                    </div>
                                                </Grid>
                                            </ToolTip>
                                            )}
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },


        {
            id: 'quantity',
            Header: 'Quantity',
            accessor: "quantity",
            width: 30,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].quantity`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(

                                        <InputField type={'number'} id={`${name}[${index}].quantity`} {...field}
                                                    size={"small"}
                                                    step="1"
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].quantity`, event.target.value)
                                                        let discountedAmount = event.target.value * original.price - (original.discount)/100 * event.target.value * original.price;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
                                                    }}

                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />
                                    )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'price',
            Header: 'Price',
            accessor: "price",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].price`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].price`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].price`, event.target.value)
                                                        let discountedAmount = original.quantity * event.target.value - (original.discount)/100 * original.quantity * event.target.value;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
                                                    }}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'discount',
            Header: 'Discount (%)',
            width: 30,
            accessor: "discount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].discount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].discount`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].discount`, event.target.value)
                                                        let discountedAmount = original.quantity * original.price - (event.target.value)/100 * original.quantity * original.price;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'tax',
            Header: 'VAT (%)',
            width: 60,
            accessor: "tax",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].tax`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].tax`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        console.log(event.target.value)
                                                        form.setFieldValue(`${name}[${index}].tax`, event.target.value)
                                                        let discountedAmount = original.quantity * original.price - (original.discount)/100 * original.quantity * original.price;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + event.target?.value/100 * discountedAmount)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'amount',
            Header: 'Amount',
            accessor: "amount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].amount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].amount`} {...field}
                                                    size={"small"}
                                                    value={original.amount}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                //console.log(cellObj)

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        handleRemove(index, formikInstance, name)
    }

    const getBalance = () => {

        return '';
        // return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }

    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, accountHeadOptions]);


    return (
        <div>

            <div style={{paddingBottom: 40}}>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} sectionName={name} balance={getBalance}
                                  total={mode === 'view' ? {
                                      'totalAmount': tableRowSum() + tableRowAdditionalSum(),
                                      'paidAmount': parseFloat(amountPaid || 0),
                                      'remainingAmount': tableRowSum() + tableRowAdditionalSum() - parseFloat(amountPaid || 0)
                                  }: null}
                                  type={mode  === 'view' ? 'estimateAdditional': null}
                                  minLength={1}
                                  mode={mode}
                                  fromProduct={true}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )
};

export default EstimateAdditionalForm
