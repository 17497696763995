import React from 'react';

interface Props {
    color: string,
    children ?: any
}

const CardComponent: React.FC<Props> = (Props) => {
    return (
        <div style={{backgroundColor: Props.color}}>
            {Props.children}
        </div>
    );
};

CardComponent.defaultProps = {
    color: 'gray'
}

export default CardComponent;
