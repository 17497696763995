import React from 'react';
import InventoryAdjustmentHome from "../../templates/inventoryAdjustmentHome";

const InventoryAdjustment = () => {
    return (
        <div>
            <InventoryAdjustmentHome/>
        </div>
    );
};

export default InventoryAdjustment;