import React from 'react';
import ProductItemHome from "../../templates/productItemHome";
import ProductOfferHome from "../../templates/productOfferHome";

const ProductOffer: React.FC<any> = (Props) => {
    return (
        <div>
            <ProductOfferHome/>
        </div>
    );
};

export default ProductOffer;