import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from "yup";
import axiosInstance from "../../../../../config/axiosConfig";
import {useHistory} from "react-router-dom";
import useSWR, {trigger} from "swr";
import {useDispatch} from "react-redux";
import {Field, Form, Formik} from "formik";
import {Grid} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import InputField from "../../../../shared/atom/formElements/inputField";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Button from "../../../../shared/atom/button";

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const TermsAndConditionForm: React.FC<any> = (Props) => {

    const initialValue = {
        termAndConditions: [
            {
                id: 1,
                value: ''
            }
        ]
    }

    const {data: CompanyInfo} = useSWR('/getCompanyInformation');

    useEffect(() => {
        if(!CompanyInfo) return;

        console.log(JSON.parse(CompanyInfo.t.termsAndConditions))

        setFormState({
            termAndConditions : CompanyInfo.t.termsAndConditions ? JSON.parse(CompanyInfo.t.termsAndConditions) : []
        })

    }, [CompanyInfo])

    const styles = useStyles();

    const [isEdit, setIsEdit] = useState(false);

    const [formState, setFormState] = useState(initialValue);

    const validationSchema = Yup.object({
        // name: Yup.string().required('Required'),
    })

    const onSubmit = async (values: any, formActions: any) => {

        console.log(values)
        //
        let isReset = values.action === 'submitAndAdd';

        //
        try {
            
            const {data: CompanyInfo} = await axiosInstance.get('/getCompanyInformation');

            const {data} = await axiosInstance.put('/updateCompanyInformation', {
                ...CompanyInfo?.t,
                termsAndConditions: JSON.stringify(values.termAndConditions)
            });
            responseProcess(data, formActions, isReset)

        } catch (e) {
            console.log(e)
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')

        }


    }

    const history = useHistory();
    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {

            trigger('/getCompanyInformation')
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if (!isReset) handleClose();

        } else {

            handleResponse(data.message, 'warning');

        }
    }


    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }


    const addNew = (formikInstance: any) => {

        const {values: {termAndConditions}} = formikInstance;
        //console.log(tableRow);
        let tempArr = {
            id: termAndConditions[termAndConditions.length - 1].id + 1,
            value: ''
        }
        termAndConditions.push(tempArr)
        formikInstance.setFieldValue('termAndConditions', [...termAndConditions])

    }

    const handleRemove = (index: number, formikInstance: any) => {
        const {values: {termAndConditions}} = formikInstance;
        let tempTable = [...termAndConditions].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue('termAndConditions', [...tempTable])
    }

    const handleClose = () => {
        if(CompanyInfo) {
            setFormState({
                termAndConditions : CompanyInfo.t.termsAndConditions ? JSON.parse(CompanyInfo.t.termsAndConditions) : []
            })
        }

        setIsEdit(false)

    }

    return (
        <div>
            <Formik
                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {


                    return (
                        <div>
                            <Form style={{margin: 20, paddingBottom: 40}}>
                                <Grid container justify={"space-between"}>

                                    <Grid container item sm={10} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={12}>
                                            <label htmlFor="name"
                                                   className={styles.label} style={{fontSize: 16}}> Terms &
                                                Conditions:</label>
                                        </Grid>

                                        <Grid item xs={8}>


                                            {isEdit ? (
                                                formik?.values?.termAndConditions.map((item: any, index: number) => {
                                                    return (

                                                        <div style={{margin: '15px 0'}}>

                                                            <div style={{position: 'relative', paddingLeft: 20}}>
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    fontSize: 14,
                                                                    fontWeight: 700
                                                                }}>
                                                                    {index + 1}.
                                                                </div>
                                                                <Field name={`termAndConditions[${index}].value`}>
                                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                                        const {field, form, meta} = props;
                                                                        return (
                                                                            <>
                                                                                <InputField type={'text'}
                                                                                            id={`termAndConditions[${index}].value`} {...field}
                                                                                            size={"small"}
                                                                                            multiline={true}
                                                                                            rows={3}
                                                                                            error={meta.touched && meta.error}
                                                                                />
                                                                                <ErrorLabel meta={meta}/>
                                                                            </>
                                                                        )
                                                                    }}
                                                                </Field>

                                                                {formik.values.termAndConditions.length > 1 && (
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: 7,
                                                                        right: -30
                                                                    }}>
                                                                        <DeleteForeverIcon fontSize={"small"}
                                                                                           style={{
                                                                                               color: 'red',
                                                                                               cursor: 'pointer',
                                                                                           }}
                                                                                           onClick={() => handleRemove(index, formik)}

                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                        </div>

                                                    )
                                                })
                                            ) : (

                                                <div>
                                                    {formik.values.termAndConditions.filter((item: any) => item.value).map((item: any, index: number) => {
                                                        return (
                                                            <div>
                                                                {index + 1}. {item.value}
                                                            </div>
                                                        )
                                                    })}

                                                    <div>
                                                        {formik.values.termAndConditions.filter((item: any) => item.value).length === 0 && (
                                                            <div>
                                                                Terms and conditions not added yet. Click Below to set
                                                                you terms and conditions.
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            )}


                                        </Grid>


                                    </Grid>
                                </Grid>
                                <div>
                                    {isEdit && (
                                        <div style={{maxWidth: 140}}>

                                            <Button variant={"text"} customColor={COLOR_PROFILE.buttonBlue}
                                                    textColor={COLOR_PROFILE.primaryColor}
                                                    disabled={Props.submitting}
                                                    startIcon={<AddIcon/>}
                                                    onClick={() => addNew(formik)}
                                                // type={'submit'}
                                            >
                                                <span style={{fontSize: 15}}>
                                                    Add another
                                                </span>
                                            </Button>
                                        </div>
                                    )}
                                </div>

                                {!isEdit && (
                                    <div style={{maxWidth: 250}}>

                                        <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                textColor={'white'}
                                                disabled={Props.submitting}
                                                onClick={() => setIsEdit(true)}
                                            // type={'submit'}
                                        >
                                                <span style={{fontSize: 15}}>
                                                    Edit/ Add Terms & Conditions
                                                </span>
                                        </Button>
                                    </div>
                                )}

                                {isEdit && (
                                    <FormSaveButton noFixed={true} mode={'edit'} from={Props.from}
                                                    submitting={formik.isSubmitting} handleClose={handleClose}/>
                                )}
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    );
};

export default TermsAndConditionForm;