import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import * as Yup from "yup";
import useSWR, {trigger} from "swr";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Grid, Popper, useMediaQuery} from "@material-ui/core";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import InputField from "../../../../shared/atom/formElements/inputField";
import DateComponent from "../../../../product/molecules/dateComponent";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import {matchSorter} from "match-sorter";
import ToolTip from "../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {Column} from "react-table";
import VoucherTableForm from "../../../../accounting/organisms/Forms/voucherTableForm";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {getInvoiceAmounts} from "../../../../../utils/invoiceAmounts";
import {getTaxAmount} from "../../../../../utils/getTax";
import TdsForm from "../../../../sales/organisms/forms/tdsForm";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs} from "../../../../../config/dateFunctions";
import ProductItemForm from "../../../../product/organisms/form/productItem";
import DialogComponent from "../../../../shared/atom/dialog";
import AccountHeadForm from "../../../../accounting/organisms/Forms/accountHeadForm";
import {useReactToPrint} from "react-to-print";
import PurchaseOrderPrintView from "../purchaseOrder/purchaseOrderPrintView";
import ReportActionButton from "../../../../accounting/organisms/reportActionButton";
import ErrorDialog from "../../../../shared/organisms/errorDialog";
import ExpenseEntryPrintView from "./expenseEntryPrintView";
import {ACCOUNT_CODE_MAPPER} from "../../../../../utils/accountCodeMapper";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {
    from: string
    mode: 'edit' | 'create' | 'view',
    nextPath?: any,
    id ?: string | null
}

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    },
    labelValue: {
        fontWeight: "bold",
        fontSize: 14,
        paddingLeft: 10, paddingRight: 10,
        color: COLOR_PROFILE.primaryColor
    }
}))

const PopperMy = function (props: any) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (<Popper {...props} style={{width: matches ? 250:500}} placement='bottom-start'/>)
}

const ExpenseEntryForm: React.FC<Props> = (Props) => {
    const initialState = {
        transactionYearAd: null,
        transactionMonthAd: null,
        transactionDateAd: null,
        transactionYearBs: null,
        transactionMonthBs: null,
        transactionDateBs: null,

        dueYearAd: null,
        dueMonthAd: null,
        dueDateAd: null,
        dueYearBs: null,
        dueMonthBs: null,
        dueDateBs: null,

        dueAmount: 0,
        code: '',
        vendor: null,
        paymentStatus: 'Full',
        paymentMode: null,
        referenceBillNumber: '',

        amountPaid: 0,
        purchaseOrder: null,
        document: '',
        tableRow: [
            {
                id: 1,
                product: null,
                accountHead: null,
                taxableAmount: 0,
                tax: 0,
                description: '',
                amount: 0
            },
            {
                id: 2,
                product: null,
                accountHead: null,
                taxableAmount: 0,
                tax: 0,
                description: '',
                amount: 0
            },
        ],

        tds: [
            {id: 1, item: null, product: null, taxableAmount: 0, tax: 0, amount: 0},
            {id: 2, item: null, product: null, taxableAmount: 0, tax: 0, amount: 0},
        ],

    }

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();

    const {data: expenseEntryCode} = useSWR('/getCode?name=expenseEntry&id=0')
    const {data: vendorsData} = useSWR('/getVendors');
    const {data: paymentModeOptions} = useSWR('/getHeadsForPayment');
    const {data: purchaseOrderOptions} = useSWR('/getPurchaseOrders?vendorId=0')
    const {data: headOptionsInCategory} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.inventoryAccount},${ACCOUNT_CODE_MAPPER.indirectExpenseAdministrativeExpense},${ACCOUNT_CODE_MAPPER.currentLiabilites},${ACCOUNT_CODE_MAPPER.currentAssetAdvanceTax}`);
    const {data: productOptions} = useSWR('/getProducts');
    const {data: indirectExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectExpenseAccount}`);
    const {data: directExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.directExpenseAccount}`);
    const {data: tdsOptions} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.indirectExpenseTDS}`);

    const location = useLocation();
    useEffect(() => {

        dispatch({type: 'SET_DATA_FETCHING', payload: true});

        if (!formatedNepEngDates) return;
        if(!paymentModeOptions) return;
        if(!vendorsData) return;
        if(!productOptions) return;
        if(!indirectExpenseAccounts) return;
        if(!directExpenseAccounts) return;
        if(!tdsOptions) return;
        dispatch({type: 'SET_DATA_FETCHING', payload: false});

        if (Props.id) {

            // @ts-ignore
            const {vendorDto, expenseEntryNumber,referenceBillNumber,paymentStatus,paymentMethod,paymentAmount,paymentDueDate,purchaseDate,productSubs, journalVoucherDto} = location.state
            let adTransactionDate = purchaseDate.split('-');
            let bsTransactionDate: any = convertAdToBs(purchaseDate, formatedNepEngDates) || [];
            let adDueDate = paymentDueDate ? paymentDueDate.split('-'): [];
            let bsDueDate: any = convertAdToBs(paymentDueDate || [], formatedNepEngDates) || [];


            // @ts-ignore
            let tempJson = {
                transactionYearAd: adTransactionDate[0],
                transactionDateAd: adTransactionDate[2],
                transactionMonthAd: adTransactionDate[1],
                transactionDateBs: bsTransactionDate[2],
                transactionMonthBs: bsTransactionDate[1],
                transactionYearBs: bsTransactionDate[0],
                dueYearAd: adDueDate[0],
                dueMonthAd: adDueDate[1],
                dueDateAd: adDueDate[2],
                dueYearBs: bsDueDate[0],
                dueMonthBs: bsDueDate[1],
                dueDateBs: bsDueDate[2],
            }

            // @ts-ignore
            setFormState({
                ...formState,
                ...tempJson,
                code: expenseEntryNumber,
                referenceBillNumber: referenceBillNumber,
                paymentStatus: paymentStatus,
                amountPaid: paymentAmount,
                paymentMode: paymentMethod ? paymentModeOptions.data.find((item: any) => item.id === paymentMethod.id): null,
                tableRow: [...productSubs.map((item: any) => {
                    return {
                        ...item,
                        product: productOptions.data.find((prodItem: any) => item.productId === prodItem.id),
                        taxableAmount: (item.amount * 100)/ (100 + item.tax),
                        accountHead: [ ...indirectExpenseAccounts.data, ...directExpenseAccounts.data].find((headItem: any) => headItem.id === item.accountHeadId)
                    }
                })],
                // tableRow:  journalVoucherDto && [...journalVoucherDto?.journalRows.filter((item: any) => [...indirectExpenseAccounts.data,...directExpenseAccounts.data].find((tdsItem: any) => tdsItem.id === item.accountHead.id)).map((item: any, index: number) => {
                //     return {
                //         ...item,
                //         product: productOptions.data.find((prodItem: any) => item.productId === prodItem.id),
                //         taxableAmount: (item.amount * 100)/ (100 + item.tax),
                //         accountHead: [ ...indirectExpenseAccounts.data, ...directExpenseAccounts.data].find((headItem: any) => headItem.id === item.accountHeadId)
                //     }
                // })],
                tds: journalVoucherDto && [...journalVoucherDto?.journalRows.filter((item: any) => tdsOptions.data.find((tdsItem: any) => tdsItem.id === item.accountHead.id)).map((item: any, index: number) => {
                    // let account = tdsOptions.data.find((itemHead: any) => itemHead.id === item.accountHead.id);
                    let entries = JSON.parse(item.description)
                    return {
                        id : index+1,
                        product: entries?.product,
                        // description: item.description.split('_').slice(0,-1).join('_'),
                        taxableAmount: entries?.taxableAmount,
                        tax: entries?.tax,
                        amount: entries?.amount
                    }
                })],
                vendor: vendorDto,
            })
        }


    }, [formatedNepEngDates,productOptions,tdsOptions, paymentModeOptions,indirectExpenseAccounts,directExpenseAccounts, vendorsData])



    useEffect(() => {

        if(Props.id) return;

        if (expenseEntryCode) setFormState(prevState => {
            return {
                ...prevState,
                code: expenseEntryCode.t.code
            }
        })

    }, [expenseEntryCode])


    const convert = (transactionDate: any) => {

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            transactionYearAd: adDate[0],
            transactionDateAd: adDate[2],
            transactionMonthAd: adDate[1],
            transactionDateBs: bsDate[2],
            transactionMonthBs: bsDate[1],
            transactionYearBs: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }

    useEffect(() => {
        if(Props.id) return;
        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])
    }, [formatedNepEngDates])

    const [openDialogProduct, setOpenDialogProduct] = useState(false)
    const [openDialogHead, setOpenDialogHead] = useState(false)

    const [formState, setFormState] = useState(initialState);
    const styles = useStyles();
    const validationSchema = Yup.object({
        vendor: Yup.object().when("paymentStatus", {
            is: 'Full',
            then: Yup.object().nullable(true),
            otherwise: Yup.object().required("Required").nullable(true)
        }).nullable(true),
        paymentStatus: Yup.string().required('Required').nullable(true),
        paymentMode: Yup.object().when("paymentStatus", {
            is: 'Unpaid',
            then: Yup.object().nullable(true),
            otherwise: Yup.object().required("Required").nullable(true)
        }).nullable(true),
        amountPaid: Yup.number().when("paymentStatus", {
            is: 'Unpaid',
            then: Yup.number().max(0, "Must be 0"),
            otherwise: Yup.number().min(1, "Must be greater than 0").required("Required")
        })
        // purchaseOrder: Yup.object().required('Required').nullable(true)
    })


    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            if(values.tableRow.filter((item: any) => (item.accountHead)).length === 0){
                handleResponse(`Select at least one product to create a bundle.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRow.filter((item: any) => (item.accountHead) && !(item.taxableAmount &&  item.amount)).length > 0) {
                handleResponse(`Some row doesn't have Account head, Taxable Amount and Amount.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tds.filter((item: any) => item.product && !(item.taxableAmount && item.amount)).length > 0){
                handleResponse(`Selected TDS account has no Price or amount.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            let vendorAccount : any = {}

            if(values.paymentStatus !== 'Full'){
                let {data: tempAcc} = await axiosInstance.get(`/getAccountHeadOfVendorById/${values?.vendor?.id}`)
                vendorAccount = tempAcc;
            }
            let taxAccount = headOptionsInCategory.data.filter((item: any) => item.code === ACCOUNT_CODE_MAPPER.purchaseVatAccountCode)[0];
            let entryJournal: object[] = [];
            let totalTaxableAmount = 0.00, totalTax = 0.00, totalAmount = 0.00;
            let exemptedAmount = 0.00;

            values.tableRow.filter((item: any) => item.amount).map((item: any) => {

                let amount = item.amount;
                let taxAmount = 0;

                totalAmount += item.amount;

                if(item.tax > 0) {
                    taxAmount = getTaxAmount(item.amount, parseFloat(item.tax));
                    totalTax += taxAmount;
                    amount  -= taxAmount;

                    entryJournal.push({
                        accountHead: {id: taxAccount.id},
                        amount: taxAmount,
                        tax: item.tax,
                        accountGroupId: taxAccount.accgroupid,
                        type: 'debit',
                        name: 'vat table row',
                        rate: '',
                        description: item.description,
                        subTypeId: taxAccount.subtypeid
                    })
                } else {
                    exemptedAmount += amount
                }

                totalTaxableAmount += item.taxableAmount;

                entryJournal.push({
                    accountHead: {id: item.accountHead.id},
                    amount: item.taxableAmount,
                    tax: item.tax,
                    accountGroupId: item.accountHead.accountGroupDto.id,
                    type: 'debit',
                    name: 'item row',
                    rate: '',
                    description: item.description,
                    subTypeId: item.accountHead.accountGroupDto.accountSubType.id
                })
            })

            let tdsTaxableAmount = 0.00, tdsTaxAmount = 0.00, tdsTotalAmount = 0.00;

            values.tds.filter((item: any) => item.product && item.amount).map((item: any) => {
                let account =  item.product;
                let amount = item.amount;
                tdsTotalAmount += amount;
                tdsTaxableAmount += parseFloat(item.taxableAmount);
                let taxAmount = 0;
                if(item.tax > 0) {
                    // taxAmount =  getTaxAmount(item.amount, parseFloat(item.tax));
                    // tdsTaxAmount += taxAmount;
                    // entryJournal.push ({
                    //     accountHead: {id: taxAccount.id},
                    //     amount: taxAmount,
                    //     tax: item.tax,
                    //     accountGroupId: taxAccount.accgroupid,
                    //     type: 'debit',
                    //     rate: '',
                    //     description: item.description,
                    //     subTypeId: taxAccount.subtypeid
                    // })
                } else {
                    // exemptedAmount += amount
                }

                entryJournal.push({
                    accountHead: {id: account.id},
                    amount: parseFloat(item.amount),
                    tax: item.tax,
                    type: 'credit',
                    name: 'tds',
                    accountGroupId: account.accgroupid,
                    rate: '',
                    description: JSON.stringify(item),
                    subTypeId: account.subtypeid
                })
            })

            let sumProduct = values.tableRow.filter((item: any) => item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            let sumTds = values.tds.filter((item: any) => item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)


            let vendorRow: any = [];
            let bankRow: any = [];

            if(values.paymentStatus === 'Full' ){
                bankRow = [{
                    accountHead: {id: values.paymentMode.id},
                    amount: sumProduct - sumTds,
                    tax: '',
                    type: 'credit',
                    name: 'bank',
                    accountGroupId: values.paymentMode.accgroupid,
                    rate: '',
                    description: values.chequeNumber,
                    subTypeId: values.paymentMode.subtypeid
                }]
            }

            if (values.paymentStatus === 'Semi'){
                bankRow = [{
                    accountHead: {id: values.paymentMode.id},
                    amount: parseFloat(values.amountPaid),
                    tax: '',
                    type: 'credit',
                    name: 'bank',
                    accountGroupId: values.paymentMode.accgroupid,
                    rate: '',
                    description: values.chequeNumber,
                    subTypeId: values.paymentMode.subtypeid
                }]
            }


            if(values.paymentStatus === 'Semi' || values.paymentStatus === 'Unpaid'){
                vendorRow = [{
                    accountHead: {id: vendorAccount.t.id},
                    amount: sumProduct - sumTds - parseFloat(values.amountPaid || 0),
                    name:'vendor',
                    tax: '',
                    type: 'credit',
                    accountGroupId: vendorAccount.t.accgroupid,
                    rate: '',
                    description: '',
                    subTypeId: vendorAccount.t.subtypeid
                }]
            }

            let bankAccount = values.paymentStatus === 'Semi' ? [...vendorRow, ...bankRow] : values.paymentStatus === 'Full' ? [...bankRow] : [...vendorRow];

            let voucherEditInfo = {};
            if(Props.mode === 'edit'){
                //@ts-ignore
                let {voucherNumber, id} = location.state.journalVoucherDto
                voucherEditInfo = {
                    voucherNumber: voucherNumber,
                    id: id
                }
            }

            let payload = {
                purchaseDate: values.transactionYearAd + '-' + ('0' + values.transactionMonthAd).slice(-2) + '-' + ('0' + values.transactionDateAd).slice(-2),
                paymentDueDate: (values.dueYearAd && values.dueMonthAd && values.dueDateAd) ? values.dueYearAd + '-' + ('0' + values.dueMonthAd).slice(-2) + '-' + ('0' + values.dueDateAd).slice(-2): '',
                paymentStatus: values.paymentStatus,
                paymentMethod: values.paymentMode ? {id: values.paymentMode.id} : null,
                vendorDto: values.vendor ? {id: values.vendor.id}: null,
                expenseEntryNumber: values.code,
                amount: 0.00,
                discount: 0.00,
                taxableAmount: totalTaxableAmount + tdsTaxableAmount,
                taxAmount: totalTax + tdsTaxAmount,
                totalAmount: parseFloat(String(totalAmount)) + parseFloat(String(tdsTotalAmount)),
                exemptedAmount: exemptedAmount,
                paymentAmount: values.amountPaid,
                referenceBillNumber: values.referenceBillNumber,
                description: values.description,
                productSubs: values.tableRow.filter((item: any) =>  item.accountHead && item.amount).map((item: any, index: number) => {

                    let editId= {};

                    //add id of each index of productSubs to each product sub in the new list of product that need to be sent to the server
                    if(Props.mode === 'edit'){
                        // @ts-ignore
                        if(location.state.productSubs.length >= index + 1 ){
                            // @ts-ignore
                            editId = {id: location.state.productSubs[index].id}
                        }
                    }

                    return {
                        ...editId,
                        productId: item.product?.id || null,
                        accountHeadId: item.accountHead.id,
                        name: item.product?.name || "",
                        amount: item.amount,
                        tax: parseFloat(item.tax)
                    }
                }),

                journalVoucherDto: {
                    ...voucherEditInfo,
                    type: 'journal',
                    receiptNumber: '',
                    journalRows: [
                        ...entryJournal,
                        ...bankAccount
                    ]
                }
            }

            if(Props.id) {

                const {data} = await axiosInstance.put('/updateExpenseBill', {...payload,id: parseInt(Props.id as string)});
                responseProcess(data, formActions, isReset)

            }else{
                const {data} = await axiosInstance.post('/addExpenseBill', payload);
                responseProcess(data, formActions, isReset)
            }


        } catch (e) {
            console.log(e)
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }

        formActions.setSubmitting(false)
    }

    const history = useHistory();

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {

            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) history.replace('/dashboard/purchase/expense-entry')
            trigger('/getCode?name=expenseEntry&id=0');
        } else {

            handleResponse(data.message, 'warning')

        }
    }

    const dispatch = useDispatch();
    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }


    const handleRemove = (index: number, formikInstance: any, sectionName: string) => {
        const {values} = formikInstance;

        console.log('sectionnale', sectionName);

        let tempTable = [...values[sectionName]].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue(sectionName, [...tempTable])
    }


    const addNew = useCallback((formikInstance: any, sectionName: string) => {

        const {values} = formikInstance;
        let rows = values[sectionName]
        let tempArr = {};

        if(sectionName === 'tableRow'){
            tempArr = {
                id: rows[rows.length - 1].id + 1,
                product: null,
                accountHead: null,
                taxableAmount: 0,
                tax: 0,
                description: '',
                amount: 0
            }
        }else if(sectionName === 'tds'){
            tempArr = {
                id: rows.length > 1 ? rows[rows.length - 1].id + 1:1,
                product: null, taxableAmount: 0, tax: 0, amount: 0
            }
        }

        values[sectionName].push(tempArr)
        formikInstance.setFieldValue(sectionName, [...values[sectionName]])

    }, [formState])

    const componentRef = useRef(null);
    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@page { size: auto; margin: 00mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 130px 40px 100px 40px !important;  } }'
    });

    const printClicked = async () => {

        if (handlePrint) {
            handlePrint()
        }

    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>

            <DialogComponent open={openDialogProduct} handleClose={openDialogProduct} title={'Add Product'}
                             maxWidth={'md'}>
                <ProductItemForm mode={'edit'} from={'journal page'} handleClose={() => setOpenDialogProduct(false)}
                                 noFixed={true}
                />
            </DialogComponent>

            <DialogComponent open={openDialogHead} handleClose={openDialogHead} title={'Add Account Head'}
                             maxWidth={'sm'}>
                <AccountHeadForm mode={'edit'} from={'journal page'} handleClose={() => setOpenDialogHead(false)}
                                 noFixed={true}
                />
            </DialogComponent>


            <Formik
                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {
                    return (
                        <div>
                            <PagePrompt formik={formik}/>
                            <Form style={{margin: 0}} onKeyDown={onKeyDown}>

                                <div ref={componentRef}>

                                <Grid container justify={"space-between"}>
                                    {Props.mode === 'view' ?
                                        (
                                            formik.values.tableRow.length > 0 &&
                                            <ExpenseEntryPrintView formik={formik}/>
                                        )
                                        :
                                        (
                                            <>

                                            <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="code"
                                                   className={styles.label}> Expense Entry No.:</label>
                                        </Grid>

                                        <Grid item xs={8} className={styles.label}>
                                            {formik.values.code}
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="vendor"
                                                   className={styles.label}> Vendor: {formik.values.paymentStatus !== 'Full' && <RequiredNotation/>}</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'vendor'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`vendor`} {...field}
                                                                                   size={"small"}

                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`vendor`, value)
                                                                                   }}

                                                                                   options={vendorsData ? vendorsData.data : []}
                                                                                   loading={!vendorsData}

                                                                                   getOptionLabel={option => option.name}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="referenceBillNumber" className={styles.label}> Reference
                                                Bill No.:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'referenceBillNumber'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'referenceBillNumber'} {...field}
                                                                                size={"small"}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="fromDate"
                                                   className={styles.label}
                                            >
                                                Bill Date:<RequiredNotation/>
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <DateComponent
                                                ad={['transactionYearAd', 'transactionMonthAd', 'transactionDateAd']}
                                                bs={['transactionYearBs', 'transactionMonthBs', 'transactionDateBs']}
                                            />

                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="paymentStatus"
                                                   className={styles.label}> Payment Status:<RequiredNotation/></label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'paymentStatus'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`paymentStatus`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`paymentStatus`, value)
                                                                                   }}

                                                                                   options={['Full', 'Semi', 'Unpaid']}
                                                                                   getOptionLabel={option => option}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="paymentMode"
                                                   className={styles.label}> Payment Mode:{formik.values.paymentStatus !== 'Unpaid' && <RequiredNotation/>}
                                            </label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'paymentMode'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`paymentMode`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`paymentMode`, value)
                                                                                   }}

                                                                                   options={paymentModeOptions ? paymentModeOptions.data : []}
                                                                                   loading={!paymentModeOptions}

                                                                                   groupBy={option => option.subtypename}
                                                                                   PopperComponent={PopperMy}
                                                                                   getOptionLabel={option => option.headname}
                                                                                   renderOption={(option) => {
                                                                                       return (
                                                                                           <div>
                                                                                               <div style={{
                                                                                                   fontSize: 11,
                                                                                               }}>
                                                                                                   <>{option.code}</>
                                                                                               </div>
                                                                                               <div>{option.headname}</div>
                                                                                           </div>
                                                                                       )
                                                                                   }}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="chequeNumber" className={styles.label}> Cheque
                                                Number:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'chequeNumber'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'chequeNumber'} {...field}
                                                                                size={"small"}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="amountPaid" className={styles.label}>
                                                Amount Paid:
                                                {formik.values.paymentStatus !== 'Unpaid' && <RequiredNotation/>}
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'amountPaid'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'number'}
                                                                                id={'amountPaid'} {...field}
                                                                                size={"small"}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="dueTime"
                                                   className={styles.label}
                                            >
                                                Due Date:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>
                                            <DateComponent
                                                ad={['dueYearAd', 'dueMonthAd', 'dueDateAd']}
                                                bs={['dueYearBs', 'dueMonthBs', 'dueDateBs']}
                                            />
                                        </Grid>
                                    </Grid>


                                            </>
                                        )}
                                </Grid>

                                <div>

                                    <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>
                                        <u>Expenses</u>
                                    </div>

                                    <TableForm handleRemove={handleRemove} addNew={addNew} setOpenDialogHead={setOpenDialogHead} setOpenDialogProduct={setOpenDialogProduct}
                                               mode={Props.mode}
                                               formikInstance={formik}/>
                                </div>

                                {!(Props.mode === 'view' && formik.values.tds.length === 0) && (
                                    <div>

                                        <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>
                                            <u>TDS</u>
                                        </div>
                                        <TdsForm handleRemove={handleRemove} addNew={addNew}
                                                 setOpenDialog={setOpenDialogHead}
                                                 mode={Props.mode}
                                                 formikInstance={formik}/>
                                    </div>
                                )}

                                <div>
                                    <Grid container item xs={12} sm={6} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="description" className={styles.label}> Description:</label>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'description'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;


                                                            return (
                                                                <>
                                                                {Props.mode === 'view' ? (
                                                                    <div className={styles.labelValue} >
                                                                        {field?.value || ''}
                                                                    </div>
                                                                ):(
                                                                    <>
                                                                    <InputField type={'text'}
                                                                                id={'description'} {...field}
                                                                                size={"small"}
                                                                                multiline={true}
                                                                                rows={3}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                        </>
                                                                    )}
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>


                                    </Grid>
                                </div>

                                </div>

                                {Props.mode !== 'view' ?
                                    <div style={{marginTop: 50}}>
                                        <FormSaveButton mode={Props.mode} from={'product-bundle'}
                                                        submitting={formik.isSubmitting}/>
                                    </div>
                                    :
                                    <div>
                                        <ReportActionButton approveData={location.state} path={'Expense Entry'} handlePrint={printClicked} handleDownloadAsPdf={printClicked}/>
                                    </div>
                                }
                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    );
};


const TableForm: React.FC<any> = ({handleRemove, addNew, setOpenDialogHead, setOpenDialogProduct, formikInstance, mode}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRow,tds, amountPaid, paymentStatus}} = useFormikContext();
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');
    const {data: indirectExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectExpenseAccount}`);
    const {data: directExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.directExpenseAccount}`);
    const {data: productOptions} = useSWR('/getProducts');

    const name = 'tableRow'
    const formik = useFormikContext();
    const style= useStyles();
    const [errorDialog, setErrorDialog] = useState(false);
    const isView = mode === 'view';


    const getDebit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit))
        }, 0.00)
    }, [tableRow])





    // let sum = values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
    //     return (result + parseFloat(item.amount))
    // }, 0.00)

    const getCredit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRow])


    const data = useMemo(() => tableRow, [tableRow, formikInstance]);


    const handleDialogOpen = (type: string) => {
        if(type === 'head'){
            setOpenDialogHead(true)
        }else{
            setOpenDialogProduct(true)
        }

    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.down('xs'));
        return (<Popper {...props} style={{width: matches ? 250:500}} placement='bottom-start'/>)
    }

    // {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},

    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },


//         {
//             id: 'productItem',
//             width: isView? undefined: 280,
//             Header: 'Product',
//             accessor: "productItem",
//             Cell: (cellObj: any) => {
//
//                 const index = cellObj.row.index;
//                 const {row: {original}} = cellObj
//
//
//                 return (
//                     <Field name={`${name}[${index}].product`}>
//                         {
//                             (props: { field: any; form: any; meta: any; }) => {
//
//                                 const {field, form, meta} = props;
//
//                                 const getOptions = (options: any) => {
//                                     return options;
//                                 }
//
//                                 // @ts-ignore
//                                 return (
//                                     <>
//
//                                         <Grid container alignItems={"center"} justify={"center"}>
//                                             <Grid item xs>
//                                                 {isView ? (
//                                                     <div className={style.labelValue}>
//                                                         {field?.value?.name || ''}
//                                                     </div>
//                                                 ):(
//                                                 <AutoCompleteInput type={'text'}
//                                                                    id={`${name}[${index}].product`} {...field}
//                                                                    size={"small"}
//                                                                    placeholder={'Select Product'}
//                                                                    filterOptions={(options, {inputValue}) => {
//                                                                        let filteredOptions = getOptions(options)
//                                                                        //todo do this again
//                                                                        return matchSorter(filteredOptions, inputValue, {keys: ['name', 'code']});
//                                                                    }}
//                                                                    onChange={(e, value) => {
//
//                                                                        // form.setFieldValue(`${name}[${index}].product`, value)
// return;
//
//                                                                        if(!value) {
//                                                                            form.setFieldValue(`${name}[${index}].product`, value)
//                                                                            form.setFieldValue(`tds[${index}].item`, value)
//                                                                            return;
//                                                                        }
//                                                                        if(value.expense_account_id){
//
//                                                                            form.setFieldValue(`${name}[${index}].product`, value)
//                                                                            // form.setFieldValue(`tds[${index}].item`, value)
//                                                                            // (indirectExpenseAccounts && directExpenseAccounts) ? [...indirectExpenseAccounts.data,...directExpenseAccounts.data]
//                                                                            console.log(value)
//
//                                                                            if (indirectExpenseAccounts && directExpenseAccounts) {
//                                                                                let tempAccounts = [...indirectExpenseAccounts.data,...directExpenseAccounts.data]
//                                                                                console.log(tempAccounts.find((accountItem: any) => accountItem.id === value.expense_account_id));
//                                                                                form.setFieldValue(`${name}[${index}].accountHead`,
//                                                                                    tempAccounts.find((accountItem: any) => accountItem.id === value.expense_account_id)
//                                                                                )
//                                                                            }
//                                                                        }else{
//
//                                                                            setErrorDialog(true)
//
//                                                                        }
//
//                                                                    }}
//
//                                                                    hideOutline={true}
//                                                                    autoHighlight={true}
//                                                                    autoSelect={true}
//                                                                    options={productOptions ? productOptions.data : []}
//                                                                    loading={!productOptions}
//                                                                    variant={'standard'}
//                                                                    InputProps={{
//                                                                        disableUnderline: true,
//                                                                        style: {paddingLeft: 5, paddingRight: 5}
//                                                                    }}
//                                                                    PopperComponent={PopperMy}
//                                                                    getOptionLabel={option => option.name}
//                                                                    renderOption={(option) => {
//                                                                        return (
//                                                                            <div>
//                                                                                <div style={{
//                                                                                    fontSize: 11,
//                                                                                }}>
//                                                                                    <>{option.code}</>
//                                                                                </div>
//                                                                                <div>{option.name}</div>
//                                                                            </div>
//                                                                        )
//                                                                    }}
//                                                                    error={meta.touched && meta.error}
//                                                 />
//                                                 )}
//                                             </Grid>
//                                             {!isView && (
//                                             <ToolTip title={'Add new Product'}>
//                                                 <Grid item style={{
//                                                     borderLeft: '1px solid #d0d0d0',
//                                                     padding: '8px 3px 3px 3px',
//                                                     cursor: 'pointer',
//
//                                                 }}
//                                                       onClick={() => handleDialogOpen('product')}
//                                                 >
//
//                                                     <div>
//
//                                                         <AddIcon style={{
//                                                             color: COLOR_PROFILE.primaryColor,
//                                                             fontWeight: 700,
//                                                             fontSize: 20
//                                                         }}/>
//
//                                                     </div>
//                                                 </Grid>
//                                             </ToolTip>
//                                             )}
//                                         </Grid>
//                                     </>
//
//                                 )
//                             }
//                         }
//                     </Field>
//                 )
//             }
//         },
        {
            id: 'accountHead',
            width: isView ? undefined: 300,
            Header: 'Account Head',
            accessor: "accountHead",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].accountHead`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {

                                    return options;
                                    let tempRows = form.values.tableRow;

                                    //todo do this section again
                                    let selectedProduct = tempRows.filter((item: any) => item.product).map((item: any) => item.product?.id);
                                    if (field.value?.id) selectedProduct.splice(selectedProduct.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedProduct.includes(item.id)) : [];

                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>
                                                {isView ? (
                                                    <div className={style.labelValue}>
                                                        {field?.value?.name || ''}
                                                    </div>
                                                ):(
                                                    <AutoCompleteInput type={'text'}
                                                                       id={`${name}[${index}].product`} {...field}
                                                                       size={"small"}
                                                                       placeholder={'Select Head'}
                                                                       filterOptions={(options, {inputValue}) => {
                                                                           let filteredOptions = getOptions(options)
                                                                           //todo do this again
                                                                           return matchSorter(filteredOptions, inputValue, {keys: ['name', 'code']});
                                                                       }}
                                                                       onChange={(e, value) => {
                                                                           form.setFieldValue(`${name}[${index}].accountHead`, value)
                                                                       }}

                                                                       hideOutline={true}
                                                                       autoHighlight={true}
                                                                       autoSelect={true}
                                                                       options={(indirectExpenseAccounts && directExpenseAccounts) ? [...indirectExpenseAccounts.data,...directExpenseAccounts.data] : []}
                                                                       loading={!indirectExpenseAccounts && !directExpenseAccounts}
                                                                       variant={'standard'}
                                                                       InputProps={{
                                                                           disableUnderline: true,
                                                                           style: {paddingLeft: 5, paddingRight: 5}
                                                                       }}
                                                                       groupBy={option => option.subtypename}
                                                                       PopperComponent={PopperMy}
                                                                       getOptionLabel={option => option.name}
                                                                       renderOption={(option) => {
                                                                           return (
                                                                               <div>
                                                                                   <div style={{
                                                                                       fontSize: 11,
                                                                                   }}>
                                                                                       <>{option.code}</>
                                                                                   </div>
                                                                                   <div>{option.name}</div>
                                                                               </div>
                                                                           )
                                                                       }}
                                                                       error={meta.touched && meta.error}
                                                    />
                                                )}
                                                {/*<div style={{fontSize: 14, fontWeight: 700, color:COLOR_PROFILE.primaryColor, paddingLeft: 5, paddingRight: 5}}>*/}
                                                {/*    {field?.value?.name || ''}*/}
                                                {/*</div>*/}

                                            </Grid>
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'taxableAmount',
            Header: 'Taxable Amount',
            accessor: "taxableAmount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].taxableAmount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].taxableAmount`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].taxableAmount`, parseFloat(event.target.value))
                                                        // form.setFieldValue(`tds[${index}].taxableAmount`, parseFloat(event.target.value))
                                                        form.setFieldValue(`${name}[${index}].amount`, (100 + parseFloat(original.tax))/100 * parseFloat(event.target.value))

                                                    }}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'tax',
            Header: 'VAT (%)',
            width: 60,
            accessor: "tax",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].tax`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].tax`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].tax`, parseFloat(event.target.value))
                                                        form.setFieldValue(`${name}[${index}].amount`,  ((100 + parseFloat(event.target.value))/100) * parseFloat(original.taxableAmount))

                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'amount',
            Header: 'Amount',
            accessor: "amount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].amount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].amount`} {...field}
                                                    size={"small"}
                                                    value={original.amount}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                //console.log(cellObj)

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        //console.log('delete clicked')
        handleRemove(index, formikInstance, name)
    }

    const getBalance = () => {
        return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }

    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, accountHeadOptions, indirectExpenseAccounts, directExpenseAccounts]);


    return (
        <div>

            <ErrorDialog
                open={errorDialog}
                handleClose={() => setErrorDialog(false)}
                message={<div>The selected item is not associated with proper account head. Expense item must be have either direct expense or indirect expense type head selected in their Dr. Account Type. Click in this link to learn fully about it.</div>}
                title={'Error!'}
                size={'sm'}
            />

            <div style={{paddingBottom: 40}}>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} sectionName={name} balance={getBalance}
                                   type={'expenseEntry'}
                                  minLength={1}
                                  mode={mode}
                                  fromProduct={true}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )
};


export default ExpenseEntryForm;
