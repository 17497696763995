import React from 'react';
import {createStyles, makeStyles, Theme, useTheme, withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CompanySetupHome from "../../templates/companySetupHome";
import VerifyRequests from "../../templates/verifyRequests";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import {useMediaQuery} from "@material-ui/core";
import Calendar from "../../../commonPages/organisms/Calendar";

const AntTabs = withStyles({
    root: {
        color: 'black',
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: COLOR_PROFILE.primaryColor,
    },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 100,
            color: COLOR_PROFILE.primaryColor,
            fontSize: 15,
            opacity: 1,
            marginLeft: 5,
            marginRight: theme.spacing(4),
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                color: COLOR_PROFILE.primaryColor,
            },
            '&$selected': {
                color: COLOR_PROFILE.primaryColor,
                fontWeight: 700,
            },
            '&:focus': {
                color: COLOR_PROFILE.primaryColor,
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);


interface StyledTabProps {
    label: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fafafa'
    },
    padding: {
        padding: theme.spacing(3),
    },
    demo1: {
        // backgroundColor: theme.palette.background.paper,
    },
    demo2: {
        backgroundColor: '#2e1534',
    },
}));


export default function CustomizedTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        dispatch({type: 'SET_DASHBOARD_TAB', payload: newValue})

    };

    const dashboard = useSelector((state: RootState) => state.dashboard);
    const {dashboardTab} = useSelector((state: any) => state.selectedStatus);



    return (
        <div className={classes.root}>
            <div className={classes.demo1}>

                <div style={{margin: 20}}>
                <CompanySetupHome/>
                </div>

                {/*<Calendar/>*/}
                {/*<AntTabs value={dashboardTab} onChange={handleChange} aria-label="tab">*/}
                {/*    <AntTab label="Dashboard"/>*/}
                {/*    {dashboard.role.makerChecker === 'CHECKER' && (*/}
                {/*        <AntTab label="Verify Requests"/>*/}
                {/*    )}*/}
                {/*</AntTabs>*/}

                {/*<div style={{margin: 20}}>*/}

                {/*    {*/}
                {/*        dashboardTab === 0 && (*/}
                {/*            <CompanySetupHome/>*/}
                {/*        )*/}
                {/*    }*/}

                {/*    {*/}
                {/*        dashboardTab === 1 && (*/}
                {/*            <VerifyRequests/>*/}
                {/*        )*/}
                {/*    }*/}

                {/*</div>*/}
            </div>
        </div>
    );
}






