import React, {useEffect, useState} from 'react';
import {Route, useHistory, useParams} from "react-router-dom";
import useSWR from "swr";
import FromSubTypes from "../../../templates/reporting/fromSubTypes";
import {useDispatch, useSelector} from "react-redux";
import GeneralLedgerFilterForm from "../../../organisms/Forms/generalLedger";
import {RootState} from "../../../../../store/RootReducer";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {Grid} from "@material-ui/core";
import ReportActionButton from "../../../organisms/reportActionButton";

const ProfitLoss: React.FC<any> = (Props: any) => {

    // @ts-ignore
    const {id} = useParams();

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [branch, setBranch] = useState(null);

    const [formState, setFormState] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    // const {data: getData} = useSWR(id === 'home' ? '/report/getAccountGroups' : id.includes('head') ? `/getBalance/?from=2020-04-01&till=2020-06-31&id=${id.split('=')[1]}` : `/report/getAccountGroupAndBalance/${id}`)

    const history = useHistory();
    let balance = 0;
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch({type: "REMOVE_REPORT_FILTER"})
        }
    }, [])

    return (
        <div>

            {/*<button onClick={() => window.print()}>PRINT</button>*/}

            <div style={{maxWidth: 1000}}>

                <div style={{
                    padding: '10px 0 0 20px',
                    fontWeight: 700,
                    fontSize: 18,
                    color: COLOR_PROFILE.primaryColor
                }}>
                    Profit & Loss
                </div>

                <div>
                    {!id?.includes('head') && (
                    <GeneralLedgerFilterForm from={'groupSummary'}
                                             setStartDate={setStartDate}
                                             setEndDate={setEndDate}
                                             setBranch={setBranch}
                                             setFormState={setFormState}
                                             submitting={submitting}
                                             setSubmitting={setSubmitting}
                    />
                    )}

                </div>

                <div>
                    <Route path={'/dashboard/reports/profit-loss/table/:id'} exact>
                        <div>
                            <ProfitLossTable startDate={startDate} endDate={endDate} branch={branch}
                                             setSubmitting={setSubmitting}
                            />
                        </div>
                    </Route>
                </div>
            </div>
        </div>
    );


};

const ProfitLossTable: React.FC<any> = (Props) => {
    // @ts-ignore
    const {id} = useParams();

    const filterFormData = useSelector((state: RootState) => state.reportFilterReducer);

    const [fromDate, setFromDate] = useState(null)
    const [tillDate, setTillDate] = useState(null);
    const [branchId, setBranchId] = useState(null);
    const [total, setTotal] = useState('');


    useEffect(() => {
        // @ts-ignore
        setFromDate((filterFormData.transactionYearAdFrom && filterFormData.transactionMonthAdFrom && filterFormData.transactionDateAdFrom) ? filterFormData.transactionYearAdFrom + '-' + ('0' + filterFormData.transactionMonthAdFrom).slice(-2) + '-' + ('0' + filterFormData.transactionDateAdFrom).slice(-2) : Props.startDate);
        // @ts-ignore
        setTillDate((filterFormData.transactionYearAdTill && filterFormData.transactionMonthAdTill && filterFormData.transactionDateAdTill) ? filterFormData.transactionYearAdTill + '-' + ('0' + filterFormData.transactionMonthAdTill).slice(-2) + '-' + ('0' + filterFormData.transactionDateAdTill).slice(-2) : Props.endDate);

        setBranchId(Props.branch ? Props.branch.id : null)
        Props.setSubmitting(true)

    }, [Props.endDate, Props.startDate, Props.branch])

    const {data: getData} = useSWR((fromDate && tillDate) ? id === 'home' ?
        `/report/getAccountTypesAndSubTypes?from=${fromDate || ''}&to=${tillDate|| ''}&name=profitLoss&branchId=${branchId}` :
        id.includes('head') ? `/report/getBalance?from=${fromDate|| ''}&to=${tillDate|| ''}&accHeadId=${id.split('=')[1]}&branchId=${branchId}` :
            id.includes('type') ? `/report/getAccountSubTypesGroups?from=${fromDate|| ''}&to=${tillDate|| ''}&accSubTypeId=${id.split('=')[1]}&branchId=${branchId}` :
                `/report/getAccountGroupAndBalance?from=${fromDate|| ''}&to=${tillDate|| ''}&id=${id}&branchId=${branchId}`
        : null
    )

    useEffect(() => {
        if(!getData) return;
        Props.setSubmitting(false)
    }, [getData])

    useEffect(() => {
        if (id !== 'home') return;
        if (!getData) return;
        let val = getData.data2[0].value;
        setTotal(val > 0 ? Math.abs(val) + ' Profit' : Math.abs(val) + ' Loss');
    }, [getData])

    return (
        <div>

            {getData && (
                <div style={{paddingBottom: 50}}>
                    <FromSubTypes

                        getData={id === 'home' ? getData?.data?.filter((item: any) => item.name === 'Income')[0]?.accountSubTypes.filter((item: any) => !(item.balance === null)) : getData}
                        type={'Income'}
                        withOutHeadBackground={true} withTwoHead={id === 'home'}
                        from={'profit-loss/table'}
                    />

                    {id === 'home' && (
                        <>
                            <FromSubTypes
                                getData={id === 'home' ? getData?.data?.filter((item: any) => item.name === 'Expenses')[0]?.accountSubTypes.filter((item: any) => !(item.balance === null)) : getData}
                                from={'profit-loss/table'}
                                // finalReport={true}
                                withOutHeadBackground={true}
                                finalReportName={'Profit & Loss'}
                                finalData={total}
                                type={'Expenses'}/>
                        </>

                    )}

                    <div>
                        <ReportActionButton profitLoss={ id.includes('head') ? null :
                            getData?.data?.find((item: any) => item?.accountSubTypes.find((item: any) => item.balance!== null)) && (
                                <Grid container justify={"flex-end"}
                                      alignItems={"center"}
                                      spacing={0}
                                      style={{
                                          fontSize: 16, fontWeight: 600,
                                          color: COLOR_PROFILE.primaryColor, padding: '20px 30px 0px 20px'
                                      }}
                                >
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item>
                                        Operating Profit/ Loss:
                                    </Grid>
                                    <Grid item xs={5} style={{paddingLeft: 50}}>
                                        {total}
                                    </Grid>
                                </Grid>
                            )
                            }
                        />
                    </div>
                </div>

            )}
        </div>
    )
}


export default ProfitLoss;
