import {Route} from "react-router-dom";
import {motion} from "framer-motion";
import AccessFilter from "../../../accessControl/accessFilter";
import AccessDenied from "../../../components/accounting/templates/errorTemplates/accessDenied";
import PurchaseRfp from "../../../components/purchase/virtualPages/purchaseRfp";
import PurchaseRfpAdd from "../../../components/purchase/virtualPages/purchaseRfpAdd";
import PurchaseOrder from "../../../components/purchase/virtualPages/purhcaseOrder";
import PurchaseBill from "../../../components/purchase/virtualPages/purchaseBill";
import PurchaseBillAdd from "../../../components/purchase/virtualPages/purchaseBillAdd";
import PurchaseOrderAdd from "../../../components/purchase/virtualPages/purchaseOrderAdd";
import PurchaseReturn from "../../../components/purchase/virtualPages/purchaseReturn";
import PurchaseReturnAdd from "../../../components/purchase/virtualPages/purchaseReturnAdd";
import InventoryLevel from "../../../components/product/virtualPages/inventoryLevel";
import InventoryAdjustmentAdd from "../../../components/product/virtualPages/inventoryAdjustmentAdd";
import InventoryAdjustment from "../../../components/product/virtualPages/inventoryAdjustment";
import ExpenseEntry from "../../../components/purchase/virtualPages/expenseEntry";
import PurchaseEntryAdd from "../../../components/purchase/virtualPages/expenseEntryAdd";
import React from "react";

export const PurchaseRoutes = [
    <Route path={'/dashboard/purchase/request-for-proposal'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}
        >
            <AccessFilter

                section={"purchaseRFP"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseRfp/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/purchase/request-for-proposal/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseRFP"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseRfpAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/purchase/request-for-proposal/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseRFP"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseRfpAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/purchase/request-for-proposal/view/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseRFP"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseRfpAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/purchase/purchase-order'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseOrder"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseOrder/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,


    <Route path={'/dashboard/purchase/purchase-bill'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseBill"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseBill/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,


    <Route path={'/dashboard/purchase/purchase-bill/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseBill"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseBillAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/purchase/purchase-bill/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseBill"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseBillAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/purchase/purchase-bill/view/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseBill"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseBillAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,


    <Route path={'/dashboard/purchase/purchase-order/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseOrder"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseOrderAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/purchase/purchase-order/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseOrder"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseOrderAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/purchase/purchase-order/view/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseOrder"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseOrderAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/purchase/purchase-return'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseReturn"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                {/*asdfasdfas fad sf*/}
                                <PurchaseReturn/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/purchase/purchase-return/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseReturn"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseReturnAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/purchase/purchase-return/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"purchaseReturn"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseReturnAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/purchase/purchase-return/view/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}
        >
            <AccessFilter

                section={"purchaseReturn"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseReturnAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,


    <Route path={'/dashboard/inventory/inventory-level'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"inventoryLevel"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <InventoryLevel/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,


    <Route path={'/dashboard/inventory/inventory-adjustment/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"inventoryAdjustment"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <InventoryAdjustmentAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,


    <Route path={'/dashboard/inventory/inventory-adjustment'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"inventoryAdjustment"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <InventoryAdjustment/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/purchase/expense-entry'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"expenseBill"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <ExpenseEntry/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/purchase/expense-entry/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"expenseBill"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <PurchaseEntryAdd/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/purchase/expense-entry/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"expenseBill"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <PurchaseEntryAdd/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/purchase/expense-entry/view/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"expenseBill"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <PurchaseEntryAdd/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>
]