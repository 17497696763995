import React from 'react';
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {AnimatePresence, motion} from 'framer-motion';
import AccessFilter from "../../accessControl/accessFilter";
import AccessDenied from "../../components/accounting/templates/errorTemplates/accessDenied";
import MonthlyVATReturn from "../../components/VATFiling/virtualPages/monthlyVATReturn";
import GlobalSetting from "../../components/commonPages/virtualPages/globalSetting";
import {ProductRoutes} from "./product";
import {AccountingSetupRoutes} from "./accountingSetup";
import {ReportingRoutes} from "./reporting";
import {PurchaseRoutes} from "./purchase";
import CustomizedTabs from "../../components/accounting/organisms/primaryTabs";
import {CompanySetupRoutes} from "./companySetup";
import {AccountingEntriesRoutes} from "./accountingEntries";
import {SalesRoutes} from "./sales";
import VerifyRequests from "../../components/accounting/templates/verifyRequests";
import {VatManagement} from "./vatManagement";

const DashboardRoute: React.FC = () => {



    const location = useLocation();

    return (
        <div>

            <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>

                    <Redirect from={'/dashboard/home'} to={'/dashboard/home/setup/share-holders'} exact={true}/>

                    <Route path={'/dashboard/home'}>
                        <div style={{margin: '-10px -23px 0 -23px'}}>
                            <CustomizedTabs/>
                        </div>
                    </Route>,

                    <Route path={'/dashboard/verify-requests'}>
                        <div style={{margin: '-10px -23px 0 -23px'}}>
                            <VerifyRequests/>
                        </div>
                    </Route>,

                    {CompanySetupRoutes}
                    {AccountingSetupRoutes}
                    {AccountingEntriesRoutes}
                    {ProductRoutes}
                    {PurchaseRoutes}
                    {SalesRoutes}
                    {VatManagement}
                    {ReportingRoutes}


                    <Route path={'/dashboard/vat-filing/monthly-vat-return'} exact>

                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: 0.08}}

                        >
                            <AccessFilter

                                section={"balanceSheet"}
                                privilege={"read"}
                                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                                    if (!isAllowed) return <AccessDenied/>

                                    return (
                                        <div>
                                            <div>
                                                <MonthlyVATReturn/>
                                            </div>
                                        </div>
                                    )

                                }}

                            />
                        </motion.div>
                    </Route>

                    {/*this is terms and conditions to it*/}
                    <Route path={'/dashboard/global-setting'} exact>

                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: 0.08}}

                        >
                            <AccessFilter

                                section={"balanceSheet"}
                                privilege={"read"}
                                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                                    if (!isAllowed) return <AccessDenied/>

                                    return (
                                        <div>
                                            <div>
                                                <GlobalSetting/>
                                            </div>
                                        </div>
                                    )

                                }}

                            />
                        </motion.div>
                    </Route>


                    <Route path={'/dashboard/*'} exact>

                        <div>
                            not found
                        </div>

                    </Route>


                </Switch>

            </AnimatePresence>
        </div>
    );
};

export default DashboardRoute;
