import React from 'react';
import PayablesTemplate from "../../../templates/reporting/payablesTemplate";

const PayablesReport: React.FC  = () => {
    return (
        <div>
            <PayablesTemplate/>
        </div>
    );
};

export default PayablesReport;