import React from 'react';
import {COLOR_PROFILE} from "../../../../../config/colorCode";

const DataEmptyMessage = () => {
    return (
        <div>
            <div style={{color: COLOR_PROFILE.primaryColor, paddingTop: 10, paddingLeft: 5}}>
                <div style={{fontSize: 16, fontWeight: 600}}>
                    No data to show here!
                </div>
                <div style={{fontSize: 14}}>
                    Data will be visible once an entry is made.
                </div>
            </div>
        </div>
    );
};

export default DataEmptyMessage;