import React, {useState} from 'react';
import SetupAddNew from "../../../organisms/setupAddNew";
import CustomTable from "../../../organisms/tables/customerTable";
import SetupFileUpload from "../../../organisms/setupFileUpload";
import TableFilter from "../../../organisms/Forms/tableFilter";
import MembershipTable from "../../../organisms/tables/membershipTable";

const MembershipPage: React.FC = () => {


    return (
        <div>

            <div style={{maxWidth: 1000 }}>
                <div style={{padding: 20}}>
                    <SetupAddNew

                        buttonNameAdd={{pathTo: '/dashboard/sales/membership/add', name: 'Add Membership'}}

                        message={
                            <div style={{
                                fontSize: 16,
                                fontWeight: 600,
                                padding: '10px 10px 10px 5px'
                            }}>
                                <div>Click below to add a Membership
                                </div>

                            </div>
                        }

                    />
                </div>


                <div style={{padding: '0 30px 0 28px'}}>
                    <MembershipTable/>
                </div>
            </div>

        </div>
    );
};

export default MembershipPage;
