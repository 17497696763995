import React from 'react';
import ProductCategoryAddition from "../../templates/productCategoryAddition";
import {useParams} from "react-router-dom";

const ProductCategoryAdd: React.FC = () => {

    return (
        <div>
            <ProductCategoryAddition />
        </div>
    );
};

export default ProductCategoryAdd;