import React from 'react';
import PageTopBar from "../../../accounting/organisms/pageTopBar";
import DividerComponent from "../../../shared/atom/divider";
import InventoryAdjustmentForm from "../../organisms/form/inventoryAdjustment";
import {useTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";

const InventoryAdjustmentAddition = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div>
            <div style={{paddingLeft: matches ? 10: 0}}>
                <PageTopBar name={'Inventory Adjustment'}/>
            </div>

            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: matches ? '25px 0px 0px -0px': '25px 0px 10px 25px', overflow: "scroll"}}>
                <div style={{maxWidth: matches? "100vw": 1250, paddingRight: matches ? 10:20, paddingLeft: 25,paddingBottom: 80}}>
                <InventoryAdjustmentForm mode={"create"} from={'inventory-adjustment'}/>
                </div>
            </div>
        </div>
    );
};

export default InventoryAdjustmentAddition;