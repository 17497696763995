import React from 'react';
import ProductCategoryHome from "../../../components/product/virtualPages/productCategory";
import ProductCategoryAdd from "../../../components/product/virtualPages/productCategoryAdd";
import Uom from "../../../components/product/virtualPages/uom";
import UomAdd from "../../../components/product/virtualPages/uomAdd";
import ProductItem from "../../../components/product/virtualPages/productItem";
import ProductItemAdd from "../../../components/product/virtualPages/productAdd";
import ProductBundleHome from "../../../components/product/templates/productBundleHome";
import ProductBundle from "../../../components/product/virtualPages/productBundleAdd";
import ProductOffer from "../../../components/product/virtualPages/productOffer";
import ProductOfferAdd from "../../../components/product/virtualPages/productOfferAdd";
import {Route} from "react-router-dom";
import {motion} from "framer-motion";
import AccessFilter from "../../../accessControl/accessFilter";
import AccessDenied from "../../../components/accounting/templates/errorTemplates/accessDenied";
import ProductHierarchy from "../../../components/product/virtualPages/productHierarchy";

export const ProductRoutes = [


    <Route path={'/dashboard/product/product-hierarchy'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"category"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductHierarchy/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/product/product-category'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"category"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductCategoryHome/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-category/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"category"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductCategoryAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-category/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"category"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductCategoryAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/uom'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"uom"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <Uom/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/uom/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"uom"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <UomAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/uom/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"uom"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <UomAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-item'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"productItem"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductItem/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-item/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"productItem"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductItemAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-item/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"productItem"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductItemAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-bundle'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"bundle"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductBundleHome/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-bundle/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"bundle"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductBundle/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-bundle/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"bundle"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductBundle/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-sales-offer'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesOffer"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductOffer/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-sales-offer/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesOffer"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductOfferAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/product/product-sales-offer/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}
        >
            <AccessFilter

                section={"salesOffer"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProductOfferAdd/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>
]

