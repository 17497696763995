import React, {useEffect, useState} from 'react';
import CustomChart from "../../molecules/customChart";
import useSWR from "swr";
import {Grid} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../config/colorCode";


const BarChart: React.FC<any> = (Props) => {

    const [chartData, setChartData] = useState([]);
    const [label, setLabel] = useState([]);


    // const {data: report} = useSWR(`/report/receivablePayable?from=2022-02-21&to=2022-03-03`);



    useEffect(() => {
        if(!Props.report) return;
        setChartData([])
        let receivables = Props.report;
        let sortedKeys: any = Object.keys(receivables).sort();

        setChartData([])
        console.log(Props.report)
        // @ts-ignore
        setLabel(calculatelabel(Props.days));

        // setLabel(sortedKeys)
        sortedKeys.forEach((item: string) => {
            // @ts-ignore
            setChartData(prevState => {
                let oldData: any = prevState;
                oldData.push((receivables[item]) * (Props.type === 'payable' ? -1: 1))
                return oldData
            })
        })
    }, [Props.report, Props.days])

    const calculatelabel = (days: number) => {
        // let days = 10;

        setLabel([]);
        let dataLabel = [];
        let currentMonth;
        let currentDate = Props.currentDate ? Props.currentDate:  new Date();
        let currentDay;


        for (let i = days; i > 0; i--) {

            currentDay = currentDate.getDate();

            currentMonth = currentDate.toLocaleString('default', {month: 'long'});

            dataLabel[days - i] = currentDay;

            currentDate.setDate(currentDate.getDate() - 1);


            if (i === days) {
                dataLabel[days - i] = dataLabel[days - i] + ' ' + currentMonth;
                continue;
            }


            if (currentDay === 1) {
                dataLabel[days - i] = dataLabel[days - i] + ' ' + currentMonth;
                continue;
            }

            if (i === 1) {
                dataLabel[days - i] = dataLabel[days - i] + ' ' + currentMonth;
            }

        }

        return dataLabel.reverse()
    }

    // @ts-ignore
    return (
        <Grid container justify={"center"} style={{}}>
            <div style={{position: 'relative', height: 'auto', width: '100%'}}>
                    <CustomChart
                        data={
                            {
                                datasets: [{
                                    label: '',
                                    barPercentage: 0.9,
                                    barThickness: 20,
                                    maxBarThickness: 30,
                                    minBarLength: 2,
                                    // borderColor: COLOR_PROFILE.buttonLightGreen,
                                    tension: 0.1,
                                    fill: false,
                                    backgroundColor:COLOR_PROFILE.buttonLightGreen,
                                    data: [...chartData]
                                }],

                                // showTooltips: true,
                                labels: [...label]
                            }
                        }
                        type={'bar'}
                        options={{
                            responsive: true, maintainAspectRatio: true, color: COLOR_PROFILE.buttonGreen,
                            // showTooltips: true,
                            hover: {
                                mode: "index",
                                intersect: false
                            },
                            // scales: {
                            //     xAxis: {
                            //         barPercentage: 1,
                            //         categoryPercentage: 0.9,
                            //     }
                            // },

                            // interaction: {
                            //     intersect: false,
                            //     mode: 'index',
                            // },
                            plugins: {
                                tooltip: {
                                    enabled: true,
                                    mode: "index",
                                    intersect: true
                                }
                            },
                        }}
                    />
            </div>

        </Grid>
    );
};

export default BarChart;
