import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import Button from "../../../../shared/atom/button";
import useAddressHandler from "../../../../../effects/useAddressHandler";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../../../config/axiosConfig";
import useSWR, {trigger} from "swr";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import InputField from "../../../../shared/atom/formElements/inputField";
import FormSaveButton from "../../FormSaveButton";
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import RequiredNotation from "../../../molecules/requiredNotation";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {

    from: string
    mode: 'edit' | 'create',
    nextPath?: string
    id?: any

}

const EmployeeForm: React.FC<Props> = (Props) => {


    const initialValues = {
        name: "",
        phoneNumber: "",
        email: "",
        ppPhotoUrl: null,

        branch: null,

        position: '',
        manager: false,

        description: "",
        dateOfBirth: '',
        citizenshipNumber: "",
        citizenshipPhotoUrl: null,
        panNumber: '',
        panPhotoUrl: null,
        emergencyContactName: '',
        emergencyContactNumber: '',

        address: {
            country: "Nepal",
            provinceOrState: null,
            district: null,
            city: '',
            wardNumber: null,
            municipalityOrVdc: null,
            googleMapLocationUrl: ''
        },


    }

    const [formState, setFormState] = useState(initialValues);
    const [selectedAddress, setSelectedAddress] = useState({
        province: null,
        district: null,
        vdc: null,
        ward: null
    })

    const {
        provinceOptions,
        districtOptions,
        vdcOptions,
        wardOptions,
        provinceLoading,
        vdcLoading
    } = useAddressHandler({...selectedAddress})
    const {data: branchOptions} = useSWR('/getAllBranches?isActive=true')

    const location = useLocation();

    useEffect(() => {

        if(!branchOptions) return;
        if(!(provinceOptions && districtOptions && vdcOptions && wardOptions)) return;

            if (Props.id) {
                // @ts-ignore
                const {name,phoneNumber,email,address,ppPhotoUrl,branchDto,position,manager,description,dateOfBirth,citizenshipNumber,citizenshipPhotoUrl,panNumber,panPhotoUrl,emergencyContactName,emergencyContactNumber} = location.state;


                try {

                    let provinceTemp = provinceOptions.find((item: any) => item.id === address?.provinceOrState?.id);
                    validateProvince(provinceTemp);
                    let districtTemp = districtOptions.find((item:any) => item.district == address?.district)
                    handleSelectedDistrict(districtTemp);
                    let muniCipalityTemp = vdcOptions.find((item: any) => item.municipalityOrVdcName = address?.municipalityOrVdc);
                    validateMunicipality(muniCipalityTemp);

                    let addressTemp = {
                        address: {
                            country: 'Nepal',
                            provinceOrState: provinceTemp,
                            municipalityOrVdc:  muniCipalityTemp,
                            district: districtTemp,
                            wardNumber: address?.wardNumber || null,
                            city: address?.city || '',
                            googleMapLocationUrl: address?.googleMapLocationUrl || ''
                        }

                    }

                    // @ts-ignore
                    setFormState({
                        ...addressTemp,
                        name: name,
                        phoneNumber: phoneNumber,
                        email: email,
                        ppPhotoUrl: ppPhotoUrl,

                        branch: branchDto,

                        position: position,
                        manager: false,

                        description: description,
                        dateOfBirth: dateOfBirth ? dateOfBirth.split("T")[0]: '',
                        citizenshipNumber: citizenshipNumber,
                        citizenshipPhotoUrl: citizenshipPhotoUrl,
                        panNumber: panNumber,
                        panPhotoUrl: panPhotoUrl,
                        emergencyContactName: emergencyContactName,
                        emergencyContactNumber: emergencyContactNumber,
                    })

                } catch (e) {

                }

            }

        }
        ,
        [branchOptions, provinceOptions, districtOptions, vdcOptions, wardOptions]
    )

    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Email is invalid').required('Required'),
        branch: Yup.object().required('Required').nullable(true)
    })

    const dispatch = useDispatch();

    const onSubmit = async (values: any, other: any) => {

        let isReset = values.action === 'submitAndAdd';


        try {

            // axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`


            const tempVal = {...values};
            console.log("tempVal", tempVal)


            const payload = {
                ...tempVal,
                email: values.email.toLowerCase().trim(),
                branchDto: {
                    id: tempVal.branch ? tempVal.branch.id : null
                },
                address: {
                    ...tempVal.address,
                    provinceOrState: tempVal.address.provinceOrState ? tempVal.address.provinceOrState.province : null,
                    municipalityOrVdc: tempVal.address.municipalityOrVdc ? tempVal.address.municipalityOrVdc.municipalityOrVdcName : null,
                    district: tempVal.address.district ? tempVal.address.district.district : null,
                    wardNumber: tempVal.address.wardNumber,
                    city: tempVal.address.city
                }
            }


            if (Props.id) {
                const {data} = await axiosInstance.put('/updateEmployee', {...payload, id: Props.id})
                handleResponse(data, other, isReset)

            } else {
                const {data} = await axiosInstance.post('/registerEmployee', payload)
                handleResponse(data, other, isReset)

            }
            // return


        } catch (e) {

            console.log(e);
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: 'Something went wrong', type: 'warning'}
            })
        }

    }

    const handleResponse = (data: any, other: any, isReset: boolean) => {

        let message = ''
        let barType = 'info'

        if (data?.code === 'OK') {

            message = (data?.message)
            if (data?.message === "Employee registered" || data?.message === "Employee information updated.") {
                trigger(`/getAllEmployees/1/5`);
                barType = 'success'
                dispatch({
                    type: 'OPEN_SNACKBAR',
                    payload: {message: message, type: barType}
                })

                other.setSubmitting(false);
                other.resetForm();
                if (!isReset) history.replace('/dashboard/company-setup/employee');

            } else {
                barType = 'warning'
            }

        } else {
            message = 'Something went wrong';
            barType = 'warning';
        }

        other.setSubmitting(false);

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: message, type: barType}
        })
    }


    const validateProvince = (value: any) => {

        if (value?.province) {

            setSelectedAddress(prevState => {
                return {
                    ...prevState,
                    province: value
                }
            })

        }

        return false

    }

    const handleSelectedDistrict = (value: any) => {

        setSelectedAddress(prevState => {
            return {
                ...prevState,
                district: value
            }
        })

    }

    const validateMunicipality = (value: any) => {


        if (value?.wardNumbers) {

            setSelectedAddress(prevState => {
                return {
                    ...prevState,
                    vdc: value
                }
            })

        }

    }

    const history = useHistory();

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>


            <div>

                <Formik
                    initialValues={formState}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnMount={false}
                    validateOnBlur={true}
                >

                    {formik => {


                        return (
                            <div>
                                <PagePrompt formik={formik}/>

                                <Form style={{margin: 20}} onKeyDown={onKeyDown}>


                                    <Grid container justify={"space-between"}>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="name"
                                                       style={{
                                                           fontWeight: "bold",
                                                           fontSize: 14
                                                       }}>Name:<RequiredNotation/></label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'name'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'text'} id={'name'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="email" style={{fontWeight: "bold", fontSize: 14}}>
                                                    Email:<RequiredNotation/>
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'email'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'email'}
                                                                                    id={'email'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="branch"
                                                       style={{
                                                           fontWeight: "bold",
                                                           fontSize: 14
                                                       }}> Branch:<RequiredNotation/></label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'branch'}
                                                    >
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}
                                                                                           id={'branch'} {...field}
                                                                                           size={"small"}
                                                                                           onChange={(e, value) => {

                                                                                               form.setFieldValue("branch", value)
                                                                                           }}
                                                                                           loading={!branchOptions}
                                                                                           options={branchOptions?.data}
                                                                                           getOptionLabel={option => option.name || 'Temp'}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="position" style={{fontWeight: "bold", fontSize: 14}}>
                                                    Position:
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'position'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'position'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="phoneNumber" style={{fontWeight: "bold", fontSize: 14}}>
                                                    Phone Number
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'phoneNumber'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'phone'}
                                                                                    id={'phoneNumber'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="dateOfBirth" style={{fontWeight: "bold", fontSize: 14}}>
                                                    DOB:
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'dateOfBirth'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'date'}
                                                                                    id={'dateOfBirth'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="citizenshipNumber"
                                                       style={{fontWeight: "bold", fontSize: 14}}>
                                                    Citizenship Number:
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'citizenshipNumber'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'citizenshipNumber'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="panNumber" style={{fontWeight: "bold", fontSize: 14}}>
                                                    PAN Number
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'panNumber'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'panNumber'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="emergencyContactName: "
                                                       style={{fontWeight: "bold", fontSize: 14}}>
                                                    Emergency Contact Name:
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'emergencyContactName'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'name'}
                                                                                    id={'emergencyContactName'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="emergencyContactNumber"
                                                       style={{fontWeight: "bold", fontSize: 14}}>
                                                    Emergency Contact Number:
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'emergencyContactNumber'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'phone'}
                                                                                    id={'emergencyContactNumber'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="provinceOrState"
                                                       style={{fontWeight: "bold", fontSize: 14}}> Province:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'address.provinceOrState'}
                                                           validate={validateProvince}
                                                    >
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}
                                                                                           id={'provinceOrState'} {...field}
                                                                                           size={"small"}
                                                                                           onChange={(e, value) => {
                                                                                               if (value === null) {
                                                                                                   form.setFieldValue('address.district', null)
                                                                                                   form.setFieldValue('address.municipalityOrVdc', null)
                                                                                                   form.setFieldValue('address.city', null)
                                                                                                   form.setFieldValue('address.wardNumber', null)
                                                                                               }
                                                                                               form.setFieldValue("address.provinceOrState", value)
                                                                                           }}
                                                                                           options={provinceOptions}
                                                                                           getOptionLabel={option => option.province}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="district"
                                                       style={{fontWeight: "bold", fontSize: 14}}> District:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'address.district'}

                                                    >
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}
                                                                                           id={'district'} {...field}
                                                                                           size={"small"}
                                                                                           onChange={(e, value) => {
                                                                                               if (value === null) {
                                                                                                   form.setFieldValue('address.municipalityOrVdc', null)
                                                                                                   form.setFieldValue('address.wardNumber', null)
                                                                                                   form.setFieldValue('address.city', null)
                                                                                               } else {
                                                                                                   handleSelectedDistrict(value)

                                                                                               }
                                                                                               form.setFieldValue("address.district", value)
                                                                                           }}
                                                                                           options={districtOptions}
                                                                                           getOptionLabel={option => option.district}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>

                                                        <span
                                                            style={{
                                                                color: 'red',
                                                                fontSize: 12,
                                                                bottom: 0,
                                                                left: 2
                                                            }}
                                                        >
                                                            {(meta.touched && meta.error) ? meta.error : null}
                                                        </span>

                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="municipalityOrVdc"
                                                       style={{fontWeight: "bold", fontSize: 14}}> Municipality/
                                                    Vdc:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'address.municipalityOrVdc'}
                                                           validate={validateMunicipality}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}
                                                                                           id={'municipalityOrVdc'} {...field}
                                                                                           size={"small"}
                                                                                           onChange={(e, value) => {
                                                                                               if (value === null) {
                                                                                                   form.setFieldValue('address.wardNumber', null)
                                                                                               }
                                                                                               form.setFieldValue("address.municipalityOrVdc", value)
                                                                                           }}
                                                                                           loading={vdcLoading}
                                                                                           options={vdcOptions}
                                                                                           getOptionLabel={option => option.municipalityOrVdcName}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="wardNumber"
                                                       style={{fontWeight: "bold", fontSize: 14}}> Ward
                                                    No.:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'address.wardNumber'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}

                                                                                           id={'wardNumber'} {...field}
                                                                                           size={"small"}
                                                                                           onChange={(e, value) => form.setFieldValue("address.wardNumber", value)}
                                                                                           options={wardOptions}
                                                                                           getOptionLabel={option => option}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="city"
                                                       style={{fontWeight: "bold", fontSize: 14}}> Tole/ Street:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'address.city'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'text'} id={'city'} {...field}
                                                                                    size={"small"}

                                                                                    onChange={(e: any) => {
                                                                                        form.setFieldValue("address.city", e.target.value)
                                                                                    }}

                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 15}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="googleMapLocationUrl"
                                                       style={{fontWeight: "bold", fontSize: 14}}> Google Map
                                                    URL:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'address.googleMapLocationUrl'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'address.googleMapLocationUrl'} {...field}
                                                                                    size={"small"}


                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid container justify={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            <FormSaveButton mode={Props.mode} from={Props.from}
                                                            submitting={formik.isSubmitting}/>
                                        </Grid>
                                        <Grid item>
                                            {Props.from === 'home' && <div>
                                                <div>
                                                    <Button variant={"contained"} customColor={'#297ecc'}
                                                            textColor={'white'}
                                                            onClick={() => history.push(Props.nextPath ? Props.nextPath : '')}>
                                                        Go to User and Roles
                                                    </Button>
                                                </div>
                                            </div>}
                                        </Grid>

                                    </Grid>

                                </Form>
                            </div>
                        )
                    }}
                </Formik>

            </div>

        </div>
    )

}

export default EmployeeForm;
