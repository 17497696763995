import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import Button from "../../../shared/atom/button";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import CircularSpinner from "../../../shared/atom/spinner";
import axiosInstance from "../../../../config/axiosConfig";
import {trigger} from "swr";
import {APPROVAL_MAPPER} from "../../../../utils/contants";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";

const ReportActionButton: React.FC<any> = (Props) => {


    const [submitting, setSubmitting] = useState(false);


    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        console.log("location",location.state);
    },[])

    const handleApprove = async () => {

        setSubmitting(true)

        const payload = {
            ids: [Props.approveData.id],
            model: null,
            message: null
        }

        // @ts-ignore
        let mappedItem: any = APPROVAL_MAPPER[Props.path];

        const {data} = await axiosInstance.put(`${mappedItem.approval}`, payload);

        if (data?.code === 'OK') {
            if (data?.message.includes('approved')) {
                // @ts-ignore
                updateLocationState('approved');
                handleResponse('Approved successfully.', 'success')
            } else {
                handleResponse('Something went wrong', 'warning')
            }
        }

        setSubmitting(false)
    }

    const convertToJournal = async () => {
        setSubmitting(true)

        let payload = {
            ids: [Props.approveData.id],
            model: null,
            message: null
        };

        const {data} = await axiosInstance.put(`/convertToJrs`, payload)

        if(data.code === 'OK') {
            updateLocationState("journaled");
            handleResponse(data.message, 'success')
        }   else{
            handleResponse(data.message, 'warning')
        }
    }

    const updateLocationState = (name: string) => {
        if (history.location && history.location.state ) {
            // @ts-ignore
            const state = { ...history.location.state, [name]: true };
            delete state.from;
            history.replace({ ...history.location, state });
        }
    }
    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    const cancelInvoice = () => {
        Props.setCancelInv(true)
    }

    return (
        <div
            style={{
                position: 'fixed',
                bottom: 0,
                paddingBottom: 20,
                paddingTop: 15,
                marginLeft: -80,
                paddingLeft: 80,
                width: '100%',
                background: '#fafafa'
            }}
        >

            <Grid container justify={"flex-start"} spacing={2} alignItems={'center'}>
                <Grid item>
                    <div style={{maxWidth: 150}}>
                        <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue} textColor={'white'}

                                onClick={() => Props.handlePrint()}
                        >
                                    <span style={{fontSize: 15}}>
                                        Print
                                    </span>
                        </Button>
                    </div>
                </Grid>

                <Grid item>
                    <div style={{maxWidth: 180}}>
                        <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue} textColor={'white'}
                                onClick={() => Props.handleDownloadAsPdf()}
                                >
                                    <span style={{fontSize: 15}}>
                                        Download as PDF
                                    </span>
                        </Button>
                    </div>
                </Grid>

                {/*<Grid item>*/}
                {/*    <div style={{maxWidth: 140}}>*/}
                {/*        <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue} textColor={'white'}*/}
                {/*                >*/}
                {/*                    <span style={{fontSize: 15}}>*/}
                {/*                        Email*/}
                {/*                    </span>*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</Grid>*/}

                <Grid item>
                </Grid>
                <Grid item>
                </Grid>
                <Grid item>
                </Grid>

                {(Props.approveData && !Props?.approveData?.approved ) && (
                    <Grid item>
                        <div style={{maxWidth: 140}}>
                            <Button variant={"contained"} customColor={COLOR_PROFILE.buttonGreen} textColor={'white'}
                                    onClick={handleApprove}
                            >
                                    <span style={{fontSize: 15}}>
                                        Approve
                                    </span>
                            </Button>
                        </div>
                    </Grid>
                )}

                {(Props.convertToJournal && !Props?.approveData?.journaled ) && (
                    <Grid item>
                        <div style={{maxWidth: 180}}>
                            <Button variant={"contained"} customColor={COLOR_PROFILE.buttonGreen} textColor={'white'}
                                    onClick={convertToJournal}
                            >
                                    <span style={{fontSize: 15}}>
                                        Convert to Journal
                                    </span>
                            </Button>
                        </div>
                    </Grid>
                )}

                {(Props.path === 'invoiceView' && Props.setCancelInv) && (
                    <Grid item>
                        <div style={{maxWidth: 180}}>
                            <Button variant={"contained"} customColor={COLOR_PROFILE.buttonRed} textColor={'white'}
                                    onClick={cancelInvoice}
                            >
                                    <span style={{fontSize: 15}}>
                                        Cancel Invoice
                                    </span>
                            </Button>
                        </div>
                    </Grid>
                )}

                <Grid item>

                    {submitting && <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                </Grid>

                {Props.profitLoss && (
                    <Grid item xs={6} container justify={"flex-end"} alignItems={"center"} style={{marginTop: -20}}>
                        {Props.profitLoss}
                    </Grid>
                )}

            </Grid>

        </div>
    );
};

export default ReportActionButton;
