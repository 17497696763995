import React from 'react';
import ProductItemHome from "../../templates/productItemHome";

const ProductItem = () => {
    return (
        <div>
            <ProductItemHome/>
        </div>
    );
};

export default ProductItem;