import React from 'react';
import CompanySetupSteps from "../../organisms/companySetupSteps";
import HomeCompanySetup from "../../../../routes/homeCompanySetup";
import LabelledButton from "../../molecules/labelledButton";
import ShortcutList from "../../organisms/shortcutList";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import {Grid, useMediaQuery} from "@material-ui/core";
import DashboardReport from "../../organisms/dashboardReport";
import AccessFilter from "../../../../accessControl/accessFilter";
import axiosInstance from "../../../../config/axiosConfig";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import useSWR, {trigger} from "swr";
import {useTheme} from "@material-ui/core/styles";
import PayabalesChart from "../reporting/payablesTemplate/PayabalesChart";
import PurchaseChart from "../reporting/purchaseTemplate/PurchaseChart";
import ReceivablesChart from "../reporting/receivablesTemplate/ReceivablesChart";
import SalesChart from "../reporting/salesTemplate/SalesChart";
import DashboardReportItem from "../../molecules/dashboardReportItem";

const CompanySetupHome: React.FC = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const {data: reportData} = useSWR('/dashboardReport?branchId=-1');

    const report = [
        {name: 'Total Payable', accessor: 'Account Payable'},
        {name: 'Total Receivable', accessor: 'Account Receivables'},
    ]

    const reportAndShortCut =

        <AccessFilter

            section={"groupSummary"}
            privilege={"read"}
            render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                if (!(hasAllAccess || isBranchAdmin)) return (
                    <div>
                        <Grid container justify={'center'}>

                            <Grid item>
                                {/*{!matches &&*/}
                                {/*<ShortcutList/>*/}
                                {/*}*/}
                            </Grid>
                        </Grid>

                    </div>
                )

                return (

                    <div style={{position: "relative"}}>
                        <div>
                            <Grid container>
                                <Grid item container style={{maxWidth: 600, margin: '0 auto'}}>
                                    <DashboardReport/>
                                </Grid>
                            </Grid>
                        </div>

                        {/*{!matches &&*/}
                        {/*    <div style={{position: "absolute", right: 0, bottom: 0, top: 0}}>*/}
                        {/*        <ShortcutList/>*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </div>

                )

            }}

        />

    const history = useHistory();

    const handleClick = async () => {

        try {

            let payload = {
                "id": 1,
                "steps": "0",
                "complete": true
            }


            const {data} = await axiosInstance.put('/updateSetupState', payload);

            trigger('/dashboard')
            history.replace('/dashboard/accountingSetup-setup/account-group')

            if (data.code === 'OK') {


            }


        } catch (e) {

        }

    }


    const companySetup =
        <>
            <div style={{textAlign: 'center', margin: '25px 15px 15px 15px'}}>

                <div style={{fontSize: 16, fontWeight: 600, color: COLOR_PROFILE.primaryColor}}>
                    Setup your business quickly! Incase you want to skip all setup, click here:
                </div>
                <div style={{margin: '0 20px 20px 20px'}}>
                    <LabelledButton
                        label={''}
                        color={'#297ECC'}
                        onClick={handleClick}
                        variant={"contained"}
                        buttonName={'Skip All Setup'} textColor={'white'}

                    />
                </div>
            </div>

            <div>
                <CompanySetupSteps dimension={{height: 50, width: 155}}/>
            </div>

            <div style={{paddingBottom: 20}}>
                <HomeCompanySetup/>
            </div>
        </>

    const dashboard = useSelector((state: RootState) => state.dashboard);


    return (
        <div style={{maxWidth: 1100, padding: '0 10px', margin: '0 auto'}}>

            {dashboard?.userState?.complete ? (
                <div>
                    {reportAndShortCut}
                </div>
            ) : (
                <div>
                    {reportAndShortCut}
                </div>
            )}

            <AccessFilter

                section={"groupSummary"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!(hasAllAccess || isBranchAdmin)) return (
                        <div>
                            <Grid container justify={'center'}>

                                <Grid item>
                                    {/*{!matches &&*/}
                                    {/*<ShortcutList/>*/}
                                    {/*}*/}
                                </Grid>
                            </Grid>

                        </div>
                    )

                    return (


                        <Grid container spacing={3} style={{paddingTop: 20}}>
                            <Grid item xs={12} sm={6}>
                                <PurchaseChart from={"dashboard"}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SalesChart from={"dashboard"}/>
                            </Grid>
                            <Grid item container xs={12} sm={6} style={{margin: "0 auto"}}>

                                <Grid item xs>
                                    <PayabalesChart from={"dashboard"}
                                                    total={
                                                        <div style={{
                                                            margin: "0 auto",
                                                            paddingBottom: 7,
                                                            paddingTop: 10
                                                        }}>
                                                            <DashboardReportItem striped name={report[0].name}
                                                                                 loading={!reportData}
                                                                                 value={reportData ?
                                                                                     reportData.data.filter((itemFiltered: any) => itemFiltered.name === report[0].accessor)[0]?.sum?.toString() : ''}
                                                            />
                                                        </div>
                                                    }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sm={6}>
                                <Grid item xs>
                                    <ReceivablesChart from={"dashboard"}
                                                      total={
                                                          <div style={{
                                                              margin: "0 auto",
                                                              paddingBottom: 7,
                                                              paddingTop: 10
                                                          }}>
                                                              <DashboardReportItem striped name={report[1].name}
                                                                                   loading={!reportData}
                                                                                   value={reportData ?
                                                                                       reportData.data.filter((itemFiltered: any) => itemFiltered.name === report[1].accessor)[0]?.sum?.toString() : ''}
                                                              />
                                                          </div>
                                                      }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }}/>

        </div>
);
};


export default CompanySetupHome;
