import React, {useMemo, useState} from 'react';
import {Grid, IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../reactTable/useServerCall";
import useSWR from "swr";
import ReactTable from "../../reactTable/ReactTable";
import CircularSpinner from "../../../../shared/atom/spinner";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DataEmptyMessage from "../dataEmptyMessage";
import {useHistory} from "react-router-dom";

const BankTable: React.FC = () => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);

const history = useHistory();

    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} style={{marginRight: 15, paddingRight: 15}}>


                    <Grid item xs={6}>
                        <IconButton size={"small"} style={{color: '#ec4949'}}>
                            <DeleteIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton size={"small"} style={{color: '#4aca53'}}
                                    onClick={() => history.push(`/dashboard/company-setup/banks/edit/${val.row.original.id}`, val.row.original)}

                        >
                            <EditIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>

                </Grid>
            )
        },

        {
            Header: 'Account Name',
            accessor: 'accountName'
        },
        {
            Header: 'Account Type',
            accessor: 'accountType'
        },
        {
            Header: 'Bank',
            accessor: 'bankDefault.name'
        },
        {
            Header: 'Branch',
            accessor: 'bankDefault.branchName'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },


    ]




    const path = '/getBanks'
    const {data, loading} = useServerCall({path, page, perPage})

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}`)


    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];

    const mockData = useMemo(() => calledData, [data]);

    const columns = useMemo(() => shareHolderColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <ReactTable
                    data={mockData}
                    columns={columns}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={page}
                    perPage={perPage}
                    totalPage={data?.t.totalPage}
                />
            ): (
                (page ===1 && loading) ? <CircularSpinner circleColor={COLOR_PROFILE.buttonBlue}/>
                    :    <DataEmptyMessage/>
            )}
        </div>
    );
};

export default BankTable;
