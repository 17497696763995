import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation,} from 'react-router-dom';
import Dashboard from '../../components/accounting/virtualPages/dashboard';
import LoginPage from '../../components/accounting/virtualPages/login';
import SignUpPage from "../../components/accounting/virtualPages/signUpPage";
import useSWR from "swr";
const Mousetrap = require('mousetrap');


const MainRoute: React.FC = () => {

    const history = useHistory();

    useEffect(() => {

        Mousetrap.bind("alt+shift+j", () => history.push('/dashboard/voucher-entries/journal-voucher/add'))
        Mousetrap.bind("alt+shift+r", () => history.push('/dashboard/voucher-entries/receipt-voucher/add'))
        Mousetrap.bind("alt+shift+p", () => history.push('/dashboard/voucher-entries/payment-voucher/add'))
        Mousetrap.bind("alt+shift+s", () => history.push('/dashboard/sales/invoice/add'))


    }, [history])
    // const {data} = useSWR("report/monthlyVatReport");



    return (

        <div>

            <div style={{position: 'relative'}}>
                {/*<AnimatePresence exitBeforeEnter initial={false}>*/}
                <Switch>

                    <Redirect to={'/login'} path={'/'} exact/>
                    <Redirect path={'/dashboard'} to={'/dashboard/home/'} exact/>

                    <PublicRoute path="/login">
                        <LoginPage/>
                    </PublicRoute>

                    <Route path="/sign-up" exact>
                        <SignUpPage/>
                    </Route>

                    <PrivateRoute path="/dashboard" exact>
                        <Dashboard/>
                    </PrivateRoute>

                    <PrivateRoute path="/dashboard/*" exact>
                        <Dashboard/>
                    </PrivateRoute>

                    <Route path="*">
                        <div>
                            404 not found
                        </div>
                    </Route>
                </Switch>
            </div>
            {/*</AnimatePresence>*/}


        </div>
    );
};



// @ts-ignore
function PrivateRoute({children, ...rest}) {

    return (
        <Route
            {...rest}
            render={({location}) =>
                localStorage.getItem('token') ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

// @ts-ignore
function PublicRoute({children, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) =>
                localStorage.getItem('token') ? (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: {from: location}
                        }}
                    />
                ) : (
                    children
                )
            }
        />
    );
}

export default MainRoute;
