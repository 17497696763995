import React, {useEffect, useRef, useState} from 'react';
import useSWR from "swr";
import {convertAdToBs, getTodaysDate} from "../../../../../config/dateFunctions";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {Collapse, Divider, Grid} from "@material-ui/core";
import BarChart from "../../../organisms/barChart";
import ListView from "../../../organisms/ListView";
import CategoryReport from "../../../organisms/categoryReportDrill";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentDate, getDiffdate, getFormattedDate} from "../../../../../utils/dateFunction";
import {Field, Form, Formik} from "formik";
import DateComponent from "../../../../product/molecules/dateComponent";
import Button from "../../../../shared/atom/button";
import SalesChart from "./SalesChart";
import SetupFileUpload from "../../../organisms/setupFileUpload";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {RootState} from "../../../../../store/RootReducer";
import {getFirstFiscalDate} from "../../../../../config/fiscalYear";
import InvoiceTable from "../../../../sales/organisms/tables/invoiceTable";
import {useReactToPrint} from "react-to-print";
import axiosInstance from "../../../../../config/axiosConfig";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../../product/molecules/errorLabel";

const SalesTemplate = () => {

    const ref: any = useRef();
    const [height, setHeight] = useState(400)

    const initialState = {
        transactionYearAdFrom: null,
        transactionMonthAdFrom: null,
        transactionDateAdFrom: null,
        transactionYearBsFrom: null,
        transactionMonthBsFrom: null,
        transactionDateBsFrom: null,
        transactionYearAdTill: null,
        transactionMonthAdTill: null,
        transactionDateAdTill: null,
        transactionYearBsTill: null,
        transactionMonthBsTill: null,
        transactionDateBsTill: null,
        billType: "All"
    }

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();


    const [formState, setFormState] = useState<any>(initialState);
    const [fromDate, setFromDate] = useState("");
    const [tillDate, setTillDate] = useState("");
    const dashboard = useSelector((state: RootState) => state.dashboard);

    const [submitting, setSubmitting] = useState(false);
    const [formStateNew, setFormStateNew] = useState({search: ''})
    const [checked, setChecked] = useState<any>([]);

    useEffect(() => {

        // if(!location.pathname.includes('head')) return;
        // if (!localStorage.getItem('search')) return

        if(!nepEngDates) return;
        if(!formatedNepEngDates) return;

        let adDate = getFirstFiscalDate(nepEngDates.data, dashboard.fiscalYear);
        let bsDate: any = convertAdToBs(adDate.join('-'), formatedNepEngDates) || [];

        let adDateTill = getTodaysDate().split('-');
        let bsDateTill: any = convertAdToBs(getTodaysDate(), formatedNepEngDates) || [];

        setFromDate(getFormattedDate(adDate[0],adDate[1],adDate[2]));
        setTillDate(getFormattedDate(adDateTill[0],adDateTill[1],adDateTill[2]));

        // @ts-ignore
        let tempJson = {
            transactionYearAdFrom: adDate[0],
            transactionDateAdFrom: adDate[2],
            transactionMonthAdFrom: adDate[1],
            transactionDateBsFrom: bsDate[2],
            transactionMonthBsFrom: bsDate[1],
            transactionYearBsFrom: bsDate[0],
            transactionYearAdTill: adDateTill[0],
            transactionMonthAdTill: adDateTill[1],
            transactionDateAdTill: adDateTill[2],
            transactionYearBsTill: bsDateTill[0],
            transactionMonthBsTill: bsDateTill[1],
            transactionDateBsTill: bsDateTill[2],
            billType: "All"
        }

        setFormState((prevState: any) => {
            return {
                ...prevState,
                ...tempJson
            }
        })


    }, [nepEngDates, formatedNepEngDates])

    const getStartDate = (tillDays: number) => {
        let todaysDate = new Date();
        todaysDate.setDate(todaysDate.getDate() - tillDays + 1);
        return todaysDate.getFullYear() + '-' + ('0' + (todaysDate.getMonth() + 1)).slice(-2) + '-' + ('0' + todaysDate.getDate()).slice(-2);
    }

    const {data: report} = useSWR(`/report/invoiceReport?from=${fromDate}&to=${tillDate}`);



    useEffect(() => {
        if(ref?.current) setHeight(ref.current.clientHeight);
    })

    const {data: customerReceivable} = useSWR('/report/invoiceProductReport?from=&to=')

    const dispatch = useDispatch();
    const onSubmit = (values: any) => {
        console.log('clicked')
        setFormState(values);
        setSubmitting(true);
        setSubmitting(false);
        const {
            transactionYearAdFrom,
            transactionMonthAdFrom,
            transactionDateAdFrom,
            transactionDateAdTill,
            transactionMonthAdTill,
            transactionYearAdTill
        } = values;
        if (transactionYearAdFrom && transactionMonthAdFrom && transactionDateAdFrom && transactionDateAdTill && transactionMonthAdTill && transactionYearAdTill) {
            setFromDate(getFormattedDate(transactionYearAdFrom, transactionMonthAdFrom, transactionDateAdFrom));
            setTillDate(getFormattedDate(transactionYearAdTill, transactionMonthAdTill, transactionDateAdTill));
        } else {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `Please enter both from and till date`, type: "warning"}
            })
        }
    }

    const [hovered, setHovered] = useState(false);

    const handleClick = (name: number) => {


        let checkedList = [...checked];

        if (checked.includes(name)) {
            checkedList.splice(checkedList.indexOf(name), 1)

        } else {
            checkedList.push(name)
        }

        setChecked(checkedList);

    }

    const validationSchema = Yup.object({})

    const componentRef = useRef(null);
    const componentRefCanceled = useRef(null);
    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@page { size: auto; margin: 00mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 50px 40px 50px 40px !important; background-color: white } }'

    });

    const printClicked = async () => {

        if (handlePrint) {
            // let {data: printed} = await axiosInstance.get(`/printed/${Props.id}`)
            // console.log(printed)
            handlePrint()
        }
    }

    const handlePrintCanceled = useReactToPrint({
        content: () => componentRefCanceled.current,
        pageStyle: '@page { size: auto; margin: 00mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 50px 40px 50px 40px !important; background-color: white } }'

    });

    const printClickedCanceled = async () => {

        if (handlePrintCanceled) {
            // let {data: printed} = await axiosInstance.get(`/printed/${Props.id}`)
            // console.log(printed)
            handlePrintCanceled()
        }
    }

    return (
        <div style={{color: COLOR_PROFILE.primaryColor }}>


            <SetupFileUpload downloadPath={'/download/vatRegister'}
                             downloadText={"Download VAT Register"}
                             fileName={'VAT Register.xlsx'}
            />
            <Divider style={{marginBottom: 10, marginLeft: 18, marginTop: 10}}/>
            <Grid container>
                <Grid item  xs={12} sm={8}>
                    <div ref={ref} style={{padding: 20}}>
                        <SalesChart/>
                    </div>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <div>
                        <div  style={{fontWeight: 700 , margin: '20px 0 0 20px',  fontSize: 16,}}>
                            Services Sold <span style={{fontSize: 12, fontWeight: 500}}>(Lifetime)</span>
                        </div>
                    </div>
                    <Grid container xs={12} style={{padding: '15px 20px 60px', }} >
                        {ref && (
                            <Grid item xs={12} style={{paddingTop: 15, boxShadow: `0 0 10px 0px ${COLOR_PROFILE.backgroundDark}`, maxHeight: height -30 || undefined,  minWidth: '100%', padding: 0, overflow: 'auto'}}>
                                <ListView
                                    header={["Item", "Quantity"]}
                                    data={customerReceivable?.t ? Object.keys(customerReceivable.t).map((item: any) => {
                                        console.log("item" , item);
                                        return { key: item,value: customerReceivable.t[item]}
                                    }): []}/>

                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Divider style={{margin: 20}}/>

            <div style={{marginLeft: 20}}>
                <Grid  item xs={12} style={{textAlign: 'center', fontWeight: 700, padding: 10, fontSize: 18}}>
                    <u>Sales Report</u>
                </Grid>
                <div style={{maxWidth: 1000, margin: "0 auto"}}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={formState}
                        // onSubmit={(values: any) => {}}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnMount={false}
                        validateOnBlur={true}
                        onSubmit={onSubmit}
                    >
                        {formik => {
                            return (
                                <Form style={{marginBottom: 20, paddingTop: 10, marginRight: 0}}>
                                    <Grid  container xs={12} justify={"space-between"} alignItems={"center"} spacing={2}>
                                        <Grid item container xs={5} justify={"flex-end"}>
                                            {/*From*/}
                                            <Grid item container alignItems={"center"} xs={2} style={{fontWeight: 700}}>
                                                From
                                            </Grid>
                                            <Grid item container xs={10}>
                                                <DateComponent
                                                    ad={['transactionYearAdFrom', 'transactionMonthAdFrom', 'transactionDateAdFrom']}
                                                    bs={['transactionYearBsFrom', 'transactionMonthBsFrom', 'transactionDateBsFrom']}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs={5} justify={"flex-end"}>
                                            {/*Till*/}
                                            <Grid item xs={2} container justify={"center"} alignItems={"center"}
                                                  style={{fontWeight: 700}}>
                                                Till
                                            </Grid>
                                            <Grid item container xs={10}>
                                                <DateComponent
                                                    ad={['transactionYearAdTill', 'transactionMonthAdTill', 'transactionDateAdTill']}
                                                    bs={['transactionYearBsTill', 'transactionMonthBsTill', 'transactionDateBsTill']}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs={2} style={{paddingTop: 20}}>

                                            <Grid item xs={5} container justify={"center"} alignItems={"center"}
                                                  style={{fontWeight: 700, paddingRight: 2}}>
                                                Bill Type
                                            </Grid>
                                            <Grid item xs={7}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'billType'}>
                                                        {(props: { field: any; form: any; meta: any; }) => {
                                                            const {field, form, meta} = props;
                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'billType'} {...field}
                                                                                       size={"small"}
                                                                                       fullWidth={true}
                                                                                       disableClearable={true}
                                                                                       autoHighlight={true}
                                                                                       autoSelect={true}
                                                                                       onChange={(e, value) => {
                                                                                           form.setFieldValue('billType', value)
                                                                                       }}

                                                                                       options={['Active', 'Inactive', 'All']}
                                                                                       getOptionLabel={option => option}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>
                                                            )
                                                        }}
                                                    </Field>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid item container justify={"center"} xs={12}>
                                            <div style={{minWidth: 70, marginBottom: -20}}>
                                                <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                        textColor={'white'}
                                                        type={'submit'}
                                                >
                                                    <span style={{fontSize: 12}}>Apply</span>
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Form>
                            )
                        }}
                    </Formik>
                </div>

                <div style={{paddingBottom: checked.length > 0 ? 40: undefined}} ref={componentRef}>

                    <InvoiceTable handleClick={handleClick} checked={checked} formState={formState}
                                  report={true}
                                  setSubmitting={setSubmitting} submitting={submitting}
                    />
                </div>



            </div>
        </div>
    );
}

export default SalesTemplate;
