import React from 'react';
import PurchaseBillAddition from "../../templates/purchaseBillAddition";

const PurchaseBillAdd = () => {
    return (
        <div>
            <PurchaseBillAddition/>
        </div>
    );
};

export default PurchaseBillAdd;