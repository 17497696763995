import React, {useEffect, useRef, useState} from 'react';
import useSWR from "swr";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {Divider, Grid} from "@material-ui/core";
import SalesChart from "../salesTemplate/SalesChart";
import ListView from "../../../organisms/ListView";
import ReturnChart from "./ReturnChart";
import {convertAdToBs, getTodaysDate} from "../../../../../config/dateFunctions";
import {Form, Formik} from "formik";
import DateComponent from "../../../../product/molecules/dateComponent";
import Button from "../../../../shared/atom/button";
import {useDispatch, useSelector} from "react-redux";
import {getFormattedDate} from "../../../../../utils/dateFunction";
import * as Yup from "yup";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {getFirstFiscalDate} from "../../../../../config/fiscalYear";
import {RootState} from "../../../../../store/RootReducer";
import InvoiceTable from "../../../../sales/organisms/tables/invoiceTable";
import ReturnTable from "../../../../sales/organisms/tables/returnTable";
import {useReactToPrint} from "react-to-print";

const SalesReturnTemplate = () => {
    const ref: any = useRef();
    const [height, setHeight] = useState(400)

    const initialState = {
        transactionYearAdFrom: null,
        transactionMonthAdFrom: null,
        transactionDateAdFrom: null,
        transactionYearBsFrom: null,
        transactionMonthBsFrom: null,
        transactionDateBsFrom: null,
        transactionYearAdTill: null,
        transactionMonthAdTill: null,
        transactionDateAdTill: null,
        transactionYearBsTill: null,
        transactionMonthBsTill: null,
        transactionDateBsTill: null,
    }

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();


    const [formState, setFormState] = useState<any>(initialState);
    const [fromDate, setFromDate] = useState("");
    const [tillDate, setTillDate] = useState("");
    const dashboard = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {

        // if(!location.pathname.includes('head')) return;
        // if (!localStorage.getItem('search')) return

        if(!nepEngDates) return;
        if(!formatedNepEngDates) return;

        let adDate = getFirstFiscalDate(nepEngDates.data, dashboard.fiscalYear);
        let bsDate: any = convertAdToBs(adDate.join('-'), formatedNepEngDates) || [];

        let adDateTill = getTodaysDate().split('-');
        let bsDateTill: any = convertAdToBs(getTodaysDate(), formatedNepEngDates) || [];

        setFromDate(getFormattedDate(adDate[0],adDate[1],adDate[2]));
        setTillDate(getFormattedDate(adDateTill[0],adDateTill[1],adDateTill[2]));

        // @ts-ignore
        let tempJson = {
            transactionYearAdFrom: adDate[0],
            transactionDateAdFrom: adDate[2],
            transactionMonthAdFrom: adDate[1],
            transactionDateBsFrom: bsDate[2],
            transactionMonthBsFrom: bsDate[1],
            transactionYearBsFrom: bsDate[0],
            transactionYearAdTill: adDateTill[0],
            transactionMonthAdTill: adDateTill[1],
            transactionDateAdTill: adDateTill[2],
            transactionYearBsTill: bsDateTill[0],
            transactionMonthBsTill: bsDateTill[1],
            transactionDateBsTill: bsDateTill[2],
        }

        setFormState((prevState: any) => {
            return {
                ...prevState,
                ...tempJson
            }
        })


    }, [nepEngDates, formatedNepEngDates])

    const getStartDate = (tillDays: number) => {
        let todaysDate = new Date();
        todaysDate.setDate(todaysDate.getDate() - tillDays + 1);
        return todaysDate.getFullYear() + '-' + ('0' + (todaysDate.getMonth() + 1)).slice(-2) + '-' + ('0' + todaysDate.getDate()).slice(-2);
    }

    const {data: report} = useSWR(`/report/salesReturnReport?from=${fromDate}&to=${tillDate}`);


    useEffect(() => {
        if(ref?.current) setHeight(ref.current.clientHeight);
    })

    const {data: customerReceivable} = useSWR('/report/salesReturnProductReport?from=&to=')

    const dispatch = useDispatch();
    const onSubmit = (values: any) => {

        setSubmitting(true);

        const {
            transactionYearAdFrom,
            transactionMonthAdFrom,
            transactionDateAdFrom,
            transactionDateAdTill,
            transactionMonthAdTill,
            transactionYearAdTill
        } = values;
        if (transactionYearAdFrom && transactionMonthAdFrom && transactionDateAdFrom && transactionDateAdTill && transactionMonthAdTill && transactionYearAdTill) {
            setFromDate(getFormattedDate(transactionYearAdFrom, transactionMonthAdFrom, transactionDateAdFrom));
            setTillDate(getFormattedDate(transactionYearAdTill, transactionMonthAdTill, transactionDateAdTill));
        } else {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `Please enter both from and till date`, type: "warning"}
            })
        }

        setSubmitting(false);
    }

    const componentRef = useRef(null);
    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@page { size: auto; margin: 00mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 50px 40px 50px 40px !important; background-color: white } }'

    });

    const printClicked = async () => {

        if (handlePrint) {
            // let {data: printed} = await axiosInstance.get(`/printed/${Props.id}`)
            // console.log(printed)
            handlePrint()
        }
    }
    const [submitting, setSubmitting] = useState(false);


    const [hovered, setHovered] = useState(false);

    const validationSchema = Yup.object({})


    return (
        <div style={{color: COLOR_PROFILE.primaryColor }}>
            <Grid container>
                <Grid item  xs={12} sm={8}>
                    <div ref={ref} style={{padding: 20}}>
                        <ReturnChart/>
                    </div>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <div>
                        <div  style={{fontWeight: 700 , margin: '20px 0 0 20px',  fontSize: 16,}}>
                            Services Returned <span style={{fontSize: 12, fontWeight: 500}}>(Lifetime)</span>
                        </div>
                    </div>
                    <Grid container xs={12} style={{padding: '15px 20px 60px', }} >
                        {ref && (
                            <Grid item xs={12} style={{paddingTop: 15, boxShadow: `0 0 10px 0px ${COLOR_PROFILE.backgroundDark}`, maxHeight: height -30 || undefined,  minWidth: '100%', padding: 0, overflow: 'auto'}}>
                                <ListView
                                    header={["Item", "Quantity"]}
                                    data={customerReceivable?.t ? Object.keys(customerReceivable.t).map((item: any) => {
                                        console.log("item" , item);
                                        return { key: item,value: customerReceivable.t[item]}
                                    }): []}/>

                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Divider style={{margin: 20}}/>

            <div style={{marginLeft: 20}}>

                <div style={{maxWidth: 800, margin: "0 auto"}}>

                    <Grid  item xs={12} style={{textAlign: 'center', fontWeight: 700, padding: '5px 5px 20px 5px', fontSize: 18}}>
                        <u>Return Report</u>
                    </Grid>

                <Grid item container xs={12} alignItems={"center"}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={formState}
                        // onSubmit={(values: any) => {}}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnMount={false}
                        validateOnBlur={true}
                        onSubmit={onSubmit}
                    >
                        {formik => {
                            return (
                                <Form style={{marginBottom: 20, paddingTop: 10}}>
                                    <Grid  container  justify={"center"} spacing={2}>
                                        <Grid item container xs={6} >
                                            {/*From*/}
                                            <Grid item container alignItems={"center"} xs={2} style={{fontWeight: 700}}>
                                                From
                                            </Grid>
                                            <Grid item container xs={10}>
                                                <DateComponent
                                                    ad={['transactionYearAdFrom', 'transactionMonthAdFrom', 'transactionDateAdFrom']}
                                                    bs={['transactionYearBsFrom', 'transactionMonthBsFrom', 'transactionDateBsFrom']}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs={6} >
                                            {/*Till*/}
                                            <Grid item xs={2} container justify={"center"} alignItems={"center"}
                                                  style={{fontWeight: 700}}>
                                                Till
                                            </Grid>
                                            <Grid item container xs={10}>
                                                <DateComponent
                                                    ad={['transactionYearAdTill', 'transactionMonthAdTill', 'transactionDateAdTill']}
                                                    bs={['transactionYearBsTill', 'transactionMonthBsTill', 'transactionDateBsTill']}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} container justify={"center"}>
                                            <div style={{minWidth: 70, marginBottom: -20}}>
                                                <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                        textColor={'white'}
                                                        type={'submit'}
                                                >
                                                    <span style={{fontSize: 12}}>Apply</span>
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Form>
                            )
                        }}
                    </Formik>
                </Grid>
                </div>
                <div style={{paddingBottom:  undefined}} ref={componentRef}>

                    <ReturnTable formState={formState} report={true}
                                 setSubmitting={setSubmitting} submitting={submitting}
                    />
                </div>

            </div>

        </div>
    );
};

export default SalesReturnTemplate;
