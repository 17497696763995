import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {

        try{
            if (isSubmitting && !isValidating) {
                let keys = Object.keys(errors);
                if (keys.length > 0) {
                    const errorElement = document.getElementById(keys[0]) as HTMLElement;
                    if (errorElement) {
                        errorElement.focus();
                    }
                }
            }
        }catch (e) {

        }

    }, [errors, isSubmitting, isValidating]);

    return null;
};

export default FocusError;