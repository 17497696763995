import React, {useState} from 'react';
import AccessFilter from "../../../../accessControl/accessFilter";
import SetupAddNew from "../../../accounting/organisms/setupAddNew";
import ProductFilter from "../../organisms/form/filter";
import InventoryAdjustmentTable from "../../organisms/tables/inventoryAdjustmentTable";

const InventoryAdjustmentHome: React.FC<any> = (Props) => {

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})

    return (
        <div>
            <AccessFilter
                section={"inventoryAdjustment"}
                privilege={'write'}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>
                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/inventory/inventory-adjustment/add',
                                            name: 'Adjust Inventory'
                                        }}


                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>Adjust Inventory by clicking the button below
                                                </div>

                                            </div>
                                        }

                                    />
                                </div>

                            </div>
                        </div>
                    )

                }}

            />

            <div style={{maxWidth: 1000}}>
                <div style={{padding: '0 30px 0 28px'}}>

                    <div style={{marginBottom: -10}}>
                        <ProductFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                       submitting={submitting} from={'adjustment'}/>
                    </div>


                    <InventoryAdjustmentTable formState={formState} setSubmitting={setSubmitting}/>
                </div>
            </div>
        </div>
    );
};

export default InventoryAdjustmentHome;