import React from 'react';
import SetupAddNew from "../../../organisms/setupAddNew";
import TableFeader from "../../../organisms/reactTable/TableFeader";
import SetupFileUpload from "../../../organisms/setupFileUpload";
import AccessDenied from "../../../templates/errorTemplates/accessDenied";
import AccessFilter from "../../../../../accessControl/accessFilter";

const ShareHolderPage: React.FC = () => {
    return (
        <div style={{maxWidth: 1000}}>

            <AccessFilter
                section={"shareHolders"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>

                            <div>

                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/company-setup/share-holders/add',
                                            name: 'Add Share Holder'
                                        }}
                                        // buttonNameImport={{pathTo: '', name: 'Import Vendors from Head Office'}}

                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                // maxWidth: 750,
                                                // margin: '0 auto',
                                                // textAlign: 'center',
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>Add shareholder by pressing the button below.
                                                </div>
                                                {/*<div style={{fontSize: 14, fontWeight: 500}}>Something some thing</div>*/}
                                            </div>
                                        }

                                    />
                                </div>

                                <div style={{width: 900, paddingLeft: 15, paddingBottom: 10}}>
                                    <SetupFileUpload/>
                                </div>

                            </div>

                        </div>

                    )

                }}

            />


            <AccessFilter
                section={"shareHolders"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>

                            <div>

                                <div style={{padding: '0 30px 0 28px'}}>
                                    <TableFeader/>
                                </div>

                            </div>

                        </div>

                    )

                }}

            />


        </div>
    );
};

export default ShareHolderPage;
