import React from 'react';
import {Chart, ChartProps} from "react-chartjs-2";
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title , CategoryScale, BarElement, BarController, Tooltip} from 'chart.js';
ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarElement,BarController, Tooltip );

// {
//     datasets: [{
//         barPercentage: 0.9,
//         barThickness: 20,
//         maxBarThickness: 30,
//         minBarLength: 2,
//         data: [10, 20, 30, 40, 50, 60, 70]
//     }],
//         labels: [1,2,3,4,5,6,7]
// }

const CustomChart : React.FC <ChartProps>= (Props) => {


    return (
        <div>

            <Chart data={Props.data} options={Props.options}  type={Props.type}/>

        </div>
    );
};

export default CustomChart;
