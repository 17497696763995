import React, {useEffect, useState} from 'react';
import {Route, useHistory, useParams} from "react-router-dom";
import useSWR from "swr";
import FromSubTypes from "../../../templates/reporting/fromSubTypes";
import {useDispatch, useSelector} from "react-redux";
import GeneralLedgerFilterForm from "../../../organisms/Forms/generalLedger";
import {RootState} from "../../../../../store/RootReducer";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import ReportActionButton from "../../../organisms/reportActionButton";
import {Grid} from "@material-ui/core";

const BalanceSheet: React.FC = () => {

    // @ts-ignore
    const {id} = useParams();

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [branch, setBranch] = useState(null);

    const [formState, setFormState] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const history = useHistory();
    let balance = 0;
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch({type: "REMOVE_REPORT_FILTER"})
        }
    }, [])


    return (
        <div>

            <div style={{maxWidth: 1000}}>

                <div
                    style={{
                        padding: '10px 0 0 20px',
                        fontWeight: 700,
                        fontSize: 18,
                        color: COLOR_PROFILE.primaryColor
                    }}
                >
                    Balance Sheet
                </div>
                <div>

                    <GeneralLedgerFilterForm
                        from={'groupSummary'}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        branch={branch}
                        setBranch={setBranch}
                        submitting={submitting}
                        setFormState={setFormState}
                        setSubmitting={setSubmitting}
                    />


                </div>

                <div>

                    <Route path={'/dashboard/reports/balance-sheet/table/:id'} exact>
                        <div>
                            <BalanceSheetTable startDate={startDate} endDate={endDate} branch={branch}
                                               setSubmitting={setSubmitting}

                            />
                        </div>
                    </Route>
                </div>
            </div>
        </div>
    );


};

const BalanceSheetTable: React.FC<any> = (Props) => {

    // @ts-ignore
    const {id} = useParams();

    const filterFormData = useSelector((state: RootState) => state.reportFilterReducer);

    const [fromDate, setFromDate] = useState(null);
    const [branchId, setBranchId] = useState(null);
    const [tillDate, setTillDate] = useState(null);
    const [total, setTotal] = useState('');
    const [tempJson, setTempJson] = useState([{
        name: 'Profit/ Loss',
        balance: 0,
        id: 0,
        nonClickable: true
    }])

    useEffect(() => {
        // @ts-ignore
        setFromDate((filterFormData.transactionYearAdFrom && filterFormData.transactionMonthAdFrom && filterFormData.transactionDateAdFrom) ? filterFormData.transactionYearAdFrom + '-' + ('0' + filterFormData.transactionMonthAdFrom).slice(-2) + '-' + ('0' + filterFormData.transactionDateAdFrom).slice(-2) : Props.startDate);
        // @ts-ignore
        setTillDate((filterFormData.transactionYearAdTill && filterFormData.transactionMonthAdTill && filterFormData.transactionDateAdTill) ? filterFormData.transactionYearAdTill + '-' + ('0' + filterFormData.transactionMonthAdTill).slice(-2) + '-' + ('0' + filterFormData.transactionDateAdTill).slice(-2) : Props.endDate);
        setBranchId(Props.branch ? Props.branch.id : null);

        Props.setSubmitting(true)

    }, [Props.endDate, Props.startDate, Props.branch])

    const {data: getData} = useSWR((fromDate && tillDate) ? id === 'home' ?
        `/report/getAccountTypesAndSubTypes?from=${fromDate || ''}&to=${tillDate || ''}&name=balanceSheet&branchId=${branchId}` :
        id.includes('head') ? `/report/getBalance?from=${fromDate || ''}&to=${tillDate || ''}&accHeadId=${id.split('=')[1]}&branchId=${branchId}` :
            id.includes('type') ? `/report/getAccountSubTypesGroups?from=${fromDate || ''}&to=${tillDate || ''}&accSubTypeId=${id.split('=')[1]}&branchId=${branchId}` :
                `/report/getAccountGroupAndBalance?from=${fromDate || ''}&to=${tillDate || ''}&id=${id}&branchId=${branchId}`
        : null
    )

    useEffect(() => {
        if (!getData) return;
        Props.setSubmitting(false)

    }, [getData])

    useEffect(() => {

        if (id !== 'home') return;
        if (!getData) return;

        try {
            let prevState = tempJson;
            prevState[0].name = getData.data2[0]?.value > 0 ? 'Profit' : 'Loss',
                prevState[0].balance = getData.data2[0]?.value;
            setTempJson(prevState);
        } catch (e) {

        }


        let val = 0;
        Object.keys(getData.t).map((item: any) => {

            val += getData.t[item];

            return item

        })

        setTotal(val > 0 ? Math.abs(val) + ' Dr' : Math.abs(val) + ' Cr');

    }, [getData])


    return (
        <div>
            {getData && (
                <div style={{paddingBottom: 50}}>

                    <FromSubTypes
                        from={'balance-sheet/table'}
                        withTwoHead={id === 'home'}
                        withOutHeadBackground={true}
                        getData={id === 'home' ? getData?.data.filter((item: any) => item.name === `Assets`)[0].accountSubTypes.filter((item: any) => !(item.balance === null)) : getData}
                        type={`Assets`}/>

                    {id === 'home' && (
                        <>
                            <FromSubTypes
                                withOutHeadBackground={true}
                                from={'balance-sheet/table'}
                                getData={getData?.data.filter((item: any) => item.name === `Equity`)[0].accountSubTypes.filter((item: any) => !(item.balance === null))}
                                type={`Equity`}
                            />

                            <FromSubTypes
                                from={'balance-sheet/table'}
                                withOutHeadBackground={true}
                                getData={[...getData?.data.filter((item: any) => item.name === 'Liabilities')[0].accountSubTypes.filter((item: any) => !(item.balance === null)), ...tempJson]}
                                type={'Liabilities'}
                            />

                            <Grid container
                                  style={{
                                      color: COLOR_PROFILE.primaryColor,
                                      fontSize: 16, fontWeight: 600,
                                      paddingRight: 25,
                                      paddingTop: 10,

                                  }}
                            >
                                <Grid item xs={1} style={{minWidth: 42}}>
                                </Grid>

                                <Grid item xs={7}>
                                    Total (Liabilities/ Equity)
                                </Grid>

                                <Grid item xs={3} style={{marginLeft: 35}}>
                                    {Math.abs(getData?.data.filter((item: any) => item.name === 'Liabilities')[0].balance + getData?.data.filter((item: any) => item.name === 'Equity')[0].balance + getData?.data2[0].value)}
                                </Grid>


                            </Grid>

                        </>
                    )}

                    <div>
                        <ReportActionButton/>
                    </div>

                </div>

            )}
        </div>
    )
};

export default BalanceSheet;
