import React from 'react';
import AccessFilter from "../../../../accessControl/accessFilter";
import SetupAddNew from "../../../accounting/organisms/setupAddNew";
import ProductFilter from "../../organisms/form/filter";
import ProductOfferTable from "../../organisms/tables/productOfferTable";
import UomTable from "../../organisms/tables/uomTable";

const UomHome = () => {
    return (
        <div>
            <AccessFilter

                section={"uom"}
                privilege={'write'}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>


                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/product/uom/add',
                                            name: 'Add Unit of Measure'
                                        }}


                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>Add Unit of Measure by clicking the button below
                                                </div>

                                            </div>
                                        }

                                    />
                                </div>

                            </div>
                        </div>
                    )

                }}

            />

            <div style={{maxWidth: 1000}}>
                <div style={{padding: '0 30px 0 28px'}}>
                    <UomTable />
                </div>
            </div>
        </div>
    );
};

export default UomHome;