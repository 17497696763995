import React, {useEffect, useMemo, useRef, useState} from 'react';
import GeneralLedgerFilterForm from "../../../organisms/Forms/generalLedger";
import DividerComponent from "../../../../shared/atom/divider";
import ReportTable from "../../../organisms/tables/reportTable";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../store/RootReducer";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import Grid from "@material-ui/core/Grid";
import {useReactToPrint} from "react-to-print";
import ReportActionButton from "../../../organisms/reportActionButton";


const GeneralLedger: React.FC = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch({type: "REMOVE_LEDGER"})
        }
    }, [])


    const branchColumns = [

        {
            id: 'id',
            Header: 'SN.',
            accessor: 'id',
            width: 10,
            Cell: (item: any) => {

                const {row, parentIndex} = item;
                let id = row.id.split('.').map((item: string) => parseInt(item) + 1);
                return (
                    <span style={{color: COLOR_PROFILE.primaryColor}}>{id.join('.')}</span>
                )

            }
        },
        {
            Header: 'Voucher Number',
            accessor: 'voucherNumber'
        },
        {
            Header: 'Transaction Date',
            accessor: 'transactionDate',
            Cell: (val: any) => {

                const {row: {original}} = val
                return (
                    <div>
                        {original.transactionDate.split('T')[0]}
                    </div>
                )

            }
        },
        {
            Header: 'Against',
            accessor: 'headAgainst',

        },

        {
            Header: 'Debit',
            accessor: 'debit',
            alignment: 'right',
            Cell: (val: any) => {

                const {row: {original: {journalRows}}} = val
                balance = balance - journalRows.amount;

                return (
                    <div style={{textAlign: 'right'}}>
                        {journalRows.type === 'debit' ? journalRows.amount : 0.00}
                    </div>
                )

            }
        },

        {
            Header: 'Credit',
            accessor: 'credit',
            alignment: 'right',

            Cell: (val: any) => {

                const {row: {original: {journalRows}}} = val
                balance = balance + journalRows.amount;
                return (
                    <div style={{textAlign: 'right'}}>
                        {journalRows.type === 'credit' ? journalRows.amount : 0.00}
                    </div>
                )

            }
        },
        {
            Header: 'Balance',
            accessor: 'balance',
            alignment: 'right',
            Cell: (val: any, other: any) => {

                let balance = 0;
                const getBalance = () => {

                    try {
                        const {row: {index, allCells}} = val

                        let tempVal = [...mockData.slice(0, index + 1)];
                        balance = [...tempVal].reduce((result: number, completelyNew: any) => {
                            const newItem = completelyNew.journalRows
                            let toReturn = newItem.type === 'credit' ? (parseFloat(result.toString()) - parseFloat(newItem.amount)) : (parseFloat(result.toString()) + parseFloat(newItem.amount))
                            return toReturn.toFixed(2)
                        }, 0.00)

                    } catch (e) {

                    }

                    return Math.abs(balance) + ' ' + (balance < 0 ? 'Cr' : balance > 0 ? 'Dr' : '');
                }

                return (
                    <div style={{textAlign: 'right'}}>
                        {getBalance()}
                    </div>
                )

            }
        },


    ]

    let balance = 0;
    const ledgerData = useSelector((state: RootState) => state.ledgerData);

    // @ts-ignore
    const calledData = ledgerData ? ledgerData : [];
    const mockData = useMemo(() => calledData, [ledgerData]);
    const columns = useMemo(() => branchColumns, [mockData,]);
    const [isRowLoading, setIsRowLoading] = useState([]);

    const componentRef = useRef(null);

    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    return (
        <div>

            <div style={{maxWidth: 1000}}>

                <Grid container justify={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <div style={{
                            padding: '10px 0 0 20px',
                            fontWeight: 700,
                            fontSize: 18,
                            color: COLOR_PROFILE.primaryColor
                        }}>
                            General Ledger
                        </div>
                    </Grid>
                    <Grid item style={{paddingRight: 20}}>
                        {/*<IconButton onClick={handlePrint} disabled={calledData.length === 0}><PrintIcon*/}
                        {/*    style={{color: COLOR_PROFILE.primaryColor}}/></IconButton>*/}
                    </Grid>
                </Grid>


                <div style={{display: 'none'}}>
                    <div ref={componentRef}>
                        <div style={{padding: 20, textAlign: 'center'}}>
                            <h2>
                                General Ledger
                            </h2>
                            <div style={{textAlign: 'left'}}>

                            </div>
                        </div>
                        <div style={{display: 'block', overflow: 'visible', height: 'auto', width:'auto'}}>
                            <ReportTable columns={columns} data={mockData}/>
                        </div>
                        <div style={{padding: '80px 20px 20px 20px', maxWidth: 700, margin: '0 auto'}}>
                            <Grid container alignItems={"center"} justify={"space-between"}>
                                <Grid item style={{textAlign: 'center'}}>
                                    <div style={{borderTop: `1px solid ${COLOR_PROFILE.primaryColor}`, width: 100}}>

                                    </div>
                                    <div>
                                        Printed By
                                    </div>
                                </Grid>
                                <Grid item style={{textAlign: 'center'}}>
                                    <div style={{borderTop: `1px solid ${COLOR_PROFILE.primaryColor}`, width: 100}}>

                                    </div>
                                    <div>
                                        Checked By
                                    </div>
                                </Grid>
                                <Grid item style={{textAlign: 'center'}}>
                                    <div style={{borderTop: `1px solid ${COLOR_PROFILE.primaryColor}`, width: 100}}>

                                    </div>
                                    <div>
                                        Approved By
                                    </div>
                                </Grid>
                            </Grid>


                        </div>
                    </div>
                </div>


                <GeneralLedgerFilterForm from={'generalLedger'}/>

                <DividerComponent/>

                <div>
                    {ledgerData.length > 0 && (
                        <div style={{paddingBottom: 50}}>
                            <ReportTable columns={columns} data={mockData}
                                         isRowLoading={isRowLoading}
                            />
                            <div>
                                <ReportActionButton/>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default GeneralLedger;
