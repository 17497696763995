import React, {useEffect, useState} from 'react';
import useSWR, {trigger} from "swr";
import * as Yup from "yup";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik} from "formik";
import {Grid} from "@material-ui/core";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import InputField from "../../../../shared/atom/formElements/inputField";
import FormSaveButton from "../../FormSaveButton";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import RequiredNotation from "../../../molecules/requiredNotation";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {

    from: string
    mode: 'edit' | 'create',
    id?: string | null

}


const AccountGroupForm: React.FC<Props> = (Props) => {

    const initialValues = {
        name: '',
        accountSubType: null,
        description: '',
        code: '',
    }

    const {data: accountTypeOptions} = useSWR('/getAllAccountSubTypesAndGroups');

    const location = useLocation();

    useEffect(() => {
        if (!accountTypeOptions) return;
        if (Props.id) {
            console.log(location.state)

            // @ts-ignore
            const {name, accountSubType, description, code, parent} = location.state

            let typeOrGroup;

            if(parent){
                typeOrGroup = [...accountTypeOptions.data2].find((item: any) => item.id === parent)
            }else{
                typeOrGroup = [...accountTypeOptions.data].find((item: any) => item.id === accountSubType.id)

            }


            setFormState({
                name: name,
                code: code,
                accountSubType: typeOrGroup,
                description: description
            })

        }

    }, [accountTypeOptions])


    const [formState, setFormState] = useState(initialValues)


    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        code: Yup.string().required('Required'),
        accountSubType: Yup.object().required('Required').nullable(true)
    })


    const history = useHistory();

    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        const {name: accSubName, type, code, id, account_sub_type_id} = values.accountSubType

        try {

            let payload = {
                name: values.name,
                code: values.code,
                accountSubType: type === 'account_sub_type' ? {id: id} : {id: account_sub_type_id},
                parent: type === 'account_group' ? id : null,
                description: values.description
            }

            if (Props.id) {

                let combinedTypeAndGroup = [...accountTypeOptions?.data, ...accountTypeOptions?.data2];
                let selectedAccount = combinedTypeAndGroup.filter((item: any) => (item.code === values.code) && (item.id === parseInt(Props.id as string)))[0]

                // @ts-ignore
                const { parent} = location.state
                console.log(values.accountSubType)
                console.log('payload', payload)
                console.log('code, id', values.code + Props.id)
                console.log(location.state)
                console.log('selected ', combinedTypeAndGroup.filter((item: any) => (item.code === values.code) && (item.id === parseInt(Props.id as string))))



                payload = {
                    ...payload,
                    accountSubType: parent !== null ?  {id: selectedAccount.account_sub_type_id} : {id: selectedAccount.account_sub_type_id},
                    parent: type === 'account_group' ? id : null,
                }

                // @ts-ignore
                let childArr = location.state.child.length > 0 ? location.state.child.map((item: any) => {
                    return {
                        id: item.id
                    }
                }) : null

                // @ts-ignore
                const {data} = await axiosInstance.put('/updateAccountGroup', {...payload, id: parseInt(Props.id), child: childArr})
                responseProcess(data, formActions, isReset)

            } else {

                const {data} = await axiosInstance.post('/addAccountGroup', payload)
                responseProcess(data, formActions, isReset)

            }

        } catch (e) {
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }

        formActions.setSubmitting(false)

    }

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {

            trigger('/getAllAccountSubTypesAndGroups')
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) history.goBack()

        } else {

            handleResponse(data.message, 'warning')

        }
    }


    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    const size = Props.from === 'journal page' ? 12 : 6;

    const handleGetCode = async (value: any, form: any) => {

        if(Props.id) return;

        if(!value){
            form.setFieldValue("code", '')
            return
        }

        const {name: accSubName, type, code, id, account_sub_type_id} = value;
        const {data} = await axiosInstance.get(`/getCode?name=accountGroup&id=${type === 'account_sub_type' ? id: account_sub_type_id}`)
        form.setFieldValue("code", data.t.code)

    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>


            <Formik

                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {

                    return (
                        <div>

                            <PagePrompt formik={formik}/>

                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>
                                <Grid container justify={"space-between"}>

                                    <Grid container item sm={size} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="accountSubType"
                                                   style={{fontWeight: "bold", fontSize: 14}}> Account
                                                Type:<RequiredNotation/></label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'accountSubType'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'accountSubType'} {...field}
                                                                                       size={"small"}

                                                                                       onChange={(e, value) => {
                                                                                           if(value) handleGetCode(value,form);
                                                                                           form.setFieldValue("accountSubType", value)
                                                                                       }}
                                                                                       options={accountTypeOptions ? [...accountTypeOptions.data, ...accountTypeOptions.data2] : []}
                                                                                       loading={!accountTypeOptions}
                                                                                       getOptionLabel={option => option.name}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid container item sm={size} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="name" style={{fontWeight: "bold", fontSize: 14}}> Name:<RequiredNotation/></label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'name'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;


                                                        return (
                                                            <>
                                                                <InputField type={'text'} id={'name'} {...field}
                                                                            size={"small"}
                                                                            fullWidth={true}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>

                                <Grid container item sm={size} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="code" style={{fontWeight: "bold", fontSize: 14}}> Code:<RequiredNotation/></label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'code'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;


                                                        return (
                                                            <>
                                                                <InputField type={'text'} id={'code'} {...field}
                                                                            size={"small"}
                                                                            fullWidth={true}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>

                                <Grid container item sm={size} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="description"
                                               style={{fontWeight: "bold", fontSize: 14}}> Description:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'description'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;


                                                        return (
                                                            <>
                                                                <InputField type={'text'} id={'description'} {...field}
                                                                            size={"small"}
                                                                            multiline={true}
                                                                            rows={3}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>

                                <FormSaveButton mode={Props.mode} from={'account-group'} submitting={formik.isSubmitting}/>


                            </Form>

                        </div>
                    )
                }}
            </Formik>
        </div>
    )
};

export default AccountGroupForm;
