import React from 'react';
import Tooltip, {TooltipProps} from '@material-ui/core/Tooltip';
import {Theme, withStyles} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../config/colorCode";


interface Props extends TooltipProps {



}

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: COLOR_PROFILE.buttonGreen,
        color: 'white',
        maxWidth: 220,
        paddingBottom: -5,
        fontSize: theme.typography.pxToRem(12),
        // border: '1px solid #dadde9',
    },
    tooltipArrow: {
        backgroundColor: COLOR_PROFILE.buttonGreen,
    },
    arrow: {
        color: COLOR_PROFILE.buttonGreen,
        marginBottom: 5
    }
}))(Tooltip);


const ToolTip: React.FC<Props> = ({open,title, children}) => {
    return (
        <HtmlTooltip
            arrow={true}
            placement="top"
            title={title}
        >
            {children}
        </HtmlTooltip>
    );
};

export default ToolTip;
