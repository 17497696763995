import React, {useState} from 'react';
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Grid, Popper} from '@material-ui/core';
import InputField from "../../../../shared/atom/formElements/inputField";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import useSWR from "swr";
import Button from "../../../../shared/atom/button";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import CircularSpinner from "../../../../shared/atom/spinner";
import SearchIcon from '@material-ui/icons/Search';
import {matchSorter} from "match-sorter";

const TableFilter: React.FC<any> = (Props) => {

    const initialValues = {
        search: '',
        accountType: null,
        group: null,
    }

    const [formState, setFormState] = useState(initialValues)


    const validationSchema = Yup.object({})

    const onSubmit = (values: any, formAction: any) => {

        Props.setFormState(values)

    };

    const {data: accountTypeOptions} = useSWR('/getAllAccountSubTypesAndGroups');
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');


    const withAccountType = ['journal', 'accountHead', 'receipt', 'payment'];
    const withAccountHead = ['journal', 'receipt', 'payment']
    const withSubType = ['journal', 'accountHead', 'receipt', 'payment', 'accountGroup']

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{width: 400}} placement='bottom-end'/>)
    }

    return (

        <div>


            <Formik

                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {

                    return (
                        <div style={{marginTop: 10}}>
                            <Form style={{marginLeft: 2}}>
                                <Grid container justify={"flex-start"} spacing={2}>

                                    <Grid item xs={Props.from === 'customer' ? 5: 3} alignItems={'center'}
                                          style={{marginBottom: 5}}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'search'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;

                                                        return (
                                                            <>
                                                                <InputField type={'text'} id={'search'} {...field}
                                                                            size={"small"}
                                                                            filterInput={true}
                                                                            placeholder={ withAccountHead.includes(Props.from) ? 'Code': Props.from === 'customer' ? "Search... (name, email, phone)":'Code/ Name'}
                                                                            fullWidth={true}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>
                                    </Grid>


                                    {withSubType.includes(Props.from) && (
                                    <Grid item xs={3} alignItems={'center'}
                                          style={{marginBottom: 5}}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'accountType'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;

                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={'accountType'} {...field}
                                                                                   size={"small"}
                                                                                   placeholder={'Sub Type'}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue("accountType", value)
                                                                                   }}
                                                                                   options={accountTypeOptions ? accountTypeOptions.data : []}
                                                                                   loading={!accountTypeOptions}
                                                                                   getOptionLabel={option => option.name}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>
                                    </Grid>
                                    )}

                                    {withAccountType.includes(Props.from) && (

                                        <Grid item xs={3} alignItems={'center'}
                                              style={{marginBottom: 5}}>

                                            <div style={{position: 'relative'}}>
                                                <Field name={'group'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'group'} {...field}
                                                                                       size={"small"}
                                                                                       placeholder={'Account Group'}
                                                                                       onChange={(e, value) => {
                                                                                           form.setFieldValue("group", value)
                                                                                       }}
                                                                                       options={accountTypeOptions ? accountTypeOptions.data2 : []}
                                                                                       loading={!accountTypeOptions}
                                                                                       getOptionLabel={option => option.name}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>
                                        </Grid>
                                    )}

                                    {withAccountHead.includes(Props.from) && (

                                        <Grid item xs={3} alignItems={'center'}
                                              style={{marginBottom: 5}}>

                                            <div style={{position: 'relative'}}>
                                                <Field name={'head'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'head'} {...field}
                                                                                       size={"small"}
                                                                                       placeholder={'Account Head'}
                                                                                       onChange={(e, value) => {
                                                                                           form.setFieldValue("head", value)
                                                                                       }}
                                                                                       filterOptions={(options, {inputValue}) => {

                                                                                           return matchSorter(options, inputValue, {keys: ['name', 'code', 'subtypename']});
                                                                                       }}
                                                                                       options={accountHeadOptions ? accountHeadOptions.data : []}
                                                                                       loading={!accountHeadOptions}
                                                                                       groupBy={option => option.subtypename}
                                                                                       PopperComponent={PopperMy}
                                                                                       renderOption={(option) => {
                                                                                           return (
                                                                                               <div>
                                                                                                   <div style={{
                                                                                                       fontSize: 11,
                                                                                                   }}>
                                                                                                       <>{option.code}</>
                                                                                                   </div>
                                                                                                   <div>{option.name}</div>
                                                                                               </div>
                                                                                           )
                                                                                       }}
                                                                                       getOptionLabel={option => option.name}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>
                                        </Grid>
                                    )}

                                    <Grid item xs={3} alignItems={'center'}
                                          style={{marginBottom: 5}}>
                                        <Grid container justify={"flex-start"} spacing={2} alignItems={'center'}>
                                            <Grid item>
                                                <div style={{maxWidth: 140}}>
                                                    <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                            textColor={'white'}
                                                            disabled={Props.submitting}
                                                            startIcon={<SearchIcon/>}

                                                            type={'submit'}>
                                                            <span style={{fontSize: 15}}>
                                                            Search
                                                            </span>
                                                    </Button>
                                                </div>
                                            </Grid>

                                            <Grid item>
                                                {Props.submitting &&
                                                <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Form>

                        </div>
                    )
                }}
            </Formik>
        </div>
    );
};

export default TableFilter;
