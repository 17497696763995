const initialState = {
    message: '',
    variant: 'info'
}

const snackBarReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case 'OPEN_SNACKBAR':
            state = {...action.payload};
            return state;

        case 'CLOSE_SNACKBAR' :
            return initialState

        default:
            return state
    }
}

export default snackBarReducer;

