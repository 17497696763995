import React from 'react';
import InvoiceHome from "../../templates/invoiceHome";

const Invoice = () => {
    return (
        <div>
            <InvoiceHome/>
        </div>
    );
};

export default Invoice;