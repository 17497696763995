import React, {useEffect, useRef, useState} from 'react';
import {Collapse, Divider, Grid} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import useSWR from "swr";
import CategoryReport from "../../../organisms/categoryReportDrill";
import ListView from "../../../organisms/ListView";
import ReceivablesChart from "./ReceivablesChart";
import {getTodaysDate} from "../../../../../config/dateFunctions";

const ReceivablesTemplate: React.FC = () => {

    const ref: any = useRef();
    const [height, setHeight] = useState(400)
    const [total, setTotal] = useState('');

    useEffect(() => {
        if(ref?.current) setHeight(ref.current.clientHeight);
    })

    const {data: customerReceivable} = useSWR(`/report/customerVendorReport?from=&to=${getTodaysDate()}`)

    return (
        <div style={{color: COLOR_PROFILE.primaryColor }}>

            <Grid container>
                <Grid item xs={12} sm={8}>
                    <div ref={ref} style={{padding: 20}}>
                        <ReceivablesChart/>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    {/*<div>*/}
                    {/*    <CustomChart*/}
                    {/*        data={*/}
                    {/*                {*/}
                    {/*                datasets: [{*/}
                    {/*                    barPercentage: 0.9,*/}
                    {/*                    barThickness: 20,*/}
                    {/*                    maxBarThickness: 30,*/}
                    {/*                    minBarLength: 2,*/}
                    {/*                    data: [10, 20, 30, 40, 50, 60, 70]*/}
                    {/*                }],*/}
                    {/*                    labels: [1,2,3,4,5,6,7]*/}
                    {/*                }*/}
                    {/*        }*/}

                    {/*        options={{responsive: true, maintainAspectRatio: true}}*/}
                    {/*        type={'bar'}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div>
                        <div  style={{fontWeight: 700 , margin: '20px 0 0 20px',  fontSize: 16,}}>
                            Customers Receivables <span style={{fontSize: 12, fontWeight: 500}}>(Lifetime)</span>
                        </div>
                    </div>
                    <Grid container xs={12} style={{padding: '15px 20px 60px', }} >
                        {ref && (
                        <Grid item xs={12} style={{paddingTop: 15, boxShadow: `0 0 10px 0px ${COLOR_PROFILE.backgroundDark}`, maxHeight: height -30 || undefined,  minWidth: '100%', padding: 0, overflow: 'auto'}}>
                            <ListView
                                header={["Customer", "Amount"]}
                                data={customerReceivable?.t ? Object.keys(customerReceivable.t).map((item: any) => {
                                console.log("item" , item);
                                return { key: customerReceivable.t[item].name,value: customerReceivable.t[item].accountHeadDto.balance}
                            }): []}/>

                        </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <div>
                <div style={{padding: '0 0 10px 20px', fontWeight: 700,  fontSize: 16}}>
                    Receivable Summary:
                </div>
                <div>
                    <CategoryReport setTotal={setTotal} type={"receivables"}/>
                </div>
            </div>


        </div>
    );
};

export default ReceivablesTemplate;
