import React, {useEffect, useMemo, useState} from 'react';
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import TrialBalanceTable from "../../reporting/trialBalance/trialBalanceTable";
import {Link, Route} from "react-router-dom";
import SetupFileUpload from "../../../organisms/setupFileUpload";
import {getFirstFiscalDate, isInBetweenFiscal, isTransactionDateValid} from "../../../../../config/fiscalYear";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/RootReducer";
import useSWR from "swr";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs, getTodaysDate} from "../../../../../config/dateFunctions";
import Grid from "@material-ui/core/Grid";
import LoopIcon from "@material-ui/icons/Loop";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ReportTable from "../../../organisms/tables/reportTable";
import {log} from "util";
import CircularSpinner from "../../../../shared/atom/spinner";
import {recursivelyUpdateTable} from "../../reporting/recursiveFunction";

const ChartOfAccounts = () => {

    const [submitting, setSubmitting] = useState(false);
    const [date, setDate] = useState({
        adDate: null,
        adDateTill: null
    })
    const [dataComplete, setDataComplete] = useState([]);
    const [tableData, setTableData] = useState([]);

    const {data} = useSWR('/downloadTrialBalance');

    useEffect(() => {
        console.log(data);
        if (!data) return
        setDataComplete(data)
        setTableData(data.filter((item: any) => item.name !== "Adjustment"))
        data.map((item: any) => {
            console.log(item.accountSubTypes);
        })
    }, [data]);

    const dashboard = useSelector((state: RootState) => state.dashboard);
    const {
        formatedNepEngDates,
        nepaliYear,
        nepaliMonths,
        englishYear,
        englishMonths,
        nepEngDates
    } = useAdAndBsHandler();

    useEffect(() => {

        // if(!location.pathname.includes('head')) return;
        // if (!localStorage.getItem('search')) return

        if (!nepEngDates) return;
        if (!formatedNepEngDates) return;

        let adDate = getFirstFiscalDate(nepEngDates.data, dashboard.fiscalYear).join('-');

        let adDateTill = getTodaysDate();

        // @ts-ignore
        setDate({adDate: adDate, adDateTill: adDateTill})

    }, [nepEngDates, formatedNepEngDates])


    const tempColumns = [

        {
            id: 'id',
            Header: '',
            accessor: 'id',
            width: 10,
            Cell: (item: any) => {
                const {row, parentIndex} = item;


                if (row.original.name.includes('Total')) return null

                let id = row.id.split('.').map((item: string) => parseInt(item) + 1);

                return (
                    <span style={{color: COLOR_PROFILE.primaryColor}}>{id.join('.')}</span>
                )
            }
        },
        {
            Header: 'Particulars',
            id: 'expander',
            Cell: (item: any) => {

                const {row, isLoading, isExpanded} = item;
                const {row: {original}} = item;
                const isTotal = original.name.includes('Total');
                const head = original.head;
                const type = false

                const toggleRowExpandedProps = row.getToggleRowExpandedProps();

                const onClick = async (event: any) => {
                    if (!isLoading) {
                        if (!isExpanded) {
                            await handleClickRow(row);
                        }
                        console.log(row.original)
                        toggleRowExpandedProps.onClick(event);
                    }
                }


                return (
                    <Grid container alignItems={"center"}
                          {...row.getToggleRowExpandedProps({
                              style: {
                                  paddingLeft: isTotal ? 0 : `${row.depth}rem`,
                                  paddingTop: head ? 3: 0,
                                  paddingBottom: head ? 3: 0,
                                  cursor: isTotal ? 'default' : "pointer",
                                  marginLeft: isTotal ? '-0.5rem' : "inherit"
                              },
                          })}
                          onClick={!head ? onClick : () => {
                          }}
                    >

                        {(!head && !isTotal) && (<Grid item style={{padding: '3px 0px 0 0 ', color: 'gray'}}>
                            {
                                isLoading ? <LoopIcon style={{fontSize: 20, padding: 2}}/> : (row.isExpanded) ?
                                    <KeyboardArrowDownIcon fontSize={"small"}/> : <ChevronRightIcon fontSize={"small"}/>
                            }
                        </Grid>)}
                        <Grid item style={{paddingLeft: isTotal ? 0 : 5, marginLeft: head ? 5 : 0}}>

                                <span style={{
                                    color: head ? '#50bf47' : type ? COLOR_PROFILE.primaryColor : '#2595b1',
                                    fontSize: isTotal ? 16 : "inherit",
                                }}>{row.original.name} {('(' + row.original.code + ')')}</span>

                        </Grid>


                    </Grid>
                )
            },
        },


    ]


    const columnsExpense = useMemo(() => tempColumns, [tableData]);

    const [isRowLoading, setIsRowLoading] = useState({});


    const handleClickRow = async (item: any) => {
        const {id, original, depth} = item;

        setIsRowLoading({[id]: true});
        const head = false;

        try {

            let childData: any = tableData

            if (depth === 0) {
                console.log('depth is 0');
                childData = original.accountSubTypeDtos;
            } else {

                let groups = original.accountGroupDtos ? [...original.accountGroupDtos] : [];
                // @ts-ignore
                let heads: any = (original.accountHeadDtos || original.child) ? [...original.accountHeadDtos.map((item: any) => {
                    return {...item, head: true}
                }), ...original.child] : [];
                console.log('heads --------------', heads);
                childData = [ ...groups, ...heads]
            }


            setIsRowLoading({[id]: false})

            if (tableData) {

                // @ts-ignore
                const updatedTableData: [] = recursivelyUpdateTable({tableData, childData, id});

                // @ts-ignore
                setTableData([...updatedTableData]);
            }
        } catch (e) {
            setIsRowLoading({[id]: false})
        }

    }

    return (
        <div>

            <div
                style={{
                    padding: '10px 0 20px 20px',
                    fontWeight: 700,
                    fontSize: 18,
                    color: COLOR_PROFILE.primaryColor
                }}
            >
                System Initialization
            </div>

            <div style={{width: 900, paddingLeft: 0, paddingBottom: 10}}>
                <SetupFileUpload downloadPath={'/downloadAccountHeads'} uploadPath={'/uploadAccountHeads'}
                                 fileName={'Account Heads.xlsx'}/>
            </div>

            <div
                style={{
                    padding: '10px 0 20px 20px',
                    fontWeight: 700,
                    fontSize: 18,
                    color: COLOR_PROFILE.primaryColor
                }}
            >
                Chart of Accounts:
            </div>

            <div style={{paddingLeft: 20}}>
                {!data && <CircularSpinner circleColor={COLOR_PROFILE.buttonBlue}/>}
            </div>


            {tableData && (
                <div>
                    <ReportTable columns={columnsExpense}
                                 data={tableData}
                                 type={'journal'}
                                 reportType={"temp"}
                                 isChartOfAccount={true}
                                 isRowLoading={isRowLoading}
                                 withOutHeadBackground={false}
                    />
                </div>
            )}
        </div>
    );
};

export default ChartOfAccounts;
