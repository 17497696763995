import React from 'react';
import useSWR from "swr";
import DashboardReportItem from "../../molecules/dashboardReportItem";
import {Grid} from "@material-ui/core";

const DashboardReport = () => {


    const report = [
        {name: 'Cash & Equivalents', accessor: 'Cash'},
        {name: 'Bank Accounts', accessor: 'Bank Accounts'}
    ]

    const {data: reportData} = useSWR('/dashboardReport?branchId=-1');


    return (
        <Grid container item justify={"center"}>
            {report.map((item: any) => {
                return (
                    <Grid item style={{ padding: 10, margin: '0 4px'}}>
                        <DashboardReportItem name={item.name} loading={!reportData}
                                             value={reportData ?
                                             reportData.data.filter((itemFiltered: any) => itemFiltered.name === item.accessor)[0]?.sum?.toString() : ''}
                        />
                    </Grid>
                )
            })}
        </Grid>
    );
};

export default DashboardReport;
