export const addOrRemoveFromArray = (array: any, item: any) => {
    const exists = array.includes(item)
    if (exists) {
        return array.filter((c: any) => { return c !== item })
    } else {
        const result = array
        result.push(item)
        return result
    }
}
//two dates to format 01-05-23-34
export const GetEffectiveTime = (date1: any, date2: any) => {

    if(!date1) return '';
    if(!date2) return '';

    const format = (data: any) => {
        return ('0' + data).slice(-2)
    }
    return format(date1.getHours()) +'-' + format(date1.getMinutes()) + '-' + format(date2.getHours())+'-' + format(date2.getMinutes())

}