import React from 'react';
import ReceivablesTemplate from "../../../templates/reporting/receivablesTemplate";

const ReceivablesReport: React.FC  = () => {


    return (
        <div>
            <ReceivablesTemplate/>
        </div>
    );
};

export default ReceivablesReport;