import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Checkbox, Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../../../accounting/organisms/reactTable/useServerCall";
import useSWR, {trigger} from "swr";
import ReactTable from "../../../../accounting/organisms/reactTable/ReactTable";
import DataEmptyMessage from "../../../../accounting/organisms/tables/dataEmptyMessage";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DeleteModal from "../../../../accounting/organisms/deleteModal";
import DeleteIcon from "@material-ui/icons/Delete";
import AccessFilter from "../../../../../accessControl/accessFilter";

const ProductItemTable: React.FC<any> = (Props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const [deleteId, setDeleteId] = useState(null)


    const history = useHistory();

    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            // width: 40,
            alignment: 'center',
            Cell: (val: any) => {
                let id: any = val.row.original.id;
                return (
                    <Grid container spacing={3} justify='center' alignItems={"center"} style={{marginRight: 30, paddingRight: 20}}>

                        <AccessFilter

                            section={"productItem"}
                            privilege={"update"}
                            render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                                if (!isAllowed) return null


                                return (

                                    <Grid item xs={4}>
                                        <Checkbox
                                            color={"primary"}
                                            size={"small"}
                                            style={{color: COLOR_PROFILE.buttonGreen, margin: -5, fontSize: 10}}
                                            checked={Props.checked.indexOf(id) !== -1}
                                            onChange={() => Props.handleClick(id)}
                                        />
                                    </Grid>

                                )

                            }}

                        />

                        <AccessFilter

                            section={"productItem"}
                            privilege={"delete"}
                            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                                if (!isAllowed) return null

                                return (
                                    <Grid item xs={4}>
                                        <IconButton size={"small"} style={{color: '#ec4949'}}
                                                    onClick={() => setDeleteId(val.row.original.id)}>
                                            <DeleteIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Grid>
                                )

                            }}

                        />

                        <AccessFilter

                            section={"productItem"}
                            privilege={"update"}
                            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                                if (!isAllowed) return null

                                return (

                                    <Grid item xs={4}>
                                        <IconButton size={"small"} style={{color: '#4aca53'}}
                                                    onClick={() => {
                                                        history.push(`/dashboard/product/product-item/edit/${val.row.original.id}`, val.row.original)
                                                    }}>
                                            <EditIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Grid>

                                )

                            }}

                        />



                    </Grid>
                )
            }
        },
        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'code'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Purchase Price',
            Footer: 'Purchase Price',
            accessor: 'buyingPrice'
        },{
            Header: 'Selling Price',
            Footer: 'Selling Price',
            accessor: 'sellingPrice'
        },
        {
            Header: 'Debit Heads',
            Footer: 'Debit Heads',
            Cell: (val: any) => {
                let {row: {original}} =val.cell

                let debitHead = original.inventoryAccount?.name || original.assetAccount?.name || original.expenseAccount?.name || ''


                return (
                    <div style={{fontWeight: 700}}>
                        {debitHead}
                    </div>
                )
            }
        },
        {
            Header: 'Credit Heads',
            Footer: 'Credit Heads',
            Cell: (val: any) => {
                let {row: {original}} =val.cell

                let creditHead = original.salesAccount?.name || '';

                return (
                <div style={{fontWeight: 700}}>
                    {creditHead}
                </div>
                )
            }
        },

        {
            Header: 'Quantity',
            Footer: 'Quantity',
            accessor: 'quantity'
        },
        {
            Header: 'Category',
            Footer: 'Category',
            accessor: 'categoryDto.name'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },

    ]

    const path = '/getProducts'

    const getParams = () => {
        return `?name=${Props.formState.search}&enabled=${Props.formState.enable === 'Enabled'}&categoryId=${Props.formState.category?.id || 0}`
    }
    useEffect(() => {
        if(Props.submitting) return;
        trigger(`${path}/${page -1}/${perPage}${getParams()}`)
    }, [Props.submitting])


    const {data, loading} = useServerCall({path, page, perPage,
        search: getParams()
    })

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}${getParams()}`)

    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];
    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => shareHolderColumns, [Props.checked, Props.handleClick]);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (

                <div>
                    {deleteId && (
                        <DeleteModal message={`Are you sure? Once deleted it cannot be reverted.`}
                                     trigger={`${path}/${page - 1}/${perPage}${getParams()}`}
                                     deletePath={`/deleteProduct/${deleteId}`} open={deleteId}
                                     handleDeleteCancel={() => setDeleteId(null)}
                        />
                    )}
                    <ReactTable
                        data={mockData}
                        perPageOption={[30, 50, 100]}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        totalPage={data?.t.totalPage}
                    />
                </div>

            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default ProductItemTable;