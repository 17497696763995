import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../store/RootReducer";
import useSWR from "swr";
import FromSubTypes from "../../../../templates/reporting/fromSubTypes";
import {Grid} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../../../config/colorCode";
import ReportActionButton from "../../../../organisms/reportActionButton";

const TrialBalanceTable: React.FC<any> = (Props) => {

    // @ts-ignore
    const {id} = useParams();

    const filterFormData = useSelector((state: RootState) => state.reportFilterReducer);

    const [fromDate, setFromDate] = useState(Props.isChartOfAccount? Props.startDate: null)
    const [tillDate, setTillDate] = useState(Props.isChartOfAccount? Props.endDate: null);
    const [branchId, setBranchId] = useState(null);
    const [total, setTotal] = useState('');
    const [grandSum, setGrandSum] = useState({credit: 0, debit: 0})

    useEffect(() => {
        if(!Props.startDate) return;
        if(!Props.endDate) return;
        if(Props.isChartOfAccount) return;

        // @ts-ignore
        setFromDate((filterFormData.transactionYearAdFrom && filterFormData.transactionMonthAdFrom && filterFormData.transactionDateAdFrom) ? filterFormData.transactionYearAdFrom + '-' + ('0' + filterFormData.transactionMonthAdFrom).slice(-2) + '-' + ('0' + filterFormData.transactionDateAdFrom).slice(-2) : Props.startDate);
        // @ts-ignore
        setTillDate((filterFormData.transactionYearAdTill && filterFormData.transactionMonthAdTill && filterFormData.transactionDateAdTill) ? filterFormData.transactionYearAdTill + '-' + ('0' + filterFormData.transactionMonthAdTill).slice(-2) + '-' + ('0' + filterFormData.transactionDateAdTill).slice(-2) : Props.endDate);
        setBranchId(Props.branch ? Props.branch.id : null)
        Props.setSubmitting(true)

    }, [Props.endDate, Props.startDate, Props.branch])

    const {data: getData} = useSWR((Props.startDate && Props.endDate) ? id === 'home' ?
        `/report/getAccountTypesAndSubTypes?from=${fromDate|| ''}&to=${tillDate|| ''}&name=trialBalance&branchId=${branchId}` :
        id.includes('head') ? `/report/getBalance?from=${fromDate|| ''}&to=${tillDate|| ''}&accHeadId=${id.split('=')[1]}&branchId=${branchId}` :
            id.includes('type') ? `/report/getAccountSubTypesGroups?from=${fromDate|| ''}&to=${tillDate|| ''}&accSubTypeId=${id.split('=')[1]}&branchId=${branchId}` :
                `/report/getAccountGroupAndBalance?from=${fromDate|| ''}&to=${tillDate|| ''}&id=${id}&branchId=${branchId}`
        : null
    )

    useEffect(() => {
        if (!getData) return;
        Props.setSubmitting(false)
    }, [getData])

    useEffect(() => {

        if (id !== 'home') return;
        if(Props.isChartOfAccount) return;

        if (!getData) return;

        let filteredData = getData.data.map((item: any) => item.accountSubTypes)

        let sumPerType = filteredData.map((item: any) => {
            return {
                sumDebit: getSum(item, 'debit'),
                sumCredit: getSum(item, 'credit')
            }
        })

        // @ts-ignore
        setGrandSum({
            debit: getGrandTotal(sumPerType, 'sumDebit'),
            credit: getGrandTotal(sumPerType, 'sumCredit')
        })

        let val = 0;
        Object.keys(getData.t).map((item: any) => {
            val += getData.t[item];
            return item

        })
        setTotal(val > 0 ? Math.abs(val) + ' Dr' : Math.abs(val) + ' Cr');

    }, [getData])

    const getGrandTotal = (tableData: any, type: string) => {
        return tableData.reduce((result: number, item: any) => {
            return (result + parseFloat(item[type]))
        }, 0.00)
    }

    const getSum = (tableData: any, type: string) => {


        return tableData.reduce((result: number, item: any) => {
            if (type === 'debit') {
                return (item.balance > 0 ? result + parseFloat(item.balance) : result)
            } else {
                return (item.balance < 0 ? result + parseFloat(item.balance) : result)
            }
        }, 0.00)
    }


    return (
        <div>
            {getData && (
                <div style={{paddingBottom: 50}}>

                    <FromSubTypes
                        from={'trial-balance/table'}
                        withTwoHead={id === 'home'}
                        isChartOfAccount={Props.isChartOfAccount}
                        fromDate={fromDate}
                        tillDate={tillDate}
                        withOutHeadBackground={true}
                        getData={(id === 'home') ? getData?.data.filter((item: any) => item.name === 'Assets')[0].accountSubTypes.filter((item: any) => !(item.balance === null && !Props.isChartOfAccount)) : getData}
                        type={'Assets'}
                    />

                    {id === 'home' && (
                        <div>
                            <FromSubTypes
                                from={'trial-balance/table'}
                                withOutHeadBackground={true}
                                isChartOfAccount={Props.isChartOfAccount}
                                getData={ getData?.data.filter((item: any) => item.name === 'Liabilities')[0].accountSubTypes.filter((item: any) => !(item.balance === null&& !Props.isChartOfAccount))}
                                type={'Liabilities'}/>
                            
                            <FromSubTypes
                                withOutHeadBackground={true}
                                from={'trial-balance/table'}
                                isChartOfAccount={Props.isChartOfAccount}
                                getData={ getData?.data.filter((item: any) => item.name === 'Equity')[0].accountSubTypes.filter((item: any) => !(item.balance === null && !Props.isChartOfAccount))}
                                type={'Equity'}/>

                            <FromSubTypes
                                withOutHeadBackground={true}
                                from={'trial-balance/table'}
                                isChartOfAccount={Props.isChartOfAccount}
                                getData={getData?.data.filter((item: any) => item.name === 'Income')[0].accountSubTypes.filter((item: any) => !(item.balance === null && !Props.isChartOfAccount))}
                                type={'Income'}/>

                            <FromSubTypes
                                from={'trial-balance/table'}
                                isChartOfAccount={Props.isChartOfAccount}
                                getData={ getData?.data.filter((item: any) => item.name === 'Expenses')[0].accountSubTypes.filter((item: any) => !(item.balance === null && !Props.isChartOfAccount))}
                                withOutHeadBackground={true}
                                finalReportName={'Balance Sheet'}
                                finalData={total}
                                type={`Expenses`}
                            />

                            {!Props.isChartOfAccount && (
                                <Grid container
                                      style={{
                                          color: COLOR_PROFILE.primaryColor,
                                          fontSize: 18, fontWeight: 600,
                                          paddingRight: 25,
                                          paddingTop: 10,
                                      }}
                                >
                                    <Grid item xs={1} style={{minWidth: 42}}>
                                    </Grid>

                                    <Grid item xs={6}>
                                        Grand total
                                    </Grid>

                                    <Grid item xs={3} style={{marginLeft: -37}}>
                                        {Math.abs(grandSum.debit)}
                                    </Grid>

                                    <Grid item xs={2} style={{marginLeft: -16}}>
                                        {Math.abs(grandSum.credit)}
                                    </Grid>

                                </Grid>
                            )}
                        </div>
                    )}

                    {!Props.isChartOfAccount && (
                        <div>
                            <ReportActionButton/>
                        </div>
                    )}
                </div>
            )}
        </div>
    )

}

export default TrialBalanceTable;