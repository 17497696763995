import React from 'react';


interface Props {
    color ?: string
}

const DividerComponent: React.FC<Props> = (Props) => {


    const color = Props.color || '#fafafa'

    return (
        <div>
            <div style={{borderTop: `2px solid ${color}`, margin: '7px 0 7px 0'}}>
            </div>

        </div>
    );
};

export default DividerComponent;
