import React from 'react';
import ProductItemForm from "../../../product/organisms/form/productItem";
import DialogComponent from "../../atom/dialog";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import Button from "../../atom/button";
import {Grid} from "@material-ui/core";

interface Props {
    message: React.ReactElement,
    open: boolean,
    handleClose: () => void,
    title ?: string,
    size ?: 'md' | 'sm'
}

const ErrorDialog: React.FC<Props> = (Props) => {
    return (
        <DialogComponent open={Props.open} handleClose={Props.handleClose} title={Props.title || ''}
                         maxWidth={Props.size || 'md'}>
            <div style={{padding: '0px 0px', fontSize: 14, fontWeight: 700, color: COLOR_PROFILE.primaryColor}}>
                {Props.message}
            </div>
            <Grid container justify={"flex-end"} style={{paddingTop: 20, paddingBottom: 10}}>
                <div style={{maxWidth: 150}}>
                    <Button variant={"contained"} customColor={COLOR_PROFILE.buttonRed} textColor={'white'}
                            onClick={() => Props.handleClose()}
                    >
                        <span style={{fontSize: 15}}>
                            Close
                        </span>
                    </Button>
                </div>
            </Grid>
        </DialogComponent>
    );
};

export default ErrorDialog;