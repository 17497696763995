import React from 'react';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../../config/colorCode";

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const PurchaseOrderPrintView: React.FC<any>  = (Props) => {

    const {formik} = Props;
    const styles = useStyles();

    return (
        <>

            <Grid container item sm={12}>
                <div style={{margin: '0 auto', textAlign: 'center', padding: 10, marginBottom: 30}}>
                    <div style={{fontSize: 18, fontWeight: 700, color: COLOR_PROFILE.primaryColor}}>
                        Purchase Order
                    </div>

                </div>
            </Grid>

            <Grid container item xs={6} alignItems={'center'} spacing={2}
                  style={{marginBottom: 15}}>
                <Grid item xs={4}>
                    <label htmlFor="vendor"
                           className={styles.label}> Vendor: </label>
                </Grid>

                <Grid item xs={8} className={styles.label}>
                    {formik.values.vendor?.name}
                </Grid>
            </Grid>

            <Grid container item xs={6} alignItems={'center'} spacing={2}
                  style={{marginBottom: 15}}>
                <Grid item xs={4}>
                    <label htmlFor="paymentMethod"
                           className={styles.label}> Payment Method: </label>
                </Grid>

                <Grid item xs={8} className={styles.label}>
                    {formik.values.paymentMethod}
                </Grid>
            </Grid>


            <Grid container item xs={6} alignItems={'center'} spacing={2}
                  style={{marginBottom: 10}}>
                <Grid item xs={4}>
                    <label htmlFor="fromDate"
                           className={styles.label}
                    >
                        Date:
                    </label>
                </Grid>

                <Grid item container xs={8} alignItems={'center'} className={styles.label}>

                    {`${formik.values.transactionYearAd}/${formik.values.transactionMonthAd}/${formik.values.transactionDateAd}`}

                </Grid>
            </Grid>

            <Grid container item xs={6} alignItems={'center'} spacing={2}
                  style={{marginBottom: 10}}>
                <Grid item xs={4}>
                    <label htmlFor="fromDate"
                           className={styles.label}
                    >
                        Initial Payment:
                    </label>
                </Grid>

                <Grid item container xs={8} alignItems={'center'} className={styles.label}>

                    {formik.values.initialPayment}

                </Grid>
            </Grid>

            <Grid container item xs={6} alignItems={'center'} spacing={2}
                  style={{marginBottom: 10}}>
                <Grid item xs={4}>
                    <label htmlFor="fromDate"
                           className={styles.label}
                    >
                        Initial Payment Date:
                    </label>
                </Grid>

                <Grid item container xs={8} alignItems={'center'} className={styles.label}>

                    {(formik.values.paymentYearAd && formik.values.paymentMonthAd && formik.values.paymentDateAd) ?  `${formik.values.paymentYearAd}/${formik.values.paymentMonthAd}/${formik.values.paymentDateAd}`: ''}

                </Grid>
            </Grid>

            <Grid container item xs={6} alignItems={'center'} spacing={2}
                  style={{marginBottom: 10}}>
                <Grid item xs={4}>
                    <label htmlFor="fromDate"
                           className={styles.label}
                    >
                        Payment Due Date:
                    </label>
                </Grid>

                <Grid item container xs={8} alignItems={'center'} className={styles.label}>

                    {(formik.values.paymentDueYearAd && formik.values.paymentDueMonthAd && formik.values.paymentDueDateAd) ? `${formik.values.paymentDueYearAd}/${formik.values.paymentDueMonthAd}/${formik.values.paymentDueDateAd}`: ''}

                </Grid>
            </Grid>


        </>
    );
};

export default PurchaseOrderPrintView;