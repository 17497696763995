import React, {useEffect, useMemo, useState} from 'react';
import {CircularProgress, Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../reactTable/useServerCall";
import useSWR from "swr";
import ReactTable from "../../reactTable/ReactTable";
import {useHistory} from 'react-router-dom';
import AccessFilter from "../../../../../accessControl/accessFilter";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DataEmptyMessage from "../dataEmptyMessage";
import CircularSpinner from "../../../../shared/atom/spinner";
import ToolTip from "../../../../shared/atom/tooltip";

interface Props {
    type: string
}

const JournalVoucherTable: React.FC<any> = (Props) => {


    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    const filterType = {
        'journal': {
            menu: 'journalEntry'
        },
        'receipt': {
            menu: 'receiptEntry'
        },
        'payment': {
            menu: 'paymentEntry'
        }
    }
    const history = useHistory();

    const branchColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {

                return (
                    <Grid container spacing={3} justify={"center"}>


                        {/*<Grid item xs={6}>*/}
                        {/*    <IconButton size={"small"} style={{color: '#ec4949'}}>*/}
                        {/*        <DeleteIcon fontSize={"small"}/>*/}
                        {/*    </IconButton>*/}
                        {/*</Grid>*/}

                        <AccessFilter

                            section={Props.type === 'journal' ? 'journalEntry' : Props.type === 'payment' ? 'paymentEntry' : 'receiptEntry'}
                            privilege={"update"}
                            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                                if (!isAllowed) return null

                                return (

                                    <Grid item>
                                        <ToolTip title={val.row.original.label !== "Journal" ? `Not Editable: This journal is made through ${val.row.original.label}`: ""}>
                                            <IconButton size={"small"} style={{color: val.row.original.label !== "Journal" ? COLOR_PROFILE.backgroundDark:'#4aca53'}}
                                                        onClick={() => {
                                                            if(val.row.original.label !== "Journal") return;
                                                            history.push(`/dashboard/voucher-entries/${Props.type}-voucher/edit/${val.row.original.id}`, val.row.original)
                                                        }}
                                                        // disabled={val.row.original.label !== "Journal"}
                                            >
                                                <EditIcon fontSize={"small"}/>
                                            </IconButton>
                                        </ToolTip>
                                    </Grid>

                                )

                            }}

                        />


                    </Grid>
                )
            }
        },
        {
            Header: 'Voucher Code',
            Footer: 'Voucher Code',
            accessor: 'voucherNumber'
        },
        {
            Header: 'Transaction Date',
            Footer: 'Transaction Date',
            accessor: 'transactionDate'
        },
        {
            Header: 'Debit Head',
            Cell: (item: any) => {

                const {row: {original}} = item
                return (
                    <div>
                        {original.journalRows.filter((item: any) => item.type === 'debit').map((item: any) => item.accountHead.name).join(', ')}
                    </div>
                )

            }
        },
        {
            Header: 'Credit Head',
            Cell: (item: any) => {

                const {row: {original}} = item
                return (
                    <div>
                        {original.journalRows.filter((item: any) => item.type === 'credit').map((item: any) => item.accountHead.name).join(', ')}
                    </div>
                )

            },
        },

        {
            Header: 'Source',
            Footer: 'Source',
            Cell: (val: any) => {
              let row = val.row.original;
              return (
                  <div style={{fontWeight: 700}}>
                      {row.label}
                  </div>
              )
            }
        },
        {
            Header: 'Description',
            Footer: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },


    ]
    const receiptColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {

                return (
                    <Grid container spacing={3} justify={"center"}>


                        {/*<Grid item xs={6}>*/}
                        {/*    <IconButton size={"small"} style={{color: '#ec4949'}}>*/}
                        {/*        <DeleteIcon fontSize={"small"}/>*/}
                        {/*    </IconButton>*/}
                        {/*</Grid>*/}

                        <AccessFilter

                            section={Props.type === 'journal' ? 'journalEntry' : Props.type === 'payment' ? 'paymentEntry' : 'receiptEntry'}
                            privilege={"update"}
                            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                                if (!isAllowed) return null

                                return (

                                    <Grid item>

                                        <IconButton size={"small"} style={{color: '#4aca53'}}
                                                    onClick={() => history.push(`/dashboard/voucher-entries/${Props.type}-voucher/edit/${val.row.original.id}`, val.row.original)}
                                        >
                                            <EditIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Grid>

                                )

                            }}

                        />


                    </Grid>
                )
            }
        },
        {
            Header: 'Voucher Code',
            Footer: 'Voucher Code',
            accessor: 'voucherNumber'
        },
        {
            Header: 'Transaction Date',
            Footer: 'Transaction Date',
            accessor: 'transactionDate'
        },
        {
            Header: 'Debit Head',
            Cell: (item: any) => {

                const {row: {original}} = item
                return (
                    <div>
                        {original.journalRows.filter((item: any) => item.type === 'debit').map((item: any) => item.accountHead.name).join(', ')}
                    </div>
                )

            }
        },
        {
            Header: 'Credit Head',
            Cell: (item: any) => {

                const {row: {original}} = item
                return (
                    <div>
                        {original.journalRows.filter((item: any) => item.type === 'credit').map((item: any) => item.accountHead.name).join(', ')}
                    </div>
                )

            },
        },
        {

            Header: 'Amount',
            Cell: (item: any) => {

                const {row: {original}} = item

                return (
                    <div>
                        {original.journalRows.filter((item: any) => item.type === (Props.type === 'payment' ? 'debit' :'credit')).reduce((result: number, item: any) => {
                            return (result + parseFloat(item.amount))
                        }, 0.00)}
                    </div>
                )

            }
        },
        {
            Header: 'Description',
            Footer: 'Description',
            accessor: 'description'
        },

        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },


    ]


    const path = `/getJournals/${Props.type}`

    const {data, loading} = useServerCall({
        path, page, perPage,
        search: `?voucherId=&accHeadId=${Props.formState.head ? Props.formState.head.id : 0}&voucherCode=${Props.formState.search}&subTypeId=${Props.formState.accountType ? Props.formState.accountType.id : 0}&groupId=${Props.formState.group ? Props.formState.group.id : 0}`
    })

    useEffect(() => {
        setPage(1);
    }, [Props.formState])

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}?voucherId=&accHeadId=${Props.formState.head ? Props.formState.head.id : 0}&voucherCode=${Props.formState.search}&subTypeId=${Props.formState.accountType ? Props.formState.accountType.id : 0}&groupId=${Props.formState.group ? Props.formState.group.id : 0}`)


    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];

    const mockData = useMemo(() => calledData, [data]);


    const columns = useMemo(() => Props.type === 'journal' ? branchColumns: receiptColumns, [Props]);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <>

                    <ReactTable
                        data={mockData}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        totalPage={data?.t.totalPage}
                    />
                </>
            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default JournalVoucherTable;
