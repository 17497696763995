import {Grid} from '@material-ui/core';
import React, {useState} from 'react';
import Button from "../../../shared/atom/button";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {Link} from "react-router-dom";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import CircularSpinner from "../../../shared/atom/spinner";
import axiosInstance from "../../../../config/axiosConfig";
import {trigger} from "swr";
import {useDispatch} from "react-redux";
import DialogComponent from "../../../shared/atom/dialog";


interface ButtonInterface {
    pathTo: string,
    name: string,
    refreshPath?: string,
}

interface Props {
    route?: string,
    type?: string,
    message?: any,
    buttonNameAdd: ButtonInterface,
    buttonNameImport?: ButtonInterface,
}

const SetupAddNew: React.FC<Props> = (Props) => {

    const [importing, setImporting] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true)
    };

    const handleClose = (name: string) => () => {
        setOpen(false);
        if(name === 'import') handleImport().then(r => {});
    }

    const handleImport = async () => {

        setImporting(true);
        try {

            const {data} = await axiosInstance.post(Props.buttonNameImport?.pathTo as string);
            responseProcess(data)

        } catch (e) {

            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
            setImporting(false);

        }

        setImporting(false);

    }

    const responseProcess = (data: any) => {

        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            // @ts-ignore
            trigger(Props.buttonNameImport?.refreshPath);
        } else {
            handleResponse(data.message, 'warning')
        }
    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {
        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })
    }

    return (
        <Grid container justify={"center"}>

            <div style={{padding: Props.buttonNameImport ? 0 : 5, color: COLOR_PROFILE.primaryColor, fontSize: 16}}>
                {Props.message}
            </div>

            {Props.buttonNameImport && (

                <Grid container spacing={5} style={{padding: '10px 00px 0px 10px'}} justify={'center'}>
                    <Grid item>
                        <div style={{maxWidth: 850}}>
                            <Button customColor={'rgb(41, 126, 204)'} textColor={'rgb(41, 126, 204)'} variant={"text"}
                                    size={"large"}
                                    onClick={handleClick}
                                    startIcon={<CloudDownloadIcon style={{color: 'rgb(41, 126, 204)'}}/>}
                            >
                                {Props?.buttonNameImport?.name}
                            </Button>
                        </div>
                    </Grid>
                    {importing && (
                        <Grid item style={{marginTop: 3}}>
                            <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>
                        </Grid>

                    )}
                </Grid>
            )}

            <Grid container spacing={5} style={{padding: '0px 10px 10px 10px'}} justify={'center'}>

                <Grid item>
                    <div style={{maxWidth: 400}}>
                        <Link to={Props.buttonNameAdd.pathTo}>
                            <Button customColor={COLOR_PROFILE.buttonLightGreen} textColor={'white'} variant={"contained"}
                                    size={"large"}
                            >
                                <span >{Props.buttonNameAdd.name}</span>
                            </Button>
                        </Link>

                    </div>
                </Grid>
            </Grid>


            {open && (
                <DialogComponent open={open} maxWidth={"xs"}>


                    <div style={{fontSize: 16, fontWeight: 600, paddingBottom: 0}}>
                        <div>Are you sure?</div>

                        <p style={{fontSize: 14}} >Importing</p>
                    </div>
                    <Grid container spacing={3} style={{margin: '10px 0 0 -10px '}} alignItems={"center"}>

                        <Grid item>
                            <div style={{minWidth: 125}}>
                                <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue} textColor={'white'}
                                        type={'submit'} onClick={handleClose('import')}>
                                    <span style={{fontSize: 15}}>
                                        Yes, Import Now
                                    </span>
                                </Button>
                            </div>
                        </Grid>

                                <Grid item>
                                    <div style={{minWidth: 125}}>
                                        <Button variant={"contained"} customColor={COLOR_PROFILE.buttonRed} textColor={'white'}
                                                type={'button'}
                                                onClick={handleClose('cancel')}
                                        >
                                    <span style={{fontSize: 15}}>
                                        Cancel
                                    </span>
                                        </Button>
                                    </div>
                                </Grid>



                    </Grid>
                </DialogComponent>
            )}

        </Grid>
    );
};

export default SetupAddNew;
