import React, {useState} from 'react';
import SetupAddNew from "../../../organisms/setupAddNew";
import CustomTable from "../../../organisms/tables/customerTable";
import SetupFileUpload from "../../../organisms/setupFileUpload";
import TableFilter from "../../../organisms/Forms/tableFilter";

const CustomerPage: React.FC = () => {

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})

    return (
        <div>

            <div style={{maxWidth: 1000 }}>
                <div style={{padding: 20}}>
                    <SetupAddNew

                        buttonNameAdd={{pathTo: '/dashboard/sales/customers/add', name: 'Add Customer'}}

                        message={
                            <div style={{
                                fontSize: 16,
                                fontWeight: 600,
                                padding: '10px 10px 10px 5px'
                            }}>
                                <div>Click below to add a customers
                                </div>

                            </div>
                        }

                    />
                </div>

                <div style={{width: 900, paddingLeft: 15, paddingBottom: 10}}>
                    <SetupFileUpload/>
                </div>

                <div style={{padding: '0 30px 0 28px'}}>

                    <div style={{marginBottom: -10}}>
                        <TableFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                     submitting={submitting}
                                     from={'customer'}
                        />
                    </div>
                    <CustomTable formState={formState} setSubmitting={setSubmitting}/>
                </div>
            </div>

        </div>
    );
};

export default CustomerPage;
