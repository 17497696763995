import React, {useEffect, useState} from 'react';
import {Route, useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import GeneralLedgerFilterForm from "../../../organisms/Forms/generalLedger";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import TrialBalanceTable from "./trialBalanceTable";

const TrialBalance: React.FC = () => {

    // @ts-ignore
    const {id} = useParams();

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [branch, setBranch] = useState(null);

    const [formState, setFormState] = useState(null);
    const [submitting, setSubmitting] = useState(false);



    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch({type: "REMOVE_REPORT_FILTER"})
        }
    }, [])


    return (
        <div>

            <div style={{maxWidth: 1000}}>

                <div
                    style={{
                        padding: '10px 0 0 20px',
                        fontWeight: 700,
                        fontSize: 18,
                        color: COLOR_PROFILE.primaryColor
                    }}
                >
                    Trial Balance
                </div>

                <div>
                    <GeneralLedgerFilterForm from={'groupSummary'}
                                             setStartDate={setStartDate}
                                             setEndDate={setEndDate}
                                             setBranch={setBranch}
                                             branch={branch}
                                             submitting={submitting}
                                             setFormState={setFormState}
                                             setSubmitting={setSubmitting}
                    />
                </div>

                <div>
                    <Route path={'/dashboard/reports/trial-balance/table/:id'} exact>
                        <div>
                            <TrialBalanceTable startDate={startDate} endDate={endDate}
                                               branch={branch}
                                               setSubmitting={setSubmitting}
                            />
                        </div>
                    </Route>
                </div>
            </div>
        </div>
    );

};


export default TrialBalance;
