import React, {useState} from 'react';
import axiosInstance from "../../../../config/axiosConfig";
import {useDispatch} from "react-redux";
import AccessFilter from "../../../../accessControl/accessFilter";
import SetupAddNew from "../../../accounting/organisms/setupAddNew";
import SalesFilter from "../../organisms/forms/salesFilter";
import {Grid} from "@material-ui/core";
import Button from "../../../shared/atom/button";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import CircularSpinner from "../../../shared/atom/spinner";
import InvoiceTable from "../../organisms/tables/invoiceTable";
import ReturnTable from "../../organisms/tables/returnTable";

const ReturnHome: React.FC<any> = (Props) => {
    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})
    const [checked, setChecked] = useState<any>([]);




    return (
        <div>

            <AccessFilter

                section={"salesReturn"}
                privilege={'write'}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>


                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/sales/return/add',
                                            name: 'Add Return'
                                        }}

                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>
                                                    Add Return by clicking the button below
                                                </div>
                                            </div>
                                        }

                                    />
                                </div>

                            </div>
                        </div>
                    )

                }}

            />

            <div style={{maxWidth: 1000}}>
                <div style={{padding: '0 30px 0 28px'}}>

                    <div style={{marginBottom: -10}}>
                        <SalesFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                     submitting={submitting} from={'return'}/>
                    </div>



                    <div style={{paddingBottom: checked.length > 0 ? 40: undefined}}>

                        <ReturnTable formState={formState}
                                      setSubmitting={setSubmitting} submitting={submitting}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ReturnHome;