import React from 'react';
import SetupAddNew from "../../../organisms/setupAddNew";
import BranchTable from "../../../organisms/tables/branchTable";
import SetupFileUpload from "../../../organisms/setupFileUpload";
import AccessDenied from "../../../templates/errorTemplates/accessDenied";
import AccessFilter from "../../../../../accessControl/accessFilter";

const BranchPage: React.FC = () => {
    return (
        <div>


            <div style={{maxWidth: 1000}}>

                <AccessFilter
                    section={"branches"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>

                                    <div style={{padding: 20}}>
                                        <SetupAddNew

                                            buttonNameAdd={{
                                                pathTo: '/dashboard/company-setup/branch/add',
                                                name: 'Add Branch'
                                            }}
                                            // buttonNameImport={{pathTo: '', name: 'Import Vendors from Head Office'}}

                                            message={
                                                <div style={{
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    padding: '10px 10px 10px 5px'
                                                }}>
                                                    <div>Add branches by pressing the button below.
                                                    </div>

                                                </div>
                                            }

                                        />
                                    </div>

                                    <div style={{width: 900, paddingLeft: 15, paddingBottom: 10}}>
                                        <SetupFileUpload/>
                                    </div>
                                </div>

                            </div>

                        )

                    }}

                />


                <AccessFilter
                    section={"branches"}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <div style={{padding: '0 30px 0 28px'}}>
                                        <BranchTable/>
                                    </div>
                                </div>

                            </div>

                        )

                    }}

                />


            </div>


        </div>
    );
};

export default BranchPage;
