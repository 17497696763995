import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useSWR, {trigger} from "swr";
import useServerCall from "../../../../accounting/organisms/reactTable/useServerCall";
import ReactTable from "../../../../accounting/organisms/reactTable/ReactTable";
import DataEmptyMessage from "../../../../accounting/organisms/tables/dataEmptyMessage";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AccessFilter from "../../../../../accessControl/accessFilter";
import {convertAdToBsDisplay, getTodaysDate} from "../../../../../config/dateFunctions";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import DashboardReportItem from "../../../../accounting/molecules/dashboardReportItem";
import SetupFileUpload from "../../../../accounting/organisms/setupFileUpload";

const ReturnTable: React.FC<any> = (Props) => {
    const {formState} = Props;

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState( 30);

    const history = useHistory();

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();

    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {
                let id: any = val.row.original.id;

                return (
                    <Grid container spacing={3} justify='center' >

                        <Grid item >
                            <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                        onClick={() => {
                                            history.push(`/dashboard/sales/return/view/${val.row.original.id}`,
                                                {...val.row.original}
                                            )
                                        }}>
                                <VisibilityIcon fontSize={"small"}/>
                            </IconButton>
                        </Grid>


                    </Grid>
                )
            }
        },
        {
            Header: 'Return No.',
            Footer: 'Return No.',
            accessor: 'salesReturnNumber'
        },
        {
            Header: 'Ref Invoice No.',
            Footer: 'Ref Invoice No.',
            accessor: 'referenceInvoiceNumber'
        },
        {
            Header: 'Is Synced?',
            accessor: 'synced',
            Cell: (val: any) => {
                let temp = val.row.original.syncWithIrd;

                return <div>{temp ? 'True': 'False'}</div>
            }
        },
        {
            Header: 'Customer',
            accessor: 'customer.name'
        },
        {
            Header: 'Return Date',
            accessor: 'Return Date',
            Cell: (val: any) => {
                let temp = val.row.original.createdAt;
                return <div> {convertAdToBsDisplay(temp.split('T')[0], formatedNepEngDates)}</div>
            }
        },
        {
            Header: 'Created By',
            accessor: 'createdBy.name'
        },
        {
            Header: 'Is Printed?',
            accessor: 'billPrinted',
            Cell: (val: any) => {
                let temp = val.row.original.billPrinted;

                return <div>{temp ? 'True': 'False'}</div>
            }
        },
        {
            Header: 'Printed By',
            accessor: 'printedBy.name'
        },
        {
            Header: 'Printed At',
            accessor: 'printedTime'
        },
        {
            Header: 'No Of Prints',
            accessor: 'printNumber'
        }
    ]

    const  reportColumn = [
        {
            Header: 'Return No',
            width:30,
            accessor: 'billNumber',
            Cell: (val: any) => {
                let temp = val.row.original.salesReturnNumber;
                return (
                    <div style={{minWidth: 30}}>{temp}</div>
                )
            }
        },
        {
            Header: 'Return Date',
            accessor: 'createdAt',
            Cell: (val: any) => {
                let temp = val.row.original.createdAt;
                return <div> {convertAdToBsDisplay(temp.split('T')[0], formatedNepEngDates)}</div>
            }
        },
        {
            Header: 'Customer',
            accessor: 'customer.name'
        },
        {
            Header: 'Total Amount',
            accessor: 'totalAmount',
            Cell: (val: any) => {
                let temp = val.row.original.totalAmount;
                return <div>Rs. {temp}</div>
            }

        },
        {
            Header: 'No of Services',
            accessor: 'services',
            Cell: (val: any) => {
                let temp = val.row.original.productBundleSubs;
                return <div>{temp.length}</div>
            }

        }
    ]

    const path = '/getSR'

    const getParam = (formState: any) => {
        return `customerId=${formState.customer ? formState.customer.id : 0}&from=${
            (formState.transactionYearAdFrom && formState.transactionMonthAdFrom && formState.transactionDateAdFrom) ? (formState.transactionYearAdFrom + '-' + ('0' + formState?.transactionMonthAdFrom).slice(-2) + '-' + ('0' + formState.transactionDateAdFrom).slice(-2)) : ''
        }&to=${
            (formState.transactionYearAdTill && formState.transactionMonthAdTill && formState.transactionDateAdTill) ? (formState.transactionYearAdTill + '-' + ('0' + formState?.transactionMonthAdTill).slice(-2) + '-' + ('0' + formState.transactionDateAdTill).slice(-2)) : getTodaysDate()
        }`
    }


    useEffect(() => {
        if(Props.submitting) return;

        trigger(`${path}/${page -1}/${perPage}?${getParam(formState)}`)

    }, [Props.submitting])

    const {data, loading} = useServerCall({
        path, page, perPage,
        search: `?${getParam(formState)}`
    })

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])


    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];
    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => Props.report ? reportColumn:shareHolderColumns, [formState]);

    const sumPath = '/getSRTotal'
    const {data: sumData} = useSWR(`${sumPath}?${getParam(formState)}`)


    return (
        <div style={{maxWidth: !Props.report ? `calc(100vw - 300px)`: undefined, overflow: "auto"}}>
            <div style={{minWidth:  !Props.report ? 1600: undefined}}>

                {Props.report &&
                    <Grid container justify={"center"} style={{marginTop: 15}}>

                        <Grid  item xs={12} style={{textAlign: 'center', fontWeight: 700, paddingTop: 10, paddingBottom: 10, fontSize: 16}}>
                            <SetupFileUpload downloadPath={'/download/salesReturnReport?'+getParam(formState)}
                                             downloadText={"Download Sales Return Report"}
                                             centered={true}
                                             fileName={'Sales Return.xlsx'}
                            />
                        </Grid>

                        <DashboardReportItem name={'Total Return'}
                                             value={sumData?.t}
                                             loading={!sumData}
                        />
                    </Grid>
                }
            {!(mockData.length === 0 && page === 1) ? (
                <ReactTable
                    data={mockData}
                    columns={columns}
                    perPageOption={[30, 50, 100]}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={page}
                    perPage={perPage}
                    totalPage={data?.t.totalPage}
                />
            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
            </div>
        </div>
    );
};

export default ReturnTable;
