import React, {useEffect, useMemo, useState} from 'react';
import useSWR from "swr";
import {Grid, IconButton} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReactTable from "../../../accounting/organisms/reactTable/ReactTable";
import DataEmptyMessage from "../../../accounting/organisms/tables/dataEmptyMessage";
import SetupFileUpload from "../../../accounting/organisms/setupFileUpload";
import {convertAdToBs, convertAdToBsDisplay, getTodaysDate} from "../../../../config/dateFunctions";
import useAdAndBsHandler from "../../../../effects/useAdAndBsHandler";
import MaterializedTemplate from "../../templates/materializedTemplate";
import {getFirstFiscalDate} from "../../../../config/fiscalYear";
import {getFormattedDate} from "../../../../utils/dateFunction";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import {Field, Form, Formik} from "formik";
import DateComponent from "../../../product/molecules/dateComponent";
import AutoCompleteInput from "../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../product/molecules/errorLabel";
import Button from "../../../shared/atom/button";
import * as Yup from "yup";

const MaterializedView = () => {

    const initialState = {
        transactionYearAdFrom: null,
        transactionMonthAdFrom: null,
        transactionDateAdFrom: null,
        transactionYearBsFrom: null,
        transactionMonthBsFrom: null,
        transactionDateBsFrom: null,
        transactionYearAdTill: null,
        transactionMonthAdTill: null,
        transactionDateAdTill: null,
        transactionYearBsTill: null,
        transactionMonthBsTill: null,
        transactionDateBsTill: null,
    }

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState<any>(initialState);
    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();
    const dashboard = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {

        // if(!location.pathname.includes('head')) return;
        // if (!localStorage.getItem('search')) return

        if(!nepEngDates) return;
        if(!formatedNepEngDates) return;

        let adDate = getFirstFiscalDate(nepEngDates.data, dashboard.fiscalYear);
        let bsDate: any = convertAdToBs(adDate.join('-'), formatedNepEngDates) || [];

        let adDateTill = getTodaysDate().split('-');
        let bsDateTill: any = convertAdToBs(getTodaysDate(), formatedNepEngDates) || [];


        // @ts-ignore
        let tempJson = {
            transactionYearAdFrom: adDate[0],
            transactionDateAdFrom: adDate[2],
            transactionMonthAdFrom: adDate[1],
            transactionDateBsFrom: bsDate[2],
            transactionMonthBsFrom: bsDate[1],
            transactionYearBsFrom: bsDate[0],
            transactionYearAdTill: adDateTill[0],
            transactionMonthAdTill: adDateTill[1],
            transactionDateAdTill: adDateTill[2],
            transactionYearBsTill: bsDateTill[0],
            transactionMonthBsTill: bsDateTill[1],
            transactionDateBsTill: bsDateTill[2],
            billType: "All"
        }

        setFormState((prevState: any) => {
            return {
                ...prevState,
                ...tempJson
            }
        })


    }, [nepEngDates, formatedNepEngDates])

    const onSubmit = (values: any) => {
        console.log('clicked')
        setFormState(values);
        setSubmitting(true);
        setSubmitting(false);
    }

    const validationSchema = Yup.object({})


    return (
        <>
            <div style={{maxWidth: 900, margin: "0 auto"}}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formState}
                    // onSubmit={(values: any) => {}}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnMount={false}
                    validateOnBlur={true}
                    onSubmit={onSubmit}
                >
                    {formik => {
                        return (
                            <Form style={{marginBottom: 20, paddingTop: 10, marginRight: 0}}>
                                <Grid  container xs={12} justify={"space-between"} alignItems={"center"} spacing={2}>
                                    <Grid item container xs={6} justify={"flex-end"}>
                                        {/*From*/}
                                        <Grid item container alignItems={"center"} xs={2} style={{fontWeight: 700}}>
                                            From
                                        </Grid>
                                        <Grid item container xs={10}>
                                            <DateComponent
                                                ad={['transactionYearAdFrom', 'transactionMonthAdFrom', 'transactionDateAdFrom']}
                                                bs={['transactionYearBsFrom', 'transactionMonthBsFrom', 'transactionDateBsFrom']}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container xs={6} justify={"flex-end"}>
                                        {/*Till*/}
                                        <Grid item xs={2} container justify={"center"} alignItems={"center"}
                                              style={{fontWeight: 700}}>
                                            Till
                                        </Grid>
                                        <Grid item container xs={10}>
                                            <DateComponent
                                                ad={['transactionYearAdTill', 'transactionMonthAdTill', 'transactionDateAdTill']}
                                                bs={['transactionYearBsTill', 'transactionMonthBsTill', 'transactionDateBsTill']}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid item container justify={"center"} xs={12}>
                                        <div style={{minWidth: 70, marginBottom: -20}}>
                                            <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                    textColor={'white'}
                                                    type={'submit'}
                                            >
                                                <span style={{fontSize: 12}}>Apply</span>
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>

                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <MaterializedTemplate formState={formState}
                                  report={true}
                                  setSubmitting={setSubmitting} submitting={submitting}/>

            <MaterializedTemplate formState={formState}
                                  report={true}
                                  return={true}
                                  setSubmitting={setSubmitting} submitting={submitting}/>
        </>
   )
};

export default MaterializedView;
