import React, {useEffect, useMemo, useState} from 'react';
import {Grid, IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../reactTable/useServerCall";
import useSWR from "swr";
import ReactTable from "../../reactTable/ReactTable";
import DeleteModal from "../../deleteModal";
import CircularSpinner from "../../../../shared/atom/spinner";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DataEmptyMessage from "../dataEmptyMessage";
import ToolTip from "../../../../shared/atom/tooltip";
import AccessFilter from "../../../../../accessControl/accessFilter";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/RootReducer";
import {useHistory} from "react-router-dom";

const EmployeeTable: React.FC<any> = (Props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [deleteId, setDeleteId] = useState(null)

    const dashboard = useSelector((state: RootState) => state.dashboard);


    const history = useHistory();
    const branchColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} style={{marginRight: 15, paddingRight: 15}} justify={"center"}>


                    <Grid item xs={6}>
                        <AccessFilter

                            section={"employees"}
                            privilege={"delete"}
                            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                                if (!isAllowed || (dashboard.email === val.row.original.email)) return null

                                return (

                                    <ToolTip title={'Delete User'}>

                                        <IconButton size={"small"} style={{color: '#ec4949'}}
                                                    onClick={() => setDeleteId(val.row.original.id)}>
                                            <DeleteIcon fontSize={"small"}
                                                        onClick={() => setDeleteId(val.row.original.id)}
                                            />
                                        </IconButton>
                                    </ToolTip>

                                )

                            }}

                        />
                    </Grid>


                    <Grid item xs={6}>
                        <AccessFilter
                            section={"employees"}
                            privilege={"update"}
                            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                                if (!isAllowed || (dashboard.email === val.row.original.email)) return null

                                return (

                                    <IconButton size={"small"} style={{color: '#4aca53'}} onClick={() => {
                                        history.push(`/dashboard/company-setup/employee/edit/${val.row.original.id}`, val.row.original)
                                    }
                                    }>
                                        <EditIcon fontSize={"small"}/>
                                    </IconButton>
                                )

                            }}

                        />
                    </Grid>
                </Grid>
            )
        },

        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Email',

            Footer: 'Email',
            accessor: 'email'
        },

        {
            Header: 'Branch',
            Footer: 'Branch',
            accessor: 'branchDto.name'
        },

        {
            Header: 'PAN Number',
            Footer: 'PAN Number',
            accessor: 'panNumber'
        },
        {
            Header: 'Phone Number',
            Footer: 'Phone Number',
            accessor: 'phoneNumber'
        },


    ]


    const groupedHeader = [
        {
            Header: 'Actions',
            width: 50,
            columns: [
                {
                    id: 'delete',
                    width: 10,

                    accessor: 'deleteButton',
                    Cell: () => (
                        <Grid container>


                            <Grid item xs={12}>
                                <IconButton size={"small"} style={{color: 'red'}}>
                                    <DeleteIcon fontSize={"small"}/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    )
                },
                {
                    id: 'edit',
                    width: 10,
                    accessor: 'editButton',
                    Cell: () => (
                        <Grid container>

                            <Grid item xs={12}>
                                <IconButton size={"small"} style={{color: 'green'}}>
                                    <EditIcon fontSize={"small"}/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    )
                }
            ]
        },
        {
            Header: 'Info',
            columns: [
                {
                    Header: 'Name',
                    Footer: 'Name',
                    accessor: 'name'
                },
                {
                    Header: 'Email',

                    Footer: 'Email',
                    accessor: 'email'
                },
                {
                    Header: 'Phone',
                    Footer: 'Phone',

                    accessor: 'phone'
                },
                {
                    Header: 'DOB',
                    Footer: 'DOB',

                    accessor: 'dateOfBirth'
                },
                {
                    Header: 'Citizenship Number',
                    Footer: 'Citizenship Number',

                    accessor: 'citizenshipNumber'
                }
            ]
        }
    ]

    const path = '/getEmployees'

    const {data, loading} = useServerCall({path, page, perPage, search: `?name=${Props.formState.search}`})

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}?name=${Props.formState.search}`)


    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];

    const mockData = useMemo(() => calledData, [data]);

    const columns = useMemo(() => branchColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div>
                    {deleteId && (
                        <DeleteModal
                            message={`Are you sure? If the employee has a user account, it will be deleted as well.`}
                            trigger={`${path}/${page - 1}/${perPage}`}
                            withVerification={true}
                            deletePath={`/deleteEmployee/${deleteId}`} open={deleteId}
                            handleDeleteCancel={() => setDeleteId(null)}
                        />
                    )}

                    <ReactTable
                        data={mockData}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        totalPage={data?.t.totalPage}
                    />
                </div>
            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default EmployeeTable;
