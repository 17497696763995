import React from 'react';
import ReturnAddition from "../../templates/returnAddition";

const ReturnAdd = () => {
    return (
        <div>
          <ReturnAddition/>
        </div>
    );
};

export default ReturnAdd;