import React from 'react';
import ProductItemForm from "../../organisms/form/productItem";
import PageTopBar from "../../../accounting/organisms/pageTopBar";
import DividerComponent from "../../../shared/atom/divider";
import {useParams} from "react-router-dom";

const ProductItemAddition = () => {

    // @ts-ignore
    const {id} = useParams();

    return (
        <div>

            <PageTopBar name={id ? 'Edit Product Item':'Add Product Item'}/>


            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: '25px 0px 10px 25px',}}>
                <ProductItemForm mode={id? 'edit':'create'} from={'product-item'} id={id}/>
            </div>
        </div>
    );
};

export default ProductItemAddition;