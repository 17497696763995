import React, {useEffect} from 'react';
import {BrowserRouter as Router, useHistory,} from 'react-router-dom';
import MainRoute from "./routes/mainRoute";
import axiosInstance from "./config/axiosConfig";
import {cache, SWRConfig} from 'swr';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import axios from "axios";

Sentry.init({
    dsn: "https://15ec8fce83f249ad9f93242473644937@o1063555.ingest.sentry.io/6054017",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

function App() {

    useEffect(() => {
        if (localStorage.getItem('token')) {
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
        }

        // const data =  axios.post("http://ec2-13-232-7-44.ap-south-1.compute.amazonaws.com:8080/login?username=demo@gmail.com&password=asdf")

    }, [])

    // window.addEventListener('scanner', function(readerEvent){
    //     console.log(readerEvent)
    // })

    // window.addEventListener('keypress', function(readerEvent){
    //     console.log(readerEvent)
    // })



    const history = useHistory();

    function localStorageProvider(): any {
        // When initializing, we restore the data from `localStorage` into a map.
        const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))

        // Before unloading the app, we write back all the data into `localStorage`.
        window.addEventListener('beforeunload', () => {
            const appCache = JSON.stringify(Array.from(map.entries()))
            localStorage.setItem('app-cache', appCache)
        })

        // We still use the map for write & read for performance.
        return map/**/
    }


    return (

        <Router>
            <SWRConfig
                value={{
                    fetcher: (url: string) => axiosInstance.get(url).then(r => r.data) ,
                    revalidateOnFocus: false,
                    shouldRetryOnError: false
                }}
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <MainRoute/>
                </MuiPickersUtilsProvider>
            </SWRConfig>
        </Router>

    );
}


export default App;
