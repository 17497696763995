import React, {useCallback, useEffect, useMemo} from 'react';
import {Field, useFormikContext} from "formik";
import {Grid, Popper, useMediaQuery} from "@material-ui/core";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import {matchSorter} from "match-sorter";
import ToolTip from "../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import InputField from "../../../../shared/atom/formElements/inputField";
import {Column} from "react-table";
import VoucherTableForm from "../../../../accounting/organisms/Forms/voucherTableForm";
import useSWR from "swr";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {ACCOUNT_CODE_MAPPER} from "../../../../../utils/accountCodeMapper";

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    },
    labelValue: {
        fontWeight: "bold",
        fontSize: 14,
        paddingLeft: 10, paddingRight: 10,
        color: COLOR_PROFILE.primaryColor
    }
}))

const TdsForm: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance, mode, payable}) => {

    const {values: {tableRow,tds, tableRowNonTaxable, tableRowAdditional, amountPaid, paymentStatus}} = useFormikContext();
    const name = "tds";
    const isView = mode === 'view';
    const style= useStyles();

    useEffect(() => {
        if(paymentStatus === 'Full'){
            formikInstance.setFieldValue('amountPaid', (tableRowSum() + tableRowNonTaxableSum() + tableRowAdditionalSum() - tdsSum()))
        }

    }, [paymentStatus,tds, tableRow])

    const tableRowSum = useCallback(() => {
        return tableRow.filter((item: any) =>  item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])

    const tableRowNonTaxableSum = useCallback(() => {
        return tableRowNonTaxable?.filter((item: any) =>  item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00) || 0
    }, [tableRowNonTaxable])

    const tableRowAdditionalSum = useCallback(() => {
        return tableRowAdditional?.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00) || 0
    }, [tableRowAdditional])

    const tdsSum = useCallback(() => {
        return tds.filter((item: any) =>  item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tds])

    const {data: tdsOptions} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.indirectExpenseTDS}`);
    const {data: tdsOptionsPayable} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.currentAssetAdvanceTax}`);
    const data = useMemo(() => tds, [tds, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tds[values.values.tds.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.down('xs'));
        return (<Popper {...props} style={{width: matches ? 250:500}} placement='bottom-start'/>)
    }


    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        // {
        //     id: 'item',
        //     width: isView ? undefined: 200,
        //     Header: 'Product',
        //     accessor: "item",
        //     Cell: (cellObj: any) => {
        //
        //         const index = cellObj.row.index;
        //         const {row: {original}} = cellObj
        //
        //
        //         return (
        //             <Field name={`${name}[${index}].item`}>
        //                 {
        //                     (props: { field: any; form: any; meta: any; }) => {
        //
        //
        //                         const {field, form, meta} = props;
        //
        //                         const getOptions = (options: any) => {
        //                             return options;
        //                         }
        //
        //
        //                         // @ts-ignore
        //                         return (
        //                             <>
        //                                 <Grid container alignItems={"center"} justify={"center"}>
        //                                     <Grid item xs>
        //                                             <div className={style.labelValue}>
        //                                                 {field?.value?.name || ''}
        //                                             </div>
        //
        //                                     </Grid>
        //
        //
        //                                 </Grid>
        //                             </>
        //
        //                         )
        //                     }
        //                 }
        //             </Field>
        //         )
        //     }
        // },


        {
            id: 'productItem',
            width: isView ? undefined: 400,
            Header: 'TDS Account',
            accessor: "productItem",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].product`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {


                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {
                                    return options;
                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>
                                                {isView ? (
                                                    <div className={style.labelValue}>
                                                        {field?.value?.headname || ''}
                                                    </div>
                                                ):(

                                                <AutoCompleteInput type={'text'}
                                                                   id={`${name}[${index}].product`} {...field}
                                                                   size={"small"}
                                                                   placeholder={'Select TDS'}
                                                                   filterOptions={(options, {inputValue}) => {
                                                                       let filteredOptions = getOptions(options)

                                                                       //todo do this again
                                                                       return matchSorter(filteredOptions, inputValue, {keys: ['headname', 'code']});
                                                                   }}
                                                                   onChange={(e, value) => {
                                                                       form.setFieldValue(`${name}[${index}].product`, value)
                                                                   }}

                                                                   hideOutline={true}
                                                                   autoHighlight={true}
                                                                   autoSelect={true}

                                                                   options={!payable ? tdsOptions ? tdsOptions.data: [] : tdsOptionsPayable ? tdsOptionsPayable.data.filter((item: any) => item.code === "CA-206-001"): []}
                                                                   loading={!tdsOptions}
                                                                   variant={'standard'}
                                                                   InputProps={{
                                                                       disableUnderline: true,
                                                                       style: {paddingLeft: 5, paddingRight: 5}
                                                                   }}
                                                                   PopperComponent={PopperMy}
                                                                   getOptionLabel={option => option.headname}
                                                                   renderOption={(option) => {
                                                                       return (
                                                                           <div>
                                                                               <div style={{
                                                                                   fontSize: 11,
                                                                               }}>
                                                                                   <>{option.code}</>
                                                                               </div>
                                                                               <div>{option.headname}</div>
                                                                           </div>
                                                                       )
                                                                   }}
                                                                   error={meta.touched && meta.error}
                                                />
                                                )}
                                            </Grid>
                                            {!isView && (
                                            <ToolTip title={'Add new head'}>
                                                <Grid item style={{
                                                    borderLeft: '1px solid #d0d0d0',
                                                    padding: '8px 3px 3px 3px',
                                                    cursor: 'pointer',

                                                }}
                                                      onClick={() => handleDialogOpen()}
                                                >

                                                    <div>

                                                        <AddIcon style={{
                                                            color: COLOR_PROFILE.primaryColor,
                                                            fontWeight: 700,
                                                            fontSize: 20
                                                        }}/>

                                                    </div>
                                                </Grid>
                                            </ToolTip>
                                            )}
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'taxableAmount',
            Header: 'Taxable Amount',
            accessor: "taxableAmount",
            width: 30,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].taxableAmount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].taxableAmount`} {...field}
                                                    size={"small"}
                                                    step="1"
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].taxableAmount`, parseFloat(event.target.value))
                                                        form.setFieldValue(`${name}[${index}].amount`,  (parseFloat(original.tax)/100 * parseFloat(event.target.value)).toFixed(2))
                                                    }}

                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'tax',
            Header: 'TAX (%)',
            width: 60,
            accessor: "tax",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].tax`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].tax`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tds.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        console.log(event.target.value)
                                                        form.setFieldValue(`${name}[${index}].tax`, event.target.value)
                                                        form.setFieldValue(`${name}[${index}].amount`,  (event.target.value/100 * original.taxableAmount).toFixed(2))

                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'tdsAmount',
            Header: 'Amount',
            accessor: "tdsAmount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].amount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].amount`} {...field}
                                                    size={"small"}
                                                    value={original.amount}
                                                    onKeyDown={handleKeyUp(index === form.values.tds.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                //console.log(cellObj)

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        //console.log('delete clicked')
        if(paymentStatus === 'Full'){
            formikInstance.setFieldValue('amountPaid', (tableRowSum() + tableRowAdditionalSum() - tdsSum()))
        }
        handleRemove(index, formikInstance, name)
    }


    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, tdsOptions]);


    return (
        <div>

            <div style={{paddingBottom: 40}}>
                <VoucherTableForm columns={columns} data={data} sectionName={name} addNew={addNew}
                                  total={{
                                      'totalAmount': tableRowSum() + tableRowNonTaxableSum() + (tableRowAdditional ? tableRowAdditionalSum() : 0),
                                      'paidAmount': parseFloat(amountPaid || 0),
                                      'remainingAmount': tableRowSum() + tableRowNonTaxableSum() + (tableRowAdditional ? tableRowAdditionalSum() : 0) - tdsSum() - parseFloat(amountPaid || 0),
                                      'tdsAmount': tdsSum()
                                  }} type={'expenseEntryAdditional'}

                                  minLength={1}
                                  mode={mode}
                                  fromProduct={true}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )
};

export default TdsForm;
