import React from 'react';
import PageTopBar from "../../../accounting/organisms/pageTopBar";
import DividerComponent from "../../../shared/atom/divider";
import EstimateAdditionForm from "../../organisms/forms/estimate";
import {useLocation, useParams} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";

interface Props {
    from: string
}

const EstimateAddition: React.FC<Props> = ({from}) => {

    const isInvoice = from === 'invoice'

    // @ts-ignore
    const {id} = useParams();
    const path = useLocation();
    const isEdit = path.pathname.includes('edit');
    const isView = !isEdit && id;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div>
            <PageTopBar name={isEdit ? 'Edit Proforma/Estimate': isView? 'View Proforma/Estimate':'Create Proforma/Estimate'}/>

            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: matches ? '25px 0px 0px -0px': '25px 0px 10px 25px', overflow: "scroll"}}>
                <div style={{maxWidth: matches? "100vw": 1250, paddingRight: matches ? 10:20, paddingLeft: 25,paddingBottom: 80}}>
                    <EstimateAdditionForm mode={isEdit ? 'edit': isView ? 'view':'create'} from={from} id={id}/>
                </div>
            </div>
        </div>
    );
};

export default EstimateAddition;