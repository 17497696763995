import React, {useState} from 'react';
import SetupAddNew from "../../../organisms/setupAddNew";
import VendorTable from "../../../organisms/tables/vendorTable";
import SetupFileUpload from "../../../organisms/setupFileUpload";
import TableFilter from "../../../organisms/Forms/tableFilter";
import AccessDenied from "../../../templates/errorTemplates/accessDenied";
import AccessFilter from "../../../../../accessControl/accessFilter";

const VendorsSetup: React.FC = () => {

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})

    return (
        <div>


            <div style={{maxWidth: 1000 }}>

                <AccessFilter
                    section={"vendors"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <div style={{padding: 20}}>
                                        <SetupAddNew

                                            buttonNameAdd={{pathTo: '/dashboard/company-setup/vendors/add', name: 'Add Vendors'}}
                                            // buttonNameImport={{pathTo: '', name: 'Import Vendors from Head Office'}}

                                            message={
                                                <div style={{
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    padding: '10px 10px 10px 5px'
                                                }}>
                                                    <div>Click below to add vendors. Vendors can also be imported from parent.
                                                    </div>

                                                </div>
                                            }

                                        />
                                    </div>


                                    <div style={{width: 900, paddingLeft: 15, paddingBottom: 10}}>
                                        <SetupFileUpload/>
                                    </div>

                                </div>

                            </div>

                        )

                    }}

                />


                <AccessFilter
                    section={"vendors"}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>

                                    <div style={{padding: '0 30px 0 28px'}}>

                                        <div style={{marginBottom: -10}}>
                                            <TableFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                                         submitting={submitting}
                                                         from={'vendor'}
                                            />
                                        </div>



                                        <VendorTable formState={formState} setSubmitting={setSubmitting}/>
                                    </div>
                                </div>

                            </div>

                        )

                    }}

                />



            </div>


        </div>
    );
};

export default VendorsSetup;
