import React from 'react';
import PageTopBar from "../../../accounting/organisms/pageTopBar";
import DividerComponent from "../../../shared/atom/divider";
import PurchaseBillForm from "../../organisms/forms/purchaseBill";
import ExpenseEntryForm from "../../organisms/forms/expenseEntry";
import {useLocation, useParams} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";

const PurchaseEntryAddition: React.FC =  () => {

    // @ts-ignore
    const {id} = useParams();
    const path = useLocation();
    const isEdit = path.pathname.includes('edit');
    const isView = !isEdit && id;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div>
            <PageTopBar name={ isEdit? 'Edit Expense Entry': isView ? 'View Expense Entry':'Add Expense Entry'}/>

            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: matches ? '25px 0px 0px -0px': '25px 0px 10px 25px', overflow: "scroll"}}>
                <div style={{maxWidth: matches? "100vw": 1250, paddingRight: matches ? 10:20, paddingLeft: 25,paddingBottom: 80}}>
                <ExpenseEntryForm mode={isEdit ? 'edit' : isView? 'view':'create'} from={'expense-entry'} id={id}/>
                </div>
            </div>
        </div>
    );
};

export default PurchaseEntryAddition;