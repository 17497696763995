import React, {useEffect, useState} from 'react';
import useSWR from "swr";
import * as Yup from "yup";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Grid} from "@material-ui/core";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import Button from "../../../../shared/atom/button";
import {useDispatch, useSelector} from "react-redux";
import {convertAdToBs, convertBsToAd, getTodaysDate} from "../../../../../config/dateFunctions";
import {matchSorter} from "match-sorter";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import CircularSpinner from "../../../../shared/atom/spinner";
import {RootState} from "../../../../../store/RootReducer";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {FaCalendarAlt} from "react-icons/fa";
import AccessFilter from "../../../../../accessControl/accessFilter";
import {useLocation, useParams} from "react-router-dom";
import RequiredNotation from "../../../molecules/requiredNotation";
import {loadFeatures} from "framer-motion/types/motion/features/definitions";
import {getFirstFiscalDate} from "../../../../../config/fiscalYear";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

const GeneralLedgerFilterForm: React.FC<any> = (Props: any) => {

    const initialValues = {
        accountHead: null,
        dateFrom: null,
        branch: null,
        dateTill: null,
        transactionYearAdFrom: null,
        transactionMonthAdFrom: null,
        transactionDateAdFrom: null,
        transactionYearBsFrom: null,
        transactionMonthBsFrom: null,
        transactionDateBsFrom: null,
        transactionYearAdTill: null,
        transactionMonthAdTill: null,
        transactionDateAdTill: null,
        transactionYearBsTill: null,
        transactionMonthBsTill: null,
        transactionDateBsTill: null,
    }

    // @ts-ignore
    const location = useLocation();

    // @ts-ignore
    useEffect(() => {

        if(!location.pathname.includes('head')) return;
        if (!localStorage.getItem('search')) return

        // @ts-ignore
        let {accountHead, dateFrom, branch, dateTill, transactionYearAdFrom, transactionMonthAdFrom, transactionDateAdFrom, transactionYearBsFrom, transactionMonthBsFrom, transactionDateBsFrom, transactionYearAdTill, transactionMonthAdTill, transactionDateAdTill, transactionYearBsTill, transactionMonthBsTill, transactionDateBsTill} = JSON.parse(localStorage.getItem('search'))
        const fromDate = (transactionYearAdFrom && transactionMonthAdFrom && transactionDateAdFrom) ? transactionYearAdFrom + '-' + ('0' + transactionMonthAdFrom).slice(-2) + '-' + ('0' + transactionDateAdFrom).slice(-2) : null;
        const tillDate = (transactionYearAdTill && transactionMonthAdTill && transactionDateAdTill) ? transactionYearAdTill + '-' + ('0' + transactionMonthAdTill).slice(-2) + '-' + ('0' + transactionDateAdTill).slice(-2) : null;

        // @ts-ignore
        setFormState(JSON.parse(localStorage.getItem('search')))
        // setFormState(location.state.formState)
        // @ts-ignore
        Props.setStartDate(fromDate);
        // @ts-ignore
        Props.setEndDate(tillDate);
        // @ts-ignore
        Props.setBranch(branch)
        // @ts-ignore
        Props.setFormState(JSON.parse(localStorage.getItem('search')))

    }, [])

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();
    const dashboard = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {

        // if(!location.pathname.includes('head')) return;
        // if (!localStorage.getItem('search')) return

        if(!nepEngDates) return;
        if(!formatedNepEngDates) return;

        let adDate = getFirstFiscalDate(nepEngDates.data, dashboard.fiscalYear);
        let bsDate: any = convertAdToBs(adDate.join('-'), formatedNepEngDates) || [];

        let adDateTill = getTodaysDate().split('-');
        let bsDateTill: any = convertAdToBs(getTodaysDate(), formatedNepEngDates) || [];

        if(Props.from !== 'generalLedger'){
            Props.setStartDate(adDate.join('-'));
            Props.setEndDate(adDateTill.join('-'));
        }

        // @ts-ignore
        let tempJson = {
            transactionYearAdFrom: adDate[0],
            transactionDateAdFrom: adDate[2],
            transactionMonthAdFrom: adDate[1],
            transactionDateBsFrom: bsDate[2],
            transactionMonthBsFrom: bsDate[1],
            transactionYearBsFrom: bsDate[0],
            transactionYearAdTill: adDateTill[0],
            transactionMonthAdTill: adDateTill[1],
            transactionDateAdTill: adDateTill[2],
            transactionYearBsTill: bsDateTill[0],
            transactionMonthBsTill: bsDateTill[1],
            transactionDateBsTill: bsDateTill[2],
        }

        setFormState((prevState: any) => {
            return {
                ...prevState,
                ...tempJson
            }
        })


    }, [nepEngDates, formatedNepEngDates])


    const [formState, setFormState] = useState(initialValues)

    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');
    const {data: branchOptions} = useSWR('/getAllBranches?isActive=true');
    const {data: branchOptionsInactive} = useSWR('/getAllBranches?isActive=false');


    const validationSchema1 = Yup.object({
        accountHead: Yup.object().required('Required').nullable(true)
    })
    const validationSchema2 = Yup.object({})

    const dispatch = useDispatch();
    const onSubmit = async (values: any, formActions: any) => {

        //checking fiscal year on the basis of month
        if(parseInt(values.transactionMonthBsFrom) < 3 && parseInt(values.transactionMonthBsTill) >= 3){
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `Date range is not of same fiscal year`, type: 'warning'}
            })
            return
        }

        const fromDate = (values.transactionYearAdFrom && values.transactionMonthAdFrom && values.transactionDateAdFrom) ? values.transactionYearAdFrom + '-' + ('0' + values.transactionMonthAdFrom).slice(-2) + '-' + ('0' + values.transactionDateAdFrom).slice(-2) : null;
        const tillDate = (values.transactionYearAdTill && values.transactionMonthAdTill && values.transactionDateAdTill) ? values.transactionYearAdTill + '-' + ('0' + values.transactionMonthAdTill).slice(-2) + '-' + ('0' + values.transactionDateAdTill).slice(-2) : null;

        try {

            if (Props.from === 'generalLedger') {
                const {data} = await axiosInstance.get(`/report/getBalance?from=${fromDate || ''}&to=${tillDate || ''}&accHeadId=${values.accountHead.id}&branchId=${values.branch ? values.branch.id : null}`)
                handleResponse(data, values)
            } else if (Props.from === 'groupSummary') {
                Props.setStartDate(fromDate);
                Props.setEndDate(tillDate);
                Props.setFormState(values)
                localStorage.setItem('search', JSON.stringify(values));
                Props.setBranch(values.branch)
                // Props.setSubmitting(true);
                return

                const {data} = await axiosInstance.get(`${Props.path}?from=${fromDate}&to=${tillDate}${Props.id ? ('&id=' + Props.id) : ''}`)
                handleResponse(data, values)
            }

        } catch (e) {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `Something went wrong`, type: 'warning'}
            })
            Props.setSubmitting(false);
            formActions.setSubmitting(false)

        }
        Props.setSubmitting(false);
        formActions.setSubmitting(false)
    }


    const handleResponse = (data: any, values: any) => {
        if (data.code === 'OK') {
            if (Props.from === 'generalLedger') {
                const tempData = data.data
                let anotherTemp = tempData.map((x: any) => {

                    let headAgainst = x.journalRows.filter((item: any) => item.accountHead.id !== values.accountHead.id).map((item: any) => item.accountHead.name).join(', ');

                    return {
                        ...x,
                        headAgainst: headAgainst,
                        journalRows: x.journalRows.filter((item: any) => item.accountHead.id === values.accountHead.id)[0]
                    }
                })

                dispatch({type: 'Add_LEDGER', payload: anotherTemp})
            } else {
                dispatch({type: 'Add_LEDGER', payload: data})
            }

        } else {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `${data.message}`, type: 'warning'}
            })
        }
    }

    const filterFormData = useSelector((state: RootState) => state.reportFilterReducer);

    const styleLib = {
        label: {
            color: COLOR_PROFILE.primaryColor,
            fontWeight: 600,
            fontSize: 14
        }

    }

    const handleDateAdBlurFrom = (form: any) => {

        const {transactionYearAdFrom, transactionMonthAdFrom, transactionDateAdFrom} = form.values
        const dateInBs = convertAdToBs(transactionYearAdFrom + '-' + transactionMonthAdFrom + '-' + transactionDateAdFrom, formatedNepEngDates);

        // @ts-ignore
        if (!dateInBs) return;

        // @ts-ignore
        form.setFieldValue(`transactionYearBsFrom`, dateInBs[0])
        // @ts-ignore
        form.setFieldValue(`transactionMonthBsFrom`, parseInt(dateInBs[1]).toString())
        // @ts-ignore
        form.setFieldValue(`transactionDateBsFrom`, parseInt(dateInBs[2]).toString());


    }

    const handleDateBsBlurFrom = (form: any) => {
        const {transactionYearBsFrom, transactionMonthBsFrom, transactionDateBsFrom} = form.values
        const dateInAd = convertBsToAd(transactionYearBsFrom + '-' + transactionMonthBsFrom + '-' + transactionDateBsFrom, formatedNepEngDates);

        // @ts-ignore
        if (!dateInAd) return;

        // @ts-ignore
        let deconstructedDate = dateInAd?.split('T')[0]?.split('-');
        // @ts-ignore
        form.setFieldValue(`transactionYearAdFrom`, deconstructedDate[0])
        // @ts-ignore
        form.setFieldValue(`transactionMonthAdFrom`, parseInt(deconstructedDate[1]).toString())
        // @ts-ignore
        form.setFieldValue(`transactionDateAdFrom`, parseInt(deconstructedDate[2]).toString())


    }

    const handleDateAdBlurTill = (form: any) => {
        const {transactionYearAdTill, transactionMonthAdTill, transactionDateAdTill} = form.values
        const dateInBs = convertAdToBs(transactionYearAdTill + '-' + transactionMonthAdTill + '-' + transactionDateAdTill, formatedNepEngDates);

        // @ts-ignore
        if (!dateInBs) return;


        // @ts-ignore
        form.setFieldValue(`transactionYearBsTill`, dateInBs[0])
        // @ts-ignore
        form.setFieldValue(`transactionMonthBsTill`, parseInt(dateInBs[1]).toString())
        // @ts-ignore
        form.setFieldValue(`transactionDateBsTill`, parseInt(dateInBs[2]).toString())


    }

    const handleDateBsBlurTill = (form: any) => {
        const {transactionYearBsTill, transactionMonthBsTill, transactionDateBsTill} = form.values
        const dateInAd = convertBsToAd(transactionYearBsTill + '-' + transactionMonthBsTill + '-' + transactionDateBsTill, formatedNepEngDates);

        // @ts-ignore
        if (!dateInAd) return;

        // @ts-ignore
        let deconstructedDate = dateInAd?.split('T')[0]?.split('-');
        // @ts-ignore
        form.setFieldValue(`transactionYearAdTill`, deconstructedDate[0])
        // @ts-ignore
        form.setFieldValue(`transactionMonthAdTill`, parseInt(deconstructedDate[1]).toString())
        // @ts-ignore
        form.setFieldValue(`transactionDateAdTill`, parseInt(deconstructedDate[2]).toString())


    }

    const isGeneralLedger = Props.from === 'generalLedger';

    const [formData, setFormData] = useState(initialValues);

    useEffect(() => {

        setFormData(filterFormData)

    }, [filterFormData])

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }


    return (
        <div>

            <Formik

                initialValues={formState}
                // initialValues={Props.from !== 'generalLedger' ? filterFormData : initialValues}
                enableReinitialize={true}
                onSubmit={onSubmit}
                validationSchema={Props.from === 'generalLedger' ? validationSchema1 : validationSchema2}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {

                    return (
                        <div>
                            <PagePrompt formik={formik}/>

                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>
                                <Grid container>
                                    {isGeneralLedger && (
                                        <>
                                            <Grid container item sm={6} alignItems={'center'} spacing={0}
                                                  style={{marginBottom: 10}}>
                                                <Grid item xs={4}>
                                                    <label htmlFor="accountHead"
                                                           style={{fontWeight: "bold", fontSize: 14}}> Account
                                                        Head:<RequiredNotation/></label>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'accountHead'}
                                                        >
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;
                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={'accountHead'} {...field}
                                                                                               size={"small"}
                                                                                               onChange={(e, value) => {

                                                                                                   form.setFieldValue("accountHead", value)
                                                                                               }}
                                                                                               options={accountHeadOptions ? accountHeadOptions.data : []}
                                                                                               loading={!accountHeadOptions}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               filterOptions={(options, {inputValue}) => {
                                                                                                   return matchSorter(options, inputValue, {keys: ['name', 'code']});
                                                                                               }}
                                                                                               renderOption={(option) => {
                                                                                                   return (
                                                                                                       <div>
                                                                                                           <div style={{
                                                                                                               fontSize: 12,
                                                                                                               color: COLOR_PROFILE.buttonBlue
                                                                                                           }}>
                                                                                                               {option.code}
                                                                                                           </div>
                                                                                                           <div>{option.name}</div>
                                                                                                       </div>
                                                                                                   )
                                                                                               }}
                                                                                               getOptionLabel={option => option.name}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                                <span style={{
                                                                                    color: 'red',
                                                                                    fontSize: 12,
                                                                                    bottom: 0,
                                                                                    left: 2
                                                                                }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                            </Grid>


                                        </>
                                    )}

                                    <Grid container item sm={6}
                                          style={{marginBottom: 10}} alignItems={"center"}>

                                        <AccessFilter

                                            section={"balanceSheet"}
                                            privilege={"read"}
                                            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                                                if (!hasAllAccess) return null

                                                return (

                                                    <>
                                                        <Grid item xs={4}
                                                              style={{paddingLeft: isGeneralLedger ? '10%' : 0}}>
                                                            <label htmlFor="branch"
                                                                   style={{
                                                                       fontWeight: "bold",
                                                                       fontSize: 14
                                                                   }}> Branch:</label>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'branch'}
                                                                >
                                                                    {
                                                                        (props: { field: any; form: any; meta: any; }) => {

                                                                            const {field, form, meta} = props;


                                                                            return (
                                                                                <>
                                                                                    <AutoCompleteInput type={'text'}
                                                                                                       id={'branch'} {...field}
                                                                                                       size={"small"}
                                                                                                       onChange={(e, value) => {

                                                                                                           form.setFieldValue("branch", value)
                                                                                                       }}
                                                                                                       options={branchOptions ? branchOptions.data : []}
                                                                                                       loading={!branchOptions}
                                                                                                       getOptionLabel={option => option.name}
                                                                                                       error={meta.touched && meta.error}
                                                                                    />
                                                                                    <div style={{position: "absolute"}}>
                                                                                <span style={{
                                                                                    color: 'red',
                                                                                    fontSize: 12,
                                                                                    bottom: 0,
                                                                                    left: 2
                                                                                }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                                    </div>
                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                </Field>

                                                            </div>

                                                        </Grid>
                                                    </>

                                                )

                                            }}

                                        />


                                    </Grid>

                                    {!isGeneralLedger && (

                                        <Grid container item sm={6} alignItems={'center'}
                                              style={{marginBottom: 15}}>
                                        </Grid>
                                    )}

                                    <Grid container item sm={6} alignItems={'center'}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="dateFrom"
                                                   style={{fontWeight: "bold", fontSize: 14}}>From:</label>
                                        </Grid>
                                        <Grid item container xs={8}>
                                            <Grid item container xs={6} style={{paddingRight: 10}}>
                                                <Grid item container xs={12}
                                                      style={{...styleLib.label, textAlign: 'center'}}
                                                      justify={"center"}
                                                      spacing={1}
                                                >
                                                    <Grid item>
                                                        <div>Ad</div>
                                                    </Grid>
                                                    <Grid item>
                                                        <FaCalendarAlt style={{
                                                            fontSize: 12,
                                                            color: COLOR_PROFILE.buttonBlue
                                                        }}/>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={4}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionYearAdFrom'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionYearAdFrom`} {...field}
                                                                                               size={"small"}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               placeholder={'Year'}
                                                                                               onBlur={() => handleDateAdBlurFrom(form)}
                                                                                               padding={0}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionYearAdFrom`, value)
                                                                                               }}
                                                                                               options={englishYear}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                                <Grid item xs={4}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionMonthAdFrom'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionMonthAdFrom`} {...field}
                                                                                               size={"small"}
                                                                                               placeholder={'Month'}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               padding={0}
                                                                                               onBlur={() => handleDateAdBlurFrom(form)}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionMonthAdFrom`, value)
                                                                                               }}
                                                                                               options={englishMonths}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                                <Grid item xs={4}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionDateAdFrom'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;

                                                                    const getDaysOptions = () => {
                                                                        const {
                                                                            transactionYearAdFrom,
                                                                            transactionMonthAdFrom
                                                                        } = form.values
                                                                        if (!transactionMonthAdFrom && !transactionMonthAdFrom) return []

                                                                        let daysInMonth = new Date(transactionYearAdFrom, transactionMonthAdFrom - 1, 0).getDate();

                                                                        let dateArray = [];

                                                                        for (let i = 0; i < daysInMonth; i++) {
                                                                            dateArray[i] = (i + 1).toString()
                                                                        }

                                                                        return dateArray;
                                                                    }


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionDateAdFrom`} {...field}
                                                                                               size={"small"}
                                                                                               filterOptions={(options, {inputValue}) => {
                                                                                                   let filteredOptions = getDaysOptions()
                                                                                                   return matchSorter(filteredOptions, inputValue).sort((a:any, b:any) => parseInt(a)- parseInt(b));
                                                                                               }}
                                                                                               padding={0}
                                                                                               placeholder={'Day'}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               onBlur={() => handleDateAdBlurFrom(form)}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionDateAdFrom`, value)
                                                                                               }}
                                                                                               options={[]}


                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>

                                            </Grid>

                                            <Grid item container xs={6} style={{paddingLeft: 10}}>
                                                <Grid item container xs={12}
                                                      style={{...styleLib.label, textAlign: 'center'}}
                                                      justify={"center"}
                                                      spacing={1}
                                                >
                                                    <Grid item>
                                                        <div>BS</div>
                                                    </Grid>
                                                    <Grid item>
                                                        <FaCalendarAlt style={{
                                                            fontSize: 12,
                                                            color: COLOR_PROFILE.buttonBlue
                                                        }}/>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={4} style={{marginTop: 0}}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionYearBsFrom'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionYearBsFrom`} {...field}
                                                                                               size={"small"}
                                                                                               placeholder={'Year'}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionYearBsFrom`, value)
                                                                                               }}
                                                                                               options={nepaliYear}
                                                                                               padding={0}
                                                                                               onBlur={() => handleDateBsBlurFrom(form)}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                                <Grid item xs={4} style={{marginTop: 0}}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionMonthBsFrom'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionMonthBsFrom`} {...field}
                                                                                               size={"small"}
                                                                                               placeholder={'Month'}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionMonthBsFrom`, value)
                                                                                               }}
                                                                                               onBlur={() => handleDateBsBlurFrom(form)}
                                                                                               padding={0}
                                                                                               options={nepaliMonths}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                                <Grid item xs={4} style={{marginTop: 0}}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionDateBsFrom'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;

                                                                    const getDateOptions = () => {

                                                                        const {
                                                                            transactionYearBsFrom,
                                                                            transactionMonthBsFrom
                                                                        } = form.values
                                                                        if (!transactionMonthBsFrom && !transactionMonthBsFrom) return [];

                                                                        // @ts-ignore
                                                                        return formatedNepEngDates.filter((item: any) => (parseInt(item.nepaliYear) === parseInt(transactionYearBsFrom)) && (parseInt(item.nepaliMonth) === parseInt(transactionMonthBsFrom)))[0].days


                                                                    }


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionDateBsFrom`} {...field}
                                                                                               size={"small"}
                                                                                               filterOptions={(options, {inputValue}) => {
                                                                                                   let filteredOptions = getDateOptions()
                                                                                                   return matchSorter(filteredOptions, inputValue).sort((a:any, b:any) => parseInt(a)- parseInt(b));
                                                                                               }}
                                                                                               placeholder={'Day'}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionDateBsFrom`, value)
                                                                                               }}
                                                                                               options={[]}
                                                                                               padding={0}
                                                                                               onBlur={() => handleDateBsBlurFrom(form)}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4} style={{paddingLeft: '10%'}}>
                                            <label htmlFor="dateTill"
                                                   style={{fontWeight: "bold", fontSize: 14}}>Till:</label>
                                        </Grid>
                                        <Grid item container xs={8}>


                                            <Grid item container xs={6} style={{paddingRight: 10}}>
                                                <Grid item container xs={12}
                                                      style={{...styleLib.label, textAlign: 'center'}}
                                                      justify={"center"}
                                                      spacing={1}
                                                >
                                                    <Grid item>
                                                        <div>Ad</div>
                                                    </Grid>
                                                    <Grid item>
                                                        <FaCalendarAlt style={{
                                                            fontSize: 12,
                                                            color: COLOR_PROFILE.buttonBlue
                                                        }}/>
                                                    </Grid>
                                                </Grid>


                                                <Grid item xs={4}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionYearAdTill'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionYearAdTill`} {...field}
                                                                                               size={"small"}
                                                                                               placeholder={'Year'}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionYearAdTill`, value)
                                                                                               }}
                                                                                               onBlur={() => handleDateAdBlurTill(form)}

                                                                                               padding={0}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               options={englishYear}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                                <Grid item xs={4}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionMonthAdTill'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionMonthAdTill`} {...field}
                                                                                               size={"small"}
                                                                                               placeholder={'Month'}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionMonthAdTill`, value)
                                                                                               }}
                                                                                               padding={0}
                                                                                               onBlur={() => handleDateAdBlurTill(form)}
                                                                                               options={englishMonths}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                                <Grid item xs={4}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionDateAdTill'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    const getDaysOptions = () => {
                                                                        const {
                                                                            transactionYearAdTill,
                                                                            transactionMonthAdTill
                                                                        } = form.values
                                                                        if (!transactionMonthAdTill && !transactionMonthAdTill) return []

                                                                        let daysInMonth = new Date(transactionYearAdTill, transactionMonthAdTill - 1, 0).getDate();

                                                                        let dateArray = [];

                                                                        for (let i = 0; i < daysInMonth; i++) {
                                                                            dateArray[i] = (i + 1).toString()
                                                                        }

                                                                        return dateArray;
                                                                    }


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionDateAdTill`} {...field}
                                                                                               size={"small"}
                                                                                               filterOptions={(options, {inputValue}) => {
                                                                                                   let filteredOptions = getDaysOptions()
                                                                                                   return matchSorter(filteredOptions, inputValue).sort((a:any, b:any) => parseInt(a)- parseInt(b));
                                                                                               }}
                                                                                               placeholder={'Day'}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               padding={0}
                                                                                               onBlur={() => handleDateAdBlurTill(form)}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionDateAdTill`, value)
                                                                                               }}
                                                                                               options={[]}


                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                            </Grid>

                                            <Grid item container xs={6} style={{paddingLeft: 10}}>
                                                <Grid item container xs={12}
                                                      style={{...styleLib.label, textAlign: 'center'}}
                                                      justify={"center"}
                                                    // spacing={1}
                                                >
                                                    <Grid item>
                                                        <div>BS</div>
                                                    </Grid>
                                                    <Grid item>
                                                        <FaCalendarAlt style={{
                                                            fontSize: 12,
                                                            color: COLOR_PROFILE.buttonBlue
                                                        }}/>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={4} style={{marginTop: 0}}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionYearBsTill'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionYearBsTill`} {...field}
                                                                                               size={"small"}
                                                                                               placeholder={'Year'}

                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionYearBsTill`, value)
                                                                                               }}
                                                                                               options={nepaliYear}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               padding={0}
                                                                                               onBlur={() => handleDateBsBlurTill(form)}

                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                                <Grid item xs={4} style={{marginTop: 0}}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionMonthBsTill'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionMonthBsTill`} {...field}
                                                                                               size={"small"}
                                                                                               placeholder={'Month'}

                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionMonthBsTill`, value)
                                                                                               }}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               padding={0}
                                                                                               onBlur={() => handleDateBsBlurTill(form)}
                                                                                               options={nepaliMonths}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>
                                                <Grid item xs={4} style={{marginTop: 0}}>

                                                    <div style={{position: 'relative'}}>
                                                        <Field name={'transactionDateBsTill'}>
                                                            {
                                                                (props: { field: any; form: any; meta: any; }) => {

                                                                    const {field, form, meta} = props;

                                                                    const getDateOptions = () => {

                                                                        const {
                                                                            transactionYearBsTill,
                                                                            transactionMonthBsTill
                                                                        } = form.values
                                                                        if (!transactionMonthBsTill && !transactionMonthBsTill) return [];

                                                                        // @ts-ignore
                                                                        return formatedNepEngDates.filter((item: any) => (parseInt(item.nepaliYear) === parseInt(transactionYearBsTill)) && (parseInt(item.nepaliMonth) === parseInt(transactionMonthBsTill)))[0].days


                                                                    }


                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`transactionDateBsTill`} {...field}
                                                                                               size={"small"}
                                                                                               filterOptions={(options, {inputValue}) => {
                                                                                                   let filteredOptions = getDateOptions()
                                                                                                   return matchSorter(filteredOptions, inputValue).sort((a:any, b:any) => parseInt(a)- parseInt(b));
                                                                                               }}
                                                                                               placeholder={'Day'}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               forcePopupIcon={false}
                                                                                               disableClearable={true}
                                                                                               padding={0}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`transactionDateBsTill`, value)
                                                                                               }}
                                                                                               options={[]}

                                                                                               onBlur={() => handleDateBsBlurTill(form)}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                            </div>
                                                                        </>

                                                                    )
                                                                }
                                                            }
                                                        </Field>

                                                    </div>

                                                </Grid>

                                            </Grid>


                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid container justify={'space-between'}>

                                    <Grid item container justify={"flex-start"} spacing={2} alignItems={'center'}
                                          xs={6}>

                                        <Grid item>
                                            <div style={{width: 100}}>

                                                <Button variant={"contained"} type={"submit"} customColor={'#297ECC'}
                                                        textColor={'white'}> Generate</Button>

                                            </div>
                                        </Grid>
                                        <Grid item>
                                            {((formik.isSubmitting || Props.submitting) && formik.touched) &&
                                            <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        {/*<div style={{width: 100}}>*/}

                                        {/*    <Button variant={"text"} type={"reset"} customColor={'#297ECC'}*/}
                                        {/*            textColor={'#297ECC'}> Reset Form </Button>*/}

                                        {/*</div>*/}
                                    </Grid>
                                </Grid>
                                <SetterComponent/>

                            </Form>
                        </div>
                    )
                }}

            </Formik>
        </div>
    );
};

const SetterComponent = () => {
    const {values} = useFormikContext();
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch({type: "ADD_REPORT_FILTER", payload: values})

    }, [values])

    return (
        <>
        </>
    )
}


export default GeneralLedgerFilterForm;
