import {Route} from "react-router-dom";
import {motion} from "framer-motion";
import AccessFilter from "../../../accessControl/accessFilter";
import AccessDenied from "../../../components/accounting/templates/errorTemplates/accessDenied";
import ChartOfAccounts from "../../../components/accounting/virtualPages/accountingSetup/chartOfAccounts";
import AccountGroupPage from "../../../components/accounting/virtualPages/accountingSetup/accountGroupPage";
import AddAccountGroupPage from "../../../components/accounting/virtualPages/accountingSetup/addAccountGroupPage";
import AccountHeadPage from "../../../components/accounting/virtualPages/accountingSetup/accountHeadPage";
import AddAccountHeadPage from "../../../components/accounting/virtualPages/accountingSetup/addAccountHeadPage";
import React from "react";

export const AccountingSetupRoutes = [

    <Route path={'/dashboard/accounting-setup'} exact>
        <div>
            Lets show some quick links and reports here just to make sense of hierarchy
        </div>
    </Route>,

    <Route path={'/dashboard/accounting-setup/chart-of-accounts'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"accountGroup"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>

                            <div>
                                <ChartOfAccounts/>
                            </div>

                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/accounting-setup/account-group'} exact>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"accountGroup"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>

                            <div>
                                <AccountGroupPage/>
                            </div>

                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/accounting-setup/account-group/add'} exact>

        <AccessFilter

            section={"accountGroup"}
            privilege={"write"}
            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                if (!isAllowed) return <AccessDenied/>

                return (

                    <div>
                        <div>
                            <AddAccountGroupPage/>
                        </div>
                    </div>

                )

            }}

        />
    </Route>,

    <Route path={'/dashboard/accounting-setup/account-group/edit/:id'} exact>

        <AccessFilter

            section={"accountGroup"}
            privilege={"update"}
            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                if (!isAllowed) return <AccessDenied/>

                return (

                    <div>
                        <div>
                            <AddAccountGroupPage/>
                        </div>
                    </div>

                )

            }}

        />
    </Route>,

    <Route path={'/dashboard/accounting-setup/account-head'} exact>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"accountHead"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <AccountHeadPage/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/accounting-setup/account-head/add'} exact>

        <AccessFilter

            section={"accountHead"}
            privilege={"write"}
            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                if (!isAllowed) return <div>Access Denied</div>

                return (

                    <div>
                        <div>
                            <AddAccountHeadPage/>
                        </div>
                    </div>

                )

            }}

        />
    </Route>,

    <Route path={'/dashboard/accounting-setup/account-head/edit/:id'} exact>
        <AccessFilter
            section={"accountHead"}
            privilege={"update"}
            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                if (!isAllowed) return <AccessDenied/>

                return (

                    <div>
                        <div>
                            <AddAccountHeadPage/>
                        </div>
                    </div>

                )

            }}

        />
    </Route>,

]