import React from 'react';
import TermsAndConditionForm from "../../organisms/forms/termsAndConditions";

const TermsAndConditions = () => {
    return (
        <div>
            <div>
            </div>

            <TermsAndConditionForm/>
        </div>
    );
};

export default TermsAndConditions;