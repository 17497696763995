import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useSWR, {trigger} from "swr";
import useServerCall from "../../../../accounting/organisms/reactTable/useServerCall";
import ReactTable from "../../../../accounting/organisms/reactTable/ReactTable";
import DataEmptyMessage from "../../../../accounting/organisms/tables/dataEmptyMessage";
import {convertAdToBs, convertAdToBsDisplay, getTodaysDate} from "../../../../../config/dateFunctions";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DashboardReportItem from "../../../../accounting/molecules/dashboardReportItem";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import SetupFileUpload from "../../../../accounting/organisms/setupFileUpload";

const InvoiceTable: React.FC<any>  = (Props) => {
    const {formState} = Props;

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);

    const history = useHistory();
    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();


    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {
                let id: any = val.row.original.id;

                return (
                    <Grid container spacing={2} justify='center' alignItems={"center"}>

                        <Grid item>
                            <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                        onClick={() => {
                                            history.push(`/dashboard/sales/invoice/view/${val.row.original.id}`,
                                                {...val.row.original}
                                            )
                                        }}>
                                <VisibilityIcon fontSize={"small"}/>
                            </IconButton>
                        </Grid>


                    </Grid>
                )
            }
        },
        {
            Header: 'Bill No.',
            Footer: 'Bill No.',
            accessor: 'billNumber'
        },
        {
            Header: 'Invoice Date',
            Footer: 'Bill Date',
            accessor: 'createdAt',
            Cell: (val: any) => {
                let temp = val.row.original.createdAt;
                return <div> {convertAdToBsDisplay(temp.split('T')[0], formatedNepEngDates)}</div>
            }
        },
        {
            Header: 'Is Synced?',
            accessor: 'syncWithIrd',
            Cell: (val: any) => {
                let temp = val.row.original.syncWithIrd;
                return <div>{temp ? 'True': 'False'}</div>
            }
        },
        {
            Header: 'Is Canceled?',
            accessor: 'canceled',
            Cell: (val: any) => {
                let temp = val.row.original.canceled;
                return <div>{temp ? 'True': 'False'}</div>
            }
        },

        {
            Header: 'Customer',
            Footer: 'Customer',
            accessor: 'customer.name'
        },

    ]

    const trailColumns = [

        {
            id: 'delete',
            Header: 'Action',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {
                let id: any = val.row.original.id;

                return (
                    <Grid container spacing={2} justify='center' alignItems={"center"}>

                        <Grid item>
                            <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                        onClick={() => {
                                            history.push(`/dashboard/sales/invoice/view/${val.row.original.id}`,
                                                {...val.row.original}
                                            )
                                        }}>
                                <VisibilityIcon fontSize={"small"}/>
                            </IconButton>
                        </Grid>


                    </Grid>
                )
            }
        },
        {
            Header: 'Bill No.',
            accessor: 'billNumber',

        },
        {
            Header: 'Customer',
            accessor: 'customer.name'
        },
        {
            Header: 'Invoice Date',
            accessor: 'invoiceDate',
            Cell: (val: any) => {
                let temp = val.row.original.createdAt;
                return <div> {convertAdToBsDisplay(temp.split('T')[0], formatedNepEngDates)}</div>
            }
        },
        {
            Header: 'Created By',
            accessor: 'createdBy.name'
        },
        {
            Header: 'Is Printed?',
            accessor: 'billPrinted',
            Cell: (val: any) => {
                let temp = val.row.original.billPrinted;

                return <div>{temp ? 'True': 'False'}</div>
            }
        },
        {
            Header: 'Printed By',
            accessor: 'printedBy.name'
        },
        {
            Header: 'Printed At',
            accessor: 'printedTime'
        },
        {
            Header: 'No Of Prints',
            accessor: 'printNumber'
        },
        {
            Header: 'Is Canceled?',
            accessor: 'canceled',
            Cell: (val: any) => {
                let temp = val.row.original.canceled;
                return <div>{temp ? 'True': 'False'}</div>
            }
        },
        {
            Header: 'Canceled By',
            accessor: 'canceledBy.name'
        },
        {
            Header: 'Canceled At',
            accessor: 'canceledAt'
        },

    ]

    const  reportColumn = [
        {
            Header: 'Bill No',
            width:30,
            accessor: 'billNumber',
            Cell: (val: any) => {
                let temp = val.row.original.billNumber;
                return (
                    <div style={{minWidth: 30}}>{temp}</div>
                )
            }
        },
        {
            Header: 'Bill Date',
            accessor: 'createdAt',
            Cell: (val: any) => {
                let temp = val.row.original.createdAt;
                return <div> {convertAdToBsDisplay(temp.split('T')[0], formatedNepEngDates)}</div>
            }
        },
        {
            Header: 'Customer',
            accessor: 'customer.name'
        },
        {
            Header: 'Is Canceled?',
            accessor: 'canceled',
            Cell: (val: any) => {

                let temp = val.row.original.canceled;
                return <div>{temp ? 'True': 'False'}</div>
            }
        },
        {
            Header: 'Total Amount',
            accessor: 'totalAmount',
            Cell: (val: any) => {
                let temp = val.row.original.totalAmount;
                return <div>Rs. {temp}</div>
            }

        },
        {
            Header: 'No of Services',
            accessor: 'services',
            Cell: (val: any) => {
                let temp = val.row.original.productBundleSubs;
                return <div>{temp.length}</div>
            }

        }
    ]

    const  reportColumnCanceled = [
        {
            Header: 'Bill No',
            width:30,
            accessor: 'billNumber',
            Cell: (val: any) => {
                let temp = val.row.original.billNumber;
                return (
                    <div style={{minWidth: 30}}>{temp}</div>
                )
            }
        },
        {
            Header: 'Bill Date',
            accessor: 'createdAt',
            Cell: (val: any) => {
                let temp = val.row.original.createdAt;
                return <div> {convertAdToBsDisplay(temp.split('T')[0], formatedNepEngDates)}</div>
            }
        },
        {
            Header: 'Customer',
            accessor: 'customer.name'
        },
        {
            Header: 'Total Amount',
            accessor: 'totalAmount',
            Cell: (val: any) => {
                let temp = val.row.original.totalAmount;
                return <div>Rs. {temp}</div>
            }

        },
        {
            Header: 'No of Services',
            accessor: 'services',
            Cell: (val: any) => {
                let temp = val.row.original.productBundleSubs;
                return <div>{temp.length}</div>
            }

        },
        {
            Header: 'Canceled By',
            accessor: 'canceledBy.name'
        },
        {
            Header: 'Canceled At',
            accessor: 'canceledAt'
        },
    ]

    const path = '/getInvoices'

    const sumPath = '/getInvoicesTotal'

    const getParam = (formState: any) => {

        return `customerId=${formState.customer ? formState.customer.id : 0}&billType=${formState.billType || "All"}&from=${
            (formState.transactionYearAdFrom && formState.transactionMonthAdFrom && formState.transactionDateAdFrom) ? (formState.transactionYearAdFrom + '-' + ('0' + formState?.transactionMonthAdFrom).slice(-2) + '-' + ('0' + formState.transactionDateAdFrom).slice(-2)) : ''
        }&to=${
            (formState.transactionYearAdTill && formState.transactionMonthAdTill && formState.transactionDateAdTill) ? (formState.transactionYearAdTill + '-' + ('0' + formState?.transactionMonthAdTill).slice(-2) + '-' + ('0' + formState.transactionDateAdTill).slice(-2)) : getTodaysDate()
        }`

    }

    useEffect(() => {
        if(Props.submitting) return;

        trigger(`${path}/${page -1}/${perPage}
        ?${getParam(formState)}`)

    }, [Props.submitting])

    const {data: sumData} = useSWR(`${sumPath}?${getParam(formState)}`)

    const {data, loading} = useServerCall({
        path, page, perPage,
        search: `?${getParam(formState)}`
    })

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    // const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}?customerId=${formState.customer ? formState.customer.id : 0}&from=${
    //     (formState.transactionYearAdFrom && formState.transactionMonthAdFrom && formState.transactionDateAdFrom) ? (formState.transactionYearAdFrom + '-' + ('0' + formState?.transactionMonthAdFrom).slice(-2) + '-' + ('0' + formState.transactionDateAdFrom).slice(-2)) : ''
    // }&to=${
    //     (formState.transactionYearAdTill && formState.transactionMonthAdTill && formState.transactionDateAdTill) ? (formState.transactionYearAdTill + '-' + ('0' + formState?.transactionMonthAdTill).slice(-2) + '-' + ('0' + formState.transactionDateAdTill).slice(-2)) : getTodaysDate()
    // }`)

    // @ts-ignore
    const calledData = data?.t.data ?
         data.t.data : [{}];


    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => Props.from ==='trail' ? trailColumns: Props.report ? Props.canceled ? reportColumnCanceled:reportColumn: shareHolderColumns, [Props.checked, formatedNepEngDates]);




    return (
        <div style={{minWidth: Props.from ==='trail'? 2000: undefined}}>
            {(Props.report) &&
                <Grid container justify={"center"}>


                    <Grid  item xs={12} style={{textAlign: 'center', fontWeight: 700, paddingTop: 10, paddingBottom: 10, fontSize: 16}}>
                        <SetupFileUpload downloadPath={'/download/salesReport?'+getParam(formState)}
                                         downloadText={Props.canceled ? "Download Cancellation Report":"Download Sales Report"}
                                         centered={true}
                                         fileName={Props.canceled ? 'Cancellation Report.xlsx': 'Sales Report.xlsx'}
                        />
                    </Grid>


                    <DashboardReportItem name={'Total'}
                                         value={sumData?.t}
                     loading={!sumData}
                    />
                </Grid>
            }



            {!(mockData.length === 0 && page === 1) ? (
                <ReactTable
                    data={mockData}
                    perPageOption={[30, 50, 100]}
                    columns={columns}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={page}
                    perPage={perPage}
                    totalPage={data?.t.totalPage}
                />
            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default InvoiceTable;
