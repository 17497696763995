import React from 'react';
import ExpenseEntryHome from "../../templates/expenseEntryHome";

const ExpenseEntry = () => {
    return (
        <div>
            <ExpenseEntryHome/>
        </div>
    );
};

export default ExpenseEntry;