import React, {useState} from 'react';
import {createMuiTheme, makeStyles, withStyles} from '@material-ui/core/styles';
import {Grid, List, ListItem, ListItemText,} from "@material-ui/core";
import Collapse from '@material-ui/core/Collapse';
import {ThemeProvider} from '@material-ui/styles';
import {NavLink, useHistory, useLocation,} from "react-router-dom";
import ToolTip from "../../../shared/atom/tooltip";
import AccessFilter from "../../../../accessControl/accessFilter";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {COLOR_PROFILE} from "../../../../config/colorCode";

const theme = createMuiTheme({
    overrides: {
        MuiList: {
            root: {
                '&:hover': {
                },
            },


        },
        MuiListItemText: {
            root: {
                // color: "black",

                paddingLeft: 10,
                fontWeight: 550
            }
        }
    }
});

const ListItemNew = withStyles({
    root: {
        "&$selected": {
            backgroundColor: "red",
            color: "white"
        },
        "&$selected:hover": {
            backgroundColor: "purple",
            color: "white"
        },
        "&:hover": {
            backgroundColor: "blue",
            color: "white"
        }
    },
    selected: {}
})(ListItem);

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
        color: 'white',

    },
    nested: {
        paddingLeft: theme.spacing(2),
        paddingTop: 2,
        paddingBottom: 2,
        '&:hover': {
            background: COLOR_PROFILE.primaryColor,
            color: 'white'
        },
        '&:focusVisible': {
            background: COLOR_PROFILE.primaryColor,
            // color: 'white'
        },

    },
    active: {
        background: COLOR_PROFILE.primaryColor,
        'root': {
            color: 'white'
        }
    }


}));


const DrawerList: React.FC = (props: any) => {


    const classes = useStyles();
    // eslint-disable-next-line
    const [open, setOpen] = useState(false);
    const [hoveredAdd, setHoveredAdd] = useState(null);
    const [hoveredTab, setHoveredTab] = useState(null)
    const location = useLocation();

    const dashboard = useSelector((state: RootState) => state.dashboard);

    let tempJSX = null;
    let listItem;
    let tempInnerJSX = null;
    if (props.subMenu) {


        listItem = props.subMenu.map((item: any, index: number) => {

            let name = item.name;
            let Icon = 'icon';
            let path = props.path + item.path;
            let isActive = location.pathname.includes(path)


            return (

                <AccessFilter

                    section={item.accessTo}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean, isChecker: boolean) => {


                        if(!isAllowed) return null;

                        return (


                            <div>
                                <ListItem
                                    button
                                    component={NavLink} key={index + 1}
                                    activeClassName={classes.active}
                                    className={classes.nested}
                                    style={{color: isActive ? 'white' : undefined}}
                                    onMouseOver={() => {
                                        setHoveredTab(item.id)
                                    }}
                                    onMouseLeave={() => setHoveredTab(null)}
                                    to={`${hoveredAdd === item.id ? item.addPath : path}`}

                                >

                                    <ListItemText disableTypography={true} style={{marginLeft: 10}}>


                                        <Grid container justify={"space-between"} alignItems={"center"}>
                                            <Grid item style={{paddingBottom: 5}}>
                                                <span
                                                    style={{
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                     {name}
                                                </span>
                                            </Grid>
                                            {/*history.push(props.addPath)*/}

                                            <AccessFilter

                                                section={item.accessTo}
                                                privilege={"write"}
                                                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                                                    if (!isAllowed) return null

                                                    return (

                                                        <div>
                                                            {
                                                                (item.addPath
                                                                    // && hoveredTab === item.id
                                                                ) && (
                                                                    <Grid item>
                                                                        <div
                                                                            style={{
                                                                                textAlign: "right",
                                                                                marginRight: -1,
                                                                                marginBottom: -3,
                                                                                paddingLeft: 10,
                                                                                fontSize: 18
                                                                            }}
                                                                            onMouseOver={() => setHoveredAdd(item.id)}
                                                                            onMouseLeave={() => setHoveredAdd(null)}
                                                                        >
                                                                            <ToolTip title={'Add'}>
                                                                                <AddCircleOutlineIcon
                                                                                    fontSize={"inherit"}
                                                                                    // style={{color: isActive ? 'white' : COLOR_PROFILE.primaryColor}}
                                                                                />
                                                                            </ToolTip>
                                                                        </div>

                                                                    </Grid>
                                                                )}
                                                        </div>

                                                    )

                                                }}

                                            />


                                        </Grid>

                                    </ListItemText>
                                </ListItem>

                                <div style={{borderTop: `1px solid ${COLOR_PROFILE.containerBackground}`}}>

                                </div>
                            </div>

                        )

                    }}

                />


            );
        });


        let name = props.name;
        let Icon = props.icon;
        let path = props.path;

        let found = dashboard.role.menus.filter((item: any) => item.name === 'All Menus').length > 0 ? true : dashboard.role.menus.filter((item: any) => item.section === props.accessTo).length > 0;


        tempInnerJSX = (props.clickable) ? (
            <div>
                <ListItem button
                          onClick={() => setOpen(prevState => !prevState)}
                    // component={NavLink} to={`${path}`}
                    // activeClassName={classes.active}
                          style={{height: '42px'}}


                >

                    <ListItemText disableTypography={true} style={{marginLeft: -10}}>
                        <Grid container justify={"space-between"} alignItems={'center'}>

                            <Grid item>
                                <Icon/>
                            </Grid>
                            <Grid item xs={9} style={{paddingBottom: 5}}>
                                <span
                                    style={{
                                        fontSize: '14px',
                                        color: 'black'
                                    }}>{name}</span>

                            </Grid>
                            <Grid item>
                                <div style={{marginRight: -4}}> {!open ? <ArrowRightIcon/> :
                                    <ArrowDropDownIcon/>} </div>
                            </Grid>
                        </Grid>
                    </ListItemText>

                </ListItem>
                <div style={{borderTop: `1px solid ${COLOR_PROFILE.containerBackground}`}}>

                </div>
            </div>

        ) : (
            <ListItem
                style={{height: '42px'}}
            >

                <ListItemText disableTypography={true}>
                    <Grid container justify={"space-between"}>

                        <Grid item>
                        <span
                            style={{
                                fontSize: '14px',
                                color: 'black'
                            }}>{name}</span>

                        </Grid>
                        <Grid item>
                            <div> {open ? <ArrowRightIcon/> : <ArrowDropDownIcon/>} </div>
                        </Grid>
                    </Grid>
                </ListItemText>

            </ListItem>
        )
        // }

        tempJSX =
            (

                <List style={{paddingTop: 0, paddingBottom: 0}}>

                    {found && tempInnerJSX}

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" style={{paddingTop: 0, paddingBottom: 0}}>
                            {listItem}
                        </List>
                    </Collapse>

                </List>
            );

    } else {


        let name = props.name;
        let path = props.path;
        let Icon = props.icon;

        let isActive = location.pathname.includes(path)

        let isChecker = dashboard.role.makerChecker === 'CHECKER';
        if(props.name === "Verify Requests" && !isChecker) return null;

        tempJSX = (
            <div>
                <List style={{paddingTop:0 , paddingBottom: 0}}>
                    <ListItem
                        button component={NavLink} to={`${path}`}
                        activeClassName={classes.active}
                        className={classes.nested}
                        style={{height: '42px', color: isActive ? 'white' : undefined}}>

                        <ListItemText

                            disableTypography={true}>


                            <Grid container justify={"space-between"} style={{marginLeft: -18, paddingLeft: 8}} alignItems={"center"}>

                                <Grid item xs={1}>
                                    <Icon/>
                                </Grid>
                                <Grid item xs={9} style={{marginLeft: -18, paddingBottom: 5}}>
                                <span
                                    style={{
                                        fontSize: '14px',

                                    }}
                                >
                                        {name}
                                </span>

                                </Grid>
                                <Grid item>
                                    <div>

                                    </div>
                                </Grid>
                            </Grid>

                        </ListItemText>
                    </ListItem>
                </List>

                <div style={{borderTop: `1px solid ${COLOR_PROFILE.containerBackground}`}}>

                </div>

            </div>

        )

    }


    return (
        <ThemeProvider theme={theme}>
            {tempJSX}
            {/*<div style={{borderTop: `1px solid ${COLOR_PROFILE.navigationBackground}`}}>*/}

            {/*</div>*/}
        </ThemeProvider>
    );
};


export default DrawerList;
