import React from 'react';
import {TextField as MTextField, TextFieldProps} from "@material-ui/core";

interface CustomTextFieldProps {
        isNegative ?: boolean
}



const InputField: React.FC<TextFieldProps & CustomTextFieldProps> =
    ({
         variant,
         onBlur,
         style,
         onChange,
         size,
         placeholder,
         disabled,
         id,
         type,
         value,
         fullWidth,

         rows,
         error,
         children,
         helperText,
         select,
         multiline,
         className,
         onKeyUp,
         onKeyDown,
         InputProps,

         isNegative
     }) => {

        return (
            <MTextField
                error={error}
                rows={rows}
                multiline={multiline}
                onBlur={onBlur}
                disabled={disabled}
                helperText={helperText}
                variant={variant}
                onChange={onChange}
                value={value}
                fullWidth
                onWheel={() => {
                    // @ts-ignore
                    document.activeElement.blur();
                }}
                onKeyDown={onKeyDown}
                size={size}
                select={select}
                placeholder={placeholder}
                id={id}
                onKeyUp={onKeyUp}
                type={type}
                autoComplete={'off'}

                InputProps={{
                    ...InputProps,
                    // inputProps: { min: type === 'number' ? !isNegative && 0 : null, step: 0.1 },
                    style: {
                        borderRadius: 0,
                        border: "none",
                        fontSize: 14,
                        paddingTop: -2,
                        paddingLeft: variant === 'standard' ? 10 : undefined,
                        paddingRight: variant === 'standard' ? 10 : undefined,
                        fontWeight: 'bold',
                        
                    },
                    'aria-label': 'naked',


                }}

                className={className}

            >
                {children}
            </MTextField>
        );

    };

InputField.defaultProps = {
    variant: "outlined",
    size: "medium",
}


export default InputField;
