import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Checkbox, Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../../../accounting/organisms/reactTable/useServerCall";
import useSWR from "swr";
import ReactTable from "../../../../accounting/organisms/reactTable/ReactTable";
import DataEmptyMessage from "../../../../accounting/organisms/tables/dataEmptyMessage";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModal from "../../../../accounting/organisms/deleteModal";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import AccessFilter from "../../../../../accessControl/accessFilter";
import {getTodaysDate} from "../../../../../config/dateFunctions";

const ProductBundleTable: React.FC<any> = (Props) => {
    const {formState} = Props;
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const [deleteId, setDeleteId] = useState(null)

    const history = useHistory();
    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' style={{marginRight: 15, paddingRight: 15}}>

                    <AccessFilter

                        section={"bundle"}
                        privilege={"delete"}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed) return null

                            return (

                                <Grid item xs={6}>
                                    <IconButton size={"small"} style={{color: '#ec4949'}}
                                                onClick={() => setDeleteId(val.row.original.id)}
                                    >
                                        <DeleteIcon fontSize={"small"}/>
                                    </IconButton>
                                </Grid>

                            )

                        }}

                    />

                    <AccessFilter

                        section={"bundle"}
                        privilege={"update"}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed) return null

                            return (

                                <Grid item xs={6}>
                                    <IconButton size={"small"} style={{color: '#4aca53'}}
                                                onClick={() => {
                                                    history.push(`/dashboard/product/product-bundle/edit/${val.row.original.id}`, val.row.original)
                                                }}>
                                        <EditIcon fontSize={"small"}/>
                                    </IconButton>
                                </Grid>

                            )

                        }}

                    />

                </Grid>
            )
        },
        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'code'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },

        {
            Header: 'Bundle Price',
            Footer: 'Bundle Price',
            accessor: 'bundlePrice'
        },
        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} = cellObj.cell
                return <div>{original.productSub.map((item: any) => item.name + '(' + item.quantity + ')').join(', ')}</div>
            }
        },
        {
            Header: 'Effective Days',
            Footer: 'Effective Days',
            accessor: 'effectiveDays'
        },
        {
            Header: 'Effective From',
            Footer: 'Effective From',
            accessor: 'effectiveFrom'
        },
        {
            Header: 'Effective To',
            Footer: 'Effective To',
            accessor: 'effectiveTo'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },
    ]

    const path = '/getProductBundles'

    const getParams = () => {
        return `?name=${formState.search}&from=${
            (formState.transactionYearAdFrom && formState.transactionMonthAdFrom && formState.transactionDateAdFrom) ? (formState.transactionYearAdFrom + '-' + ('0' + formState?.transactionMonthAdFrom).slice(-2) + '-' + ('0' + formState.transactionDateAdFrom).slice(-2)) : ''
        }&to= ${
            (formState.transactionYearAdTill && formState.transactionMonthAdTill && formState.transactionDateAdTill) ? (formState.transactionYearAdTill + '-' + ('0' + formState?.transactionMonthAdTill).slice(-2) + '-' + ('0' + formState.transactionDateAdTill).slice(-2)) : getTodaysDate()
        }`
    }

    const {data, loading} = useServerCall({
        path, page, perPage,
        search: getParams()
    })

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}${getParams()}`)

    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];
    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => shareHolderColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div>
                    {deleteId && (
                        <DeleteModal message={`Are you sure? Once deleted it cannot be reverted.`}
                                     trigger={`${path}/${page - 1}/${perPage}${getParams()}`}
                                     deletePath={`/deleteProductBundle/${deleteId}`} open={deleteId}
                                     handleDeleteCancel={() => setDeleteId(null)}
                        />
                    )}

                    <ReactTable
                        data={mockData}
                        perPageOption={[30, 50, 100]}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        totalPage={data?.t.totalPage}
                    />
                </div>
            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default ProductBundleTable;
