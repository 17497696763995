import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../store/RootReducer";
import {ACCESS_MAPPER} from "../../utils/contants";


interface Props {
    render: any,
    section: "allMenu" |
        "shareHolders" |
        'branches' |
        'employees' |
        'userAndRoles' |
        'bank' |
        'customers' |
        'vendors' |
        "accountGroup" |
        'accountHead' |
        'costCenter' |
        'journalEntry' |
        'paymentEntry' |
        'receiptEntry' |
        'salesEntry' |
        'generalLedger' |
        'groupSummary' |
        'payable' |
        'receivable' |
        'balanceSheet' |
        'profitAndLoss' |
        'trialBalance' |
        'sales' |
        'purchaseRFP' |
        'purchaseOrder' |
        'purchaseBill' |
        'expenseBill' |
        'purchaseReturn' |
        'estimate' |
        'salesReturn' |
        "category" |
        "uom" |
        "productItem" |
        "bundle" |
        "salesOffer" |
        "inventoryLevel" |
        "inventoryAdjustment"|
        "payableReport"|
        "receivableReport" |
        "salesReport" |
        "invoice"|
        "vat" |
        "tds",
    privilege: 'read' | 'write' | 'update' | 'delete'
}


const AccessFilter: React.FC<Props> = (Props) => {

    const [isAllowed, setIsAllowed] = useState<boolean>(false);
    const [hasAllAccess, setHasAllAccess] = useState<boolean>(false)
    const [isBranchAdmin, setIsBranchAdmin] = useState<boolean>(false)
    const {section, privilege} = Props;
    const [isChecker, setIsChecker] = useState<boolean>(false)

    const dashboard = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {

        try {

            if (!dashboard) return;

            // @ts-ignore
            let sectionName: string = ACCESS_MAPPER[section].name;
            let dashboardMenus = dashboard.role.menus;

            let found
            if (dashboardMenus.find((item: any) => (item.name === 'All Menus'))) {
                found = true
                setHasAllAccess(true)
            } else {
                found = dashboardMenus.find((item: any) => (item.name === sectionName) && (item.privilege === privilege));
            }

            setIsBranchAdmin(dashboard.role.role === 'BRANCH_ADMIN');
            setIsAllowed(!!found);
            setIsChecker(dashboard.role.makerChecker === 'CHECKER')

        } catch (e) {

        }

    }, [dashboard])

    return Props.render(isAllowed, hasAllAccess, isBranchAdmin, isChecker);

};

export default AccessFilter;
