import React from 'react';
import CancelationTemplate from "../../../templates/reporting/cancelationTemplate";

const CancellationReport = () => {
    return (
        <div>
            <CancelationTemplate/>
        </div>
    );
};

export default CancellationReport;
