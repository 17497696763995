import axios from "axios";
import baseUrl from "./baseVariables";

const axiosInstance = axios.create({
    baseURL: baseUrl,
    // headers: {
    //     'Content-Encoding': 'gzip'
    // }
    // headers: {
    //     Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJwcm9ncmVzc2l2ZUBnbWFpbC5jb20iLCJpc3MiOiJzeXN0ZW0iLCJpYXQiOjE2MjU0NjYyNzUsImV4cCI6MTYyNTUwMjI3NX0.iz3EyM84d_pUdgSFMwNvgK9ICcb91ty2F_TCqVM9MY8`
    // }
    // timeout: 1,
});

if(localStorage.getItem('token')){
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
}


export default axiosInstance;
