import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../../../accounting/organisms/reactTable/useServerCall";
import useSWR from "swr";
import ReactTable from "../../../../accounting/organisms/reactTable/ReactTable";
import DataEmptyMessage from "../../../../accounting/organisms/tables/dataEmptyMessage";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MakeCopyModal from "../../../../accounting/organisms/makeCopyModal";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import AccessFilter from "../../../../../accessControl/accessFilter";

const PurchaseRfpTable: React.FC<any> = (Props) => {

    const {formState} = Props;

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const [copyId, setCopyId] = useState(null)

    const history = useHistory();

    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' style={{marginRight: 30, paddingRight: 20, }}>



                    <Grid item xs={4}>
                        <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                    onClick={() => {
                                        history.push(`/dashboard/purchase/request-for-proposal/view/${val.row.original.id || 1}`, val.row.original)
                                    }}>
                            <VisibilityIcon fontSize={"small"}/>
                        </IconButton>
                    </Grid>

                    <AccessFilter

                        section={"purchaseRFP"}
                        privilege={'write'}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed) return null

                            return (

                                <Grid item xs={4}>
                                    <IconButton size={"small"} style={{color: COLOR_PROFILE.buttonGreen}}
                                                onClick={() => setCopyId(val.row.original.id)}>
                                        <FileCopyIcon fontSize={"small"}/>
                                    </IconButton>
                                </Grid>

                            )

                        }}

                    />

                    <AccessFilter

                        section={"purchaseRFP"}
                        privilege={"update"}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed) return null

                            return (

                                <Grid item xs={4}>
                                    <IconButton size={"small"} style={{color: '#4aca53'}}
                                                onClick={() => {
                                                    history.push(`/dashboard/purchase/request-for-proposal/edit/${val.row.original.id || 1}`, val.row.original)
                                                }}>
                                        <EditIcon fontSize={"small"}/>
                                    </IconButton>
                                </Grid>

                            )

                        }}

                    />


                </Grid>
            )
        },
        {
            Header: 'RFP No.',
            Footer: 'RFP No.',
            accessor: 'rfpNumber'
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },

        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} = cellObj.cell
                return <div>{original.productSubs.map((item: any) => item.name).join(', ')}</div>
            }
        },
        {
            Header: 'Issue Date',
            Footer: 'Issue Date',
            accessor: 'issueDate'
        },
        {
            Header: 'Expiry Date',
            Footer: 'Expiry Date',
            accessor: 'expiryDate'
        },

        {
            Header: 'Vendor',
            Footer: 'Vendor',
            accessor: 'vendor.name'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },
    ]

    const path = '/getPurchaseRFPs'
    const {data, loading} = useServerCall({path, page, perPage,
        search: `?name=${formState.search}`
    })

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}?name=${formState.search}`)

    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];
    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => shareHolderColumns, [setCopyId]);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div>
                    {copyId && (
                        <MakeCopyModal message={`Are you sure?`}
                                     trigger={`${path}/${page - 1}/${perPage}?name=${formState.search}`}
                                     copyPath={`/copyRFP/${copyId}`} open={copyId}
                                     handleDeleteCancel={() => setCopyId(null)}
                        />
                    )}
                    <ReactTable
                        data={mockData}
                        perPageOption={[30, 50, 100]}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        totalPage={data?.t.totalPage}
                    />
                </div>

            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default PurchaseRfpTable;