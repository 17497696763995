import React from 'react';
import {TimePicker, TimePickerProps} from "@material-ui/pickers";

interface Props extends TimePickerProps {

}

const CustomTimePicker: React.FC<Props> = (Props) => {
    return (
        <TimePicker
            {...Props}
            InputProps={{
                ...Props.InputProps,
                style: {
                    borderRadius: 0,
                    border: "none",
                    fontSize: 14,
                    paddingTop: -2,
                    paddingLeft: Props.inputVariant === 'standard' ? 10 : undefined,
                    paddingRight: Props.inputVariant === 'standard' ? 10 : undefined,
                    fontWeight: 'bold',

                },
                'aria-label': 'naked',

            }}
        />

    );
};

export default CustomTimePicker;