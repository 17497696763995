import {Dialog, DialogContent, DialogProps, DialogTitle} from '@material-ui/core';
import React from 'react';
import {COLOR_PROFILE} from "../../../../config/colorCode";

interface Props extends DialogProps {
    title?: string,
    handleClose?: any,
}

const DialogComponent: React.FC<Props> = (Props) => {
    return (
        <div>
            <Dialog
                onEscapeKeyDown={Props.handleClose}
                open={Props.open}
                onBackdropClick={Props.handleClose}
                maxWidth={Props.maxWidth || 'md'}
                fullWidth
            >
                {Props.title && <DialogTitle style={{color: COLOR_PROFILE.primaryColor}}>{Props.title}</DialogTitle>}
                <DialogContent>{Props.children}</DialogContent>

            </Dialog>
        </div>
    );
};

export default DialogComponent;
