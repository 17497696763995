import React from 'react';
import EstimateHome from "../../templates/estimateHome";

const Estimate = () => {
    return (
        <div>
            <EstimateHome/>
        </div>
    );
};

export default Estimate;