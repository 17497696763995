import React, {useEffect, useMemo, useState} from 'react';
import useAdAndBsHandler from "../../../../effects/useAdAndBsHandler";
import useSWR, {trigger} from "swr";
import {convertAdToBsDisplay, getTodaysDate} from "../../../../config/dateFunctions";
import SetupFileUpload from "../../../accounting/organisms/setupFileUpload";
import ReactTable from "../../../accounting/organisms/reactTable/ReactTable";
import DataEmptyMessage from "../../../accounting/organisms/tables/dataEmptyMessage";

const MaterializedTemplate: React.FC<any> = (Props) => {
    const {formState} = Props;
    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);

    const shareHolderColumns = [

        {
            Header: 'bill_no',
            accessor: 'bill_no'
        },
        {
            Header: 'amount',
            accessor: 'amount'
        },
        {
            Header: 'bill_date',
            accessor: 'bill_date',
            Cell: (val: any) => {
                let temp = val.row.original.bill_date || "";
                return <div> {convertAdToBsDisplay(temp.split('T')[0], formatedNepEngDates)}</div>
            }
        },

        {
            Header: 'customer_name',
            accessor: 'customer_name'
        },
        {
            Header: 'customer_pan',
            accessor: 'customer_pan'
        },
        {
            Header: 'discount',
            accessor: 'discount',
        },
        {
            Header: 'entered_by',
            accessor: 'entered_by'
        },
        {
            Header: 'fiscal_year',
            accessor: 'fiscal_year'
        },
        {
            Header: 'is_bill_active',
            accessor: 'is_bill_active',
            Cell: (val: any) => {
                let temp = val.row.original.is_bill_active;
                return <div>{!temp ? 'True': 'False'}</div>
            }
        },
        {
            Header: 'is_bill_printed',
            accessor: 'is_bill_printed',
            Cell: (val: any) => {
                let temp = val.row.original.is_bill_printed;

                return <div>{temp ? 'True': 'False'}</div>
            }
        },
        {
            Header: 'is_realtime',
            accessor: 'is_realtime',
            Cell: (val: any) => {
                let temp = val.row.original.is_realtime;

                return <div>{temp ? 'True': 'False'}</div>
            }
        },
        {
            Header: 'payment_method',
            accessor: 'payment_method'
        },
        {
            Header: 'printed_by',
            accessor: 'printed_by'
        },
        {
            Header: 'printed_time',
            accessor: 'printed_time'
        },
        {
            Header: 'sync_with_ird',
            accessor: 'sync_with_ird',
            Cell: (val: any) => {
                let temp = val.row.original.sync_with_ird;

                return <div>{temp ? 'True': 'False'}</div>
            }
        },
        {
            Header: 'tax_amount',
            accessor: 'tax_amount'
        },
        {
            Header: 'taxable_amount',
            accessor: 'taxable_amount'
        },
        {
            Header: 'total_amount',
            accessor: 'total_amount'
        },
        {
            Header: 'vat_refund_amount',
            accessor: 'vat_refund_amount'
        },
        {
            Header: 'transaction_id',
            accessor: 'transaction_id'
        },
    ]



    const getParam = (formState: any) => {

        return `from=${
            (formState.transactionYearAdFrom && formState.transactionMonthAdFrom && formState.transactionDateAdFrom) ? (formState.transactionYearAdFrom + '-' + ('0' + formState?.transactionMonthAdFrom).slice(-2) + '-' + ('0' + formState.transactionDateAdFrom).slice(-2)) : ''
        }&to=${
            (formState.transactionYearAdTill && formState.transactionMonthAdTill && formState.transactionDateAdTill) ? (formState.transactionYearAdTill + '-' + ('0' + formState?.transactionMonthAdTill).slice(-2) + '-' + ('0' + formState.transactionDateAdTill).slice(-2)) : getTodaysDate()
        }&isReturn=${Props.return || false}`

    }

    let path = '/report/getMaterializedView';
    let downloadPath = '/download/materialized';

    const {data} = useSWR(`${path}?page=${page - 1}&size=${perPage}&${getParam(formState)}`)


    useEffect(() => {

        if(Props.submitting) return;
        trigger(`${path}?page=${page - 1}&size=${perPage}&${getParam(formState)}`)

    }, [Props.submitting])

    useEffect(() => {
        Props.setSubmitting(data === null)
    }, [data])

    const calledData = data ? data.content : [];
    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => shareHolderColumns, [formatedNepEngDates]);


    return (
        <div>

            <div style={{textAlign: 'center', fontSize: 16}}>
                <SetupFileUpload downloadPath={`${downloadPath}?${getParam(formState)}`}
                                 downloadText={`Download materialized View ${Props.return ? '(Sales Return)': '(Sales)'}`}
                                 centered={true}
                                 fileName={'Materialized.xlsx'}/>
            </div>

            <div style={{maxWidth: `calc(100vw - 300px)`, overflow: "auto", marginLeft: 10, marginRight: 10}}>
                <div style={{minWidth:  1600}}>
                    {!(mockData.length === 0 ) ? (
                        <ReactTable
                            data={mockData}
                            columns={columns}
                            perPageOption={[30, 50, 100]}
                            setPage={setPage}
                            setPerPage={setPerPage}
                            currentpage={page}
                            perPage={perPage}
                            totalPage={data?.totalPages}
                        />
                    ) : (
                        ( !data) ? null
                            : <DataEmptyMessage/>
                    )}
                </div>
            </div>

        </div>
    );
};

export default MaterializedTemplate;
