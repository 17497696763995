import React, {useState} from 'react';
import useSWR from "swr";
import {getTodaysDate} from "../../../../../config/dateFunctions";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import {getCurrentDate, getDiffdate, getFormattedDate} from "../../../../../utils/dateFunction";
import {useHistory} from "react-router-dom";
import {Collapse, Divider, Grid, Paper} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {ArrowRightAlt} from "@material-ui/icons";
import {Form, Formik} from "formik";
import DateComponent from "../../../../product/molecules/dateComponent";
import Button from "../../../../shared/atom/button";
import BarChart from "../../../organisms/barChart";

const ReturnChart: React.FC<any> = (Props) => {
    const dashboard = Props.from === "dashboard";

    const initialState = {
        transactionYearAdFrom: null,
        transactionMonthAdFrom: null,
        transactionDateAdFrom: null,
        transactionYearBsFrom: null,
        transactionMonthBsFrom: null,
        transactionDateBsFrom: null,
        transactionYearAdTill: null,
        transactionMonthAdTill: null,
        transactionDateAdTill: null,
        transactionYearBsTill: null,
        transactionMonthBsTill: null,
        transactionDateBsTill: null,
    }

    const [formState, setFormState] = useState<any>(initialState);

    const [fromDate, setFromDate] = useState("");
    const [tillDate, setTillDate] = useState("");

    const [days, setDays] = useState<any>(7);

    const getStartDate = (tillDays: number) => {
        let todaysDate = new Date();
        todaysDate.setDate(todaysDate.getDate() - tillDays + 1);
        return todaysDate.getFullYear() + '-' + ('0' + (todaysDate.getMonth() + 1)).slice(-2) + '-' + ('0' + todaysDate.getDate()).slice(-2);
    }

    const {data: report} = useSWR(`/report/salesReturnReport?from=${days === "Custom" ? fromDate : getStartDate(days)}&to=${days === "Custom" ? tillDate : getTodaysDate()}`);

    const validationSchema = Yup.object({})

    const dispatch = useDispatch();
    const onSubmit = (values: any) => {
        const {
            transactionYearAdFrom,
            transactionMonthAdFrom,
            transactionDateAdFrom,
            transactionDateAdTill,
            transactionMonthAdTill,
            transactionYearAdTill
        } = values;
        if (transactionYearAdFrom && transactionMonthAdFrom && transactionDateAdFrom && transactionDateAdTill && transactionMonthAdTill && transactionYearAdTill) {
            setFromDate(getFormattedDate(transactionYearAdFrom, transactionMonthAdFrom, transactionDateAdFrom));
            setTillDate(getFormattedDate(transactionYearAdTill, transactionMonthAdTill, transactionDateAdTill));
        } else {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `Please enter both from and till date`, type: "warning"}
            })
        }
    }

    const [hovered, setHovered] = useState(false);

    const history = useHistory();
    const handleClick = () => {
        history.push("/dashboard/reports/sales-return-report/home");
    }
    return (
        <Paper style={{padding: 10, cursor: "pointer",}}
               onMouseOver={() => setHovered(p => !p)}
               onMouseOut={() => setHovered(p => !p)}
               elevation={hovered && dashboard ? 5:1}
               onClick={handleClick}
        >
            <Grid container justify={"space-between"} style={{}}>
                <Grid item xs={!dashboard ? 2 : 8}>
                    <Grid container style={{
                        fontSize: 16,
                        paddingLeft: 5,
                        fontWeight: 700,

                        color: COLOR_PROFILE.primaryColor
                    }}>
                        <Grid item>
                            Daily Return
                        </Grid>
                        {dashboard && (
                            <Grid item>
                                <div style={{
                                    fontSize: 9,  paddingLeft: 15,  display: 'flex', paddingTop: 1,
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    color:  COLOR_PROFILE.buttonLightGreen,
                                }}

                                >
                                    Click to view full report <ArrowRightAlt style={{color: COLOR_PROFILE.buttonLightGreen}}/>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={!dashboard ? 10 : 4} container justify={"flex-end"} alignItems={"center"} spacing={1}>
                    {!dashboard && (
                        <Grid item xs={12} spacing={1} container alignItems={"center"} justify={"flex-end"}>
                            Last:
                            {[7, 30, "Custom"].map((item: any) => {
                                return (
                                    <Grid item>
                                        <div style={{
                                            padding: "5px",
                                            backgroundColor: days === item ? COLOR_PROFILE.buttonLightGreen : undefined,
                                            color: days === item ? 'white' : COLOR_PROFILE.primaryColor,
                                            border: `1px solid ${COLOR_PROFILE.backgroundDark}`,
                                            cursor: "pointer"
                                        }} onClick={() => setDays(item)}>
                                            {item} days
                                        </div>

                                    </Grid>
                                )

                            })}
                        </Grid>
                    )}

                    {/*)}*/}
                </Grid>

            </Grid>
            {days === "Custom" && (
                <Divider style={{paddingTop: 2, marginTop: 10}}/>
            )}
            <Collapse in={days === "Custom"} mountOnEnter={true} unmountOnExit={true}>
                {/*{days === "Custom" && (*/}

                <Grid item container xs={12} alignItems={"center"}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={formState}
                        // onSubmit={(values: any) => {}}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnMount={false}
                        validateOnBlur={true}
                        onSubmit={onSubmit}
                    >
                        {formik => {
                            return (
                                <Form style={{marginBottom: 20, paddingTop: 10}}>
                                    <Grid item container xs justify={"flex-end"} alignItems={"center"} spacing={2}>
                                        <Grid item container xs justify={"flex-end"}>
                                            {/*From*/}
                                            <Grid item container alignItems={"center"} xs={2} style={{fontWeight: 700}}>
                                                From
                                            </Grid>
                                            <Grid item container xs={10}>
                                                <DateComponent
                                                    ad={['transactionYearAdFrom', 'transactionMonthAdFrom', 'transactionDateAdFrom']}
                                                    bs={['transactionYearBsFrom', 'transactionMonthBsFrom', 'transactionDateBsFrom']}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs justify={"flex-end"}>
                                            {/*Till*/}
                                            <Grid item xs={2} container justify={"center"} alignItems={"center"}
                                                  style={{fontWeight: 700}}>
                                                Till
                                            </Grid>
                                            <Grid item container xs={10}>
                                                <DateComponent
                                                    ad={['transactionYearAdTill', 'transactionMonthAdTill', 'transactionDateAdTill']}
                                                    bs={['transactionYearBsTill', 'transactionMonthBsTill', 'transactionDateBsTill']}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container justify={"flex-end"} xs={2}>
                                            <div style={{minWidth: 70, marginBottom: -20}}>
                                                <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                        textColor={'white'}
                                                        type={'submit'}
                                                >
                                                    <span style={{fontSize: 12}}>Apply</span>
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Form>
                            )
                        }}
                    </Formik>
                </Grid>
            </Collapse>
            {/*{report}*/}
            <div style={{marginTop: days === "Custom" ? -10 : 0}}>
                <BarChart days={days === "Custom" ? getDiffdate(fromDate, tillDate) : days} report={report?.t}
                          currentDate={days === "Custom" && getCurrentDate(tillDate)}/>
            </div>

        </Paper>
    );
};

export default ReturnChart;
