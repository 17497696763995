const ledgerDataReducer = (state: any = [], action: any) => {
    switch (action.type) {
        case 'Add_LEDGER':
            state = action.payload;
            return state;

        case 'REMOVE_LEDGER':
            return []

        default:
            return state
    }
}

export default ledgerDataReducer;

