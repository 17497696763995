import React from 'react';
import ProductBundleAddition from "../../templates/productBundleAddition";

const ProductBundle: React.FC = () => {
    return (
        <div>
            <ProductBundleAddition/>
        </div>
    );
};

export default ProductBundle;