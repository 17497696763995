import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {Grid, Popper, useMediaQuery} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import * as Yup from "yup";
import useSWR, {trigger} from "swr";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik} from "formik";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import InputField from "../../../../shared/atom/formElements/inputField";
import DateComponent from "../../../../product/molecules/dateComponent";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import EstimateAdditionalForm from "./estimateAdditional";
import EstimateProductsForm from "./estimateProducts";
import {useDispatch} from "react-redux";
import {getInvoiceAmounts} from "../../../../../utils/invoiceAmounts";
import {getTaxAmount} from "../../../../../utils/getTax";
import TdsForm from "../tdsForm";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs} from "../../../../../config/dateFunctions";
import DialogComponent from "../../../../shared/atom/dialog";
import ProductItemForm from "../../../../product/organisms/form/productItem";
import AccountHeadForm from "../../../../accounting/organisms/Forms/accountHeadForm";
import ExpenseEntryPrintView from "../../../../purchase/organisms/forms/expenseEntry/expenseEntryPrintView";
import ReportActionButton from "../../../../accounting/organisms/reportActionButton";
import {useReactToPrint} from "react-to-print";
import EstimatePrintView from "./estimatePrintView";
import {ACCOUNT_CODE_MAPPER} from "../../../../../utils/accountCodeMapper";
import PagePrompt from "../../../../shared/organisms/pagePrompt";
import FocusError from "../../../../shared/organisms/formikErrorFocus";
import AddIcon from "@material-ui/icons/Add";
import ToolTip from "../../../../shared/atom/tooltip";
import {matchSorter} from "match-sorter";
import CustomerForm from "../../../../accounting/organisms/Forms/customerForm";

interface Props {
    from: string,
    mode: 'edit' | 'create' | 'view',
    nextPath?: any,
    id ?: string | null
}

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const PopperMy = function (props: any) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (<Popper {...props} style={{width: matches ? 250:400, marginRight: 20}} placement='bottom-start'/>)
}

const EstimateAdditionForm: React.FC<Props> = (Props) => {

    const {from} = Props;
    const isInvoice = from === 'invoice';

    const initialState = {
        estimateYearAd: null,
        estimateMonthAd: null,
        estimateDateAd: null,
        estimateYearBs: null,
        estimateMonthBs: null,
        estimateDateBs: null,

        expiryYearAd: null,
        expiryMonthAd: null,
        expiryDateAd: null,
        expiryYearBs: null,
        expiryMonthBs: null,
        expiryDateBs: null,

        code: '',
        customer: null,
        paymentStatus: 'Full',
        paymentMode: null,
        amountPaid: 0,
        chequeNumber: '',
        referenceBillNumber: '',
        document: '',
        description: '',

        //for invoice

        fiscalYear: '',

        tableRow: [
            {
                id: 1,
                product: null,
                quantity: 0,
                accountHead: null,
                uom: null,
                price: 0,
                discount: 0,
                tax: 0,
                amount: 0
            },
            {
                id: 2,
                product: null,
                accountHead: null,
                quantity: 0,
                uom: null,
                price: 0,
                discount: 0,
                tax: 0,
                amount: 0
            },
        ],

        tableRowAdditional: [
            {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, tax: 0, amount: 0},
            {id: 2, product: null, quantity: 0, uom: null, price: 0, discount: 0, tax: 0, amount: 0},
        ],
        tds: [
            {id: 1, item: null, product: null, taxableAmount: 0, tax: 0, amount: 0},
            // {id: 2, item: null, product: null, taxableAmount: 0, tax: 0, amount: 0},
        ],

    }

    const {data: estimateCode} = useSWR('/getCode?name=estimate&id=0')
    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();
    const {data: accountForSales} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.directIncomeRevenue}`)
    const {data: customerOptions} = useSWR('/getAllCustomers');
    const {data: paymentModeOptions} = useSWR('/getHeadsForPayment');
    const {data: headOptionsInCategory} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.inventoryAccount},${ACCOUNT_CODE_MAPPER.indirectExpenseAdministrativeExpense},${ACCOUNT_CODE_MAPPER.currentLiabilites}`);
    // const {data: salesAccounts} = useSWR('/getHeadByGroup?groups=DI-502');
    const {data: additionalChargeAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectIncomeAccount}`);
    const {data: productAndBundleOptions} = useSWR('/getProductsBundlesAndOffers');
    const {data: tdsOptions} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.currentAssetAdvanceTax}`);


    useEffect(() => {
        if(!formatedNepEngDates) return;
        if(!productAndBundleOptions) return;
        if(!paymentModeOptions) return;
        if(!additionalChargeAccounts) return;

        if (Props.id) {

            let additionalChargeAccountsId = additionalChargeAccounts.data.map((item: any) => item.id);

            // @ts-ignore
            const {customer,estimateDate,estimateNumber,expiryDate,amountPaid,paymentMode,paymentStatus,chequeNumber,description,productBundleSubs,journalVoucherDto} = location.state

            console.log(journalVoucherDto);
            let adTransactionDate = estimateDate.split('-');
            let bsTransactionDate: any = convertAdToBs(estimateDate, formatedNepEngDates) || [];

            let adExpiryDate = expiryDate ? expiryDate.split('-') : [];
            let bsExpiryDate: any = convertAdToBs(expiryDate, formatedNepEngDates) || [];


            // @ts-ignore
            let tempJson = {
                estimateYearAd: adTransactionDate[0],
                estimateDateAd: adTransactionDate[2],
                estimateMonthAd: adTransactionDate[1],
                estimateDateBs: bsTransactionDate[2],
                estimateMonthBs: bsTransactionDate[1],
                estimateYearBs: bsTransactionDate[0],

                expiryYearAd: adExpiryDate[0],
                expiryDateAd: adExpiryDate[2],
                expiryMonthAd: adExpiryDate[1],
                expiryDateBs: bsExpiryDate[2],
                expiryMonthBs: bsExpiryDate[1],
                expiryYearBs: bsExpiryDate[0],

            }

            // @ts-ignore
            setFormState({
                ...formState,
                ...tempJson,
                code: estimateNumber,
                chequeNumber: chequeNumber,
                paymentStatus: paymentStatus,
                amountPaid: amountPaid,
                customer: customer,
                description: description,
                paymentMode: paymentMode ? paymentModeOptions.data.find((item: any) => item.id === paymentMode.id): null,
                //purchase order number
                tableRow: [...productBundleSubs.map((item: any) => {

                    return {
                        ...item,
                        product: [...productAndBundleOptions.data.map((item: any) => {
                            return {...item, name: 'Product'}
                        }), ...productAndBundleOptions.data2.map((item: any) => {
                            return {...item, name: 'Bundle'}
                        })].find((prodItem: any) =>  ((prodItem.name === 'Product' ?  prodItem.productid : prodItem.bundleid) === (item.productId || item.productBundleId)) && (prodItem.name === (item.productId ? 'Product': 'Bundle'))),
                        // accountHead: [ ...inventoryAccount.data, ...assetAccount.data.map((item: any) => {
                        //     return {...item, subtypename: 'Fixed Asset', isInventory: false, headname: item.name}
                        // })].find((headItem: any) => headItem.id === item.accountHeadId)
                    }


                })],

                tableRowAdditional: journalVoucherDto && [ ...journalVoucherDto.journalRows.filter((item: any) => additionalChargeAccountsId.includes(item.accountHead.id)).map((item: any) => {

                    console.log('jornal',item);

                    let account = additionalChargeAccounts.data.find((itemHead: any) => itemHead.id === item.accountHead.id);
                    let entries = JSON.parse(item.description);
                    return {
                        product: entries.product,
                        // description: item.description?.split('_')?.slice(0,-1)?.join('_'),
                        price: entries.price,
                        tax: entries.tax,
                        amount: entries.amount,
                        quantity: entries.quantity, uom: entries.uom, discount: entries.discount
                    }
                })],
                tds: journalVoucherDto && [...journalVoucherDto?.journalRows.filter((item: any) => tdsOptions.data.find((tdsItem: any) => tdsItem.id === item.accountHead.id)).map((item: any, index: number) => {
                    // let account = tdsOptions.data.find((itemHead: any) => itemHead.id === item.accountHead.id);
                    let entries = JSON.parse(item.description)
                    return {
                        id : index+1,
                        product: entries?.product,
                        // description: item.description.split('_').slice(0,-1).join('_'),
                        taxableAmount: entries?.taxableAmount,
                        tax: entries?.tax,
                        amount: entries?.amount
                    }
                })],

            })
        }


    }, [formatedNepEngDates, productAndBundleOptions,paymentModeOptions,tdsOptions, additionalChargeAccounts])


    useEffect(() => {

        if(Props.id) return;
        if (estimateCode) setFormState(prevState => {
            return {
                ...prevState,
                code: estimateCode.t.code
            }
        })

    }, [estimateCode])

    const [openDialogProduct, setOpenDialogProduct] = useState(false)
    const [openDialogCustomer, setOpenDialogCustomer] = useState(false)
    const [openDialogHead, setOpenDialogHead] = useState(false)
    const [formState, setFormState] = useState(initialState);

    const location = useLocation();
    const styles = useStyles();

    const convert = (transactionDate: any) => {

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            estimateYearAd: adDate[0],
            estimateDateAd: adDate[2],
            estimateMonthAd: adDate[1],
            estimateDateBs: bsDate[2],
            estimateMonthBs: bsDate[1],
            estimateYearBs: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }

    useEffect(() => {

        if (Props.id) return;
        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])

    }, [formatedNepEngDates])


    const validationSchema = Yup.object({
        customer: Yup.object().when("paymentStatus", {
            is: 'Full',
            then: Yup.object().nullable(true),
            otherwise: Yup.object().required("Required").nullable(true)
        }).nullable(true),
        paymentStatus: Yup.string().required('Required').nullable(true),
        paymentMode: Yup.object().when("paymentStatus", {
            is: 'Unpaid',
            then: Yup.object().nullable(true),
            otherwise: Yup.object().required("Required").nullable(true)
        }).nullable(true),
        amountPaid: Yup.number().when("paymentStatus", {
            is: 'Unpaid',
            then: Yup.number().max(0, "Must be 0"),
            otherwise: Yup.number().min(1, "Must be greater than 0").required("Required")
        })
    })


    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            if(values.tableRow.filter((item: any) => (item.product )).length === 0){
                handleResponse(`Select at least one product.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRow.filter((item: any) => (item.product) && !(item.quantity && item.price && item.amount)).length > 0) {
                handleResponse(`Some row doesn't have Product, Quantity, Price and Amount.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRowAdditional.filter((item: any) => (item.product) && !(item.price && item.amount)).length > 0){
                handleResponse(`Selected additional charge has no Price or amount.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            let customerAccount : any = {}

            if(values.paymentStatus !== 'Full'){
                let {data: tempAcc} = await axiosInstance.get(`/getAccountHeadOfCustomerById/${values?.customer?.id}`)
                customerAccount = tempAcc;
            }

            let entryJournal: object[] = [];
            let taxAccount = headOptionsInCategory.data.filter((item: any) => item.code === ACCOUNT_CODE_MAPPER.vatAccountCode)[0];
            let exemptedAmount = 0.00;

            values.tableRowAdditional.filter((item: any) => item.product && item.amount).map((item: any) => {

                let taxAmount = 0;
                let amount = parseFloat(item.amount) ;
                if(item.tax > 0) {

                    taxAmount = getTaxAmount(item.amount, parseFloat(item.tax));
                    amount  -= taxAmount

                    entryJournal.push({
                        accountHead: {id: taxAccount.id},
                        amount: taxAmount,
                        tax: item.tax,
                        accountGroupId: taxAccount.accgroupid,
                        type: 'credit',
                        estimate: true,
                        rate: '',
                        description: item.description,
                        subTypeId: taxAccount.subtypeid
                    })

                }else{
                    exemptedAmount += amount
                }

                entryJournal.push({
                    accountHead: {id: item.product.id},
                    amount: amount,
                    tax: item.tax,
                    accountGroupId: item.product.accgroupid,
                    type: 'credit',
                    rate: '',
                    estimate: true,
                    description: JSON.stringify(item),
                    subTypeId: item.product.subtypeid
                })
            })

             values.tableRow.filter((item: any) => item.product && item.amount).map((item: any) => {


                 // if(isOffer(item.product)){
                 //     handleResponse(`${item.product.bundlename || item.product.productname} has an offer. Discount cannot be allowed`, 'warning');
                 //     return;
                 // }
                 //
                 // // @ts-ignore
                 // else if( (item.discount  <= item.product.max_allowable_discount) ){
                 //     if( values.customer){
                 //         if(item.discount <= values.customer.maximum_allowed_discount ){
                 //
                 //         }else {
                 //             let message = (item.discount  > values.customer.maximum_allowed_discount) ? "Value is greater than allowed for customer." : "";
                 //             message = (item.discount  > item.product.max_allowable_discount) ? "Value is greater than allowed in item." : message
                 //             handleResponse(message, 'warning');
                 //             return;
                 //         }
                 //     }
                 //
                 // }else{
                 //
                 //     // @ts-ignore
                 //     let  message = (item.discount  > item.product.max_allowable_discount) ? `Value is greater than allowed in item ${item.product.bundlename || item.product.productname}` : ''
                 //     handleResponse(message, 'warning');
                 //     return;
                 // }


                 let account = accountForSales.data.filter((itemAccount: any) => itemAccount.id === (item.product.sales_account_id))[0];

                 // console.log(account);
                 // throw new Error('asdf');


                 let amount = item.amount;
                let taxAmount = 0;

                if(item.tax > 0) {
                    taxAmount = getTaxAmount(item.amount, parseFloat(item.tax));
                    amount  -= taxAmount

                    entryJournal.push({
                        accountHead: {id: taxAccount.id},
                        amount: taxAmount,
                        tax: item.tax,
                        accountGroupId: taxAccount.accgroupid,
                        type: 'credit',
                        rate: '',
                        estimate: true,
                        description: item.description,
                        subTypeId: taxAccount.subtypeid
                    })
                } else {
                    exemptedAmount += amount
                }

                entryJournal.push( {
                    accountHead: {id: account.id},
                    amount: amount,
                    tax: item.tax,
                    type: 'credit',
                    estimate: true,
                    accountGroupId: account.accgroupid,
                    rate: '',
                    description: item.description,
                    subTypeId: account.subtypeid
                })
            })


            let sumProduct = values.tableRow.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            let sumAdditional = values.tableRowAdditional.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            let sumTds = values.tds.filter((item: any) => item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            let tdsTaxableAmount = 0.00, tdsTaxAmount = 0.00, tdsTotalAmount = 0.00;


            values.tds.filter((item: any) => item.product && item.amount).map((item: any) => {
                let account =  item.product;
                let amount = item.amount;
                tdsTotalAmount += amount;
                tdsTaxableAmount += parseFloat(item.taxableAmount);

                entryJournal.push({
                    accountHead: {id: account.id},
                    amount: parseFloat(item.amount),
                    tax: item.tax,
                    type: 'debit',
                    name: 'tds',
                    accountGroupId: account.accgroupid,
                    rate: '',
                    description: JSON.stringify(item),
                    subTypeId: account.subtypeid
                })

            })

            let customerRow: any = [];
            let bankRow: any = [];

            if(values.paymentStatus === 'Full' || values.paymentStatus === 'Semi'){
                bankRow = [{
                    accountHead: {id: values.paymentMode.id},
                    amount: parseFloat(values.amountPaid),
                    tax: '',
                    type: 'debit',
                    name: 'bank',
                    accountGroupId: values.paymentMode.accgroupid,
                    rate: '',
                    estimate: true,
                    description: values.chequeNumber,
                    subTypeId: values.paymentMode.subtypeid
                }]
            }
            if (values.paymentStatus === 'Semi' || values.paymentStatus === 'Unpaid'){
                customerRow = [{
                    accountHead: {id: customerAccount.t.id},
                    amount: sumAdditional + sumProduct - sumTds - parseFloat(values.amountPaid || 0),
                    tax: '',
                    type: 'debit',
                    name: 'customer',
                    accountGroupId: customerAccount.t.accgroupid,
                    rate: '',
                    estimate: true,
                    description: '',
                    subTypeId: customerAccount.t.subtypeid
                }]
            }


            let bankAccount = values.paymentStatus === 'Semi' ? [...customerRow, ...bankRow] : values.paymentStatus === 'Full' ? [...bankRow] : [...customerRow];

            const {amount, discount, taxableAmount, taxAmount, totalAmount} = getInvoiceAmounts([...values.tableRow, ...values.tableRowAdditional]);

            let voucherEditInfo = {};
            if(Props.mode === 'edit'){
                //@ts-ignore
                let {voucherNumber, id} = location.state.journalVoucherDto
                voucherEditInfo = {
                    voucherNumber: voucherNumber,
                    id: id
                }
            }

            let payload = {
                estimateDate: values.estimateYearAd + '-' + ('0' + values.estimateMonthAd).slice(-2) + '-' + ('0' + values.estimateDateAd).slice(-2),
                expiryDate:(values.expiryYearAd && values.expiryMonthAd && values.expiryDateAd) ?  values.expiryYearAd + '-' + ('0' + values.expiryMonthAd).slice(-2) + '-' + ('0' + values.expiryDateAd).slice(-2): '',
                paymentMode: values.paymentMode ? {id : values.paymentMode.id}: null,
                paymentStatus: values.paymentStatus,
                customer: values.customer ? {id: values.customer.id}: null,
                estimateNumber: values.code,
                description: values.description,
                amountPaid:values.amountPaid,
                amount: amount,
                discount: discount,
                taxableAmount: taxableAmount,
                taxAmount: taxAmount,
                totalAmount: totalAmount,
                exemptedAmount: exemptedAmount,
                productBundleSubs: values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).map((item: any, index: number) => {



                    let editId= {};

                    //add id of each index of productSubs to each product sub in the new list of product that need to be sent to the server
                    if(Props.mode === 'edit'){
                        // @ts-ignore
                        if(location.state.productBundleSubs.length >= index + 1 ){
                            // @ts-ignore
                            editId = {id: location.state.productBundleSubs[index].id}
                        }
                    }

                    return {
                        ...editId,
                        productId: item.product.name === 'Product' ? item.product.productid: 0,
                        productBundleId: item.product.name === 'Product' ? 0: item.product.bundleid,
                        price: parseFloat(item.price),
                        discount: parseFloat(item.discount),
                        amount: parseFloat(item.amount),
                        quantity: item.quantity,
                        tax: parseFloat(item.tax)
                    }
                }),

                journalVoucherDto: {
                    ...voucherEditInfo,
                    type: 'journal',
                    receiptNumber: '',
                    journalRows: [
                        ...entryJournal,
                        ...bankAccount
                    ]
                }
            }

            console.log([
                ...entryJournal,
                ...bankAccount
            ])

            // throw new Error ("asdf");

            if(Props.id){

                const {data} = await axiosInstance.put('/updateEstimate', {...payload,id: parseInt(Props.id as string)});
                responseProcess(data, formActions, isReset)

            }else{
                const {data} = await axiosInstance.post('/addEstimate', payload);
                responseProcess(data, formActions, isReset)
            }


        } catch (e) {
            console.log(e)
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }

        formActions.setSubmitting(false)
    }


    const history = useHistory();

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {
        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) history.replace('/dashboard/sales/estimate')
            trigger('/getCode?name=estimate&id=0')
        } else {
            handleResponse(data.message, 'warning')
        }
    }

    const dispatch = useDispatch();
    const handleResponse = (message: any, type: string) => {
        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })
    }

    // const handleRemove = (index: number, formikInstance: any, sectionName: string) => {
    //     const {values} = formikInstance;
    //     let tempTable = [...values[sectionName]].filter((item: any, indexInner: number) => indexInner !== index)
    //     formikInstance.setFieldValue(sectionName, [...tempTable])
    // }

    const handleRemove = (index: number, formikInstance: any, sectionName: string) => {
        const {values} = formikInstance;
        console.log(sectionName);
        console.log(values[sectionName]);
        let tempTable = [...values[sectionName]].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue(sectionName, [...tempTable])
    }

    const isOffer = (product: any) => {

        if(!product.offerid) return false;
        let today = new Date().toLocaleString('en-us', {  weekday: 'long' });
        let initials = today.substring(0,2);
        if(product.effective_days.includes(initials)) return true;

    };


    const addNew = useCallback((formikInstance: any, sectionName: string) => {

        const {values} = formikInstance;
        let rows = values[sectionName]
        let tempArr = {};

        if(sectionName === 'tableRow'){
            tempArr = {
                id: rows ? rows[rows.length - 1].id + 1: 1,
                product: null,
                quantity: 0,
                accountHead: null,
                uom: null,
                price: 0,
                discount: 0,
                tax: 0,
                amount: 0
            }
        }else if(sectionName === 'tableRowAdditional'){
            tempArr = {
                id: rows.length> 1 ? rows[rows.length - 1].id + 1: 1,
                product: null, quantity: 0, uom: null, price: 0, discount: 0, tax: 0, amount: 0
            }
        }

        values[sectionName].push(tempArr)
        formikInstance.setFieldValue(sectionName, [...values[sectionName]])

    }, [formState])

    const componentRef = useRef(null);
    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@page { size: auto; margin: 00mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 130px 40px 100px 40px !important;  } }'

    });

    const printClicked = async () => {

        if (handlePrint) {
            handlePrint()
        }
    }
    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }


    return (
        <div>

            <DialogComponent open={openDialogProduct} handleClose={openDialogProduct} title={'Add Product'}
                             maxWidth={'md'}>
                <ProductItemForm mode={'edit'} from={'journal page'} handleClose={() => setOpenDialogProduct(false)}
                                 noFixed={true}
                />
            </DialogComponent>


            <DialogComponent open={openDialogCustomer} handleClose={setOpenDialogCustomer} title={'Add Customer'}
                             maxWidth={'md'}>
                <CustomerForm mode={'edit'} from={'journal page'} handleClose={() => setOpenDialogCustomer(false)}
                                 noFixed={true}
                />
            </DialogComponent>

            <DialogComponent open={openDialogHead} handleClose={openDialogHead} title={'Add Account Head'}
                             maxWidth={'sm'}>
                <AccountHeadForm mode={'edit'} from={'journal page'} handleClose={() => setOpenDialogHead(false)}
                                 noFixed={true}
                />
            </DialogComponent>

            <Formik
                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {

                    return (
                        <div>
                            <FocusError/>

                            <PagePrompt formik={formik}/>

                            <Form style={{margin: 0}} onKeyDown={onKeyDown}>
                                <div ref={componentRef}>

                                <Grid container justify={"space-between"}>

                                    {Props.mode === 'view' ?
                                        (
                                            formik.values.tableRow.length > 0 &&
                                            <EstimatePrintView formik={formik}/>
                                        ):
                                        (
                                            <>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="code"
                                                   className={styles.label}> Estimate No.:</label>
                                        </Grid>

                                        <Grid item xs={8} className={styles.label}>
                                            {formik.values.code}
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="customer"
                                                   className={styles.label}> Customer:{formik.values.paymentStatus !== 'Full' && <RequiredNotation/>}</label>
                                        </Grid>

                                        <Grid item container xs={8} justify={"space-between"}>
                                            <Grid item xs={10}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'customer'}>
                                                        {(props: { field: any; form: any; meta: any; }) => {
                                                            const {field, form, meta} = props;
                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={`customer`} {...field}
                                                                                       size={"small"}
                                                                                       filterOptions={(options, {inputValue}) => {
                                                                                           // let filteredOptions = getOptions(options)
                                                                                           //todo do this again
                                                                                           return matchSorter(options, inputValue, {keys: [ 'name','email', 'phone']}).sort(function(a: any, b: any) {
                                                                                               let nameA = a.name.toUpperCase();
                                                                                               let nameB = b.name.toUpperCase();
                                                                                               if (nameA < nameB) {
                                                                                                   return -1;
                                                                                               }
                                                                                               if (nameA > nameB) {
                                                                                                   return 1;
                                                                                               }

                                                                                               return 0;
                                                                                           });
                                                                                       }}
                                                                                       autoHighlight={true}
                                                                                       autoSelect={true}
                                                                                       onChange={(e, value) => {
                                                                                           form.setFieldValue(`customer`, value)
                                                                                           if(!value) return
                                                                                           let rows = form.values.tableRow;
                                                                                           form.setFieldValue("tableRow", rows.map((item: any) => {
                                                                                               let change = item.discount > value.maximum_allowed_discount;
                                                                                             return {...item, discount: change ? 0 : item.discount, amount : change ? (item.price * item.quantity)*(1+ (item.tax/100)) : item.amount}
                                                                                           }))
                                                                                       }}
                                                                                       options={customerOptions ? customerOptions.data : []}
                                                                                       loading={!customerOptions}
                                                                                       PopperComponent={PopperMy}
                                                                                       renderOption={(option) => {
                                                                                           return (
                                                                                               <div>
                                                                                                   <div style={{
                                                                                                       fontSize: 14,
                                                                                                   }}>
                                                                                                       <>{option.name} <span style={{color: COLOR_PROFILE.buttonBlue}}>({option.maximum_allowed_discount || 0}%)</span></>
                                                                                                   </div>
                                                                                                   <div style={{fontSize: 12}}> {option.email}</div>
                                                                                                   <div style={{fontSize: 14, color: COLOR_PROFILE.buttonGreen}}>{option.phone}</div>
                                                                                               </div>
                                                                                           )
                                                                                       }}
                                                                                       getOptionLabel={option => option.name}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>
                                                            )
                                                        }}

                                                    </Field>

                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <ToolTip title={'Add new customer'}>
                                                    <Grid item style={{
                                                        border: '1px solid #d0d0d0',
                                                        padding: '8px 10px 3px 10px',
                                                        cursor: 'pointer',
                                                        background: COLOR_PROFILE.buttonGreen,
                                                        // "&:hover":{boxShadow: "0 0 5px 1px gray"}

                                                    }}
                                                          onClick={() => setOpenDialogCustomer(prevState => !prevState)}
                                                    >
                                                        <div style={{}}>

                                                            <AddIcon style={{
                                                                color: "white",
                                                                fontWeight: 700,
                                                                fontSize: 20
                                                            }}/>

                                                        </div>
                                                    </Grid>
                                                </ToolTip>

                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="referenceBillNumber" className={styles.label}> Reference
                                                No.:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'referenceBillNumber'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'referenceBillNumber'} {...field}
                                                                                size={"small"}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="estimateDate"
                                                   className={styles.label}
                                            >
                                                Estimate Date:<RequiredNotation/>
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <DateComponent
                                                ad={['estimateYearAd', 'estimateMonthAd', 'estimateDateAd']}
                                                bs={['estimateYearBs', 'estimateMonthBs', 'estimateDateBs']}
                                            />

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="expiryDate"
                                                   className={styles.label}
                                            >
                                                Expiry Date:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <DateComponent
                                                ad={['expiryYearAd', 'expiryMonthAd', 'expiryDateAd']}
                                                bs={['expiryYearBs', 'expiryMonthBs', 'expiryDateBs']}
                                            />

                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="paymentStatus"
                                                   className={styles.label}> Payment Status:<RequiredNotation/> </label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'paymentStatus'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`paymentStatus`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`paymentStatus`, value)
                                                                                   }}

                                                                                   options={['Full', 'Semi', 'Unpaid']}
                                                                                   getOptionLabel={option => option}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="paymentMode"
                                                   className={styles.label}> Payment Mode:{formik.values.paymentStatus !== 'Unpaid' && <RequiredNotation/>}
                                            </label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'paymentMode'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`paymentMode`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`paymentMode`, value)
                                                                                   }}

                                                                                   options={paymentModeOptions ? paymentModeOptions.data : []}
                                                                                   loading={!paymentModeOptions}

                                                                                   groupBy={option => option.subtypename}
                                                                                   PopperComponent={PopperMy}
                                                                                   getOptionLabel={option => option.headname}
                                                                                   renderOption={(option) => {
                                                                                       return (
                                                                                           <div>
                                                                                               <div style={{
                                                                                                   fontSize: 11,
                                                                                               }}>
                                                                                                   <>{option.code}</>
                                                                                               </div>
                                                                                               <div>{option.headname}</div>
                                                                                           </div>
                                                                                       )
                                                                                   }}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="chequeNumber" className={styles.label}> Cheque
                                                Number:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'chequeNumber'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;


                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'chequeNumber'} {...field}
                                                                                size={"small"}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="amountPaid" className={styles.label}> Amount Paid:{formik.values.paymentStatus !== 'Unpaid' && <RequiredNotation/>}</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'amountPaid'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;


                                                            return (
                                                                <>
                                                                    <InputField type={'number'}
                                                                                id={'amountPaid'} {...field}
                                                                                size={"small"}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                            </>
                                        )}
                                </Grid>

                                <div>
                                    <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>
                                        <u>Products</u>
                                    </div>
                                    <EstimateProductsForm handleRemove={handleRemove} addNew={addNew}
                                                          setOpenDialog={setOpenDialogProduct}
                                                          mode={Props.mode}
                                                          formValues={{...formik.values}}
                                               formikInstance={formik}
                                    />
                                </div>

                                {!(Props.mode === 'view' && formik.values.tableRowAdditional.length === 0) && (
                                    <div>

                                        <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>
                                            <u>Additional Charges</u>
                                        </div>
                                        <EstimateAdditionalForm handleRemove={handleRemove} addNew={addNew}
                                                                mode={Props.mode}
                                                                setOpenDialog={setOpenDialogHead}
                                                             formikInstance={formik}
                                        />
                                    </div>
                                )}
                                    {!(Props.mode === 'view' && formik.values.tds.length === 0) && (
                                        <div>

                                            <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>
                                                <u>TDS</u>
                                            </div>
                                            <TdsForm handleRemove={handleRemove} addNew={addNew}
                                                     setOpenDialog={setOpenDialogHead}
                                                     mode={Props.mode}
                                                     payable={true}
                                                     formikInstance={formik}/>
                                        </div>
                                    )}
                                {/*<div>*/}

                                {/*    <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>*/}
                                {/*        <u>TDS</u>*/}
                                {/*    </div>*/}
                                {/*    <TdsForm handleRemove={handleRemove} addNew={addNew}*/}
                                {/*                         setOpenDialog={setOpenDialogHead}*/}
                                {/*                         formikInstance={formik}/>*/}
                                {/*</div>*/}

                                <div>
                                    <Grid container item xs={12} sm={6} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="description" className={styles.label}> Description:</label>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'description'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;


                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'description'} {...field}
                                                                                size={"small"}
                                                                                multiline={true}
                                                                                rows={3}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>
                                </div>

                                {Props.mode !== 'view' ?
                                    <div style={{marginTop: 50}}>
                                        <FormSaveButton mode={Props.mode} from={'product-bundle'}
                                                        submitting={formik.isSubmitting}/>
                                    </div>
                                    :
                                    <div>
                                        <ReportActionButton approveData={location.state} path={'Proforma'} convertToJournal={true} handlePrint={printClicked} handleDownloadAsPdf={printClicked}/>
                                    </div>
                                }

                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    );
}

export default EstimateAdditionForm;
