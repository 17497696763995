import React, {useEffect, useMemo, useState} from 'react';
import {Grid, IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../reactTable/useServerCall";
import useSWR from "swr";
import DeleteModal from "../../deleteModal";
import ReactTable from "../../reactTable/ReactTable";
import CircularSpinner from "../../../../shared/atom/spinner";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DataEmptyMessage from "../dataEmptyMessage";
import {useHistory} from "react-router-dom";

const VendorTable: React.FC<any> = (Props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const [deleteId, setDeleteId] = useState(null)

    const history = useHistory();
    const branchColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => {
                return (
                    <Grid container spacing={3} justify={'center'}>


                        {/*<Grid item xs={6}>*/}
                        {/*    <IconButton size={"small"} style={{color: '#ec4949'}} onClick={() => setDeleteId(val.row.original.id)}>*/}
                        {/*        <DeleteIcon fontSize={"small"}/>*/}
                        {/*    </IconButton>*/}
                        {/*</Grid>*/}
                        <Grid item >
                            <IconButton size={"small"} style={{color: '#4aca53'}}
                                        onClick={() => history.push(`/dashboard/company-setup/vendor/edit/${val.row.original.id}`, val.row.original)}

                            >
                                <EditIcon fontSize={"small"}/>
                            </IconButton>
                        </Grid>

                    </Grid>
                )
            }
        },

        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Phone 1',
            Footer: 'Phone 1',
            accessor: 'phone1'
        },
        {
            Header: 'Phone 2',

            Footer: 'Phone 2',
            accessor: 'phone2'
        },

        {
            Header: 'Website',
            Footer: 'Website',
            accessor: 'websiteUrl'
        },
        {
            Header: 'Products',
            Footer: 'Products',
            accessor: 'products'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },


    ]


    const groupedHeader = [
        {
            Header: 'Actions',
            width: 50,
            columns: [
                {
                    id: 'delete',
                    width: 10,

                    accessor: 'deleteButton',
                    Cell: () => (
                        <Grid container>


                            <Grid item xs={12}>
                                <IconButton size={"small"} style={{color: 'red'}}>
                                    <DeleteIcon fontSize={"small"}/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    )
                },
                {
                    id: 'edit',
                    width: 10,
                    accessor: 'editButton',
                    Cell: () => (
                        <Grid container>

                            <Grid item xs={12}>
                                <IconButton size={"small"} style={{color: 'green'}}>
                                    <EditIcon fontSize={"small"}/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    )
                }
            ]
        },
        {
            Header: 'Info',
            columns: [
                {
                    Header: 'Name',
                    Footer: 'Name',
                    accessor: 'name'
                },
                {
                    Header: 'Email',

                    Footer: 'Email',
                    accessor: 'email'
                },
                {
                    Header: 'Phone',
                    Footer: 'Phone',

                    accessor: 'phone'
                },
                {
                    Header: 'DOB',
                    Footer: 'DOB',

                    accessor: 'dateOfBirth'
                },
                {
                    Header: 'Citizenship Number',
                    Footer: 'Citizenship Number',

                    accessor: 'citizenshipNumber'
                }
            ]
        }
    ]

    const path = '/getVendors'

    const {data, loading} = useServerCall({
        path, page, perPage,
        search: `?name=${Props.formState.search}`
    })

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}?name=${Props.formState.search}`)


    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [];

    const mockData = useMemo(() => calledData, [data]);

    const columns = useMemo(() => branchColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div>
                    <DeleteModal message={`Are you sure? Once deleted it cannot be reverted.`}
                                 trigger={`/getBranches/${page - 1}/${perPage}`}
                                 deletePath={`/deleteBranch/${deleteId}`} open={deleteId}
                                 handleDeleteCancel={() => setDeleteId(null)}
                    />
                    <ReactTable
                        data={mockData}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPageOption={[30,50,100]}
                        perPage={perPage}
                        totalPage={data?.t.totalPage}
                    />
                </div>
            ): (
                (page ===1 && loading) ? null
                    :    <DataEmptyMessage/>
            )}
        </div>
    );
};

export default VendorTable;
