import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import Button from "../../../../shared/atom/button";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import InputField from "../../../../shared/atom/formElements/inputField";
import useAddressHandler from "../../../../../effects/useAddressHandler";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import FormSaveButton from "../../FormSaveButton";
import axiosInstance from "../../../../../config/axiosConfig";
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import RequiredNotation from "../../../molecules/requiredNotation";
import PagePrompt from "../../../../shared/organisms/pagePrompt";


interface Props {

    from: string
    mode: 'edit' | 'create'
    nextPath?: string
    id?: any
}


const VendorForm: React.FC<Props> = (Props) => {


    const dispatch = useDispatch();

    const initialValues = {


        address: {
            country: "Nepal",
            provinceOrState: null,
            district: null,
            city: "",
            wardNumber: null,
            municipalityOrVdc: null,
            googleMapLocationUrl: ''
        },
        name: "",
        accountHeadName: '',
        panNumber: '',
        phone1: "",
        phone2: "",
        websiteUrl: '',
        products: ""

    }

    const [formState, setFormState] = useState(initialValues)

    const [selectedAddress, setSelectedAddress] = useState({
        province: null,
        district: null,
        vdc: null,
        ward: null
    })

    const {
        provinceOptions,
        districtOptions,
        vdcOptions,
        wardOptions,
        provinceLoading,
        vdcLoading
    } = useAddressHandler({...selectedAddress})

    const location = useLocation();

    useEffect(() => {

            if(!(provinceOptions && districtOptions && vdcOptions && wardOptions)) return;

            if (Props.id) {
                // @ts-ignore
                const {name,panNumber,panPicture,phone1,phone2,products,websiteUrl,accountHeadName,address} = location.state;


                try {

                    let provinceTemp = provinceOptions.find((item: any) => item.id === address?.provinceOrState?.id);
                    validateProvince(provinceTemp);
                    let districtTemp = districtOptions.find((item:any) => item.district == address?.district)
                    handleSelectedDistrict(districtTemp);
                    let muniCipalityTemp = vdcOptions.find((item: any) => item.municipalityOrVdcName = address?.municipalityOrVdc);
                    validateMunicipality(muniCipalityTemp);

                    let addressTemp = {
                        address: {
                            country: 'Nepal',
                            provinceOrState: provinceTemp,
                            municipalityOrVdc:  muniCipalityTemp,
                            district: districtTemp,
                            wardNumber: address?.wardNumber || null,
                            city: address?.city || '',
                            googleMapLocationUrl: address?.googleMapLocationUrl || ''
                        }

                    }

                    // @ts-ignore
                    setFormState({
                        ...addressTemp,
                        name: name,
                        accountHeadName: accountHeadName,
                        panNumber: panNumber,
                        phone1: phone1,
                        phone2: phone2,
                        websiteUrl: websiteUrl,
                        products: products
                    })

                } catch (e) {

                }

            }

        }
        ,
        [provinceOptions, districtOptions, vdcOptions, wardOptions]
    )


    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        accountHeadName: Yup.string().required('Required').nullable(true)
    })




    const onSubmit = async (values: any, other: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            const tempVal = values;
            const payload = {
                ...tempVal,
                address: {
                    ...tempVal.address,
                    provinceOrState: tempVal.address.provinceOrState ? tempVal.address.provinceOrState.province : null,
                    municipalityOrVdc: tempVal.address.municipalityOrVdc ? tempVal.address.municipalityOrVdc.municipalityOrVdcName : null,
                    district: tempVal.address.district ? tempVal.address.district.district : null,
                    wardNumber: tempVal.address.wardNumber,
                    city: tempVal.address.city,
                }
            }

            if(Props.id){
                const {data} = await axiosInstance.put('/updateVendor', {...payload, id: Props.id})

                if (data?.code === 'OK') {
                    handleResponse(data, other, isReset);
                }else{
                    throw new Error();
                }

            }else{
                const {data} = await axiosInstance.post('/addVendor', payload)

                if (data?.code === 'OK') {
                    handleResponse(data, other, isReset);
                }else{
                    throw new Error();
                }
            }

        } catch (e) {
            console.log(e)
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: 'Something went wrong', type: 'warning'}
            })
            other.setSubmitting(false)
        }

        other.setSubmitting(false)


    }


    const handleResponse = (data: any, formActions: any, isReset: any) => {

        let message = ''
        let barType = 'info'


        if (data?.code === 'OK') {

            message = data?.message;

            if (data?.message.includes('added') || data?.message === "Vendor information updated") {
                // trigger(`/getShareHolders/0/5`);
                formActions.resetForm();
                barType = 'success'
                if(!isReset) history.replace('/dashboard/company-setup/vendors');

            } else {
                barType = 'warning'
            }

        }

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: message, type: barType}
        })
    }




    const validateProvince = (value: any) => {


        if (value?.province) {


            setSelectedAddress(prevState => {
                return {
                    ...prevState,
                    province: value
                }
            })

        }

        return false

    }

    const handleSelectedDistrict = (value: any) => {

        setSelectedAddress(prevState => {
            return {
                ...prevState,
                district: value
            }
        })

    }

    const validateMunicipality = (value: any) => {


        if (value?.wardNumbers) {

            setSelectedAddress(prevState => {
                return {
                    ...prevState,
                    vdc: value
                }
            })

        }

    }

    const history = useHistory();

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>
            <Formik

                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {


                    return (
                        <div>
                            <PagePrompt formik={formik}/>

                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>

                                <Grid container justify={"space-between"}>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="name"
                                                   style={{
                                                       fontWeight: "bold",
                                                       fontSize: 14
                                                   }}>Name:<RequiredNotation/></label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'name'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'} id={'name'} {...field}
                                                                                size={"small"}

                                                                                onChange = {(event: any) => {
                                                                                    form.setFieldValue("name", event.target.value)

                                                                                    if(!Props.id) form.setFieldValue("accountHeadName", event.target.value + ' (Account Payables)');

                                                                                    if(!event.target.value) form.setFieldValue("accountHeadName", '');


                                                                                }}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="accountHeadName" style={{fontWeight: "bold", fontSize: 14}}>
                                                Account Head Name:<RequiredNotation/>
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'accountHeadName'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                disabled={Props.id}
                                                                                id={'accountHeadName'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                            <span style={{
                                                color: 'red',
                                                fontSize: 12,
                                                bottom: 0,
                                                left: 2
                                            }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="panNumber" style={{fontWeight: "bold", fontSize: 14}}>PAN
                                                Number:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'panNumber'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'panNumber'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="phone1" style={{fontWeight: "bold", fontSize: 14}}>Phone No.
                                                1:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'phone1'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'phone'} id={'phone1'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="phone2" style={{fontWeight: "bold", fontSize: 14}}>Phone No.
                                                2:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'phone2'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'phone'} id={'phone2'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'flex-start'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4} style={{marginTop: 5}}>
                                            <label htmlFor="products"
                                                   style={{fontWeight: "bold", fontSize: 14}}>Products:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'products'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'} id={'products'} {...field}
                                                                                size={"small"}
                                                                                multiline={true}
                                                                                rows={3}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'flex-start'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4} style={{marginTop: 5}}>
                                            <label htmlFor="websiteUrl" style={{fontWeight: "bold", fontSize: 14}}>Website
                                                URL:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'websiteUrl'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'websiteUrl'} {...field}
                                                                                size={"small"}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>
                                                                    {(meta.touched && meta.error) ? meta.error : null}
                                                                </span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="provinceOrState"
                                                   style={{fontWeight: "bold", fontSize: 14}}> Province:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'address.provinceOrState'}
                                                       validate={validateProvince}
                                                >
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;


                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'provinceOrState'} {...field}
                                                                                       size={"small"}
                                                                                       onChange={(e, value) => {
                                                                                           if (value === null) {
                                                                                               form.setFieldValue('address.district', null)
                                                                                               form.setFieldValue('address.municipalityOrVdc', null)
                                                                                               form.setFieldValue('address.city', null)
                                                                                               form.setFieldValue('address.wardNumber', null)
                                                                                           }
                                                                                           form.setFieldValue("address.provinceOrState", value)
                                                                                       }}
                                                                                       options={provinceOptions}
                                                                                       getOptionLabel={option => option.province}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="district"
                                                   style={{fontWeight: "bold", fontSize: 14}}> District:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'address.district'}

                                                >
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'district'} {...field}
                                                                                       size={"small"}
                                                                                       onChange={(e, value) => {
                                                                                           if (value === null) {
                                                                                               form.setFieldValue('address.municipalityOrVdc', null)
                                                                                               form.setFieldValue('address.wardNumber', null)
                                                                                               form.setFieldValue('address.city', null)
                                                                                           } else {
                                                                                               handleSelectedDistrict(value)

                                                                                           }
                                                                                           form.setFieldValue("address.district", value)
                                                                                       }}
                                                                                       options={districtOptions}
                                                                                       getOptionLabel={option => option.district}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>

                                                        <span
                                                            style={{
                                                                color: 'red',
                                                                fontSize: 12,
                                                                bottom: 0,
                                                                left: 2
                                                            }}
                                                        >
                                                            {(meta.touched && meta.error) ? meta.error : null}
                                                        </span>

                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="municipalityOrVdc"
                                                   style={{fontWeight: "bold", fontSize: 14}}> Municipality/
                                                Vdc:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'address.municipalityOrVdc'}
                                                       validate={validateMunicipality}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'municipalityOrVdc'} {...field}
                                                                                       size={"small"}
                                                                                       onChange={(e, value) => {
                                                                                           if (value === null) {
                                                                                               form.setFieldValue('address.wardNumber', null)
                                                                                           }
                                                                                           form.setFieldValue("address.municipalityOrVdc", value)
                                                                                       }}
                                                                                       loading={vdcLoading}
                                                                                       options={vdcOptions}
                                                                                       getOptionLabel={option => option.municipalityOrVdcName}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="wardNumber" style={{fontWeight: "bold", fontSize: 14}}> Ward
                                                No.:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'address.wardNumber'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'wardNumber'} {...field}
                                                                                       size={"small"}
                                                                                       onChange={(e, value) => form.setFieldValue("address.wardNumber", value)}
                                                                                       options={wardOptions}
                                                                                       getOptionLabel={option => option}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="city" style={{fontWeight: "bold", fontSize: 14}}> Tole/
                                                Street:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'address.city'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'} id={'city'} {...field}
                                                                                size={"small"}

                                                                                onChange={(e: any) => {
                                                                                    form.setFieldValue("address.city", e.target.value)
                                                                                }}

                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="googleMapLocationUrl"
                                                   style={{fontWeight: "bold", fontSize: 14}}> Google
                                                Map URL:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'address.googleMapLocationUrl'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'address.googleMapLocationUrl'} {...field}
                                                                                size={"small"}

                                                                                onChange={(e: any) => {
                                                                                    form.setFieldValue("address.googleMapLocationUrl", e.target.value);
                                                                                }}

                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>


                                </Grid>


                                <Grid container justify={"space-between"} alignItems={"center"}>
                                    <Grid item>
                                        <FormSaveButton mode={Props.mode} from={Props.from}
                                                        submitting={formik.isSubmitting}/>
                                    </Grid>
                                    <Grid item>
                                        {Props.from === 'home' && <div>
                                            <div>
                                                <Button variant={"contained"} customColor={'#297ecc'}
                                                        textColor={'white'}
                                                        onClick={() => history.push(Props.nextPath ? Props.nextPath : '')}>
                                                    Go to Branch Setup
                                                </Button>
                                            </div>
                                        </div>}
                                    </Grid>

                                </Grid>

                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )

}


export default VendorForm;
