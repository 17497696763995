import {Checkbox, Grid} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from "yup";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../molecules/errorLabel";
import InputField from "../../../../shared/atom/formElements/inputField";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {makeStyles} from "@material-ui/core/styles";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import {useDispatch} from "react-redux";
import useSWR, {trigger} from "swr";
import axiosInstance from "../../../../../config/axiosConfig";
import {useHistory, useLocation} from "react-router-dom";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {

    from: string
    mode: 'edit' | 'create',
    nextPath ?: any,
    id?: string | null

}

interface categoryInterface {
    parentCategory?: object | null,
    categoryName: string,
    code: string,
    treatAsInventory?: boolean,
    treatAsExpense?: boolean,
    expenseAccount?: object | null,
    incomeAccount?: object | null,
    inventoryAccount?: object | null

}

const useStyles= makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const ProductCategoryForm: React.FC<Props> = (Props) => {

    const initialValue: categoryInterface = {
        parentCategory: null,
        categoryName: '',
        code: '',
        treatAsInventory: false,
        treatAsExpense: true,
        expenseAccount: null,
        incomeAccount: null,
        inventoryAccount: null
    }
    const styles = useStyles();

    const {data: categoryCode} = useSWR('/getCode?name=category&id=0')
    const {data: accountsData} = useSWR('/getHeadsForCategory');
    const {data: categoriesData} = useSWR('/getAllCategories');

    const location = useLocation();

    useEffect(() => {

        if(!categoriesData) return;
        if (Props.id) {

            // @ts-ignore
            const {code, name, description, parent} = location.state

            setFormState({
                parentCategory: categoriesData.data.find((item: any) => item.id === parseInt(parent)),
                categoryName: name,
                code: code,
                treatAsInventory: false,
                treatAsExpense: true,
                expenseAccount: null,
                incomeAccount: null,
                inventoryAccount: null
            })
        }
    }, [categoriesData])

    useEffect(() => {

        if(Props.id) return;
        if (categoryCode) setFormState(prevState => {
            return {
                ...prevState,
                code: categoryCode.t.code
            }
        })

    }, [categoryCode])

    const [formState, setFormState] = useState(initialValue);

    const validationSchema = Yup.object({
        categoryName: Yup.string().required('Required')
    })

    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            let payload = {
                name: values.categoryName,
                code: values.code,
                parent: values.parentCategory? values.parentCategory.id : 0,
            }

            if(Props.id){
                const {data} = await axiosInstance.put('/updateCategory', {id: parseInt(Props.id), ...payload});
                responseProcess(data, formActions, isReset);
            }else{
                const {data} = await axiosInstance.post('/addCategory', payload);
                responseProcess(data, formActions, isReset);
            }

        }catch (e) {
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }
    }

    const history = useHistory();

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {
        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) history.replace('/dashboard/product/product-category')
            trigger('/getCode?name=category&id=0');
            trigger('/getAllCategories');
        } else {
            handleResponse(data.message, 'warning')
        }
    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {
        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })
    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }


    return (
        <div>
            <Formik
                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {
                    return (
                        <div>
                            <PagePrompt formik={formik}/>
                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>
                                <Grid container justify={"space-between"}>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="categoryName"
                                                  className={styles.label}> Category Code:</label>
                                        </Grid>

                                        <Grid item xs={8} className={styles.label}>
                                            {formik.values.code}
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="categoryName"
                                                   className={styles.label}> Category Name:<RequiredNotation/></label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'categoryName'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'text'}
                                                                            id={'categoryName'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="parentCategory"
                                                   className={styles.label}> Parent Category:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'parentCategory'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={'parentCategory'} {...field}
                                                                                   size={"small"}
                                                                                   onChange={(e, value) => form.setFieldValue("parentCategory", value)}
                                                                    loading={!categoriesData}
                                                                    options={categoriesData ? categoriesData.data : []}
                                                                                   getOptionLabel={option => option.name}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <FormSaveButton mode={Props.mode} from={Props.from} submitting={formik.isSubmitting}/>

                            </Form>

                        </div>
                    )
                }}
            </Formik>
        </div>
    );
};

export default ProductCategoryForm;