import React from 'react';
import PageTopBar from "../../../accounting/organisms/pageTopBar";
import DividerComponent from "../../../shared/atom/divider";
import ProductBundleForm from "../../organisms/form/productBundle";
import ProductOfferForm from "../../organisms/form/productOffer";
import {useParams} from "react-router-dom";

const ProductOfferAddition: React.FC = (Props) => {

    // @ts-ignore
    const {id} = useParams();

    return (
        <div>
            <PageTopBar name={id? 'Edit Product Sales/ Offer':'Add Product Sales/ Offer'}/>

            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: '25px 0px 10px 25px',}}>
                <div style={{maxWidth: 1130, paddingRight:20, paddingBottom: 50}}>
                    <ProductOfferForm mode={id ? 'edit':"create"} from={'product-offer'} id={id}/>
                </div>

            </div>
        </div>
    );
};

export default ProductOfferAddition;