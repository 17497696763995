import React from 'react';
import ProductCategoryHome from "../../templates/productCategoryHome";

const ProductCategory: React.FC = () => {
    return (
        <div>
            <ProductCategoryHome/>
        </div>
    );
};

export default ProductCategory;