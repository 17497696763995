import {Route} from "react-router-dom";
import {motion} from "framer-motion";
import AccessFilter from "../../../accessControl/accessFilter";
import AccessDenied from "../../../components/accounting/templates/errorTemplates/accessDenied";
import GeneralLedger from "../../../components/accounting/virtualPages/reporting/generalLedger";
import GroupSummary from "../../../components/accounting/virtualPages/reporting/groupSummary";
import ProfitLoss from "../../../components/accounting/virtualPages/reporting/profitLoss";
import TrialBalance from "../../../components/accounting/virtualPages/reporting/trialBalance";
import BalanceSheet from "../../../components/accounting/virtualPages/reporting/balanceSheet";
import React from "react";
import ReceivablesReport from "../../../components/accounting/virtualPages/reporting/receivablesReport";
import PayablesReport from "../../../components/accounting/virtualPages/reporting/payablesReport";
import PurchaseReport from "../../../components/accounting/virtualPages/reporting/purchaseReport";
import SalesReport from "../../../components/accounting/virtualPages/reporting/salesReport/indes";
import ExpenseReport from "../../../components/accounting/virtualPages/reporting/expenseReport";
import SalesReturnTemplate from "../../../components/accounting/templates/reporting/salesReturnTemplate";
import CancellationReport from "../../../components/accounting/virtualPages/reporting/cancellationReport";

export const ReportingRoutes = [
    <Route path={'/dashboard/reports/general-ledger'} exact>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"generalLedger"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <GeneralLedger/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/reports/group-summary'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"groupSummary"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <GroupSummary/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/reports/receivables-reports/:id'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"receivableReport"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ReceivablesReport/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/reports/payable-reports/:id'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"payableReport"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PayablesReport/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/reports/purchase-reports/:id'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"groupSummary"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PurchaseReport/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/reports/sales-reports/:id'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesReport"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <SalesReport/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/reports/cancellation-reports/:id'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesReport"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <CancellationReport/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/reports/sales-return-report/:id'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesReport"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <SalesReturnTemplate/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/reports/expense-reports/:id'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesReport"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ExpenseReport/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/reports/profit-loss'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"profitAndLoss"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ProfitLoss/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/reports/trial-balance'}>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"trialBalance"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <TrialBalance/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/reports/balance-sheet'}>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"balanceSheet"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <BalanceSheet/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>
]
