import React, {useEffect, useState} from 'react';
import useSWR, {trigger} from "swr";
import * as Yup from "yup";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik} from "formik";
import {Grid} from "@material-ui/core";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import InputField from "../../../../shared/atom/formElements/inputField";
import FormSaveButton from "../../FormSaveButton";
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import RequiredNotation from "../../../molecules/requiredNotation";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {

    from: string
    mode: 'edit' | 'create',
    handleClose?: any
    id?: any,
    noFixed?: boolean

}

const AccountHeadForm: React.FC<Props> = (Props) => {


    const initialValues = {
        name: '',
        accountGroupDto: null,
        description: '',
        code: '',
        reporting: false
    }
    const {data: accountGroupOptions} = useSWR('/getAllAccountGroups');
    const location = useLocation();

    useEffect(() => {

        if (!accountGroupOptions) return;

        if (Props.id) {

            console.log(location.state);
            // @ts-ignore
            const {name, accountGroupDto, description, code} = location.state;

            setFormState({
                name: name,
                accountGroupDto: accountGroupDto,
                description: description,
                code: code,
                reporting: false
            })


        }

    }, [accountGroupOptions])


    const [formState, setFormState] = useState(initialValues)


    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        accountGroupDto: Yup.object().required('Required').nullable(),
        code: Yup.string().required('Required')

    })
    const dispatch = useDispatch();


    const onSubmit = async (values: any, formActions: any) => {

        const {id} = values.accountGroupDto

        let isReset = values.action === 'submitAndAdd';


        try {
            //todo there is account type in account head post... what is that???
            const payload = {

                name: values.name,
                code: values.code,
                accountGroupDto: {id: id},
                description: values.description

            }

            if(Props.id){

                // @ts-ignore
                let {id} = location.state;
                const {data} = await axiosInstance.put('/updateAccountHead', {...payload, id : id})

                if (data.code === 'OK') {
                    trigger('/getAllAccountHeads')
                    responseProcess(data, formActions, isReset)
                } else {
                    handleResponse(data.message, 'warning')
                }
            }else{
                const {data} = await axiosInstance.post('/addAccountHead', payload)

                if (data.code === 'OK') {
                    trigger('/getAllAccountHeads')
                    responseProcess(data, formActions, isReset)
                } else {
                    handleResponse(data.message, 'warning')
                }
            }



        } catch (e) {
            handleResponse('Something went wrong', 'warning')
        }


        formActions.setSubmitting(false)


    }

    const history = useHistory();

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {

            trigger('/getAllAccountSubTypesAndGroups')
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) {
                Props.handleClose ?
                    Props.handleClose() :
                    history.replace('/dashboard/accounting-setup/account-head');
            }

        } else {

            handleResponse(data.message, 'warning')

        }
    }


    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }


    const size = Props.from === 'journal page' ? 12 : 6;


    const handleGetCode = async (value: any, form: any) => {


        if(!value){
            form.setFieldValue("code", '')
            return
        }


        const {name: accSubName, type, code, id} = value;

        const {data} = await axiosInstance.get(`/getCode?name=accountHead&id=${id}`)
        form.setFieldValue("code", data.t.code)
    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>
            <Formik

                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {


                    return (
                        <div>

                            <PagePrompt formik={formik}/>

                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>


                                <Grid container justify={"space-between"}>

                                    <Grid container item sm={size} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="accountGroupDto"
                                                   style={{fontWeight: "bold", fontSize: 14}}> Account
                                                Group:<RequiredNotation/></label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'accountGroupDto'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'accountGroupDto'} {...field}
                                                                                       size={"small"}

                                                                                       onChange={(e, value) => {
                                                                                           handleGetCode(value,form);
                                                                                           form.setFieldValue("accountGroupDto", value)
                                                                                       }}
                                                                                       options={accountGroupOptions?.data}
                                                                                       loading={!accountGroupOptions}
                                                                                       getOptionLabel={option => option.name}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid container item sm={size} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="name" style={{fontWeight: "bold", fontSize: 14}}> Name:<RequiredNotation/></label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'name'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;


                                                        return (
                                                            <>
                                                                <InputField type={'text'} id={'name'} {...field}
                                                                            size={"small"}
                                                                            fullWidth={true}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>

                                <Grid container item sm={size} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="code" style={{fontWeight: "bold", fontSize: 14}}> Code:<RequiredNotation/></label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'code'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;


                                                        return (
                                                            <>
                                                                <InputField type={'text'} id={'code'} {...field}
                                                                            size={"small"}
                                                                            fullWidth={true}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>


                                <Grid container item sm={size} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="description"
                                               style={{fontWeight: "bold", fontSize: 14}}> Description:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'description'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;


                                                        return (
                                                            <>
                                                                <InputField type={'text'} id={'description'} {...field}
                                                                            size={"small"}
                                                                            multiline={true}
                                                                            rows={3}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>

                                <FormSaveButton mode={Props.mode} from={'account-head'} submitting={formik.isSubmitting}
                                                handleClose={Props.handleClose}
                                                noFixed={Props.noFixed}
                                />


                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )
};

export default AccountHeadForm;
