import React from 'react';
import InvoiceAddition from "../../templates/invoiceAddition";

const InvoiceAdd: React.FC = () => {
    return (
        <div>
            <InvoiceAddition/>
        </div>
    );
};

export default InvoiceAdd;
