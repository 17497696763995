import {Route} from "react-router-dom";
import {motion} from "framer-motion";
import AccessFilter from "../../../accessControl/accessFilter";
import AccessDenied from "../../../components/accounting/templates/errorTemplates/accessDenied";
import Estimate from "../../../components/sales/virtualPages/estimate";
import EstimateAdd from "../../../components/sales/virtualPages/estimateAdd";
import Invoice from "../../../components/sales/virtualPages/invoice";
import InvoiceAdd from "../../../components/sales/virtualPages/invoiceAdd";
import Return from "../../../components/sales/virtualPages/return";
import React from "react";
import ReturnAdd from "../../../components/sales/virtualPages/returnAdd";
import CustomerPage from "../../../components/accounting/virtualPages/companySetup/customers";
import AddCustomerPage from "../../../components/accounting/virtualPages/companySetup/addCustomerPage";
import MembershipPage from "../../../components/accounting/virtualPages/companySetup/membership";
import AddMembershipPage from "../../../components/accounting/virtualPages/companySetup/addMembershipPage";
import InvoicePrintViewSuvam from "../../../components/sales/organisms/forms/invoice/invoicePrintViewSuvam";
import AccessLogs from "../../../components/sales/virtualPages/logs";
import MaterializedView from "../../../components/sales/virtualPages/materializedView";

export const SalesRoutes = [

    <Route path={'/dashboard/sales/membership'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}
            >
                <MembershipPage/>
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/sales/membership/add'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}
            >
                <AddMembershipPage/>
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/sales/membership/edit/:id'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}
            >
                <AddMembershipPage/>
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/sales/customers'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}
            >
                <CustomerPage/>
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/sales/customers/add'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AddCustomerPage/>
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/sales/customers/edit/:id'} exact>
        <div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.08}}

            >
                <AddCustomerPage/>
            </motion.div>
        </div>
    </Route>,

    <Route path={'/dashboard/sales/estimate'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"estimate"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <Estimate/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/sales/estimate/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"estimate"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <EstimateAdd from={'estimate'}/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/sales/estimate/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"estimate"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <EstimateAdd from={'estimate'}/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/sales/estimate/view/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"estimate"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <EstimateAdd from={'estimate'}/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/sales/invoice'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}
        >
            <AccessFilter

                section={"invoice"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <Invoice/>

                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,


    <Route path={'/dashboard/sales/invoice/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"invoice"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <InvoiceAdd/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/sales/invoice/view/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"invoice"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <InvoiceAdd/>
                                {/*<InvoicePrintViewSuvam/>*/}
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/sales/logs'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"balanceSheet"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <AccessLogs/>
                                {/*<InvoicePrintViewSuvam/>*/}
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/sales/return'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesReturn"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <Return/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,
    <Route path={'/dashboard/sales/materialized'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesReturn"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <MaterializedView/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/sales/return/add'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesReturn"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <ReturnAdd/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/sales/return/edit/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesReturn"}
                privilege={"update"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <ReturnAdd/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/sales/return/view/:id'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"salesReturn"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (
                        <div>
                            <div>
                                <ReturnAdd/>
                            </div>
                        </div>
                    )

                }}

            />
        </motion.div>
    </Route>
]
