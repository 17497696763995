import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {useHistory, useLocation} from "react-router-dom";
import * as Yup from "yup";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Grid, Popper, useMediaQuery} from "@material-ui/core";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import DateComponent from "../../../../product/molecules/dateComponent";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import InputField from "../../../../shared/atom/formElements/inputField";
import useSWR, {trigger} from "swr";
import {matchSorter} from "match-sorter";
import ToolTip from "../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import {Column} from "react-table";
import VoucherTableForm from "../../../../accounting/organisms/Forms/voucherTableForm";
import {useDispatch} from "react-redux";
import {getTaxAmount} from "../../../../../utils/getTax";
import {getInvoiceAmounts} from "../../../../../utils/invoiceAmounts";
import TdsForm from "../../../../sales/organisms/forms/tdsForm";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs} from "../../../../../config/dateFunctions";
import ProductItemForm from "../../../../product/organisms/form/productItem";
import DialogComponent from "../../../../shared/atom/dialog";
import AccountHeadForm from "../../../../accounting/organisms/Forms/accountHeadForm";
import PurchaseOrderPrintView from "../purchaseOrder/purchaseOrderPrintView";
import ReportActionButton from "../../../../accounting/organisms/reportActionButton";
import {useReactToPrint} from "react-to-print";
import PurchaseBillPrintView from "./purchaseBillPrintView";
import ErrorDialog from "../../../../shared/organisms/errorDialog";
import {ACCOUNT_CODE_MAPPER} from "../../../../../utils/accountCodeMapper";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {
    from: string
    mode: 'edit' | 'create' | 'view',
    nextPath?: any,
    id ?: string | null

}

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    },
    labelValue: {
        fontWeight: "bold",
        fontSize: 14,
        paddingLeft: 10, paddingRight: 10,
        color: COLOR_PROFILE.primaryColor
    }
}))

const PopperMy = function (props: any) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (<Popper {...props} style={{width: matches ? 250:500}} placement='bottom-start'/>)
}

const PurchaseBillForm: React.FC<any> = (Props) => {

    const initialState = {
        transactionYearAd: null,
        transactionMonthAd: null,
        transactionDateAd: null,
        transactionYearBs: null,
        transactionMonthBs: null,
        transactionDateBs: null,
        code: '',
        vendor: null,
        paymentStatus: 'Full',
        paymentMode: null,
        amountPaid: 0,
        chequeNumber: '',
        referenceBillNumber: '',
        purchaseOrder: null,
        document: '',
        description: '',

        entryDate: '',

        tableRow: [
            {
                id: 1,
                accountHead:null,
                product: null,
                quantity: 0,
                uom: null,
                price: 0,
                discount: 0,
                expiryDate: '',
                tax: 0,
                description: '',
                amount: 0
            },
            {
                id: 2,
                accountHead:null,
                product: null,
                quantity: 0,
                uom: null,
                price: 0,
                discount: 0,
                tax: 0,
                expiryDate: '',
                description: '',
                amount: 0
            },
        ],

        tableRowAdditional: [
            {id: 1, product: null, price: 0, tax: 0, description: '', amount: 0},
            {id: 2, product: null, price: 0, tax: 0, description: '', amount: 0},
        ],
        tds: [
            {id: 1, item: null, product: null, taxableAmount: 0, tax: 0, amount: 0},
            {id: 2, item: null, product: null, taxableAmount: 0, tax: 0, amount: 0},
        ],
    }

    const {data: purchaseBillCode} = useSWR('/getCode?name=purchaseBill&id=0')

    useEffect(() => {

        if(Props.id) return;
        if (purchaseBillCode) setFormState(prevState => {
            return {
                ...prevState,
                code: purchaseBillCode.t.code
            }
        })

    }, [purchaseBillCode])

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();
    const {data: productOptions} = useSWR('/getProducts');
    const {data: inventoryAccount} = useSWR( `/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.inventoryAccount}`);
    const {data: assetAccount} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.assetAccount}`);
    const {data: vendorsData} = useSWR('/getVendors');
    const {data: paymentModeOptions} = useSWR('/getHeadsForPayment');
    const {data: purchaseOrderOptions} = useSWR('/getPurchaseOrders?vendorId=0')
    const {data: headOptionsInCategory} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.currentAssetInventory},${ACCOUNT_CODE_MAPPER.indirectExpenseAdministrativeExpense},${ACCOUNT_CODE_MAPPER.currentLiabilites},${ACCOUNT_CODE_MAPPER.currentAssetAdvanceTax}`);
    const {data: indirectExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectExpenseAccount}`);
    const {data: directExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.directExpenseAccount}`);
    const {data: tdsOptions} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.indirectExpenseTDS}`);

    const location = useLocation();

    useEffect(() => {

        dispatch({type: 'SET_DATA_FETCHING', payload: true});
        if(!formatedNepEngDates) return;
        if(!inventoryAccount) return;
        if(!assetAccount) return;
        if(!productOptions) return;
        if(!paymentModeOptions) return;
        if(!indirectExpenseAccounts) return;
        if(!directExpenseAccounts) return;

        let additionalChargeAccounts = [...indirectExpenseAccounts.data,...directExpenseAccounts.data].filter((item: any) => item.name !== 'VAT');
        let additionalChargeAccountsId = additionalChargeAccounts.map((item: any) => item.id);

        dispatch({type: 'SET_DATA_FETCHING', payload: false});

        if (Props.id) {

            // @ts-ignore
            const {productSubs, billNumber,paymentStatus,paymentMode,referenceBillNumber,amountPaid,description,transactionDate, billDate, orderNumber, vendorDto, paymentMethod, initialPayment, paymentMethod2, chequeNumber, journalVoucherDto} = location.state
            let adTransactionDate = billDate.split('-');
            let bsTransactionDate: any = convertAdToBs(billDate, formatedNepEngDates) || [];


            // @ts-ignore
            let tempJson = {
                transactionYearAd: adTransactionDate[0],
                transactionDateAd: adTransactionDate[2],
                transactionMonthAd: adTransactionDate[1],
                transactionDateBs: bsTransactionDate[2],
                transactionMonthBs: bsTransactionDate[1],
                transactionYearBs: bsTransactionDate[0]
            }




            // @ts-ignore
            setFormState({
                ...formState,
                ...tempJson,
                code: billNumber,
                chequeNumber: chequeNumber,
                referenceBillNumber: referenceBillNumber,
                paymentStatus: paymentStatus,
                amountPaid: amountPaid,
                vendor: vendorDto,
                description: description,
                entryDate: transactionDate,
                paymentMode: paymentMode ? paymentModeOptions.data.find((item: any) => item.id === paymentMode.id): null,
                //purchase order number
                tableRow: [...productSubs.map((item: any) => {

                    let tempAccounts = [...indirectExpenseAccounts.data,...directExpenseAccounts.data].map((item: any) => {
                        return {...item, headname: item.name, accgroupid: item.accountGroupDto.id, subtypeid: item.accountSubTypeId}
                    })

                    return {
                        ...item,
                        product: productOptions.data.find((prodItem: any) => item.productId === prodItem.id),
                        accountHead: [ ...inventoryAccount.data,[...indirectExpenseAccounts.data,...directExpenseAccounts.data].map((item: any) => {

                            return {...item, headname: item.name, accgroupid: item.accountGroupDto.id,isInventory: false, subtypeid: item.accountSubTypeId}
                        }), assetAccount.data.map((item: any) => {
                            return {...item, subtypename: 'Fixed Asset', isInventory: false, headname: item.name}
                        })].find((headItem: any) => headItem.id === item.accountHeadId)
                    }
                })],

                tableRowAdditional: [ ...journalVoucherDto.journalRows.filter((item: any) => additionalChargeAccountsId.includes(item.accountHead.id) && !additionalChargeAccountsId.includes(productSubs.map((itemHead: any) => itemHead.accountHeadId))).map((item: any) => {
                    let account = additionalChargeAccounts.find((itemHead: any) => (itemHead.id === item.accountHead.id) && !additionalChargeAccountsId.includes(productSubs.map((itemHead: any) => itemHead.accountHeadId)));
                    console.log('additional',account);

                    if(account){
                        console.log('item', {
                            ...JSON.parse(item.description.split('_')[1])
                        })
                        return {
                            ...JSON.parse(item.description.split('_')[1])
                        }
                    }else{
                       return  []
                    }

                })],
                tds: journalVoucherDto && [...journalVoucherDto?.journalRows.filter((item: any) => tdsOptions.data.find((tdsItem: any) => tdsItem.id === item.accountHead.id)).map((item: any, index: number) => {
                    // let account = tdsOptions.data.find((itemHead: any) => itemHead.id === item.accountHead.id);
                    let entries = JSON.parse(item.description)
                    return {
                        id : index+1,
                        product: entries?.product,
                        // description: item.description.split('_').slice(0,-1).join('_'),
                        taxableAmount: entries?.taxableAmount,
                        tax: entries?.tax,
                        amount: entries?.amount
                    }
                })],

            })




        }

        dispatch({type: 'SET_DATA_FETCHING', payload: false});

    }, [formatedNepEngDates, productOptions, inventoryAccount, assetAccount, paymentModeOptions, indirectExpenseAccounts, directExpenseAccounts, tdsOptions])


    const convert = (transactionDate: any) => {
        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];
        // @ts-ignore
        let tempJson = {
            transactionYearAd: adDate[0],
            transactionDateAd: adDate[2],
            transactionMonthAd: adDate[1],
            transactionDateBs: bsDate[2],
            transactionMonthBs: bsDate[1],
            transactionYearBs: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })
    }

    useEffect(() => {
        if(Props.id) return;
        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])
    }, [formatedNepEngDates])

    const [openDialogProduct, setOpenDialogProduct] = useState(false)
    const [openDialogHead, setOpenDialogHead] = useState(false)

    const [formState, setFormState] = useState(initialState);
    const styles = useStyles();
    const validationSchema = Yup.object({
        vendor: Yup.object().when("paymentStatus", {
            is: 'Full',
            then: Yup.object().nullable(true),
            otherwise: Yup.object().required("Required").nullable(true)
        }).nullable(true),
        paymentMode: Yup.object().when("paymentStatus", {
            is: 'Unpaid',
            then: Yup.object().nullable(true),
            otherwise: Yup.object().required("Required").nullable(true)
        }).nullable(true),
        amountPaid: Yup.number().when("paymentStatus", {
            is: 'Unpaid',
            then: Yup.number().max(0, "Must be 0"),
            otherwise: Yup.number().min(1, "Must be greater than 0").required("Required")
        }),
        paymentStatus: Yup.string().required('Required').nullable(true),
        // purchaseOrder: Yup.object().required('Required').nullable(true)
    })


    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            if(values.tableRow.filter((item: any) => (item.product ) && (item.accountHead)).length === 0){
                handleResponse(`Select at least one product with account.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRow.filter((item: any) => (item.product && item.accountHead) && !(item.quantity && item.price && item.amount)).length > 0) {
                handleResponse(`Some row doesn't have Product, Quantity, Price and Amount.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRowAdditional.filter((item: any) => (item.product) && !(item.price && item.amount)).length > 0){
                handleResponse(`Selected additional charge has no Price or amount.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }


            let vendorAccount : any = {}

            if(values.paymentStatus !== 'Full'){
                let {data: tempAcc} = await axiosInstance.get(`/getAccountHeadOfVendorById/${values?.vendor?.id}`)
                vendorAccount = tempAcc;
            }

            let entryJournal: object[] = [];
            let taxAccount = headOptionsInCategory.data.filter((item: any) => item.code === ACCOUNT_CODE_MAPPER.purchaseVatAccountCode)[0];

            // console.log(item.code, )
            let exemptedAmount = 0.00;

            values.tableRowAdditional.filter((item: any) => item.product && item.amount).forEach((item: any) => {

                let taxAmount = 0;
                let amount = parseFloat(item.amount) ;
                if(item.tax > 0) {
                    taxAmount = getTaxAmount(parseFloat(item.amount), parseFloat(item.tax));

                    amount -= taxAmount
                    entryJournal.push({
                        accountHead: {id: taxAccount.id},
                        amount: taxAmount,
                        name: 'additional tax',
                        tax: item.tax,
                        accountGroupId: taxAccount.accgroupid,
                        type: 'debit',
                        rate: '',
                        description: item.description,
                        subTypeId: taxAccount.subtypeid
                    })
                } else {
                    exemptedAmount += amount;
                }

                entryJournal.push({
                    accountHead: {id: item.product.id},
                    amount: amount,
                    tax: item.tax,
                    name: 'additional account',
                    accountGroupId: item.product.accountGroupDto.id,
                    type: 'debit',
                    rate: '',
                    description: item.description + '_' + JSON.stringify(item),
                    subTypeId: item.product.accountGroupDto.accountSubType.id
                })
            })

             values.tableRow.filter((item: any) => item.product && item.accountHead && item.amount && item.accountHead).map((item: any) => {

                let account =  item.accountHead
                let amount = item.amount;
                let taxAmount = 0;
                if(item.tax > 0) {

                    taxAmount = getTaxAmount(item.amount, parseFloat(item.tax));
                    amount  -= taxAmount

                    entryJournal.push({
                        accountHead: {id: taxAccount.id},
                        amount: taxAmount,
                        tax: item.tax,
                        name: 'row tax',
                        accountGroupId: taxAccount.accgroupid,
                        type: 'debit',
                        rate: '',
                        description: item.description,
                        subTypeId: taxAccount.subtypeid
                    })

                }else{
                    exemptedAmount += amount;
                }

                entryJournal.push({
                    accountHead: {id: account.id},
                    amount: amount,
                    tax: item.tax,
                    name: 'row account',
                    type: 'debit',
                    accountGroupId: account.accgroupid,
                    rate: '',
                    description: item.description,
                    subTypeId: account.subtypeid
                })
            })

            let sumProduct = values.tableRow.filter((item: any) => item.product && item.accountHead && item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            let sumAdditional = values.tableRowAdditional.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            let sumTds = values.tds.filter((item: any) => item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            let vendorRow: any = [];
            let bankRow: any = [];



            let tdsTaxableAmount = 0.00, tdsTaxAmount = 0.00, tdsTotalAmount = 0.00;


            values.tds.filter((item: any) => item.product && item.amount).map((item: any) => {
                let account =  item.product;
                let amount = item.amount;
                tdsTotalAmount += amount;
                tdsTaxableAmount += parseFloat(item.taxableAmount);

                entryJournal.push({
                    accountHead: {id: account.id},
                    amount: parseFloat(item.amount),
                    tax: item.tax,
                    type: 'credit',
                    name: 'tds',
                    accountGroupId: account.accgroupid,
                    rate: '',
                    description: JSON.stringify(item),
                    subTypeId: account.subtypeid
                })

            })

            if(values.paymentStatus === 'Full' || values.paymentStatus === 'Semi'){
                bankRow = [{
                    accountHead: {id: values.paymentMode.id},
                    amount: parseFloat(values.amountPaid),
                    tax: '',
                    type: 'credit',
                    name: 'bank',
                    accountGroupId: values.paymentMode.accgroupid,
                    rate: '',
                    description: values.chequeNumber,
                    subTypeId: values.paymentMode.subtypeid
                }]
            }
            if(values.paymentStatus === 'Semi' || values.paymentStatus === 'Unpaid'){
                vendorRow = [{
                    accountHead: {id: vendorAccount.t.id},
                    amount: sumAdditional + sumProduct - sumTds - parseFloat(values.amountPaid || 0),
                    tax: '',
                    type: 'credit',
                    name: 'vendor',
                    accountGroupId: vendorAccount.t.accgroupid,
                    rate: '',
                    description: '',
                    subTypeId: vendorAccount.t.subtypeid
                }]
            }

            let bankAccount = values.paymentStatus === 'Semi' ? [...vendorRow, ...bankRow] : values.paymentStatus === 'Full' ? [...bankRow] : [...vendorRow];

            const {amount, discount, taxableAmount, taxAmount, totalAmount} = getInvoiceAmounts([...values.tableRow, ...values.tableRowAdditional]);

            let voucherEditInfo = {};
            if(Props.mode === 'edit'){
                //@ts-ignore
                let {voucherNumber, id} = location.state.journalVoucherDto
                voucherEditInfo = {
                    voucherNumber: voucherNumber,
                    id: id
                }
            }

            let payload = {
                billDate: values.transactionYearAd + '-' + ('0' + values.transactionMonthAd).slice(-2) + '-' + ('0' + values.transactionDateAd).slice(-2),
                paymentStatus: values.paymentStatus,
                paymentMode: values.paymentMode ? {id: values.paymentMode.id} : null,
                chequeNumber:values.chequeNumber,
                vendorDto: values.vendor ? {id: values.vendor.id}: null,
                billNumber: values.code,
                amount: amount,
                discount: discount,
                taxableAmount: taxableAmount,
                taxAmount: taxAmount,
                totalAmount: totalAmount,
                exemptedAmount: exemptedAmount,
                referenceBillNumber: values.referenceBillNumber,
                description: values.description,
                amountPaid: values.amountPaid,
                productSubs: values.tableRow.filter((item: any) => item.product && item.accountHead && item.quantity && item.amount).map((item: any, index: number) => {

                    let editId= {};

                    //add id of each index of productSubs to each product sub in the new list of product that need to be sent to the server
                    if(Props.mode === 'edit'){
                        // @ts-ignore
                        if(location.state.productSubs.length >= index + 1 ){
                            // @ts-ignore
                            editId = {id: location.state.productSubs[index].id}
                        }
                    }

                    return {
                        ...editId,
                        productId: item.product.id,
                        accountHeadId: item.accountHead.id,
                        name: item.product.name,
                        quantity: item.quantity,
                        uom: item.uom,
                        price: item.price,
                        discount: item.discount,
                        amount: item.amount,
                        expiryDate: item.expiryDate,
                        tax: item.tax
                    }
                }),

                journalVoucherDto: {
                    ...voucherEditInfo,
                    type: 'journal',
                    receiptNumber: '',
                    journalRows: [
                        ...entryJournal,
                        ...bankAccount
                    ]
                }
            }

            console.log([
                ...entryJournal,
                ...bankAccount
            ])
            // throw new Error('asdf');

            if(Props.id) {

                // console.log({...payload,id: parseInt(Props.id)})
                // throw new Error('asd');

                const {data} = await axiosInstance.put('/updatePurchaseBill', {...payload,id: parseInt(Props.id)});
                responseProcess(data, formActions, isReset)

            } else {
                const {data} = await axiosInstance.post('/addPurchaseBill', payload);
                responseProcess(data, formActions, isReset)
            }

        } catch (e) {
            console.log(e)
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }

        formActions.setSubmitting(false)
    }

    const history = useHistory();
    const responseProcess = (data: any, formActions: any, isReset: boolean) => {
        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) history.replace('/dashboard/purchase/purchase-bill')
            trigger('/getCode?name=purchaseBill&id=0')
        } else {
            handleResponse(data.message, 'warning')
        }
    }

    const dispatch = useDispatch();
    const handleResponse = (message: any, type: string) => {
        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })
    }

    const handleRemove = (index: number, formikInstance: any, sectionName: string) => {
        const {values} = formikInstance;
        let tempTable = [...values[sectionName]].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue(sectionName, [...tempTable])
    }


    const addNew = useCallback((formikInstance: any, sectionName: string) => {

        const {values} = formikInstance;
        let rows = values[sectionName]
        let tempArr = {};

        if(sectionName === 'tableRow'){

            tempArr = {
                id: rows[rows.length - 1].id + 1,
                product: null,
                quantity: 0,
                uom: null,
                price: 0,
                discount: 0,
                tax: 0,
                expiryDate: '',
                description: '',
                amount: 0
            }

        }else if(sectionName === 'tableRowAdditional'){

            tempArr = {
                id: rows.length > 1 ? rows[rows.length - 1].id + 1: 1,
                product: null, price: 0, tax: 0, description: '', amount: 0
            }

        }

        values[sectionName].push(tempArr)
        formikInstance.setFieldValue(sectionName, [...values[sectionName]])

    }, [formState])


    const componentRef = useRef(null);
    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@page { size: auto; margin: 00mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 130px 40px 100px 40px !important;  } }'

    });

    const printClicked = async () => {

        if (handlePrint) {
            handlePrint()
        }
    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>

            <DialogComponent open={openDialogProduct} handleClose={openDialogProduct} title={'Add Product'}
                             maxWidth={'md'}>
                <ProductItemForm mode={'edit'} from={'journal page'} handleClose={() => setOpenDialogProduct(false)}
                                 noFixed={true}
                />
            </DialogComponent>

            <DialogComponent open={openDialogHead} handleClose={openDialogHead} title={'Add Account Head'}
                             maxWidth={'sm'}>
                <AccountHeadForm mode={'edit'} from={'journal page'} handleClose={() => setOpenDialogHead(false)}
                                 noFixed={true}
                />
            </DialogComponent>

            <Formik

                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {


                    return (
                        <div>
                            <PagePrompt formik={formik}/>

                            <Form style={{margin: 0}} onKeyDown={onKeyDown}>

                                <div ref={componentRef} >

                                <Grid container justify={"space-between"}>

                                    {Props.mode === 'view' ?
                                        (
                                            formik.values.tableRow.length > 0 &&
                                            <PurchaseBillPrintView formik={formik}/>
                                        ):
                                        (
                                            <>


                                    <Grid container item sm={6} alignItems={'center'}
                                          spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="code"
                                                   className={styles.label}> Bill Entry No.:</label>
                                        </Grid>

                                        <Grid item xs={8} className={styles.label}>
                                            {formik.values.code}
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="vendor"
                                                   className={styles.label}> Vendor: {formik.values.paymentStatus !== 'Full' && <RequiredNotation/>}</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'vendor'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`vendor`} {...field}
                                                                                   size={"small"}

                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`vendor`, value)
                                                                                   }}

                                                                                   options={vendorsData ? vendorsData.data : []}
                                                                                   loading={!vendorsData}

                                                                                   getOptionLabel={option => option.name}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="referenceBillNumber" className={styles.label}> Reference
                                                Bill No.:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'referenceBillNumber'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'referenceBillNumber'} {...field}
                                                                                size={"small"}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="fromDate"
                                                   className={styles.label}
                                            >
                                                Bill Date:<RequiredNotation/>
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <DateComponent
                                                ad={['transactionYearAd', 'transactionMonthAd', 'transactionDateAd']}
                                                bs={['transactionYearBs', 'transactionMonthBs', 'transactionDateBs']}
                                            />

                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="paymentStatus"
                                                   className={styles.label}> Payment Status:<RequiredNotation/> </label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'paymentStatus'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`paymentStatus`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`paymentStatus`, value)
                                                                                   }}
                                                                                   options={['Full', 'Semi', 'Unpaid']}
                                                                                   getOptionLabel={option => option}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="paymentMode"
                                                   className={styles.label}> Payment Mode:{formik.values.paymentStatus !== 'Unpaid' && <RequiredNotation/>}
                                            </label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'paymentMode'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`paymentMode`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`paymentMode`, value)
                                                                                   }}
                                                                                   options={paymentModeOptions ? paymentModeOptions.data : []}
                                                                                   loading={!paymentModeOptions}
                                                                                   groupBy={option => option.subtypename}
                                                                                   PopperComponent={PopperMy}
                                                                                   getOptionLabel={option => option.headname}
                                                                                   renderOption={(option) => {
                                                                                       return (
                                                                                           <div>
                                                                                               <div style={{
                                                                                                   fontSize: 11,
                                                                                               }}>
                                                                                                   <>{option.code}</>
                                                                                               </div>
                                                                                               <div>{option.headname}</div>
                                                                                           </div>
                                                                                       )
                                                                                   }}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="chequeNumber" className={styles.label}> Cheque
                                                Number:</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'chequeNumber'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'chequeNumber'} {...field}
                                                                                size={"small"}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="amountPaid" className={styles.label}> Amount Paid:{formik.values.paymentStatus !== 'Unpaid' && <RequiredNotation/>}</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'amountPaid'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'number'}
                                                                                id={'amountPaid'} {...field}
                                                                                size={"small"}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="purchaseOrder"
                                                   className={styles.label}> Purchase Order: </label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'purchaseOrder'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`purchaseOrder`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`purchaseOrder`, value)
                                                                                   }}

                                                                                   options={purchaseOrderOptions ? purchaseOrderOptions.data : []}
                                                                                   loading={!purchaseOrderOptions}

                                                                                   getOptionLabel={option => option.order_number}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                            </>)}
                                </Grid>

                                <div>
                                    <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>
                                        <u>Products</u>
                                    </div>
                                    <TableForm handleRemove={handleRemove} addNew={addNew}
                                               setOpenDialog={setOpenDialogProduct}
                                               mode={Props.mode}
                                               formikInstance={formik}/>
                                </div>

                                <div>
                                    {!(Props.mode === 'view' && formik.values.tableRowAdditional.length === 0) && (
                                        <div>
                                            <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>
                                                <u>Additional Charges</u>
                                            </div>
                                            <TableFormAdditional handleRemove={handleRemove} addNew={addNew}
                                                                 setOpenDialog={setOpenDialogHead}
                                                                 mode={Props.mode}
                                                                 formikInstance={formik}/>
                                        </div>
                                    )}
                                </div>

                                {/*<div>*/}

                                {/*    <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>*/}
                                {/*        <u>TDS</u>*/}
                                {/*    </div>*/}
                                {/*    <TdsForm handleRemove={handleRemove} addNew={addNew}*/}
                                {/*                         setOpenDialog={setOpenDialogHead}*/}
                                {/*                         formikInstance={formik}/>*/}
                                {/*</div>*/}

                                {!(Props.mode === 'view' && formik.values.tds.length === 0) && (
                                    <div>

                                        <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>
                                            <u>TDS</u>
                                        </div>
                                        <TdsForm handleRemove={handleRemove} addNew={addNew}
                                                 setOpenDialog={setOpenDialogHead}
                                                 mode={Props.mode}
                                                 formikInstance={formik}/>
                                    </div>
                                )}

                                <div>
                                    <Grid container item xs={12} sm={6} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="description" className={styles.label}> Description:</label>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'description'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;


                                                            return (
                                                                <>
                                                                {Props.mode === 'view' ? (
                                                                    <div className={styles.labelValue} >
                                                                        {field?.value || 0}
                                                                    </div>
                                                                ):(
                                                                    <>
                                                                    <InputField type={'text'}
                                                                                id={'description'} {...field}
                                                                                size={"small"}
                                                                                multiline={true}
                                                                                rows={3}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                    </>
                                                                    )}
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>
                                </div>
                                </div>
                                {Props.mode !== 'view' ?
                                    <div style={{marginTop: 50}}>
                                        <FormSaveButton mode={Props.mode} from={'product-bundle'}
                                                        submitting={formik.isSubmitting}/>
                                    </div>
                                    :
                                    <div>
                                        <ReportActionButton approveData={location.state} path={'Purchase Bill Entry'} handlePrint={printClicked} handleDownloadAsPdf={printClicked}/>
                                    </div>

                                }

                            </Form>
                        </div>
                    )
                }}

            </Formik>

        </div>
    );
};


const TableForm: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance, mode}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRow}} = useFormikContext();
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');
    const {data: productOptions} = useSWR('/getProducts');
    const {data: uomOptions} = useSWR('/getAllUoms');



    const {data: indirectExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectExpenseAccount}`);
    const {data: directExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.directExpenseAccount}`);
    const {data: inventoryAccount} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.inventoryAccount}`);
    const {data: assetAccount} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.assetAccount}`);

    useEffect(() => {
        console.log("indirect expense", indirectExpenseAccounts)
    }, [indirectExpenseAccounts])

    const name = 'tableRow'
    const formik = useFormikContext();
    const style= useStyles();
    const isView = mode === 'view';

    const [errorDialog, setErrorDialog] = useState(false);

    const getDebit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit))
        }, 0.00)
    }, [tableRow])


    const actualAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity))
        }, 0.00)
    }, [tableRow])

    const discountAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity) - parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])

    const bundlePrice = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])


    // let sum = values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
    //     return (result + parseFloat(item.amount))
    // }, 0.00)

    const getCredit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRow])


    const data = useMemo(() => tableRow, [tableRow, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {

        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.down('xs'));

        return (<Popper {...props} style={{width: matches ? 250:500}} placement='bottom-start'/>)
    }


    // {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},

    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },


        {
            id: 'productItem',
            width: isView ? undefined:250,
            Header: 'Product Item',
            accessor: "productItem",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].product`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                // const [options, setOptions] = useState<any>([]);
                                //
                                // let accountHead = original.accountHead
                                //
                                // const {data: productFromAccount} = useSWR(accountHead ? `/getProductsForAccountHead?accountHeadId=${accountHead.id}&type=${accountHead.isInventory ? 'inventory' : 'asset'}` : null)
                                //
                                // useEffect(() => {
                                //
                                //     if(!productFromAccount) return;
                                //     setOptions(productFromAccount.data)
                                //
                                // }, [productFromAccount])



                                const getOptions = (options: any) => {
                                    let tempRows = form.values.tableRow;

                                    //todo do this section again
                                    let selectedProduct = tempRows.filter((item: any) => item.product).map((item: any) => item.product?.id);
                                    if (field.value?.id) selectedProduct.splice(selectedProduct.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedProduct.includes(item.id)) : [];

                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>

                                                {isView ? (
                                                    <div className={style.labelValue}>
                                                        {field?.value?.name || ''}
                                                    </div>
                                                ):(

                                                    <AutoCompleteInput type={'text'}
                                                                       id={`${name}[${index}].product`} {...field}
                                                                       size={"small"}
                                                                       placeholder={'Select Product'}
                                                                       filterOptions={(options, {inputValue}) => {
                                                                           //todo do this again
                                                                           return matchSorter(options, inputValue, {keys: ['name', 'code']});
                                                                       }}
                                                                       onChange={(e, value) => {

                                                                           // inventory_account_id
                                                                           // sales_account_id
                                                                           // asset_account_id

                                                                           if(!value) {
                                                                               form.setFieldValue(`${name}[${index}].price`, value ? value.buying_price : 0)
                                                                               form.setFieldValue(`${name}[${index}].product`, value)
                                                                               form.setFieldValue(`${name}[${index}].accountHead`, null)
                                                                               form.setFieldValue(`${name}[${index}].amount`, value)
                                                                               return;
                                                                           }

                                                                           console.log(value);

                                                                           if(value.inventory_account_id || value.expense_account_id || value.asset_account_id){

                                                                               form.setFieldValue(`${name}[${index}].price`, value ? value.buying_price : 0)
                                                                               form.setFieldValue(`${name}[${index}].product`, value)
                                                                               form.setFieldValue(`${name}[${index}].amount`, value)

                                                                               if(value.expense_account_id){
                                                                                   console.log('in + dir',[...indirectExpenseAccounts.data,...directExpenseAccounts.data])
                                                                                   if (indirectExpenseAccounts && directExpenseAccounts) {
                                                                                       let tempAccounts = [...indirectExpenseAccounts.data,...directExpenseAccounts.data].map((item: any) => {
                                                                                           return {...item, headname: item.name, accgroupid: item.accountGroupDto.id, subtypeid: item.accountSubTypeId}
                                                                                       })
                                                                                       console.log('expense',tempAccounts.find((accountItem: any) => accountItem.id === value.expense_account_id));
                                                                                       form.setFieldValue(`${name}[${index}].accountHead`,
                                                                                           tempAccounts.find((accountItem: any) => accountItem.id === value.expense_account_id)
                                                                                       )
                                                                                   }
                                                                               }else{
                                                                                   if (inventoryAccount && assetAccount) {

                                                                                       let tempAccounts = [...inventoryAccount.data.map((item: any) => {
                                                                                           return {...item, isInventory: true}
                                                                                       }), ...assetAccount.data.map((item: any) => {
                                                                                           return {...item, subtypename: 'Fixed Asset', accgroupid: item.accountGroupDto.id, subtypeid: item.accountSubTypeId, isInventory: false, headname: item.name}
                                                                                       })]
                                                                                       console.log('inv', tempAccounts.find((accountItem: any) => accountItem.id === (value.inventory_account_id || value.asset_account_id)))
                                                                                       form.setFieldValue(`${name}[${index}].accountHead`,
                                                                                           tempAccounts.find((accountItem: any) => accountItem.id === (value.inventory_account_id || value.asset_account_id))
                                                                                       )
                                                                                   }
                                                                               }



                                                                           }else{
                                                                               setErrorDialog(true)
                                                                           }



                                                                       }}

                                                                       hideOutline={true}
                                                                       autoHighlight={true}
                                                                       autoSelect={true}
                                                                       options={productOptions ? productOptions.data: []}
                                                                       loading={!productOptions}
                                                                       variant={'standard'}
                                                                       InputProps={{
                                                                           disableUnderline: true,
                                                                           style: {paddingLeft: 5, paddingRight: 5}
                                                                       }}
                                                                       PopperComponent={PopperMy}
                                                                       getOptionLabel={option => option.name}
                                                                       renderOption={(option) => {
                                                                           return (
                                                                               <div>
                                                                                   <div style={{
                                                                                       fontSize: 11,
                                                                                   }}>
                                                                                       <>{option.code}</>
                                                                                   </div>
                                                                                   <div>{option.name}</div>
                                                                               </div>
                                                                           )
                                                                       }}
                                                                       error={meta.touched && meta.error}
                                                    />
                                                )}

                                            </Grid>

                                                {!isView && (
                                                    <ToolTip title={'Add new product'}>
                                                        <Grid item style={{
                                                            borderLeft: '1px solid #d0d0d0',
                                                            padding: '8px 3px 3px 3px',
                                                            cursor: 'pointer',

                                                        }}
                                                              onClick={() => handleDialogOpen()}
                                                        >

                                                            <div>

                                                                <AddIcon style={{
                                                                    color: COLOR_PROFILE.primaryColor,
                                                                    fontWeight: 700,
                                                                    fontSize: 20
                                                                }}/>

                                                            </div>
                                                        </Grid>
                                                    </ToolTip>
                                                    )}
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'accountHead',
            width: isView ? undefined: 200,
            Header: 'Inv/ Asset Account',
            accessor: "accountHead",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].accountHead`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>
                                                {/*<AutoCompleteInput type={'text'}*/}
                                                {/*                   id={`accountHead`} {...field}*/}
                                                {/*                   size={"small"}*/}
                                                {/*                   autoHighlight={true}*/}
                                                {/*                   placeholder={'Select Account'}*/}
                                                {/*                   autoSelect={true}*/}
                                                {/*                   onChange={(e, value) => {*/}
                                                {/*                       form.setFieldValue(`${name}[${index}].accountHead`, value)*/}
                                                {/*                   }}*/}
                                                {/*                   options={(inventoryAccount && assetAccount) ?*/}
                                                {/*                       [...inventoryAccount.data.map((item: any) => {*/}
                                                {/*                           return {...item, isInventory: true}*/}
                                                {/*                       }), ...assetAccount.data.map((item: any) => {*/}
                                                {/*                           return {...item, subtypename: 'Fixed Asset', isInventory: false, headname: item.name}*/}
                                                {/*                       })]*/}
                                                {/*                       : []}*/}
                                                {/*                   loading={!inventoryAccount && !assetAccount}*/}
                                                {/*                   groupBy={option => option.subtypename}*/}
                                                {/*                   PopperComponent={PopperMy}*/}

                                                {/*                   hideOutline={true}*/}
                                                {/*                   variant={'standard'}*/}
                                                {/*                   InputProps={{*/}
                                                {/*                       disableUnderline: true,*/}
                                                {/*                       style: {paddingLeft: 5, paddingRight: 5}*/}
                                                {/*                   }}*/}

                                                {/*                   getOptionLabel={option => option.headname}*/}
                                                {/*                   renderOption={(option) => {*/}
                                                {/*                       return (*/}
                                                {/*                           <div>*/}
                                                {/*                               <div style={{*/}
                                                {/*                                   fontSize: 11,*/}
                                                {/*                               }}>*/}
                                                {/*                                   <>{option.code}</>*/}
                                                {/*                               </div>*/}
                                                {/*                               <div>{option.headname}</div>*/}
                                                {/*                           </div>*/}
                                                {/*                       )*/}
                                                {/*                   }}*/}
                                                {/*                   error={meta.touched && meta.error}*/}
                                                {/*/>*/}

                                                <div style={{fontSize: 14, fontWeight: 700, color:COLOR_PROFILE.primaryColor, paddingLeft: 5, paddingRight: 5}}>
                                                    {field?.value?.headname || ''}
                                                </div>

                                            </Grid>

                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'quantity',
            Header: 'Quantity',
            accessor: "quantity",
            width: 30,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].quantity`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].quantity`} {...field}
                                                    size={"small"}
                                                    step="1"
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].quantity`, event.target.value)
                                                        let discountedAmount = event.target.value * original.price - (original.discount)/100 * event.target.value * original.price;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)                                                    }}

                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'uom',
            Header: 'UOM',
            width: isView ? undefined:30,
            accessor: "uom",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].uom`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value?.name || ''}
                                        </div>
                                    ):(
                                        <AutoCompleteInput type={'text'}
                                                           id={`${name}[${index}].uom`} {...field}
                                                           size={"small"}
                                                           onChange={(e, value) => {
                                                               form.setFieldValue(`${name}[${index}].uom`,value)
                                                           }}

                                                           hideOutline={true}
                                                           autoHighlight={true}
                                                           autoSelect={true}
                                                           options={uomOptions ? uomOptions.data : []}
                                                           loading={!uomOptions}
                                                           variant={'standard'}
                                                           InputProps={{
                                                               disableUnderline: true,
                                                               style: {paddingLeft: 5, paddingRight: 5}
                                                           }}
                                                           getOptionLabel={option => option.name}
                                                           renderOption={(option) => {
                                                               return (
                                                                   <div>
                                                                       <div>{option.name}</div>
                                                                   </div>
                                                               )
                                                           }}
                                                           error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'price',
            Header: 'Price',
            accessor: "price",
            // width: isView ? undefined:100,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].price`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].price`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].price`, event.target.value)
                                                        let discountedAmount = original.quantity * event.target.value - (original.discount)/100 * original.quantity * event.target.value;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
                                                    }}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'discount',
            Header: 'Discount (%)',
            width: 30,
            accessor: "discount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].discount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].discount`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].discount`, event.target.value)
                                                        let discountedAmount = original.quantity * original.price - (event.target.value)/100 * original.quantity * original.price;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + original.tax/100 * discountedAmount)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'tax',
            Header: 'VAT (%)',
            width: 60,
            accessor: "tax",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].tax`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].tax`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].tax`, event.target.value)
                                                        let discountedAmount = original.quantity * original.price - (original.discount)/100 * original.quantity * original.price;
                                                        form.setFieldValue(`${name}[${index}].amount`, (discountedAmount)  + event.target?.value/100 * discountedAmount)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'amount',
            Header: 'Amount',
            accessor: "amount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].amount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].amount`} {...field}
                                                    size={"small"}
                                                    value={original.amount}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                //console.log(cellObj)

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        //console.log('delete clicked')
        handleRemove(index, formikInstance, name)
    }

    const getBalance = () => {
        return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }


    const columns = useMemo<Column[]>(() => isView ? voucherColumn.filter((item: any, index: number) => index !== 2) : voucherColumn, [name, handleRemove, accountHeadOptions, inventoryAccount, assetAccount, setErrorDialog]);


    return (
        <div>

            <ErrorDialog
                open={errorDialog}
                handleClose={() => setErrorDialog(false)}
                message={<div>The selected item is not associated with proper account head. Purchased items must be have either inventory type or fixed asset type head selected in their Dr. Account Type. Click in this link to learn fully about it.</div>}
                title={'Error!'}
                size={'sm'}
            />

            <div style={{paddingBottom: 40}}>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} sectionName={name} balance={getBalance}
                                  total={{
                                      'actualAmount': actualAmount(),
                                      'discountAmount': discountAmount(),
                                      'bundlePrice': bundlePrice()
                                  }} type={'purchaseBill'}
                                  minLength={1}
                                  mode={mode}
                                  fromProduct={true}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )
};

const TableFormAdditional: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance, mode}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRowAdditional, tableRow, amountPaid,tds, paymentStatus}} = useFormikContext();
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');
    const {data: productOptions} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.indirectExpenseOperatingExpense}`);
    const {data: indirectExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectExpenseAccount}`);
    const {data: directExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.directExpenseAccount}`);

    // const {data: productOptions} = useSWR('/getHeadByGroup?groups=IE-601');

    const name = 'tableRowAdditional'
    const formik = useFormikContext();
    const isView = mode === 'view'
    const style = useStyles();

    useEffect(() => {
        if(paymentStatus === 'Full'){
            formik.setFieldValue('amountPaid', (tableRowSum() + tableRowAdditionalSum() -tdsSum()))
        }

    }, [paymentStatus,tableRowAdditional, tableRow])

    const getDebit = useCallback(() => {
        return tableRowAdditional.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit))
        }, 0.00)
    }, [tableRowAdditional])

    const tableRowSum = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.accountHead && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])

    const tableRowAdditionalSum = useCallback(() => {
        return tableRowAdditional.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRowAdditional])

    const tdsSum = useCallback(() => {
        return tds.filter((item: any) =>  item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tds])


    // let sum = values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
    //     return (result + parseFloat(item.amount))
    // }, 0.00)

    const getCredit = useCallback(() => {
        return tableRowAdditional.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRowAdditional])


    const data = useMemo(() => tableRowAdditional, [tableRowAdditional, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {

        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.down('xs'));

        return (<Popper {...props} style={{width: matches ? 250:500}} placement='bottom-start'/>)
    }


    // {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, description: '', amount: 0},

    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        {
            id: 'productItem',
            width: isView ? undefined: 400,
            Header: 'Name',
            accessor: "productItem",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].product`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {
                                    let tempRows = form.values.tableRowAdditional;

                                    //todo do this section again
                                    let selectedProduct = tempRows.filter((item: any) => item.product).map((item: any) => item.product?.id);
                                    if (field.value?.id) selectedProduct.splice(selectedProduct.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedProduct.includes(item.id)) : [];

                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>
                                                {isView ? (
                                                    <div className={style.labelValue}>
                                                        {field?.value?.name || ''}
                                                    </div>
                                                ):(
                                                <AutoCompleteInput type={'text'}
                                                                   id={`${name}[${index}].product`} {...field}
                                                                   size={"small"}
                                                                   placeholder={'Select Account'}
                                                                   filterOptions={(options, {inputValue}) => {
                                                                       let filteredOptions = getOptions(options)

                                                                       //todo do this again
                                                                       return matchSorter(filteredOptions, inputValue, {keys: ['headname', 'code']});
                                                                   }}
                                                                   onChange={(e, value) => {
                                                                       form.setFieldValue(`${name}[${index}].product`, value)

                                                                       // form.setFieldValue(`${name}[${index}].price`, value ? value.selling_price : 0)
                                                                       // form.setFieldValue(`${name}[${index}].amount`,  value ? value.selling_price * (1 + original.tax / 100): 0)
                                                                   }}

                                                                   hideOutline={true}
                                                                   autoHighlight={true}
                                                                   autoSelect={true}
                                                                   options={(indirectExpenseAccounts && directExpenseAccounts) ? [...indirectExpenseAccounts.data,...directExpenseAccounts.data] : []}
                                                                   loading={!indirectExpenseAccounts && !directExpenseAccounts}
                                                                   variant={'standard'}
                                                                   InputProps={{
                                                                       disableUnderline: true,
                                                                       style: {paddingLeft: 5, paddingRight: 5}
                                                                   }}
                                                                   groupBy={options => options.subtypename}
                                                                   PopperComponent={PopperMy}
                                                                   getOptionLabel={option => option.name}
                                                                   renderOption={(option) => {
                                                                       return (
                                                                           <div>
                                                                               <div style={{
                                                                                   fontSize: 11,
                                                                               }}>
                                                                                   <>{option.code}</>
                                                                               </div>
                                                                               <div>{option.name}</div>
                                                                           </div>
                                                                       )
                                                                   }}
                                                                   error={meta.touched && meta.error}
                                                />
                                                    )}
                                            </Grid>

                                            {!isView && (
                                            <ToolTip title={'Add new head'}>
                                                <Grid item style={{
                                                    borderLeft: '1px solid #d0d0d0',
                                                    padding: '8px 3px 3px 3px',
                                                    cursor: 'pointer',

                                                }}
                                                      onClick={() => handleDialogOpen()}
                                                >

                                                    <div>

                                                        <AddIcon style={{
                                                            color: COLOR_PROFILE.primaryColor,
                                                            fontWeight: 700,
                                                            fontSize: 20
                                                        }}/>

                                                    </div>
                                                </Grid>
                                            </ToolTip>
                                            )}
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },


        {
            id: 'price',
            Header: 'Price',
            accessor: "price",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].price`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].price`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].price`, event.target.value)
                                                        form.setFieldValue(`${name}[${index}].amount`, (parseFloat(event.target.value)  + (original.tax/100 * parseFloat(event.target.value))))
                                                    }}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'tax',
            Header: 'VAT (%)',
            width: 60,
            accessor: "tax",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].tax`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].tax`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].tax`, event.target.value)
                                                        form.setFieldValue(`${name}[${index}].amount`, (parseFloat(original.price)  + (parseFloat(event.target.value)/100 * parseFloat(original.price))))
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'description',
            Header: 'Description',
            accessor: "description",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].description`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value ||''}
                                        </div>
                                    ):(
                                        <InputField type={'text'} id={`${name}[${index}].description`} {...field}
                                                    size={"small"}
                                                    multiline={true}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'amount',
            Header: 'Amount',
            accessor: "amount",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].amount`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                    {isView ? (
                                        <div className={style.labelValue}>
                                            {field?.value || 0}
                                        </div>
                                    ):(
                                        <InputField type={'number'} id={`${name}[${index}].amount`} {...field}
                                                    size={"small"}
                                                    value={original.amount}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        )}
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                //console.log(cellObj)

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        handleRemove(index, formikInstance, name)
    }

    const getBalance = () => {
        return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }

    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, accountHeadOptions, productOptions, directExpenseAccounts, indirectExpenseAccounts]);


    return (
        <div>

            <div style={{paddingBottom: 40}}>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} sectionName={name} balance={getBalance}
                                  // total={{
                                  //     'totalAmount': tableRowSum() + tableRowAdditionalSum(),
                                  //     'paidAmount': parseFloat(amountPaid || 0),
                                  //     'remainingAmount': tableRowSum() + tableRowAdditionalSum() - parseFloat(amountPaid || 0)
                                  // }}
                    // type={'purchaseBillAdditional'}
                                  minLength={1}
                                  mode = {mode}
                                  fromProduct={true}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )
};

export default PurchaseBillForm;
