const initialValues = {
    accountHead: null,
    dateFrom: null,
    dateTill: null,
    branch:null,
    transactionYearAdFrom: null,
    transactionMonthAdFrom: null,
    transactionDateAdFrom: null,
    transactionYearBsFrom: null,
    transactionMonthBsFrom: null,
    transactionDateBsFrom: null,
    transactionYearAdTill: null,
    transactionMonthAdTill: null,
    transactionDateAdTill: null,
    transactionYearBsTill: null,
    transactionMonthBsTill: null,
    transactionDateBsTill: null,
}

const ReportFilterReducer = (state: any = initialValues, action: any) => {

    switch (action.type) {

        case 'ADD_REPORT_FILTER':
            state = action.payload;
            return state;

        case 'REMOVE_REPORT_FILTER' :
            return state

        default:
            return state
    }
}

export default ReportFilterReducer;
