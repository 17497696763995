
export const COLOR_PROFILE = {

    primaryColor: '#072359',
    containerBackground:'#fafafa',
    textBlack: 'black',
    buttonRed: '#F98080',
    navigationBackground: '#E8EEF2',
    buttonBlue: '#297ECC',
    tableHeader: '#C9E5FF',
    tableRowOdd: '#E8EEF2',
    tableRowEven: '#FBFDFF',
    setupStepSelectedBackground: '#163F8C',
    setupStepBackground: '#E1E1E1',
    setupNameBackground: '#F5F5F5',
    buttonGreen: '#2DB63B',
    buttonLightGreen: '#29CC97',
    setupBackground: '#F0F0F0',
    backgroundDark: '#C0C0C0',
    textGreenBlue: "rgb(37, 149, 177)",
    orange: "#f89432"
}
