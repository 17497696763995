import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import useSWR, {trigger} from "swr";
import * as Yup from "yup";
import axiosInstance from "../../../../../config/axiosConfig";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Field, Form, Formik} from "formik";
import {Grid} from "@material-ui/core";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import InputField from "../../../../shared/atom/formElements/inputField";
import ErrorLabel from "../../../molecules/errorLabel";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {
    from: string
    mode: 'edit' | 'create',
    nextPath ?: any,
    id?: string | null
}

const useStyles= makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const UomAdditionForm: React.FC<Props> = (Props) => {

    const initialValue = {
        name: ''
    }
    const styles = useStyles();

const location = useLocation();
    useEffect(() => {

        if (Props.id) {

            // @ts-ignore
            const {name} = location.state

            setFormState({
                name: name
            })
        }

    }, [])

    const [formState, setFormState] = useState(initialValue);

    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
    })

    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            let payload = {
                name: values.name,
            }

            if(Props.id){
                const {data} = await axiosInstance.put('/updateUom', {id: parseInt(Props.id), ...payload});
                responseProcess(data, formActions, isReset)
            }else{
                const {data} = await axiosInstance.post('/addUom', payload);
                responseProcess(data, formActions, isReset)
            }


        }catch (e) {

            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')

        }

    }


    const history = useHistory();
    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {

            trigger('/getAllAccountSubTypesAndGroups')
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) history.replace('/dashboard/product/uom')

        } else {

            handleResponse(data.message, 'warning');

        }
    }


    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>
            <Formik
                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {
                    return (
                        <div>
                            <PagePrompt formik={formik}/>
                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>
                                <Grid container justify={"space-between"}>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="name"
                                                   className={styles.label}> UOM Name:<RequiredNotation/></label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'name'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'text'}
                                                                            id={'name'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <FormSaveButton mode={Props.mode} from={Props.from} submitting={formik.isSubmitting}/>

                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    );
};

export default UomAdditionForm;