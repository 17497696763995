import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../../config/colorCode";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: 600,
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    },
    value: {
        fontWeight: 700,
        fontSize: 16, color:
        COLOR_PROFILE.primaryColor
    }
}))

const PurchaseReturnPrintPreview: React.FC<any> =  (Props) => {
    const {formik} = Props;
    const styles = useStyles();

    return (
        <>

            <Grid container item xs={12}>
                <div style={{margin: '0 auto', textAlign: 'center', padding: 10, marginBottom: 30}}>
                    <div style={{fontSize: 18, fontWeight: 700, color: COLOR_PROFILE.primaryColor}}>
                        Purchase Return Bill
                    </div>

                </div>
            </Grid>


            <Grid container item xs={6} spacing={2}
                  style={{marginBottom: 15}}>
                <Grid item xs={4}>
                    <label htmlFor="vendor"
                           className={styles.label}> Vendor: </label>
                </Grid>

                <Grid item xs={8} className={styles.value}>
                    <div>
                        {formik.values.vendor?.name}
                    </div>
                    <div>
                        {formik.values.vendor?.address?.city}
                    </div>
                    <div>
                        {formik.values.vendor?.phone1}
                    </div>

                </Grid>
            </Grid>

            <Grid container item xs={6} spacing={2}
                  style={{marginBottom: 10}}>
                <Grid item xs={4}>
                    <label htmlFor="fromDate"
                           className={styles.label}
                    >
                        Return Bill Number:
                    </label>
                </Grid>

                <Grid item container xs={8} className={styles.value}>

                    {formik.values.code}

                </Grid>
            </Grid>

            <Grid container item xs={6} spacing={2}
                  style={{marginBottom: 10}}>
                <Grid item xs={4}>
                    <label htmlFor="fromDate"
                           className={styles.label}
                    >
                        Bill Date:
                    </label>
                </Grid>

                <Grid item container xs={8} className={styles.value}>

                    {`${formik.values.transactionYearAd}/${formik.values.transactionMonthAd}/${formik.values.transactionDateAd}`}

                </Grid>
            </Grid>


            {/*<Grid container item xs={6} alignItems={'center'} spacing={2}*/}
            {/*      style={{marginBottom: 10}}>*/}
            {/*    <Grid item xs={4}>*/}
            {/*        <label htmlFor="fromDate"*/}
            {/*               className={styles.label}*/}
            {/*        >*/}
            {/*            Entry Date:*/}
            {/*        </label>*/}
            {/*    </Grid>*/}

            {/*    <Grid item container xs={8} alignItems={'center'} className={styles.value}>*/}

            {/*        {formik.values.entryDate?.split('-')?.join('/')}*/}

            {/*    </Grid>*/}
            {/*</Grid>*/}

        </>
    );
};

export default PurchaseReturnPrintPreview;