import React from 'react';
import PageTopBar from "../../../accounting/organisms/pageTopBar";
import DividerComponent from "../../../shared/atom/divider";
import PurchaseOrderForm from "../../organisms/forms/purchaseOrder";
import PurchaseBillForm from "../../organisms/forms/purchaseBill";
import {useLocation, useParams} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";

const PurchaseBillAddition = () => {

    // @ts-ignore
    const {id} = useParams();
    const path = useLocation();
    const isEdit = path.pathname.includes('edit');
    const isView = !isEdit && id;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));


    return (
        <div >
            <div style={{paddingLeft: matches ? 10: 0}}>
                <PageTopBar name={isEdit ? 'Edit Purchase Bill' : isView ? 'View Purchase Bill':'Add Purchase Bill'}/>
            </div>


            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>

            </div>

            <div style={{margin: matches ? '25px 0px 0px -0px': '25px 0px 10px 25px', overflow: "scroll"}}>
                <div style={{maxWidth: matches? "100vw": 1250, paddingRight: matches ? 10:20, paddingLeft: 25,paddingBottom: 80}}>
                    <div>
                        <PurchaseBillForm mode={isEdit? 'edit': isView ? 'view' :'create'} from={'purchase-bill'} id={id}/>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PurchaseBillAddition;