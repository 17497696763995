import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {useHistory, useLocation} from "react-router-dom";
import * as Yup from "yup";
import {addOrRemoveFromArray, GetEffectiveTime} from "../../../../../config/helperFunctions";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Grid, Popper} from "@material-ui/core";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import InputField from "../../../../shared/atom/formElements/inputField";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import DateComponent from "../../../../product/molecules/dateComponent";
import DaysLayout from "../../../../product/molecules/daysLayout";
import CustomTimePicker from "../../../../shared/atom/formElements/picker";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import useSWR, {trigger} from "swr";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import {matchSorter} from "match-sorter";
import ToolTip from "../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import {Column} from "react-table";
import VoucherTableForm from "../../../../accounting/organisms/Forms/voucherTableForm";
import {useDispatch} from "react-redux";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs} from "../../../../../config/dateFunctions";
import {getFormattedDate} from "../../../../../utils/dateFunction";
import ProductItemForm from "../../../../product/organisms/form/productItem";
import DialogComponent from "../../../../shared/atom/dialog";
import PurhcaseRfpProduct from "./purchaseRfpProduct";
import PurchaseRfpProduct from "./purchaseRfpProduct";
import {useReactToPrint} from "react-to-print";
import PurchaseOrderPrintView from "../purchaseOrder/purchaseOrderPrintView";
import ReportActionButton from "../../../../accounting/organisms/reportActionButton";
import PurchaseRfpPrintView from "./purchaseRfpPrintView";
import PurchaseRfpTerms from "./purchaseRfpTerms";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {
    from: string
    mode: 'edit' | 'create' | 'view',
    nextPath?: any
    id ?: any
}

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const PurchaseRfpForm: React.FC<any> = (Props) => {
    const initialState = {
        issueYearAd: null,
        issueMonthAd: null,
        issueDateAd: null,
        issueYearBs: null,
        issueMonthBs: null,
        issueDateBs: null,
        expiryYearAd: null,
        expiryMonthAd: null,
        expiryDateAd: null,
        expiryYearBs: null,
        expiryMonthBs: null,
        expiryDateBs: null,
        code: '',
        vendor: null,
        paymentMethod: null,
        description: '',
        tableRow: [
            {id: 1, product: null, quantity: 0, description: ''},
            {id: 2, product: null, quantity: 0, description: ''},
        ],

    }

    const {data: rfpCode} = useSWR('/getCode?name=RFP&id=0')

    useEffect(() => {

        if (Props.mode !== 'create') return;

        if (rfpCode) setFormState(prevState => {
            return {
                ...prevState,
                code: rfpCode.t.code
            }
        })

    }, [rfpCode])


    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();
    const {data: productOptions} = useSWR('/getProducts');

    useEffect(() => {

        dispatch({type: 'SET_DATA_FETCHING', payload: true});

        if (!formatedNepEngDates) return;
        if(!productOptions) return;
        dispatch({type: 'SET_DATA_FETCHING', payload: false});

        if (Props.id) {

            // @ts-ignore
            const {productSubs, rfpNumber, vendor, issueDate, expiryDate, paymentMethod, description} = location.state
            let adIssueDate = issueDate.split('-');
            let bsIssueDate: any = convertAdToBs(issueDate, formatedNepEngDates) || [];

            let adExpiryDate = expiryDate ?  expiryDate.split('-'): [];
            let bsExpiryDate: any = convertAdToBs(expiryDate, formatedNepEngDates) || [];

            // @ts-ignore
            let tempJson = {
                issueYearAd: adIssueDate[0],
                issueDateAd: adIssueDate[2],
                issueMonthAd: adIssueDate[1],
                issueDateBs: bsIssueDate[2],
                issueMonthBs: bsIssueDate[1],
                issueYearBs: bsIssueDate[0],

                expiryYearAd: adExpiryDate[0],
                expiryMonthAd: adExpiryDate[1],
                expiryDateAd: adExpiryDate[2],
                expiryYearBs: bsExpiryDate[0],
                expiryMonthBs: bsExpiryDate[1],
                expiryDateBs: bsExpiryDate[2],

            }

            // @ts-ignore
            setFormState({

                ...tempJson,
                code: rfpNumber,
                tableRow: [...productSubs.map((item: any) => {
                    return {
                        ...item,
                        product: productOptions.data.find((prodItem: any) => item.productId === prodItem.id)
                    }
                })],
                vendor: vendor,
                paymentMethod: paymentMethod,
                description: description

            })
        }


    }, [formatedNepEngDates, productOptions])


    const convert = (transactionDate: any) => {

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            issueYearAd: adDate[0],
            issueDateAd: adDate[2],
            issueMonthAd: adDate[1],
            issueDateBs: bsDate[2],
            issueMonthBs: bsDate[1],
            issueYearBs: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }

    useEffect(() => {

        if (Props.id) return;
        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])

    }, [formatedNepEngDates])

    const [openDialog, setOpenDialog] = useState(false)

    const location = useLocation();
    const {data: vendorOptions} = useSWR('/getVendors')
    const [formState, setFormState] = useState(initialState);

    const styles = useStyles();
    const validationSchema = Yup.object({
        vendor: Yup.object().required('Required').nullable(true),
        paymentMethod: Yup.string().required('Required').nullable(true),
    })


    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            if (values.tableRow.filter((item: any) => (item.product && item.quantity)).length === 0) {
                handleResponse(`Select at least one product to create RFP.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            // if (values.tableRow.filter((item: any) => (item.product && !item.quantity).length > 0)) {
            //     handleResponse(`Some row doesn't have Product and Quantity. Please delete the excess rows or fill the required columns.`, 'warning')
            //     formActions.setSubmitting(false)
            //     return;
            // }

            let payload = {
                productSubs: values.tableRow.filter((item: any) => item.product && item.quantity).map((item: any, index: number) => {

                    let editId= {};

                    //add id of each index of productSubs to each product sub in the new list of product that need to be sent to the server
                    if(Props.mode === 'edit'){
                        // @ts-ignore
                        if(location.state.productSubs.length >= index + 1 ){
                            // @ts-ignore
                            editId = {id: location.state.productSubs[index].id}
                        }
                    }

                    return {
                        ...editId,
                        productId: item.product.id,
                        name: item.product.name,
                        quantity: parseInt(item.quantity),
                        description: item.description
                    }
                }),
                name: '',
                vendor: {id: values.vendor.id},
                paymentMethod: values.paymentMethod,
                rfpNumber: values.code,
                termsAndCondition: '',
                issueDate: getFormattedDate(values.issueYearAd, values.issueMonthAd, values.issueDateAd),
                expiryDate: getFormattedDate(values.expiryYearAd, values.expiryMonthAd, values.expiryDateAd),
                description: values.description,
            }

            if(Props.id){
                //add id or order in the update json of purchase order ...
                const {data} = await axiosInstance.put('/updatePurchaseRfp', {...payload,id: parseInt(Props.id)});
                responseProcess(data, formActions, isReset)
            }else{
                const {data} = await axiosInstance.post('/addPurchaseRFP', payload);
                responseProcess(data, formActions, isReset)
            }

        } catch (e) {
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }

        formActions.setSubmitting(false)
    }

    const history = useHistory();
    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if (!isReset) history.replace('/dashboard/purchase/request-for-proposal')
            trigger('/getCode?name=RFP&id=0')

        } else {

            handleResponse(data.message, 'warning')

        }
    }

    const dispatch = useDispatch();
    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }


    const handleRemove = (index: number, formikInstance: any) => {

        //console.log(formikInstance);
        const {values: {tableRow}} = formikInstance;
        let tempTable = [...tableRow].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue('tableRow', [...tempTable])

    }


    const addNew = useCallback((formikInstance: any) => {

        const {values: {tableRow}} = formikInstance;
        let tempArr = {
            id: tableRow[tableRow.length - 1].id + 1,
            product: null, quantity: 0, description: ''
        }

        tableRow.push(tempArr)
        formikInstance.setFieldValue('tableRow', [...tableRow])

    }, [formState])

    const componentRef = useRef(null);
    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@page { size: auto; margin: 00mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 130px 40px 100px 40px !important;  } }'
    });

    const printClicked = async () => {
        if (handlePrint) handlePrint()
    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>

            <DialogComponent open={openDialog} handleClose={setOpenDialog} title={'Add Product'}
                             maxWidth={'md'}>
                <ProductItemForm mode={'edit'} from={'journal page'} handleClose={() => setOpenDialog(false)}
                                 noFixed={true}
                />
            </DialogComponent>

            <Formik

                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {

                    return (
                        <div>
                            <PagePrompt formik={formik}/>

                            <Form style={{ margin: Props.mode === 'view' ? '20px 20px': 20}} onKeyDown={onKeyDown}>

                                <div ref={componentRef}>

                                <Grid container justify={"space-between"}>

                                    {Props.mode === 'view' ?
                                        (
                                            formik.values.tableRow.length > 0 &&
                                            <PurchaseRfpPrintView formik={formik}/>
                                        ) :
                                        (
                                            <>

                                                <Grid container item xs={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 15}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="code"
                                                               className={styles.label}> RFP Number:</label>
                                                    </Grid>

                                                    <Grid item xs={8} className={styles.label}>
                                                        {formik.values.code}
                                                    </Grid>

                                                </Grid>

                                                <Grid container item xs={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 15}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="vendor"
                                                               className={styles.label}> Vendor: <RequiredNotation/></label>
                                                    </Grid>

                                                    <Grid item xs={8}>
                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'vendor'}>
                                                                {(props: { field: any; form: any; meta: any; }) => {
                                                                    const {field, form, meta} = props;
                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={`vendor`} {...field}
                                                                                               size={"small"}

                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue(`vendor`, value)
                                                                                               }}

                                                                                               options={vendorOptions ? vendorOptions.data : []}
                                                                                               loading={!vendorOptions}
                                                                                               getOptionLabel={option => option.name}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <ErrorLabel meta={meta}/>
                                                                        </>
                                                                    )
                                                                }}
                                                            </Field>

                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item xs={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 15}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="paymentMethod"
                                                               className={styles.label}> Payment
                                                            Method:<RequiredNotation/> </label>
                                                    </Grid>

                                                    <Grid item xs={8}>
                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'paymentMethod'}>
                                                                {(props: { field: any; form: any; meta: any; }) => {
                                                                    const {field, form, meta} = props;
                                                                    return (
                                                                        <>
                                                                            <AutoCompleteInput type={'text'}
                                                                                               id={'paymentMethod'} {...field}
                                                                                               size={"small"}
                                                                                               autoHighlight={true}
                                                                                               autoSelect={true}
                                                                                               onChange={(e, value) => {
                                                                                                   form.setFieldValue('paymentMethod', value)
                                                                                               }}
                                                                                               options={['Cash', 'Cheque']}
                                                                                               getOptionLabel={option => option}
                                                                                               error={meta.touched && meta.error}
                                                                            />
                                                                            <ErrorLabel meta={meta}/>
                                                                        </>
                                                                    )
                                                                }}
                                                            </Field>

                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item xs={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 10}}>
                                                </Grid>

                                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 10}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="fromDate"
                                                               className={styles.label}
                                                        >
                                                            Issue Date:
                                                        </label>
                                                    </Grid>

                                                    <Grid item container xs={8} alignItems={'center'}>

                                                        <DateComponent
                                                            ad={['issueYearAd', 'issueMonthAd', 'issueDateAd']}
                                                            bs={['issueYearBs', 'issueMonthBs', 'issueDateBs']}
                                                        />

                                                    </Grid>
                                                </Grid>

                                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 10}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="fromDate"
                                                               className={styles.label}
                                                        >
                                                            Expiry Date:
                                                        </label>
                                                    </Grid>

                                                    <Grid item container xs={8} alignItems={'center'}>

                                                        <DateComponent
                                                            ad={['expiryYearAd', 'expiryMonthAd', 'expiryDateAd']}
                                                            bs={['expiryYearBs', 'expiryMonthBs', 'expiryDateBs']}
                                                        />

                                                    </Grid>
                                                </Grid>

                                            </>
                                        )
                                    }


                                </Grid>

                                <div>
                                    <PurchaseRfpProduct handleRemove={handleRemove} addNew={addNew}
                                                        setOpenDialog={setOpenDialog}
                                                        mode={Props.mode}
                                                        formikInstance={formik}/>
                                </div>


                                    {Props.mode === 'view' && (
                                        <div style={{marginTop: -30}}>
                                            <PurchaseRfpTerms/>
                                        </div>
                                    )}

                                </div>

                                {Props.mode !== 'view' ?

                                    <div style={{marginTop: 50}}>
                                        <FormSaveButton mode={Props.mode} from={'purchase-rfp'}
                                                        submitting={formik.isSubmitting}/>
                                    </div>
                                    :
                                    <div>
                                        <ReportActionButton approveData={location.state} path={'Request for Proposal'} handlePrint={printClicked} handleDownloadAsPdf={printClicked}/>
                                    </div>
                                }
                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    );
};


export default PurchaseRfpForm;