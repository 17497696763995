import React from 'react';
import PurchaseBillHome from "../../templates/purchaseBillHome";

const PurchaseBill = () => {
    return (
        <div>
            <PurchaseBillHome/>
        </div>
    );
};

export default PurchaseBill;