import React, {useEffect, useState} from 'react';
import Button from "../../../shared/atom/button";
import {CloudDownload} from "@material-ui/icons";
import axiosInstance from "../../../../config/axiosConfig";
import {Divider, Grid} from "@material-ui/core";
import InvoiceTable from "../../organisms/tables/invoiceTable";
import useAdAndBsHandler from "../../../../effects/useAdAndBsHandler";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import {getFirstFiscalDate} from "../../../../config/fiscalYear";
import {convertAdToBs, getTodaysDate, getYesterDayDate} from "../../../../config/dateFunctions";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import DateComponent from "../../../product/molecules/dateComponent";
import {COLOR_PROFILE} from "../../../../config/colorCode";

const AccessLogs = () => {

    const handleLogDownload = (formState: any) => {

        let date = (formState.transactionYearAdTillLog && formState.transactionMonthAdTillLog && formState.transactionDateAdTillLog) ? (formState.transactionYearAdTillLog + '-' + ('0' + formState?.transactionMonthAdTillLog).slice(-2) + '-' + ('0' + formState.transactionDateAdTillLog).slice(-2)) : '';

        axiosInstance.get(`/getLog?date=${date}`).then((response: any) => response)
            .then((blob) => {

                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob.data], {type: 'text/plain'}),
                );

                const link = document.createElement('a');
                link.href = url;

                console.log(blob)

                link.setAttribute(
                    'download',
                    `access-log-${date}.log`,
                );

                document.body.appendChild(link);
                link.click();
                // @ts-ignore
                link.href = null;
            });
    }

    const initialState = {
        transactionYearAdFrom: null,
        transactionMonthAdFrom: null,
        transactionDateAdFrom: null,
        transactionYearBsFrom: null,
        transactionMonthBsFrom: null,
        transactionDateBsFrom: null,
        transactionYearAdTill: null,
        transactionMonthAdTill: null,
        transactionDateAdTill: null,
        transactionYearBsTill: null,
        transactionMonthBsTill: null,
        transactionDateBsTill: null,
        transactionYearAdTillLog: null,
        transactionMonthAdTillLog: null,
        transactionDateAdTillLog: null,
        transactionYearBsTillLog: null,
        transactionMonthBsTillLog: null,
        transactionDateBsTillLog: null,
    }

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState<any>(initialState);

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();
    const dashboard = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {

        // if(!location.pathname.includes('head')) return;
        // if (!localStorage.getItem('search')) return

        if(!nepEngDates) return;
        if(!formatedNepEngDates) return;

        let adDate = getFirstFiscalDate(nepEngDates.data, dashboard.fiscalYear);
        let bsDate: any = convertAdToBs(adDate.join('-'), formatedNepEngDates) || [];

        let adDateTill = getTodaysDate().split('-');
        let bsDateTill: any = convertAdToBs(getTodaysDate(), formatedNepEngDates) || [];

        let adDateTillLog = getYesterDayDate().split('-');
        let bsDateTillLog: any = convertAdToBs(getYesterDayDate(), formatedNepEngDates) || [];


        // @ts-ignore
        let tempJson = {
            transactionYearAdFrom: adDate[0],
            transactionDateAdFrom: adDate[2],
            transactionMonthAdFrom: adDate[1],
            transactionDateBsFrom: bsDate[2],
            transactionMonthBsFrom: bsDate[1],
            transactionYearBsFrom: bsDate[0],
            transactionYearAdTill: adDateTill[0],
            transactionMonthAdTill: adDateTill[1],
            transactionDateAdTill: adDateTill[2],
            transactionYearBsTill: bsDateTill[0],
            transactionMonthBsTill: bsDateTill[1],
            transactionDateBsTill: bsDateTill[2],
            transactionYearAdTillLog: adDateTillLog[0],
            transactionMonthAdTillLog: adDateTillLog[1],
            transactionDateAdTillLog: adDateTillLog[2],
            transactionYearBsTillLog: bsDateTillLog[0],
            transactionMonthBsTillLog: bsDateTillLog[1],
            transactionDateBsTillLog: bsDateTillLog[2],
            billType: "All"
        }

        setFormState((prevState: any) => {
            return {
                ...prevState,
                ...tempJson
            }
        })


    }, [nepEngDates, formatedNepEngDates])

    const onSubmit = (values: any) => {
        console.log('clicked')
        setFormState(values);
        setSubmitting(true);
        setSubmitting(false);
    }

    const validationSchema = Yup.object({})


    return (
        <div style={{margin: 20}}>

            <Grid container xs={12} justify={"center"} style={{textAlign: "center"}}>
                <Grid item xs={12} style={{padding: 10, marginLeft: -10, fontWeight: 700, fontSize: 16}}>
                    <u>Activity Log</u>
                </Grid>
                <Grid container justify={"center"} item xs={12} style={{maxWidth: 900, margin: "0 auto"}}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={formState}
                        // onSubmit={(values: any) => {}}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnMount={false}
                        validateOnBlur={true}
                        onSubmit={onSubmit}
                    >
                        {formik => {
                            return (
                                <Form style={{marginBottom: 20, paddingTop: 10, marginRight: 0}}>
                                    <Grid  container xs={12} justify={"center"} alignItems={"center"} spacing={2}>
                                        <Grid item container xs={12} justify={"flex-end"}>
                                            {/*From*/}
                                            <Grid item container alignItems={"center"} xs={2} style={{fontWeight: 700}}>
                                                Date
                                            </Grid>
                                            <Grid item container xs={10}>
                                                <DateComponent
                                                    ad={['transactionYearAdTillLog', 'transactionMonthAdTillLog', 'transactionDateAdTillLog']}
                                                    bs={['transactionYearBsTillLog', 'transactionMonthBsTillLog', 'transactionDateBsTillLog']}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} justify={"center"} style={{maxWidth: 210, margin: '0 auto', paddingTop: 20}}>
                                        <Button variant={"contained"} customColor={'#297ECC'} textColor={'white'}
                                                component="span"
                                                onClick={() => handleLogDownload(formik.values)}
                                                startIcon={<CloudDownload style={{color: "white"}}/>}
                                        >
                                            Download Log
                                        </Button>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </Grid>

            </Grid>
            <Divider style={{margin: '20px 0'}}/>
            <div style={{fontWeight: 700, textAlign: 'center', fontSize: 16, paddingRight: 16}}>
                <u>Audit Trail</u>
            </div>


            <div style={{maxWidth: 900, margin: "0 auto"}}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formState}
                    // onSubmit={(values: any) => {}}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnMount={false}
                    validateOnBlur={true}
                    onSubmit={onSubmit}
                >
                    {formik => {
                        return (
                            <Form style={{marginBottom: 20, paddingTop: 10, marginRight: 0}}>
                                <Grid  container xs={12} justify={"space-between"} alignItems={"center"} spacing={2}>
                                    <Grid item container xs={6} justify={"flex-end"}>
                                        {/*From*/}
                                        <Grid item container alignItems={"center"} xs={2} style={{fontWeight: 700}}>
                                            From
                                        </Grid>
                                        <Grid item container xs={10}>
                                            <DateComponent
                                                ad={['transactionYearAdFrom', 'transactionMonthAdFrom', 'transactionDateAdFrom']}
                                                bs={['transactionYearBsFrom', 'transactionMonthBsFrom', 'transactionDateBsFrom']}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container xs={6} justify={"flex-end"}>
                                        {/*Till*/}
                                        <Grid item xs={2} container justify={"center"} alignItems={"center"}
                                              style={{fontWeight: 700}}>
                                            Till
                                        </Grid>
                                        <Grid item container xs={10}>
                                            <DateComponent
                                                ad={['transactionYearAdTill', 'transactionMonthAdTill', 'transactionDateAdTill']}
                                                bs={['transactionYearBsTill', 'transactionMonthBsTill', 'transactionDateBsTill']}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid item container justify={"center"} xs={12}>
                                        <div style={{minWidth: 70, marginBottom: -20}}>
                                            <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                    textColor={'white'}
                                                    type={'submit'}
                                            >
                                                <span style={{fontSize: 12}}>Apply</span>
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>

                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <div style={{maxWidth: `calc(100vw - 300px)`, overflow: "auto"}}>
            <InvoiceTable formState={formState}
                          from={'trail'}
                          setSubmitting={setSubmitting} submitting={submitting}/>
            </div>
        </div>
    );
};

export default AccessLogs;
