import React, {Fragment, useEffect, useState} from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem
} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import InputField from "../../../../shared/atom/formElements/inputField";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import useSWR from "swr";
import FormSaveButton from "../../FormSaveButton";
import axiosInstance from "../../../../../config/axiosConfig";
import {useDispatch, useSelector} from "react-redux";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import RequiredNotation from "../../../molecules/requiredNotation";
import {useHistory, useLocation} from 'react-router-dom';
import {matchSorter} from "match-sorter";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PagePrompt from "../../../../shared/organisms/pagePrompt";
import {RootState} from "../../../../../store/RootReducer";

interface Props {

    from: string
    mode: 'edit' | 'create'
    nextPath?: string
    id?: any
}


const UserRoleForm: React.FC<Props> = (Props) => {


    const rolesList = [
        {id: 1, name: "Branch Admin", value: 'BRANCH_ADMIN'},
        {id: 2, name: "Checker", value: 'CHECKER'},
        {id: 3, name: "Maker", value: 'MAKER'},
    ]

    const [showPassword, setShowPassword] = useState({
        password: true,
        confirmPassword: true
    })

    const dashboard = useSelector((state: RootState) => state.dashboard);


    const {data: employeeOptions} = useSWR('/getAllEmployees');

    const location = useLocation();

    const initialValues = {
        role: dashboard.role.role === "BRANCH_ADMIN" ? "CHECKER" : 'BRANCH_ADMIN',
        employee: null,
        userName: '',
        password: '',
        confirmPassword: '',
        access: [],
        branch: null
    }

    useEffect(() => {

            if (Props.id) {
                // @ts-ignore
                const {employeeDto, email, role} = location.state;

                try {
                    let accessMenus = role.menus.map((item: any) => item.id);

                    // @ts-ignore
                    setFormState({
                        employee: employeeDto,
                        userName: email,
                        branch: null,
                        role: role.role === 'USER' ? role.makerChecker:  'BRANCH_ADMIN',
                        access: accessMenus
                    })

                } catch (e) {
                    console.log(e);
                }
            }

        }, []
    )

    const [filteredMenus, setFilteredMenus] = useState([]);
    const [menusForAdmin, setMenusForAdmin] = useState([]);

    const {data: allMenus, error: allMenusError} = useSWR('/getAllMenus')


    useEffect(() => {

        if (!allMenus) return;

        //hide these two sections entirely when company admin creates any user
        let excludeFrom =  ['Share Holders', 'Branches'];

        //for exclusion of delete access to user and role. This is used by company admin
        const excludeSomeFrom = ['User Management'];

        //branch admin cannot create user who can crud other users so

        // excludeFrom = [...excludeFrom, ...excludeSomeFrom]
        excludeFrom = [...excludeFrom]

        // @ts-ignore
        let allMenuHeader: any = [];
        const report = groupBy(allMenus, 'section')

        let sectionGrouped = Object.keys(report).map((key) => {
            let grouped = groupBy(report[key], 'name');
            console.log(grouped);

            let mapped = Object.keys(grouped).filter((item: any) => !excludeFrom.includes(item)).map((key) => {
                if (!excludeFrom.includes(key)) allMenuHeader.push(key);
                return {[key]: grouped[key]}
            })
            return {[key]: mapped}
        });


        allMenus.filter((item: any) => {

            //previous version
            // const excludeCondition = ((excludeFrom.includes(item.name) && item.privilege === 'delete') || (item.privilege === 'all operations'))

            //new version
            const excludeCondition = (excludeFrom.includes(item.name) || (excludeSomeFrom.includes(item.name)  && item.privilege === 'delete') || (item.privilege === 'all operations'))

            return !excludeCondition

        }).map((item: any) => {
            allMenuHeader.push(item.id);
            return item.id
        });

        if(!Props.id){
            setFormState((prevState: any) => {
                // @ts-ignore
                return ({
                    ...prevState,
                    access: allMenuHeader
                })
            })
        }

        // @ts-ignore
        setFilteredMenus(sectionGrouped);
        // @ts-ignore
        setMenusForAdmin(allMenuHeader);

    }, [allMenus])


    const groupBy = (xs: any, key: string) => {
        return xs.reduce(function (rv: any, x: any) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const [formState, setFormState] = useState(initialValues)

    const validationSchema = Yup.object({
        userName: Yup.string().required('Required'),
        employee: Yup.object().required('Required').nullable(true),
        password: Yup.string().required('Required'),
        confirmPassword: Yup.string()
            .test('passwords-match', 'Passwords must match', function (value) {
                return this.parent.password === value
            }),

    })

    const validationSchemaEdit = Yup.object({
        userName: Yup.string().required('Required'),
        employee: Yup.object().required('Required').nullable(true),
    })


    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            // @ts-ignore
            if ([...new Set(values.access)].length === 0) {
                handleResponse('Please select at least one responsibility.', 'warning')
                return;
            }

            // @ts-ignore
            let menus = [...new Set(values.access)].filter((item: any) => !isNaN(parseInt(item))).map((item: any) => {
                return {
                    id: parseInt(item)
                }
            })

            let payload = {
                name: values.employee.name,
                email: values.userName.toLowerCase().trim(),
                password: values.password,
                confirmPassword: values.confirmPassword,
                headBranch: false,
                employeeDto: {
                    id: values.employee.id
                },
                role: {
                    // if Branch admin send role as BRANCH_ADMIN and makerChecker as CHECKER
                    // else send role as USER and makerChecker as entered in the form i.e either CHECKER or MAKER
                    role: values.role === 'BRANCH_ADMIN' ? values.role : 'USER',
                    makerChecker: values.role === 'BRANCH_ADMIN' ? 'CHECKER' : values.role,
                    menus: menus
                }
            }


            if (Props.id) {

                // @ts-ignore
                let {id, role} = location.state;

                // @ts-ignore
                let payloadEdit = {
                    id: id,
                    email: values.userName.toLowerCase().trim(),
                    role: {
                        id: role.id,
                        role: values.role === 'BRANCH_ADMIN' ? values.role : 'USER',
                        makerChecker: values.role === 'BRANCH_ADMIN' ? 'CHECKER' : values.role,
                        menus: menus
                    }

                }

                const {data} = await axiosInstance.put('/editUser', payloadEdit)
                responseProcess(data, formActions, isReset)

            } else {

                const {data} = await axiosInstance.post(values.role === 'BRANCH_ADMIN' ? '/registerBranchAdmin' : '/registerBranchEmployee', payload)
                responseProcess(data, formActions, isReset)
            }

        } catch (e) {
            handleResponse('Something went wrong', 'warning')
        }


    }

    const history = useHistory();

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {

            handleResponse(data.message, data.message.includes('exists') ? 'warning' : 'success')
            if (!data.message.includes('exists')) formActions.resetForm();
            formActions.setSubmitting(false)
            if(!data.message.includes('exists')){
                formActions.resetForm();
                if(!isReset) history.replace('/dashboard/company-setup/user-and-roles');
            }
        } else {

            formActions.setSubmitting(false)
            handleResponse(data.message, 'warning')

        }
    }


    const dispatch = useDispatch();


    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    const styleLib = {
        label: {
            color: COLOR_PROFILE.primaryColor,
            fontWeight: 600,
            fontSize: 14
        }

    }

    const handleClick =(name: string) =>  () => {

        setShowPassword((prevState: any) => {
            return{
                ...prevState,
                [name]: !prevState[name]
            }
        })
    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>

            {menusForAdmin.length > 0 && (
                <Formik

                    initialValues={formState}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    validationSchema={Props.id ? validationSchemaEdit : validationSchema}
                    validateOnChange={false}
                    validateOnMount={false}
                    validateOnBlur={true}
                >

                    {formik => {


                        return (
                            <div>

                                <PagePrompt formik={formik}/>

                                <Form style={{margin: 20}} onKeyDown={onKeyDown}>


                                    <Grid container justify={"space-between"}>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 10}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="employee"
                                                       style={styleLib.label}> Employee:<RequiredNotation/></label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'employee'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}
                                                                                           id={'employee'} {...field}
                                                                                           size={"small"}
                                                                                           placeHolder={'Select Employee'}
                                                                                           filterOptions={(options, {inputValue}) => {
                                                                                               return matchSorter(options, inputValue, {keys: ['name', 'branch_name']});
                                                                                           }}
                                                                                           onChange={(e, value) => {
                                                                                               form.setFieldValue("employee", value)
                                                                                               if (value) form.setFieldValue('userName', value.email)
                                                                                           }}
                                                                                           groupBy={(option) => option.branch_name}
                                                                                           disabled={Props.id}
                                                                                           options={employeeOptions? employeeOptions.data: []}
                                                                                           loading={!employeeOptions}
                                                                                           getOptionLabel={option => option.name}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 10}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="userName"
                                                       style={styleLib.label}> User
                                                    Name:<RequiredNotation/></label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'userName'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'email'}
                                                                                    id={'userName'} {...field}
                                                                                    size={"small"}
                                                                                    disabled={Props.id}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        {!Props.id && (
                                            <>
                                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 10}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="password"
                                                               style={styleLib.label}> Password:<RequiredNotation/></label>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'password'}>
                                                                {
                                                                    (props: { field: any; form: any; meta: any; }) => {

                                                                        const {field, form, meta} = props;


                                                                        return (
                                                                            <>
                                                                                <InputField type={showPassword.password ? 'password': 'text'}
                                                                                            id={'password'} {...field}
                                                                                            size={"small"}
                                                                                            InputProps={{
                                                                                                endAdornment: (
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        {showPassword.password ? (
                                                                                                            <VisibilityIcon
                                                                                                                fontSize={"small"}
                                                                                                                style={{cursor: 'pointer', color: COLOR_PROFILE.primaryColor}}
                                                                                                                onClick={handleClick('password')}
                                                                                                            />
                                                                                                        ):(
                                                                                                            <VisibilityOffIcon
                                                                                                                fontSize={"small"}
                                                                                                                style={{cursor: 'pointer', color: COLOR_PROFILE.primaryColor}}
                                                                                                                onClick={handleClick('password')}
                                                                                                            />
                                                                                                        )}

                                                                                                    </InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                            error={meta.touched && meta.error}
                                                                                />
                                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                                </div>
                                                                            </>

                                                                        )
                                                                    }
                                                                }
                                                            </Field>

                                                        </div>

                                                    </Grid>
                                                </Grid>

                                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                      style={{marginBottom: 10}}>
                                                    <Grid item xs={4}>
                                                        <label htmlFor="confirmPassword"
                                                               style={styleLib.label}> Confirm
                                                            Password:<RequiredNotation/></label>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'confirmPassword'}>
                                                                {
                                                                    (props: { field: any; form: any; meta: any; }) => {

                                                                        const {field, form, meta} = props;

                                                                        return (
                                                                            <>
                                                                                <InputField type={showPassword.confirmPassword ? 'password': 'text'}
                                                                                            id={'confirmPassword'} {...field}
                                                                                            size={"small"}
                                                                                            InputProps={{
                                                                                                endAdornment: (
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        {showPassword.confirmPassword ? (
                                                                                                            <VisibilityIcon
                                                                                                                fontSize={"small"}
                                                                                                                style={{cursor: 'pointer', color: COLOR_PROFILE.primaryColor}}
                                                                                                                onClick={handleClick('confirmPassword')}
                                                                                                            />
                                                                                                        ):(
                                                                                                            <VisibilityOffIcon
                                                                                                                fontSize={"small"}
                                                                                                                style={{cursor: 'pointer', color: COLOR_PROFILE.primaryColor}}
                                                                                                                onClick={handleClick('confirmPassword')}
                                                                                                            />
                                                                                                        )}

                                                                                                    </InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                            error={meta.touched && meta.error}
                                                                                />
                                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                                </div>
                                                                            </>

                                                                        )
                                                                    }
                                                                }
                                                            </Field>

                                                        </div>

                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 10}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="role"
                                                       style={styleLib.label}> Role:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'role'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;

                                                                return (
                                                                    <>
                                                                        <InputField type={'text'} id={'role'} {...field}
                                                                                    select={true}
                                                                                    size={"small"}
                                                                                    onChange={(event: any) => {
                                                                                        if (event.target.value === 'BRANCH_ADMIN') {
                                                                                            form.setFieldValue('access', menusForAdmin)
                                                                                        } else {
                                                                                            form.setFieldValue('access', [])
                                                                                        }
                                                                                        form.setFieldValue('role', event.target.value)
                                                                                    }}
                                                                                    value={form.values.role}
                                                                                    error={meta.touched && meta.error}
                                                                        >
                                                                            {rolesList.filter((item: any) => dashboard.role.role === "BRANCH_ADMIN" ? item.value !== "BRANCH_ADMIN" : true).map((option) => (
                                                                                <MenuItem key={option.id}
                                                                                          style={{
                                                                                              fontSize: 14,
                                                                                              color: COLOR_PROFILE.primaryColor,
                                                                                              fontWeight: 600
                                                                                          }}
                                                                                          value={option.value}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            ))}

                                                                        </InputField>
                                                                        <div style={{position: "absolute"}}>
                                                                            <span style={{
                                                                                color: 'red',
                                                                                fontSize: 12,
                                                                                bottom: 0,
                                                                                left: 2
                                                                            }}>
                                                                                {(meta.touched && meta.error) ? meta.error : null}
                                                                            </span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 10}}>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 10}}>
                                        </Grid>

                                        <Grid container item xs={12}
                                              style={{
                                                  fontSize: 14,
                                                  marginBottom: 15,
                                                  fontWeight: 600,
                                                  padding: '10px 0 20px 0',
                                                  color: COLOR_PROFILE.primaryColor,
                                              }} justify={"center"}
                                        >
                                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                                <div style={{fontSize: 16}}>
                                                    Assign responsibility:
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}
                                                  style={{fontSize: 14, textAlign: 'center', fontWeight: 500}}>
                                                <div>
                                                    (Only for Checker and Maker. Admin gets full authority)
                                                </div>
                                            </Grid>

                                        </Grid>

                                        <Grid container item sm={12}
                                              justify={"space-between"}
                                              style={{marginBottom: 10}}>

                                            {filteredMenus?.map(((item: any) => {


                                                let name = Object.keys(item)[0]

                                                if (name === 'All operations') return null;


                                                return (

                                                    <Grid container item sm={6} spacing={2}
                                                          style={{marginBottom: 10}}
                                                          justify={"space-between"}
                                                    >

                                                        <Grid item xs={12}>
                                                            <FormControl>
                                                                <FormLabel
                                                                    style={{
                                                                        color: COLOR_PROFILE.primaryColor,
                                                                        fontSize: 14,
                                                                        marginBottom: 5,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    {name}
                                                                    <div style={{
                                                                        borderTop: `2px solid ${COLOR_PROFILE.primaryColor}`,
                                                                        marginTop: 5,
                                                                        width: 110
                                                                    }}>

                                                                    </div>
                                                                    {/*<span style={{fontSize: 11, fontWeight: 500}}>(only for checker and maker)</span>*/}
                                                                </FormLabel>


                                                                <Field name={'access'}>


                                                                    {(props: { field: any; form: any; meta: any; }) => {


                                                                        return (
                                                                            <>
                                                                                <div
                                                                                    // style={{margin: '0px 10px 5px 25px'}}
                                                                                >
                                                                                    <Grid container item
                                                                                          justify={"flex-end"}>
                                                                                        <Grid item xs={6}
                                                                                              style={{minWidth: 300}}>

                                                                                        </Grid>
                                                                                        <Grid item container
                                                                                              xs={6}
                                                                                              style={{color: COLOR_PROFILE.primaryColor}}
                                                                                        >

                                                                                            <Grid item
                                                                                                  xs={name === 'Report' ? 12 : 3}
                                                                                                  style={{
                                                                                                      fontSize: 12,
                                                                                                      fontWeight: 600,
                                                                                                      marginLeft: -6
                                                                                                  }}>
                                                                                                Read
                                                                                            </Grid>

                                                                                            {name !== 'Report' && (
                                                                                                <>
                                                                                                    <Grid item xs={3}
                                                                                                          style={{
                                                                                                              fontSize: 12,
                                                                                                              fontWeight: 600,
                                                                                                              marginLeft: -4,
                                                                                                          }}>
                                                                                                        Write
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}
                                                                                                          style={{
                                                                                                              fontSize: 12,
                                                                                                              fontWeight: 600,
                                                                                                              marginLeft: -5
                                                                                                          }}>
                                                                                                        Update
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}
                                                                                                          style={{
                                                                                                              fontSize: 12,
                                                                                                              fontWeight: 600,
                                                                                                              marginLeft: 2
                                                                                                          }}>
                                                                                                        Delete
                                                                                                    </Grid>

                                                                                                </>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>

                                                                                <FormGroup style={{margin: 10}}>


                                                                                    {item[name]?.filter((options: any) => ['CHECKER', 'MAKER'].includes(props.form.values.role) ? !["User Management", "Employees", "Membership"].includes(Object.keys(options)[0]): true).map((options: any, index: number) => {

                                                                                        let optionName = Object.keys(options)[0]

                                                                                        let show = false;


                                                                                        return (
                                                                                            <Fragment key={options.id}>
                                                                                                <Grid container item>
                                                                                                    <Grid item xs={6}>


                                                                                                        <FormControlLabel

                                                                                                            value={optionName}
                                                                                                            control={
                                                                                                                <>

                                                                                                                    <Checkbox
                                                                                                                        size={"small"}
                                                                                                                        // disabled={!['CHECKER', 'MAKER'].includes(props.form.values.role)}
                                                                                                                        style={{
                                                                                                                            color: props.field.value.includes(optionName) ? COLOR_PROFILE.buttonGreen : '#0A291E',
                                                                                                                            padding: 5
                                                                                                                        }}
                                                                                                                        color={'primary'}
                                                                                                                        onChange={(event: any) => {

                                                                                                                            let currentValue = props.field.value

                                                                                                                            console.log(currentValue, optionName)

                                                                                                                            if (currentValue.indexOf(optionName) === -1 && optionName === 'All Menus') {
                                                                                                                                props.form.setFieldValue('access', menusForAdmin)
                                                                                                                            } else {

                                                                                                                                if (optionName === 'All Menus') {
                                                                                                                                    props.form.setFieldValue('access', [])
                                                                                                                                    return;
                                                                                                                                }
                                                                                                                                if (currentValue.indexOf(optionName) === -1) {
                                                                                                                                    options[optionName].map((item: any) => {
                                                                                                                                        currentValue.push(item.id)
                                                                                                                                        return item
                                                                                                                                    })
                                                                                                                                    currentValue.push(optionName)
                                                                                                                                } else {

                                                                                                                                    options[optionName].map((item: any) => {
                                                                                                                                        currentValue.splice(currentValue.indexOf(item.id), 1)
                                                                                                                                        return item

                                                                                                                                    })
                                                                                                                                    currentValue.splice(currentValue.indexOf(optionName), 1)
                                                                                                                                }

                                                                                                                                // @ts-ignore
                                                                                                                                props.form.setFieldValue('access', [...new Set(currentValue)])
                                                                                                                            }


                                                                                                                        }}

                                                                                                                        checked={props.field.value.includes(optionName)}
                                                                                                                    />
                                                                                                                </>

                                                                                                            }
                                                                                                            label={<span
                                                                                                                style={{
                                                                                                                    fontSize: 12,
                                                                                                                    color: COLOR_PROFILE.primaryColor,
                                                                                                                    fontWeight: 600
                                                                                                                }}>{optionName}</span>}
                                                                                                        />


                                                                                                    </Grid>
                                                                                                    <Grid item xs={6}>
                                                                                                        <GetNestedComponent
                                                                                                            item={item}
                                                                                                            props={props}
                                                                                                            name={name}
                                                                                                            options={options}

                                                                                                        />
                                                                                                    </Grid>


                                                                                                </Grid>

                                                                                                <div style={{
                                                                                                    borderTop: `1px solid #fcfcfc`,
                                                                                                    marginLeft: -10,
                                                                                                    marginRight: 10
                                                                                                }}>

                                                                                                </div>
                                                                                            </Fragment>
                                                                                        )
                                                                                    })}

                                                                                </FormGroup>

                                                                            </>
                                                                        )

                                                                    }}
                                                                </Field>

                                                            </FormControl>
                                                        </Grid>


                                                    </Grid>

                                                )
                                            }))}

                                        </Grid>


                                    </Grid>

                                    <FormSaveButton mode={Props.mode} from={Props.from}
                                                    submitting={formik.isSubmitting}
                                    />

                                </Form>
                            </div>
                        )
                    }}
                </Formik>
            )}
        </div>
    )

}


const GetNestedComponent: React.FC<any> = ({item, name, props, options: subOptions}) => {

    let headerName = Object.keys(subOptions)[0]

    return (

        <FormGroup
            // style={{margin: '0px 10px 5px 25px'}}
        >
            <Grid container item>
                {subOptions[headerName]?.map((options: any, index: number) => {

                    let optionName = Object.keys(options)[0];

                    return (
                        <Fragment key={options.id}>

                            <Grid item xs={3} style={{textAlign: 'center'}}>


                                <FormControlLabel

                                    value={options.id}
                                    control={
                                        <Checkbox
                                            size={"small"}
                                            // disabled={!['CHECKER', 'MAKER'].includes(props.form.values.role)}
                                            style={{
                                                color: props.field.value.includes(options.id) ? COLOR_PROFILE.buttonGreen : '#0A291E',

                                                padding: 5
                                            }}

                                            color={'primary'}

                                            onChange={(event: any) => {

                                                let currentValue = props.field.value

                                                if (!props.field.value.includes(headerName)) {

                                                } else {

                                                    currentValue.splice(currentValue.indexOf(headerName), 1)

                                                }


                                                if (currentValue.indexOf(options.id) === -1) {

                                                    subOptions[headerName].map((optionsChild: any, indexChild: any) => {

                                                        if (indexChild <= index) {
                                                            currentValue.push(optionsChild.id)
                                                        }

                                                        // if ((optionsChild.id === currentValue.id) && (indexChild === subOptions[headerName].length - 1)) {
                                                        //     currentValue.push(headerName);
                                                        // }

                                                        return options

                                                    })


                                                } else {
                                                    subOptions[headerName].map((optionsChild: any, indexChild: any) => {

                                                        if (indexChild >= index) {
                                                            if (currentValue.indexOf(optionsChild.id) !== -1) currentValue.splice(currentValue.indexOf(optionsChild.id), 1);
                                                        }

                                                        return options
                                                    })

                                                    // currentValue.splice(currentValue.indexOf(options.id), 1);
                                                }


                                                // @ts-ignore
                                                props.form.setFieldValue('access', [...new Set(currentValue)])
                                            }}

                                            checked={props.field.value.includes(options.id)}
                                        />


                                    }
                                    label={
                                        <span style={{
                                            fontSize: 12,
                                            fontWeight: 600
                                        }}>
                                            {/*{options.privilege}*/}
                                        </span>
                                    }
                                />

                            </Grid>

                        </Fragment>
                    )
                })}
            </Grid>


        </FormGroup>
    )

}

export default UserRoleForm;

