import React, {useEffect, useMemo, useRef, useState} from 'react';
import useSWR from "swr";
import ReportTable from "../../../organisms/tables/reportTable";
import {Link, Route, useHistory, useParams} from "react-router-dom";
import axiosInstance from "../../../../../config/axiosConfig";
import GeneralLedgerFilterForm from "../../../organisms/Forms/generalLedger";
import {useDispatch} from "react-redux";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {useReactToPrint} from 'react-to-print';
import Grid from '@material-ui/core/Grid';
import {recursivelyUpdateTable} from "../recursiveFunction";
import ReportActionButton from "../../../organisms/reportActionButton";
import DataEmptyMessage from "../../../organisms/tables/dataEmptyMessage";
import {getTodaysDate} from "../../../../../config/dateFunctions";


const GroupSummary: React.FC = () => {


    // @ts-ignore
    const {id} = useParams();

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [branch, setBranch] = useState(null);

    const [isPrinting, setIsPrinting] = useState(false);
    const [paramId, setParamId] = useState('');

    const [formState, setFormState] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const history = useHistory();

    let balance = 0;

    useEffect(() => {

        //console.log()

    }, [])


    const dispatch = useDispatch();
    useEffect(() => {

        return () => {

            dispatch({type: "REMOVE_REPORT_FILTER"})

        }

    }, [])


    const componentRef = useRef(null);
    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const printClicked = async () => {

        await setIsPrinting(true);
        if (handlePrint) {
            handlePrint()
        }
    }

    return (

        <div>
            <div style={{maxWidth: 1000}}>

                <Grid container justify={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <div style={{
                            padding: '10px 0 0 20px',
                            fontWeight: 700,
                            fontSize: 18,
                            color: COLOR_PROFILE.primaryColor
                        }}>
                            Group Summary
                        </div>
                    </Grid>

                </Grid>

                <div>

                    <GeneralLedgerFilterForm from={'groupSummary'}
                                             setStartDate={setStartDate}
                                             setEndDate={setEndDate}
                                             setFormState={setFormState}
                                             setBranch={setBranch}
                                             submitting={submitting}
                                             setSubmitting={setSubmitting}
                    />


                </div>


                <div style={{display: 'none'}}>
                    <div ref={componentRef}>
                        <div style={{padding: 20, textAlign: 'center'}}>
                            <h2>
                                Group Summary
                            </h2>
                            <div style={{textAlign: 'left'}}>
                                From : {startDate}
                                <br/>
                                Till : {endDate}
                            </div>
                        </div>

                        {/*<Route path={'/dashboard/reports/group-summary/table/:id'} exact>*/}
                        {/*    <div>*/}
                        {/*        <GroupSummaryTable startDate={startDate} endDate={endDate} branch={branch}*/}
                        {/*                           test={'test'}/>*/}
                        {/*    </div>*/}
                        {/*</Route>*/}

                        <div style={{padding: '80px 20px 20px 20px', maxWidth: 700, margin: '0 auto'}}>
                            <Grid container alignItems={"center"} justify={"space-between"}>
                                <Grid item style={{textAlign: 'center'}}>
                                    <div style={{borderTop: `1px solid ${COLOR_PROFILE.primaryColor}`, width: 100}}>

                                    </div>
                                    <div>
                                        Printed By
                                    </div>
                                </Grid>
                                <Grid item style={{textAlign: 'center'}}>
                                    <div style={{borderTop: `1px solid ${COLOR_PROFILE.primaryColor}`, width: 100}}>

                                    </div>
                                    <div>
                                        Checked By
                                    </div>
                                </Grid>
                                <Grid item style={{textAlign: 'center'}}>
                                    <div style={{borderTop: `1px solid ${COLOR_PROFILE.primaryColor}`, width: 100}}>

                                    </div>
                                    <div>
                                        Approved By
                                    </div>
                                </Grid>
                            </Grid>


                        </div>
                    </div>
                </div>

                <Route path={'/dashboard/reports/group-summary/table/:id'} exact>
                    <div>
                        <GroupSummaryTable startDate={startDate} endDate={endDate} branch={branch}
                                           formState={formState}
                                           setSubmitting={setSubmitting}
                                           setParamId={setParamId}
                        />
                    </div>
                </Route>
            </div>

        </div>
    );
};

const GroupSummaryTable: React.FC<any> = (Props) => {

    //@ts-ignore
    const {id} = useParams();
    //console.log(id)

    const [fromDate, setFromDate] = useState(null)
    const [tillDate, setTillDate] = useState(null);
    const [branchId, setBranchId] = useState(null);

    useEffect(() => {
        Props.setParamId()
    }, [])


    useEffect(() => {
        // @ts-ignore
        setFromDate(Props.startDate);
        setTillDate(Props.endDate)
        // @ts-ignore
        setBranchId(Props.branch ? Props.branch.id : null);
        setTableData([])
        Props.setSubmitting(true)

    }, [Props.startDate, Props.endDate, Props.branch])


    const {data: getData} = useSWR((Props.startDate && Props.endDate) ? id === 'home' ?
        `/report/getAccountGroups?from=${fromDate || ''}&to=${tillDate || getTodaysDate()}&branchId=${branchId}` : id.includes('head') ?
            `/report/getBalance/?from=${fromDate || ''}&to=${tillDate|| getTodaysDate()}&accHeadId=${id.split('=')[1]}&branchId=${branchId}`
            : `/report/getAccountGroupAndBalance/?from=${fromDate|| ''}&to=${tillDate|| getTodaysDate()}&id=${id}&branchId=${branchId}`
        : null
    )


    useEffect(() => {
        if(!getData) return;
        Props.setSubmitting(false)

    }, [getData])

    let balance = 0;


    const dispatch = useDispatch();
    useEffect(() => {

        return () => {

            dispatch({type: "REMOVE_LEDGER"})

        }

    }, [])

    const branchColumns = [

        {
            id: 'id',
            Header: 'SN.',
            accessor: 'id',
            width: 10,
            Cell: (val: any) => {
                const {row} = val
                return <div>{parseInt(row.id) + 1}</div>
            }
        },
        {
            Header: 'Voucher Number',
            accessor: 'voucherNumber'
        },
        {
            Header: 'Transaction Date',
            accessor: 'transactionDate',
            Cell: (val: any) => {

                const {row: {original}} = val
                return (
                    <div>
                        {original.transactionDate.split('T')[0]}
                    </div>
                )

            }
        },
        {
            Header: 'Debit',
            accessor: 'debit',
            alignment: 'right',
            Cell: (val: any) => {

                const {row: {original: {journalRows}}} = val
                balance = balance - journalRows.amount;

                return (
                    <div style={{textAlign: 'right'}}>
                        {journalRows.type === 'debit' ? journalRows.amount : 0.00}
                    </div>
                )

            }
        },

        {
            Header: 'Credit',
            accessor: 'credit',
            alignment: 'right',

            Cell: (val: any) => {

                const {row: {original: {journalRows}}} = val
                //console.log(val.row)
                balance = balance + journalRows.amount;
                console.log('journal rows', journalRows)
                return (
                    <div style={{textAlign: 'right'}}>
                        {journalRows.type === 'credit' ? journalRows.amount : 0.00}
                    </div>
                )

            }
        },
        {
            Header: 'Balance',
            accessor: 'balance',
            alignment: 'right',

            Cell: (val: any, other: any) => {

                let balance = 0;
                const getBalance = () => {

                    try {
                        const {row: {index, allCells}} = val

                        let tempVal = [...mockData.slice(0, index + 1)];
                        balance = [...tempVal].reduce((result: number, completelyNew: any) => {
                            const newItem = completelyNew.journalRows
                            let toReturn = newItem.type === 'credit' ? (parseFloat(result.toString()) - parseFloat(newItem.amount)) : (parseFloat(result.toString()) + parseFloat(newItem.amount))
                            return toReturn.toFixed(2)
                        }, 0.00)

                    } catch (e) {

                    }

                    return Math.abs(balance) + ' ' + (balance < 0 ? 'Cr' : balance > 0 ? 'Dr' : '');
                }


                return (
                    <div style={{textAlign: 'right'}}>
                        {getBalance()}
                    </div>
                )

            }
        },
    ]


    const getFormattedHead = () => {
        let anotherTemp = getData.data.map((x: any) => {
            return {
                ...x,
                journalRows: x.journalRows.filter((item: any) => item.accountHead.id === parseInt(id.split('=')[1]))[0]
            }
        })

        return anotherTemp
    }


    const isHead = (item: {}) => {
        return Object.keys(item)
    }


    const tempColumns = [

        {
            id: 'id',
            Header: 'SN.',
            accessor: 'id',
            width: 10,
            Cell: (item: any) => {

                console.log(item)

                const {row} = item;
                let id = row.id.split('.').map((item: string) => parseInt(item) + 1);

                return (
                    <span style={{color: COLOR_PROFILE.primaryColor}}>{id.join('.')}</span>
                )
            }
        },
        {
            Header: 'Particulars',
            id: 'expander',
            Cell: (item: any) => {

                const {row, isLoading, isExpanded} = item;


                return (

                                <span >{row.original.name}</span>

                )
            },
        },

        {
            Header: 'Debit (Dr)',
            accessor: 'debit',
            width: 10,
            Cell: (val: any) => {

                const {row: {original}} = val
                return (
                    <div>
                        {original.balance ? original.balance <= 0 && Math.abs(original.balance.toFixed(2)) : null}
                    </div>
                )

            }

        },

        {
            Header: 'Credit (Cr)',
            accessor: 'credit',
            width: 10,
            Cell: (val: any) => {

                const {row: {original}} = val
                return (
                    <div>
                        {original.balance ? original.balance >= 0 && Math.abs(original.balance.toFixed(2)) : null}
                    </div>
                )

            }

        },
    ]


    const calledData =
        getData ? id === 'home'
            ? getData.data :
            id.includes('head') ? getFormattedHead() : getData.t.child.concat(getData.data) : [];

    const [isRowLoading, setIsRowLoading] = useState({});
    const [tableData, setTableData] = useState([]);
    const mockData = useMemo(() => calledData, [getData]);
    const getColumn = id.includes('head') ? branchColumns : tempColumns;
    const columns = useMemo(() => getColumn, [id, tableData, Props.formState]);


    useEffect(() => {
        if (!getData) return;
        if (id.includes('head')) {
            setTableData(getFormattedHead());
        } else {
            setTableData(getData.data);

        }


    }, [getData])

    const handleClickRow = async (item: any) => {
        const {id, original} = item;
        setIsRowLoading({[id]: true});


        try {
            const {data: subData} = await fetchChildData(original.id);

            let childData = subData.t.child.concat(subData.data)
            setIsRowLoading({[id]: false})

            if (tableData) {
                // const updatedTableData = recursivelyUpdateTable({tableData, childData, id});
                // @ts-ignore
                const updatedTableData = recursivelyUpdateTable({tableData, childData, id});
                console.log('updated table data', updatedTableData);
                setTableData(updatedTableData);
            }
        } catch (e) {
            setIsRowLoading({[id]: false})
        }

    }

    const fetchParentData = () => axiosInstance.get(`/report/getAccountGroups?from=${fromDate}&to=${tillDate}&branchId=${branchId}`);
    const fetchChildData = (id: number) => axiosInstance.get(`/report/getAccountGroupAndBalance/?from=${fromDate}&to=${tillDate}&id=${id}&branchId=${branchId}`);


    return (
        <div>


            <div>

                {getData && (

                    tableData?.length > 0 ? (
                        <div style={{paddingBottom: 50}}>

                            <ReportTable columns={columns} data={tableData}
                                         isRowLoading={isRowLoading}

                                // renderRowSubComponent={renderRowSubComponent}
                            />
                            <div>
                                <ReportActionButton/>
                            </div>
                        </div>
                    ): (
                        <div style={{paddingLeft: 17}}>
                            <DataEmptyMessage/>
                        </div>
                    )


                )}


            </div>

        </div>
    );
}


export default GroupSummary;
