import React, {useEffect} from 'react';
import Button from "../../../shared/atom/button";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";

interface Props {
    message: string,
    type: 'info' | 'warning' | 'success'
}

const SnackBar: React.FC<Props> = ({message, type}) => {


    const color = {
        info: {
            background: 'gray',
            color: 'black'
        },
        warning: {
            background: '#db7272',
            color: 'white'
        },
        success: {
            background: '#379b3a',
            color: "white"
        }
    }

    const dispatch = useDispatch();

    useEffect(() => {

        setTimeout(() => {

            handleClick();

        }, 3000)

    }, [message])

    const handleClick = () => {

        dispatch({type: 'CLOSE_SNACKBAR', payload: {message: '', variant: "info"}})

    }

    return (
        <div>
            <div style={{position: 'relative'}}>
                <div style={{
                    position: 'absolute',
                    top: 10,
                    left: 0,
                    right: 0,
                    fontSize: 14,
                    zIndex: 1000,
                    background: color[type].background,

                    color: color[type].color,
                    padding: 10,
                    width: '35%',
                    margin: '0 auto',
                    boxShadow: `0px 0 15px 0px ${color[type].background}`
                }}>
                    <div style={{position: 'relative'}}>

                        <div style={{marginBottom: 20}}>
                            {message}
                        </div>

                        <Grid container justify={'flex-end'}>
                            <div style={{width: 80}}>
                                <Button customColor={'#b7b7b7'} variant={"contained"} textColor={'white'} size={"small"}
                                        onClick={handleClick}>
                                    <strong>Done</strong>
                                </Button>
                            </div>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SnackBar;
