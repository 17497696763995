import React from 'react';
import {Button as MButton, ButtonProps} from "@material-ui/core";

interface buttonTypes {

}

interface Props extends ButtonProps {
    customColor: string,
    textColor: string,
    component?: any,
    type?: "button" | "submit" | "reset" | undefined
}

const Button: React.FC<Props> = (Props) => {


    return (

        <MButton
            style={{
                ...Props.style,
                borderRadius: 5,
                background: Props.variant === 'contained' ? Props.customColor : '',
                textTransform: 'none',
                minWidth: '100%',
                boxShadow: `0px 1px 6px 0px ${Props.customColor}`,
                color: Props.textColor
            }}
            disabled={Props.disabled}
            component={Props.component}
            size={Props.size}
            disableElevation={false}
            onClick={Props.onClick}
            startIcon={Props.startIcon}
            type={Props.type}
            variant={Props.variant}
        >
            {Props.children}
        </MButton>


    );
};

Button.defaultProps = {
    children: '',
    variant: 'outlined',
    size: 'medium',
}


export default Button;
