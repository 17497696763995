import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import Button from "../../../shared/atom/button";
import {CloudUpload} from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import axiosInstance from "../../../../config/axiosConfig";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import {useDispatch} from "react-redux";
import CircularSpinner from "../../../shared/atom/spinner";

const SetupFileUpload: React.FC<any> = (Props: any) => {

    const [submitting, setSubmitting] = useState(false);


    const handleClick = (event: any) => {
        setSubmitting(true)
        event.preventDefault();
        const path =  Props.downloadPath  || '/download/branches' ;
        // const path =  Props.downloadPath  || '/downloadProductSample' ;

        axiosInstance.get(path, {responseType: 'blob'}).then((response: any) => response)
            .then((blob) => {

                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'}),
                );

                const link = document.createElement('a');
                link.href = url;

                console.log(blob)

                link.setAttribute(
                    'download',
                    Props.fileName || `user.xlsx`,
                );

                document.body.appendChild(link);
                link.click();

                setSubmitting(false)

            });


    }

    const handleChange = async (event: any) => {

        const selectedFile = event.target.files[0];
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        );

        // const path =  '/upload/shareHolders?branchCode=null';
        const path =  Props.uploadPath || '/upload/branches?branchCode=null';

        setSubmitting(true)
        let {data} = await axiosInstance.post(path, formData)

        if (data.code === 'OK') {
            handleResponse('Uploaded successfully', 'success')
        }else{
            handleResponse('Something went wrong', 'warning')
        }

        setSubmitting(false)

        event.target.value = "";

    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {
        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })
    }


    return (
        <div style={{color: COLOR_PROFILE.primaryColor, marginRight: 40}}>
            <Grid container spacing={2} alignItems={'flex-start'} justify={Props.centered? "center": "flex-start"} style={{margin: '3px 10px 3px 10px'}}>
                <Grid item xs={4} container alignItems={'center'} spacing={2} style={{marginTop: -5}}>
                    <Grid item xs>
                        <a href='/' onClick={handleClick}><u style={{fontWeight: "bold", color: COLOR_PROFILE.primaryColor}}>
                            {Props.downloadText ? Props.downloadText : "Download sample file format"}</u></a>
                    </Grid>
                    {/*<Grid item xs>*/}
                    {/*    <InfoIcon fontSize={"small"} style={{marginTop: 5}}/>*/}
                    {/*</Grid>*/}
                </Grid>


                {Props.uploadPath && (
                <Grid item container xs={8} style={{borderLeft: '2px solid #d3d3d3', paddingLeft: 30}}>
                    <Grid item xs={4}>
                        {Props.referencePath === 'employee' && <div style={{display: 'inline-block', fontWeight: 550, paddingRight: 20}}>First Branch's Employee:</div>}
                        <div style={{display: 'inline-block'}}>
                            <div style={{maxWidth: 250}}>
                                <input
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    style={{display: 'none'}}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleChange}
                                />

                                <label htmlFor="contained-button-file">
                                    <Button variant={"contained"} customColor={'#297ECC'} textColor={'white'}
                                            component="span"
                                            disabled={submitting}
                                            startIcon={<CloudUpload style={{color: "white"}}/>}>
                                        Upload File
                                    </Button>
                                </label>
                            </div>

                        </div>
                    </Grid>

                    {Props.path === 'employees' && (
                        <div style={{marginTop: 15}}>
                            <div style={{display: 'inline-block', fontWeight: 550}}>First Branch:</div>
                            <div style={{display: 'inline-block', paddingLeft: 20}}>
                                <div style={{maxWidth: 150}}>
                                    <input
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        style={{display: 'none'}}
                                        type="file"
                                        id="contained-button-file"

                                        onChange={handleChange}
                                    />

                                    <label htmlFor="contained-button-file">
                                        <Button variant={"contained"} customColor={'#297ECC'} textColor={'white'}
                                                component="span"
                                                disabled={submitting}

                                                startIcon={<CloudUpload style={{color: "white"}}/>}>
                                            Upload File
                                        </Button>
                                    </label>

                                </div>
                            </div>
                        </div>
                    )}
                </Grid>
                )}

                {submitting && (
                    <Grid item>
                        <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default SetupFileUpload;
