import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import useSWR from "swr";
import {Grid, Popper} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import InputField from "../../../../shared/atom/formElements/inputField";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import DateComponent from "../../../../product/molecules/dateComponent";
import Button from "../../../../shared/atom/button";
import SearchIcon from "@material-ui/icons/Search";
import CircularSpinner from "../../../../shared/atom/spinner";
import {getFirstFiscalDate} from "../../../../../config/fiscalYear";
import {convertAdToBs, getTodaysDate, getYesterDayDate} from "../../../../../config/dateFunctions";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/RootReducer";

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const SalesFilter: React.FC<any> = (Props) => {
    const styles = useStyles();

    const initialValues = {
        search: '',
        enable: 'Enabled',
        isJournaled: 'Without Journal',
        customer: null,
        transactionYearAdFrom: null,
        transactionMonthAdFrom: null,
        transactionDateAdFrom: null,
        transactionYearBsFrom: null,
        transactionMonthBsFrom: null,
        transactionDateBsFrom: null,
        transactionYearAdTill: null,
        transactionMonthAdTill: null,
        transactionDateAdTill: null,
        transactionYearBsTill: null,
        transactionMonthBsTill: null,
        transactionDateBsTill: null,

    }

    const [formState, setFormState] = useState(initialValues)

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();
    const dashboard = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {

        // if(!location.pathname.includes('head')) return;
        // if (!localStorage.getItem('search')) return

        if(!nepEngDates) return;
        if(!formatedNepEngDates) return;

        let adDate = getFirstFiscalDate(nepEngDates.data, dashboard.fiscalYear);
        let bsDate: any = convertAdToBs(adDate.join('-'), formatedNepEngDates) || [];

        let adDateTill = getTodaysDate().split('-');
        let bsDateTill: any = convertAdToBs(getTodaysDate(), formatedNepEngDates) || [];

        let adDateTillLog = getYesterDayDate().split('-');
        let bsDateTillLog: any = convertAdToBs(getYesterDayDate(), formatedNepEngDates) || [];


        // @ts-ignore
        let tempJson = {
            transactionYearAdFrom: adDate[0],
            transactionDateAdFrom: adDate[2],
            transactionMonthAdFrom: adDate[1],
            transactionDateBsFrom: bsDate[2],
            transactionMonthBsFrom: bsDate[1],
            transactionYearBsFrom: bsDate[0],
            transactionYearAdTill: adDateTill[0],
            transactionMonthAdTill: adDateTill[1],
            transactionDateAdTill: adDateTill[2],
            transactionYearBsTill: bsDateTill[0],
            transactionMonthBsTill: bsDateTill[1],
            transactionDateBsTill: bsDateTill[2],
            transactionYearAdTillLog: adDateTillLog[0],
            transactionMonthAdTillLog: adDateTillLog[1],
            transactionDateAdTillLog: adDateTillLog[2],
            transactionYearBsTillLog: bsDateTillLog[0],
            transactionMonthBsTillLog: bsDateTillLog[1],
            transactionDateBsTillLog: bsDateTillLog[2],
            search: '',
            enable: 'Enabled',
            isJournaled: 'Without Journal',
            customer: null,
        }

        setFormState((prevState: any) => {
            return {
                ...prevState,
                ...tempJson
            }
        })

        Props.setFormState(tempJson);


    }, [nepEngDates, formatedNepEngDates])


    const validationSchema = Yup.object({})
    const onSubmit = (values: any, formAction: any) => {
        Props.setFormState(values)
    };

    const {data: customerList} = useSWR('/getAllCustomers')

    const withJournaled = ['estimate']
    const withDate = ['estimate', 'return', 'invoice'];
    const withName = ['purchase-rfp'];
    const withCustomer = ['estimate', 'return', 'invoice']

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{width: 400}} placement='bottom-end'/>)
    }

    return (

        <div>

            <Formik

                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {

                    return (
                        <div style={{marginTop: 10}}>
                            <Form style={{marginLeft: 2}}>
                                <Grid container justify={withJournaled.includes(Props.from) ? 'flex-start': withDate.includes(Props.from) ? "space-between" : 'flex-start'}
                                      alignItems={"center"} spacing={1}>

                                    {withName.includes(Props.from) && (
                                        <Grid item xs={3} alignItems={'center'}
                                              style={{marginBottom: withDate.includes(Props.from) ? 0 : 5}}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'search'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <InputField type={'text'} id={'search'} {...field}
                                                                                size={"small"}
                                                                                filterInput={true}
                                                                                placeholder={'Code/ Name'}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>
                                        </Grid>
                                    )}

                                    {withCustomer.includes(Props.from) && (
                                        <Grid item xs={2} alignItems={'center'}
                                              style={{marginBottom: 0}}>

                                            <div style={{position: 'relative'}}>
                                                <Field name={'customer'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'customer'} {...field}
                                                                                       size={"small"}
                                                                                       placeholder={'Customer'}
                                                                                       onChange={(e, value) => {
                                                                                           form.setFieldValue("customer", value)

                                                                                       }}

                                                                                       options={customerList ? customerList.data : []}
                                                                                       loading={!customerList}
                                                                                       getOptionLabel={option => option.name}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>
                                        </Grid>
                                    )}

                                    {withDate.includes(Props.from) && (
                                        <>
                                            <Grid container item xs={5} alignItems={'center'} spacing={2}

                                                  style={{marginBottom: 10, paddingLeft: 20}}>
                                                <Grid item xs={2}>
                                                    <label htmlFor="fromDate"
                                                           className={styles.label}
                                                    >
                                                        From:
                                                    </label>
                                                </Grid>

                                                <Grid item container xs={10} alignItems={'center'}>

                                                    <DateComponent
                                                        ad={['transactionYearAdFrom', 'transactionMonthAdFrom', 'transactionDateAdFrom']}
                                                        bs={['transactionYearBsFrom', 'transactionMonthBsFrom', 'transactionDateBsFrom']}
                                                    />

                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={5} alignItems={'center'} spacing={2}
                                                  style={{marginBottom: 10}}>
                                                <Grid item xs={2}>
                                                    <label htmlFor="tillDate"
                                                           className={styles.label}
                                                    >
                                                        Till:
                                                    </label>
                                                </Grid>

                                                <Grid item container xs={10} alignItems={'center'}>

                                                    <DateComponent
                                                        ad={['transactionYearAdTill', 'transactionMonthAdTill', 'transactionDateAdTill']}
                                                        bs={['transactionYearBsTill', 'transactionMonthBsTill', 'transactionDateBsTill']}
                                                    />

                                                </Grid>
                                            </Grid>


                                        </>
                                    )}

                                    {withJournaled.includes(Props.from) && (
                                        <Grid item xs={3} alignItems={'center'}
                                              style={{marginBottom: 5, marginRight: 20}}>

                                            <div style={{position: 'relative'}}>
                                                <Field name={'isJournaled'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;

                                                            return (
                                                                <>
                                                                    <AutoCompleteInput type={'text'}
                                                                                       id={'isJournaled'} {...field}
                                                                                       size={"small"}
                                                                                       disableClearable={true}
                                                                                       onChange={(e, value) => {
                                                                                           form.setFieldValue("isJournaled", value)
                                                                                           // Props.setChecked([])
                                                                                           let tempJson = {'isJournaled': value}
                                                                                           Props.setFormState({...form.values, ...tempJson});

                                                                                       }}

                                                                                       options={['With Journal', 'Without Journal']}

                                                                                       getOptionLabel={option => option}
                                                                                       error={meta.touched && meta.error}
                                                                    />
                                                                    <div style={{position: "absolute"}}>
                                                                <span style={{
                                                                    color: 'red',
                                                                    fontSize: 12,
                                                                    bottom: 0,
                                                                    left: 2
                                                                }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>
                                        </Grid>
                                    )}

                                    <Grid item xs={3} alignItems={'center'}
                                          style={{marginBottom: 5}}>
                                        <Grid container justify={"flex-start"} spacing={2} alignItems={'center'}>
                                            <Grid item>
                                                <div style={{maxWidth: 140}}>
                                                    <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                            textColor={'white'}
                                                            disabled={Props.submitting}
                                                            startIcon={<SearchIcon/>}

                                                            type={'submit'}>
                                                            <span style={{fontSize: 15}}>
                                                                Search
                                                            </span>
                                                    </Button>
                                                </div>
                                            </Grid>

                                            <Grid item>
                                                {Props.submitting &&
                                                <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )
};


export default SalesFilter
