export const ACCOUNT_CODE_MAPPER = {
    // assetAccount: 'FXD-AST',
    // indirectExpenseAccount: 'IND-EXPN',
    // directExpenseAccount: 'D-EXPN',
    // inventoryAccount: 'CA-204',
    // currentAssetInventory: 'CA-204',
    // indirectExpenseAdministrativeExpense: 'IE-604',
    // vatAccountCode: 'IE-604-006',
    // indirectExpenseOperatingExpense: 'IE-601',
    // indirectExpenseTDS: 'IE-605',
    // directIncomeRevenue: 'DI-502',
    // tdsAccounts: 'IE-605',
    // returnTypeAccount: ''

    assetAccount: 'FXD-AST',
    indirectExpenseAccount: 'IND-EXPN',
    indirectIncomeAccount: 'IND-INCM',
    directExpenseAccount: 'D-EXPN',
    inventoryAccount: 'CA-203',
    currentAssetInventory: 'CA-203',
    indirectExpenseAdministrativeExpense: 'IE-607',
    currentLiabilites: 'CL-304',
    vatAccountCode: 'CL-304-001',
    currentAssetAdvanceTax: 'CA-206',
    purchaseVatAccountCode: 'CA-206-003',
    indirectExpenseOperatingExpense: 'IE-607',
    indirectExpenseTDS: 'CL-305',
    directIncomeRevenue: 'DI-501',
    returnTypeAccount: 'CA-203',
    salesReturnGroup: 'DI-501',
    salesReturnHead: 'DI-501-004',
    receivablesGroup: 'CA-205',
    payableGroup: 'CL-301'
}


