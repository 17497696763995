import React from 'react';
import PageTopBar from "../../../accounting/organisms/pageTopBar";
import DividerComponent from "../../../shared/atom/divider";
import ProductOfferForm from "../../organisms/form/productOffer";
import UomAdditionForm from "../../organisms/form/uom";
import {useParams} from "react-router-dom";

const UomAddition = () => {

    // @ts-ignore
    const {id} = useParams();


    return (
        <div>
            <PageTopBar name={id ? 'Edit Unit Of Measure':'Add Unit Of Measure'}/>

            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: '25px 0px 10px 25px',}}>
                <div style={{maxWidth: 1130, paddingRight:20, paddingBottom: 50}}>
                    <UomAdditionForm mode={id ? 'edit':"create"} from={'uom'} id={id}/>
                </div>
            </div>
        </div>
    );
};

export default UomAddition;