import {Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Button from "../../../../shared/atom/button";
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import InputField from "../../../../shared/atom/formElements/inputField";
import axiosInstance from "../../../../../config/axiosConfig";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import {trigger} from "swr";
import useAddressHandler from "../../../../../effects/useAddressHandler";
import FormSaveButton from "../../FormSaveButton";
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import RequiredNotation from "../../../molecules/requiredNotation";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {

    from: string
    mode: 'edit' | 'create',
    nextPath?: string
    id?: any
}


const ShareHolderForm: React.FC<Props> = (Props) => {


    const [selectedAddress, setSelectedAddress] = useState({
        province: null,
        district: null,
        vdc: null,
        ward: null
    })

    const {provinceOptions, districtOptions, vdcOptions, wardOptions} = useAddressHandler({...selectedAddress})

    const location = useLocation();

    useEffect(() => {

            if (Props.id) {
                // @ts-ignore
                const {name, phone, email, address, citizenshipNumber, citizenshipPhoto, dateOfBirth} = location.state;

                try {

                    let addressTemp = {
                        address: {
                            country: 'Nepal',
                            province: {province: address.provinceOrState},
                            municipality: {municipalityOrVdcName: address.municipalityOrVdc},
                            district: {district: address.district},
                            ward: address.wardNumber,
                            city: address.city,
                            googleMapLocationUrl: address.googleMapLocationUrl
                        }

                    }

                    // @ts-ignore
                    setFormState({
                        ...addressTemp,
                        name: name,
                        phone: phone,
                        email: email,
                        citizenshipNumber: citizenshipNumber,
                        dateOfBirth: dateOfBirth
                    })
                }catch (e) {

                }

            }

        }
        ,
        []
    )

    const history = useHistory();

    const initialValues = {
        name: "",
        phone: "",
        email: "",
        address: {
            country: 'Nepal',
            province: null,
            city: '',
            googleMapLocationUrl: '',
            district: null,
            municipality: null,
            ward: null


        },
        citizenshipNumber: "",
        dateOfBirth: "",
    }


    const [formState, setFormState] = useState(initialValues)

    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Email is invalid').required('Required'),
    })

    const dispatch = useDispatch();

    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';
        let tempVal = {...values};

        let message = ''
        let barType = 'info'

        try {

            console.log('adding share holder')

            let payload = {
                ...tempVal,
                address: {
                    country: 'Nepal',
                    provinceOrState: tempVal.address?.province?.province,
                    municipalityOrVdc: tempVal.address?.municipality?.municipalityOrVdcName,
                    district: tempVal.address?.district?.district,
                    wardNumber: tempVal.address?.ward,
                    city: tempVal.address?.city,
                    googleMapLocationUrl: tempVal.address?.googleMapLocationUrl
                }
            };

            if (Props.id) {
                // @ts-ignore
                payload = {...payload,id: location.state.id, address: {id : location.state.address.id}}
                const {data} = await axiosInstance.put('/updateShareHolder', payload)
                handleResponse(data, formActions,isReset);

            } else {
                const {data} = await axiosInstance.post('/registerShareHolder', payload)
                handleResponse(data, formActions, isReset);
            }


        } catch (e) {

            console.log(e);
            formActions.setSubmitting(false);

            message = e.response.data.message
            barType = 'warning'

            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: message, type: barType}
            })

        }


        formActions.setSubmitting(false);


    }




    const handleResponse = (data: any, formActions: any, isReset: boolean) => {

        let message = ''
        let barType = 'info'

        if (data?.code === 'OK') {

            message = data?.message;

            if (data?.message === "Share holder registered" || data?.message === `Share holder's information updated.`) {
                trigger(`/getShareHolders/0/5`);
                barType = 'success'
                formActions.resetForm();
                if(!isReset) history.replace('/dashboard/company-setup/share-holders')
            } else {
                barType = 'warning'
            }

        }

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: message, type: barType}
        })
    }

    const validateProvince = (value: any) => {


        if (value?.province) {


            setSelectedAddress(prevState => {
                return {
                    ...prevState,
                    province: value
                }
            })

        }

        return false

    }

    const handleSelectedDistrict = (value: any) => {

        setSelectedAddress(prevState => {
            return {
                ...prevState,
                district: value
            }
        })

    }

    const validateMunicipality = (value: any) => {


        if (value?.wardNumbers) {

            setSelectedAddress(prevState => {
                return {
                    ...prevState,
                    vdc: value
                }
            })

        }

    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }


    return (
        <Formik

            initialValues={formState}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnMount={false}
            validateOnBlur={true}
        >
            {formik => {


                return (
                    <div>
                        <PagePrompt formik={formik}/>

                        <Form style={{margin: 20}} onKeyDown={onKeyDown}>


                            <Grid container justify={"space-between"}>


                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="name"
                                               style={{fontWeight: "bold", fontSize: 14}}> Name:<RequiredNotation/></label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'name'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;

                                                        return (
                                                            <>
                                                                <InputField type={'text'} id={'name'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>


                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="email"
                                               style={{fontWeight: "bold", fontSize: 14}}> Email:<RequiredNotation/></label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'email'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;

                                                        return (
                                                            <>
                                                                <InputField type={'email'} id={'email'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="phone" style={{fontWeight: "bold", fontSize: 14}}> Phone
                                            number:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Field name={'phone'}>
                                            {
                                                (props: { field: any; form: any; meta: any; }) => {

                                                    const {field, form, meta} = props;

                                                    return (
                                                        <InputField type={'tel'} id={'phone'} {...field}
                                                                    size={"small"}
                                                            // styles={{backgroundColor: '#fafafa'}}
                                                                    error={meta.touched && meta.error}
                                                                    helperText={meta.touched && meta.error ? meta.error : null}/>

                                                    )
                                                }
                                            }
                                        </Field>
                                    </Grid>
                                </Grid>


                                {/*<Grid container item sm={6} alignItems={'center'} spacing={2} style={{marginBottom: 15}}>*/}
                                {/*    <Grid item xs={4}>*/}
                                {/*        <label htmlFor="address" style={{fontWeight: "bold", fontSize: 14}}> Address:</label>*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={8}>*/}
                                {/*        <Field name={'address'}>*/}
                                {/*            {*/}
                                {/*                (props: { field: any; form: any; meta: any; }) => {*/}

                                {/*                    const {field, form, meta} = props;*/}

                                {/*                    return (*/}
                                {/*                        <InputField type={'text'} id={'address'} {...field}*/}
                                {/*                                    size={"small"}*/}
                                {/*                            // styles={{backgroundColor: '#fafafa'}}*/}
                                {/*                                    error={meta.touched && meta.error}*/}
                                {/*                                    helperText={meta.touched && meta.error ? meta.error : null}/>*/}

                                {/*                    )*/}
                                {/*                }*/}
                                {/*            }*/}
                                {/*        </Field>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}

                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="citizenshipNumber"
                                               style={{fontWeight: "bold", fontSize: 14}}> Citizenship
                                            No.:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Field name={'citizenshipNumber'}>
                                            {
                                                (props: { field: any; form: any; meta: any; }) => {

                                                    const {field, form, meta} = props;

                                                    return (
                                                        <InputField type={'text'}
                                                                    id={'citizenshipNumber'} {...field}
                                                                    size={"small"}
                                                            // styles={{backgroundColor: '#fafafa'}}
                                                                    error={meta.touched && meta.error}
                                                                    helperText={meta.touched && meta.error ? meta.error : null}/>

                                                    )
                                                }
                                            }
                                        </Field>
                                    </Grid>
                                </Grid>

                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="dateOfBirth"
                                               style={{fontWeight: "bold", fontSize: 14}}> Date of
                                            birth:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Field name={'dateOfBirth'}>
                                            {
                                                (props: { field: any; form: any; meta: any; }) => {

                                                    const {field, form, meta} = props;

                                                    return (
                                                        <InputField type={'date'} id={'dateOfBirth'} {...field}
                                                                    size={"small"}
                                                            // styles={{backgroundColor: '#fafafa'}}
                                                                    error={meta.touched && meta.error}
                                                                    helperText={meta.touched && meta.error ? meta.error : null}/>

                                                    )
                                                }
                                            }
                                        </Field>
                                    </Grid>
                                </Grid>
                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                </Grid>

                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="province"
                                               style={{fontWeight: "bold", fontSize: 14}}> Province:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'address.province'}
                                                   validate={validateProvince}
                                            >
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;


                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={'province'} {...field}
                                                                                   size={"small"}
                                                                                   onChange={(e, value) => {
                                                                                       if (value === null) {
                                                                                           form.setFieldValue('address.district', null)
                                                                                           form.setFieldValue('address.municipality', null)
                                                                                           form.setFieldValue('address.ward', null)
                                                                                       }
                                                                                       form.setFieldValue("address.province", value)
                                                                                   }}
                                                                                   options={provinceOptions}
                                                                                   getOptionLabel={option => option.province}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>
                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="district"
                                               style={{fontWeight: "bold", fontSize: 14}}> District:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'address.district'}

                                            >
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;

                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={'district'} {...field}
                                                                                   size={"small"}
                                                                                   onChange={(e, value) => {
                                                                                       if (value === null) {
                                                                                           form.setFieldValue('address.municipality', null)
                                                                                           form.setFieldValue('address.ward', null)
                                                                                       } else {
                                                                                           handleSelectedDistrict(value)

                                                                                       }
                                                                                       form.setFieldValue("address.district", value)
                                                                                   }}
                                                                                   options={districtOptions}
                                                                                   getOptionLabel={option => option.district}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>

                                                        <span
                                                            style={{
                                                                color: 'red',
                                                                fontSize: 12,
                                                                bottom: 0,
                                                                left: 2
                                                            }}
                                                        >
                                                            {(meta.touched && meta.error) ? meta.error : null}
                                                        </span>

                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>

                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="municipality"
                                               style={{fontWeight: "bold", fontSize: 14}}> Municipality/
                                            Vdc:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'address.municipality'} validate={validateMunicipality}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;

                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={'municipality'} {...field}
                                                                                   size={"small"}
                                                                                   onChange={(e, value) => {
                                                                                       if (value === null) {
                                                                                           form.setFieldValue('address.ward', null)
                                                                                       }
                                                                                       form.setFieldValue("address.municipality", value)
                                                                                   }}
                                                                                   options={vdcOptions}
                                                                                   getOptionLabel={option => option.municipalityOrVdcName}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>

                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="ward" style={{fontWeight: "bold", fontSize: 14}}> Ward
                                            No.:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'address.ward'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;

                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={'ward'} {...field}
                                                                                   size={"small"}
                                                                                   onChange={(e, value) => form.setFieldValue("address.ward", value)}
                                                                                   options={wardOptions}
                                                                                   getOptionLabel={option => option}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                        <span style={{
                                                            color: 'red',
                                                            fontSize: 12,
                                                            bottom: 0,
                                                            left: 2
                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>

                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="city"
                                               style={{fontWeight: "bold", fontSize: 14}}> Tole/ Street:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Field name={'address.city'}>
                                            {
                                                (props: { field: any; form: any; meta: any; }) => {

                                                    const {field, form, meta} = props;

                                                    return (
                                                        <InputField type={'text'} id={'address.city'} {...field}
                                                                    size={"small"}
                                                                    // onChange={(e: any, value: any) => {
                                                                    //     form.setFieldValue("address.city", value)
                                                                    // }}
                                                            // styles={{backgroundColor: '#fafafa'}}
                                                                    error={meta.touched && meta.error}
                                                                    // helperText={meta.touched && meta.error ? meta.error : null}
                                                        />

                                                    )
                                                }
                                            }
                                        </Field>
                                    </Grid>
                                </Grid>

                                <Grid container item sm={6} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={4}>
                                        <label htmlFor="googleMapLocationUrl"
                                               style={{fontWeight: "bold", fontSize: 14}}> Google Map Url:</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Field name={'address.googleMapLocationUrl'}>
                                            {
                                                (props: { field: any; form: any; meta: any; }) => {

                                                    const {field, form, meta} = props;

                                                    return (
                                                        <InputField type={'text'} id={'address.googleMapLocationUrl'} {...field}
                                                                    size={"small"}
                                                                    error={meta.touched && meta.error}
                                                        />

                                                    )
                                                }
                                            }
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container justify={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <FormSaveButton mode={Props.mode} from={Props.from}
                                                    submitting={formik.isSubmitting}/>
                                </Grid>
                                <Grid item>
                                    {Props.from === 'home' && <div>
                                        <div>
                                            <Button variant={"contained"} customColor={'#297ecc'}
                                                    textColor={'white'}
                                                    onClick={() => history.push(Props.nextPath ? Props.nextPath : '')}>
                                                Go to Branch Setup
                                            </Button>
                                        </div>
                                    </div>}
                                </Grid>

                            </Grid>

                        </Form>

                    </div>
                )
            }}
        </Formik>
    )

}


export default ShareHolderForm;
