import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {useHistory, useLocation} from "react-router-dom";
import * as Yup from "yup";
import {GetEffectiveTime} from "../../../../../config/helperFunctions";
import axiosInstance from "../../../../../config/axiosConfig";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Grid, Popper, useMediaQuery} from "@material-ui/core";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../molecules/errorLabel";
import DateComponent from "../../../molecules/dateComponent";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import useSWR, {trigger} from "swr";
import {matchSorter} from "match-sorter";
import ToolTip from "../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import InputField from "../../../../shared/atom/formElements/inputField";
import {Column} from "react-table";
import VoucherTableForm from "../../../../accounting/organisms/Forms/voucherTableForm";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs} from "../../../../../config/dateFunctions";
import {useDispatch} from "react-redux";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {

    from: string
    mode: 'edit' | 'create',
    nextPath?: any

}

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const InventoryAdjustmentForm: React.FC<Props> = (Props) => {

    const initialState = {
        transactionYearAd: null,
        transactionMonthAd: null,
        transactionDateAd: null,
        transactionYearBs: null,
        transactionMonthBs: null,
        transactionDateBs: null,
        code: '',
        vendor: null,
        adjustmentNumber: null,
        adjustmentType: null,
        description: '',
        tableRow: [
            {id: 1, product: null,category: null, availableQuantity: 0, price: 0, adjustmentQuantity: 0, newQuantity:0, description: '', amount: 0},
            {id: 2, product: null,category: null, availableQuantity: 0, price: 0,adjustmentQuantity: 0, newQuantity:0, description: '', amount: 0},
        ],
    }

    const [openDialog, setOpenDialog] = useState(false)
    const [formState, setFormState] = useState(initialState);

    const styles = useStyles();
    const location = useLocation();
    const {data: vendorOptions} = useSWR('/getVendors')

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();


    const {data: inventoryAdjustmentCode} = useSWR('/getCode?name=inventoryAdjustment&id=0')

    useEffect(() => {

        if (inventoryAdjustmentCode) setFormState(prevState => {
            return {
                ...prevState,
                code: inventoryAdjustmentCode.t.code
            }
        })

    }, [inventoryAdjustmentCode])


    const convert = (transactionDate: any) => {

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            transactionYearAd: adDate[0],
            transactionDateAd: adDate[2],
            transactionMonthAd: adDate[1],
            transactionDateBs: bsDate[2],
            transactionMonthBs: bsDate[1],
            transactionYearBs: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }

    useEffect(() => {

        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])
    }, [formatedNepEngDates])


    const validationSchema = Yup.object({
        // vendor: Yup.object().required('Required').nullable(true)
    })


    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            if(values.tableRow.filter((item: any) => (item.product )).length === 0){
                handleResponse(`Select at least one product.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            if(values.tableRow.filter((item: any) => (item.product) && !(item.adjustmentQuantity && item.price && item.amount)).length > 0) {
                handleResponse(`Some row doesn't have Adjustment Quantity, Price and Amount.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            let payload = {
                productSubs: values.tableRow.filter((item: any) => item.product && item.price).map((item: any) => {
                    return {
                        productId: item.product.id,
                        name: item.product.name,
                        price: parseFloat(item.price),
                        amount: parseFloat(item.amount),
                        quantity: parseInt(item.adjustmentQuantity),
                        description: item.description
                    }
                }),
                vendorDto: values.vendor ? {id: values.vendor.id}: null,
                adjustmentNumber: values.code,
                adjustmentType: values.adjustmentType,
                adjustmentDate: values.transactionYearAd + '-' + ('0' + values.transactionMonthAd).slice(-2) + '-' + ('0' + values.transactionDateAd).slice(-2),
                description: values.description,
            }

            // console.log(payload)
            // throw new Error('asdf')

            const {data} = await axiosInstance.post('/adjustInventory', payload);
            responseProcess(data, formActions, isReset)

        } catch (e) {

            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }

        formActions.setSubmitting(false)
    }


    const history = useHistory();

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            if(!isReset) history.replace('/dashboard/inventory/inventory-adjustment')
            trigger('/getCode?name=inventoryAdjustment&id=0')
        } else {

            handleResponse(data.message, 'warning')

        }
    }

    const dispatch = useDispatch();
    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }


    const handleRemove = (index: number, formikInstance: any) => {
        const {values: {tableRow}} = formikInstance;
        let tempTable = [...tableRow].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue('tableRow', [...tempTable])
    }


    const addNew = useCallback((formikInstance: any) => {
        const {values: {tableRow}} = formikInstance;
        let tempArr = {
            id: tableRow[tableRow.length - 1].id + 1,
            product: null,category: null, availableQuantity: 0, adjustmentQuantity: 0, newQuantity:0, description: ''
        }
        tableRow.push(tempArr)
        formikInstance.setFieldValue('tableRow', [...tableRow])
    }, [formState])

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }


    return (
        <div>

            <Formik
                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {

                    return (
                        <div>
                            <PagePrompt formik={formik}/>
                            <Form style={{margin: 0}} onKeyDown={onKeyDown}>
                                <Grid container justify={"space-between"}>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="code"
                                                   className={styles.label}>Adjustment No.:</label>
                                        </Grid>

                                        <Grid item xs={8} className={styles.label}>
                                            {formik.values.code}
                                        </Grid>

                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="vendor"
                                                   className={styles.label}> Vendor:</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'vendor'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`vendor`} {...field}
                                                                                   size={"small"}

                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`vendor`, value)
                                                                                   }}

                                                                                   options={vendorOptions ? vendorOptions.data: []}
                                                                                   loading={!vendorOptions}

                                                                                   getOptionLabel={option => option.name}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                    {/*      style={{marginBottom: 15}}>*/}
                                    {/*    <Grid item xs={4}>*/}
                                    {/*        <label htmlFor="adjustmentType"*/}
                                    {/*               className={styles.label}> Adjustment Type: </label>*/}
                                    {/*    </Grid>*/}

                                    {/*    <Grid item xs={8}>*/}
                                    {/*        <div style={{position: 'relative'}}>*/}
                                    {/*            <Field name={'adjustmentType'}>*/}
                                    {/*                {(props: { field: any; form: any; meta: any; }) => {*/}
                                    {/*                    const {field, form, meta} = props;*/}
                                    {/*                    return (*/}
                                    {/*                        <>*/}
                                    {/*                            <AutoCompleteInput type={'text'}*/}
                                    {/*                                               id={`adjustmentType`} {...field}*/}
                                    {/*                                               size={"small"}*/}
                                    {/*                                               autoHighlight={true}*/}
                                    {/*                                               autoSelect={true}*/}
                                    {/*                                               onChange={(e, value) => {*/}
                                    {/*                                                   form.setFieldValue(`adjustmentType`, value)*/}
                                    {/*                                               }}*/}

                                    {/*                                               options={[]}*/}
                                    {/*                                               loading={true}*/}

                                    {/*                                               getOptionLabel={option => option.name}*/}
                                    {/*                                               error={meta.touched && meta.error}*/}
                                    {/*                            />*/}
                                    {/*                            <ErrorLabel meta={meta}/>*/}
                                    {/*                        </>*/}
                                    {/*                    )*/}
                                    {/*                }}*/}
                                    {/*            </Field>*/}

                                    {/*        </div>*/}
                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 10}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="fromDate"
                                                   className={styles.label}
                                            >
                                                Adjustment Date:
                                            </label>
                                        </Grid>

                                        <Grid item container xs={8} alignItems={'center'}>

                                            <DateComponent
                                                ad={['transactionYearAd', 'transactionMonthAd', 'transactionDateAd']}
                                                bs={['transactionYearBs', 'transactionMonthBs', 'transactionDateBs']}
                                            />

                                        </Grid>
                                    </Grid>




                                </Grid>

                                <div>

                                    <TableForm handleRemove={handleRemove} addNew={addNew} setOpenDialog={setOpenDialog}
                                               formikInstance={formik}/>
                                </div>


                                <div>
                                    <Grid container item xs={12} sm={6} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="description" className={styles.label}> Description:</label>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'description'}>
                                                    {
                                                        (props: { field: any; form: any; meta: any; }) => {

                                                            const {field, form, meta} = props;


                                                            return (
                                                                <>
                                                                    <InputField type={'text'}
                                                                                id={'description'} {...field}
                                                                                size={"small"}
                                                                                multiline={true}
                                                                                rows={3}
                                                                                fullWidth={true}
                                                                                error={meta.touched && meta.error}
                                                                    />
                                                                    <ErrorLabel meta={meta}/>
                                                                </>

                                                            )
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                        </Grid>
                                    </Grid>
                                </div>

                                <div style={{marginTop: 50}}>
                                    <FormSaveButton mode={Props.mode} from={'purchase-rfp'}
                                                    submitting={formik.isSubmitting}/>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    );
};


const TableForm: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance}) => {

    //console.log('from inner component',formikInstance)
    const {values: {tableRow}} = useFormikContext();
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');
    const {data: productOptions} = useSWR('/getProducts');

    const name = 'tableRow'
    const formik = useFormikContext();


    const getDebit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit))
        }, 0.00)
    }, [tableRow])


    const actualAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity))
        }, 0.00)
    }, [tableRow])

    const discountAmount = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.price) * parseFloat(item.quantity) - parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])

    const bundlePrice = useCallback(() => {
        return tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
            return (result + parseFloat(item.amount))
        }, 0.00)
    }, [tableRow])


    // let sum = values.tableRow.filter((item: any) => item.product && item.quantity && item.amount).reduce((result: number, item: any) => {
    //     return (result + parseFloat(item.amount))
    // }, 0.00)

    const getCredit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.credit))
        }, 0.00)
    }, [tableRow])


    const data = useMemo(() => tableRow, [tableRow, formikInstance]);


    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;

        // //console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0 || lastRow.credit > 0) {
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.down('xs'));
        return (<Popper {...props} style={{width: matches ? 250:500}} placement='bottom-start'/>)
    }


    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        {
            id: 'productItem',
            width: 280,
            Header: 'Product Item',
            accessor: "productItem",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].product`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {
                                    let tempRows = form.values.tableRow;

                                    let selectedProduct = tempRows.filter((item: any) => item.product).map((item: any) => item.product?.id);
                                    if (field.value?.id) selectedProduct.splice(selectedProduct.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedProduct.includes(item.id)) : [];

                                }


                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"} style={{padding: '5px 0'}}>
                                            <Grid item xs>
                                                <AutoCompleteInput type={'text'}
                                                                   id={`${name}[${index}].product`} {...field}
                                                                   size={"small"}
                                                                   placeholder={'Select Product'}
                                                                   filterOptions={(options, {inputValue}) => {
                                                                       let filteredOptions = getOptions(options)
                                                                       return matchSorter(filteredOptions, inputValue, {keys: ['name', 'code']});
                                                                   }}
                                                                   onChange={(e, value) => {
                                                                       form.setFieldValue(`${name}[${index}].price`, value ? value.buying_price : 0)
                                                                       form.setFieldValue(`${name}[${index}].product`, value)
                                                                   }}

                                                                   hideOutline={true}
                                                                   autoHighlight={true}
                                                                   autoSelect={true}
                                                                   options={productOptions ? productOptions.data : []}
                                                                   loading={!productOptions}
                                                                   variant={'standard'}
                                                                   InputProps={{
                                                                       disableUnderline: true,
                                                                       style: {paddingLeft: 5, paddingRight: 5}
                                                                   }}
                                                                   PopperComponent={PopperMy}
                                                                   getOptionLabel={option => option.name}
                                                                   renderOption={(option) => {
                                                                       return (
                                                                           <div>
                                                                               <div style={{
                                                                                   fontSize: 11,
                                                                               }}>
                                                                                   <>{option.code}</>
                                                                               </div>
                                                                               <div>{option.name}</div>
                                                                           </div>
                                                                       )
                                                                   }}
                                                                   error={meta.touched && meta.error}
                                                />
                                            </Grid>
                                            {/*<ToolTip title={'Add new product'}>*/}
                                            {/*    <Grid item style={{*/}
                                            {/*        borderLeft: '1px solid #d0d0d0',*/}
                                            {/*        padding: '8px 3px 3px 3px',*/}
                                            {/*        cursor: 'pointer',*/}

                                            {/*    }}*/}
                                            {/*          onClick={() => handleDialogOpen()}*/}
                                            {/*    >*/}

                                            {/*        <div>*/}

                                            {/*            <AddIcon style={{*/}
                                            {/*                color: COLOR_PROFILE.primaryColor,*/}
                                            {/*                fontWeight: 700,*/}
                                            {/*                fontSize: 20*/}
                                            {/*            }}/>*/}

                                            {/*        </div>*/}
                                            {/*    </Grid>*/}
                                            {/*</ToolTip>*/}
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        // {
        //     id: 'category',
        //     Header: 'Category',
        //     accessor: "category",
        //     width: 10,
        //     Cell: (cellObj: any) => {
        //
        //         const index = cellObj.row.index;
        //         const {row: {original}} = cellObj
        //
        //         return (
        //             <div style={{paddingLeft: 5, fontWeight: 700, color: COLOR_PROFILE.primaryColor}}>
        //                 Category
        //             </div>
        //         )
        //
        //
        //     }
        // },
        {
            id: 'availableQuantity',
            Header: 'Available Quantity',
            accessor: "availableQuantity",
            width: 10,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <div style={{paddingLeft: 5, fontWeight: 700, color: COLOR_PROFILE.primaryColor}}>
                        {original.product?.quantity}
                    </div>
                )

                return (
                    <Field name={`${name}[${index}].availableQuantity`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].availableQuantity`} {...field}
                                                    size={"small"}
                                                    step="1"
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].availableQuantity`, event.target.value)
                                                    }}

                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'adjustmentQuantity',
            Header: 'Adj. Quantity',
            accessor: "adjustmentQuantity",
            width: 10,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].adjustmentQuantity`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].adjustmentQuantity`} {...field}
                                                    size={"small"}
                                                    step="1"
                                                    variant={"standard"}
                                                    isNegative={true}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].adjustmentQuantity`, event.target.value)
                                                        form.setFieldValue(`${name}[${index}].amount`, event.target.value * original.price)
                                                    }}

                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'newQuantity',
            Header: 'New Quantity',
            accessor: "newQuantity",
            width: 10,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj

                return (
                    <div style={{paddingLeft: 5, fontWeight: 700, color: COLOR_PROFILE.primaryColor}}>
                        {(parseFloat(original.product?.quantity) + parseFloat(original?.adjustmentQuantity)) || ''}
                    </div>
                )

            }
        },

        {
            id: 'price',
            Header: 'Price',
            accessor: "price",
            // width: 120,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj

                return (
                    <Field name={`${name}[${index}].price`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].price`} {...field}
                                                    size={"small"}
                                                    step="1"
                                                    variant={"standard"}
                                                    isNegative={true}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].price`, event.target.value)
                                                        form.setFieldValue(`${name}[${index}].amount`, event.target.value * original.adjustmentQuantity)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'amount',
            Header: 'Amount',
            accessor: "amount",
            // width: 150,
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj

                return (
                    <div style={{paddingLeft: 5, fontWeight: 700, color: COLOR_PROFILE.primaryColor}}>
                        {parseFloat(original.amount).toFixed(2) || 0}
                    </div>
                )

            }
        },

        {
            id: 'description',
            Header: 'Note',
            // width: 100,
            accessor: "description",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;
                const {row: {original}} = cellObj


                return (
                    <Field name={`${name}[${index}].description`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;


                                return (
                                    <>
                                        <InputField type={'text'} id={`${name}[${index}].description`} {...field}
                                                    size={"small"}
                                                    multiline={true}
                                                    variant={"standard"}
                                                    onChange={(event: any) => {
                                                        form.setFieldValue(`${name}[${index}].description`, event.target.value)
                                                    }}

                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    style={{border: 'none'}}
                                                    error={meta.touched && meta.error}
                                        />

                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                return (
                    <div>


                        {/*<DeleteForeverIcon fontSize={"small"} style={{color: 'red', cursor: 'pointer',}}*/}
                        {/*                   onClick={() => handleDeleteClick(index)}/>*/}

                    </div>
                )
            }
        }

    ]

    const handleDeleteClick = (index: number) => {
        handleRemove(index, formikInstance)
    }

    const getBalance = () => {
        return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }

    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, productOptions]);


    return (
        <div>

            <div style={{paddingBottom: 40}}>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} balance={getBalance}
                                  total={{
                                      'actualAmount': actualAmount(),
                                      'discountAmount': discountAmount(),
                                      'bundlePrice': bundlePrice()
                                  }} type={'purchaseRfp'}
                                  minLength={2}
                                  fromProduct={true}
                                  handleDeleteClick={handleDeleteClick}
                />
            </div>

        </div>
    )

};

export default InventoryAdjustmentForm;