import React from 'react';
import PurchaseReturnAddition from "../../templates/purchaseReturnAddition";

const PurchaseReturnAdd = () => {
    return (
        <div>
            <PurchaseReturnAddition/>
        </div>
    );
};

export default PurchaseReturnAdd;