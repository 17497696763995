import React, {useState} from 'react';
import AccessFilter from "../../../../accessControl/accessFilter";
import SetupAddNew from "../../../accounting/organisms/setupAddNew";
import ProductCategoryTable from "../../organisms/tables/productCategoryTable";
import ProductItemTable from "../../organisms/tables/productItemTable";
import ProductOfferTable from "../../organisms/tables/productOfferTable";
import ProductFilter from "../../organisms/form/filter";
import {Grid} from "@material-ui/core";
import Button from "../../../shared/atom/button";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import CircularSpinner from "../../../shared/atom/spinner";
import axiosInstance from "../../../../config/axiosConfig";
import {useDispatch} from "react-redux";

const ProductItemHome = () => {

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: '', enable: 'Enabled', category: null})
    const [checked, setChecked] = useState<any>([]);


    const handleClick = (name: number) => {

        console.log(name);

        let checkedList = [...checked];

        if (checked.includes(name)) {
            checkedList.splice(checkedList.indexOf(name), 1)

        } else {
            checkedList.push(name)
        }

        setChecked(checkedList);

    }

    const handleEnableDisable = async (item: any) => {

        if (checked.length === 0) return;

        try {

            setSubmitting(true);

            let payload = checked.map((item: any) => parseInt(item));

            const {data} = await axiosInstance.put(`/enableOrDisableProduct?action=${formState.enable === 'Enabled' ? 'disable' : 'enable'}`, payload)

            if(data.code === 'OK') {
                setChecked([]);
                handleResponse(data.message, 'success')
            }   else{
                handleResponse(data.message, 'warning')
            }

            setSubmitting(false);

        } catch (e) {

            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')

        }

    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    return (
        <div>

            <AccessFilter

                section={"productItem"}
                privilege={'write'}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>


                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/product/product-item/add',
                                            name: 'Add Product Item'
                                        }}


                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>Add Product by clicking the button below
                                                </div>

                                            </div>
                                        }

                                    />
                                </div>

                            </div>
                        </div>
                    )

                }}

            />

            <div style={{maxWidth: 1000}}>
                <div style={{padding: '0 30px 0 28px'}}>

                    <div style={{marginBottom: -10}}>
                        <ProductFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                       submitting={submitting} from={'item'}
                                       checked={checked}
                                       setChecked={setChecked}
                        />
                    </div>

                    {checked.length > 0 && (
                        <div
                            style={{
                                position: 'fixed',
                                bottom: 0,
                                zIndex: 100,
                                width: '100%',
                                paddingTop: 20,
                                background: '#fafafa',
                                paddingBottom: 20
                            }}
                        >
                            <Grid container justify={"flex-start"} spacing={2}  alignItems={'center'}>
                                <Grid item>
                                    <div style={{maxWidth: 200}}>
                                        <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                textColor={'white'}
                                                disabled={submitting}
                                                onClick={handleEnableDisable}
                                                style={{width: 100, }}
                                        >
                                            <span style={{fontSize: 15}}>
                                                {formState.enable === 'Enabled' ? 'Disable' : 'Enable'}
                                            </span>
                                        </Button>
                                    </div>
                                </Grid>

                                <Grid item>
                                    {submitting &&
                                    <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                                </Grid>

                            </Grid>
                        </div>
                    )}

                    <div style={{paddingBottom: 40}}>
                    <ProductItemTable handleClick={handleClick} checked={checked} formState={formState}
                                      setSubmitting={setSubmitting} submitting={submitting}/>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ProductItemHome;