import React from 'react';
import SetupAddNew from "../../../organisms/setupAddNew";
import UserRoleTable from "../../../organisms/tables/userRoleTable/userRoleTable";
import AccessDenied from "../../../templates/errorTemplates/accessDenied";
import AccessFilter from "../../../../../accessControl/accessFilter";

const UserAndRolePage: React.FC = () => {
    return (
        <div>

            <div style={{maxWidth: 1000}}>

                <AccessFilter
                    section={"userAndRoles"}
                    privilege={"write"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>
                                    <div style={{padding: 20}}>
                                        <SetupAddNew

                                            buttonNameAdd={{
                                                pathTo: '/dashboard/company-setup/user-and-roles/add',
                                                name: 'Add User'
                                            }}
                                            // buttonNameImport={{pathTo: '', name: 'Import Vendors from Head Office'}}

                                            message={
                                                <div style={{
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    padding: '10px 10px 10px 5px'
                                                }}>
                                                    <div>Click below to add a user.
                                                    </div>

                                                </div>
                                            }

                                        />
                                    </div>
                                </div>

                            </div>

                        )

                    }}

                />


                <AccessFilter
                    section={"userAndRoles"}
                    privilege={"read"}
                    render={(isAllowed: boolean, hasAllAccess: boolean) => {

                        if (!isAllowed) return <AccessDenied/>

                        return (

                            <div>

                                <div>

                                    <div style={{padding: '0 30px 0 28px'}}>
                                        <UserRoleTable/>
                                    </div>
                                </div>

                            </div>

                        )

                    }}

                />


            </div>

        </div>
    );
};

export default UserAndRolePage;
