import React, {useMemo, useState} from 'react';
import {Grid, IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../reactTable/useServerCall";
import useSWR from "swr";
import ReactTable from "../../reactTable/ReactTable";
import {useHistory} from 'react-router-dom';
import CircularSpinner from "../../../../shared/atom/spinner";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import DataEmptyMessage from "../dataEmptyMessage";
import BlockIcon from '@material-ui/icons/Block';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ToolTip from "../../../../shared/atom/tooltip";
import AccessFilter from "../../../../../accessControl/accessFilter";
import DeleteModal from "../../deleteModal";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/RootReducer";

const UserRoleTable: React.FC = () => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [deleteId, setDeleteId] = useState(null)
    const [disableId, setDisableId] = useState(null)
    const [enableId, setEnableId] = useState(null);

    const history = useHistory();

    const dashboard = useSelector((state: RootState) => state.dashboard);


    const shareHolderColumns = [

        {
            id: 'delete',
            Header: 'Actions',
            width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify={"center"} style={{marginRight: 30, paddingRight: 20}}>


                    <AccessFilter

                        section={"userAndRoles"}
                        privilege={"delete"}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed || (dashboard?.role?.id === val.row.original?.role?.id)) return null


                            return (
                                <Grid item xs={4}>
                                    <ToolTip title={'Delete User'}>
                                        <IconButton size={"small"} style={{color: '#ec4949'}}>
                                            <DeleteIcon fontSize={"small"}
                                                        onClick={() => setDeleteId(val.row.original.id)}
                                            />
                                        </IconButton>
                                    </ToolTip>
                                </Grid>
                            )

                        }}

                    />

                    <AccessFilter

                        section={"userAndRoles"}
                        privilege={"delete"}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed || (dashboard?.role?.id === val.row.original?.role?.id)) return null

                            return (
                                <Grid item xs={4}>
                                    <ToolTip title={!val.row.original.active ? "Enable User":'Disable User'}>
                                        <IconButton size={"small"} style={{color: '#ec4949'}}
                                        >
                                            {
                                                val.row.original.active ?
                                                    <BlockIcon fontSize={"small"}
                                                               onClick={() => setDisableId(val.row.original.id)}
                                                    />
                                                :
                                                    <PlayArrowIcon fontSize={"small"}
                                                               onClick={() => setEnableId(val.row.original.id)}
                                                    />
                                            }
                                        </IconButton>
                                    </ToolTip>
                                </Grid>
                            )

                        }}

                    />

                    <AccessFilter

                        section={"userAndRoles"}
                        privilege={"update"}
                        render={(isAllowed: boolean, hasAllAccess: boolean) => {

                            if (!isAllowed || (dashboard?.role?.id === val.row?.original?.role?.id)) return null

                            return (
                                <Grid item xs={4} >
                                    <IconButton size={"small"} style={{color: '#4aca53'}}
                                                disabled={!val.row.original.active}
                                                onClick={() => history.push(`/dashboard/company-setup/user-and-roles/edit/${val.row.original.id}`, val.row.original)}
                                    >
                                        <EditIcon fontSize={"small"}/>
                                    </IconButton>
                                </Grid>
                            )

                        }}

                    />

                </Grid>
            )
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Email',

            Footer: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Branch',
            Footer: 'Branch',

            accessor: 'branchDto.name'
        },
        {
            Header: 'Role',
            Footer: 'Role',
            Cell: (val: any) => {

                const original = val.row.original;
                let role = '';

                if(original?.role?.role === 'USER') {
                    role = original?.role?.makerChecker || '';
                }else{
                    role = original?.role?.role || ''
                }

                return <div>{role}</div>
            }
        }

    ]


    const path = '/getUsers'
    const {data, loading} = useServerCall({path, page, perPage})

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}`)


    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];

    const mockData = useMemo(() => calledData, [data]);

    const columns = useMemo(() => shareHolderColumns, [dashboard]);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <div>
                    {deleteId && (
                        <DeleteModal message={`Are you sure? Once deleted it cannot be reverted.`}
                                     trigger={`${path}/${page - 1}/${perPage}`}
                                     withVerification={true}
                                     deletePath={`/deleteUser/${deleteId}`} open={deleteId}
                                     handleDeleteCancel={() => setDeleteId(null)}
                        />
                    )}

                    {(disableId || enableId) && (
                        <DeleteModal message={disableId ? `Are you sure? User will be disabled.` : `Are you sure? User will be enabled.`}
                                     trigger={`${path}/${page - 1}/${perPage}`}
                                     deletePath={disableId ? `/disableUser/${disableId}`: `/enableUser/${enableId}`} open={disableId || enableId}
                                     disable={true}
                                     withVerification={true}

                                     enable={enableId!==null}
                                     handleDeleteCancel={() => disableId ? setDisableId(null) : setEnableId(null)}
                        />
                    )}


                    <ReactTable
                        data={mockData}
                        columns={columns}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        perPage={perPage}
                        totalPage={data?.t.totalPage}
                    />
                </div>

            ) : (
                (page === 1 && loading) ? <CircularSpinner circleColor={COLOR_PROFILE.buttonBlue}/>
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default UserRoleTable;
