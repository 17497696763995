import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Grid, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useServerCall from "../../../../accounting/organisms/reactTable/useServerCall";
import useSWR from "swr";
import ReactTable from "../../../../accounting/organisms/reactTable/ReactTable";
import DataEmptyMessage from "../../../../accounting/organisms/tables/dataEmptyMessage";

const InventoryAdjustmentTable: React.FC<any> =  (Props) => {
    const {formState} = Props;

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);

    const history = useHistory();

    const shareHolderColumns = [

        {
            id: 'delete',
            // Header: 'Actions',
            // width: 10,
            alignment: 'center',
            Cell: (val: any) => (
                <Grid container spacing={3} justify='center' style={{marginRight: 15, paddingRight: 15}}>


                    <Grid item>
                        {/*<IconButton size={"small"} style={{color: '#4aca53'}}*/}
                        {/*            onClick={() => {*/}
                        {/*            }}>*/}
                        {/*    <EditIcon fontSize={"small"}/>*/}
                        {/*</IconButton>*/}
                    </Grid>

                </Grid>
            )
        },
        {
            Header: 'Code',
            Footer: 'Code',
            accessor: 'adjustmentNumber'
        },

        {
            Header: 'Products',
            Footer: 'Products',
            Cell: (cellObj: any) => {
                let {row: {original}} =cellObj.cell
                return <div>{original.productSubs.map((item: any) => item.name + '('+ item.quantity+')').join(', ')}</div>
            }
        },

        {
            Header: 'Vendor',
            Footer: 'Vendor',
            accessor: 'vendorDto.name'
        },
        {
            Header: 'Description',
            Footer: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Adjusted By',
            Footer: 'Adjusted By',
            accessor: 'createdBy.name'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            Cell: (val: any) => (
                <div style={{fontWeight: 700}}>
                    {val.row.original?.approved ? 'Approved': 'Unapproved'}
                </div>
            )
        },
    ]

    const path = '/getAdjustedInventories'
    const {data, loading} = useServerCall({path, page, perPage,
        search: `?vendorId=${formState.vendor ? formState.vendor.id : 0}&from=${
            (formState.transactionYearAdFrom && formState.transactionMonthAdFrom && formState.transactionDateAdFrom) ? (formState.transactionYearAdFrom + '-' + ('0' + formState?.transactionMonthAdFrom).slice(-2) + '-' + ('0' + formState.transactionDateAdFrom).slice(-2)): ''
        }&to=${
            (formState.transactionYearAdTill && formState.transactionMonthAdTill && formState.transactionDateAdTill) ? (formState.transactionYearAdTill + '-' + ('0' + formState?.transactionMonthAdTill).slice(-2) + '-' + ('0' + formState.transactionDateAdTill).slice(-2)): ''
        }`
    })

    useEffect(() => {
        Props.setSubmitting(loading)
    }, [loading])

    const {data: dataSwr} = useSWR(`${path}/${page}/${perPage}?vendorId=${formState.vendor ? formState.vendor.id : 0}&from=${
        (formState.transactionYearAdFrom && formState.transactionMonthAdFrom && formState.transactionDateAdFrom) ? (formState.transactionYearAdFrom + '-' + ('0' + formState?.transactionMonthAdFrom).slice(-2) + '-' + ('0' + formState.transactionDateAdFrom).slice(-2)): ''
    }&to=${
        (formState.transactionYearAdTill && formState.transactionMonthAdTill && formState.transactionDateAdTill) ? (formState.transactionYearAdTill + '-' + ('0' + formState?.transactionMonthAdTill).slice(-2) + '-' + ('0' + formState.transactionDateAdTill).slice(-2)): ''
    }`)

    // @ts-ignore
    const calledData = data?.t.data ? data.t.data : [{}];
    const mockData = useMemo(() => calledData, [data]);
    const columns = useMemo(() => shareHolderColumns, []);

    return (
        <div>
            {!(mockData.length === 0 && page === 1) ? (
                <ReactTable
                    data={mockData}
                    perPageOption={[30, 50, 100]}
                    columns={columns}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={page}
                    perPage={perPage}
                    totalPage={data?.t.totalPage}
                />
            ) : (
                (page === 1 && loading) ? null
                    : <DataEmptyMessage/>
            )}
        </div>
    );
};

export default InventoryAdjustmentTable;