import React from 'react';
import PageTopBar from "../../../accounting/organisms/pageTopBar";
import DividerComponent from "../../../shared/atom/divider";
import ProductItemForm from "../../../product/organisms/form/productItem";
import PurchaseRfpForm from "../../organisms/forms/purchaseRfp";
import {useLocation, useParams} from "react-router-dom";

const PurchaseRfpAddition: React.FC = (Props) => {

    // @ts-ignore
    const {id} = useParams();
    const path = useLocation();
    const isEdit = path.pathname.includes('edit');
    const isView = !isEdit && id;

    return (
        <div>

            <PageTopBar name={ isEdit ? 'Edit Request For Proposal' : isView ? 'View Request For Proposal':'Add Request For Proposal'}/>

            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: '25px 0px 10px 25px', overflow: "scroll"}}>
                <div style={{maxWidth: 1130, paddingRight:20, paddingBottom: 50}}>
                    <PurchaseRfpForm mode={isEdit ? 'edit': isView ? 'view':'create'} from={'product-item'} id={id}/>
                </div>
            </div>
        </div>
    );
};

export default PurchaseRfpAddition;