import React, {useEffect, useState} from 'react';
import axiosInstance from "../../../../config/axiosConfig";
import {useDispatch} from "react-redux";
import AccessFilter from "../../../../accessControl/accessFilter";
import SetupAddNew from "../../../accounting/organisms/setupAddNew";
import SalesFilter from "../../organisms/forms/salesFilter";
import {Grid} from "@material-ui/core";
import Button from "../../../shared/atom/button";
import {COLOR_PROFILE} from "../../../../config/colorCode";
import CircularSpinner from "../../../shared/atom/spinner";
import EstimateTable from "../../organisms/tables/estimateTable";
import InvoiceTable from "../../organisms/tables/invoiceTable";
import useSWR from "swr";
import axios from "axios";
import {CloudDownload} from "@material-ui/icons";

const InvoiceHome = () => {
    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})
    const [checked, setChecked] = useState<any>([]);
    const [log, setLog] = useState('');

    const handleClick = (name: number) => {


        let checkedList = [...checked];

        if (checked.includes(name)) {
            checkedList.splice(checkedList.indexOf(name), 1)

        } else {
            checkedList.push(name)
        }

        setChecked(checkedList);

    }

    // const {data} = useSWR("/getLog")

    useEffect(() => {


    },[])



    const handleEnableDisable = async (item: any) => {

        if (checked.length === 0) return;

        try {

            setSubmitting(true);

            let payload = checked.map((item: any) => parseInt(item));

            const {data} = await axiosInstance.put(`/enableOrDisableProduct?action=enable}`, payload)

            if(data.code === 'OK') {
                setChecked([]);
                handleResponse(data.message, 'success')
            }   else{
                handleResponse(data.message, 'warning')
            }

            setSubmitting(false);

        } catch (e) {

            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')

        }

    }

    const dispatch = useDispatch();

    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    return (
        <div>

            <AccessFilter

                section={"accountGroup"}
                privilege={'write'}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>


                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/sales/invoice/add',
                                            name: 'Create Invoice'
                                        }}

                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>
                                                    Create Invoice by clicking the button below
                                                </div>
                                            </div>
                                        }

                                    />
                                </div>

                            </div>
                        </div>
                    )

                }}

            />

            <div style={{maxWidth: 1000}}>
                <div style={{padding: '0 30px 0 28px'}}>

                    <div style={{marginBottom: -10}}>
                        <SalesFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                     submitting={submitting} from={'invoice'}/>
                    </div>

                    {checked.length > 0 && (
                        <div
                            style={{
                                position: 'fixed',
                                bottom: 0,
                                zIndex: 100,
                                width: '100%',
                                paddingTop: 10,
                                background: '#fafafa',
                                paddingBottom: 20
                            }}
                        >
                            <Grid container justify={"flex-start"} spacing={2} alignItems={'center'}>
                                <Grid item>
                                    <div style={{maxWidth: 400}}>
                                        <Button variant={"contained"} customColor={COLOR_PROFILE.buttonBlue}
                                                textColor={'white'}
                                                disabled={submitting}
                                                onClick={handleEnableDisable}
                                                style={{width: 180}}
                                        >
                                            <span style={{fontSize: 15}}>
                                                Convert To Bill
                                            </span>
                                        </Button>
                                    </div>
                                </Grid>

                                <Grid item>
                                    {submitting &&
                                    <CircularSpinner size={30} circleColor={COLOR_PROFILE.buttonBlue}/>}
                                </Grid>

                            </Grid>
                        </div>
                    )}


                    <div style={{paddingBottom: checked.length > 0 ? 40: undefined}}>

                        <InvoiceTable handleClick={handleClick} checked={checked} formState={formState}
                                       setSubmitting={setSubmitting} submitting={submitting}
                        />
                    </div>
                </div>

            </div>

        </div>
    );
};

export default InvoiceHome;
