import React, {useState} from 'react';
import SetupAddNew from "../../../organisms/setupAddNew";
import JournalVoucherTable from "../../../organisms/tables/journalVoucher";
import AccessFilter from "../../../../../accessControl/accessFilter";
import TableFilter from "../../../organisms/Forms/tableFilter";

const ReceiptVoucher: React.FC = () => {

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})

    return (
        <div>

            <AccessFilter

                section={"receiptEntry"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return null

                    return (

                        <div>

                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/voucher-entries/receipt-voucher/add',
                                            name: 'Add Receipt'
                                        }}

                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>
                                                    Add Receipt Voucher by clicking on the button below:
                                                </div>

                                            </div>
                                        }

                                    />
                                </div>

                            </div>


                        </div>

                    )

                }}

            />


            <AccessFilter

                section={"receiptEntry"}
                privilege={"write"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return null

                    return (

                        <div style={{maxWidth: 1000}}>

                            <div style={{padding: '0 30px 0 28px'}}>

                                <div>
                                    <div style={{marginBottom: -10}}>
                                        <TableFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                                     submitting={submitting}
                                                     from={'payment'}
                                        />
                                    </div>

                                    <JournalVoucherTable type={'receipt'} formState={formState} setSubmitting={setSubmitting}/>
                                </div>

                            </div>
                        </div>

                    )

                }}

            />


        </div>
    );
};

export default ReceiptVoucher;
