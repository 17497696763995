import React from 'react';
import EstimateAddition from "../../templates/estimateAddition";

interface Props {
    from: string
}

const EstimateAdd: React.FC<Props> = ({from}) => {
    return (
        <div>
            <EstimateAddition from={from}/>
        </div>
    );
};

export default EstimateAdd;