import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {Field, Form, Formik} from 'formik';
import {Grid, Popper} from "@material-ui/core";
import * as Yup from "yup";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import InputField from "../../../../shared/atom/formElements/inputField";
import ErrorLabel from "../../../molecules/errorLabel";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import useSWR, {trigger} from "swr";
import {useDispatch} from "react-redux";
import axiosInstance from "../../../../../config/axiosConfig";
import {useHistory, useLocation} from "react-router-dom";
import {ACCOUNT_CODE_MAPPER} from "../../../../../utils/accountCodeMapper";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

interface Props {
    from: string
    mode: 'edit' | 'create',
    nextPath?: any
    handleClose?: any
    id?: any,
    noFixed?: boolean
}

interface productItem {
    productName: string,
    brandName?: string,
    productCode: string,
    category?: object | null,
    uom?: object | null,
    modelNo: string,
    purchasePrice: number | '',
    sellingPrice: number | '',
    reorderLevel: number | '',
    maxAllowedDiscount: number | '',
    description?: string,
    inventoryAssetAccount?: object | null,
    salesAccount?: object | null
}

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const PopperMy = function (props: any) {
    return (<Popper {...props} style={{width: 500}} placement='bottom-start'/>)
}

const ProductItemForm: React.FC<Props> = (Props) => {

    const initialValue: productItem = {
        productName: '',
        brandName: '',
        productCode: '',
        category: null,
        uom: null,
        modelNo: '',
        purchasePrice: '',
        sellingPrice: '',
        reorderLevel: '',
        maxAllowedDiscount: '',
        description: '',
        inventoryAssetAccount: null,
        salesAccount: null
    }

    const styles = useStyles();

    const {data: productCode} = useSWR('/getCode?name=product&id=0')
    const [formState, setFormState] = useState(initialValue);
    const {data: categoriesData} = useSWR('/getAllCategories');
    const {data: uomOptions} = useSWR('/getAllUoms')
    const {data: salesAccounts} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.directIncomeRevenue}`);
    const {data: inventoryAccount} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.inventoryAccount}`);
    const {data: assetAccount} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.assetAccount}`);
    const {data: indirectExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectExpenseAccount}`);
    const {data: directExpenseAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.directExpenseAccount}`);

    const location = useLocation();

    useEffect(() => {

        if (!categoriesData) return;
        if(!inventoryAccount) return;
        if(!salesAccounts) return;
        if(!assetAccount) return;
        if(!indirectExpenseAccounts) return;
        if(!directExpenseAccounts) return;

        let assetInvAccounts =  [...inventoryAccount.data.map((item: any) => {
            return {...item, isInventory: true}
        }), ...assetAccount.data.map((item: any) => {
            return {...item, subtypename: 'Fixed Asset', isInventory: false, headname: item.name}
        }), ...indirectExpenseAccounts.data.map((item: any) => {
            return {...item, subtypename: 'Indirect Expense', isExpense: true, headname: item.name}
        }),...directExpenseAccounts.data.map((item: any) => {
            return {...item, subtypename: 'Direct Expense', isExpense: true, headname: item.name}
        })]

        if (Props.id) {

            // @ts-ignore
            const { assetAccount,brandName,modelNumber,inventoryAccount, salesAccount, buyingPrice, categoryDto, code, description, discount, maxAllowableDiscount,expenseAccount, reOrderLevel, name, quantity, sellingPrice, tax, uom} = location.state


            setFormState({
                productName: name,
                brandName: brandName,
                productCode: code,
                category: categoryDto,
                uom: uom,
                modelNo: modelNumber,
                purchasePrice: buyingPrice,
                sellingPrice: sellingPrice,
                reorderLevel: reOrderLevel,
                maxAllowedDiscount: maxAllowableDiscount,
                description: description,
                inventoryAssetAccount: assetInvAccounts ? assetInvAccounts.find((itemHead: any) => itemHead.id === (assetAccount ? assetAccount?.id : inventoryAccount ? inventoryAccount?.id: expenseAccount?.id)): null,
                salesAccount: salesAccounts ? salesAccounts.data.find((item: any) => item.id === salesAccount?.id) : null

            })
        }
    }, [categoriesData, inventoryAccount, assetAccount, salesAccounts, directExpenseAccounts, indirectExpenseAccounts])

    useEffect(() => {

        if (Props.id) return;

        if (productCode) setFormState(prevState => {
            return {
                ...prevState,
                productCode: productCode.t.code
            }
        })

    }, [productCode])


    const validationSchema = Yup.object({
        productName: Yup.string().required('Required'),
        category: Yup.object().required('Required').nullable(true),
        // inventoryAssetAccount: Yup.object().required('Required').nullable(true),
        // salesAccount: Yup.object().required('Required').nullable(true)
    })

    const onSubmit = async (values: any, formActions: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {

            let payload = {
                name: values.productName,
                brandName: values.brandName,

                //todo this is missing in update payload
                code: values.productCode,
                modelNumber: values.modelNo,

                //todo uom is not accepted by server
                uom: values.uom,
                reOrderLevel: values.reorderLevel,
                description: values.description,
                buyingPrice: values.purchasePrice,
                sellingPrice: values.sellingPrice,
                maxAllowableDiscount: values.maxAllowedDiscount,
                categoryDto: values.category ? {id: values.category.id} : {id: 0},
                assetAccount: values.inventoryAssetAccount? (values.inventoryAssetAccount.isInventory ) ? null : (!values.inventoryAssetAccount.isExpense) ? {id: values.inventoryAssetAccount.id}: null: null,
                inventoryAccount: values.inventoryAssetAccount ? values.inventoryAssetAccount.isInventory ? {id: values.inventoryAssetAccount.id} : null: null,
                salesAccount:values.salesAccount ?  {id: values.salesAccount.id}: null,
                expenseAccount: values.inventoryAssetAccount ? values.inventoryAssetAccount.isExpense ? {id: values.inventoryAssetAccount.id}: null: null
            }
            //
            // console.log(payload)
            // throw new Error('asdf');

            if (Props.id) {
                const {data} = await axiosInstance.put('/updateProduct', {id: parseInt(Props.id), ...payload});
                responseProcess(data, formActions, isReset)
            } else {
                const {data} = await axiosInstance.post('/addProduct', payload);
                responseProcess(data, formActions, isReset)
            }
        } catch (e) {
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }
    }

    const history = useHistory();
    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            trigger('/getProducts')
            trigger('/getProductsBundlesAndOffers')
            formActions.resetForm();
            if (!isReset) {

                Props.handleClose ?
                    Props.handleClose() :
                    history.replace('/dashboard/product/product-item')

            }else {
                trigger('/getCode?name=product&id=0')
            }
        } else {
            handleResponse(data.message, 'warning')
        }
    }

    const dispatch = useDispatch();
    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>

            <Formik
                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {
                    return (
                        <div>
                            <PagePrompt formik={formik}/>
                            <Form style={{margin: 20, paddingBottom: 25}} onKeyDown={onKeyDown}>
                                <Grid container justify={"space-between"}>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="productCode"
                                                   className={styles.label}> Product Code:</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div className={styles.label}>
                                                {formik.values.productCode}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="productName"
                                                   className={styles.label}> Product Name:<RequiredNotation/></label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'productName'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'text'}
                                                                            id={'productName'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="category"
                                                   className={styles.label}> Category:<RequiredNotation/></label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'category'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={'category'} {...field}
                                                                                   size={"small"}
                                                                                   onChange={(e, value) => form.setFieldValue("category", value)}
                                                                                   loading={!categoriesData}
                                                                                   options={categoriesData ? categoriesData.data : []}
                                                                                   getOptionLabel={option => option.name}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="brandName"
                                                   className={styles.label}> Brand Name:</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'brandName'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'text'}
                                                                            id={'brandName'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="uom"
                                                   className={styles.label}> UOM:</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'uom'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={'uom'} {...field}
                                                                                   size={"small"}
                                                                                   onChange={(e, value) => form.setFieldValue("uom", value)}
                                                                                   loading={!uomOptions}
                                                                                   options={uomOptions ? uomOptions.data : []}
                                                                                   getOptionLabel={option => option.name}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="modelNo"
                                                   className={styles.label}> Model No.:</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'modelNo'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'text'}
                                                                            id={'modelNo'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="purchasePrice"
                                                   className={styles.label}> Purchase Price:</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'purchasePrice'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'number'}
                                                                            id={'purchasePrice'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="maxAllowedDiscount"
                                                   className={styles.label}> Maximum Allowed Discount(%):</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'maxAllowedDiscount'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'number'}
                                                                            id={'maxAllowedDiscount'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="sellingPrice"
                                                   className={styles.label}> Selling Price:</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'sellingPrice'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'number'}
                                                                            id={'sellingPrice'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="reorderLevel"
                                                   className={styles.label}> Reorder Level:</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'reorderLevel'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'number'}
                                                                            id={'reorderLevel'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>


                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="description"
                                                   className={styles.label}> Description:</label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'description'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <InputField type={'text'}
                                                                            multiline={true}
                                                                            rows={3}
                                                                            id={'description'} {...field}
                                                                            size={"small"}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={12} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <div style={{marginLeft: 7, fontSize: 16, fontWeight: 700, color: COLOR_PROFILE.primaryColor}}>
                                            <u>Accounts:</u>
                                        </div>
                                    </Grid>

                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="inventoryAssetAccount"
                                                   className={styles.label}> Inventory/ Asset/ Expense Account:
                                            </label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'inventoryAssetAccount'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`inventoryAssetAccount`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`inventoryAssetAccount`, value)
                                                                                   }}

                                                                                   options={(inventoryAccount && assetAccount && indirectExpenseAccounts && directExpenseAccounts) ?
                                                                                       [...inventoryAccount.data.map((item: any) => {
                                                                                           return {...item, isInventory: true}
                                                                                       }), ...assetAccount.data.map((item: any) => {
                                                                                           return {...item, subtypename: 'Fixed Asset', isInventory: false, headname: item.name}
                                                                                       }), ...indirectExpenseAccounts.data.map((item: any) => {
                                                                                           return {...item, subtypename: 'Indirect Expense', isExpense: true, headname: item.name}
                                                                                       }),...directExpenseAccounts.data.map((item: any) => {
                                                                                           return {...item, subtypename: 'Direct Expense', isExpense: true, headname: item.name}
                                                                                       })]
                                                                                       : []}
                                                                                   loading={!inventoryAccount && !assetAccount && !indirectExpenseAccounts && !directExpenseAccounts}

                                                                                   groupBy={option => option.subtypename}
                                                                                   PopperComponent={PopperMy}
                                                                                   getOptionLabel={option => option.headname}
                                                                                   renderOption={(option) => {
                                                                                       return (
                                                                                           <div>
                                                                                               <div style={{
                                                                                                   fontSize: 11,
                                                                                               }}>
                                                                                                   <>{option.code}</>
                                                                                               </div>
                                                                                               <div>{option.headname}</div>
                                                                                           </div>
                                                                                       )
                                                                                   }}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>



                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                          style={{marginBottom: 15}}>
                                        <Grid item xs={4}>
                                            <label htmlFor="salesAccount"
                                                   className={styles.label}> Sales Account:
                                            </label>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{position: 'relative'}}>
                                                <Field name={'salesAccount'}>
                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                        const {field, form, meta} = props;
                                                        return (
                                                            <>
                                                                <AutoCompleteInput type={'text'}
                                                                                   id={`salesAccount`} {...field}
                                                                                   size={"small"}
                                                                                   autoHighlight={true}
                                                                                   autoSelect={true}
                                                                                   onChange={(e, value) => {
                                                                                       form.setFieldValue(`salesAccount`, value)
                                                                                   }}

                                                                                   options={salesAccounts ? salesAccounts.data : []}
                                                                                   loading={!salesAccounts}

                                                                                   groupBy={option => option.subtypename}
                                                                                   PopperComponent={PopperMy}
                                                                                   getOptionLabel={option => option.headname}
                                                                                   renderOption={(option) => {
                                                                                       return (
                                                                                           <div>
                                                                                               <div style={{
                                                                                                   fontSize: 11,
                                                                                               }}>
                                                                                                   <>{option.code}</>
                                                                                               </div>
                                                                                               <div>{option.headname}</div>
                                                                                           </div>
                                                                                       )
                                                                                   }}
                                                                                   error={meta.touched && meta.error}
                                                                />
                                                                <ErrorLabel meta={meta}/>
                                                            </>
                                                        )
                                                    }}
                                                </Field>

                                            </div>
                                        </Grid>
                                    </Grid>


                                </Grid>


                                <FormSaveButton mode={Props.mode} from={Props.from} submitting={formik.isSubmitting}
                                                handleClose={Props.handleClose}
                                                noFixed={Props.noFixed}
                                />

                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    );
};

export default ProductItemForm;
