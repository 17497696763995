import React, {useState} from 'react';
import AccessFilter from "../../../../accessControl/accessFilter";
import SetupAddNew from "../../../accounting/organisms/setupAddNew";
import ProductFilter from "../../organisms/form/filter";
import ProductBundleTable from "../../organisms/tables/productBundleTable";
import InventoryLevelTable from "../../organisms/tables/inventoryLevelTable";
import {COLOR_PROFILE} from "../../../../config/colorCode";

const InventoryLevelHome = () => {

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})

    return (
        <div>
            <div style={{
                marginLeft: 30,
                padding: '20px 0',
                fontSize: 18,
                fontWeight: 700,
                color: COLOR_PROFILE.primaryColor
            }}>
                Inventory Level
            </div>

            <div style={{maxWidth: 1000}}>
                <div style={{padding: '0 30px 0 28px'}}>

                    <div style={{marginBottom: -10}}>
                        <ProductFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                       submitting={submitting} from={'inventory-level'}/>
                    </div>


                    <InventoryLevelTable formState={formState} setSubmitting={setSubmitting}/>
                </div>
            </div>
        </div>
    );
};

export default InventoryLevelHome;