import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/RootReducer";
import useSWR from "swr";
import FromSubTypes from "../../templates/reporting/fromSubTypes";
import {ACCOUNT_CODE_MAPPER} from "../../../../utils/accountCodeMapper";
import {getTodaysDate} from "../../../../config/dateFunctions";

const CategoryReport: React.FC<any> = (Props) => {
    // @ts-ignore
    const {id} = useParams();
    // const id: any= '1';

    const filterFormData = useSelector((state: RootState) => state.reportFilterReducer);

    const [fromDate, setFromDate] = useState(Props.isChartOfAccount? Props.startDate: null)
    const [tillDate, setTillDate] = useState(Props.isChartOfAccount? Props.endDate: null);
    const [branchId, setBranchId] = useState(null);
    const [total, setTotal] = useState('');
    const [grandSum, setGrandSum] = useState({credit: 0, debit: 0})

    useEffect(() => {
        if(!Props.startDate) return;
        if(!Props.endDate) return;
        if(Props.isChartOfAccount) return;

        // @ts-ignore
        setFromDate((filterFormData.transactionYearAdFrom && filterFormData.transactionMonthAdFrom && filterFormData.transactionDateAdFrom) ? filterFormData.transactionYearAdFrom + '-' + ('0' + filterFormData.transactionMonthAdFrom).slice(-2) + '-' + ('0' + filterFormData.transactionDateAdFrom).slice(-2) : Props.startDate);
        // @ts-ignore
        setTillDate((filterFormData.transactionYearAdTill && filterFormData.transactionMonthAdTill && filterFormData.transactionDateAdTill) ? filterFormData.transactionYearAdTill + '-' + ('0' + filterFormData.transactionMonthAdTill).slice(-2) + '-' + ('0' + filterFormData.transactionDateAdTill).slice(-2) : Props.endDate);
        setBranchId(Props.branch ? Props.branch.id : null)
        Props.setSubmitting(true)

    }, [Props.endDate, Props.startDate, Props.branch])

    const {data: categoryData} = useSWR("/getAllAccountGroups");

    const {data: getData} = useSWR(
        categoryData ?
                `/report/getAccountGroupAndBalance?from=${fromDate|| ''}&to=${tillDate|| getTodaysDate()}&id=${categoryData.data.find((item: any) => item.code === (Props.type === "payable" ?ACCOUNT_CODE_MAPPER.payableGroup:  ACCOUNT_CODE_MAPPER.receivablesGroup)).id}&branchId=${branchId}`
                // `/report/getAccountGroupAndBalance?from=${fromDate|| ''}&to=${tillDate|| ''}&id=${3}&branchId=${branchId}`
            : null

    )

    useEffect(() => {
        if (!getData) return;
        let temp: any= getData
        console.log([...temp.data, ...temp.t.child])
        // Props.setSubmitting(false)
    }, [getData])

    useEffect(() => {

        // return;

        if (id !== 'home') return;
        if(Props.isChartOfAccount) return;

        if (!getData) return;

        let filteredData = getData.data.map((item: any) => item.accountSubTypes)

        let sumPerType = filteredData.map((item: any) => {
            return {
                sumDebit: getSum(item, 'debit'),
                sumCredit: getSum(item, 'credit')
            }
        })

        // @ts-ignore
        setGrandSum({
            debit: getGrandTotal(sumPerType, 'sumDebit'),
            credit: getGrandTotal(sumPerType, 'sumCredit')
        })

        let val = 0;
        Object.keys(getData.t).map((item: any) => {
            val += getData.t[item];
            return item

        })
        setTotal(val > 0 ? Math.abs(val) + ' Dr' : Math.abs(val) + ' Cr');
        // Props.setTotal(getGrandTotal(sumPerType, 'sumDebit'));

    }, [getData])

    const getGrandTotal = (tableData: any, type: string) => {
        return tableData.reduce((result: number, item: any) => {
            return (result + parseFloat(item[type]))
        }, 0.00)
    }

    const getSum = (tableData: any, type: string) => {

        if(!tableData) return ;
        return tableData.reduce((result: number, item: any) => {
            if (type === 'debit') {
                return (item.balance > 0 ? result + parseFloat(item.balance) : result)
            } else {
                return (item.balance < 0 ? result + parseFloat(item.balance) : result)
            }
        }, 0.00)
    }


    return (
        <div>
            {getData && (
                <div >
                <div style={{paddingBottom: 0}}>
                    {[...getData.data, ...getData.t.child].length  === 0 ?
                    <div style={{paddingLeft: 20}}>
                        No data present!
                    </div>
                        :
                        <FromSubTypes
                            from={'trial-balance/table'}
                            withTwoHead={true}
                            isChartOfAccount={Props.isChartOfAccount}
                            fromDate={fromDate}
                            tillDate={tillDate}
                            withOutHeadBackground={true}
                            getData={getData ? [...getData.data, ...getData.t.child] : []}
                            type={Props.type === 'receivables' ? 'Receivables': 'Payables'}
                        />
                    }


                </div>
                </div>



            )}
        </div>
    )
};

export default CategoryReport;
