import React from 'react';
import ReturnHome from "../../templates/returnHome";

const Return = () => {
    return (
        <div>
            <ReturnHome/>
        </div>
    );
};

export default Return;