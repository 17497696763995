import {combineReducers} from "redux";
import dashboardReducer from "./dashboard";
import snackBarReducer from "./snackBar";
import ledgerDataReducer from "./ledgerData";
import ReportFilterReducer from "./reportFilter";
import selectedStatus from "./selectedStatus";
import formState from "./formState";

export const rootReducer = combineReducers({
    dashboard: dashboardReducer,
    snackBar: snackBarReducer,
    ledgerData: ledgerDataReducer,
    reportFilterReducer: ReportFilterReducer,
    selectedStatus: selectedStatus,
    formState: formState
});

export type RootState = ReturnType<typeof rootReducer>
