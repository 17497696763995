import {Route} from "react-router-dom";
import {motion} from "framer-motion";
import AccessFilter from "../../../accessControl/accessFilter";
import AccessDenied from "../../../components/accounting/templates/errorTemplates/accessDenied";
import JournalVoucher from "../../../components/accounting/virtualPages/voucherEntries/journalVoucher";
import AddJournalVoucher from "../../../components/accounting/virtualPages/voucherEntries/addJournalVoucher";
import ReceiptVoucher from "../../../components/accounting/virtualPages/voucherEntries/receiptVoucher";
import AddReceiptVoucher from "../../../components/accounting/virtualPages/voucherEntries/addReceiptVoucher";
import PaymentVoucher from "../../../components/accounting/virtualPages/voucherEntries/paymentVoucher";
import AddPaymentVoucher from "../../../components/accounting/virtualPages/voucherEntries/addPaymentVoucher";
import React from "react";

export const AccountingEntriesRoutes = [
    <Route path={'/dashboard/voucher-entries'} exact>
        <div>
            voucher entries
        </div>
    </Route>,

    <Route path={'/dashboard/voucher-entries/journal-voucher'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >

            <AccessFilter
                section={"journalEntry"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>

                            <div>
                                <JournalVoucher/>
                            </div>

                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/voucher-entries/journal-voucher/add'} exact>

        <AccessFilter

            section={"journalEntry"}
            privilege={"write"}
            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                if (!isAllowed) return <AccessDenied/>

                return (

                    <div>
                        <div>
                            <AddJournalVoucher/>
                        </div>
                    </div>

                )

            }}

        />
    </Route>,

    <Route path={'/dashboard/voucher-entries/journal-voucher/edit/:id'} exact>

        <AccessFilter

            section={"journalEntry"}
            privilege={"update"}
            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                if (!isAllowed) return <AccessDenied/>

                return (

                    <div>
                        <div>
                            <AddJournalVoucher/>
                        </div>
                    </div>

                )

            }}

        />
    </Route>,

    <Route path={'/dashboard/voucher-entries/receipt-voucher'} exact>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"receiptEntry"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <ReceiptVoucher/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/voucher-entries/receipt-voucher/add'} exact>

        <AccessFilter

            section={"receiptEntry"}
            privilege={"write"}
            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                if (!isAllowed) return <AccessDenied/>

                return (

                    <div>
                        <div>
                            <AddReceiptVoucher/>
                        </div>
                    </div>

                )

            }}

        />
    </Route>,

    <Route path={'/dashboard/voucher-entries/receipt-voucher/edit/:id'} exact>

        <AccessFilter

            section={"receiptEntry"}
            privilege={"update"}
            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                if (!isAllowed) return <AccessDenied/>

                return (

                    <div>
                        <div>
                            <AddReceiptVoucher/>
                        </div>
                    </div>

                )

            }}

        />
    </Route>,

    <Route path={'/dashboard/voucher-entries/payment-voucher'} exact>

        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.08}}

        >
            <AccessFilter

                section={"paymentEntry"}
                privilege={"read"}
                render={(isAllowed: boolean, hasAllAccess: boolean) => {

                    if (!isAllowed) return <AccessDenied/>

                    return (

                        <div>
                            <div>
                                <PaymentVoucher/>
                            </div>
                        </div>

                    )

                }}

            />
        </motion.div>
    </Route>,

    <Route path={'/dashboard/voucher-entries/payment-voucher/add'} exact>

        <AccessFilter

            section={"paymentEntry"}
            privilege={"write"}
            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                if (!isAllowed) return <AccessDenied/>

                return (

                    <div>
                        <div>
                            <AddPaymentVoucher/>
                        </div>
                    </div>

                )

            }}

        />
    </Route>,

    <Route path={'/dashboard/voucher-entries/payment-voucher/edit/:id'} exact>

        <AccessFilter

            section={"paymentEntry"}
            privilege={"update"}
            render={(isAllowed: boolean, hasAllAccess: boolean) => {

                if (!isAllowed) return <AccessDenied/>

                return (

                    <div>
                        <div>
                            <AddPaymentVoucher/>
                        </div>
                    </div>

                )

            }}

        />
    </Route>
]