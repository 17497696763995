import React, {useState} from 'react';
import AccessFilter from "../../../../accessControl/accessFilter";
import SetupAddNew from "../../../accounting/organisms/setupAddNew";
import ProductItemTable from "../../organisms/tables/productItemTable";
import ProductOfferTable from "../../organisms/tables/productOfferTable";
import ProductFilter from "../../organisms/form/filter";

const ProductOfferHome = () => {

    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({search: ''})

    return (
        <div>

            <AccessFilter

                section={"salesOffer"}
                privilege={'write'}
                render={(isAllowed: boolean, hasAllAccess: boolean, isBranchAdmin: boolean) => {

                    if (!isAllowed) return null

                    return (
                        <div>


                            <div style={{maxWidth: 1000}}>
                                <div style={{padding: 20}}>
                                    <SetupAddNew

                                        buttonNameAdd={{
                                            pathTo: '/dashboard/product/product-sales-offer/add',
                                            name: 'Add Sales/Offer'
                                        }}


                                        message={
                                            <div style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                padding: '10px 10px 10px 5px'
                                            }}>
                                                <div>Add Sales/Offer by clicking the button below
                                                </div>

                                            </div>
                                        }

                                    />
                                </div>

                            </div>
                        </div>
                    )

                }}

            />

            <div style={{maxWidth: 1000}}>
                <div style={{padding: '0 30px 0 28px'}}>

                    <div style={{marginBottom: -10}}>
                        <ProductFilter setFormState={setFormState} setSubmitting={setSubmitting}
                                       submitting={submitting} from={'offer'}/>
                    </div>

                    <ProductOfferTable formState={formState} setSubmitting={setSubmitting}/>
                </div>
            </div>

        </div>
    );
};

export default ProductOfferHome;