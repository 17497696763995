import React, {useCallback, useEffect, useRef, useState} from 'react';
import useSWR, {trigger} from "swr";
import {useHistory, useLocation} from "react-router-dom";
import * as Yup from "yup";
import axiosInstance from "../../../../../config/axiosConfig";
import {useDispatch} from "react-redux";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Divider, Grid, Popper} from "@material-ui/core";
import RequiredNotation from "../../../../accounting/molecules/requiredNotation";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import ErrorLabel from "../../../../product/molecules/errorLabel";
import InputField from "../../../../shared/atom/formElements/inputField";
import DateComponent from "../../../../product/molecules/dateComponent";
import FormSaveButton from "../../../../accounting/organisms/FormSaveButton";
import {makeStyles} from "@material-ui/core/styles";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import ReturnProducts from "./returnProducts";
import {getTaxAmount} from "../../../../../utils/getTax";
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs} from "../../../../../config/dateFunctions";
import {ACCOUNT_CODE_MAPPER} from "../../../../../utils/accountCodeMapper";
import PagePrompt from "../../../../shared/organisms/pagePrompt";
import EstimatePrintView from "../estimate/estimatePrintView";
import {useReactToPrint} from "react-to-print";
import ReportActionButton from "../../../../accounting/organisms/reportActionButton";
import ReturnPrintView from "./returnPrintView";
import {getInvoiceAmounts} from "../../../../../utils/invoiceAmounts";
import {getFormattedDate} from "../../../../../utils/dateFunction";
import DialogComponent from "../../../../shared/atom/dialog";
import ProductItemForm from "../../../../product/organisms/form/productItem";
import CustomerForm from "../../../../accounting/organisms/Forms/customerForm";
import AccountHeadForm from "../../../../accounting/organisms/Forms/accountHeadForm";
import InvoiceCancelForm from "../invoice/invoiceCancelForm";
import InvoicePrintViewSuvam from "../invoice/invoicePrintViewSuvam";
import {matchSorter} from "match-sorter";
import ToolTip from "../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import EstimateProductsFormNonTaxable from "../estimate/estimateProductNonTaxable";
import EstimateProductsForm from "../estimate/estimateProducts";

interface Props {
    from: string
    mode: 'edit' | 'create' | 'view',
    nextPath?: any,
    id ?: string | null

}

const useStyles = makeStyles((theme: any) => ({
    label: {
        fontWeight: "bold",
        fontSize: 14, color:
        COLOR_PROFILE.primaryColor
    }
}))

const PopperMy = function (props: any) {
    return (<Popper {...props} style={{width: 500}} placement='bottom-start'/>)
}

const ReturnAdditionForm: React.FC<Props> = (Props) => {

    const initialState = {
        transactionYearAd: null,
        transactionMonthAd: null,
        transactionDateAd: null,
        transactionYearBs: null,
        transactionMonthBs: null,
        transactionDateBs: null,

        salesReturnNumber: '',
        panNumber: '',
        customer: null,
        paymentStatus: 'Full',
        paymentMode: null,
        amountPaid: '',
        chequeNumber: '',
        // referenceBillNumber: '',
        document: '',
        description: '',

        //for sugam logistics
        consignmentYearAd: null,
        consignmentMonthAd: null,
        consignmentDateAd: null,
        consignmentYearBs: null,
        consignmentMonthBs: null,
        consignmentDateBs: null,

        consignmentNo: '',
        consignmentEx: '',
        consignmentVia: '',
        consignmentTo: '',
        noOfPackages: 1,

        referenceInvoiceNumber: null,
        reasonForReturn: "",



        //for invoice

        fiscalYear: '',


        tableRow: [
            {
                id: 1,
                product: null,
                quantity: 1,
                uom: null,
                price: 0,
                discount: 0,
                tax: 13,
                amount: 0
            },
            {
                id: 2,
                product: null,
                quantity: 1,
                uom: null,
                price: 0,
                discount: 0,
                tax: 13,
                amount: 0
            },
        ],

        tableRowNonTaxable: [
            {
                id: 1,
                product: null,
                quantity: 1,
                uom: null,
                price: 0,
                discount: 0,
                tax: 0,
                amount: 0
            },
            {
                id: 2,
                product: null,
                quantity: 1,
                uom: null,
                price: 0,
                discount: 0,
                tax: 0,
                amount: 0
            },
        ],

        tableRowAdditional: [
            {id: 1, product: null, quantity: 0, uom: null, price: 0, discount: 0, tax: 0, amount: 0},
            {id: 2, product: null, quantity: 0, uom: null, price: 0, discount: 0, tax: 0, amount: 0},
        ],
        tds: [
            {id: 1, item: null, product: null, taxableAmount: 0, tax: 0, amount: 0},
            // {id: 2, item: null, product: null, taxableAmount: 0, tax: 0, amount: 0},
        ],



    }

    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths} = useAdAndBsHandler();

    const convert = (transactionDate: any) => {

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            transactionYearAd: adDate[0],
            transactionDateAd: adDate[2],
            transactionMonthAd: adDate[1],
            transactionDateBs: bsDate[2],
            transactionMonthBs: bsDate[1],
            transactionYearBs: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }



    useEffect(() => {

        if (!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])

    }, [formatedNepEngDates])

    const {data: invoiceCode} = useSWR('/getCode?name=salesReturn&id=0')

    useEffect(() => {

        if(Props.id) return;

        if (invoiceCode) setFormState(prevState => {
            return {
                ...prevState,
                salesReturnNumber: invoiceCode.t.code
            }
        })

    }, [invoiceCode])

    const [openDialogProduct, setOpenDialogProduct] = useState(false)
    const [openDialogHead, setOpenDialogHead] = useState(false)
    const [openDialogCustomer, setOpenDialogCustomer] = useState(false)
    const [cancelInv, setCancelInv] = useState(false);
    const [invoiceId, setInvoiceId] = useState(0);


    const [formState, setFormState] = useState(initialState);

    const location = useLocation();
    const styles = useStyles();

    const validationSchema = Yup.object({
        customer: Yup.object().required("Required").nullable(true),
        paymentStatus: Yup.string().required('Required').nullable(true),
        paymentMode: Yup.object().required("Required").nullable(true),
        referenceInvoiceNumber: Yup.object().required("Required").nullable(true),
        amountPaid: Yup.number().when("paymentStatus", {
            is: 'Unpaid',
            then: Yup.number().max(0, "Must be 0"),
            otherwise: Yup.number().min(1, "Must be greater than 0").required("Required")
        })
    })

    const {data: accountForSales} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.directIncomeRevenue}`)
    const {data: customerOptions} = useSWR('/getAllCustomers');
    const {data: billMinimalOptions} = useSWR('/getAllInvoices');
    const {data: invoiceById} = useSWR(invoiceId >0  ? `/getInvoiceById/${invoiceId}`: null);
    const {data: paymentModeOptions} = useSWR('/getHeadsForPayment');
    const {data: headOptionsInCategory} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.inventoryAccount},${ACCOUNT_CODE_MAPPER.indirectExpenseAdministrativeExpense},${ACCOUNT_CODE_MAPPER.currentLiabilites}`);
    const {data: headOptionsInCategoryVat} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.currentAssetInventory},${ACCOUNT_CODE_MAPPER.indirectExpenseAdministrativeExpense},${ACCOUNT_CODE_MAPPER.currentLiabilites},${ACCOUNT_CODE_MAPPER.currentAssetAdvanceTax}`);

    const {data: productAndBundleOptions} = useSWR('/getProductsBundlesAndOffers');
    const {data: tdsOptions} = useSWR(`/getHeadByGroup?groups=${ACCOUNT_CODE_MAPPER.currentAssetAdvanceTax}`);
    const {data: additionalChargeAccounts} = useSWR(`/getAccountHeadForSubtype/${ACCOUNT_CODE_MAPPER.indirectIncomeAccount}`);

    const [canceled, setCanceled] = useState(false);

    useEffect(() => {

        let nonTaxableItem = ['Indian Freight', 'Nepal Freight'];
        // let taxableItem = ['Indian Customs', 'Nepal Customs', 'Sur-Charges', 'Detention Charges', 'Misc.', 'Demurrage'];
        let taxableItem = ['Customs', 'Charges'];
        if(!productAndBundleOptions) return;
        if(Props.id) return;
        let nonTaxable = productAndBundleOptions?.data.filter((item: any) => nonTaxableItem.includes(item.productname)).map((item: any, index: number) => {
            return {
                id: index,
                product: {...item, name: 'Product'},
                quantity: 1,
                uom: null,
                price: 0,
                discount: 0,
                tax: 0,
                amount: 0
            }
        })

        let taxable = productAndBundleOptions?.data.filter((item: any) => taxableItem.find((itemArray: any) => item.productname.includes(itemArray))).map((item: any, index: number) => {
            return {
                id: index,
                product:  {...item, name: 'Product'},
                quantity: 1,
                uom: null,
                price: 0,
                discount: 0,
                tax: 13,
                amount: 0
            }
        })

        console.log('taxable',taxable)

        setFormState(prevState => {
            return{
                ...prevState,
                // tableRowNonTaxable: [...nonTaxable],
                // tableRow: [...taxable]
            }
        })

    }, [productAndBundleOptions])


    useEffect(() => {
        if(!formatedNepEngDates) return;
        if(!productAndBundleOptions) return;
        if(!paymentModeOptions) return;
        if(!additionalChargeAccounts) return;
        if(!tdsOptions) return;

        setFormState((prevState: any) => {
            return {...prevState, paymentMode: paymentModeOptions.data.find((item: any) => item.code === 'CA-201-001')}
        })

        if (Props.id) {


            // @ts-ignore
            const {customer,transactionDate,salesReturnNumber,expiryDate,amountPaid, canceled,paymentMode,paymentStatus,chequeNumber,description,productBundleSubs,journalVoucherDto, consignmentDate, consignmentNo, consignmentEx, consignmentVia, consignmentTo,noOfPackages} = location.state
            handleInitialPopulate(location.state, additionalChargeAccounts, true);
        }



    }, [formatedNepEngDates, productAndBundleOptions,paymentModeOptions,tdsOptions, additionalChargeAccounts])

    useEffect(() => {
        if(!formatedNepEngDates) return;
        if(!productAndBundleOptions) return;
        if(!paymentModeOptions) return;
        if(!additionalChargeAccounts) return;
        if(!tdsOptions) return;
        if(!invoiceById) return;
        setFormState((prevState: any) => {
            return {...prevState, paymentMode: paymentModeOptions.data.find((item: any) => item.code === 'CA-201-001')}
        })
        handleInitialPopulate(invoiceById, additionalChargeAccounts, false);

    }, [formatedNepEngDates, productAndBundleOptions,paymentModeOptions,tdsOptions, additionalChargeAccounts,invoiceById])



    const handleInitialPopulate = (values: any, additionalChargeAccounts: any, newReturn: boolean) => {

        let additionalChargeAccountsId = additionalChargeAccounts.data.map((item: any) => item.id);

        console.log('values', values)
        const {customer,transactionDate,salesReturnNumber,expiryDate,amountPaid, canceled,paymentMode,paymentStatus,chequeNumber,description,productBundleSubs,journalVoucherDto, consignmentDate, consignmentNo, consignmentEx, consignmentVia, consignmentTo,noOfPackages} = values;
        setCanceled(canceled);

        let adTransactionDate = transactionDate.split('-');
        let bsTransactionDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

        let adExpiryDate = expiryDate ? expiryDate.split('-') : [];
        let bsExpiryDate: any = convertAdToBs(expiryDate, formatedNepEngDates) || [];

        let adConsignmentDate = consignmentDate ? consignmentDate.split('-'): [];
        let bsConsignmentDate: any = convertAdToBs(consignmentDate, formatedNepEngDates) || [];

        // @ts-ignore
        let tempJson = {
            transactionYearAd: adTransactionDate[0],
            transactionDateAd: adTransactionDate[2],
            transactionMonthAd: adTransactionDate[1],
            transactionDateBs: bsTransactionDate[2],
            transactionMonthBs: bsTransactionDate[1],
            transactionYearBs: bsTransactionDate[0],

            expiryYearAd: adExpiryDate[0],
            expiryDateAd: adExpiryDate[2],
            expiryMonthAd: adExpiryDate[1],
            expiryDateBs: bsExpiryDate[2],
            expiryMonthBs: bsExpiryDate[1],
            expiryYearBs: bsExpiryDate[0],

            consignmentYearAd: adConsignmentDate[0],
            consignmentMonthAd: adConsignmentDate[1],
            consignmentDateAd: adConsignmentDate[2],
            consignmentYearBs: bsConsignmentDate[0],
            consignmentMonthBs: bsConsignmentDate[1],
            consignmentDateBs: bsConsignmentDate[2],


        }

        // @ts-ignore
        let numbersReturnAndInvoice = !newReturn ? {salesReturnNumber: returnNumber, referenceInvoiceNumber: invoiceObject}: {}

        // @ts-ignore
        setFormState({
            ...formState,
            ...tempJson,
            salesReturnNumber: salesReturnNumber,
            ...numbersReturnAndInvoice,
            chequeNumber: chequeNumber,
            paymentStatus: paymentStatus,
            amountPaid: description,
            customer: customer,
            panNumber: customer.panNumber,
            description: description,
            consignmentNo: consignmentNo,
            consignmentEx: consignmentEx,
            consignmentVia: consignmentVia,
            consignmentTo:consignmentTo,
            noOfPackages:noOfPackages,
            paymentMode: paymentMode ? paymentModeOptions.data.find((item: any) => item.id === paymentMode.id): null,
            //purchase order number
            tableRow: [...productBundleSubs.filter((item: any) => item.tax > 0).map((item: any) => {

                return {
                    ...item,
                    product: [...productAndBundleOptions.data.map((item: any) => {
                        return {...item, name: 'Product'}
                    }), ...productAndBundleOptions.data2.map((item: any) => {
                        return {...item, name: 'Bundle'}
                    })].find((prodItem: any) =>  ((prodItem.name === 'Product' ?  prodItem.productid : prodItem.bundleid) === (item.productId || item.productBundleId)) && (prodItem.name === (item.productId ? 'Product': 'Bundle'))),
                    // accountHead: [ ...inventoryAccount.data, ...assetAccount.data.map((item: any) => {
                    //     return {...item, subtypename: 'Fixed Asset', isInventory: false, headname: item.name}
                    // })].find((headItem: any) => headItem.id === item.accountHeadId)
                }


            })],
            tableRowNonTaxable: [...productBundleSubs.filter((item: any) => item.tax === 0).map((item: any) => {

                return {
                    ...item,
                    product: [...productAndBundleOptions.data.map((item: any) => {
                        return {...item, name: 'Product'}
                    }), ...productAndBundleOptions.data2.map((item: any) => {
                        return {...item, name: 'Bundle'}
                    })].find((prodItem: any) =>  ((prodItem.name === 'Product' ?  prodItem.productid : prodItem.bundleid) === (item.productId || item.productBundleId)) && (prodItem.name === (item.productId ? 'Product': 'Bundle'))),
                    // accountHead: [ ...inventoryAccount.data, ...assetAccount.data.map((item: any) => {
                    //     return {...item, subtypename: 'Fixed Asset', isInventory: false, headname: item.name}
                    // })].find((headItem: any) => headItem.id === item.accountHeadId)
                }


            })],

            tableRowAdditional: journalVoucherDto && [ ...journalVoucherDto.journalRows.filter((item: any) => additionalChargeAccountsId.includes(item.accountHead.id)).map((item: any) => {

                console.log('jornal',item);

                let account = additionalChargeAccounts.data.find((itemHead: any) => itemHead.id === item.accountHead.id);
                let entries = JSON.parse(item.description);
                return {
                    product: entries.product,
                    // description: item.description?.split('_')?.slice(0,-1)?.join('_'),
                    price: entries.price,
                    tax: entries.tax,
                    amount: entries.amount,
                    quantity: entries.quantity, uom: entries.uom, discount: entries.discount
                }
            })],
            tds: journalVoucherDto && [...journalVoucherDto?.journalRows.filter((item: any) => tdsOptions.data.find((tdsItem: any) => tdsItem.id === item.accountHead.id)).map((item: any, index: number) => {
                // let account = tdsOptions.data.find((itemHead: any) => itemHead.id === item.accountHead.id);
                let entries = JSON.parse(item.description)
                return {
                    id : index+1,
                    product: entries?.product,
                    // description: item.description.split('_').slice(0,-1).join('_'),
                    taxableAmount: entries?.taxableAmount,
                    tax: entries?.tax,
                    amount: entries?.amount
                }
            })],

        })
    }


    const onSubmit = async (values: any, formActions: any) => {

        const answer = window.confirm("Are you sure you want to create the return? Please verify before proceeding. Once submitted return cannot be edited!");
        if (!answer) {
            formActions.setSubmitting(false);
            return
        }

        let isReset = values.action === 'submitAndAdd';

        // @ts-ignore
        try {

            if([...values.tableRow, ...values.tableRowNonTaxable].filter((item: any) => (item.product )).length === 0){
                handleResponse(`Select at least one product.`, 'warning')
                formActions.setSubmitting(false)
                return;
            }

            // if([...values.tableRow, values.tableRowNonTaxable].filter((item: any) => (item.product) && !(item.quantity && item.price && item.amount)).length > 0) {
            //     handleResponse(`Some row doesn't have Product, Quantity, Price and Amount.`, 'warning')
            //     formActions.setSubmitting(false)
            //     return;
            // }

            // if(values.tableRowAdditional.filter((item: any) => (item.product) && !(item.price && item.amount)).length > 0){
            //     handleResponse(`Selected additional charge has no Price or amount.`, 'warning')
            //     formActions.setSubmitting(false)
            //     return;
            // }

            let customerAccount : any = {}

            if(values.paymentStatus !== 'Full'){
                let {data: tempAcc} = await axiosInstance.get(`/getAccountHeadOfCustomerById/${values?.customer?.id}`)
                customerAccount = tempAcc;
            }

            let entryJournal: object[] = [];
            let taxAccount = headOptionsInCategoryVat.data.filter((item: any) => item.code === ACCOUNT_CODE_MAPPER.purchaseVatAccountCode)[0];

            // let taxAccount = headOptionsInCategory.data.filter((item: any) => item.code === ACCOUNT_CODE_MAPPER.vatAccountCode)[0];
            let exemptedAmount = 0.00;

            values.tableRowAdditional.filter((item: any) => item.product && item.amount).map((item: any) => {

                let taxAmount = 0;
                let amount = parseFloat(item.amount);

                if(item.tax > 0) {

                    taxAmount = getTaxAmount(item.amount, parseFloat(item.tax));
                    amount  -= taxAmount

                    entryJournal.push({
                        accountHead: {id: taxAccount.id},
                        amount: taxAmount,
                        tax: item.tax,
                        accountGroupId: taxAccount.accgroupid,
                        type: 'debit',
                        rate: '',
                        description: item.description,
                        subTypeId: taxAccount.subtypeid
                    })
                } else {
                    exemptedAmount += amount
                }

                entryJournal.push( {
                    accountHead: {id: item.product.id},
                    amount: amount,
                    tax: item.tax,
                    accountGroupId: item.product.accgroupid,
                    type: 'debit',
                    rate: '',
                    description: JSON.stringify(item),
                    subTypeId: item.product.subtypeid
                })
            })

            let newRows = [...values.tableRow, ...values.tableRowNonTaxable];
            newRows.filter((item: any) => item.product && item.amount).map((item: any) => {

                let account = accountForSales.data.filter((itemAccount: any) => itemAccount.id === (item.product.sales_account_id))[0];

                let amount = item.amount;
                let taxAmount = 0;

                if(item.tax > 0) {
                    taxAmount = getTaxAmount(item.amount, parseFloat(item.tax));
                    amount -= taxAmount

                    entryJournal.push({
                        accountHead: {id: taxAccount.id},
                        amount: taxAmount,
                        tax: item.tax,
                        accountGroupId: taxAccount.accgroupid,
                        type: 'debit',
                        rate: '',
                        description: item.description,
                        subTypeId: taxAccount.subtypeid
                    })
                }else{
                    exemptedAmount += amount
                }

                entryJournal.push({
                    accountHead: {id: account.id},
                    amount: amount,
                    tax: item.tax,
                    type: 'debit',
                    accountGroupId: account.accgroupid,
                    rate: '',
                    description: item.description,
                    subTypeId: account.subtypeid
                })
            })



            let sumProduct = newRows.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            let sumAdditional = values.tableRowAdditional.filter((item: any) => item.product && item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            let sumTds = values.tds.filter((item: any) => item.amount).reduce((result: number, item: any) => {
                return (result + parseFloat(item.amount))
            }, 0.00)

            let tdsTaxableAmount = 0.00, tdsTaxAmount = 0.00, tdsTotalAmount = 0.00;

            values.tds.filter((item: any) => item.product && item.amount).map((item: any) => {
                let account =  item.product;
                let amount = item.amount;
                tdsTotalAmount += amount;
                tdsTaxableAmount += parseFloat(item.taxableAmount);

                entryJournal.push({
                    accountHead: {id: account.id},
                    amount: parseFloat(item.amount),
                    tax: item.tax,
                    type: 'credit',
                    name: 'tds',
                    accountGroupId: account.accgroupid,
                    rate: '',
                    description: JSON.stringify(item),
                    subTypeId: account.subtypeid
                })

            })


            let customerRow: any = [];
            let bankRow: any = [];

            if(values.paymentStatus === 'Full' || values.paymentStatus === 'Semi'){
                bankRow = [{
                    accountHead: {id: values.paymentMode.id},
                    amount: parseFloat(values.amountPaid),
                    tax: '',
                    type: 'credit',
                    name: 'bank',
                    accountGroupId: values.paymentMode.accgroupid,
                    rate: '',
                    estimate: true,
                    description: values.chequeNumber,
                    subTypeId: values.paymentMode.subtypeid
                }]
            }
            if (values.paymentStatus === 'Semi' || values.paymentStatus === 'Unpaid'){
                customerRow = [{
                    accountHead: {id: customerAccount.t.id},
                    amount: sumAdditional + sumProduct - sumTds - parseFloat(values.amountPaid || 0),
                    tax: '',
                    type: 'credit',
                    name: 'customer',
                    accountGroupId: customerAccount.t.accgroupid,
                    rate: '',
                    estimate: true,
                    description: '',
                    subTypeId: customerAccount.t.subtypeid
                }]
            }

            let bankAccount = values.paymentStatus === 'Semi' ? [...customerRow, ...bankRow] : values.paymentStatus === 'Full' ? [...bankRow] : [...customerRow];
            const {amount, discount, taxableAmount, taxAmount, totalAmount} = getInvoiceAmounts([...values.tableRow,...values.tableRowNonTaxable, ...values.tableRowAdditional]);



            let payload = {
                billDate: values.transactionYearAd + '-' + ('0' + values.transactionMonthAd).slice(-2) + '-' + ('0' + values.transactionDateAd).slice(-2),
                transactionDate: values.transactionYearAd + '-' + ('0' + values.transactionMonthAd).slice(-2) + '-' + ('0' + values.transactionDateAd).slice(-2),
                paymentMode: values.paymentMode ? {id : values.paymentMode.id}: null,                paymentStatus: values.paymentStatus,
                fiscalYear:"2080.081",
                panNumber: values.customer ? {id: values.customer.panNumber}: '',
                customer: values.customer ? {id: values.customer.id}: null,
                description:values.amountPaid,
                referenceInvoiceNumber: values.referenceInvoiceNumber.bill_number,
                reasonForReturn: values.reasonForReturn,
                salesReturnNumber: values.salesReturnNumber,
                consignmentDate:getFormattedDate(values.consignmentYearAd, values.consignmentMonthAd, values.consignmentDateAd),
                consignmentNo: values.consignmentNo,
                consignmentEx: values.consignmentEx,
                consignmentVia: values.consignmentVia,
                consignmentTo:values.consignmentTo,
                noOfPackages:values.noOfPackages,
                amount: amount,
                discount: discount,
                taxableAmount: taxableAmount - exemptedAmount,
                taxAmount: taxAmount ,
                totalAmount: Math.round(totalAmount),
                exemptedAmount: parseFloat(String(exemptedAmount)) + parseFloat((Math.round(totalAmount)-totalAmount).toFixed(2)),
                productBundleSubs: newRows.filter((item: any) => item.product && item.quantity && item.amount).map((item: any) => {
                    return {
                        productId: item.product.name === 'Product' ? item.product.productid: 0,
                        productBundleId: item.product.name === 'Product' ? 0: item.product.bundleid,
                        price: parseFloat(item.price),
                        discount: parseFloat(item.discount),
                        amount: parseFloat(item.amount),
                        quantity: item.quantity,
                        tax: parseFloat(item.tax)
                    }
                }),

                journalVoucherDto: {
                    type: 'journal',
                    receiptNumber: '',
                    journalRows: [
                        ...entryJournal,
                        ...bankAccount
                    ]
                }

            }

            const {data} = await axiosInstance.post('/addSR', payload);
            responseProcess(data, formActions, isReset)

        } catch (e) {
            console.log(e)
            handleResponse(e?.response?.data?.message ? e.response.data.message : 'Something went wrong', 'warning')
        }

        formActions.setSubmitting(false)
    }

    const history = useHistory();
    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {
            handleResponse(data.message, 'success')
            formActions.resetForm();
            trigger('/getCode?name=salesReturn&id=0')
            if(!isReset) history.replace('/dashboard/sales/return')
        } else {
            handleResponse(data.message, 'warning')
        }
    }

    const dispatch = useDispatch();
    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }

    const handleRemove = (index: number, formikInstance: any, sectionName: string) => {
        const {values} = formikInstance;
        let tempTable = [...values[sectionName]].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue(sectionName, [...tempTable])
    }

    const addNew = useCallback((formikInstance: any, sectionName: string) => {

        const {values} = formikInstance;
        let rows = values[sectionName]
        let tempArr = {};

        if(sectionName === 'tableRow'){
            tempArr = {
                id: rows[rows.length - 1].id + 1,
                product: null,
                quantity: 0,
                uom: null,
                price: 0,
                discount: 0,
                tax: 0,
                amount: 0
            }
        }else if(sectionName === 'tableRowAdditional'){
            tempArr = {
                id: rows[rows.length - 1].id + 1,
                product: null, quantity: 0, uom: null, price: 0, discount: 0, tax: 0, amount: 0
            }
        }

        values[sectionName].push(tempArr)
        formikInstance.setFieldValue(sectionName, [...values[sectionName]])

    }, [formState])

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const componentRef = useRef(null);
    // @ts-ignore
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => afterPrint(),
        pageStyle: '@page { size: auto; margin: 00mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 20px 40px 50px 40px !important; background-color: white } }'

    });

    const printClicked = async () => {

        if (handlePrint) {
            handlePrint()
        }
    }

    const afterPrint = async () => {
        let {data: printed} = await axiosInstance.get(`/SR/printed/${Props.id}`)
        history.replace('/dashboard/sales/return');
    }

    const [invoiceObject, setInvoiceObject] = useState(null);
    const [returnNumber, setReturnNumber] = useState('')

    return (
        <div>

            <DialogComponent open={openDialogProduct} handleClose={openDialogProduct} title={'Add Product'}
                             maxWidth={'md'}>
                <ProductItemForm mode={'create'} from={'journal page'} handleClose={() => setOpenDialogProduct(false)}
                                 noFixed={true}
                />
            </DialogComponent>

            <DialogComponent open={openDialogCustomer} handleClose={setOpenDialogCustomer} title={'Add Customer'}
                             maxWidth={'md'}>
                <CustomerForm mode={'edit'} from={'journal page'} handleClose={() => setOpenDialogCustomer(false)}
                              noFixed={true}
                />
            </DialogComponent>


            <DialogComponent open={openDialogHead} handleClose={openDialogHead} title={'Add Account Head'}
                             maxWidth={'sm'}>
                <AccountHeadForm mode={'create'} from={'journal page'} handleClose={() => setOpenDialogHead(false)}
                                 noFixed={true}
                />
            </DialogComponent>

            <DialogComponent open={cancelInv} handleClose={setCancelInv} title={'Cancel Invoice'}
                             maxWidth={'sm'}>
                <InvoiceCancelForm handleClose={() => setCancelInv(false)}
                                   id={Props.id}
                                   noFixed={true}
                />
            </DialogComponent>

            <Formik

                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {

                    return (
                        <div>
                            <Form style={{margin: '0px 20px 20px 20px'}} onKeyDown={onKeyDown}>
                                <div ref={componentRef}>

                                    {Props.mode === 'view' ?
                                        (
                                            <>
                                                <InvoicePrintViewSuvam canceled={canceled} type={'Return'} formik={formik} state={location.state} view={'Customer'}/>
                                                <div className='display-in-print' style={{pageBreakBefore: "always", marginTop: 50, textAlign: 'left'}}>
                                                    <InvoicePrintViewSuvam formik={formik} type={'Return'} state={location.state} view={'Office'}/>
                                                </div>
                                            </>
                                        ):
                                        (
                                            <>
                                                <Grid container justify={"space-between"}>

                                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="code"
                                                                   className={styles.label}> Sales Return No.:</label>
                                                        </Grid>

                                                        <Grid item xs={8} className={styles.label}>
                                                            {formik.values.salesReturnNumber}
                                                        </Grid>
                                                    </Grid>


                                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="referenceInvoiceNumber" className={styles.label}> Ref. Invoice Number:<RequiredNotation/></label>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'referenceInvoiceNumber'}>
                                                                    {
                                                                        (props: { field: any; form: any; meta: any; }) => {

                                                                            const {field, form, meta} = props;

                                                                            return (
                                                                                <>
                                                                                    <AutoCompleteInput type={'text'}
                                                                                                       id={`referenceInvoiceNumber`} {...field}
                                                                                                       size={"small"}

                                                                                                       onChange={(e, value) => {
                                                                                                           setInvoiceObject(value);
                                                                                                           setReturnNumber(formik.values.salesReturnNumber);
                                                                                                           form.setFieldValue(`referenceInvoiceNumber`, value)
                                                                                                           if(value){
                                                                                                               setInvoiceId(value.id)
                                                                                                           }else{
                                                                                                               setInvoiceId(0)
                                                                                                           }
                                                                                                       }}
                                                                                                       autoHighlight={true}
                                                                                                       autoSelect={true}
                                                                                                       // PopperComponent={PopperMy}

                                                                                                       options={billMinimalOptions ? billMinimalOptions.data : []}
                                                                                                       loading={!billMinimalOptions}

                                                                                                       getOptionLabel={option => option.bill_number}
                                                                                                       error={meta.touched && meta.error}
                                                                                    />
                                                                                    <ErrorLabel meta={meta}/>

                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                </Field>

                                                            </div>

                                                        </Grid>
                                                    </Grid>

                                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="customer"
                                                                   className={styles.label}> Customer: <RequiredNotation/></label>
                                                        </Grid>

                                                        <Grid item container xs={8} justify={"space-between"}>
                                                            <Grid item xs={10}>
                                                                <div style={{position: 'relative'}}>
                                                                    <Field name={'customer'}>
                                                                        {(props: { field: any; form: any; meta: any; }) => {
                                                                            const {field, form, meta} = props;
                                                                            return (
                                                                                <>
                                                                                    <AutoCompleteInput type={'text'}
                                                                                                       id={`customer`} {...field}
                                                                                                       size={"small"}
                                                                                                       filterOptions={(options, {inputValue}) => {
                                                                                                           // let filteredOptions = getOptions(options)
                                                                                                           //todo do this again
                                                                                                           return matchSorter(options, inputValue, {keys: [ 'name','email', 'phone']}).sort(function(a: any, b: any) {
                                                                                                               let nameA = a.name.toUpperCase();
                                                                                                               let nameB = b.name.toUpperCase();
                                                                                                               if (nameA < nameB) {
                                                                                                                   return -1;
                                                                                                               }
                                                                                                               if (nameA > nameB) {
                                                                                                                   return 1;
                                                                                                               }

                                                                                                               return 0;
                                                                                                           });
                                                                                                       }}
                                                                                                       onChange={(e, value) => {
                                                                                                           form.setFieldValue(`customer`, value)
                                                                                                           if(!value) return
                                                                                                           form.setFieldValue(`panNumber`, value.pan_number)
                                                                                                           let rows = form.values.tableRow;
                                                                                                           form.setFieldValue("tableRow", rows.map((item: any) => {
                                                                                                               let change = item.discount > value.maximum_allowed_discount;
                                                                                                               return {...item, discount: change ? 0 : item.discount, amount : change ? (item.price * item.quantity)*(1+ (item.tax/100)) : item.amount}
                                                                                                           }))
                                                                                                       }}
                                                                                                       autoHighlight={true}
                                                                                                       autoSelect={true}
                                                                                                       PopperComponent={PopperMy}

                                                                                                       renderOption={(option) => {
                                                                                                           return (
                                                                                                               <div>
                                                                                                                   <div style={{
                                                                                                                       fontSize: 14,
                                                                                                                   }}>
                                                                                                                       <>{option.name} </>
                                                                                                                   </div>
                                                                                                                   <div style={{fontSize: 12}}> {option.email}</div>
                                                                                                                   <div style={{fontSize: 14, color: COLOR_PROFILE.buttonGreen}}>{option.phone}</div>
                                                                                                               </div>
                                                                                                           )
                                                                                                       }}
                                                                                                       options={customerOptions ? customerOptions.data : []}
                                                                                                       loading={!customerOptions}

                                                                                                       getOptionLabel={option => option.name}
                                                                                                       error={meta.touched && meta.error}
                                                                                    />
                                                                                    <ErrorLabel meta={meta}/>
                                                                                </>
                                                                            )
                                                                        }}
                                                                    </Field>

                                                                </div>
                                                            </Grid>
                                                            <Grid item>
                                                                <ToolTip title={'Add new customer'}>
                                                                    <Grid item style={{
                                                                        border: '1px solid #d0d0d0',
                                                                        padding: '8px 10px 3px 10px',
                                                                        cursor: 'pointer',
                                                                        background: COLOR_PROFILE.buttonGreen,
                                                                        // "&:hover":{boxShadow: "0 0 5px 1px gray"}

                                                                    }}
                                                                          onClick={() => setOpenDialogCustomer(prevState => !prevState)}
                                                                    >
                                                                        <div style={{}}>

                                                                            <AddIcon style={{
                                                                                color: "white",
                                                                                fontWeight: 700,
                                                                                fontSize: 20
                                                                            }}/>

                                                                        </div>
                                                                    </Grid>
                                                                </ToolTip>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>


                                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="panNumber" className={styles.label}> PAN Number:</label>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'panNumber'}>
                                                                    {
                                                                        (props: { field: any; form: any; meta: any; }) => {

                                                                            const {field, form, meta} = props;

                                                                            return (
                                                                                <>
                                                                                    <InputField type={'text'}
                                                                                                id={'panNumber'} {...field}
                                                                                                size={"small"}
                                                                                                fullWidth={true}
                                                                                                error={meta.touched && meta.error}
                                                                                    />
                                                                                    <ErrorLabel meta={meta}/>
                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                </Field>

                                                            </div>

                                                        </Grid>
                                                    </Grid>



                                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 10}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="estimateDate"
                                                                   className={styles.label}
                                                            >
                                                                Transaction Date:<RequiredNotation/>
                                                            </label>
                                                        </Grid>

                                                        <Grid item container xs={8} alignItems={'center'}>

                                                            <DateComponent
                                                                ad={['transactionYearAd', 'transactionMonthAd', 'transactionDateAd']}
                                                                bs={['transactionYearBs', 'transactionMonthBs', 'transactionDateBs']}
                                                            />

                                                        </Grid>
                                                    </Grid>

                                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                                    {/*      style={{marginBottom: 15}}>*/}
                                                    {/*    <Grid item xs={4}>*/}
                                                    {/*        <label htmlFor="paymentStatus"*/}
                                                    {/*               className={styles.label}> Payment Status:<RequiredNotation/> </label>*/}
                                                    {/*    </Grid>*/}

                                                    {/*    <Grid item xs={8}>*/}
                                                    {/*        <div style={{position: 'relative'}}>*/}
                                                    {/*            <Field name={'paymentStatus'}>*/}
                                                    {/*                {(props: { field: any; form: any; meta: any; }) => {*/}
                                                    {/*                    const {field, form, meta} = props;*/}
                                                    {/*                    return (*/}
                                                    {/*                        <>*/}
                                                    {/*                            <AutoCompleteInput type={'text'}*/}
                                                    {/*                                               id={`paymentStatus`} {...field}*/}
                                                    {/*                                               size={"small"}*/}
                                                    {/*                                               autoHighlight={true}*/}
                                                    {/*                                               autoSelect={true}*/}
                                                    {/*                                               onChange={(e, value) => {*/}
                                                    {/*                                                   form.setFieldValue(`paymentStatus`, value)*/}
                                                    {/*                                               }}*/}

                                                    {/*                                               options={['Full', 'Semi', 'Unpaid']}*/}
                                                    {/*                                               getOptionLabel={option => option}*/}
                                                    {/*                                               error={meta.touched && meta.error}*/}
                                                    {/*                            />*/}
                                                    {/*                            <ErrorLabel meta={meta}/>*/}
                                                    {/*                        </>*/}
                                                    {/*                    )*/}
                                                    {/*                }}*/}
                                                    {/*            </Field>*/}

                                                    {/*        </div>*/}
                                                    {/*    </Grid>*/}
                                                    {/*</Grid>*/}


                                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="paymentMode"
                                                                   className={styles.label}> Payment Mode:<RequiredNotation/>
                                                            </label>
                                                        </Grid>

                                                        <Grid item xs={8}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'paymentMode'}>
                                                                    {(props: { field: any; form: any; meta: any; }) => {
                                                                        const {field, form, meta} = props;
                                                                        return (
                                                                            <>
                                                                                <AutoCompleteInput type={'text'}
                                                                                                   id={`paymentMode`} {...field}
                                                                                                   size={"small"}
                                                                                                   autoHighlight={true}
                                                                                                   autoSelect={true}
                                                                                                   onChange={(e, value) => {
                                                                                                       form.setFieldValue(`paymentMode`, value)
                                                                                                   }}

                                                                                                   options={paymentModeOptions ? paymentModeOptions.data : []}
                                                                                                   loading={!paymentModeOptions}

                                                                                                   groupBy={option => option.subtypename}
                                                                                                   PopperComponent={PopperMy}
                                                                                                   getOptionLabel={option => option.headname}
                                                                                                   renderOption={(option) => {
                                                                                                       return (
                                                                                                           <div>
                                                                                                               <div style={{
                                                                                                                   fontSize: 11,
                                                                                                               }}>
                                                                                                                   <>{option.code}</>
                                                                                                               </div>
                                                                                                               <div>{option.headname}</div>
                                                                                                           </div>
                                                                                                       )
                                                                                                   }}
                                                                                                   error={meta.touched && meta.error}
                                                                                />
                                                                                <ErrorLabel meta={meta}/>
                                                                            </>
                                                                        )
                                                                    }}
                                                                </Field>

                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    {/*<Grid container item sm={6} alignItems={'center'} spacing={2}*/}
                                                    {/*      style={{marginBottom: 15}}>*/}
                                                    {/*    <Grid item xs={4}>*/}
                                                    {/*        <label htmlFor="chequeNumber" className={styles.label}>*/}
                                                    {/*            Cheque Number:*/}
                                                    {/*        </label>*/}
                                                    {/*    </Grid>*/}
                                                    {/*    <Grid item xs={8}>*/}
                                                    {/*        <div style={{position: 'relative'}}>*/}
                                                    {/*            <Field name={'chequeNumber'}>*/}
                                                    {/*                {*/}
                                                    {/*                    (props: { field: any; form: any; meta: any; }) => {*/}

                                                    {/*                        const {field, form, meta} = props;*/}

                                                    {/*                        return (*/}
                                                    {/*                            <>*/}
                                                    {/*                                <InputField type={'text'}*/}
                                                    {/*                                            id={'chequeNumber'} {...field}*/}
                                                    {/*                                            size={"small"}*/}
                                                    {/*                                            fullWidth={true}*/}
                                                    {/*                                            error={meta.touched && meta.error}*/}
                                                    {/*                                />*/}
                                                    {/*                                <ErrorLabel meta={meta}/>*/}
                                                    {/*                            </>*/}

                                                    {/*                        )*/}
                                                    {/*                    }*/}
                                                    {/*                }*/}
                                                    {/*            </Field>*/}

                                                    {/*        </div>*/}

                                                    {/*    </Grid>*/}
                                                    {/*</Grid>*/}

                                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="amountPaid" className={styles.label}> Amount Paid:<RequiredNotation/></label>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'amountPaid'}>
                                                                    {
                                                                        (props: { field: any; form: any; meta: any; }) => {

                                                                            const {field, form, meta} = props;

                                                                            return (
                                                                                <>
                                                                                    <InputField type={'text'}
                                                                                                id={'amountPaid'} {...field}
                                                                                                size={"small"}
                                                                                                disabled
                                                                                                fullWidth={true}
                                                                                                error={meta.touched && meta.error}
                                                                                    />
                                                                                    <ErrorLabel meta={meta}/>
                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                </Field>

                                                            </div>

                                                        </Grid>
                                                    </Grid>




                                                    <Grid container item sm={6} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="reasonForReturn" className={styles.label}> Reason for return: </label>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'reasonForReturn'}>
                                                                    {
                                                                        (props: { field: any; form: any; meta: any; }) => {

                                                                            const {field, form, meta} = props;

                                                                            return (
                                                                                <>
                                                                                    <InputField type={'text'}
                                                                                                id={'reasonForReturn'} {...field}
                                                                                                size={"small"}
                                                                                                fullWidth={true}
                                                                                                error={meta.touched && meta.error}
                                                                                    />
                                                                                    <ErrorLabel meta={meta}/>
                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                </Field>

                                                            </div>

                                                        </Grid>
                                                    </Grid>

                                                </Grid>


                                                <Divider/>

                                                <Grid container justify={"space-between"} style={{paddingTop: 20}}>

                                                    <Grid container item sm={4} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="consignmentNo" className={styles.label}>
                                                                Consignment Number:
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'consignmentNo'}>
                                                                    {
                                                                        (props: { field: any; form: any; meta: any; }) => {

                                                                            const {field, form, meta} = props;

                                                                            return (
                                                                                <>
                                                                                    <InputField type={'text'}
                                                                                                id={'consignmentNo'} {...field}
                                                                                                size={"small"}
                                                                                                fullWidth={true}
                                                                                                error={meta.touched && meta.error}
                                                                                    />
                                                                                    <ErrorLabel meta={meta}/>
                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                </Field>

                                                            </div>

                                                        </Grid>
                                                    </Grid>

                                                    <Grid container item sm={5} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 10, marginTop: -15}}>
                                                        <Grid item xs={2}>
                                                            <label htmlFor="consignmentDate"
                                                                   className={styles.label}
                                                            >
                                                                Date:
                                                            </label>
                                                        </Grid>

                                                        <Grid item container xs={10} alignItems={'center'}>

                                                            <DateComponent
                                                                ad={['consignmentYearAd', 'consignmentMonthAd', 'consignmentDateAd']}
                                                                bs={['consignmentYearBs', 'consignmentMonthBs', 'consignmentDateBs']}
                                                            />

                                                        </Grid>
                                                    </Grid>


                                                    <Grid container item sm={3} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={8}>
                                                            <label htmlFor="noOfPackages" className={styles.label}>
                                                                Number of Packages:
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'noOfPackages'}>
                                                                    {
                                                                        (props: { field: any; form: any; meta: any; }) => {

                                                                            const {field, form, meta} = props;

                                                                            return (
                                                                                <>
                                                                                    <InputField type={'number'}
                                                                                                id={'noOfPackages'} {...field}
                                                                                                size={"small"}
                                                                                                fullWidth={true}
                                                                                                error={meta.touched && meta.error}
                                                                                    />
                                                                                    <ErrorLabel meta={meta}/>
                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                </Field>

                                                            </div>

                                                        </Grid>
                                                    </Grid>

                                                    <Grid container item sm={4} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="consignmentEs" className={styles.label}>
                                                                Ex:
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'consignmentEx'}>
                                                                    {
                                                                        (props: { field: any; form: any; meta: any; }) => {

                                                                            const {field, form, meta} = props;

                                                                            return (
                                                                                <>
                                                                                    <InputField type={'text'}
                                                                                                id={'consignmentEx'} {...field}
                                                                                                size={"small"}
                                                                                                fullWidth={true}
                                                                                                error={meta.touched && meta.error}
                                                                                    />
                                                                                    <ErrorLabel meta={meta}/>
                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                </Field>

                                                            </div>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item sm={4} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4}>
                                                            <label htmlFor="consignmentVia" className={styles.label}>
                                                                Via:
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'consignmentVia'}>
                                                                    {
                                                                        (props: { field: any; form: any; meta: any; }) => {

                                                                            const {field, form, meta} = props;

                                                                            return (
                                                                                <>
                                                                                    <InputField type={'text'}
                                                                                                id={'consignmentVia'} {...field}
                                                                                                size={"small"}
                                                                                                fullWidth={true}
                                                                                                error={meta.touched && meta.error}
                                                                                    />
                                                                                    <ErrorLabel meta={meta}/>
                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                </Field>

                                                            </div>

                                                        </Grid>
                                                    </Grid>

                                                    <Grid container item sm={4} alignItems={'center'} spacing={2}
                                                          style={{marginBottom: 15}}>
                                                        <Grid item xs={4} >
                                                            <label htmlFor="consignmentTo" className={styles.label}>
                                                                To:
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{position: 'relative'}}>
                                                                <Field name={'consignmentTo'}>
                                                                    {
                                                                        (props: { field: any; form: any; meta: any; }) => {

                                                                            const {field, form, meta} = props;

                                                                            return (
                                                                                <>
                                                                                    <InputField type={'text'}
                                                                                                id={'consignmentTo'} {...field}
                                                                                                size={"small"}
                                                                                                fullWidth={true}
                                                                                                error={meta.touched && meta.error}
                                                                                    />
                                                                                    <ErrorLabel meta={meta}/>
                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                </Field>

                                                            </div>

                                                        </Grid>
                                                    </Grid>


                                                </Grid>

                                                <Divider style={{marginBottom: 10}}/>


                                                {formik.values.tableRowNonTaxable.length > 0 && (<>
                                                    <div>
                                                    <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10, color: COLOR_PROFILE.primaryColor}}>
                                                        <u>(A) Non Taxable Items</u>
                                                    </div>
                                                    <EstimateProductsFormNonTaxable handleRemove={handleRemove} mode={Props.mode} addNew={addNew} setOpenDialog={setOpenDialogProduct}
                                                                                    type={'invoiceNonTaxable'}
                                                                                    formikInstance={formik}/>
                                                </div>
                                                </>)}
                                                <div>
                                                    <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10, color: COLOR_PROFILE.primaryColor}}>
                                                        <u>(B) Taxable Items</u>
                                                    </div>
                                                    <EstimateProductsForm handleRemove={handleRemove} mode={Props.mode} addNew={addNew} setOpenDialog={setOpenDialogProduct}
                                                                          type={'invoiceTaxable'}
                                                                          from={'return'}
                                                                          formikInstance={formik}/>
                                                </div>

                                                {/*<div>*/}

                                                {/*    <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>*/}
                                                {/*        <u>Additional Charges</u>*/}
                                                {/*    </div>*/}
                                                {/*    <EstimateAdditionalForm handleRemove={handleRemove} addNew={addNew}*/}
                                                {/*                            mode={Props.mode}*/}
                                                {/*                            setOpenDialog={setOpenDialogHead}*/}
                                                {/*                            formikInstance={formik}/>*/}
                                                {/*</div>*/}
                                            </>)}
                                </div>


                                {/*{!(Props.mode === 'view' && formik.values.tds.length === 0) && (*/}

                                {/*    Props.mode !== 'view' &&*/}
                                {/*    <div>*/}

                                {/*        <div style={{fontWeight: 700, fontSize: 16, marginBottom: 10}}>*/}
                                {/*            <u>TDS</u>*/}
                                {/*        </div>*/}
                                {/*        <TdsForm handleRemove={handleRemove} addNew={addNew}*/}
                                {/*                 setOpenDialog={setOpenDialogHead}*/}
                                {/*                 mode={Props.mode}*/}
                                {/*                 payable={true}*/}
                                {/*                 formikInstance={formik}/>*/}
                                {/*    </div>*/}
                                {/*)}*/}

                                {/*<div>*/}
                                {/*    <Grid container item sm={6} spacing={2}*/}
                                {/*          style={{marginBottom: 15}}>*/}
                                {/*        <Grid item xs={3}>*/}
                                {/*            <label htmlFor="description" className={styles.label}> Description:</label>*/}
                                {/*        </Grid>*/}
                                {/*        <Grid item xs={9}>*/}
                                {/*            <div style={{position: 'relative'}}>*/}
                                {/*                <Field name={'description'}>*/}
                                {/*                    {*/}
                                {/*                        (props: { field: any; form: any; meta: any; }) => {*/}

                                {/*                            const {field, form, meta} = props;*/}


                                {/*                            return (*/}
                                {/*                                <>*/}
                                {/*                                    <InputField type={'text'}*/}
                                {/*                                                id={'description'} {...field}*/}
                                {/*                                                size={"small"}*/}
                                {/*                                                multiline={true}*/}
                                {/*                                                rows={3}*/}
                                {/*                                                fullWidth={true}*/}
                                {/*                                                error={meta.touched && meta.error}*/}
                                {/*                                    />*/}
                                {/*                                    <ErrorLabel meta={meta}/>*/}
                                {/*                                </>*/}

                                {/*                            )*/}
                                {/*                        }*/}
                                {/*                    }*/}
                                {/*                </Field>*/}

                                {/*            </div>*/}

                                {/*        </Grid>*/}
                                {/*    </Grid>*/}
                                {/*</div>*/}


                                {Props.mode !== 'view' ?
                                    <div style={{marginTop: 50}}>
                                        <FormSaveButton mode={Props.mode} from={'product-bundle'}
                                                        submitting={formik.isSubmitting}/>
                                    </div>
                                    :
                                    <div>
                                        {!canceled && (
                                            <ReportActionButton  path={'invoice'}  handlePrint={printClicked} handleDownloadAsPdf={printClicked}/>
                                        )}
                                    </div>
                                }
                            </Form>
                        </div>
                    )
                }}
            </Formik>

        </div>
    );
};

export default ReturnAdditionForm;
