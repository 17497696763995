import React, {useEffect} from 'react';
import {usePagination, useTable} from "react-table";
import {Grid, IconButton} from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {COLOR_PROFILE} from "../../../../config/colorCode";


const ReactTable = (Props: any) => {

    const {setPerPage, setPage, columns, data, currentpage, perPage, totalPage, from} = Props;


    useEffect(() => {
    }, [data])

    // @ts-ignore
    const tableInstance: any = useTable({
        columns: columns,
        data: data,
        useControlledState: (state: any) => {
            return React.useMemo(
                () => ({
                    ...state,
                    pageIndex: currentpage,
                }),
                [state, currentpage]
            );
        },
        initialState: {pageIndex: currentpage}, // Pass our hoisted table state
        manualPagination: true,
        pageCount: totalPage,
    }, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        // rows,
        page,
        nextPage,
        previousPage,
        prepareRow,
        pageOptions,
        state: {pageIndex, pageSize},
    } = tableInstance;

    const isFromHome = from === 'home';

    return (
        <div style={{margin: '10px 0 10px 0'}}>

            <div>
                {!Props.currentPage && (
                <Grid container alignItems={'center'}>

                    <IconButton onClick={() => {
                        setPage((s: number) => (s === 0 ? 0 : s - 1));
                    }}
                                disabled={pageIndex === 1}
                    >
                        <NavigateBeforeIcon style={{color: COLOR_PROFILE.primaryColor}} fontSize={'small'}/>
                    </IconButton>
                    <span style={{fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}>Previous</span>

                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <span style={{fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}>Next</span>
                    <IconButton onClick={() => {
                        setPage((s: number) => s + 1);
                    }}
                                disabled={pageIndex === totalPage}
                    >
                        <NavigateNextIcon style={{color: COLOR_PROFILE.primaryColor}} fontSize={'small'}/>
                    </IconButton>
                    <div style={{fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 550}}>
                        | &nbsp;Go to page:{' '}
                        <input
                            style={{maxWidth: 70, fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}
                            type="number"
                            // defaultValue={pageIndex}
                            value={pageIndex}
                            min="1"
                            max={totalPage}
                            onChange={(e) => {

                                const page = e.target.value ? Number(e.target.value) : pageIndex;
                                setPage(page);
                            }}
                        />
                    </div>
                    &nbsp;
                    <span
                        style={{fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}>of {totalPage}</span>

                    &nbsp;
                    &nbsp;


                    <select
                        value={perPage}
                        onChange={(e) => {
                            // setPageSize(Number(e.target.value));
                            setPerPage(Number(e.target.value));
                        }}
                        style={{padding: 1, fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}

                    >
                        {(Props.perPageOption ? Props.perPageOption : [5, 10, 20]).map((pageSize: number) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </Grid>
                )}
            </div>

            <div style={{overflow: 'auto'}}>

                <table {...getTableProps()} style={{width: '100%', padding: '0px 0 10px 0'}}>
                    <thead
                        style={{background: COLOR_PROFILE.tableHeader}}
                    >

                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map((column: any) => {

                                    return (
                                        <th {...column.getHeaderProps()}
                                            style={{
                                                padding: 7,
                                                fontSize: 15,
                                                fontWeight: 700,
                                                color: COLOR_PROFILE.primaryColor,
                                                // minWidth: column.width ? column.width : 100,

                                                textAlign: column.alignment || 'left'
                                            }}>
                                            {column.render('Header')}
                                        </th>

                                    )
                                })
                            }
                        </tr>
                    ))}

                    </thead>
                    <tbody {...getTableBodyProps()} style={{maxHeight: 200, overflow: "auto"}}>

                    {page.map((row: any, index: any) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}
                                style={{backgroundColor: index % 2 === 0 ? COLOR_PROFILE.tableRowEven : COLOR_PROFILE.tableRowOdd}}>
                                {row.cells.map((cell: any, index: any) => {
                                    return <td {...cell.getCellProps()} style={{
                                        padding: 5,
                                        color: COLOR_PROFILE.primaryColor,
                                        fontWeight: 600,
                                        backgroundColor: index === 0 ? COLOR_PROFILE.navigationBackground: undefined,
                                        width: index === 0 ? 20 : null,
                                    }}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}

                    </tbody>

                    {/*<tfoot style={{background: 'gray'}}>*/}

                    {/*{footerGroups.map(footerGroups => (*/}
                    {/*    <tr {...footerGroups.getFooterGroupProps()}>*/}
                    {/*        {*/}
                    {/*            footerGroups.headers.map(column => {*/}

                    {/*                return (*/}
                    {/*                    <th {...column.getFooterProps()} style={{padding: 10, color: 'white'}}>*/}
                    {/*                        {column.render('Footer')}*/}
                    {/*                    </th>*/}

                    {/*                )*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    </tr>*/}
                    {/*))}*/}

                    {/*</tfoot>*/}
                </table>

            </div>

            <div
                style={{
                    bottom: 0,
                    width: '100%',
                    background: isFromHome ? undefined : '#fafafa'
                }}
            >

                {Props.currentPage && (
                <Grid container alignItems={'center'}>

                    <IconButton onClick={() => {
                        setPage((s: number) => (s === 0 ? 0 : s - 1));
                    }}
                                disabled={pageIndex === 1}
                    >
                        <NavigateBeforeIcon style={{color: COLOR_PROFILE.primaryColor}} fontSize={'small'}/>
                    </IconButton>
                    <span style={{fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}>Previous</span>

                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <span style={{fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}>Next</span>
                    <IconButton onClick={() => {
                        setPage((s: number) => s + 1);
                    }}>
                        <NavigateNextIcon style={{color: COLOR_PROFILE.primaryColor}} fontSize={'small'}/>
                    </IconButton>
                    <div style={{fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 550}}>
                        | &nbsp;Go to page:{' '}
                        <input
                            style={{maxWidth: 70, fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}
                            type="number"
                            // defaultValue={pageIndex}
                            value={pageIndex}
                            max={totalPage}
                            min="1"
                            onChange={(e) => {

                                const page = e.target.value ? Number(e.target.value) : pageIndex;
                                setPage(page);
                            }}
                        />
                    </div>
                    &nbsp;
                    <span
                        style={{fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}>of {totalPage}</span>

                    &nbsp;
                    &nbsp;


                    <select
                        value={perPage}
                        onChange={(e) => {
                            // setPageSize(Number(e.target.value));
                            setPerPage(Number(e.target.value));
                        }}
                        style={{padding: 1, fontSize: 13, color: COLOR_PROFILE.primaryColor, fontWeight: 600}}

                    >
                        {(Props.perPageOption ? Props.perPageOption : [5, 10, 20]).map((pageSize: number) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </Grid>
                )}
            </div>
        </div>
    );
};

export default ReactTable;
