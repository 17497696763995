import React from 'react';
import ProductCategoryForm from "../../organisms/form/productCategory";
import PageTopBar from "../../../accounting/organisms/pageTopBar";
import DividerComponent from "../../../shared/atom/divider";
import {useParams} from "react-router-dom";

interface Props {

}

const ProductCategoryAddition: React.FC<Props> = (Props) => {

    // @ts-ignore
    const {id} = useParams();

    return (
        <div>
            <PageTopBar name={id? 'Edit Product Category':'Add Product Category'}/>


            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{margin: '25px 0px 10px 25px',}}>
                <ProductCategoryForm mode={id? 'edit':'create'} from={'product-category'} id={id}/>
            </div>

        </div>
    );
};

export default ProductCategoryAddition;