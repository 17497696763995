const initialState = {
   dashboardTab: 0,
    verifyRequestSelected: '',
    verifyRequestOptions: []
}

const selectedStatus = (state: any = initialState, action: any) => {
    switch (action.type) {
        case 'SET_DASHBOARD_TAB':
            state = {...state,dashboardTab: action.payload};
            return state;

        case 'SET_VERIFY_REQUEST' :
            state = {...state,verifyRequestSelected: action.payload};
            return state;

        case 'SET_VERIFY_OPTIONS' :
            state = {...state,verifyRequestOptions: action.payload};
            return state;

        case 'RESET_SELECTED_STATUS':
            state = initialState
            return state

        default:
            return state
    }
}

export default selectedStatus;

