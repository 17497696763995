//returns first date of fiscal year
export const getFirstFiscalDate = (dateJson: object[], fiscalYearStatus: object[]) => {

    //get smallest running fiscal year
    let smallestRunningYear = fiscalYearStatus.filter((item: any) => item.status === 'running').map((item: any) => item.fiscalYears).sort(function (a: number, b: number) {
        return a - b
    })[0];

    //get the date of smallest fiscal year with month === 4
    let firstDate = dateJson.filter((item: any) => item.nepaliMonth === 4 && item.nepaliYear === parseInt(smallestRunningYear))[0]

    //return array after splitting the string of date
    // @ts-ignore
    return firstDate?.startDateEnglish.split('-');

}

export const isTransactionDateValid = (dateJson: object[], fiscalYearStatus: object[], dateBs: string) => {

    try {
        //get smallest running fiscal year
        let smallestRunningYear = fiscalYearStatus.filter((item: any) => item.status === 'running').map((item: any) => item.fiscalYears).sort(function (a: number, b: number) {
            return a - b
        })[0];

        //split the dateBs string
        let dateBsArray = dateBs.split('-');

        // compare year and month in dateBs such that fiscal year must
        // be greater than smallest running fiscal year and month is greater than or equal to 4
        // if both condition from above is satisfied, return true else false
        return (parseInt(dateBsArray[0]) >= smallestRunningYear) && parseInt(dateBsArray[1]) >= 4;

    } catch (e) {
        return false
    }

}

export const isInBetweenFiscal = (dateBsFrom: string, dateBsTill: string) => {

    try {

        //get year and month of dateBsFrom
        let dateBsFromArray = dateBsFrom.split('-').map((item: string) => parseInt(item));

        //get year and month of dateBsTill
        let dateBsTillArray = dateBsTill.split('-').map((item: string) => parseInt(item));

        //if yearFrom and yearTill are same and, monthFrom is greater than or equal to 4 and
        //monthTill is small than or equal to 2 then return true else false.
        // return (parseInt(dateBsFromArray[0]) === parseInt(dateBsTillArray[0]) || (parseInt(dateBsFromArray[0]) + 1 === parseInt(dateBsTillArray[0]))) && (parseInt(dateBsFromArray[1]) >= 3 && parseInt(dateBsTillArray[1]) <= 2)
        if(dateBsFromArray[1] < 4 && dateBsTillArray[1] < 4){
            return dateBsFromArray[0] === dateBsTillArray[0]
        }else if(dateBsFromArray[1] >= 4 && dateBsTillArray[1] >= 4){
            return dateBsFromArray[0] === dateBsTillArray[0]
        }else if(dateBsFromArray[1] < 4 && dateBsTillArray[1] >= 4){
            return false
        }else if(dateBsFromArray[1] >= 4 && dateBsTillArray[1] < 4){
            return dateBsFromArray[0] + 1 === dateBsTillArray[0]
        }

    } catch (e) {
        return false;
    }
}

