import React, {useCallback, useEffect, useMemo, useState} from 'react';
import * as Yup from "yup";
import useSWR, {trigger} from "swr";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Grid, Popper} from "@material-ui/core";
import AutoCompleteInput from "../../../../shared/atom/formElements/autocompleteInput";
import InputField from "../../../../shared/atom/formElements/inputField";
import FormSaveButton from "../../FormSaveButton";
import ToolTip from "../../../../shared/atom/tooltip";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {Column} from "react-table";
import VoucherTableForm from "../voucherTableForm";
import {matchSorter} from 'match-sorter';
import axiosInstance from "../../../../../config/axiosConfig";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import useAdAndBsHandler from "../../../../../effects/useAdAndBsHandler";
import {convertAdToBs, convertBsToAd} from "../../../../../config/dateFunctions";
import DialogComponent from "../../../../shared/atom/dialog";
import AccountHeadForm from "../accountHeadForm";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import {FaCalendarAlt} from "react-icons/fa";
import RequiredNotation from "../../../molecules/requiredNotation";
import {isTransactionDateValid} from "../../../../../config/fiscalYear";
import {RootState} from "../../../../../store/RootReducer";
import PagePrompt from "../../../../shared/organisms/pagePrompt";

const PaymentVouherForm: React.FC<any> = (Props: any) => {

    const initialValues = {
        payEntryId: '',
        transactionYearAd: null,
        transactionMonthAd: null,
        transactionDateAd: null,
        transactionYearBs: null,
        transactionMonthBs: null,
        transactionDateBs: null,
        receivedTo: null,
        voucherId: '',
        description: '',
        document: [],
        tableRow: [
            {id: 1, accountHead: null, receiptId: '', receivedMethod: '', description: '', debit: 0},
        ],
    }


    const [formState, setFormState] = useState(initialValues)

    const location = useLocation();
    const {formatedNepEngDates, nepaliYear, nepaliMonths, englishYear, englishMonths, nepEngDates} = useAdAndBsHandler();


    useEffect(() => {


        if (!formatedNepEngDates) return;

        if (Props.id) {

            // @ts-ignore
            const {costCenter, description, journalRows, paidFrom, receivedTo, transactionDate, voucherNumber, receiptNumber} = location.state

            let adDate = transactionDate.split('-');
            let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];

            // @ts-ignore
            let tempJson = {
                transactionYearAd: adDate[0],
                transactionDateAd: adDate[2],
                transactionMonthAd: adDate[1],
                transactionDateBs: bsDate[2],
                transactionMonthBs: bsDate[1],
                transactionYearBs: bsDate[0],
            }

            setFormState({
                ...tempJson,
                payEntryId: voucherNumber,
                receivedTo: paidFrom,
                document: [],
                voucherId: receiptNumber,
                description: description,
                tableRow: journalRows.filter((item: any) => item.accountHead.id !== paidFrom.id).map((item: any) => {
                    return {
                        id: item.id,
                        accountHead: item.accountHead,
                        receiptId: item.billId,
                        receivedMethod: item.method,
                        description: item.description,
                        debit: item.amount

                    }
                })

            })
        }
    }, [])


    const convert = (transactionDate: any) => {

        let adDate = transactionDate.split('-');
        let bsDate: any = convertAdToBs(transactionDate, formatedNepEngDates) || [];


        // @ts-ignore
        let tempJson = {
            transactionYearAd: adDate[0],
            transactionDateAd: adDate[2],
            transactionMonthAd: adDate[1],
            transactionDateBs: bsDate[2],
            transactionMonthBs: bsDate[1],
            transactionYearBs: bsDate[0],
        }

        // @ts-ignore
        setFormState(prevState => {
            return {
                ...prevState,
                ...tempJson
            }
        })

    }



    useEffect(() => {

        if(Props.id) return;
        if(!formatedNepEngDates) return;
        const newDate = new Date().toISOString();
        // @ts-ignore
        convert(newDate.split('T')[0])

    }, [formatedNepEngDates])


    const validationSchema = Yup.object({
        receivedTo: Yup.object().required('Required').nullable(true)
    })
    const {data: receiptHeadOptions} = useSWR('/getPaymentOrReceiptAccountHeads/payment')
    const {data: receiptVoucherCode} = useSWR('/getCode?name=payment&id=0')

    useEffect(() => {

        if(Props.id) return;

        if (receiptVoucherCode) setFormState(prevState => {
            return {
                ...prevState,
                payEntryId: receiptVoucherCode.t.code
            }
        })

    }, [receiptVoucherCode])


    const dispatch = useDispatch();
    const dashboard = useSelector((state: RootState) => state.dashboard);

    const onSubmit = async (values: any, other: any) => {

        let isReset = values.action === 'submitAndAdd';

        try {


            // let transactionDateBs = values.transactionYearBs + '-' + ('0' + values.transactionMonthBs).slice(-2) + '-' + ('0' + values.transactionDateBs).slice(-2);
            //
            // if(!isTransactionDateValid(nepEngDates.data, dashboard.fiscalYear,transactionDateBs)){
            //     dispatch({
            //         type: 'OPEN_SNACKBAR',
            //         payload: {message: `Transaction is not of running fiscal year.`, type: 'warning'}
            //     })
            //     return;
            // }

            if (!(values.transactionDateAd && values.transactionMonthAd && values.transactionYearAd)) {
                dispatch({
                    type: 'OPEN_SNACKBAR',
                    payload: {message: `Transaction Date is required,`, type: 'warning'}
                })
                return;
            }

            if (values.tableRow.filter((item: any) => item.accountHead !== null).length < 1) {

                dispatch({
                    type: 'OPEN_SNACKBAR',
                    payload: {message: `Enter at least one row.`, type: 'warning'}
                })
                return;
            }
            let sum = values.tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
                return (result + parseFloat(item.debit))
            }, 0.00)

            if (sum === 0.00) {
                dispatch({
                    type: 'OPEN_SNACKBAR',
                    payload: {message: `Entry does not have debit balance.`, type: 'warning'}
                })
                return;
            }
        } catch (e) {
        }


        const payload = {
            voucherNumber: values.payEntryId,
            receiptNumber: values.voucherId,
            paidFrom: values.receivedTo ? {id: values.receivedTo.id} : null,
            type: "payment",
            journalRows: values.tableRow.filter((item: any) => item.accountHead !== null).map((item: any) => {

                return {
                    type: 'debit',
                    amount: item.debit,
                    accountHead: {
                        id: item.accountHead?.id
                    },
                    subTypeId: item.accountHead.accountsubtypeid,
                    accountGroupId: item.accountHead.accgroupid,
                    billId: item.receiptId,
                    method: item.receivedMethod,
                    description: item.description
                }
            }),

            costCenter: null,
            transactionDate: values.transactionYearAd + '-' + ('0' + values.transactionMonthAd).slice(-2) + '-' + ('0' + values.transactionDateAd).slice(-2),
            description: values.description
        }


        try {

            if (Props.id) {

                // @ts-ignore
                let {journalRows, paidFrom} = location.state;

                let newPayload = payload.journalRows.map((item: any) => {

                    if(!journalRows.filter((item: any) => item.accountHead.id !== paidFrom.id).find((itemRow: any) => itemRow.accountHead.id === item.accountHead.id)) return {...item};

                    let filtered = journalRows.filter((item: any) => item.accountHead.id !== paidFrom.id).find((rowItem: any) => rowItem.accountHead.id === item.accountHead.id)
                    return {
                        ...item,
                        subTypeId: filtered.subTypeId,
                        accountGroupId: filtered.accountGroupId,
                        id: filtered.id
                    }
                })

                const {data} = await axiosInstance.put('/updatePaymentEntry', {...payload,journalRows: newPayload, id: parseInt(Props.id)})
                handleResponse(data, other, values, isReset);

            } else {
                const {data} = await axiosInstance.post('/addPaymentEntry', payload)
                handleResponse(data, other, values, isReset)
            }

            other.setSubmitting(false);

        } catch (e) {


            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: `${e.response.data.message}`, type: 'warning'}
            })

            other.setSubmitting(false);

        }


    }

    const history = useHistory();

    const handleResponse = (data: any, other: any, values: any, isReset: boolean) => {
        if (data?.code === 'OK') {
            console.log(data)
            if (data?.message === "Payment Entry Added" || data?.message === `Journal's information updated.`) {

                dispatch({
                    type: 'OPEN_SNACKBAR',
                    payload: {
                        message: `Voucher with entry : ${values.payEntryId} ${Props.id ? 'edited' : 'created'}.`,
                        type: 'success'
                    }
                })


                if(!isReset){
                    history.replace('/dashboard/voucher-entries/payment-voucher');
                }else{
                    other.resetForm();
                    trigger(`/getCode?name=payment&id=0`);
                    const newDate = new Date().toISOString();
                    // @ts-ignore
                    convert(newDate.split('T')[0])
                }

            } else {
                dispatch({
                    type: 'OPEN_SNACKBAR',
                    payload: {
                        message: ` ${data?.message ? data.message : 'Something went wrong'} `,
                        type: 'warning'
                    }
                })
            }

        } else {
            dispatch({
                type: 'OPEN_SNACKBAR',
                payload: {message: ` Something went wrong! Please try again `, type: 'warning'}
            })

        }
    }

    const handleRemove = (index: number, formikInstance: any) => {

        const {values: {tableRow}} = formikInstance;
        let tempTable = [...tableRow].filter((item: any, indexInner: number) => indexInner !== index)
        formikInstance.setFieldValue('tableRow', [...tempTable])

    }

    const addNew = useCallback((formikInstance: any) => {


        const {values: {tableRow}} = formikInstance;

        let tempArr = {
            id: tableRow[tableRow.length - 1].id + 1,
            accountHead: null,
            receiptId: '',
            receivedMethod: '',
            description: '',
            debit: 0
        }

        tableRow.push(tempArr)

        formikInstance.setFieldValue('tableRow', [...tableRow])


    }, [formState])

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const [openDialog, setOpenDialog] = useState(false)


    const styleLib = {
        label: {
            color: COLOR_PROFILE.primaryColor,
            fontWeight: 600,
            fontSize: 14
        }

    }

    const handleDateBsBlur = (form: any) => {


        const {transactionYearBs, transactionMonthBs, transactionDateBs} = form.values
        const dateInAd = convertBsToAd(transactionYearBs + '-' + transactionMonthBs + '-' + transactionDateBs, formatedNepEngDates);

        // @ts-ignore
        if (!dateInAd) return;

        // @ts-ignore
        let deconstructedDate = dateInAd?.split('T')[0]?.split('-');
        // @ts-ignore
        form.setFieldValue(`transactionYearAd`, deconstructedDate[0])
        // @ts-ignore
        form.setFieldValue(`transactionMonthAd`, parseInt(deconstructedDate[1]).toString())
        // @ts-ignore
        form.setFieldValue(`transactionDateAd`, parseInt(deconstructedDate[2]).toString())

    }

    const handleDateAdBlur = (form: any) => {
        const {transactionYearAd, transactionMonthAd, transactionDateAd} = form.values
        const dateInBs = convertAdToBs(transactionYearAd + '-' + transactionMonthAd + '-' + transactionDateAd, formatedNepEngDates);

        // @ts-ignore
        if (!dateInBs) return;
        // @ts-ignore
        form.setFieldValue(`transactionYearBs`, dateInBs[0])
        // @ts-ignore
        form.setFieldValue(`transactionMonthBs`, parseInt(dateInBs[1]).toString())
        // @ts-ignore
        form.setFieldValue(`transactionDateBs`, parseInt(dateInBs[2]).toString())

    }



    return (
        <div>
            <div>

                <DialogComponent open={openDialog} handleClose={setOpenDialog} title={'Add Account Head'}
                                 maxWidth={'sm'}>
                    <AccountHeadForm mode={'create'} from={'journal page'} handleClose={() => setOpenDialog(false)} noFixed={true}/>
                </DialogComponent>

            </div>

            <Formik

                enableReinitialize={true}
                initialValues={formState}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >

                {formik => {

                    return (
                        <div>

                            <PagePrompt formik={formik}/>

                            <Form
                                onKeyDown={onKeyDown}
                            >


                                <Grid container justify={"space-between"}>
                                    <Grid item xs={12} container justify={"space-between"}
                                          style={{margin: '10px 0px 5px 0px'}}
                                    >


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 5}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="journalEntryId"
                                                       style={{fontWeight: "bold", fontSize: 14}}>
                                                    Payment Entry Id:
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <strong>  {formState.payEntryId}</strong>
                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 5}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="receivedTo"
                                                       style={{fontWeight: "bold", fontSize: 14}}> Payment Mode:<RequiredNotation/></label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'receivedTo'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <AutoCompleteInput type={'text'}
                                                                                           id={`receivedTo`} {...field}
                                                                                           size={"small"}

                                                                                           onChange={(e, value) => {
                                                                                               form.setFieldValue(`receivedTo`, value)
                                                                                           }}
                                                                                           options={receiptHeadOptions ? receiptHeadOptions.data : []}
                                                                                           loading={!receiptHeadOptions}
                                                                                           filterOptions={(options, {inputValue}) => {

                                                                                               return matchSorter(options, inputValue, {keys: ['name','code']});
                                                                                           }}
                                                                                           getOptionLabel={option => option.name}
                                                                                           autoHighlight={true}
                                                                                           autoSelect={true}
                                                                                           renderOption={(option) => {
                                                                                               return (
                                                                                                   <div>
                                                                                                       <div style={{fontSize: 12, color: COLOR_PROFILE.buttonBlue}}>
                                                                                                           {option.code}
                                                                                                       </div>
                                                                                                       <div>{option.name}</div>
                                                                                                   </div>
                                                                                               )
                                                                                           }}
                                                                                           error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>


                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 5}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="voucherId"
                                                       style={{fontWeight: "bold", fontSize: 14}}> Voucher ID:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'voucherId'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'voucherId'} {...field}
                                                                                    size={"small"}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 5}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="transactionDate"
                                                       style={{fontWeight: "bold", fontSize: 14}}> Paid
                                                    Date:<RequiredNotation/></label>
                                            </Grid>
                                            <Grid item container xs={8} alignItems={'center'}>

                                                <Grid item container xs={6} style={{paddingRight: 3}}>
                                                    <Grid item container xs={12}
                                                          style={{...styleLib.label}} justify={"center"} spacing={1}>
                                                        <Grid item>
                                                            <div>AD</div>
                                                        </Grid>
                                                        <Grid item>
                                                            <FaCalendarAlt style={{
                                                                fontSize: 12,
                                                                color: COLOR_PROFILE.buttonBlue
                                                            }}/>
                                                        </Grid>

                                                    </Grid>

                                                    <Grid item xs={4}>

                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'transactionYearAd'}>
                                                                {
                                                                    (props: { field: any; form: any; meta: any; }) => {

                                                                        const {field, form, meta} = props;


                                                                        return (
                                                                            <>
                                                                                <AutoCompleteInput type={'text'}
                                                                                                   id={`transactionYearAd`} {...field}
                                                                                                   size={"small"}
                                                                                                   autoHighlight={true}
                                                                                                   autoSelect={true}
                                                                                                   forcePopupIcon={false}
                                                                                                   disableClearable={true}
                                                                                                   placeholder={'Year'}
                                                                                                   onChange={(e, value) => {
                                                                                                       form.setFieldValue(`transactionYearAd`, value)
                                                                                                   }}
                                                                                                   padding={0}
                                                                                                   onBlur={() => handleDateAdBlur(form)}
                                                                                                   options={englishYear}
                                                                                                   getOptionLabel={option => option}
                                                                                                   error={meta.touched && meta.error}
                                                                                />
                                                                                <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                                </div>
                                                                            </>

                                                                        )
                                                                    }
                                                                }
                                                            </Field>

                                                        </div>

                                                    </Grid>
                                                    <Grid item xs={4}>

                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'transactionMonthAd'}>
                                                                {
                                                                    (props: { field: any; form: any; meta: any; }) => {

                                                                        const {field, form, meta} = props;


                                                                        return (
                                                                            <>
                                                                                <AutoCompleteInput type={'text'}
                                                                                                   id={`transactionMonthAd`} {...field}
                                                                                                   size={"small"}
                                                                                                   autoHighlight={true}
                                                                                                   autoSelect={true}
                                                                                                   forcePopupIcon={false}
                                                                                                   placeholder={'Month'}
                                                                                                   disableClearable={true}
                                                                                                   onChange={(e, value) => {
                                                                                                       form.setFieldValue(`transactionMonthAd`, value)
                                                                                                   }}
                                                                                                   onBlur={() => handleDateAdBlur(form)}
                                                                                                   padding={0}
                                                                                                   options={englishMonths}
                                                                                                   getOptionLabel={option => option}
                                                                                                   error={meta.touched && meta.error}
                                                                                />
                                                                                <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                                </div>
                                                                            </>

                                                                        )
                                                                    }
                                                                }
                                                            </Field>

                                                        </div>

                                                    </Grid>
                                                    <Grid item xs={4}>

                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'transactionDateAd'}>
                                                                {
                                                                    (props: { field: any; form: any; meta: any; }) => {

                                                                        const {field, form, meta} = props;


                                                                        const getDaysOptions = () => {
                                                                            const {transactionYearAd, transactionMonthAd} = form.values
                                                                            if (!transactionMonthAd && !transactionMonthAd) return []

                                                                            let daysInMonth = new Date(transactionYearAd, transactionMonthAd - 1, 0).getDate();

                                                                            let dateArray = [];

                                                                            for (let i = 0; i < daysInMonth; i++) {
                                                                                dateArray[i] = (i + 1).toString()
                                                                            }

                                                                            return dateArray;
                                                                        }


                                                                        return (
                                                                            <>
                                                                                <AutoCompleteInput type={'text'}
                                                                                                   id={`transactionDateAd`} {...field}
                                                                                                   size={"small"}
                                                                                                   filterOptions={(options, {inputValue}) => {
                                                                                                       let filteredOptions = getDaysOptions()
                                                                                                       return matchSorter(filteredOptions, inputValue);
                                                                                                   }}
                                                                                                   autoHighlight={true}
                                                                                                   autoSelect={true}
                                                                                                   forcePopupIcon={false}
                                                                                                   placeholder={'Day'}
                                                                                                   disableClearable={true}
                                                                                                   onBlur={() => handleDateAdBlur(form)}
                                                                                                   onChange={(e, value) => {
                                                                                                       form.setFieldValue(`transactionDateAd`, value)
                                                                                                   }}
                                                                                                   options={ []}
                                                                                                   padding={0}

                                                                                                   getOptionLabel={option => option}
                                                                                                   error={meta.touched && meta.error}
                                                                                />
                                                                                <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                                </div>
                                                                            </>

                                                                        )
                                                                    }
                                                                }
                                                            </Field>

                                                        </div>

                                                    </Grid>

                                                </Grid>

                                                <Grid item container xs={6} style={{paddingLeft: 3}}>

                                                    <Grid item xs={12} container
                                                          style={{...styleLib.label, textAlign: 'center'}}
                                                          justify={"center"} spacing={1}>
                                                        <Grid item>
                                                            <div>BS</div>
                                                        </Grid>
                                                        <Grid item>
                                                            <FaCalendarAlt style={{
                                                                fontSize: 12,
                                                                color: COLOR_PROFILE.buttonBlue
                                                            }}/>
                                                        </Grid>
                                                    </Grid>


                                                    <Grid item xs={4} style={{marginTop: 0}}>

                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'transactionYearBs'}>
                                                                {
                                                                    (props: { field: any; form: any; meta: any; }) => {

                                                                        const {field, form, meta} = props;


                                                                        return (
                                                                            <>
                                                                                <AutoCompleteInput type={'text'}
                                                                                                   id={`transactionYearBs`} {...field}
                                                                                                   size={"small"}
                                                                                                   placeholder={'Year'}
                                                                                                   disableClearable={true}
                                                                                                   onChange={(e, value) => {
                                                                                                       form.setFieldValue(`transactionYearBs`, value)
                                                                                                   }}
                                                                                                   onBlur={() => handleDateBsBlur(form)}
                                                                                                   autoHighlight={true}
                                                                                                   autoSelect={true}
                                                                                                   options={nepaliYear}
                                                                                                   padding={0}
                                                                                                   forcePopupIcon={false}
                                                                                                   getOptionLabel={option => option}
                                                                                                   error={meta.touched && meta.error}
                                                                                />
                                                                                <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                                </div>
                                                                            </>

                                                                        )
                                                                    }
                                                                }
                                                            </Field>

                                                        </div>

                                                    </Grid>
                                                    <Grid item xs={4} style={{marginTop: 0}}>

                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'transactionMonthBs'}>
                                                                {
                                                                    (props: { field: any; form: any; meta: any; }) => {

                                                                        const {field, form, meta} = props;


                                                                        return (
                                                                            <>
                                                                                <AutoCompleteInput type={'text'}
                                                                                                   id={`transactionMonthBs`} {...field}
                                                                                                   size={"small"}
                                                                                                   placeholder={'Month'}
                                                                                                   disableClearable={true}
                                                                                                   onChange={(e, value) => {
                                                                                                       form.setFieldValue(`transactionMonthBs`, value)
                                                                                                   }}
                                                                                                   forcePopupIcon={false}
                                                                                                   padding={0}
                                                                                                   onBlur={() => handleDateBsBlur(form)}
                                                                                                   autoHighlight={true}
                                                                                                   autoSelect={true}
                                                                                                   options={nepaliMonths}
                                                                                                   getOptionLabel={option => option}
                                                                                                   error={meta.touched && meta.error}
                                                                                />
                                                                                <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                                </div>
                                                                            </>

                                                                        )
                                                                    }
                                                                }
                                                            </Field>

                                                        </div>

                                                    </Grid>
                                                    <Grid item xs={4} style={{marginTop: 0}}>

                                                        <div style={{position: 'relative'}}>
                                                            <Field name={'transactionDateBs'}>
                                                                {
                                                                    (props: { field: any; form: any; meta: any; }) => {

                                                                        const {field, form, meta} = props;

                                                                        const getDateOptions = () => {

                                                                            const {transactionYearBs, transactionMonthBs} = form.values
                                                                            if (!transactionMonthBs && !transactionMonthBs) return [];

                                                                            // @ts-ignore
                                                                            return formatedNepEngDates.filter((item: any) => (parseInt(item.nepaliYear) === parseInt(transactionYearBs)) && (parseInt(item.nepaliMonth) === parseInt(transactionMonthBs)))[0].days


                                                                        }


                                                                        return (
                                                                            <>
                                                                                <AutoCompleteInput type={'text'}
                                                                                                   id={`transactionDateBs`} {...field}
                                                                                                   size={"small"}
                                                                                                   filterOptions={(options, {inputValue}) => {
                                                                                                       let filteredOptions = getDateOptions()
                                                                                                       return matchSorter(filteredOptions, inputValue,);
                                                                                                   }}
                                                                                                   placeholder={'Day'}
                                                                                                   disableClearable={true}
                                                                                                   padding={0}
                                                                                                   onChange={(e, value) => {
                                                                                                       form.setFieldValue(`transactionDateBs`, value)
                                                                                                   }}
                                                                                                   autoHighlight={true}
                                                                                                   autoSelect={true}
                                                                                                   options={[]}
                                                                                                   forcePopupIcon={false}
                                                                                                   onBlur={() => handleDateBsBlur(form)}
                                                                                                   getOptionLabel={option => option}
                                                                                                   error={meta.touched && meta.error}
                                                                                />
                                                                                <div style={{position: "absolute"}}>
                                                                        <span style={{
                                                                            color: 'red',
                                                                            fontSize: 12,
                                                                            bottom: 0,
                                                                            left: 2
                                                                        }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                                </div>
                                                                            </>

                                                                        )
                                                                    }
                                                                }
                                                            </Field>

                                                        </div>

                                                    </Grid>

                                                </Grid>

                                            </Grid>


                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 5}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="description"
                                                       style={{fontWeight: "bold", fontSize: 14}}> Description:</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{position: 'relative'}}>
                                                    <Field name={'description'}>
                                                        {
                                                            (props: { field: any; form: any; meta: any; }) => {

                                                                const {field, form, meta} = props;


                                                                return (
                                                                    <>
                                                                        <InputField type={'text'}
                                                                                    id={'description'} {...field}
                                                                                    size={"small"}
                                                                                    multiline
                                                                                    rows={3}
                                                                                    error={meta.touched && meta.error}
                                                                        />
                                                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        }
                                                    </Field>

                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} alignItems={'center'} spacing={2}
                                              style={{marginBottom: 5}}>
                                            <Grid item xs={4}>
                                                <label htmlFor="transactionDate"
                                                       style={{fontWeight: "bold", fontSize: 14}}> Document:</label>
                                            </Grid>
                                            <Grid item xs={8} style={{fontWeight: 'bold'}}>
                                                <u>Upload</u>
                                            </Grid>
                                        </Grid>


                                    </Grid>

                                    {/*<Grid item xs={3} container*/}
                                    {/*      style={{backgroundColor: '#d0d0d0', marginBottom: 20}}*/}
                                    {/*>*/}
                                    {/*    <Grid item>*/}
                                    {/*        balances*/}
                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}

                                </Grid>


                                <TableForm handleRemove={handleRemove} addNew={addNew} setOpenDialog={setOpenDialog}
                                           formikInstance={formik}
                                />

                                <div>
                                    <FormSaveButton mode={Props.mode} from={'journal entry'} submitting={formik.isSubmitting}/>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    );
};

const TableForm: React.FC<any> = ({handleRemove, addNew, setOpenDialog, formikInstance}) => {

    const {values: {tableRow}} = useFormikContext();
    const {data: accountHeadOptions} = useSWR('/getAllAccountHeads');
    const formik = useFormikContext();

    const name = 'tableRow'


    const getDebit = useCallback(() => {
        return tableRow.filter((item: any) => item.accountHead !== null).reduce((result: number, item: any) => {
            return (result + parseFloat(item.debit))
        }, 0.00)
    }, [tableRow])

    const getCredit = useCallback(() => {
        return 0.00
    }, [tableRow])


    const data = useMemo(() => tableRow, [tableRow]);
    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleKeyUp = (values: any) => (event: any) => {
        if (!values) return;
        console.log('called key up')

        // console.log(event)
        if (event.keyCode === 9) {
            let lastRow = values.values.tableRow[values.values.tableRow.length - 1];
            if (lastRow.debit > 0) {
                console.log('add condition satisfied')
                addNew(values);
            }

        }

    }

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{width: 500}} placement='bottom-start'/>)
    }

    const voucherColumn = [

        {
            id: 'id',
            Header: 'id.',
            accessor: "id",
            width: 20

        },

        {
            id: 'accountHead',
            width:250,
            Header: 'Account Head',
            accessor: "accountHead",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;


                return (
                    <Field name={`${name}[${index}].accountHead`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                const getOptions = (options: any) => {
                                    let tempRows = form.values.tableRow;
                                    let selectedHeads = tempRows.filter((item: any) => item.accountHead).map((item: any) => item.accountHead?.id);
                                    if (field.value?.id) selectedHeads.splice(selectedHeads.indexOf(field.value?.id), 1);
                                    return options ? options.filter((item: any) => !selectedHeads.includes(item.id)) : [];

                                }

                                // @ts-ignore
                                return (
                                    <>
                                        <Grid container alignItems={"center"} justify={"center"}>
                                            <Grid item xs>
                                                <AutoCompleteInput type={'text'}
                                                                   id={`${name}[${index}].accountHead`} {...field}
                                                                   size={"small"}
                                                                   placeholder={'Select Account Head'}
                                                                   filterOptions={(options, {inputValue}) => {
                                                                       let filteredOptions = getOptions(options)
                                                                       return matchSorter(filteredOptions, inputValue, {keys: ['name', 'code','subtypename']});
                                                                   }}
                                                                   onChange={(e, value) => {
                                                                       form.setFieldValue(`${name}[${index}].accountHead`, value)
                                                                   }}
                                                                   hideOutline={true}
                                                                   autoHighlight={true}
                                                                   autoSelect={true}
                                                                   options={accountHeadOptions ? accountHeadOptions.data : []}
                                                                   loading={!accountHeadOptions}

                                                                   groupBy={option => option.subtypename}
                                                                   PopperComponent={PopperMy}
                                                                   getOptionLabel={option => option.name}
                                                                   renderOption={(option) => {
                                                                       return (
                                                                           <div>
                                                                               <div style={{
                                                                                   fontSize: 11,
                                                                               }}>
                                                                                   <>{option.code}</>
                                                                               </div>
                                                                               <div>{option.name}</div>
                                                                           </div>
                                                                       )
                                                                   }}
                                                                   error={meta.touched && meta.error}
                                                />
                                            </Grid>
                                            <ToolTip title={'Add new head'}>
                                                <Grid item style={{
                                                    borderLeft: '1px solid #d0d0d0',
                                                    padding: '8px 3px 3px 3px',
                                                    cursor: 'pointer',

                                                }}
                                                      onClick={() => handleDialogOpen()}
                                                >

                                                    <div>

                                                        <AddIcon style={{color: COLOR_PROFILE.primaryColor, fontWeight: 700, fontSize: 20}}/>

                                                    </div>
                                                </Grid>
                                            </ToolTip>
                                        </Grid>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'receiptId',
            Header: 'Payment Id',
            width: 100,

            accessor: "receiptId",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                return (
                    <Field name={`${name}[${index}].receiptId`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'text'} id={`${name}[${index}].receiptId`} {...field}
                                                    size={"small"}
                                                    multiline
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                        </div>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'receivedMethod',
            Header: 'Payment Method',
            width: 100,

            accessor: "receivedMethod",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                return (
                    <Field name={`${name}[${index}].receivedMethod`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'text'} id={`${name}[${index}].receivedMethod`} {...field}
                                                    size={"small"}
                                                    multiline
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                        </div>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            id: 'description',
            Header: 'Description',
            width: 250,

            accessor: "description",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                return (
                    <Field name={`${name}[${index}].description`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'text'} id={`${name}[${index}].description`} {...field}
                                                    size={"small"}
                                                    multiline
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                        </div>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },

        {
            id: 'debit',
            Header: 'Amount Paid (Dr)',
            accessor: "debit",
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                return (
                    <Field name={`${name}[${index}].debit`}>
                        {
                            (props: { field: any; form: any; meta: any; }) => {

                                const {field, form, meta} = props;

                                return (
                                    <>
                                        <InputField type={'number'} id={`${name}[${index}].debit`} {...field}
                                                    size={"small"}
                                                    onKeyDown={handleKeyUp(index === form.values.tableRow.length - 1 ? form : null)}
                                                    style={{border: 'none'}}
                                                    variant={"standard"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {paddingLeft: 5, paddingRight: 5}
                                                    }}
                                                    error={meta.touched && meta.error}
                                        />
                                        <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                        </div>
                                    </>

                                )
                            }
                        }
                    </Field>
                )
            }
        },
        {
            width: 15,
            id: 'delete',
            Header: '',
            Cell: (cellObj: any) => {

                const index = cellObj.row.index;

                return (
                    <div>

                        <DeleteForeverIcon fontSize={"small"} style={{color: COLOR_PROFILE.buttonRed, cursor: 'pointer',}}
                                           onClick={() => handleRemove(index, formikInstance)}/>

                    </div>
                )
            }
        }
        // [name]


    ]

    const handleDeleteClick = (index: number) => {
        console.log('delete clicked')
        handleRemove(index, formikInstance)
    }

    const getBalance = () => {
        return `${Math.abs(getCredit() - getDebit()).toFixed(2)} ${(getCredit() - getDebit()) > 0 ? '(Cr)' : (getCredit() - getDebit()) < 0 ? '(Dr)' : ''}`
    }

    const columns = useMemo<Column[]>(() => voucherColumn, [name, handleRemove, accountHeadOptions]);


    return (
        <div>

            <div style={{marginTop: 5}}>
                <VoucherTableForm columns={columns} data={data} addNew={addNew} balance={getBalance}
                                  total={{'debit': getDebit}} type={'payment'}
                                  handleDeleteClick={handleDeleteClick}
                                  minLength={1}
                />
            </div>


        </div>
    )

}

export default PaymentVouherForm;
