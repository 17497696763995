import React from 'react';
import SalesTemplate from "../../../templates/reporting/salesTemplate";

const SalesReport: React.FC= () => {
    return (
        <div>
            <SalesTemplate/>
        </div>
    );
};

export default SalesReport;