import React, {useEffect, useState} from 'react';
import useSWR, {trigger} from "swr";
import {useHistory, useLocation} from "react-router-dom";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import axiosInstance from "../../../../../../config/axiosConfig";
import {Field, Form, Formik} from "formik";
import PagePrompt from "../../../../../shared/organisms/pagePrompt";
import {Grid} from "@material-ui/core";
import RequiredNotation from "../../../../../accounting/molecules/requiredNotation";
import AutoCompleteInput from "../../../../../shared/atom/formElements/autocompleteInput";
import InputField from "../../../../../shared/atom/formElements/inputField";
import FormSaveButton from "../../../../../accounting/organisms/FormSaveButton";

interface Props {

    handleClose?: any
    id?: any,
    noFixed?: boolean

}

const InvoiceCancelForm: React.FC<Props> = (Props) => {

    const initialValues = {
        description: ''
    }
    const {data: accountGroupOptions} = useSWR('/getAllAccountGroups');
    const location = useLocation();


    const [formState, setFormState] = useState(initialValues)


    const validationSchema = Yup.object({
        description: Yup.string().required('Required')

    })
    const dispatch = useDispatch();


    const onSubmit = async (values: any, formActions: any) => {


        let isReset = values.action === 'submitAndAdd';


        try {
            const payload = {
                description: values.description
            }
            const {data} = await axiosInstance.post(`/cancelInvoice/${Props.id}`, payload)

            if (data.code === 'OK') {
                responseProcess(data, formActions, isReset)
                history.push('/dashboard/sales/invoice')
            } else {
                handleResponse(data.message, 'warning')
            }


        } catch (e) {
            handleResponse('Something went wrong', 'warning')
        }


        formActions.setSubmitting(false)

    }

    const history = useHistory();

    const responseProcess = (data: any, formActions: any, isReset: boolean) => {

        if (data.code === 'OK') {

            handleResponse('Invoice canceled.', 'success')
            formActions.resetForm();
            history.replace('/dashboard/sales/invoice');
            if (!isReset) {
                Props.handleClose ?
                    Props.handleClose() :
                    history.replace('/dashboard/sales/invoice');
            }

        } else {

            handleResponse(data.message, 'warning')

        }
    }


    const handleResponse = (message: any, type: string) => {

        dispatch({
            type: 'OPEN_SNACKBAR',
            payload: {message: `${message}`, type: type}
        })

    }


    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div>
            <Formik

                initialValues={formState}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={true}
            >
                {formik => {


                    return (
                        <div>

                            <PagePrompt formik={formik}/>

                            <Form style={{margin: 20}} onKeyDown={onKeyDown}>

                                <Grid container item sm={12} alignItems={'center'} spacing={2}
                                      style={{marginBottom: 15}}>
                                    <Grid item xs={12}>
                                        <label htmlFor="description"
                                               style={{fontWeight: "bold", fontSize: 14}}> Reason:</label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{position: 'relative'}}>
                                            <Field name={'description'}>
                                                {
                                                    (props: { field: any; form: any; meta: any; }) => {

                                                        const {field, form, meta} = props;


                                                        return (
                                                            <>
                                                                <InputField type={'text'} id={'description'} {...field}
                                                                            size={"small"}
                                                                            multiline={true}
                                                                            rows={3}
                                                                            error={meta.touched && meta.error}
                                                                />
                                                                <div style={{position: "absolute"}}>
                                                    <span style={{
                                                        color: 'red',
                                                        fontSize: 12,
                                                        bottom: 0,
                                                        left: 2
                                                    }}>{(meta.touched && meta.error) ? meta.error : null}</span>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                }
                                            </Field>

                                        </div>

                                    </Grid>
                                </Grid>

                                <FormSaveButton mode={'edit'} from={'invoiceCancel'} submitting={formik.isSubmitting}
                                                handleClose={Props.handleClose}
                                                noFixed={Props.noFixed}
                                />


                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )
};

export default InvoiceCancelForm;
