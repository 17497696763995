import React from 'react';
import PurchaseRfpHome from "../../templates/purchaseRfpHome";

const PurchaseRfp = () => {
    return (
        <div>
            <PurchaseRfpHome/>
        </div>
    );
};

export default PurchaseRfp;