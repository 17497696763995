const convertToDate = (tempDate: any) => {
    return new Date(parseInt(tempDate.split('-')[0]), parseInt(tempDate.split('-')[1]) - 1, parseInt(tempDate.split('-')[2]))
}

const findDifferenceInDate = (date1: any, date2: any) => {
    return (convertToDate(date2).getTime() - convertToDate(date1).getTime()) / (1000 * 3600 * 24);
}

export const convertAdToBs = (newDate: any, json: any) => {
    try {
        const newDateFormat = convertToDate(newDate);
        const findInJson = [...json.filter((item: any) => ((newDateFormat >= convertToDate(item.startDateEnglish)) && (newDateFormat <= convertToDate(item.endDateEnglish))))];
        const differenceInDate = findDifferenceInDate(newDate, findInJson[0].endDateEnglish);
        newDateFormat.setDate(newDateFormat.getDate() + differenceInDate);
        return (findInJson[0].nepaliYear + '-' + ('0' + findInJson[0].nepaliMonth).slice(-2) + '-' + ('0' + findInJson[0].days.sort((a: string, b: string) => parseInt(a) - parseInt(b)).reverse()[differenceInDate ? differenceInDate  : 0]).slice(-2)).split('-');
    } catch (e) {
        console.log(e)
    }
}

export const convertAdToBsDisplay = (newDate: any, json: any) => {
    try {
        const newDateFormat = convertToDate(newDate);
        const findInJson = [...json.filter((item: any) => ((newDateFormat >= convertToDate(item.startDateEnglish)) && (newDateFormat <= convertToDate(item.endDateEnglish))))];
        const differenceInDate = findDifferenceInDate(newDate, findInJson[0].endDateEnglish);
        newDateFormat.setDate(newDateFormat.getDate() + differenceInDate);
        return (findInJson[0].nepaliYear + '-' + ('0' + findInJson[0].nepaliMonth).slice(-2) + '-' + ('0' + findInJson[0].days.sort((a: string, b: string) => parseInt(a) - parseInt(b)).reverse()[differenceInDate ? differenceInDate  : 0]).slice(-2)).split('.');
    } catch (e) {
        console.log(e)
    }
}


export const convertBsToAd = (newDate: any, json: any) => {
    try {
        const newDateSplit = newDate.split('-');
        const findInJson = json.filter((item: any) => ((parseInt(newDateSplit[0]) === item.nepaliYear) && (parseInt(newDateSplit[1]) === item.nepaliMonth)));
        const differenceInDate = parseInt(newDate.split('-')[2]);
        const startDate = convertToDate(findInJson[0].startDateEnglish)
        startDate.setDate(startDate.getDate() + differenceInDate)
        return startDate.toISOString();
    } catch (e) {
        console.log(e)
    }
}

export const getTodaysDate = () => {

    try {
        let todaysDate = new Date();
        return todaysDate.getFullYear() + '-' + ('0' +(todaysDate.getMonth() + 1)).slice(-2) + '-' + ('0' +todaysDate.getDate()).slice(-2);
    } catch (e) {
        return '';
    }
}

export const getYesterDayDate = () => {

    try {
        let todaysDate = new Date();
        todaysDate.setDate(todaysDate.getDate() - 1)
        return todaysDate.getFullYear() + '-' + ('0' +(todaysDate.getMonth() + 1)).slice(-2) + '-' + ('0' +todaysDate.getDate()).slice(-2);
    } catch (e) {
        return '';
    }
}
