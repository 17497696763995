import * as React from "react";
import { setLanguage } from "../slices/preferences";
// import { useAppDispatch, useAppSelector } from "../store/hooks";
import en from "../translations/en.json";
import np from "../translations/np.json";

const files = {
  np,
  en
} as unknown as { np: Record<string, string>; en: Record<string, string> };

export const translate = (key: string, language?: string) => {
  const currentLanguage = (language ||
    // window.languageCache ||
    localStorage.getItem("language") ||
    "en") as "en" | "np";
  return Object.prototype.hasOwnProperty.call(files[currentLanguage], key)
    ? files[currentLanguage][key]
    : `[${key}]`;
};

export const setLanguageLocalStorage = (language: string) => {
  localStorage.setItem("language", language);
};

export const getLanguageLocalStorage = () =>
  (localStorage.getItem("language") || "en") as "en" | "np";

const tl = (key: string) => {
  const language = "en";
  return <>{translate(key, language)}</>;
};

export const useInitializeLanguage = () => {
  // const dispatch = useAppDispatch();
  React.useEffect(() => {
    const language = getLanguageLocalStorage();
    // dispatch(setLanguage(language));
  }, []);
};

export { tl, translate as t };
