import React from "react";
import ExpenseTemplate from "../../../templates/reporting/expenseTemplate";

const ExpenseReport: React.FC= () => {
    return (
        <div>
            <ExpenseTemplate/>
        </div>
    );
};

export default ExpenseReport;

