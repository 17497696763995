import React from 'react';
import InventoryLevelHome from "../../templates/inventoryLevelHome";

const InventoryLevel = () => {
    return (
        <div>
            <InventoryLevelHome/>
        </div>
    );
};

export default InventoryLevel;