import React from 'react';
import {useExpanded, useTable} from "react-table";
import {COLOR_PROFILE} from "../../../../../config/colorCode";

const ReportTable: React.FC<any> = (Props: any) => {

    const tableInstance: any = useTable({
        columns: Props.columns,
        data: Props.data,
        autoResetExpanded: false
    }, useExpanded)

    const {total, type} = Props;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        isRowLoading = Props.isRowLoading,
        state: { expanded },
    } = tableInstance;


    const totalStructure = {
        'journal': {
            totalIn: [0, 1],
            1: 'credit',
            2: 'debit',
        },
        'payment': {
            totalIn: [0],
            1: 'debit'
        },
        'receipt': {
            totalIn: [0],
            1: 'credit'
        }

    }

    return (
        <div>
            <div>
                <div style={{overflow: 'auto',}}>

                    <table {...getTableProps()} style={{minWidth: Props.isChartOfAccount ? 600: 1000, padding: '0 20px 0 20px'}}>
                        {Props.withTwoHead && (
                            <thead
                                style={{background: COLOR_PROFILE.tableHeader}}
                            >

                            {headerGroups.map((headerGroup: any) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column: any, index: any) => {

                                            return (
                                                <th {...column.getHeaderProps()}
                                                    style={{
                                                        padding: 7,
                                                        color: COLOR_PROFILE.primaryColor,
                                                        // minWidth: column.width || 100,
                                                        textAlign: column.alignment || 'left'
                                                    }}>
                                                    {index === 1 ? 'Particulars' : index === 0 ? 'S.N': column.render('Header')}
                                                </th>

                                            )
                                        })
                                    }
                                </tr>
                            ))}

                            </thead>
                        )}

                        <thead
                            style={{background:(Props.withOutHeadBackground && Props.withTotal) ? COLOR_PROFILE.tableRowOdd: COLOR_PROFILE.tableHeader}}
                        >

                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map((column: any, index: any) => {


                                        return (
                                            <th {...column.getHeaderProps()}
                                                style={{


                                                    backgroundColor: Props.total ? index===0 ? '#fafafa' : 'inherit': 'inherit',
                                                    padding: 7,
                                                    color: COLOR_PROFILE.primaryColor,
                                                    // minWidth: column.width || 100,
                                                    textAlign: column.alignment || 'left'
                                                }}>

                                                {(((Props.withOutHeadBackground && Props.withTotal)) && index !== 1) ? '' : column.render('Header')}
                                                {/*{column.render('Header')}*/}
                                            </th>

                                        )
                                    })
                                }
                            </tr>
                        ))}

                        </thead>
                        <tbody {...getTableBodyProps()}
                               style={{justifyContent: 'flex-start'}}>

                        {rows.map((row: any, index: any) => {
                            prepareRow(row)
                            let smallIndex = [2, 3]

                            return (
                                <>
                                    <tr {...row.getRowProps()}
                                        style={{
                                            backgroundColor:  (rows.length - 1 === index && Props.total) ?  '#fafafa':  index % 2 === 0 ? COLOR_PROFILE.tableRowEven : COLOR_PROFILE.tableRowOdd,


                                        }}
                                    >

                                        {row.cells.map((cell: any, indexCell: any) => {
                                            return (
                                                <td {...cell.getCellProps()}
                                                    style={{
                                                        padding: indexCell === 0 ? 5 : indexCell === 1 ? '7px 7px 7px 15px' : 7,
                                                        color: COLOR_PROFILE.primaryColor,
                                                        fontSize: 13,
                                                        background:  indexCell===0 ? '#fafafa' : 'inherit',
                                                        minWidth: smallIndex.includes(indexCell) ? 150 :indexCell===1 ? Props.total ?  300: 30: "inherit",
                                                        width: indexCell===0 ? 50: "inherit",
                                                        // paddingLeft:indexCell === 0 ? 5 : 7 ? Props.withOutHeadBackground ? 20 : 0 : 0,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {
                                                        cell.render('Cell', {
                                                                isLoading: isRowLoading ? isRowLoading[row.id]: null,
                                                                isExpanded: expanded[row.id],
                                                            })
                                                    }


                                                </td>
                                            )
                                        })}

                                    </tr>

                                    {((index === rows.length - 1) && (Props.finalReport)) && (
                                        <tr {...row.getRowProps()}
                                        >
                                            {row.cells.map((cell: any, indexCell: any) => {


                                                // @ts-ignore
                                                let structure = totalStructure[type];

                                                // @ts-ignore
                                                return <td {...cell.getCellProps()}
                                                           style={{
                                                               backgroundColor: row.cells.length - 1 === indexCell ? '#fafafa' : null,
                                                               padding: '5px 0px 5px 7px',
                                                               fontSize: 16, fontWeight: 600,
                                                               color: COLOR_PROFILE.primaryColor,
                                                               textAlign: (row.cells.length - structure.totalIn.length - 2) === indexCell ? 'center' : 'left',
                                                           }}>
                                                    {
                                                        (row.cells.length - structure.totalIn.length - 1) === indexCell ? Props.finalReportName :
                                                            structure.totalIn.includes(row.cells.length - 2 - indexCell) ?
                                                                Props.finalData :
                                                                null
                                                    }

                                                </td>

                                            })}

                                        </tr>
                                    )}

                                </>
                            )
                        })}


                        </tbody>


                    </table>
                </div>

            </div>

        </div>
    );


};


export default ReportTable;
