import React from 'react';

const RequiredNotation: React.FC = () => {
    return (

        <span style={{fontSize: 16, color: 'red'}}><sup>*</sup></span>

    );
};

export default RequiredNotation;
