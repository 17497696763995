import React, {useEffect, useRef, useState} from 'react';
import CustomChart from "../../../molecules/customChart";
import BarChart from "../../../organisms/barChart";
import {Collapse, Divider, Grid} from "@material-ui/core";
import {COLOR_PROFILE} from "../../../../../config/colorCode";
import useSWR from "swr";
import {getTodaysDate} from "../../../../../config/dateFunctions";
import CategoryReport from "../../../organisms/categoryReportDrill";
import ListView from "../../../organisms/ListView";
import DateComponent from "../../../../product/molecules/dateComponent";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import Button from "../../../../shared/atom/button";
import {getCurrentDate, getDiffdate, getFormattedDate} from "../../../../../utils/dateFunction";
import {useDispatch} from "react-redux";
import PayabalesChart from "./PayabalesChart";


const PayablesTemplate: React.FC = () => {

    const ref: any = useRef();
    const [height, setHeight] = useState(400)
    const [total, setTotal] = useState('');

    useEffect(() => {
        if (ref?.current) setHeight(ref.current.clientHeight);
    })

    const {data: customerReceivable} = useSWR('/report/customerVendorReport?from=&to=')

    return (
        <div style={{color: COLOR_PROFILE.primaryColor}}>

            <Grid container>
                <Grid item xs={12} sm={8}>
                    <div ref={ref} style={{padding: 20}}>
                        <PayabalesChart/>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    {/*<div>*/}
                    {/*    <CustomChart*/}
                    {/*        data={*/}
                    {/*                {*/}
                    {/*                datasets: [{*/}
                    {/*                    barPercentage: 0.9,*/}
                    {/*                    barThickness: 20,*/}
                    {/*                    maxBarThickness: 30,*/}
                    {/*                    minBarLength: 2,*/}
                    {/*                    data: [10, 20, 30, 40, 50, 60, 70]*/}
                    {/*                }],*/}
                    {/*                    labels: [1,2,3,4,5,6,7]*/}
                    {/*                }*/}
                    {/*        }*/}

                    {/*        options={{responsive: true, maintainAspectRatio: true}}*/}
                    {/*        type={'bar'}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div>
                        <div style={{fontWeight: 700, margin: '20px 0 0 20px', fontSize: 16,}}>
                            Vendor Payable <span style={{fontSize: 12, fontWeight: 500}}>(Lifetime)</span>
                        </div>
                    </div>
                    <Grid container xs={12} style={{padding: '15px 20px 60px',}}>
                        {ref && (
                            <Grid item xs={12} style={{
                                paddingTop: 15,
                                boxShadow: `0 0 10px 0px ${COLOR_PROFILE.backgroundDark}`,
                                maxHeight: height - 30 || undefined,
                                minWidth: '100%',
                                padding: 0,
                                overflow: 'auto'
                            }}>
                                <ListView
                                    header={["Vendor", "Amount"]}
                                    data={customerReceivable?.data[0] ? Object.keys(customerReceivable.data[0]).map((item: any) => {
                                        console.log("item", item);
                                        return {
                                            key: customerReceivable.data[0][item].name,
                                            value: Math.abs(customerReceivable.data[0][item].accountHeadDto.balance)
                                        }
                                    }) : []}/>

                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <div>
                <div style={{padding: '0 0 10px 20px', fontWeight: 700, fontSize: 16}}>
                    Payable Summary:
                </div>
                <div>
                    <CategoryReport setTotal={setTotal} type={"payable"}/>
                </div>
            </div>

        </div>
    );
};

export default PayablesTemplate;
