import React, {useEffect, useState} from 'react';
import {COLOR_PROFILE} from "../../../../../../config/colorCode";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../store/RootReducer";
import useSWR from "swr";
import {Grid} from "@material-ui/core";

const PurchaseRfpTerms: React.FC<any> = (Props) => {

    const initialValue = {
        termAndConditions: []
    }
    const [formState, setFormState] = useState(initialValue);
    const {data: CompanyInfo} = useSWR('/getCompanyInformation');

    useEffect(() => {
        if (!CompanyInfo) return;

        console.log(JSON.parse(CompanyInfo.t.termsAndConditions))

        setFormState({
            termAndConditions: CompanyInfo.t.termsAndConditions ? JSON.parse(CompanyInfo.t.termsAndConditions) : []
        })

    }, [CompanyInfo])


    return (
        <div style={{color: COLOR_PROFILE.primaryColor}}>

            {CompanyInfo && (
                <div style={{marginBottom: 20}}>
                    <div style={{fontSize: 16, margin: '20px 0 10px 0', fontWeight: 700}}>
                        Terms & Conditions
                    </div>
                    <div style={{border: `1px solid ${COLOR_PROFILE.backgroundDark}`, width: '100%', padding: 10}}>

                        {formState.termAndConditions.map((item: any, index: number) => {
                            return (
                                <div style={{fontSize: 14,}}>
                                    {index + 1}. {item.value}
                                </div>
                            )
                        })}

                    </div>
                </div>
            )}

            <div style={{}}>
                <div style={{fontSize: 16, paddingBottom: 10, fontWeight: 700}}>
                    Must be signed to be valid
                </div>


                <div>
                    <Grid container style={{border: `1px solid ${COLOR_PROFILE.backgroundDark}`, width: '100%',}}>
                        <Grid item container style={{borderBottom: `1px solid ${COLOR_PROFILE.backgroundDark}`}}>
                            <Grid item xs={6}
                                  style={{borderRight: `1px solid ${COLOR_PROFILE.backgroundDark}`, padding: 10}}>
                                Date:
                            </Grid>
                            <Grid item xs={6} style={{padding: 10}}>
                                Phone No:
                            </Grid>


                        </Grid>

                        <Grid item style={{padding: '10px 10px 100px 10px'}}>
                            Authorized Signature & Stamp:
                        </Grid>
                    </Grid>
                </div>

            </div>

        </div>
    );
};

export default PurchaseRfpTerms;