import React from 'react';
import ProductOfferAddition from "../../templates/productOfferAddition";

const ProductOfferAdd: React.FC = (Props) => {
    return (
        <div>
            <ProductOfferAddition/>
        </div>
    );
};

export default ProductOfferAdd;