import React from 'react';
import PageTopBar from "../../../organisms/pageTopBar";
import DividerComponent from "../../../../shared/atom/divider";
import UserRoleForm from "../../../organisms/Forms/userRoleForm";
import {useParams} from "react-router-dom";

const AddUserAndRolePage: React.FC = () => {

    // @ts-ignore
    const {id} = useParams();
    const mode = id ? 'edit' : 'create';

    return (
        <div>

            <PageTopBar name={id ? 'Edit User' : 'Add User'}/>

            <div style={{margin: '0 -22px 0 -22px'}}>
                <DividerComponent color={'#d0d0d0'}/>
            </div>

            <div style={{maxWidth: 900, paddingLeft: 25, paddingRight: 25, paddingBottom: 50}}>
                {/*<div style={{margin: '25px 0px 10px 25px',}}>*/}
                <UserRoleForm from={'branch'} mode={mode} id={id}/>
                {/*</div>*/}
            </div>
        </div>
    );
};

export default AddUserAndRolePage;
